export const EmptyPriceListIcon = (props) => {
  return (
    <svg
      width={100}
      height={120}
      viewBox="0 0 100 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <g clipPath="url(#b)" filter="url(#c)">
          <path
            d="M97.046 116a4 4 0 0 1-4 4H19.227c-2.209 0-4-1.789-4-3.998V7.989c0-4.412 1.818-7.989 4.06-7.989h73.7c2.24 0 4.059 3.577 4.059 7.99z"
            fill="#fff"
          />
          <path
            d="M93.046 119.5H19.227v1h73.819zm-77.319-3.498v-14.745h-1v14.745zm0-14.745V7.989h-1v93.268zm0-93.268c0-2.144.443-4.06 1.135-5.422C17.57 1.177 18.453.5 19.287.5v-1c-1.408 0-2.553 1.112-3.316 2.613-.777 1.53-1.244 3.608-1.244 5.876zM19.287.5h73.7v-1h-73.7zm73.7 0c.833 0 1.717.677 2.424 2.067.692 1.362 1.135 3.278 1.135 5.422h1c0-2.268-.467-4.346-1.244-5.876C95.54.612 94.394-.5 92.986-.5zm3.559 7.49v92.277h1V7.989zm0 92.277V116h1v-15.733zM19.227 119.5a3.5 3.5 0 0 1-3.5-3.498h-1a4.5 4.5 0 0 0 4.5 4.498zm73.819 1a4.5 4.5 0 0 0 4.5-4.5h-1a3.5 3.5 0 0 1-3.5 3.5z"
            fill="#F5F5F5"
          />
        </g>
        <path
          d="M95.213 0H17.06c-2.141 0-3.877 1.433-3.877 3.2v9.6c0 1.767 1.736 3.2 3.877 3.2h78.154c2.142 0 3.878-1.433 3.878-3.2V3.2c0-1.767-1.736-3.2-3.878-3.2"
          fill="#E2F0E8"
        />
        <path
          d="M21.364 10c.404 0 .8-.117 1.136-.337a2 2 0 0 0 .753-.898 1.96 1.96 0 0 0 .117-1.155 2 2 0 0 0-.56-1.024 2.06 2.06 0 0 0-1.047-.548 2.1 2.1 0 0 0-1.182.114 2.04 2.04 0 0 0-.918.737 1.97 1.97 0 0 0 .254 2.525 2.07 2.07 0 0 0 1.447.586"
          fill="#FF5F58"
        />
        <path
          d="M27.5 10c.404 0 .8-.117 1.136-.337a2 2 0 0 0 .754-.898 1.96 1.96 0 0 0 .116-1.155 2 2 0 0 0-.56-1.024 2.06 2.06 0 0 0-1.047-.548 2.1 2.1 0 0 0-1.182.114 2.04 2.04 0 0 0-.918.737 1.97 1.97 0 0 0 .255 2.525A2.07 2.07 0 0 0 27.5 10"
          fill="#FEBC2E"
        />
        <path
          d="M33.636 10c.405 0 .8-.117 1.137-.337a2 2 0 0 0 .753-.898 1.96 1.96 0 0 0 .116-1.155 2 2 0 0 0-.56-1.024 2.06 2.06 0 0 0-1.047-.548 2.1 2.1 0 0 0-1.181.114 2.04 2.04 0 0 0-.918.737 1.97 1.97 0 0 0 .254 2.525 2.07 2.07 0 0 0 1.446.586"
          fill="#28CC42"
        />
        <path
          d="M90.063 19H22.21c-2.162 0-3.915 1.401-3.915 3.13v17.74c0 1.729 1.753 3.13 3.915 3.13h67.853c2.162 0 3.914-1.401 3.914-3.13V22.13c0-1.729-1.752-3.13-3.914-3.13"
          fill="#fff"
        />
        <path
          d="M90.063 19.522H22.21c-1.802 0-3.262 1.168-3.262 2.609V39.87c0 1.44 1.46 2.608 3.262 2.608h67.853c1.801 0 3.262-1.168 3.262-2.608V22.13c0-1.44-1.46-2.608-3.262-2.608Z"
          stroke="#F5F5F5"
        />
        <path
          d="M70.511 29H51.988c-.798 0-1.564-.263-2.129-.732-.565-.47-.882-1.105-.882-1.768s.317-1.299.882-1.768S51.19 24 51.99 24h18.52c.799 0 1.565.263 2.13.732.564.47.882 1.105.882 1.768s-.318 1.299-.882 1.768-1.33.732-2.13.732m13.292 9h-31.81c-.8 0-1.566-.263-2.132-.732-.565-.47-.883-1.105-.883-1.768s.318-1.299.883-1.768c.566-.469 1.333-.732 2.132-.732h31.811c.8 0 1.567.263 2.132.732.565.47.883 1.105.883 1.768s-.318 1.299-.883 1.768c-.566.469-1.334.732-2.133.732"
          fill="#F5F5F5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.165 25.41a.833.833 0 0 1 0 1.18l-9.167 9.166a.833.833 0 0 1-1.178 0l-4.167-4.167a.833.833 0 0 1 1.179-1.178l3.577 3.577 8.578-8.577a.833.833 0 0 1 1.178 0"
          fill="#409A65"
          stroke="#409A65"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M72.676 47H4.824C2.662 47 .909 48.401.909 50.13v17.74C.91 69.598 2.662 71 4.824 71h67.852c2.162 0 3.915-1.401 3.915-3.13V50.13c0-1.729-1.753-3.13-3.915-3.13"
          fill="#fff"
        />
        <path
          d="M72.676 47.522H4.824c-1.802 0-3.262 1.168-3.262 2.609V67.87c0 1.44 1.46 2.608 3.262 2.608h67.852c1.802 0 3.263-1.167 3.263-2.608V50.13c0-1.44-1.461-2.608-3.263-2.608Z"
          stroke="#F5F5F5"
        />
        <path
          d="M53.125 57H34.602c-.799 0-1.564-.263-2.13-.732-.564-.47-.881-1.105-.881-1.768s.317-1.299.882-1.768S33.803 52 34.602 52h18.523c.799 0 1.565.263 2.13.732.564.47.881 1.105.881 1.768s-.317 1.299-.882 1.768c-.564.469-1.33.732-2.129.732m13.29 9h-31.81c-.799 0-1.566-.263-2.131-.732-.565-.47-.883-1.105-.883-1.768s.317-1.299.883-1.768c.565-.469 1.332-.732 2.132-.732h31.81c.8 0 1.567.263 2.133.732.565.47.883 1.105.883 1.768s-.318 1.299-.883 1.768c-.566.469-1.334.732-2.134.732"
          fill="#F5F5F5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.165 53.41a.833.833 0 0 1 0 1.18l-9.167 9.166a.833.833 0 0 1-1.178 0l-4.167-4.167a.833.833 0 1 1 1.179-1.178l3.577 3.577 8.577-8.577a.833.833 0 0 1 1.179 0"
          fill="#409A65"
          stroke="#409A65"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.085 75H23.233c-2.162 0-3.915 1.401-3.915 3.13v17.74c0 1.728 1.753 3.13 3.915 3.13h67.852C93.247 99 95 97.599 95 95.87V78.13c0-1.728-1.753-3.13-3.915-3.13"
          fill="#fff"
        />
        <path
          d="M91.085 75.522H23.233c-1.802 0-3.262 1.168-3.262 2.609V95.87c0 1.44 1.46 2.608 3.262 2.608h67.852c1.802 0 3.263-1.167 3.263-2.608V78.13c0-1.44-1.461-2.608-3.263-2.608Z"
          stroke="#F5F5F5"
        />
        <path
          d="M71.534 85H53.011c-.799 0-1.565-.263-2.13-.732-.564-.47-.881-1.105-.881-1.768s.317-1.299.882-1.768S52.212 80 53.01 80h18.523c.799 0 1.565.263 2.13.732.564.47.881 1.105.881 1.768s-.317 1.299-.882 1.768c-.564.469-1.33.732-2.129.732m13.291 9h-31.81c-.799 0-1.566-.263-2.131-.732-.566-.47-.883-1.105-.883-1.768s.318-1.299.883-1.768S52.215 89 53.015 89h31.81c.8 0 1.567.263 2.133.732.565.47.883 1.105.883 1.768s-.318 1.299-.883 1.768c-.566.469-1.334.732-2.134.732"
          fill="#F5F5F5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.165 81.41a.833.833 0 0 1 0 1.18l-9.167 9.166a.833.833 0 0 1-1.178 0l-4.167-4.167a.833.833 0 0 1 1.179-1.178l3.577 3.577 8.578-8.577a.833.833 0 0 1 1.178 0"
          fill="#409A65"
          stroke="#409A65"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.91 0h98.182v120H.91z" />
        </clipPath>
        <clipPath id="b">
          <path fill="#fff" d="M15.227 0h81.818v120H15.227z" />
        </clipPath>
        <filter
          id="c"
          x={10.227}
          y={-10}
          width={97.818}
          height={136}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx={3} dy={-2} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.039 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_382_5220"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_382_5220"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
