import { useEffect, useMemo, useRef, useState } from "react";
import { object, func, string } from "prop-types";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { StyledTextField } from "../../../../../../../components/TextFields/TextFields";
import { useDebounce } from "../../../../../../../helpers/hooks";
import useStyles, { cl } from "./styles";

import { getItemWithId } from "./helpers";
import { ChildItem } from "./ChildItem";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { createSelector } from "reselect";
import {
  categoriesListSelector,
  isLoadingCategoriesSelector,
} from "../../../../../../../redux/selectors/categories";
import { useSelector, useDispatch } from "react-redux";
import { getCategoriesListAction } from "../../../../../../../redux/actions/categories";
import { ExpandMore } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import { ChipComponent } from "./ChipComponent";

const selector = createSelector(
  isLoadingCategoriesSelector,
  categoriesListSelector,
  (isLoadingCategories, categoriesList) => ({
    isLoadingCategories,
    categoriesList,
  })
);

export const CategoriesComponent = ({
  control,
  setValue,
  label = "Category",
  inputProps = {},
}) => {
  const classes = useStyles();

  const inputRef = useRef(null);

  const { isLoadingCategories, categoriesList } = useSelector(selector);
  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState("");

  const [showCategories, setShowCategories] = useState([]);

  const searchInputDebounced = useDebounce(searchInput, 500);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(getCategoriesListAction({ search: searchInputDebounced }));
  }, [dispatch, searchInputDebounced]);

  useEffect(() => {
    setShowCategories(categoriesList); // Ensure showCategories is updated
  }, [categoriesList]);

  const parentCategory = useMemo(() => {
    const hasParent = getItemWithId(
      categoriesList,
      showCategories.find((category) => category?.parentCategoryId)
        ?.parentCategoryId
    );

    return (
      !!hasParent && (
        <Box
          sx={{
            "&:hover": { backgroundColor: "#F7F7F7" },
            borderRadius: "4px",
            width: "100%",
            height: "26px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            ml: "-16px",
            mt: "-14px",
          }}
          onClick={() => {
            setValue("category", hasParent, { shouldDirty: true });
          }}
        >
          <IconButton
            sx={{
              width: "26px",
              height: "26px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (hasParent?.parentCategoryId) {
                setShowCategories(
                  getItemWithId(categoriesList, hasParent?.parentCategoryId)
                    ?.childCategories
                );
              }
              if (hasParent?.parentCategoryId === null) {
                setShowCategories(categoriesList);
              }
            }}
          >
            <ChevronLeftIcon />
          </IconButton>

          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 600,
              color: "#1C1C19",
            }}
          >
            {hasParent?.name}
          </Typography>
        </Box>
      )
    );
  }, [categoriesList, setValue, showCategories]);

  const categoryField = useMemo(() => {
    return (
      <Paper
        sx={{ width: "100%", height: !parentCategory ? "130px" : "155px" }}
        elevation={0}
      >
        <Box sx={{ mb: "10px" }}>{parentCategory}</Box>
        {showCategories?.map((category) => (
          <Box
            key={category?.id}
            sx={{
              "&:hover": { backgroundColor: "#F7F7F7" },
              borderRadius: "4px",
              width: "100%",
              height: "26px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
              mt: "-14px",
              mb: "12px",
            }}
            onClick={() => {
              setOpen(false);
              setValue("category", category, { shouldDirty: true });
            }}
          >
            <Typography
              sx={{
                pl: "10px",
                fontSize: 13,
                fontWeight: 300,
                color: "#1C1C19",
                flexGrow: 1,
              }}
            >
              {category?.name}
            </Typography>

            <Box pr={1}>
              <ChipComponent
                count={category?._count?.products}
                name=""
                size="small"
                variant="outlined"
              />
            </Box>

            {category?.childCategories?.length ? (
              <IconButton
                sx={{
                  width: "26px",
                  height: "26px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowCategories(
                    getItemWithId(categoriesList, category?.id)?.childCategories
                  );
                }}
              >
                <ChevronRightIcon />
              </IconButton>
            ) : (
              <Box width="26px" height="26px" />
            )}
          </Box>
        ))}
      </Paper>
    );
  }, [categoriesList, parentCategory, setValue, showCategories]);

  const getCurrentCategoriesList = useMemo(() => {
    return searchInput ? categoriesList : [];
  }, [categoriesList, searchInput]);

  return (
    <Grid xs={12} item position="relative">
      <Controller
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => {
              setSearchInput("");
              setOpen(false);
            }}
            fullWidth
            disableClearable
            //={!field.value ? true : false}
            onInputChange={(event, option, reason) => {
              if (reason === "clear") setSearchInput("");
            }}
            loading={isLoadingCategories}
            loadingText={
              <Typography
                sx={{
                  pl: "10px",
                  fontSize: 13,
                  fontWeight: 300,
                  color: "#1C1C19",
                  flexGrow: 1,
                }}
              >
                Loading...
              </Typography>
            }
            PaperComponent={(props) => (
              <Box {...props} className={classes.recipientsTable} />
            )}
            size="small"
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            sx={cl.search}
            className={classes.textfield}
            getOptionLabel={(option) => option?.name || ""}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                label={label}
                inputRef={inputRef}
                className={classes.textfield}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                sx={cl.searchInput}
                InputProps={{
                  ...params?.InputProps,
                  ...inputProps,
                  endAdornment: (
                    <>
                      {params?.InputProps?.endAdornment}
                      {(params?.inputProps.value ||
                        (!!searchInput && !field?.value)) && (
                        <IconButton
                          sx={{ p: 0, mr: "29px" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setSearchInput("");
                            setValue("category", "");
                            if (inputRef?.current) inputRef?.current?.blur();
                          }}
                        >
                          <ClearIcon sx={{ width: "20px", height: "20px" }} />
                        </IconButton>
                      )}
                    </>
                  ),
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setShowCategories([...categoriesList]);
                }}
              />
            )}
            ListboxProps={{ sx: cl.searchListBoxProps }}
            classes={{ noOptions: classes.noOptions }}
            noOptionsText={
              categoriesList?.length ? (
                categoryField
              ) : (
                <Typography
                  sx={{
                    pl: "10px",
                    fontSize: 13,
                    fontWeight: 300,
                    color: "#1C1C19",
                    flexGrow: 1,
                  }}
                >
                  No categories found
                </Typography>
              )
            }
            popupIcon={<ExpandMore style={{ color: "#B5B5AC" }} />}
            renderOption={(props, recipient) => (
              <ChildItem {...props} category={recipient} setValue={setValue} />
            )}
            options={getCurrentCategoriesList}
            error={error?.message || ""}
            {...field}
            onChange={(e, newValue) => {
              setValue(field.name, newValue);
            }}
          />
        )}
        name="category"
        control={control}
      />
    </Grid>
  );
};

CategoriesComponent.propTypes = {
  control: object.isRequired,
  inputProps: object,
  setValue: func.isRequired,
  label: string,
};
