import { useEffect, useState } from "react";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import { HeadphonesIcon } from "../../../../../components/Icons";
import StyledButton from "../../../../../components/StyledButton";
import ImportTab from "../ImportTab";
import { ExportDialog } from "./components/ExportDialog";
import ImportOption from "./components/ImportOption";
import { getImportOptions } from "./ImportExportTab.constants";
import useStyles from "./styles";
import { HubspotContactForm, Loader } from "components";
import { useExport } from "./ImportExport.hooks";
import { getEnvironment, useAdmin } from "helpers/helpers";
import { APP_ENVS } from "utils/constants";
import { useRepsPermissions } from "helpers/hooks";
import { useImport } from "../ImportTab/components/hooks";

const ImportExportTab = () => {
  const isAdmin = useAdmin();
  const isProdEnv = getEnvironment(APP_ENVS.prod);
  const repPermissions = useRepsPermissions();

  const IMPORT_OPTIONS_LIST = getImportOptions({
    isProdEnv,
    repPermissions,
    isAdmin,
  });

  const classes = useStyles();
  const { importData, getImportData, handleImport, importStatus } = useImport();
  const [hubspotOpen, setHubspotOpen] = useState(false);

  const {
    handleSubmit,
    control,
    exportOpenData,
    updateExportState,
    formField,
    onSubmit,
  } = useExport();

  const handleExportDialog = (type) => {
    updateExportState({ type, open: true });
  };

  useEffect(getImportData, [getImportData]);

  if (importData.isImport)
    return <ImportTab step={importData.step} status={importStatus} />;

  return (
    <>
      <Loader zIndexIncrease={1} isLoading={importData.confirmLoading} />
      <HubspotContactForm
        // eslint-disable-next-line no-undef
        formId={process.env.REACT_APP_HUBSPOT_SUPPORT_FORM_ID}
        description="Please reach out to our support team to answer your questions."
        contact="Support"
        open={hubspotOpen}
        onClose={() => setHubspotOpen(false)}
      />
      <form onSubmit={handleSubmit(onSubmit)} id="export-form">
        <ExportDialog
          open={exportOpenData.open}
          type={exportOpenData.type}
          onClose={() => updateExportState({ open: false })}
          control={control}
          format={formField.format}
          disabledActionBtn={exportOpenData.loading}
          form="export-form"
        />
      </form>
      <Box>
        <Box className={classes.pageHeader}>
          <Typography className={classes.title}>Import / Export</Typography>
          <StyledButton
            disabled={isAdmin}
            label="Get help"
            variant="outlined"
            color="cancel"
            sx={{ color: "#5F6267" }}
            fontSize="15px"
            startIcon={<HeadphonesIcon />}
            onClick={() => setHubspotOpen(true)}
          />
        </Box>
        <Divider />
        <Box className={classes.pageBody}>
          <Typography className={classes.bodyText}>
            Before you start on ensure your data is up-to-date and in the right
            format.
          </Typography>

          <Box className={classes.optionsWrapper}>
            {importData.sessionsListLoading ? (
              <CircularProgress />
            ) : (
              IMPORT_OPTIONS_LIST.map((option) => (
                <ImportOption
                  inProgress={!!importData.records[option.key]?.length}
                  key={option.title}
                  disabled={option.disabled}
                  disabledImport={option.disabledImport}
                  disabledExport={option.disabledExport}
                  handleExport={handleExportDialog}
                  {...{ option, handleImport }}
                />
              ))
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ImportExportTab;
