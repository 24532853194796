import { generateAlphabetMap } from "helpers/helpers";
import { ImportContext } from "Pages/SettingsPage/SettingsPage";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import useImport from "./useImport";
import {
  getImportSessionByIdService,
  remapImportDataService,
} from "services/import";
import { error } from "utils/notifications";

const useMapData = () => {
  const { importData } = useContext(ImportContext);

  const { currentSession } = useMemo(
    () => importData,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [importData.currentSession]
  );

  const currentColumns = useMemo(
    () => importData.currentSchema?.cols || {},
    [importData.currentSchema?.cols]
  );

  const columnsMap = useMemo(
    () =>
      generateAlphabetMap(
        Object.keys(currentColumns)
          .filter((key) => {
            return key !== "discount" && key !== "paymentTerms";
          })
          .map((key) => ({
            ...currentColumns[key],
            field: key,
          }))
      ),
    [currentColumns]
  );

  const { headers } = useMemo(
    () => currentSession || { headers: {} },
    [currentSession]
  );

  const [session, setSession] = useState({
    // query: { limit: 5, offset: 0, search: "", direction: "desc" },
    loading: false,
    columnsMap,
  });

  const { updateImportData } = useImport();

  const updateSession = useCallback((newValues) => {
    setSession((prev) => ({ ...prev, ...newValues }));
  }, []);

  const updateSessionQuery = useCallback(
    (newValues) => {
      updateSession({ query: { ...session.query, ...newValues } });
    },
    [session.query, updateSession]
  );

  const getSessionById = useCallback(async () => {
    try {
      updateSession({ loading: true });
      const { data } = await getImportSessionByIdService(
        importData.uploadSession?.session,
        { ...session.query }
      );
      updateSession({ loading: false });

      updateImportData({ currentSession: data });
      if (data.map) {
        updateImportData({ mappedCols: data.map });
      }
    } catch (err) {
      updateSession({ loading: false });
      error(err?.response?.data?.message);
    }
  }, [
    importData.uploadSession?.session,
    session.query,
    updateImportData,
    updateSession,
  ]);

  useEffect(getSessionById, [getSessionById]);

  const generateMappedCols = () => {
    const cols = {};
    const reverseCols = {};

    session.columnsMap.forEach((item) => {
      Object.assign(cols, { [item.column]: item.field });
      Object.assign(reverseCols, { [item.field]: item.column });
    });

    return { mappedCols: cols, reverseMappedCols: reverseCols };
  };

  const { mappedCols, reverseMappedCols } = useMemo(generateMappedCols, [
    session.columnsMap,
  ]);

  const updateColumnsMapContext = useCallback(() => {
    updateImportData({ mappedCols, reverseMappedCols });
  }, [mappedCols, reverseMappedCols, updateImportData]);

  useEffect(updateColumnsMapContext, [updateColumnsMapContext]);

  const checkUniqueCols = useCallback((cols) => {
    const colsArray = Object.values(cols);
    const hasDuplicate = new Set(colsArray).size !== colsArray.length;
    return hasDuplicate;
  }, []);

  const onRemapChange = useCallback(
    async ({ value, column, index }) => {
      try {
        const cols = { ...importData.mappedCols, [column]: value };

        const hasDuplicates = checkUniqueCols(cols);
        if (hasDuplicates) {
          const newColumnsMap = [...session.columnsMap];

          if (index > -1) {
            const newObj = { ...newColumnsMap[index], field: value };
            newColumnsMap.splice(index, 1, newObj);
            updateSession({
              columnsMap: newColumnsMap,
            });
          }

          return;
        }

        updateSession({ loading: true });

        const data = {
          key: importData.importType?.key,
          cols,
        };
        const { data: newColumns } = await remapImportDataService(
          importData.uploadSession?.session,
          data
        );
        updateSession({
          loading: false,
          columnsMap: Object.keys(newColumns).map((key) => ({
            ...newColumns[key],
            column: key,
          })),
        });
      } catch (err) {
        updateSession({ loading: false });
        error(err?.response?.data?.message);
      }
    },
    [
      checkUniqueCols,
      importData.mappedCols,
      importData.uploadSession?.session,
      session.columnsMap,
      importData.importType?.key,
      updateSession,
    ]
  );

  return {
    session,
    updateSessionQuery,
    onRemapChange,
    currentColumns,
    currentSession,
    headers,
    getSessionById,
  };
};

export default useMapData;
