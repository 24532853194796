/* eslint-disable react/prop-types */
import * as React from "react";
export const FormMultipleTypeIcon = ({
  rectFill = "#C9C9C9",
  pathFill = "#fff",
  width = 11,
  height = 11,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={width} height={height} rx={2.2} fill={rectFill} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.49 2.426a.46.46 0 0 1 0 .648L4.45 8.116a.46.46 0 0 1-.65 0L1.51 5.824a.458.458 0 1 1 .647-.648l1.968 1.967 4.718-4.717a.46.46 0 0 1 .648 0"
      fill={pathFill}
    />
  </svg>
);
