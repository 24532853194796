import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  Typography,
  Box,
  ClickAwayListener,
  Skeleton,
} from "@mui/material";
import { StyledTextField } from "../../components/TextFields/TextFields";
import StyledButton from "../../components/StyledButton";
import useStyles from "./styles";
import {
  CartTrashIcon,
  CrossIcon,
  CustomerIcon,
  InformationIcon,
  OutlinedPlusIcon,
  PenIcon,
  SearchIcon,
} from "../../components/Icons";
import { useDebounce, useOnBoardingTasks } from "../../helpers/hooks";
import {
  getCustomersService,
  updateCustomerService,
} from "../../services/customers";
import { error } from "../../utils/notifications";
import { useFieldArray } from "react-hook-form";
import {
  CART_TYPES,
  CREATED_ORDER_TYPES,
  defaultValues,
  DEFAULT_ORDER_DATA,
  DISCOUNT_NAMES,
  DISCOUNT_TYPES,
  ORDER_TYPES,
} from "./CartPage.constants";
import { getProductsService } from "../../services/products";
import ProductsHeader from "./components/ProductsTable/ProductsHeader";
import ProductsItem from "./components/ProductsTable/ProductsItem";
import ProductsSearchItem from "./components/ProductsSearchItem/ProductsSearchItem";
import OrderCartSwitcherTab from "./components/OrderCartSwitcherTab/OrderCartSwitcherTab";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { createSelector } from "reselect";
import lodash, { isEqual } from "lodash";
import { bool } from "prop-types";
import {
  createDraftAction,
  getDrafts,
  setFetchedAction,
  updateDraftAction,
} from "../../redux/actions/drafts";
import {
  draftsFetchedSelector,
  draftsListSelector,
} from "../../redux/selectors/drafts";
import { productsExistSelector } from "../../redux/selectors/products";
import { getCartDraftDataService } from "../../services/drafts";
import {
  getOrderByIdService,
  getOrderDuplicateByIdService,
} from "../../services/orders";
import AllProductsPopup from "./components/AllProductsPopup/AllProductsPopup";
import {
  calculateAvailable,
  containsEvery,
  getFormattedDate,
  removeEmptyValuesInObject,
  useAdmin,
} from "helpers/helpers";
import { currentUserSelector } from "../../redux/selectors/auth";
import {
  getDiscountsDictionary,
  getMSDDiscountSum,
  getProductsDictionary,
  getSingleMSD,
} from "../DiscountsPage/components/ApplyDiscountPage/components/MSDPage/MSDPage.helpers";
import { getRepsService } from "../../services/reps";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
  setEditTypeAction,
  setFormChangedAction,
} from "../../redux/actions/confirmDialogs";
import DeliveryFeeDialog from "../OrdersPage/components/DeliveryFeeDialog/DeliveryFeeDialog";
import { useThirdPartyActions } from "./useThirdPartyActions";
import { paytermsListSelector } from "../../redux/selectors/payterms";
import PaytermComponent from "../SettingsPage/components/SettingsTabs/PaytermsTab/PaytermComponent";
import StyledTooltip from "../../components/StyledTooltip";
import { useIntegrations } from "../SettingsPage/components/SettingsTabs/IntegrationsTab/Integrations.hooks";
import { useCart, useProductsLastOrders } from "./CartPage.hooks";
import AllCustomersPopup from "Pages/CreateRoutePage/components/AllCustomersPopup";
import { FreeCasesDialog, StyledLabel } from "components";
import { EmailLinkDialog, FreeCasesBlock, OrderNoteBlock } from "./components";
import pluralize from "pluralize";
import { updateUserFieldAction } from "redux/actions/auth";
import ContactAddDrawer from "components/ContactsSection/ContactPopup/ContactAddDrawer";
import { ProfileComponent } from "Pages/RepresentativesPage/components";
import { SecondCustomerStep } from "./components/SecondCustomerStep";
import OrderEmailDialog from "./components/OrderEmailDialog/OrderEmailDialog";
import { ThirdCustomerStep } from "./components/ThirdCustomerStep";
import moment from "moment";
import {
  CUSTOMER_STATUS_FILTERS,
  DATA_KEYS,
  PRODUCT_TYPE_INVENTORY,
} from "utils/constants";
import {
  getDraftOrderByIdService,
  getDraftOrderDuplicateByIdService,
} from "services/draft_orders";
import { ordersLoadingSelector } from "redux/selectors/orders";

const selector = createSelector(
  draftsListSelector,
  currentUserSelector,
  draftsFetchedSelector,
  productsExistSelector,
  paytermsListSelector,
  ordersLoadingSelector,
  (
    drafts,
    currentUser,
    draftsFetched,
    productsExist,
    paytermsList,
    ordersLoading
  ) => ({
    drafts,
    currentUser,
    draftsFetched,
    productsExist,
    paytermsList,
    ordersLoading,
  })
);

const CartPage = ({ isDuplicate, isDraftOrder }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [paymentStatus, setPaymentStatus] = useState("");
  const [emailLinkDialogData, setEmailLinkDialogData] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const isDuplicateDraft = isDuplicate && state?.duplicateDraft;

  const {
    drafts,
    currentUser,
    draftsFetched,
    productsExist,
    paytermsList,
    ordersLoading,
  } = useSelector(selector);

  const {
    cartType,
    formField,
    reset,
    isDirty,
    control,
    clearErrors,
    setError,
    setValue,
    handleSubmit,
    trigger,
    errors,
    manualDeliveryFee,
    setManualDeliveryFee,
    deliveryFee,
    setDeliveryFee,
    appliedOrderDiscount,
    calculatedTotal,
    totalOrderDiscount,
    orderId,
    setInitCustomerId,
    onSubmit,
    getAvailableDiscounts,
    cartState,
    freeCasesState,
    freeCasesCount,
    handleAddMSD,
    avoidUpd,
    handleGetFreeCaseProducts,
    freeProducts,
    setFreeProducts,
    freeCasesLoading,
    cartProductsWithFreeCaseProducts,
    discountsWithQuantity,
    setLoading,
    handleProspectWarning,
    handleResetCart,
    priceListId,
    calcProductPriceByPriceList,
    priceList,
    priceListLoading,
    priceListName,
  } = useCart({
    isDuplicate,
    isDraftOrder,
    isDuplicateDraft,
    paymentStatus,
    setEmailLinkDialogData,
    setIsButtonDisabled,
    currentUser,
  });

  const isAdmin = useAdmin();
  const { createOrderType } = formField;

  const productIds = useMemo(
    () =>
      formField?.products
        ?.filter((p) => !p?.deleted && p?.product)
        ?.map((p) => p?.product?.id || p?.id) || [],
    [formField?.products]
  );

  const { lastProductsOrders } = useProductsLastOrders({ productIds });

  const [customersSearchInput, setCustomersSearchInput] = useState("");
  const [productsSearchInput, setProductsSearchInput] = useState("");
  const customersSearchInputDebounced = useDebounce(customersSearchInput, 500);
  const productsSearchInputDebounced = useDebounce(productsSearchInput, 500);
  const [productsList, setProductsList] = useState([]);
  const [customersList, setCustomersList] = useState([]);
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [customersLoading, setCustomersLoading] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);
  const [allProductsOpen, setAllProductsOpen] = useState(false);
  const [allCustomersOpen, setAllCustomersOpen] = useState(false);
  const [applyDiscountOpen, setApplyDiscountOpen] = useState(false);
  const [totalDiscountOpen, setTotalDiscountOpen] = useState(false);
  const [fieldReset, setFieldReset] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);
  const [editContact, setEditContact] = useState(null);
  const [deliveryFeeOpen, setDeliveryFeeOpen] = useState(false);
  const [repsList, setRepsList] = useState({
    THIRD_PARTY: [],
    DIRECT: [],
    loading: false,
  });
  const [emailOpen, setEmailOpen] = useState(false);
  const [orderCompleted, setOrderCompleted] = useState(false);
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const openHeaderList = Boolean(anchorEl);
  const [isOpenPaymentTermModal, setOpenPaymentTermModal] = useState(false);
  const [selectedContactEmail, setSelectedContactEmail] = useState("");
  const [oldOrder, setOldOrder] = useState(null);
  const [isFetchable, setIsFetchable] = useState(true);

  const classes = useStyles({ customersSearchInput });

  const productsRef = useRef();
  const searchRef = useRef();

  const ordersCurrentTab = useSelector(({ orders }) => orders.currentTab);

  const hasQuickBooks = useMemo(
    () => !!currentUser?.quickBooksTokens,
    [currentUser.quickBooksTokens]
  );

  const hasChosenCustomerEmail = useMemo(() => {
    if (!formField?.contactId) return false;

    if (formField?.createOrderType === "THIRD_PARTY") return true;

    return (
      formField?.customer?.email ||
      formField?.customer?.contacts?.find(
        (contact) => contact?.id === formField?.contactId
      )?.email
    );
  }, [
    formField?.contactId,
    formField?.createOrderType,
    formField?.customer?.contacts,
    formField?.customer?.email,
  ]);

  const paytermsListActive = useMemo(
    () => paytermsList.filter((payterm) => payterm.status !== "INACTIVE"),
    [paytermsList]
  );

  const { thirdPartyRepsState, fetchThirdPartyReps } = useThirdPartyActions();
  const { list: thirdPartyRepsList } = useMemo(
    () => thirdPartyRepsState,
    [thirdPartyRepsState]
  );

  const handleFetchReps = useCallback(() => {
    setRepsList((prev) => ({ ...prev, loading: true }));
    getRepsService({
      active: true,
      roles: ["sales", "third_party"],
      sort_name: "asc",
    })
      .then(({ rows = [] }) => {
        const directReps = rows.filter((r) => r.role === "SALES");
        const thirdReps = rows.filter((r) => r.role === "THIRD_PARTY");
        setRepsList((prev) => ({
          ...prev,
          DIRECT: [...directReps],
          THIRD_PARTY: [...thirdReps],
          loading: false,
        }));
      })
      .catch((err) => {
        if (err?.message === "canceled") return;
        setRepsList((prev) => ({ ...prev, loading: false }));
        error("Reps fetching error");
      });
  }, []);

  useEffect(() => {
    if (avoidUpd) return;
    handleFetchReps();
  }, [
    avoidUpd,
    createOrderType,
    formField?.customer?.assignedRepresentatives,
    handleFetchReps,
  ]);

  const handleSetDefaultSalesId = useCallback(
    (customer, type) => {
      const activeList = customer?.assignedRepresentatives?.filter(
        (r) => r?.representative?.active
      );
      const reps = activeList?.filter(
        (r) => r?.representative?.role === "SALES"
      );

      const currentType = type || createOrderType;
      let repId = "";

      if (currentType === "DIRECT" && reps?.[0]?.representative?.id) {
        const hasRepInTheList = repsList[currentType]?.some(
          (r) => r.id === reps?.[0]?.representative?.id
        );
        repId = hasRepInTheList ? reps?.[0]?.representative?.id : "";
      } else if (
        currentType === "THIRD_PARTY" &&
        formField.lastAssignedThirdPartyRepresentativeId
      ) {
        repId = formField.lastAssignedThirdPartyRepresentativeId;
      } else {
        if (currentType === "DIRECT") {
          repId = repsList[currentType]?.length
            ? repsList[currentType][0]?.id
            : "";
        } else {
          if (currentType === "THIRD_PARTY") repId = "no_rep";
        }
      }
      setValue("salesId", repId, { shouldDirty: true });
      dispatch(setFetchedAction(true));
    },
    [
      createOrderType,
      dispatch,
      formField.lastAssignedThirdPartyRepresentativeId,
      repsList,
      setValue,
    ]
  );

  const selectCustomers = useCallback(
    (customer) => {
      const contactId =
        customer?.contacts?.find((contact) => contact?.defaultContact)?.id ||
        "";
      setCustomerDiscount(hasQuickBooks ? 0 : customer.percentDiscount);
      if (contactId) clearErrors("contactId");
      clearErrors("customer");
      setValue("customer", customer, { shouldDirty: true });
      setValue("contactId", contactId, { shouldDirty: true });

      handleSetDefaultSalesId(customer);

      setCustomersSearchInput("");
      setValue(
        "paymentTerms",
        customer?.paymentTermsDuplicate ||
          paytermsListActive.find((term) => term?.defaultTerm)
      );
      setAllCustomersOpen(false);
    },
    [
      clearErrors,
      hasQuickBooks,
      paytermsListActive,
      setValue,
      handleSetDefaultSalesId,
    ]
  );

  useEffect(() => {
    if (state?.customerState && !formField?.hasProceeded) {
      selectCustomers(state?.customerState);
      setValue("products", [], { shouldDirty: true });
      setValue("hasProceeded", true);
      const reps = state.customerState?.assignedRepresentatives?.filter(
        (r) => r?.representative?.role === "SALES" && r.representative.active
      );

      if (reps?.length) {
        setValue("salesId", reps[0].representative.id, {
          shouldDirty: true,
        });
        dispatch(setFetchedAction(true));
      }
    }
  }, [
    state?.customerState,
    selectCustomers,
    setValue,
    formField?.hasProceeded,
    state,
    dispatch,
    handleSetDefaultSalesId,
  ]);

  const formFieldDebounced = useDebounce(formField, 500);
  const formFieldFastDebounced = useDebounce(formField, 200);

  useEffect(() => {
    if (!orderId) {
      const storageFormField = JSON.parse(localStorage.getItem("drafts"));
      if (!isEqual(formFieldFastDebounced, storageFormField) && fieldReset) {
        localStorage.setItem("drafts", JSON.stringify(formFieldFastDebounced));
      }
    }
  }, [fieldReset, formFieldFastDebounced, orderId]);

  const readStorage = useCallback(
    (e) => {
      if (document.hasFocus()) return;
      if (e.key === "drafts") {
        const data = JSON.parse(e?.newValue);
        reset({ ...data });
      }
      if (e.key === DATA_KEYS.DRAFT_ACTIONS) {
        const data = JSON.parse(e?.newValue);
        const draftId = data?.draftId;
        if (draftId) {
          dispatch(getDrafts({ id: draftId, data: {} }));
        } else {
          dispatch(getDrafts({ id: null, data: {} }));
          reset({ ...defaultValues });
        }
      }
    },
    [dispatch, reset]
  );

  useEffect(() => {
    if (avoidUpd) return;
    if (orderId) dispatch(setFormChangedAction(isDirty));
  }, [isDirty, orderId, dispatch, avoidUpd]);

  useEffect(() => {
    if (avoidUpd) return;
    if (!orderId) {
      window.addEventListener("storage", readStorage);
    }
    if (orderId) dispatch(setEditTypeAction("cart"));

    return () => {
      window.removeEventListener("storage", readStorage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { fields } = useFieldArray({
    control,
    name: "products",
  });

  useEffect(() => {
    let didCancel = false;
    if (drafts?.id && !orderId) {
      getCartDraftDataService({
        customerId: drafts.data.customerId,
        productsData: drafts?.data?.productsData?.map(
          ({
            id,
            quantity,
            itemDiscountAmount,
            itemDiscountType,
            discountQty,
            type,
          }) => ({
            id,
            quantity,
            itemDiscountAmount,
            itemDiscountType,
            discountQty,
            type,
          })
        ),
      })
        .then((res) => {
          if (didCancel) return;
          if (!orderId) {
            setDeliveryFee(parseFloat(drafts?.data?.deliveryFee) || 0);
            setManualDeliveryFee(drafts?.data?.manualDeliveryFee || false);
          }
          setLoading(false);
          // dispatch(endLoadingOrders());

          const setFulfillBy = (fulfillBy) => {
            let date = fulfillBy;
            if (moment().diff(moment(fulfillBy), "days") > 0) {
              date = "";
            }
            return date;
          };

          const draftsFields = {
            customId: drafts.orderCustomId?.customId,

            salesId: isThirdParty
              ? res.lastAssignedThirdPartyRepresentativeId || ""
              : drafts.data.salesId || "",
            contactId: drafts.data.contactId || "",
            // products: initialProducts.discountedProducts || res.products,
            discount: drafts.data.discount || null,
            manufacturerDiscounts: drafts.data.manufacturerDiscounts || [],
            totalOrderDiscountAmount: drafts.data.totalOrderDiscountAmount || 0,
            totalOrderDiscountType:
              drafts.data.totalOrderDiscountType || "PERCENTAGE",
            productsData: drafts.data.productsData || [],
            customerId: drafts?.data?.customerId || "",
            note: drafts?.data?.note || "",
            manualDeliveryFee: drafts?.data?.manualDeliveryFee || false,
            deliveryFee: drafts?.data?.deliveryFee || 0,
            createOrderType:
              drafts?.data?.createOrderType || ORDER_TYPES[ordersCurrentTab],
            paymentTerms:
              drafts.data?.paymentTerms || res.customer?.paymentTermsDuplicate,
            fulfillBy: setFulfillBy(drafts.data?.fulfillBy),
            // fulfillBy: drafts.data?.fulfillBy,
            freeCasesProductsIds: drafts.data?.freeCasesProductsIds,
            tagIds: drafts?.data?.tagIds || [],
            orderTags: drafts?.data?.orderTags,
          };
          const noDraftData = !Object.keys(res).length;
          if (noDraftData) {
            reset({ ...formField, ...draftsFields });
          } else {
            reset({
              ...formField,
              ...res,
              products:
                res.products
                  ?.filter(
                    (p) =>
                      p.status !== "INACTIVE" &&
                      p.parentProduct?.status !== "INACTIVE"
                  )
                  .map((p) => {
                    return { ...p, isNewAdded: true };
                  }) ?? [],
              customer: res.customer || null,
              ...draftsFields,
            });
          }
          setFieldReset(true);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log("update draft error", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (currentUser.openDiscountDialogQty)
      freeCasesState.handleOpenFreeCasesDialog();
    if ((orderId || !drafts?.id) && draftsFetched) {
      setFieldReset(true);
    }
    if (!drafts?.id && !orderId && draftsFetched) setLoading(false);
    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, drafts?.id, orderId, state?.initialProducts, draftsFetched]);

  useEffect(() => {
    if (avoidUpd) return;
    if ((orderId || isDuplicate) && state?.editedOrderData) {
      dispatch(setFormChangedAction(true));
      reset({
        ...formField,
        ...state.editedOrderData,
      });
      setDeliveryFee(state.editedOrderData.deliveryFee);
      setManualDeliveryFee(state.editedOrderData.manualDeliveryFee);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, orderId, isDuplicate, state?.editedOrderData]);

  const calcAvailable = useCallback((product) => {
    const onHand = product?.inventory?.onHand;
    const allocated = product?.inventory?.allocated;

    return onHand - allocated;
  }, []);

  const isThirdParty = useMemo(
    () => createOrderType === "THIRD_PARTY",
    [createOrderType]
  );

  const productsAvailableLessThanMinimum = useCallback(
    (product) => {
      const available = calcAvailable(product);
      if (
        product?.type === PRODUCT_TYPE_INVENTORY.non_inventory ||
        product?.sellingOutOfStock ||
        isThirdParty
      )
        return false;

      return available < product?.minOrderQTY;
    },
    [calcAvailable, isThirdParty]
  );

  const handleCheckProduct = useCallback(
    (product) => {
      const isNonInventory =
        product?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

      if (product.isMultiple) {
        const filteredChildren = product.childProducts
          .filter(
            (childProduct) =>
              !formField?.products?.some(
                (prod) =>
                  prod?.id === childProduct?.id ||
                  prod?.product?.id === childProduct?.id
              )
          )
          .filter((prod) => {
            if (isThirdParty || isNonInventory) return true;
            return (
              ((!product.sellingOutOfStock &&
                prod.inventory?.onHand - prod.inventory?.allocated > 0) ||
                product.sellingOutOfStock) &&
              !productsAvailableLessThanMinimum(prod)
            );
          });

        if (containsEvery(filteredChildren, checkedProducts)) {
          const newChecked = [...checkedProducts];
          product.childProducts.forEach((childProduct) => {
            const index = newChecked.findIndex(
              (checkedCustomer) => checkedCustomer.id === childProduct.id
            );
            newChecked.splice(index, 1);
          });
          return setCheckedProducts([...newChecked]);
        }

        const addProducts = filteredChildren.map((childProduct) => {
          return {
            ...childProduct,
            quantity: childProduct.minOrderQTY || 1,
            parentProduct: product,
            itemDiscountType: "PERCENTAGE",
            itemDiscountAmount: 0,
            isNewAdded: true,
            discountQty: 0,
          };
        });

        const uniqueProducts = [
          ...new Map(
            [...checkedProducts, ...addProducts].map((item) => [
              item["id"],
              item,
            ])
          ).values(),
        ];

        return setCheckedProducts(uniqueProducts);
      }
      const index = checkedProducts.findIndex((item) => item.id === product.id);

      if (index > -1)
        return setCheckedProducts(
          checkedProducts.filter((item) => item.id !== product.id)
        );
      setCheckedProducts([
        ...checkedProducts,
        { ...product, quantity: product.minOrderQTY || 1 },
      ]);
    },
    [
      checkedProducts,
      formField.products,
      productsAvailableLessThanMinimum,
      isThirdParty,
    ]
  );

  useEffect(() => {
    if (avoidUpd) return;
    if (!customersSearchInputDebounced || !isFetchable) {
      return setCustomersList([]);
    }
    setCustomersLoading(true);
    getCustomersService({
      search: customersSearchInputDebounced,
      status: `["${CUSTOMER_STATUS_FILTERS.active}", "${CUSTOMER_STATUS_FILTERS.prospect}"]`,
      limit: 15,
    })
      .then((res) => {
        setCustomersLoading(false);
        setCustomersList(res.rows);
      })
      .catch((err) => {
        setCustomersLoading(false);
        if (err?.message === "canceled") return;
        error("Something went wrong.");
      });
  }, [customersSearchInputDebounced, avoidUpd, isFetchable]);

  useEffect(() => {
    if (avoidUpd) return;
    if (!productsSearchInputDebounced) {
      return setProductsList([]);
    }
    setProductsLoading(true);
    getProductsService({
      search: productsSearchInputDebounced,
      status: '["active", "inactive_for_customers"]',
      include_price_list_id: priceListId || null,
    })
      .then((res) => {
        setProductsLoading(false);
        setProductsList(res.rows);
      })
      .catch((err) => {
        setCustomersLoading(false);
        if (err?.message === "canceled") return;
        error("Something went wrong.");
      });
  }, [avoidUpd, priceListId, productsSearchInputDebounced]);

  const [hasChanged, setHasChanged] = useState(false);

  const handleUpdateCart = useCallback(() => {
    if (orderId) return;
    const {
      products,
      manufacturerDiscountIds,
      customer,
      tagIds,
      customId,
      ...fieldsData
    } = formFieldDebounced;

    const fieldsForCompare = {
      customerId: drafts?.data?.customerId || "",
      deliveryFee: drafts?.data?.deliveryFee || deliveryFee || 0,
      manualDeliveryFee: drafts?.data?.deliveryFee || false,
      salesId: drafts?.data?.salesId || "",
      contactId: drafts?.data?.contactId || "",
      productsData: drafts?.data?.productsData || [],
      discount: drafts?.data?.discount || null,
      manufacturerDiscounts: drafts?.data?.manufacturerDiscounts || [],
      totalOrderDiscountAmount: drafts?.data?.totalOrderDiscountAmount || 0,
      totalOrderDiscountType:
        drafts?.data?.totalOrderDiscountType || "PERCENTAGE",
      note: drafts?.data?.note || "",
      createOrderType:
        drafts?.data?.createOrderType || defaultValues.createOrderType,
      fulfillBy: drafts?.data?.fulfillBy || defaultValues.fulfillBy,
      orderTags: drafts?.data?.orderTags || defaultValues.orderTags,
      paymentTerms: drafts?.data?.paymentTerms || defaultValues.paymentTerms,
      freeCasesProductsIds: drafts?.data?.freeCasesProductsIds || null,
    };

    const productsData = formFieldDebounced.products.map((el) => {
      return {
        id: el.id,
        quantity: el.quantity,
        itemDiscountType: el.itemDiscountType,
        itemDiscountAmount: el.itemDiscountAmount,
        manufacturerDiscountIds: el.manufacturerDiscountIds || [],
        minOrderQTY: el.minOrderQTY,
        discountQty: el.discountQty,
        type: el.type,
      };
    });

    const fieldsEqual = lodash.isEqual(fieldsForCompare, {
      ...fieldsData,
      productsData,
    });

    if (!fieldsEqual && fieldReset) {
      setHasChanged(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drafts?.data, formFieldDebounced, fieldReset]);

  useEffect(() => {
    if (avoidUpd) return;
    if (draftsFetched) {
      handleUpdateCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldDebounced, draftsFetched]);

  const { onBoardingTasks } = useOnBoardingTasks();

  const handleContactChange = (event) => {
    setSelectedContactEmail(event);
  };

  useEffect(() => {
    if (avoidUpd || isAdmin || !document.hasFocus()) return;
    const isEditMode = !!orderId;
    if (
      draftsFetched &&
      hasChanged &&
      !isEditMode &&
      onBoardingTasks.shopping_cart
    ) {
      const productsData = formField.products?.map((product) => {
        return {
          id: product.id,
          quantity: product.quantity,
          manufacturerDiscountIds: product.manufacturerDiscountIds || [],
          itemDiscountAmount: product.itemDiscountAmount,
          itemDiscountType: product.itemDiscountType,
          minOrderQTY: product.minOrderQTY,
          discountQty: product.discountQty,
          type: product.type,
        };
      });
      const {
        discount,
        salesId,
        contactId,
        customer,
        manufacturerDiscounts,
        totalOrderDiscountAmount,
        totalOrderDiscountType,
        note,
        manualDeliveryFee,
        deliveryFee,
        createOrderType,
        paymentTerms,
        fulfillBy,
        orderTags,
      } = formFieldDebounced;

      const uploadDraft = {
        // type: "CART",
        data: {
          discount,
          salesId,
          contactId: contactId || "",
          customerId: customer?.id,
          customer,
          paymentTerms,
          manufacturerDiscounts,
          productsData,
          totalOrderDiscountAmount,
          totalOrderDiscountType,
          note,
          manualDeliveryFee,
          deliveryFee,
          createOrderType,
          fulfillBy,
          freeCasesProductsIds: freeProducts?.length
            ? freeProducts
                .filter((p) => p?.freeCaseChosen)
                .map((p) => ({ id: p?.id, discountQty: p?.discountQty }))
            : null,
          tagIds: orderTags?.length
            ? orderTags?.map((t) => (typeof t === "string" ? t : t?.id))
            : [],
          orderTags,
        },
      };

      if (ordersLoading) return;
      if (drafts?.id) {
        return dispatch(
          updateDraftAction({
            id: drafts.id,
            data: { data: uploadDraft.data },
          })
        );
      }
      dispatch(createDraftAction(uploadDraft));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formFieldDebounced,
    dispatch,
    drafts?.id,
    hasChanged,
    draftsFetched,
    // productsWithFreeCasesList,
  ]);

  useEffect(() => {
    if (avoidUpd) return;
    setValue(
      "tagIds",
      formField?.orderTags?.length
        ? formField.orderTags?.map((t) => (typeof t === "string" ? t : t?.id))
        : []
    );
  }, [avoidUpd, formField.orderTags, setValue]);

  const [customerDiscount, setCustomerDiscount] = useState(0);

  const calculatedDiscount = useCallback(() => {
    const discountsSum = formField.products?.reduce((prevSum, currProd) => {
      const discountAmount = parseFloat(currProd.itemDiscountAmount) || 0;
      const price =
        calcProductPriceByPriceList({ product: currProd }) * currProd.quantity;
      const itemsDisc =
        currProd.itemDiscountType === "PERCENTAGE"
          ? (discountAmount / 100) * price
          : discountAmount;
      const customerDisc = hasQuickBooks
        ? 0
        : (price *
            (formField.customer?.percentDiscount || customerDiscount || 0)) /
          100;

      const disc = customerDisc + itemsDisc;
      return prevSum + disc;
    }, 0);

    const priceListProducts = cartProductsWithFreeCaseProducts?.map(
      (product) => {
        return {
          ...product,
          wholesalePrice: calcProductPriceByPriceList({ product }),
          price: calcProductPriceByPriceList({ product }),
        };
      }
    );

    const productsFormMsd = priceListId
      ? priceListProducts
      : cartProductsWithFreeCaseProducts;

    const msdSum = productsFormMsd.reduce((sum, currProd) => {
      const msd = getSingleMSD(
        currProd,
        formField.manufacturerDiscounts,
        null,
        freeProducts
      );
      return msd + sum;
    }, 0);
    return discountsSum + msdSum;
  }, [
    calcProductPriceByPriceList,
    cartProductsWithFreeCaseProducts,
    customerDiscount,
    formField.customer?.percentDiscount,
    formField.manufacturerDiscounts,
    formField.products,
    freeProducts,
    hasQuickBooks,
    priceListId,
  ]);

  const handleAddProducts = useCallback(() => {
    setCheckedProducts([]);
    clearErrors("products");
    if (formField.products)
      return setValue("products", [...formField.products, ...checkedProducts], {
        shouldDirty: true,
      });
    setValue("products", [...checkedProducts], { shouldDirty: true });
  }, [checkedProducts, clearErrors, formField.products, setValue]);

  const handleRemoveOrderDiscounts = (discounts) => {
    const newDiscounts = formField.manufacturerDiscounts;

    discounts.forEach((discount) => {
      const index = formField.manufacturerDiscounts.findIndex(
        (manDisc) => manDisc.id === discount.id
      );
      if (index >= 0) {
        newDiscounts.splice(index, 1);
      }
    });
    setValue("manufacturerDiscounts", [...newDiscounts], {
      shouldDirty: true,
    });
    if (!newDiscounts.length) setValue("discount", null);
  };

  useEffect(() => {
    if (avoidUpd) return;
    if (!orderId || isAdmin) return;
    setLoading(true);
    if (!isDuplicate) {
      const getService = (id) =>
        isDraftOrder ? getDraftOrderByIdService(id) : getOrderByIdService(id);

      return getService(orderId).then((res) => {
        const deletedDiscounts = res.manufacturerDiscountDuplicates.filter(
          (d) => !d.manufacturerDiscountId
        );
        // const deletedProducts = res.products.filter((d) => !d.manufacturerId);
        setOrderCompleted(res.orderStatus === "COMPLETED");
        setDeliveryStatus(res.deliveryStatus);
        setPaymentStatus(res.paymentStatus);
        setDeliveryFee(state?.editedOrderData?.deliveryFee || res.deliveryFee);
        setManualDeliveryFee(
          state?.editedOrderData?.manualDeliveryFee || res.manualDeliveryFee
        );

        setInitCustomerId(res?.customer?.id);

        if (!state?.editedOrderData && deletedDiscounts.length) {
          handleOpenDialog({
            title: "Manufacturer Specific Discount",
            isIcon: true,
            cancelPos: "right",
            onConfirm: () => {
              handleRemoveOrderDiscounts(deletedDiscounts);
              // handleRemoveOrderProducts(deletedProducts);
              dispatch(setConfirmIsOpenAction(false));
            },
            onCancel: () => {
              navigate(-1);
            },
            text: (
              <span
                style={{
                  whiteSpace: "pre-wrap",
                  fontSize: "12px",
                  color: "#363531",
                }}
              >
                The discount(s){" "}
                <span style={{ color: "#47A06D" }}>
                  {deletedDiscounts.map((d) => d.name).join(",")}
                </span>{" "}
                has been deleted. {"\n"}If you proceed to edit the order this
                discount will be removed.
              </span>
            ),
          });
        }

        const type = Object.keys(DISCOUNT_TYPES).find(
          (key) => DISCOUNT_TYPES[key] === res.appliedDiscountsType
        );
        setCustomerDiscount(res.customerDiscount);
        const productsWithDiscountQty = res.products.map((p) => {
          const discountQty =
            p.manufacturerDiscountDuplicates.find((d) => d.quantity)
              ?.quantity || 0;
          return {
            ...p,
            discountQty,
            quantity: p.quantity - discountQty,
          };
        });

        const products = productsWithDiscountQty.filter(({ quantity }) => {
          return quantity > 0;
        });
        const freeCases = productsWithDiscountQty
          .filter(({ discountQty }) => {
            return !!discountQty;
          })
          .map(({ product, discountQty, id }) => ({
            id: product.id,
            discountQty,
            productId: id,
          }));

        const tags = res?.tags?.length ? res.tags.map((t) => t?.tag) : [];
        const resetData = {
          customId: res.customId,
          paymentTerms: res.paymentTermsDuplicate,
          manualDeliveryFee: res.manualDeliveryFee,
          deliveryFee: res.deliveryFee,
          customer: res.customer,
          createdAt: res.createdAt,
          contactId:
            res.contactDuplicate?.contactId ||
            res.customer?.contacts?.find((contact) => contact.defaultContact)
              ?.id ||
            "",
          salesId: res.salesDuplicate?.representativeId || "",
          products,
          manufacturerDiscountIds:
            [...res.manufacturerDiscountDuplicates] || [],
          discount: type ? { name: DISCOUNT_NAMES[type], type } : null,
          manufacturerDiscounts: [...res.manufacturerDiscountDuplicates],
          totalOrderDiscountAmount: res.totalOrderDiscountAmount || 0,
          totalOrderDiscountType: res.totalOrderDiscountType,
          note: res.note?.text,
          createOrderType:
            ordersCurrentTab === "3rd Party"
              ? "THIRD_PARTY"
              : res?.type || res?.orderType,
          fulfillBy: getFormattedDate(res.fulfillBy) || defaultValues.fulfillBy,
          tagIds: tags?.length ? tags.map((t) => t?.id) : [],
          orderTags: tags,
          freeCasesProductsIds: freeCases,
          totalRawAmount: res.totalRawAmount,
          totalAmount: res.totalAmount,
          totalDiscountValue: res.totalDiscountValue,
          orderCustomId: res?.orderCustomId,
          ...state?.editedOrderData,
        };
        setOldOrder(resetData);
        reset(resetData);
        setLoading(false);
      });
    }

    if (!drafts?.id && draftsFetched) {
      dispatch(createDraftAction({ data: {} }));
    }

    const getService = (id) =>
      isDuplicateDraft
        ? getDraftOrderDuplicateByIdService(id)
        : getOrderDuplicateByIdService(id);

    getService(orderId).then((res) => {
      const products = [
        ...res.products
          .filter(({ quantity }) => !!quantity)
          .map((p) => {
            return {
              ...p,
              itemDiscountAmount: 0,
              itemDiscountType: "PERCENTAGE",
            };
          }),
        ...res.orderProducts.map((p) => {
          return {
            ...p,
            deleted: true,
            itemDiscountAmount: 0,
            itemDiscountType: "PERCENTAGE",
          };
        }),
      ];

      const { orderProducts, ...resetVal } = { ...res, products };
      reset({
        ...formField,
        orderProducts,
        ...resetVal,
        ...state?.editedOrderData,
        customId: drafts?.orderCustomId?.customId,
        createOrderType:
          state?.duplicateOrder?.type ||
          state?.duplicateDraft?.orderType ||
          state?.editedOrderData?.createOrderType ||
          "",
      });
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearErrors, orderId, reset, setValue, isDuplicate, drafts?.id]);

  const [currentProductRef, setCurrentProductRef] = useState(false);
  const [textProduct, setTextProduct] = useState("");

  const productsRefs = useRef([]);

  const pricesRefs = useRef({});

  const productHighestPriceWidth = useMemo(() => {
    const highestPrice = fields.reduce(
      (max, item) => (item?.price > max?.price ? item : max),
      fields[0]
    );

    const productHighestPrice = pricesRefs.current[highestPrice?.id];

    if (productHighestPrice) {
      if (productHighestPrice?.offsetWidth < productHighestPrice?.scrollWidth) {
        return productHighestPrice?.offsetWidth;
      }
      return productHighestPrice?.offsetWidth + 8;
    }

    return 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, lastProductsOrders, formField]);

  const productsDropDown = useMemo(
    () =>
      productsSearchInput &&
      (productsList?.length ? (
        <>
          <StyledTooltip
            style={{ zIndex: 1401, whiteSpace: "pre-wrap" }}
            open={!!currentProductRef}
            title={textProduct}
            //modifiers={[
            //  {
            //    name: "offset",
            //    options: { offset: [10, 10] },
            //  },
            //]}
            placement="top"
          >
            <Paper
              className={classes.section}
              sx={{
                position: "absolute",
                zIndex: 1,
                width: "100%",
                left: 0,
              }}
            >
              <Stack
                sx={{
                  maxHeight: "50vh",
                  overflow: "auto",
                }}
              >
                {productsList.map((product) =>
                  product.isMultiple ? (
                    product.childProducts.map((child) => {
                      child.sellingOutOfStock = product?.sellingOutOfStock;
                      child.minOrderQTY = product?.minOrderQTY;
                      return (
                        <ProductsSearchItem
                          ref={(el) => (productsRefs.current[child.id] = el)}
                          onMouseEnter={() => {
                            if (productsAvailableLessThanMinimum(child)) {
                              setCurrentProductRef(child?.id);
                              setTextProduct(
                                `Cannot add this product to the cart.\n Items Available: ${calcAvailable(
                                  child
                                )}, Minimum Order QTY: ${product?.minOrderQTY}`
                              );
                            }
                          }}
                          onMouseLeave={() => {
                            setCurrentProductRef(false);
                            setTextProduct("");
                          }}
                          key={child.id}
                          product={{ ...child, parentProduct: product }}
                          name={product.name}
                          manufacturer={product.manufacturer}
                          handleCheckProduct={handleCheckProduct}
                          isChecked={checkedProducts.some(
                            (prod) => prod.id === child.id
                          )}
                          disabled={
                            formField.products?.some(
                              (prod) =>
                                prod.id === child.id ||
                                prod.product?.id === child.id
                            ) || productsAvailableLessThanMinimum(child)
                          }
                          outOfStock={
                            !isThirdParty &&
                            !product.sellingOutOfStock &&
                            child.inventory?.onHand -
                              child.inventory?.allocated <=
                              0
                          }
                          hideAvailable={isThirdParty}
                          planeView
                        />
                      );
                    })
                  ) : (
                    <ProductsSearchItem
                      ref={(el) => (productsRefs.current[product.id] = el)}
                      onMouseEnter={() => {
                        if (
                          productsAvailableLessThanMinimum(product) &&
                          !product?.isMultiple
                        ) {
                          setCurrentProductRef(product?.id);
                          setTextProduct(
                            `Cannot add this product to the cart.\n Items Available: ${calcAvailable(
                              product
                            )}, Minimum Order QTY: ${product?.minOrderQTY}`
                          );
                        }
                      }}
                      onMouseLeave={() => {
                        setCurrentProductRef(false);
                        setTextProduct("");
                      }}
                      key={product.id}
                      product={product}
                      name={product.name}
                      manufacturer={product.manufacturer}
                      handleCheckProduct={handleCheckProduct}
                      isChecked={checkedProducts.some(
                        (prod) => prod.id === product.id
                      )}
                      disabled={
                        formField.products?.some(
                          (prod) =>
                            prod.id === product.id ||
                            prod.product?.id === product.id
                        ) || productsAvailableLessThanMinimum(product)
                      }
                      outOfStock={
                        !isThirdParty &&
                        !product.sellingOutOfStock &&
                        product.inventory?.onHand -
                          product.inventory?.allocated <=
                          0
                      }
                      hideAvailable={isThirdParty}
                      planeView
                    />
                  )
                )}
              </Stack>
              <Box
                display="flex"
                justifyContent="flex-end"
                p="7px 16px 8.4px"
                alignItems="center"
              >
                <StyledButton
                  label="Cancel"
                  sx={{ height: "27px", mr: "8px" }}
                  color="cancel"
                  onClick={() => {
                    setProductsSearchInput("");
                    setCheckedProducts([]);
                    setProductsList([]);
                    setProductsSearchInput("");
                  }}
                  fontWeight="600"
                  fontSize="10px"
                />
                <StyledButton
                  disabled={isAdmin}
                  label="Add"
                  variant="contained"
                  sx={{ height: "27px" }}
                  fontWeight="600"
                  fontSize="10px"
                  onClick={() => {
                    setProductsSearchInput("");
                    setProductsList([]);
                    handleAddProducts();
                  }}
                />
              </Box>
            </Paper>
          </StyledTooltip>
        </>
      ) : (
        <Paper
          className={classes.section}
          sx={{
            position: "absolute",
            zIndex: 1,
            width: "100%",
            left: 0,
            color: "rgb(156, 156, 148)",
            maxHeight: "50vh",
            overflow: "auto",
          }}
        >
          <MenuItem sx={{ color: "rgb(156, 156, 148)" }}>
            No products found
          </MenuItem>
        </Paper>
      )),
    [
      productsSearchInput,
      productsList,
      currentProductRef,
      textProduct,
      classes.section,
      isAdmin,
      handleCheckProduct,
      checkedProducts,
      formField.products,
      productsAvailableLessThanMinimum,
      isThirdParty,
      calcAvailable,
      handleAddProducts,
    ]
  );

  const customersDropDown = useMemo(
    () => (
      <Paper
        className={classes.section}
        sx={{
          position: "absolute",
          zIndex: 10,
          width: "100%",
          left: 0,
          maxHeight: "50vh",
          overflow: "auto",
          top: "50px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "48px",
            display: "flex",
            alignItems: "center",
            gap: 1.25,
            borderBottom: "0.5px solid #D5D9D9",
            pl: 1.5,
            cursor: "pointer",
          }}
          onClick={() => navigate("/customers/new")}
        >
          <OutlinedPlusIcon size="14" />
          <Typography fontSize={13} fontWeight={300} color="#5F6267">
            Add new
          </Typography>
        </Box>

        {customersList.map((customer) => {
          const isMissingInfo = customer?.missingFields;
          return (
            <ClickAwayListener
              key={customer.id}
              onClickAway={(event) => {
                if (
                  searchRef.current &&
                  !searchRef.current.contains(event.target)
                ) {
                  setIsFetchable(false);
                  setCustomersList([]);
                }
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "48px",
                  display: "flex",
                  borderBottom: "0.5px solid #D5D9D9",
                  cursor: isMissingInfo?.length ? "auto" : "pointer",
                }}
                onClick={() => {
                  if (isMissingInfo.length) return;
                  selectCustomers(customer);
                }}
              >
                {isMissingInfo?.length ? (
                  <StyledTooltip
                    arrow
                    title={
                      <Box textAlign="left">
                        <Typography fontSize={12}>Missing info:</Typography>
                        {isMissingInfo?.map((info) => (
                          <Typography key={info} fontSize={12} fontWeight={300}>
                            {info}
                          </Typography>
                        ))}
                      </Box>
                    }
                    placement="top"
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: { offset: [0, -8] },
                        },
                      ],
                    }}
                  >
                    <Box
                      sx={{
                        width: "36px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      display="flex"
                      alignItems="center"
                    >
                      <InformationIcon size="10" stroke="#FF6969" />
                    </Box>
                  </StyledTooltip>
                ) : (
                  <Box
                    sx={{
                      width: "36px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CustomerIcon color="#5F6267" width={12.63} height={10.5} />
                  </Box>
                )}
                <Box
                  sx={{
                    width: "calc(100% - 36px)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Box display="flex" alignItems="center" gap={0.5}>
                    <Typography
                      fontSize={13}
                      fontWeight="600"
                      color="#5F6267"
                      noWrap
                    >
                      {`${customer.displayName || customer.name} (ID: ${
                        customer.customId?.customId
                      })`}
                    </Typography>
                    {customer.status === CUSTOMER_STATUS_FILTERS.prospect && (
                      <StyledLabel text="prospect" />
                    )}
                  </Box>
                  <Typography
                    color="#5F6267"
                    fontSize={13}
                    fontWeight="300"
                    noWrap
                  >
                    {customer.shippingAddress?.formatted_address || "-"}
                  </Typography>
                </Box>
              </Box>
            </ClickAwayListener>
          );
        })}
      </Paper>
    ),
    [classes.section, customersList, navigate, selectCustomers]
  );

  const { handleOpenDeliveryFeeWarning, loading } = useIntegrations();

  const onTrySubmit = useCallback(
    (data) => {
      const isProspect =
        formField.customer?.status === CUSTOMER_STATUS_FILTERS.prospect;
      if (isProspect) {
        handleProspectWarning({
          onProceed: () => onSubmit(data),
          onCancel: () => setIsButtonDisabled(false),
        });
        return;
      }

      handleOpenDeliveryFeeWarning({
        buttonLabel: `Turn on and ${orderId ? "update" : "create"} order`,
        manualDeliveryFee,
        onProceed: () => onSubmit(data),
        isCreatingOrder: true,
      });
      setIsButtonDisabled(true);
    },
    [
      formField.customer?.status,
      handleOpenDeliveryFeeWarning,
      orderId,
      manualDeliveryFee,
      handleProspectWarning,
      onSubmit,
    ]
  );

  const handleOpenAdjustingDialog = ({ discounts, onConfirm }) => {
    handleOpenDialog({
      title: "Manufacturer discounts",
      text: (
        <>
          {`Adjusting the product quantity will remove the\nfollowing discounts.`}
          <Box mt="15px">
            {discounts?.map((discount) => {
              return (
                <React.Fragment key={discount.id}>
                  <span style={{ fontWeight: "400" }}>
                    - {discount?.manufacturer?.name}
                  </span>
                  |<span> {discount?.name}</span>
                </React.Fragment>
              );
            })}
          </Box>
        </>
      ),
      btnColor: "primary",
      cancelPos: "right",
      onConfirm,
    });
  };

  const productDict = getProductsDictionary(
    cartProductsWithFreeCaseProducts,
    formField.manufacturerDiscounts
  );

  const handleCheckRemove = (prod) => {
    const oneLengthDiscountIds = Object.keys(productDict).filter(
      (discId) => productDict[discId].length === 1
    );

    const oneLengthDiscounts = oneLengthDiscountIds.map((id) =>
      formField.manufacturerDiscounts.find((discount) => discount.id === id)
    );

    const matchedDiscs = oneLengthDiscounts.filter((discount) =>
      productDict[discount.id].find((formDisc) => formDisc.id === prod.id)
    );

    if (matchedDiscs.length)
      return handleOpenDialog({
        title: "Delete product?",
        text: `Are you sure you want to proceed? The following discounts will be deleted: ${matchedDiscs
          .map((disc) => disc.name)
          .join(", ")}`,
        onConfirm: () => {
          handleRemoveOrderProduct(prod);
          handleRemoveOrderDiscounts(matchedDiscs);
        },
      });

    const minQDiscs = discDict[prod.id].filter(
      (disc) => disc.requirements !== "NONE"
    );

    const notMatchedByMOQorMPAids = minQDiscs
      .map((discount) =>
        Object.keys(productDict).filter((key) => {
          if (discount.id !== key) return;
          // get sum of current discounted products qty
          const qtySum = productDict[key].reduce(
            (prevSum, currProd) => prevSum + (currProd.quantity ?? 0),
            0
          );

          // get sum of current discounted products price

          const currentPrice =
            prod.quantity *
            (calcProductPriceByPriceList({ product: prod }) || 0);

          // if reduced qty by 1 or price by 1 amount of prod price, will it violate discount's requirements?
          return (
            qtySum - prod.quantity < discount.minQuantity ||
            currentPrice -
              (calcProductPriceByPriceList({ product: prod }) || 0) *
                prod.quantity <
              discount.minPurchaseAmount
          );
        })
      )
      .flat();

    if (!matchedDiscs.length && !notMatchedByMOQorMPAids.length) {
      return setValue(
        "products",
        formField.products.filter((product) => product.id !== prod.id),
        { shouldDirty: true }
      );
    }

    const notMatchedByMOQorMPA = notMatchedByMOQorMPAids.map((id) =>
      formField.manufacturerDiscounts.find((discount) => discount.id === id)
    );

    const affectedProducts = notMatchedByMOQorMPAids.flatMap(
      (id) => productDict[id]
    );
    const freeCaseProductsToDelete = freeProducts.filter(
      (freeCaseProd) =>
        freeCaseProd.freeCaseChosen &&
        affectedProducts.some(({ id }) => id === freeCaseProd.id)
    );

    handleOpenAdjustingDialog({
      discounts: notMatchedByMOQorMPA,
      onConfirm: () => {
        handleRemoveFreeCaseProducts(freeCaseProductsToDelete);
        handleRemoveOrderDiscounts(notMatchedByMOQorMPA);
        setValue(
          "products",
          formField.products.filter((product) => product.id !== prod.id),
          { shouldDirty: true }
        );
      },
    });
  };

  const handleRemoveOrderProduct = (prod) => {
    setValue(
      "products",
      formField.products.filter((product) => product.id !== prod.id),
      { shouldDirty: true }
    );
  };

  // const handleRemoveOrderProducts = (prods) => {
  //   const newProducts = formField.products;

  //   prods.forEach((prod) => {
  //     const index = formField.products.findIndex(
  //       (formProd) => formProd.id === prod.id
  //     );
  //     if (index >= 0) {
  //       newProducts.splice(index, 1);
  //     }
  //   });

  //   setValue("products", newProducts, { shouldDirty: true });
  // };

  const handleGetQuantity = () => {
    let total = 0;
    formField?.products?.map((obj) => {
      total += obj.quantity;
    });
    return total;
  };

  useEffect(() => {
    if (avoidUpd) return;
    setCheckedProducts([]);
  }, [allProductsOpen, avoidUpd]);

  // useEffect(() => {
  //   if (avoidUpd || !formField.customer) return;
  //   if (isThirdParty) {
  //     setValue("salesId", thirdPartyRepsList[0]?.id || "");
  //   }
  // }, [
  //   isThirdParty,
  //   setValue,
  //   thirdPartyRepsList,
  //   avoidUpd,
  //   formField?.customer,
  // ]);

  const deliveryAmount = useMemo(() => {
    const deliveryFeeType = currentUser?.deliveryFeeType ?? "";
    const deliveryFeeAmount = currentUser?.deliveryFeeAmount ?? 0;

    return deliveryFeeType === "PERCENTAGE"
      ? (deliveryFeeAmount / 100) * calculatedTotal
      : deliveryFeeAmount;
  }, [
    calculatedTotal,
    currentUser?.deliveryFeeAmount,
    currentUser?.deliveryFeeType,
  ]);

  const handleApplyDiscount = (type) => {
    setApplyDiscountOpen(false);
    if (type === "total") return setTotalDiscountOpen(true);
    const manufacturers = [
      ...new Map(
        formField.products
          .filter((p) =>
            !isDraftOrder &&
            !!orderId &&
            !isDuplicate &&
            paymentStatus !== "PENDING"
              ? p.isNewAdded
              : p
          )
          .map((item) => [
            item.manufacturer?.id ||
              item.parentProduct?.manufacturer?.id ||
              item.manufacturerId,
            item.manufacturer ||
              item.parentProduct?.manufacturer || {
                id: item.manufacturerId,
                name: item.manufacturerName,
              },
          ])
      ).values(),
    ];

    const priceListProducts = formField.products.map((product) => {
      return {
        ...product,
        wholesalePrice: calcProductPriceByPriceList({ product }),
        price: calcProductPriceByPriceList({ product }),
        oldWholesalePrice: product.wholesalePrice,
      };
    });

    navigate(`/cart/discount/${type}`, {
      state: {
        manufacturers,
        customerId: formField.customer?.id,
        customer: formField.customer,
        contactId: formField.contactId,
        products: priceListId ? priceListProducts : formField.products,
        manufacturerDiscounts: formField.manufacturerDiscounts,
        isEdit: !!orderId || isDuplicate,
        orderId,
        isDuplicate,
        discount: formField.discount,
        isPayed: !!orderId && paymentStatus !== "PENDING",
        deliveryFee,
        manualDeliveryFee,
        createOrderType: formField.createOrderType,
        isDraftOrder,
        priceList: priceListId ? priceList : null,
      },
    });
    dispatch(setFormChangedAction(false));
  };

  const handleApplyTotalDiscount = (amount, type) => {
    setValue("totalOrderDiscountAmount", amount, { shouldDirty: true });
    setValue("totalOrderDiscountType", type, { shouldDirty: true });
    setValue(
      "discount",
      {
        name: "Invoice Total Discount",
        type: "total",
      },
      { shouldDirty: true }
    );

    setTotalDiscountOpen(false);
  };

  const handleDiscountsBlock = (type) => {
    const priceListProducts = cartProductsWithFreeCaseProducts?.map(
      (product) => {
        return {
          ...product,
          wholesalePrice: calcProductPriceByPriceList({ product }),
          price: calcProductPriceByPriceList({ product }),
        };
      }
    );

    const productsFormMsd = priceListId
      ? priceListProducts
      : cartProductsWithFreeCaseProducts;

    const discountRowName = (discount) => (
      <Box className={classes.discountRowName}>
        <Typography
          sx={{
            fontSize: "14px",
            color: "#707070",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            position: "relative",
            left: "20px",
          }}
        >
          {formField.discount?.name}
        </Typography>
        <Typography className={classes.discountTotal}>
          -$
          {(
            appliedOrderDiscount ||
            getMSDDiscountSum(
              productsFormMsd,
              [discount],
              null,
              freeProducts
            ) ||
            0
          ).toFixed(2)}
        </Typography>
      </Box>
    );

    const discountRowText = (discount) => (
      <Box key={discount.id} mt="-2px">
        <Typography fontSize="12px" fontWeight="400" color="#9C9C94">
          {discount?.manufacturer?.name}
        </Typography>
        <Typography
          key={discount.id}
          fontSize="12px"
          fontWeight="400"
          color="#9C9C94"
        >
          {discount.name}
        </Typography>
      </Box>
    );

    if (type === "manufacturer") {
      return formField?.manufacturerDiscounts?.map((discount) => (
        <Box key={discount.id} className={classes.discountRow}>
          {discountRowName(discount)}
          <IconButton
            disabled={isAdmin}
            sx={{ position: "absolute", right: 11, bottom: 0 }}
            onClick={() => handleRemoveDiscount(discount?.id)}
          >
            <CartTrashIcon height="15.82" width="14.23" />
          </IconButton>
          <Box className={classes.discountRowText}>
            {discountRowText(discount)}
          </Box>
        </Box>
      ));
    } else {
      return (
        <Box className={classes.discountRow}>
          {discountRowName()}
          <IconButton
            disabled={isAdmin}
            sx={{ position: "absolute", right: 11, bottom: 0 }}
            onClick={() => handleRemoveDiscount()}
          >
            <CartTrashIcon height="15.82" width="14.23" />
          </IconButton>
          <Box className={classes.discountRowText}>
            {formField?.manufacturerDiscounts?.map((discount) =>
              discountRowText(discount)
            )}
          </Box>
        </Box>
      );
    }
  };

  const handleRemoveDiscount = (id) => {
    if (id) {
      freeCasesState.setDelManufacturerDiscounts();
      const manufacturerDiscounts = formField?.manufacturerDiscounts?.filter(
        (discount) => discount.id !== id
      );

      if (!manufacturerDiscounts?.length)
        setValue("discount", null, { shouldDirty: true });

      setValue("manufacturerDiscounts", manufacturerDiscounts, {
        shouldDirty: true,
      });

      const resetFreeCaseProducts = productDict[id]?.map((p) => ({
        ...p,
        discountQty: 0,
        freeCaseChosen: false,
      }));
      setFreeProducts(resetFreeCaseProducts || []);
    } else {
      setValue("discount", null, { shouldDirty: true });
      setValue("manufacturerDiscounts", [], { shouldDirty: true });
    }

    setValue("manufacturerDiscountIds", [], { shouldDirty: true });
    setValue("manufacturerDiscountDuplicates", [], { shouldDirty: true });

    setValue("totalOrderDiscountAmount", 0, { shouldDirty: true });
    setValue("totalOrderDiscountType", "PERCENTAGE", { shouldDirty: true });
    const resetProducts = formField.products.map((product) => {
      const newIds =
        product.manufacturerDiscountDuplicates?.filter(
          ({ discountDuplicateId }) => discountDuplicateId !== id
        ) || [];
      return {
        ...product,
        itemDiscountAmount: 0,
        itemDiscountType: "PERCENTAGE",
        manufacturerDiscountIds: newIds,
        manufacturerDiscountDuplicates: newIds,
        discountQty:
          newIds.length !== product.manufacturerDiscountDuplicates?.length
            ? 0
            : product.discountQty,
      };
    });
    setValue("products", resetProducts, { shouldDirty: true });
  };

  const handleAddDiscount = () => {
    if (!formField.discount) return setApplyDiscountOpen(true);
    handleApplyDiscount(formField.discount.type);
  };

  const checkSellingOutStock = useCallback(
    (product) => {
      if (
        isThirdParty ||
        product?.sellingOutOfStock ||
        product?.parentProduct?.sellingOutOfStock
      )
        return false;

      return calcAvailable(product) < product.quantity;
    },
    [calcAvailable, isThirdParty]
  );

  const deliveryCapAmount = useMemo(() => {
    if (!currentUser.deliveryFeePriceCap) return deliveryAmount;
    return calculatedTotal - totalOrderDiscount >=
      currentUser.deliveryFeePriceCapAmount
      ? 0
      : deliveryAmount;
  }, [
    currentUser.deliveryFeePriceCap,
    currentUser.deliveryFeePriceCapAmount,
    deliveryAmount,
    calculatedTotal,
    totalOrderDiscount,
  ]);

  const totalCustomerDiscount = useMemo(
    () =>
      (hasQuickBooks
        ? 0
        : ((formField.customer?.percentDiscount || customerDiscount) / 100) *
          calculatedTotal
      )?.toFixed(2),
    [
      hasQuickBooks,
      formField.customer?.percentDiscount,
      customerDiscount,
      calculatedTotal,
    ]
  );

  const handleAddContact = (contact, isEdit) => {
    const isDefaultContact = contact?.defaultContact;

    let contacts = [];

    const newContacts = [...formField?.customer?.contacts];
    const index = newContacts.findIndex(
      (contactItem) =>
        (contact.id && contactItem.id === contact.id) ||
        (contact.fid && contactItem.fid === contact.fid)
    );

    delete contact?.fid;

    if (isEdit && index > -1) {
      newContacts.splice(index, 1, contact);

      contacts = newContacts.map((contact) =>
        removeEmptyValuesInObject(contact)
      );

      setEditContact(null);
    } else {
      delete contact?.id;
      const tempContacts = [
        ...(isDefaultContact
          ? formField?.customer?.contacts.map((contact) => ({
              ...contact,
              defaultContact: false,
            }))
          : formField?.customer?.contacts ?? []),
        contact,
      ];
      contacts = tempContacts.map((contact) =>
        removeEmptyValuesInObject(contact)
      );
    }

    updateCustomerService({ contacts }, formField.customer.id).then((res) => {
      setValue("customer", res, { shouldDirty: true });
      setValue(
        "contactId",
        res?.contacts?.find((c) => c?.name === contact?.name)?.id ??
          res?.contacts?.[0]?.id,
        { shouldDirty: true }
      );
      clearErrors("contactId");
    });
  };

  const handleOpenDialog = ({
    title,
    text,
    onConfirm,
    btnColor,
    cancelPos,
    isIcon = false,
    onCancel = () => {},
  }) => {
    dispatch(
      openConfirmDialogAction({
        title,
        text,
        isIcon,
        buttons: (
          <>
            <Button
              sx={{
                width: "98px",
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                fontSize: "13px",
                height: "28px",
                ml: cancelPos === "right" && "auto",
              }}
              variant="outlined"
              onClick={() => {
                onCancel();
                dispatch(setConfirmIsOpenAction(false));
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: "98px",
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
              }}
              color={btnColor || "confirmDelete"}
              onClick={() => {
                onConfirm();
                dispatch(setConfirmIsOpenAction(false));
              }}
              variant="contained"
            >
              Confirm
            </Button>
          </>
        ),
      })
    );
  };

  const handleCheckRemoveCustomer = (withDeleteNotAvailable = false) => {
    const customerDiscounts = formField.manufacturerDiscounts.filter(
      (disc) => disc.eligibility === "CUSTOMERS"
    );

    const deletedProducts = formField?.orderProducts?.length
      ? formField.orderProducts.map((item) => item.id)
      : [];

    const filteredDeletedProducts = formField?.products?.filter(
      (item) => !deletedProducts.includes(item?.id)
    );

    const filteredProducts = isDuplicate
      ? filteredDeletedProducts
      : filteredDeletedProducts?.filter((product) => product?.product);

    const productsList = withDeleteNotAvailable
      ? filteredProducts
      : formField.products;

    const manufacturerDiscounts = formField.manufacturerDiscounts.filter(
      (manDisc) =>
        !customerDiscounts.some((custDisc) => custDisc.id === manDisc.id)
    );

    const products = productsList.map((product) => {
      return {
        ...product,
        itemDiscountAmount: 0,
        itemDiscountType: "PERCENTAGE",
        manufacturerDiscountIds: [],
        manufacturerDiscountDuplicates: [],
      };
    });

    if (orderId) {
      return reset({
        ...formField,
        products,
        customer: "",
        contactId: "",
        salesId: "",
        customerId: "",
        discount: null,
        manufacturerDiscounts: [],
        paymentTerms: {},
        totalOrderDiscountAmount: defaultValues.totalOrderDiscountAmount,
        totalOrderDiscountType: defaultValues.totalOrderDiscountType,
      });
    }

    if (customerDiscounts.length)
      return handleOpenDialog({
        title: "Delete assigned customer?",
        text: `Are you sure you want to proceed? This action will remove the assigned customer and the following discounts: ${customerDiscounts
          .map((d) => d.name)
          .join(", ")}.`,
        onConfirm: () => {
          setCustomerDiscount(0);

          reset({
            ...formField,
            customer: "",
            contactId: "",
            salesId: "",
            customerId: "",
            paymentTerms: {},
            manufacturerDiscounts,
            discount: manufacturerDiscounts.length ? formField.discount : null,
            products,
            totalOrderDiscountAmount: defaultValues.totalOrderDiscountAmount,
            totalOrderDiscountType: defaultValues.totalOrderDiscountType,
          });
        },
      });

    setCustomerDiscount(0);
    setTimeout(() => {
      if (searchRef.current) {
        searchRef.current.focus();
      }
    }, 100);
    reset({
      ...formField,
      customer: "",
      contactId: "",
      salesId: "",
      customerId: "",
      manufacturerDiscounts: [],
      discount: null,
      products,
      paymentTerms: {},
      totalOrderDiscountAmount: defaultValues.totalOrderDiscountAmount,
      totalOrderDiscountType: defaultValues.totalOrderDiscountType,
    });
    navigate("/cart", { state: {} });
  };

  const onCheckProductsForAbility = () => {
    if (!orderId || !priceListId) return handleCheckRemoveCustomer();
    const products = formField?.orderProducts?.length
      ? formField.orderProducts
      : formField.products.filter((product) => !product.product);

    const isSingleProduct = products.length === 1;

    const text = isSingleProduct
      ? `Changing the customer will remove ${products[0].name} from the order, as it's no longer available and the price list no longer applies. Are you sure you want to proceed?`
      : "Changing the customer will remove certain products from the order, as they are no longer available and the price list no longer applies. Are you sure you want to proceed?";

    if (products.length)
      return handleOpenDialog({
        title: "Update customer?",
        text,
        onConfirm: () => {
          handleCheckRemoveCustomer(true);
        },
      });

    return handleCheckRemoveCustomer();
  };

  const discDict = useMemo(
    () =>
      getDiscountsDictionary({
        productsList: cartProductsWithFreeCaseProducts,
        checkedDiscounts: formField.manufacturerDiscounts,
        isEdit: !!orderId,
      }),
    [cartProductsWithFreeCaseProducts, formField.manufacturerDiscounts, orderId]
  );

  const handleSwitchFreeCaseItem = (item) => {
    const tempList = [...freeProducts];
    const foundIndex = tempList.findIndex((product) => product?.id === item.id);

    const formFieldIndex = formField.products.findIndex(
      ({ id, product }) => id === item?.id || product?.id === item?.id
    );

    tempList.splice(foundIndex, 1, {
      ...item,
      freeCaseChosen: !item?.freeCaseChosen,
      discountQty: 0,
    });
    setFreeProducts(tempList);
    if (formFieldIndex > -1)
      setValue(`products.${formFieldIndex}.discountQty`, 0);
  };

  const handleUpdateFreeCaseProduct = (product) => {
    freeCasesState.handleOpenFreeCasesDialog(product);
    handleGetFreeCaseProducts();
  };

  const handleRemoveFreeCaseProduct = (item) => {
    handleSwitchFreeCaseItem(item);
  };

  const handleBulkRemoveFreeCaseProducts = (items) => {
    const itemIds = items.map(({ id }) => id);
    let tempList = [...freeProducts]?.map((item) => {
      if (itemIds.includes(item?.id)) {
        return {
          ...item,
          freeCaseChosen: false,
          discountQty: 0,
        };
      }
      return item;
    });

    setFreeProducts([...tempList]);

    items?.forEach((item) => {
      const formFieldIndex = formField?.products?.findIndex(
        ({ id, product }) => id === item?.id || product?.id === item?.id
      );

      if (formFieldIndex > -1)
        setValue(`products.${formFieldIndex}.discountQty`, 0);
    });
  };

  const handleRemoveFreeCaseProducts = (items) => {
    const newFreeCaseIds = (formField.freeCasesProductsIds ?? [])?.filter(
      ({ id }) => !items.some((i) => i.id === id)
    );
    handleBulkRemoveFreeCaseProducts(items);
    setValue("freeCasesProductsIds", [...newFreeCaseIds]);
  };

  const handleSubtractQty = (product, qty, index) => {
    if (qty <= 1) return;

    const minQDiscs = discDict[product.id].filter(
      (disc) => disc.requirements !== "NONE"
    );

    const notMatchedByMOQorMPAids = minQDiscs
      .map((discount) =>
        Object.keys(productDict).filter((key) => {
          if (discount.id !== key) return;
          // get sum of current discounted products qty
          const qtySum = productDict[key].reduce(
            (prevSum, currProd) => prevSum + (currProd.quantity ?? 0),
            0
          );

          // get sum of current discounted products price

          const currentPrice =
            product.quantity * (calcProductPriceByPriceList({ product }) || 0);
          // if reduced qty by 1 or price by 1 amount of prod price, will it violate discount's requirements?
          return (
            qtySum - 1 < discount.minQuantity ||
            currentPrice - (calcProductPriceByPriceList({ product }) || 0) <
              discount.minPurchaseAmount
          );
        })
      )
      .flat();
    if (!notMatchedByMOQorMPAids.length)
      return setValue(`products.${index}.quantity`, qty - 1, {
        shouldDirty: true,
      });

    const affectedProducts = notMatchedByMOQorMPAids.flatMap(
      (id) => productDict[id]
    );
    const freeCaseProductsToDelete = freeProducts.filter(
      (freeCaseProd) =>
        freeCaseProd.freeCaseChosen &&
        affectedProducts.some(({ id }) => id === freeCaseProd.id)
    );
    const notMatchedByMOQorMPA = notMatchedByMOQorMPAids.map((id) =>
      formField.manufacturerDiscounts.find((discount) => discount.id === id)
    );
    handleOpenAdjustingDialog({
      discounts: notMatchedByMOQorMPA,
      onConfirm: () => {
        handleRemoveFreeCaseProducts(freeCaseProductsToDelete ?? []);
        handleRemoveOrderDiscounts(notMatchedByMOQorMPA);
        setValue(`products.${index}.quantity`, qty - 1, { shouldDirty: true });
      },
    });
  };

  const onEmailSubmit = () => {
    setEmailOpen(true);
  };

  const handleSendEmail = (email) => {
    onTrySubmit({ ...formField, email });
    setEmailOpen(false);
    setIsButtonDisabled(true);
  };

  const handleCreateWithNew = () => {
    onTrySubmit({ ...formField, createType: CREATED_ORDER_TYPES[0] });
    setIsButtonDisabled(true);
  };

  const handleCreateWithEmailLink = () => {
    onTrySubmit({ ...formField, createType: CREATED_ORDER_TYPES[1] });
    setIsButtonDisabled(true);
  };

  const handleSaveAsDraft = () => {
    onTrySubmit({ ...formField, createType: CREATED_ORDER_TYPES[2] });
    setIsButtonDisabled(true);
  };

  const handleUpdateDraft = () => {
    onTrySubmit({ ...formField, createType: CREATED_ORDER_TYPES[3] });
    setIsButtonDisabled(true);
  };

  const getOutOfStock = (product, index) => {
    const available = calculateAvailable(
      product.inventory?.onHand || product.product?.inventory?.onHand,
      product.inventory?.allocated || product.product?.inventory?.allocated
    );
    const qty = formField?.products?.[index]?.quantity - product.quantity;
    const editOrderCondition = orderId ? product.isNewAdded : true;
    return (
      !isThirdParty &&
      editOrderCondition &&
      !product?.sellingOutOfStock &&
      !product?.product?.sellingOutOfStock &&
      !product?.parentProduct?.sellingOutOfStock &&
      !product?.product?.parentProduct?.sellingOutOfStock &&
      available - qty <= 0
    );
  };

  const handleUpdateDeliveryFee = () => {
    setDeliveryFeeOpen(false);
    setManualDeliveryFee(formField.manualDeliveryFee);
    if (formField.manualDeliveryFee)
      setDeliveryFee(Number(formField.deliveryFee));
  };

  const returnDelivery = useCallback(() => {
    if (isThirdParty) return 0;
    if (manualDeliveryFee) return Number(deliveryFee);
    return deliveryCapAmount;
  }, [deliveryCapAmount, deliveryFee, isThirdParty, manualDeliveryFee]);

  const grandTotal = useMemo(() => {
    const withoutTotal = calculatedTotal - calculatedDiscount();
    let res = 0;
    if (withoutTotal < totalOrderDiscount) res = returnDelivery();
    else
      res =
        calculatedTotal +
        returnDelivery() -
        calculatedDiscount() -
        totalOrderDiscount;
    return res;
  }, [calculatedTotal, returnDelivery, totalOrderDiscount, calculatedDiscount]);

  const updateDiscountDisabled = () => {
    const noCustomer = !formField.customer?.id;
    const noProducts = !formField.products.length;
    const isMSD = formField.discount?.type === "manufacturer";
    const manufacturers = [
      ...new Map(
        formField.products
          .filter((p) =>
            !isDraftOrder && !!orderId && paymentStatus !== "PENDING"
              ? p.isNewAdded
              : p
          )
          .map((item) => [
            item.manufacturer?.id ||
              item.parentProduct?.manufacturer?.id ||
              item.manufacturerId,
            item.manufacturer ||
              item.parentProduct?.manufacturer || {
                id: item.manufacturerId,
                name: item.manufacturerName,
              },
          ])
      ).values(),
    ];
    return (
      // !isChangeProducts &&
      orderCompleted ||
      noCustomer ||
      noProducts ||
      (isMSD && !manufacturers.length)
    );
  };

  const customerExist = useMemo(
    () => formField?.customer?.id,
    [formField?.customer?.id]
  );

  const handleTerms = useCallback(
    (value) => {
      setValue(
        "paymentTerms",
        { ...value, paymentTermsId: value.id },
        { shouldDirty: true }
      );

      setAnchorEl(null);
    },
    [setValue]
  );

  const handleClickLogoClient = useCallback((e, el) => {
    e.stopPropagation();
    if (el.current) setAnchorEl(el.current);
  }, []);

  const handleCloseLogoClient = useCallback((e) => {
    e.stopPropagation();
    setAnchorEl(null);
  }, []);

  const defaultContactId = formField.customer?.contacts?.find(
    (contact) => contact.defaultContact
  )?.id;

  useEffect(() => {
    if (avoidUpd) return;

    if (cartType === CART_TYPES.duplicate_draft && formField?.contactId) return;

    setValue("contactId", defaultContactId || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avoidUpd, defaultContactId, setValue]);

  const discountWithQuantity = useMemo(() => {
    if (discountsWithQuantity?.length > 1)
      return { manufacturer: { name: "" } };

    return discountsWithQuantity[0];
  }, [discountsWithQuantity]);

  const handleAddPromotion = useCallback(
    (newProducts) => {
      formField.products.forEach((formProd, index) => {
        const foundInNewProducts = newProducts.find(
          (newProd) =>
            (newProd.id === formProd.product?.id ||
              newProd.id === formProd.id) &&
            newProd.discountQty !== formProd.discountQty
        );
        if (foundInNewProducts) {
          setValue(
            `products.${index}.discountQty`,
            foundInNewProducts.discountQty,
            {
              shouldDirty: true,
            }
          );
        }
      });

      const newList = newProducts.map((item) => {
        const formFieldIndex = formField.products.findIndex(
          ({ product }) => product?.id === item?.id
        );
        const cartProduct =
          formFieldIndex > -1 ? formField.products[formFieldIndex] : null;
        return {
          ...item,
          freeCaseChosen: !!item.discountQty,
          productId: item?.productId || cartProduct?.id,
          isNewAdded: !cartProduct,
        };
      });
      const newFreeCases = newList
        .filter(({ freeCaseChosen }) => !!freeCaseChosen)
        .map(({ id, discountQty }) => ({ id, discountQty }));

      setValue("freeCasesProductsIds", newFreeCases);
      setFreeProducts([...newList]);
      freeCasesState.handleCloseFreeCasesDialog();
      dispatch(updateUserFieldAction("openDiscountDialogQty", false));
    },
    [dispatch, formField.products, freeCasesState, setFreeProducts, setValue]
  );

  const [repsDialogState, setRepsDialogState] = useState({
    open: false,
    profile: null,
  });

  const handleCloseRepsDialog = () => {
    setRepsDialogState({
      open: false,
      profile: null,
    });
  };

  const defaultData = {
    ...DEFAULT_ORDER_DATA,
    createOrderType: formField?.createOrderType,
    ...(formField?.salesId !== undefined && { salesId: formField?.salesId }),
  };

  const handleOpenRepsDialog = () => {
    setRepsDialogState({
      open: true,
      profile: {
        role:
          formField?.createOrderType === "THIRD_PARTY"
            ? "THIRD_PARTY"
            : "SALES",
        showCustomersTub: false,
        chosenCustomer: formField?.customer,
        onSuccess: (rep) => {
          isThirdParty ? fetchThirdPartyReps() : handleFetchReps();
          setValue("salesId", rep?.id || "");
          clearErrors("salesId");
        },
      },
    });
  };

  if (loading || cartState.loading)
    return (
      <Box
        height="70vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={60} />
      </Box>
    );

  return (
    <>
      {!!repsDialogState.open && (
        <ProfileComponent
          isOpenProfileDialog={repsDialogState.open}
          handleCloseProfileDialog={handleCloseRepsDialog}
          profile={repsDialogState.profile}
          skipNavigation
        />
      )}

      <FreeCasesDialog
        calcProductPriceByPriceList={calcProductPriceByPriceList}
        open={freeCasesState.open}
        onClose={() => {
          freeCasesState.handleCloseFreeCasesDialog();
          dispatch(updateUserFieldAction("openDiscountDialogQty", false));
        }}
        list={freeProducts}
        discounts={discountsWithQuantity}
        handleAddPromotion={handleAddPromotion}
        handleFetchFreeCases={handleGetFreeCaseProducts}
        loading={freeCasesLoading}
        cartProducts={formField.products}
      />
      <ContactAddDrawer
        isOpen={contactOpen}
        editContact={editContact}
        handleClose={() => {
          setContactOpen(false);
          setEditContact(null);
        }}
        handleAddContact={handleAddContact}
        contacts={formField?.customer?.contacts}
      />
      <OrderEmailDialog
        isOpen={emailOpen}
        deliveryFee={parseFloat(returnDelivery()?.toFixed(2))}
        totalDiscount={parseFloat(
          (calculatedDiscount() + totalOrderDiscount)?.toFixed(2)
        )}
        isDuplicate={isDuplicate}
        handleClose={() => setEmailOpen(false)}
        order={{ ...formField }}
        handleSendEmail={handleSendEmail}
        isEdit={!!orderId}
        isDraft={cartType === CART_TYPES.draft}
        grandTotal={parseFloat(grandTotal?.toFixed(2))}
        calculatedTotal={parseFloat(calculatedTotal?.toFixed(2))}
        selectedContactEmail={selectedContactEmail}
        oldOrder={oldOrder}
        freeCaseList={freeProducts?.filter((p) => p?.freeCaseChosen)}
      />
      <PaytermComponent
        open={isOpenPaymentTermModal}
        onClose={() => {
          setOpenPaymentTermModal(false);
          setAnchorEl(null);
        }}
        isQuickAdd
        setValue={setValue}
        customName="paymentTerms"
      />

      <EmailLinkDialog
        open={!!emailLinkDialogData}
        onClose={() => {
          setEmailLinkDialogData(null);
        }}
        emailLinkDialogData={emailLinkDialogData}
        onSubmit={(type = "THIRD_PARTY") => {
          navigate("/orders", {
            state: type === "THIRD_PARTY" ? "3rd Party" : "Direct",
          });
        }}
      />

      <form id="new-cart-form" onSubmit={handleSubmit(onTrySubmit)}>
        <OrderCartSwitcherTab
          handleCreateWithEmail={handleSubmit(onEmailSubmit)}
          isEdit={!!orderId && !isDuplicate}
          createdTypeState={ORDER_TYPES?.[state?.tab]}
          {...{
            control,
            handleCreateWithNew,
            handleCreateWithEmailLink,
            handleSaveAsDraft,
            handleUpdateDraft,
            errors,
            formField,
            trigger,
            hasChosenCustomerEmail,
            setValue,
            checkSellingOutStock,
            isDuplicate,
            isButtonDisabled,
            handleSetDefaultSalesId,
            cartType,
            clearErrors,
            freeCasesLoading,
            hasDraft: drafts?.id ? !isEqual(drafts.data, defaultData) : false,
            handleResetCart,
            fieldReset,
          }}
        />

        <Box
          sx={{
            p: "13px 33px 0 27px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* STEP 1 */}
          <Box sx={{ flex: "0 1 54%", maxWidth: "54%" }}>
            <Box
              className={classes.section}
              sx={{
                height: "fit-content",
                backgroundColor: "#ffff !important",
                borderRadius: "4px",
              }}
            >
              <Box
                sx={{
                  pt: "clamp(8px, 1.1vw, 16px)",
                  pb: "clamp(10px, 1.1vw, 20px)",
                  px: "clamp(14px, 1.6vw, 29px)",
                  position: "relative",
                }}
              >
                <Box display="inline-flex" alignItems="center">
                  <Typography className={classes.stepTitle}>Step 1</Typography>
                  {errors.products && (
                    <Typography
                      fontSize="14px"
                      color="#FF6969"
                      ml="16px"
                      fontWeight="400"
                    >
                      {errors.products.message}
                    </Typography>
                  )}
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  mt="10px"
                  position="relative"
                >
                  <Box position="relative" mr="10px" width="65%">
                    <StyledTextField
                      fullWidth
                      value={productsSearchInput}
                      onChange={(e) => setProductsSearchInput(e.target.value)}
                      formSx={{ mr: "10px" }}
                      size="small"
                      InputProps={{
                        className: classes.textInput,
                        endAdornment: (
                          <>
                            <InputAdornment
                              position="start"
                              sx={{ position: "absolute", left: 9 }}
                            >
                              <SearchIcon />
                            </InputAdornment>
                            {productsLoading && (
                              <InputAdornment position="end">
                                <CircularProgress size="20px" />
                              </InputAdornment>
                            )}
                            {productsSearchInput && (
                              <InputAdornment
                                position="end"
                                sx={{ mr: "-4px" }}
                              >
                                <IconButton
                                  sx={{ p: "1px" }}
                                  onClick={() => setProductsSearchInput("")}
                                >
                                  <CrossIcon size="15" />
                                </IconButton>
                              </InputAdornment>
                            )}
                          </>
                        ),
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel,
                      }}
                      placeholder="Search products by name, manufacturer or SKU"
                    />
                    {productsDropDown}
                  </Box>
                  <AllProductsPopup
                    isOpen={allProductsOpen}
                    handleClose={() => setAllProductsOpen(false)}
                    handleCheckProduct={handleCheckProduct}
                    handleCancelProducts={() => setAllProductsOpen(false)}
                    handleAddProducts={() => {
                      setAllProductsOpen(false);
                      handleAddProducts();
                    }}
                    checkedProducts={checkedProducts}
                    addedProducts={formField.products}
                    disableOutOfStock={!isThirdParty}
                    isOrder
                    hideAvailable={isThirdParty}
                    environment="cart_page"
                    priceListId={priceListId}
                  />
                  <Box>
                    <StyledTooltip
                      style={{ whiteSpace: "pre-wrap" }}
                      title="Add at least 1 product to access the cart"
                      arrow
                      PopperProps={{
                        modifiers: [
                          { name: "offset", options: { offset: [0, -5] } },
                        ],
                      }}
                      data-testid="all-products"
                      disableHoverListener={!!productsExist}
                      placement="top"
                    >
                      <Box>
                        <StyledButton
                          label="View all"
                          disabled={!productsExist}
                          onClick={() => {
                            setProductsSearchInput("");
                            setAllProductsOpen(true);
                          }}
                          variant="outlined"
                          color="greyBtn"
                          fontSize="15px"
                          sx={{
                            color: "#6A6A6A",
                            height: "33px",
                            whiteSpace: "nowrap",
                          }}
                        />
                      </Box>
                    </StyledTooltip>
                  </Box>
                  <Typography
                    fontSize="clamp(15px, 1.3vw, 25px)"
                    color="#707070"
                    fontWeight={600}
                    ml="14px"
                    whiteSpace="nowrap"
                  >
                    Order{" "}
                    <span
                      style={{
                        fontWeight: 400,
                        color: "#9C9C94",
                      }}
                    >
                      ({handleGetQuantity()} items)
                    </span>
                  </Typography>
                </Box>
              </Box>
              {!!formField.products?.length && (
                <>
                  <ProductsHeader
                    handleDeleteAllProducts={() =>
                      handleOpenDialog({
                        title: "Delete all products?",
                        text: "Are you sure you want to proceed? This will erase all drafted products and assigned discounts.",
                        onConfirm: () => {
                          if (formField.discount?.type !== "total")
                            setValue("discount", null, { shouldDirty: true });
                          setValue("manufacturerDiscounts", [], {
                            shouldDirty: true,
                          });
                          setValue("products", [], { shouldDirty: true });
                          setValue("productsData", [], { shouldDirty: true });
                          setValue("freeCasesProductsIds", [], {
                            shouldDirty: true,
                          });
                          setFreeProducts([]);
                        },
                      })
                    }
                    formDiscountType={formField.discount?.type}
                    orderCompleted={orderCompleted}
                    deliveryStatus={deliveryStatus}
                    hideAvailable={isThirdParty}
                  />
                  {fields?.map((product, index) => {
                    return (
                      <ProductsItem
                        hasPriceListPrice={
                          !!priceList?.[product.product?.id || product.id]
                        }
                        refPrice={(el) => (pricesRefs.current[product.id] = el)}
                        handleAddDiscount={handleAddMSD}
                        prodDict={productDict}
                        key={product?.id}
                        product={formField?.products?.[index]}
                        quantity={formField?.products?.[index]?.quantity}
                        setValue={setValue}
                        index={index}
                        customerSelected={!!formField?.customer}
                        handleRemoveOrderProduct={handleCheckRemove}
                        handleOpenDiscountsDialog={handleOpenDialog}
                        handleSubtractQty={handleSubtractQty}
                        control={control}
                        formDiscountType={formField.discount?.type}
                        orderCompleted={orderCompleted}
                        outOfStock={getOutOfStock(product, index)}
                        paymentStatus={paymentStatus}
                        isThirdParty={isThirdParty}
                        checkSellingOutStock={checkSellingOutStock}
                        handleApplyDiscount={handleApplyDiscount}
                        availableDiscounts={getAvailableDiscounts(
                          formField?.products?.[index]
                        )}
                        lastProductsOrdersData={
                          !!lastProductsOrders?.data[
                            product?.product?.id || product?.id
                          ]?.lastOrders &&
                          lastProductsOrders?.data[
                            product?.product?.id || product?.id
                          ]?.lastOrders
                        }
                        gapBetweenPriceAndInfo={productHighestPriceWidth || 0}
                        {...{ calcProductPriceByPriceList, priceListLoading }}
                      />
                    );
                  })}
                </>
              )}
            </Box>

            {!!discountsWithQuantity?.length && (
              <Box mt={2}>
                <FreeCasesBlock
                  status={true}
                  title={`${freeCasesCount} free ${
                    discountWithQuantity?.manufacturer?.name
                  } ${pluralize("case", freeProducts?.length)}`}
                  list={freeProducts?.filter((p) => p?.freeCaseChosen)}
                  paymentStatus={paymentStatus}
                  formField={formField}
                  handleUpdateFreeCaseProduct={handleUpdateFreeCaseProduct}
                  handleRemoveFreeCaseProduct={handleRemoveFreeCaseProduct}
                  discDict={discDict}
                  loading={freeCasesLoading}
                  calcProductPriceByPriceList={calcProductPriceByPriceList}
                />
              </Box>
            )}
          </Box>

          {/* STEP 2 */}
          <Box sx={{ flex: "0 1 22.5%", overflow: "hidden" }}>
            <SecondCustomerStep
              formField={formField}
              setCustomersList={setCustomersList}
              setIsFetchable={setIsFetchable}
              errors={errors}
              orderCompleted={orderCompleted}
              deliveryStatus={deliveryStatus}
              paymentStatus={paymentStatus}
              control={control}
              handleCheckRemoveCustomer={onCheckProductsForAbility}
              isThirdParty={isThirdParty}
              handleClickLogoClient={handleClickLogoClient}
              handleCloseLogoClient={handleCloseLogoClient}
              setOpenPaymentTermModal={setOpenPaymentTermModal}
              anchorEl={anchorEl}
              openHeaderList={openHeaderList}
              paytermsListActive={paytermsListActive}
              handleTerms={handleTerms}
              thirdPartyRepsList={thirdPartyRepsList}
              repsList={repsList[createOrderType]}
              handleOpenRepsDialog={handleOpenRepsDialog}
              fetchThirdPartyReps={fetchThirdPartyReps}
              handleContactChange={handleContactChange}
              defaultContactId={defaultContactId}
              setContactOpen={setContactOpen}
              setEditContact={setEditContact}
              customersSearchInput={customersSearchInput}
              customersLoading={customersLoading}
              setCustomersSearchInput={setCustomersSearchInput}
              customersList={customersList}
              setAllCustomersOpen={setAllCustomersOpen}
              productsRef={productsRef}
              searchRef={searchRef}
              customersDropDown={customersDropDown}
              setStateValue={setValue}
              clearErrors={clearErrors}
              checkSellingOutStock={checkSellingOutStock}
            />

            {!!allCustomersOpen && (
              <AllCustomersPopup
                viewCustomerPopup={true}
                handleAddCustomers={(customer) => selectCustomers(customer)}
                open={allCustomersOpen}
                onClose={() => setAllCustomersOpen(false)}
              />
            )}

            {/* STEP 3 */}
            <ThirdCustomerStep
              formField={formField}
              customerExist={customerExist}
              isAdmin={isAdmin}
              priceListName={priceListName}
              priceListLoading={priceListLoading}
              updateDiscountDisabled={updateDiscountDisabled}
              handleAddDiscount={handleAddDiscount}
              applyDiscountOpen={applyDiscountOpen}
              setApplyDiscountOpen={setApplyDiscountOpen}
              handleApplyDiscount={handleApplyDiscount}
              cartState={cartState}
              deliveryStatus={deliveryStatus}
              paymentStatus={paymentStatus}
              totalDiscountOpen={totalDiscountOpen}
              setTotalDiscountOpen={setTotalDiscountOpen}
              handleApplyTotalDiscount={handleApplyTotalDiscount}
              calculatedTotal={calculatedTotal}
              hasQuickBooks={hasQuickBooks}
              customerDiscount={customerDiscount}
              totalCustomerDiscount={totalCustomerDiscount}
              handleDiscountsBlock={handleDiscountsBlock}
              isDuplicate={isDuplicate}
              isPayed={!!orderId && paymentStatus !== "PENDING"}
            />
          </Box>
          <Box sx={{ flex: "0 1 21.6%" }}>
            <Paper
              className={classes.section}
              sx={{
                p: "9px 36px 13px 28px",
              }}
            >
              <Typography
                color="#707070"
                fontWeight={600}
                fontSize="clamp(15px, 1.3vw, 25px)"
              >
                Summary
              </Typography>
              <Box borderBottom="1px solid #707070" pb="11px" mt="9px">
                <Box className={classes.summaryRow}>
                  <Typography>Items total:</Typography>
                  {priceListLoading ? (
                    <Skeleton
                      variant="text"
                      sx={{
                        fontSize: "clamp(15px, 1.3vw, 25px)",
                        width: "20%",
                      }}
                    />
                  ) : (
                    <Typography>$ {calculatedTotal?.toFixed(2)}</Typography>
                  )}
                </Box>
                <Box className={classes.summaryRow}>
                  <Typography>Discounts:</Typography>
                  {priceListLoading ? (
                    <Skeleton
                      variant="text"
                      sx={{
                        fontSize: "clamp(15px, 1.3vw, 25px)",
                        width: "20%",
                      }}
                    />
                  ) : (
                    <Typography>
                      {(calculatedDiscount() + totalOrderDiscount)?.toFixed(2)}
                    </Typography>
                  )}
                </Box>
                <Box className={classes.summaryRow}>
                  <Box display="flex" gap="5px" alignItems="center">
                    <Typography>Delivery:</Typography>
                    {isThirdParty ? null : (
                      <IconButton
                        disabled={isAdmin}
                        sx={{
                          backgroundColor: "#E6E6E6",
                          width: 19,
                          height: 19,
                          p: 0,
                          "&.Mui-disabled": {
                            backgroundColor: "#D5D9D9",
                            opacity: 0.5,
                          },
                        }}
                        onClick={() => setDeliveryFeeOpen(true)}
                      >
                        <PenIcon size={9} color="#000000" />
                      </IconButton>
                    )}
                  </Box>
                  <DeliveryFeeDialog
                    currentDeliveryFee={deliveryFee || deliveryCapAmount || 0}
                    orderTotal={`${currentUser?.deliveryFeeAmount}${
                      currentUser?.deliveryFeeType === "PERCENTAGE" ? "%" : "$"
                    }`}
                    open={deliveryFeeOpen}
                    onClose={() => {
                      reset({
                        ...formField,
                        manualDeliveryFee: defaultValues.manualDeliveryFee,
                        deliveryFee: defaultValues.deliveryFee,
                      });
                      setDeliveryFeeOpen(false);
                    }}
                    {...{ handleUpdateDeliveryFee, control, setValue, errors }}
                  />
                  <Typography>
                    {isThirdParty
                      ? "N/A"
                      : `$ ${returnDelivery()?.toFixed(2)}` || "free"}
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                color="#000000"
                mt="7px"
              >
                <Typography fontWeight="600" fontSize="20px">
                  Grand total:
                </Typography>
                {priceListLoading ? (
                  <Skeleton
                    variant="text"
                    sx={{
                      fontSize: "20px",
                      width: "25%",
                    }}
                  />
                ) : (
                  <Typography fontWeight="600" fontSize="20px">
                    ${grandTotal?.toFixed(2)}
                  </Typography>
                )}
              </Box>
            </Paper>

            <OrderNoteBlock
              {...{
                customersSearchInput,
                isAdmin,
                control,
                setValue,
                errors,
                setError,
                clearErrors,
                createOrderType,
                trigger,
              }}
            />
          </Box>
        </Box>
      </form>
    </>
  );
};

CartPage.propTypes = {
  isDuplicate: bool,
  isDraftOrder: bool,
};

export default CartPage;
