import { useMemo, useRef, useState } from "react";
import { shape, func, bool, number } from "prop-types";
import { Box, Paper, Grid } from "@mui/material";
import {
  TotalSummaryInfoBlock,
  ThreeDotButton,
  TotalSummariesToggles,
  TotalSummaryBlock,
  ActionRestrictionWrapper,
  SelectDatePicker,
} from "components";
import { useAdmin, separateNumWithComma } from "helpers/helpers";
import { addPercentsOfPayments } from "components/TotalSummariesComponents/helpers";
import { useSelector } from "react-redux";
import { currentUserSelector } from "redux/selectors/auth";
import { createSelector } from "reselect";
import { SETTINGS_NAME } from "components/TotalSummariesComponents/TotalSummariesToggles/TotalSummariesToggles.constants";
import { useBreakpoint } from "helpers/useBreakpoint";
import { useRepsPermissions } from "helpers/hooks";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  ADMIN_ONLY_VIEW_MESSAGE_PAGE,
} from "utils/constants";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

export const OrdersTotalSummaries = ({
  isLoading,
  totalSummaries,
  handleChoosePeriod,
}) => {
  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();
  const breakpoint = useBreakpoint();

  const { currentUser } = useSelector(selector);

  const orders = currentUser?.totalSummarySettings?.orders;

  const chosenTopThreeName = useMemo(() => {
    if (!orders) return "";

    return Object.keys(orders)
      .map((key) => ({ name: key, value: orders[key] }))
      .find((el) => el.value)?.name;
  }, [orders]);

  const [date, setDate] = useState("This week");
  const [openToggles, setOpenToggles] = useState(false);

  const handleDateInput = (newDates) => {
    if (newDates) {
      setDate(newDates);
      handleChoosePeriod(newDates);
    }
  };

  const toggleBtnAnchor = useRef();

  return (
    <Paper
      sx={{
        minHeight: "64px",
        height: "64px",
      }}
      component={Grid}
      container
      elevation={0}
      variant="outlined"
    >
      <Grid
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        xs={6.5}
        item
        data-testid="summaries-block"
        sx={{ pl: 1.75 }}
      >
        <Box minWidth="fit-content">
          <SelectDatePicker
            adminIsAllowed
            value={date || "Today"}
            noSquare
            height="35px"
            fontSize="15px"
            fullWidth
            sx={{
              "& > fieldset": {
                borderRadius: "4px",
              },
            }}
            textfieldInputSx={{
              borderRadius: "4px",
              mr: "10px",
            }}
            handleDateInput={handleDateInput}
            label=""
            handleClearValue={() => setDate("")}
            onChange={(e) => handleDateInput(e.target.value)}
            data-testid="orders-summaries-filter-select"
          />
        </Box>

        <TotalSummaryInfoBlock
          isLoading={isLoading}
          items={[
            {
              name: "Total Sales",
              value: totalSummaries?.totalSales
                ? `$ ${separateNumWithComma(totalSummaries?.totalSales)}`
                : 0,
            },
            { name: "Orders", value: totalSummaries?.totalOrders ?? 0 },
            {
              name: "Ordered Items",
              value: totalSummaries?.totalOrderedItems ?? 0,
            },
            {
              name: "Fulfilled Orders",
              value: totalSummaries?.totalFulfilledOrders ?? 0,
            },
          ]}
        />
      </Grid>
      <Grid
        display="flex"
        justifyContent={breakpoint === "xl" ? "flex-end" : "space-between"}
        alignItems="center"
        xs={5.5}
        item
        gap="14px"
      >
        <TotalSummaryBlock
          name={SETTINGS_NAME?.[chosenTopThreeName]?.name}
          isLoading={isLoading}
          topName={chosenTopThreeName}
          topThree={addPercentsOfPayments(totalSummaries?.topThree)}
        />

        <ActionRestrictionWrapper
          disableHoverListener={!repPermissions}
          title={
            repPermissions?.role === "SALES"
              ? ADMIN_ONLY_VIEW_MESSAGE
              : ADMIN_ONLY_VIEW_MESSAGE_PAGE
          }
        >
          <Box component="span">
            <ThreeDotButton
              paddingRight="14px"
              disabled={!!repPermissions || isAdmin}
              ref={toggleBtnAnchor}
              onClick={() => setOpenToggles(true)}
            />
          </Box>
        </ActionRestrictionWrapper>
      </Grid>
      <TotalSummariesToggles
        anchorEl={toggleBtnAnchor.current}
        open={openToggles}
        onClose={() => setOpenToggles(false)}
        settingsType="orders"
      />
    </Paper>
  );
};

OrdersTotalSummaries.propTypes = {
  isLoading: bool,
  totalSummaries: shape({
    totalSales: number,
    totalOrders: number,
    totalOrderedItems: number,
    totalFulfilledOrders: number,
  }),
  handleChoosePeriod: func,
};
