import * as React from "react";
export const FormBarcodeTypeIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.5 3v2.5a.5.5 0 0 1-1 0v-2h-2a.5.5 0 0 1 0-1H14a.5.5 0 0 1 .5.5m-10 9.5h-2v-2a.5.5 0 0 0-1 0V13a.5.5 0 0 0 .5.5h2.5a.5.5 0 0 0 0-1M14 10a.5.5 0 0 0-.5.5v2h-2a.5.5 0 0 0 0 1H14a.5.5 0 0 0 .5-.5v-2.5a.5.5 0 0 0-.5-.5M2 6a.5.5 0 0 0 .5-.5v-2h2a.5.5 0 1 0 0-1H2a.5.5 0 0 0-.5.5v2.5A.5.5 0 0 0 2 6m3-1a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 1 0v-5A.5.5 0 0 0 5 5m6.5 5.5v-5a.5.5 0 0 0-1 0v5a.5.5 0 0 0 1 0M9 5a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 1 0v-5A.5.5 0 0 0 9 5M7 5a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 1 0v-5A.5.5 0 0 0 7 5"
      fill="#C9C9C9"
    />
  </svg>
);
