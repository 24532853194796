import { useCallback, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import { ActiveTabs, FilterSearchTextField } from "components";
import { Box, Stack } from "@mui/material";
import { FILTER_TABS } from "../../FormsTab.constants";
import { cl } from "./FormsFilter.styles";
import { FormsPageContext } from "Pages/TasksPage/TasksPage";
import { formsFilterAction } from "redux/actions/forms";

export const FormsFilter = () => {
  const dispatch = useDispatch();

  const { formsFilter, handleUncheckAllForms } = useContext(FormsPageContext);

  const [tabField, setTabField] = useState(FILTER_TABS[0]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value, setParams) => setParams(value), 500),
    []
  );

  const [search, setSearch] = useState("");

  return (
    <Box sx={cl.filterWrapper}>
      <ActiveTabs
        value={tabField.value}
        onChange={(e, newVal) => {
          setTabField({ name: e.target.name, value: newVal });
          handleUncheckAllForms();
          dispatch(
            formsFilterAction({
              ...formsFilter,
              active: newVal,
              page: 1,
            })
          );
        }}
        customTabs={FILTER_TABS}
        tabStyles={{ fontWeight: "400" }}
      />

      <Stack gap="15px" direction="row" width="100%">
        <Box sx={cl.filterSearchBlock}>
          <FilterSearchTextField
            adminIsAllowed
            value={search}
            onChange={(e) => {
              const value = e.target.value;
              setSearch(value);
              debouncedSearch(value, (v) => {
                handleUncheckAllForms();
                dispatch(
                  formsFilterAction({
                    ...formsFilter,
                    page: 1,
                    search: v,
                  })
                );
              });
            }}
            formSx={cl.searchFromSx}
            placeholder="Search forms"
            fullWidth
            adornmentProps={{ sx: cl.adornmentPropsSx }}
            handleClearValue={() => {
              setSearch("");
              handleUncheckAllForms("");
              dispatch(
                formsFilterAction({
                  ...formsFilter,
                  page: 1,
                  search: undefined,
                })
              );
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
};
