import {
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { object, bool, number, func, string, array } from "prop-types";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { CartTrashIcon } from "../../../../../../../../../../../components/Icons";
import StyledLabel from "../../../../../../../../../../../components/StyledLabel/StyledLabel";
import { StyledTextField } from "../../../../../../../../../../../components/TextFields/TextFields";
import useStyles from "../../../../../../newStyles";
import { COUNTRY_PHONE_CODES } from "../../../../../../../../../../../utils/constants";
import { useMemo, useCallback } from "react";
import { onPastePhone, truncateText, useAdmin } from "helpers/helpers";
import { useRepsPermissions } from "helpers/hooks";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { ActionRestrictionWrapper, StyledButton } from "components";

const ContactsItem = ({
  contact,
  isEdit,
  index,
  control,
  setValue,
  clearErrors,
  handleRemoveContact,
  handleChangeDefault,
  countryPrefixFormat,
  phoneCodeList = COUNTRY_PHONE_CODES,
}) => {
  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();
  const classes = useStyles();
  const dispatch = useDispatch();

  const mask = (phoneNumber) => {
    return phoneNumber.replace(
      /\+(\d{1})(\d{3})(\d{3})(\d{4})/,
      "+$1 ($2) $3 $4"
    );
  };

  const handleConfirmDeleteContactDialog = useCallback(
    (index) => {
      dispatch(
        openConfirmDialogAction({
          title: "Delete contact?",
          text: (
            <span>
              Please confirm that you would like to delete contact{" "}
              <span style={{ color: "#47a06d" }}>
                {truncateText(contact?.name, 30)}
              </span>
              ?
            </span>
          ),
          buttons: (
            <>
              <StyledButton
                variant="outlined"
                color="cancel"
                label="Cancel"
                sx={{ height: "28px" }}
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                }}
              />
              <Button
                sx={{
                  width: "auto",
                  height: "28px",
                  color: "#FFFFFF",
                  fontSize: "13px",
                  boxShadow: "none",
                }}
                color="confirmDelete"
                onClick={async () => {
                  handleRemoveContact(index, false);
                  dispatch(setConfirmIsOpenAction(false));
                }}
                variant="contained"
              >
                Confirm
              </Button>
            </>
          ),
        })
      );
    },
    [contact?.name, dispatch, handleRemoveContact]
  );

  const setPhoneNumber = useMemo(() => {
    if (!contact?.phone) return "-";

    if (contact?.phone && contact?.countryPrefix?.phone_code)
      return mask(`${contact?.countryPrefix?.phone_code}${contact.phone}`);

    if (contact?.phone) return contact?.phone;
  }, [contact?.countryPrefix?.phone_code, contact?.phone]);

  if (!contact) return <></>;
  return (
    <Grid
      key={contact.id}
      container
      height="59px"
      alignItems="center"
      gap="22px"
      width="unset"
      sx={{
        ml: isEdit ? "-9px" : "27px",
        mr: "20px",
        "&:not(:last-of-type)": {
          borderBottom: !isEdit && "1px solid #d5d9d9",
        },
        "& .MuiTypography-root": {
          fontSize: "15px",
        },
      }}
    >
      <Grid item xs={3.75}>
        {isEdit ? (
          <Box display="flex" alignItems="center">
            <Controller
              render={({ field }) => (
                <>
                  <Radio
                    checked={!!field.value}
                    {...field}
                    onChange={() => handleChangeDefault(index)}
                  />
                  <StyledLabel
                    fill={field.value ? "#47a06d" : "#D5D9D9"}
                    style={{
                      mr: "22px",
                      px: "7px",
                    }}
                  />
                </>
              )}
              name={`contacts.${index}.defaultContact`}
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  fullWidth
                  placeholder="Title"
                  size="large"
                  InputProps={{ className: classes.editInput }}
                  error={error?.message || ""}
                  {...field}
                  onChange={(e) => {
                    setValue(`contacts.${index}.role`, e.target.value);
                    if (field?.value) clearErrors(`contacts.${index}.role`);
                  }}
                />
              )}
              name={`contacts.${index}.role`}
              control={control}
            />
          </Box>
        ) : (
          <Box display="flex" gap="10px" alignItems="center">
            <Typography color="#979797" noWrap>
              {contact.role}{" "}
            </Typography>
            {contact.defaultContact && <StyledLabel />}
          </Box>
        )}
      </Grid>
      <Grid item xs={2.3}>
        {isEdit ? (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                fullWidth
                placeholder="Full name"
                size="large"
                InputProps={{ className: classes.editInput }}
                error={error?.message || ""}
                {...field}
                onChange={(e) => {
                  setValue(`contacts.${index}.name`, e.target.value);
                  if (field?.value) clearErrors(`contacts.${index}.name`);
                }}
              />
            )}
            name={`contacts.${index}.name`}
            control={control}
          />
        ) : (
          <Typography noWrap>{contact.name}</Typography>
        )}
      </Grid>
      <Grid item xs={2.3}>
        {isEdit ? (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <NumberFormat
                customInput={StyledTextField}
                fullWidth
                format={countryPrefixFormat}
                mask="_"
                size="large"
                InputProps={{
                  className: classes.editInputPhone,
                  startAdornment: (
                    <FormControl>
                      <Controller
                        render={({ field }) => {
                          return (
                            <Select
                              sx={{
                                minWidth: "50px",
                                borderRadius: "0",
                                marginRight: 1,
                                "& svg": {
                                  right: -2,
                                },
                                "& .MuiSelect-select": {
                                  minWidth: "30px",
                                  paddingRight: "4px !important",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderWidth: "0 1px 0 0 !important",
                                  borderColor: "#D5D9D9 !important",
                                },
                              }}
                              size="small"
                              renderValue={() => field?.value?.phone_code}
                              {...field}
                              value={JSON.stringify(field?.value)}
                            >
                              {phoneCodeList.map((code) => {
                                return (
                                  <MenuItem
                                    key={code.label}
                                    value={JSON.stringify(code)}
                                  >
                                    {code.phone_code} {code.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          );
                        }}
                        name={`contacts.${index}.countryPrefix`}
                        control={control}
                      />
                    </FormControl>
                  ),
                }}
                error={!!error?.message || ""}
                noErrorMessage
                placeholder="Phone number"
                {...field}
                onChange={(e) => {
                  setValue(`contacts.${index}.phone`, e.target.value);
                  if (field?.value) clearErrors(`contacts.${index}.phone`);
                }}
                autoComplete="new-password"
                onPaste={(e) => {
                  const prepareVal = (v) => {
                    if (/^\+[\d]+/.test(v)) {
                      return v.substring(2);
                    }
                    return v;
                  };

                  onPastePhone({
                    e,
                    type: `contacts.${index}.phone`,
                    setValue: (type, val) => setValue(type, prepareVal(val)),
                    currentValue: `contacts.${index}.phone`,
                  });
                }}
              />
            )}
            name={`contacts.${index}.phone`}
            control={control}
          />
        ) : (
          <Typography>{setPhoneNumber}</Typography>
        )}
      </Grid>
      <Grid item xs={2.3}>
        {isEdit ? (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                fullWidth
                placeholder="Email"
                size="large"
                InputProps={{ className: classes.editInput }}
                error={error?.message || ""}
                {...field}
                onChange={(e) => {
                  setValue(`contacts.${index}.email`, e.target.value);
                  if (field?.value) clearErrors(`contacts.${index}.email`);
                }}
              />
            )}
            name={`contacts.${index}.email`}
            control={control}
          />
        ) : (
          <Typography noWrap>{contact.email}</Typography>
        )}
      </Grid>
      <Grid item xs={0.35} ml="-9px">
        {!contact.defaultContact && (
          <InputAdornment position="end">
            <ActionRestrictionWrapper disableHoverListener={!repPermissions}>
              <IconButton
                disabled={isAdmin || !!repPermissions}
                onClick={() =>
                  isEdit
                    ? handleRemoveContact(index, true)
                    : handleConfirmDeleteContactDialog(index)
                }
              >
                <CartTrashIcon />
              </IconButton>
            </ActionRestrictionWrapper>
          </InputAdornment>
        )}
      </Grid>
    </Grid>
  );
};

ContactsItem.propTypes = {
  contact: object,
  isEdit: bool,
  index: number,
  control: object,
  setValue: func,
  clearErrors: func,
  handleRemoveContact: func,
  handleChangeDefault: func,
  countryPrefixFormat: string,
  phoneCodeList: array,
};

export default ContactsItem;
