import moment from "moment";
import { PAYMENT_TERM_TYPE } from "./CustomerPage.constants";
import {
  checkAndPrepareWebsiteForCustomer,
  normalizeSnakeCaseString,
} from "helpers/helpers";
import { isEqual } from "lodash";
import { CUSTOMER_STATUS_FILTERS } from "utils/constants";

export const displayNameSpecialChars = (str) => {
  const regex = /[\t\n]/;

  return regex.test(str);
};

export const setIncomingReceivingHours = (receivingHours) => {
  const weekDays = receivingHours?.reduce((acc, day) => {
    const { day: name, working, open, closed } = day || {};

    const dayName = name?.charAt(0)?.toUpperCase() + name?.slice(1);

    let dateOpen;
    let dateClosed;
    if (working) {
      const time = moment();

      dateOpen = moment.utc(`2023-12-12T${open}:00.000Z`).set({
        year: time.get("year"),
        month: time.get("month"),
        date: time.get("date"),
      });
      dateClosed = moment.utc(`2023-12-12T${closed}:00.000Z`).set({
        year: time.get("year"),
        month: time.get("month"),
        date: time.get("date"),
      });
    }

    acc[dayName] = {
      name: dayName,
      value: working
        ? {
            clockInAt: dateOpen.toISOString(),
            clockOutAt: dateClosed.toISOString(),
          }
        : "",
    };

    return acc;
  }, {});

  return weekDays;
};

export const setIncomingRepresentatives = (representatives) => {
  if (!representatives?.length) return [];

  return representatives.map((rep) => rep?.representative);
};

export const setIncomingParentCustomerId = ({
  parentCustomerId,
  parentCustomer,
}) => {
  if (parentCustomerId === null) return null;

  if (parentCustomer?.isUncategorized) return null;

  if ((parentCustomerId, !parentCustomer?.isUncategorized)) {
    return parentCustomerId;
  }
};

const primaryPaymentMethodTypes = PAYMENT_TERM_TYPE.map((type) => type.value);

export const setReceivingHours = (weekDays) => {
  const receivingHours = Object.values(weekDays);

  return receivingHours.map((day) => {
    const { name, value } = day || {};
    const dayItem = {
      day: name.toLowerCase(),
      working: !!value,
    };

    if (value) {
      const dateOpen = moment.utc(value?.clockInAt);
      const dateClosed = moment.utc(value?.clockOutAt);
      dayItem.open = dateOpen.format("HH:mm");
      dayItem.closed = dateClosed.format("HH:mm");
    }

    return dayItem;
  });
};

export const customerReviewImages = (images) => {
  if (!images || !images.length) return [];

  return images.map((image) => (image.getUrl ? image.getUrl() : ""));
};

export const preparingData = ({ data, editRawData }) => {
  const {
    name,
    parentCustomerId,
    billingAddress,
    shippingAddress,
    displayName,
    customId,
    paymentTermsId,
    federalTaxId,
    federalTaxIdPhotoId,
    proceededWithDuplicatedFields,
    phone,
    businessFax,
    email,
    percentDiscount,
    territoryId,
    website,
    representatives,
    contacts,
    profilePhotoId,
    federalTaxIdGroupContentId,
    primaryPaymentMethod,
    documents,
    weekDays,
    isASubBusiness,
    billParentGroup,
    licenses,
    selectedTagsList,
    status,
    productCategories,
    showCategories,
    rating,
    price,
    reviews,
    categories,
    placeId,
    images,
    priceListId,
  } = data || {};

  const customerTags = selectedTagsList
    ?.filter(({ id, newTag }) => id && !newTag)
    ?.map(({ id }) => id);

  const newCustomerTags = selectedTagsList
    ?.filter(({ newTag }) => !!newTag)
    ?.map(({ tag }) => tag);

  const categoryIds = productCategories.map((item) => item.id);

  const needToPrepareAndSaveLicenses = !isEqual(
    licenses,
    editRawData?.documents
  );

  const preparedData = {
    name,
    billingAddress,
    shippingAddress,
    email,
    customId,
    proceededWithDuplicatedFields: !!proceededWithDuplicatedFields,
    priceListId: priceListId || null,
  };

  //! temporary solution - set "active" field only if edit mode
  if ((status && editRawData) || status === CUSTOMER_STATUS_FILTERS.prospect) {
    preparedData.status = status;
  }

  if (placeId) preparedData.placeId = placeId;
  preparedData.images = images;
  if (rating) preparedData.rating = rating;
  if (price) preparedData.price = price;
  if (reviews) preparedData.stats = { totalRatings: reviews };

  if (categories.length) {
    const normalizedCategories = categories.map((item) =>
      normalizeSnakeCaseString(item.category ? item.category.name : item)
    );

    preparedData.categories = normalizedCategories;
  }

  if (categoryIds.length && !showCategories) {
    preparedData.productCategories = categoryIds;
  } else {
    preparedData.productCategories = null;
  }

  if (displayName) {
    preparedData.displayName = displayName;
  } else {
    preparedData.displayName = null;
  }

  if (paymentTermsId) {
    preparedData.paymentTermsId = paymentTermsId;
  } else {
    preparedData.paymentTermsId = null;
  }

  if (federalTaxId) {
    preparedData.federalTaxId = federalTaxId;
  } else {
    preparedData.federalTaxId = null;
  }

  if (federalTaxIdPhotoId) {
    preparedData.federalTaxIdPhotoId = federalTaxIdPhotoId;
  } else {
    preparedData.federalTaxIdPhotoId = null;
  }

  if (phone) {
    const setPhone = (phone) => {
      const cleanedPhone = phone.replace(/[^+\d]/g, "");
      const preparedPhone = cleanedPhone?.length === 12 ? cleanedPhone : null;

      return preparedPhone;
    };

    preparedData.phone = setPhone(phone);
  } else {
    preparedData.phone = null;
  }

  if (businessFax) {
    preparedData.businessFax = businessFax;
  } else {
    preparedData.businessFax = null;
  }

  if (percentDiscount) {
    preparedData.percentDiscount = +percentDiscount;
  } else {
    preparedData.percentDiscount = 0;
  }

  if (territoryId) {
    preparedData.territoryId =
      territoryId === "Uncategorized" ? null : territoryId;
  }
  if (website) {
    const preparedWebsite = checkAndPrepareWebsiteForCustomer(website);
    preparedData.website = preparedWebsite;
  } else {
    preparedData.website = null;
  }

  if (customerTags) preparedData.customerTags = customerTags;

  if (newCustomerTags) preparedData.newCustomerTags = newCustomerTags;

  if (contacts) {
    preparedData.contacts = contacts.map((c) => {
      delete c?.fid;
      return { ...c };
    });
  }

  if (profilePhotoId) {
    preparedData.profilePhotoId = profilePhotoId;
  } else {
    preparedData.profilePhotoId = null;
  }

  if (federalTaxIdGroupContentId)
    preparedData.federalTaxIdGroupContentId = federalTaxIdGroupContentId;

  if (documents) preparedData.documents = documents;

  if (
    primaryPaymentMethod &&
    primaryPaymentMethodTypes.includes(primaryPaymentMethod)
  ) {
    preparedData.primaryPaymentMethod = primaryPaymentMethod;
  }

  const receivingHours = setReceivingHours(weekDays);

  if (isASubBusiness) {
    preparedData.parentCustomerId = parentCustomerId;
  } else {
    preparedData.parentCustomerId = null;
  }

  preparedData.billToParentCustomer = !!billParentGroup;

  if (representatives?.length) {
    const repsIds = representatives.map((rep) => rep?.id);
    preparedData.representatives = repsIds;
  } else {
    preparedData.representatives = [];
  }

  if (needToPrepareAndSaveLicenses) {
    const preparedLicensesData = licenses.map((license) => {
      const {
        attachments = null,
        documentNumber = null,
        expirationDate = null,
        id = null,
        name = null,
        type = null,
      } = license || {};

      const preparedLicense = {
        documentNumber,
        expirationDate,
        name,
        type,
      };

      if (attachments?.length) {
        preparedLicense.attachmentsIds = attachments.map(
          (f) => f?.attachment?.id || f?.id
        );
      }

      if (id) preparedLicense.id = id;

      return preparedLicense;
    });

    preparedData.documents = preparedLicensesData;
  }

  return { receivingHours, ...preparedData };
};

export const isActiveCustomer = (status) => {
  return status === "ACTIVE";
};

export const handleCustomerCategories = (categories) => {
  if (!categories.length) return;
  return categories.map((item) => (item.category ? item.category.name : item));
};
