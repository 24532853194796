import { makeStyles } from "@mui/styles";

export const cl = {
  SelectedFormsBlock: {
    wrapper: {
      my: "16px",
      width: "100%",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: "16px",
      rowGap: "8px",
    },
    chip: {
      border: "1px solid #D4D4D4",
      bgcolor: "#FAFAFB",
      fontSize: 14,
      color: "#5F6368",
      width: "fit-content",
      maxWidth: "220px",
    },
  },
  ActionsBlock: {
    wrapper: {
      display: "inline-flex",
      alignItems: "center",
      gap: "6px",
      width: "fit-content",
      cursor: "pointer",
      p: 0,
    },
  },
  SelectFormsBlock: {
    mItem: {
      "&:hover": { backgroundColor: "#FFF !important" },
      cursor: "auto",
      px: "16px !important",
    },
    noFoundText: {
      fontSize: 16,
      fontWeight: 300,
      color: "#6A6A6A",
      textAlign: "center",
      width: "100%",
      py: "20px",
    },
    wrapper: {
      height: "100%",
      maxHeight: "205px",
      overflow: "hidden",
      mt: "8px",
    },
    checkBox: {
      p: "5px",
      "& svg": { marginRight: "0px !important" },
    },
  },
};

export const useStyles = makeStyles(() => ({
  textInputSearch: {
    fontSize: "15px",
    height: "34px",
    backgroundColor: "#F8F8F8",
    paddingLeft: "25px",
    paddingRight: "4px",
  },
}));
