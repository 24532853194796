import { useContext } from "react";

import { ProductPriceContext } from "Pages/ProductPricePage";
import { EmptyScreen, TableHeaderControlPanel } from "components";
import { FilterBlock, ListBlock, TableHeaderBlock } from "./components";

import Box from "@mui/material/Box";
import { CircularProgress, Stack } from "@mui/material";
import { ADMIN_ONLY_VIEW_MESSAGE } from "utils/constants";

export const ProductTabContent = () => {
  const {
    selectedProducts,
    onOpenAllProducts,
    productsList,
    productsLoading,
    productsCount,
    PRODUCT_ACTIONS_LIST,
    onCancelProductSelection,
    fetchAndCheckAll,
    checkAllProducts,
    searchInputDebounced,
    repPermissions,
  } = useContext(ProductPriceContext);

  return (
    <Box>
      <FilterBlock />
      {productsLoading && !productsList.length ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          height="calc(100vh - 312px)"
        >
          <CircularProgress />
        </Stack>
      ) : productsList.length ? (
        <>
          <TableHeaderControlPanel
            actionWrapperProps={{
              sx: { padding: "0 17px 0 15.5px !important" },
            }}
            checkedCount={selectedProducts.length}
            actionsList={PRODUCT_ACTIONS_LIST}
            loading={productsLoading}
            onSelectAll={fetchAndCheckAll}
            hasCheckedItems={!!selectedProducts.length}
            availableSelectCount={productsCount}
            selectName="Product"
            onSelectVisible={checkAllProducts}
            cancelSelection={onCancelProductSelection}
            headerComponent={<TableHeaderBlock />}
          />
          <ListBlock />
        </>
      ) : (
        <EmptyScreen
          type="prices"
          onConfirm={onOpenAllProducts}
          height="calc(100vh - 312px)"
          showAction={!searchInputDebounced}
          disabled={
            !!repPermissions &&
            !repPermissions?.catalog?.create_edit_price_lists
          }
          tooltipTitle={ADMIN_ONLY_VIEW_MESSAGE}
        />
      )}
    </Box>
  );
};
