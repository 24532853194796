import { Box, CircularProgress, MenuItem, Typography } from "@mui/material";
import useStyles, { cl } from "./styles";
import React, { useContext, useMemo } from "react";
import { CustomerProfileContext } from "../../CustomerProfilePage";
import { Link } from "react-router-dom";
import { IosArrowForward } from "components/Icons";
import {
  ActionRestrictionWrapper,
  SquareSelect,
  StyledTooltip,
} from "components";
import { useCustomerActions } from "../../CustomerProfile.hooks";
import ReportDialog from "Pages/ReportsPage/components/ReportDialog/ReportDialog";
import { useReportPage } from "Pages/ReportsPage/ReportsPage.hooks";

const CustomerProfileHeader = () => {
  const classes = useStyles();
  const { customerState, isParent, repPermissions } = useContext(
    CustomerProfileContext
  );

  const {
    customerActions,
    handleCloseReportDialog,
    isOpenReportDialog,
    currentReport,
    isCustom,
    setShowTooltip,
    showTooltip,
  } = useCustomerActions();
  const customer = useMemo(
    () => customerState.customer,
    [customerState.customer]
  );

  const disabledCreateEdit = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.customers?.create_edit;
    }
  }, [repPermissions]);

  const { handleSetFavorite, reportState } = useReportPage();

  return (
    <Box className={classes.headerWrapper}>
      <ReportDialog
        open={isOpenReportDialog}
        onClose={handleCloseReportDialog}
        report={currentReport}
        isCustom={isCustom}
        handleSetFavorite={handleSetFavorite}
        disabledCustomReportBtn={reportState.loading}
      />
      <Box className={classes.pageTitleWrap}>
        <Typography
          variant="h6"
          fontWeight="400"
          fontSize="20px"
          color="primary"
          className="main"
          sx={{ textDecoration: "none" }}
          component={Link}
          to={"/customers"}
        >
          Customers
        </Typography>
        {!isParent && (
          <>
            <Box component="span">
              <IosArrowForward />
            </Box>
            <Typography
              variant="h6"
              fontWeight="400"
              fontSize="20px"
              color="disable.main"
              className="main"
              sx={{ textDecoration: "none" }}
              component={Link}
              to={`/customers/${customer?.parentCustomer?.id}`}
            >
              {customer?.parentCustomer?.displayName ||
                customer?.parentCustomer?.name}
            </Typography>
          </>
        )}
        <Box component="span">
          <IosArrowForward />
        </Box>
        <Typography
          className="secondary"
          color="disable.main"
          variant="h6"
          fontWeight="400"
          fontSize="20px"
        >
          {customer?.displayName || customer?.name}
        </Typography>
        {customerState.loading && <CircularProgress size={25} />}
      </Box>
      {/* Actions */}

      <ActionRestrictionWrapper disableHoverListener={!disabledCreateEdit}>
        <SquareSelect
          value=""
          label="Actions"
          disabled={disabledCreateEdit}
          sx={cl.actionSelect}
          selectLabelSx={cl.actionSelectLabel}
          formSx={cl.actionFormSelect}
          squareSelectLabelPosition="-5px !important"
          squareSelectLabelColor="#707070"
          radiused
          unfocused
          fullWidth
        >
          {customerActions?.map(({ label, onClick, disabled, icon }) => (
            <Box key={label}>
              <StyledTooltip
                placement="top"
                arrow
                sx={{
                  "& .MuiTooltip-tooltip": {
                    maxWidth: "315px",
                    top: "107px !important",
                  },
                }}
                title="Customers with pending orders can't be archived."
                open={showTooltip}
              >
                <Box
                  onMouseEnter={() => {
                    if (label === "Archive" && disabled) {
                      setShowTooltip(true);
                    }
                  }}
                  onMouseLeave={() => {
                    setShowTooltip(false);
                  }}
                  onClick={() => setShowTooltip(false)}
                >
                  <MenuItem
                    disabled={disabled}
                    onClick={onClick}
                    sx={{
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    {icon}
                    <Box
                      sx={{ fontSize: "12px", color: "#3F3F3F", ml: "10px" }}
                    >
                      {label}
                    </Box>
                  </MenuItem>
                </Box>
              </StyledTooltip>
            </Box>
          ))}
        </SquareSelect>
      </ActionRestrictionWrapper>
    </Box>
  );
};

export default CustomerProfileHeader;
