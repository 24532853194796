import { useMemo, useState } from "react";
import { func, object, bool } from "prop-types";
import { Grid, IconButton, Paper, Typography } from "@mui/material";
import { EyeIcon, PenIcon } from "../../../../../components/Icons";
import { useAdmin, separateNumWithComma } from "../../../../../helpers/helpers";
import DiscountsPopper from "../../../components/DiscountsPopper/DiscountsPopper";
import { ActionRestrictionWrapper } from "components";

const PaymentDetails = ({
  order,
  handleOpenDeliveryFeeDialog,
  repPermissions,
  isThirdParty,
  isThirdPartyDraft,
}) => {
  const isAdmin = useAdmin();
  const subtotal = order?.totalRawAmount || 0;

  const discounts =
    order?.totalRawAmount - order?.totalAmountWithoutDelivery || 0;
  const delivery = separateNumWithComma(order?.deliveryFee) || "Free";
  const grandTotal = order?.totalAmount || 0;

  const payments =
    order?.orderPayments?.reduce((acc, cur) => {
      acc += cur?.amount;
      return acc;
    }, 0) || 0;

  const refunds = order?.totalRefundedPayments || 0;
  const openBalance = grandTotal - payments + refunds || 0;
  const [anchorEl, setAnchorEl] = useState(null);

  const disabledRepPermissions = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.orders?.create_edit;
    }
    return false;
  }, [repPermissions]);

  return (
    <Grid xs={2.4} item>
      <Paper
        sx={{ minHeight: "275px" }}
        elevation={0}
        variant="outlined"
        component={Grid}
        container
      >
        <Grid xs={12} item />
        {/* Subtotal */}
        <Grid sx={{ padding: "12px 24px 0 0", height: "38px" }} item container>
          <Grid xs={1.7} item />
          <Grid sx={{ alignItems: "center" }} xs={5.15} item container>
            <Typography fontSize={14} fontWeight="400" color="#000000">
              Subtotal
            </Typography>
          </Grid>
          <Grid
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            xs={5.15}
            item
            container
          >
            <Typography fontSize={14} color="#000000" noWrap>
              {separateNumWithComma(subtotal)}
            </Typography>
          </Grid>
        </Grid>
        {/* Discounts */}
        <Grid sx={{ padding: "4px 24px 0 0", height: "38px" }} item container>
          <DiscountsPopper
            anchorEl={anchorEl}
            order={order}
            handleClose={() => setAnchorEl(null)}
          />
          <Grid
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
              paddingRight: 1,
            }}
            xs={1.7}
            item
            container
          >
            <ActionRestrictionWrapper
              disableHoverListener={!disabledRepPermissions}
            >
              <IconButton
                sx={{
                  backgroundColor: anchorEl ? "#939393" : "#E6E6E6",
                  width: 19,
                  height: 19,
                  p: 0,
                  "&.Mui-disabled": {
                    backgroundColor: "#D5D9D9",
                    opacity: 0.5,
                  },
                }}
                disabled={
                  disabledRepPermissions ||
                  (!order.customerDiscount &&
                    order.appliedDiscountsType === "NONE") ||
                  !discounts
                }
                onClick={(e) => {
                  e.stopPropagation();
                  setAnchorEl(e.currentTarget);
                }}
              >
                <EyeIcon height="6.8" width="10.2" fill="#000000" />
              </IconButton>
            </ActionRestrictionWrapper>
          </Grid>
          <Grid sx={{ alignItems: "center" }} xs={5.15} item container>
            <Typography fontSize={14} fontWeight="400" color="#000000">
              Discounts
            </Typography>
          </Grid>
          <Grid
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            xs={5.15}
            item
            container
          >
            <Typography fontSize={14} color="#000000">
              -{discounts > 0 && separateNumWithComma(discounts)}
            </Typography>
          </Grid>
        </Grid>
        {/* Delivery */}
        <Grid sx={{ paddingRight: "24px", height: "38px" }} item container>
          <Grid
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
              paddingRight: 1,
            }}
            xs={1.7}
            item
            container
          >
            {isThirdParty || isThirdPartyDraft ? null : (
              <ActionRestrictionWrapper
                disableHoverListener={!disabledRepPermissions}
              >
                <IconButton
                  sx={{
                    backgroundColor: "#E6E6E6",
                    width: 19,
                    height: 19,
                    p: 0,
                    "&.Mui-disabled": {
                      backgroundColor: "#D5D9D9",
                      opacity: 0.5,
                    },
                  }}
                  disabled={
                    disabledRepPermissions ||
                    isAdmin ||
                    ["COMPLETED", "CANCELED"].includes(order?.orderStatus)
                  }
                  onClick={handleOpenDeliveryFeeDialog}
                >
                  <PenIcon size={9} color="#000000" />
                </IconButton>
              </ActionRestrictionWrapper>
            )}
          </Grid>
          <Grid sx={{ alignItems: "center" }} xs={5.15} item container>
            <Typography fontSize={14} fontWeight="400" color="#000000">
              Delivery
            </Typography>
          </Grid>
          <Grid
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            xs={5.15}
            item
            container
          >
            <Typography fontSize={14} color="#000000">
              {isThirdParty || isThirdPartyDraft ? "N/A" : delivery}
            </Typography>
          </Grid>
        </Grid>
        {/* Grand Total */}
        <Grid sx={{ paddingRight: "24px", height: "38px" }} item container>
          <Grid xs={1.7} item />
          <Grid
            sx={{ borderTop: "1px solid #CCCCCC", alignItems: "center" }}
            xs={5.15}
            item
            container
          >
            <Typography fontSize={14} fontWeight={500} color="#000000">
              Grand Total
            </Typography>
          </Grid>
          <Grid
            sx={{
              borderTop: "1px solid #CCCCCC",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            xs={5.15}
            item
            container
          >
            <Typography fontSize={14} fontWeight={600} color="#47A06D" noWrap>
              $ {separateNumWithComma(grandTotal)}
            </Typography>
          </Grid>
        </Grid>
        {/* Payments */}
        {payments > 0 ? (
          <Grid sx={{ paddingRight: "24px", height: "38px" }} item container>
            <Grid xs={1.7} item />
            <Grid
              sx={{
                borderTop: "1px solid #CCCCCC",
                alignItems: "center",
                pt: 1.2,
              }}
              xs={5.15}
              item
              container
            >
              <Typography fontSize={14} fontWeight="400" color="#000000">
                Payments
              </Typography>
            </Grid>
            <Grid
              sx={{
                borderTop: "1px solid #CCCCCC",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              xs={5.15}
              item
              container
            >
              <Typography
                paddingTop={1.2}
                fontSize={14}
                fontWeight="400"
                color="#000000"
              >
                - {separateNumWithComma(payments)}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {/* Refunds */}
        {refunds > 0 ? (
          <Grid sx={{ paddingRight: "24px", height: "38px" }} item container>
            <Grid xs={1.7} item />
            <Grid sx={{ alignItems: "center" }} xs={5.15} item container>
              <Typography fontSize={14} fontWeight="400" color="#000000">
                Refunds
              </Typography>
            </Grid>
            <Grid
              sx={{
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              xs={5.15}
              item
              container
            >
              <Typography fontSize={14} fontWeight="400" color="#000000">
                {separateNumWithComma(refunds)}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {/* Open balance */}
        {openBalance > 0 && (refunds > 0 || payments > 0) ? (
          <Grid
            sx={{
              paddingRight: "24px",
              height: "38px",
              borderTop: "1px solid #CCCCCC",
            }}
            item
            container
          >
            <Grid xs={1.7} item />
            <Grid sx={{ alignItems: "center" }} xs={5.15} item container>
              <Typography fontSize={14} fontWeight={500} color="#000000">
                Open balance
              </Typography>
            </Grid>
            <Grid
              sx={{
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              xs={5.15}
              item
              container
            >
              <Typography fontSize={14} fontWeight={600} color="#47A06D" noWrap>
                $ {separateNumWithComma(openBalance)}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </Paper>
    </Grid>
  );
};
PaymentDetails.propTypes = {
  order: object,
  handleOpenDeliveryFeeDialog: func,
  repPermissions: object,
  isThirdParty: bool,
  isThirdPartyDraft: bool,
};

export default PaymentDetails;
