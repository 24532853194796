import { useMemo, useState } from "react";
import { bool, func, object, string } from "prop-types";
import { Link as NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";

import { CheckmarkIcon, CrossIcon, EyeIcon } from "components/Icons";
import {
  useAdmin,
  photoUrl,
  stringAvatar,
  truncateText,
} from "helpers/helpers";
import { MANUFACTURER_STATUSES } from "utils/constants";
import {
  ActionRestrictionWrapper,
  StyledMenu,
  StyledTooltip,
  ThreeDotButton,
} from "components";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";

const ManufacturersItem = ({
  manufacturer,
  lastItem,
  setContactsList,
  handleDeleteManufacturer,
  handleArchiveManufacturer,
  setContactsAnchorEl,
  currentTab,
  repPermissions,
}) => {
  const isAdmin = useAdmin();
  const [hoverColor, setHoverColor] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const classes = {
    grid: {
      display: "flex",
      alignItems: "center",
    },
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openHeaderList = Boolean(anchorEl);

  const handleClickOnDotsBtn = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleCloseDotsBtn = () => {
    setAnchorEl(null);
  };

  const disabledCreateEdit = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.catalog?.create_edit;
    }
  }, [repPermissions]);

  const handleClickOnActionBtn = (e, label) => {
    switch (label) {
      case "Archive Manufacturer":
        dispatch(
          openConfirmDialogAction({
            title: `${
              e.status === MANUFACTURER_STATUSES.INACTIVE
                ? "Restore"
                : "Archive"
            } ${truncateText(e.name, 25)}?`,
            text:
              e.status === MANUFACTURER_STATUSES.INACTIVE
                ? "Restoring this manufacturer will show it and all associated products from your sales channels again. " +
                  "If you need to archive this Manufacturer or its Products at a later time, you can make it inactive again."
                : "Archiving this manufacturer will hide it and all associated products from your sales channels. " +
                  "If you need to use this Manufacturer or its Products at a later time, you can make it active again.",
            userName: null,
            text2: null,
            isIcon: false,
            buttons: (
              <>
                <Button
                  sx={{
                    width: "98px",
                    color: "#6A6A6A",
                    borderColor: "#D4D4D4",
                    fontSize: "13px",
                    height: "28px",
                  }}
                  onClick={() => {
                    dispatch(setConfirmIsOpenAction(false));
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: "98px",
                    color: "#FFFFFF",
                    fontSize: "13px",
                    height: "28px",
                    boxShadow: "none",
                  }}
                  color="primary"
                  onClick={() => {
                    dispatch(setConfirmIsOpenAction(false));
                    handleArchiveManufacturer(
                      e.id,
                      e.status === MANUFACTURER_STATUSES.INACTIVE
                        ? "ACTIVE"
                        : "INACTIVE"
                    );
                  }}
                  variant="contained"
                >
                  {e.status === MANUFACTURER_STATUSES.INACTIVE
                    ? "Restore"
                    : "Archive"}
                </Button>
              </>
            ),
          })
        );
        break;
      case "Delete":
        dispatch(
          openConfirmDialogAction({
            title: `Delete ${e.name}?`,
            text: (
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 400,
                  letterSpacing: 0,
                  color: "#707070",
                }}
              >
                Are you sure you want to delete this manufacturer? <br />
                <span style={{ fontWeight: 400, color: "black" }}>
                  All products & discounts{" "}
                </span>
                associated with this manufacturer will be deleted. This can’t be
                undone.
              </Typography>
            ),
            userName: null,
            text2: null,
            isIcon: true,
            buttons: (
              <>
                <Button
                  sx={{
                    width: "98px",
                    color: "#6A6A6A",
                    borderColor: "#D4D4D4",
                    fontSize: "13px",
                    height: "28px",
                  }}
                  onClick={() => {
                    dispatch(setConfirmIsOpenAction(false));
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: "98px",
                    color: "#FFFFFF",
                    fontSize: "13px",
                    height: "28px",
                    boxShadow: "none",
                  }}
                  color="confirmDelete"
                  onClick={() => {
                    dispatch(setConfirmIsOpenAction(false));
                    handleDeleteManufacturer(e.id);
                  }}
                  variant="contained"
                >
                  Delete
                </Button>
              </>
            ),
          })
        );
        break;
      default:
        break;
    }
    setAnchorEl(null);
  };

  return (
    <Paper
      sx={{
        height: "51px",
        backgroundColor: hoverColor,
        borderBottom: lastItem ? "none" : "1px solid #D5D9D9",
      }}
      elevation={0}
      square
      component={Grid}
      container
      columns={24}
      onMouseMove={() => setHoverColor("#F7F7F7")}
      onMouseLeave={() => setHoverColor("#FFF")}
    >
      <Grid
        item
        sx={{
          ...classes.grid,
          textAlign: "center",
          justifyContent: "center",
          marginLeft: "46px",
        }}
      >
        {manufacturer.status === MANUFACTURER_STATUSES.INACTIVE ? (
          <CrossIcon fill="#FF0000" />
        ) : (
          <CheckmarkIcon />
        )}
      </Grid>
      <Grid item xs={2} sx={{ ...classes.grid, pl: "0", ml: "32px" }}>
        <Typography
          sx={{
            fontSize: "12px",
            color: "#1C1C19",
          }}
        >
          {manufacturer.customId}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          ...classes.grid,
          textOverflow: "ellipsis",
          position: "relative",
        }}
      >
        {manufacturer.photo ? (
          <Avatar
            src={photoUrl(manufacturer.photo.fileName)}
            sx={{ width: 32, height: 32 }}
          />
        ) : (
          <Avatar
            {...stringAvatar(manufacturer.name, {
              width: 32,
              height: 32,
              fontSize: "13px",
            })}
          />
        )}
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            color: "#3F3F3F",
            ml: "14px",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          component={disabledCreateEdit ? "span" : NavLink}
          to={`/catalog/manufacturers/${manufacturer.id}`}
          state={{ currentTab }}
          from={pathname}
          onClick={(e) => {
            if (disabledCreateEdit) return;

            if (e.metaKey || e.ctrlKey) return;
            e.preventDefault();
            navigate(`manufacturers/${manufacturer.id}`, {
              state: { currentTab },
            });
          }}
          noWrap
        >
          {manufacturer.name}
        </Typography>
      </Grid>
      <Grid item xs={3} sx={classes.grid}>
        <StyledTooltip title={manufacturer.email} placement="top" arrow isDark>
          <Typography
            sx={{
              fontSize: "12px",
              color: "#1C1C19",
            }}
            noWrap
          >
            {manufacturer.email ? manufacturer.email : "-"}
          </Typography>
        </StyledTooltip>
      </Grid>
      <Grid item xs={3} sx={classes.grid}>
        {!!manufacturer.contacts.length && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setContactsAnchorEl(e.target);
              setContactsList(manufacturer.contacts);
            }}
          >
            <EyeIcon />
          </IconButton>
        )}
        {manufacturer.contacts.length > 0 ? (
          <Typography
            sx={{
              fontSize: "12px",
              color: "#1C1C19",
            }}
          >
            {manufacturer.contacts.length} Contacts
          </Typography>
        ) : (
          <Typography ml={1}>-</Typography>
        )}
      </Grid>
      <Grid item xs={3} sx={classes.grid}>
        <Typography fontSize={12} color="#1C1C19" ml="7px">
          {manufacturer?._count?.products}
        </Typography>
      </Grid>
      <Grid
        item
        xs
        sx={{
          ...classes.grid,
          justifyContent: "flex-end",
        }}
      >
        <ActionRestrictionWrapper disableHoverListener={!disabledCreateEdit}>
          <ThreeDotButton
            disabled={disabledCreateEdit || isAdmin}
            onClick={handleClickOnDotsBtn}
            paddingRight="14px"
          />
        </ActionRestrictionWrapper>

        <StyledMenu
          sx={{ paddingY: 0 }}
          anchorEl={anchorEl}
          isOpen={openHeaderList}
          handleClose={handleCloseDotsBtn}
        >
          <MenuItem onClick={handleCloseDotsBtn}>
            <ListItemText
              sx={classes.threeDotsText}
              onClick={() =>
                handleClickOnActionBtn(manufacturer, "Archive Manufacturer")
              }
            >
              {manufacturer.status === MANUFACTURER_STATUSES.INACTIVE
                ? "Restore "
                : "Archive "}
              Manufacturer
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={handleCloseDotsBtn}>
            <ListItemText
              classes={{ primary: "deleteItem" }}
              sx={{
                "& > .MuiTypography-root": {
                  color: "#FF6254",
                },
              }}
              onClick={(e) =>
                handleClickOnActionBtn(manufacturer, e.target.innerText)
              }
            >
              Delete
            </ListItemText>
          </MenuItem>
        </StyledMenu>
      </Grid>
    </Paper>
  );
};

ManufacturersItem.propTypes = {
  manufacturer: object,
  lastItem: bool,
  setContactsList: func,
  handleOpenManufacturerProfile: func,
  handleDeleteManufacturer: func,
  handleArchiveManufacturer: func,
  setContactsAnchorEl: func,
  currentTab: string,
  repPermissions: object,
};
ManufacturersItem.defaultTypes = {
  manufacturer: null,
};

export default ManufacturersItem;
