import { useCallback, useMemo, useState } from "react";
import { bool, array, object, number, func } from "prop-types";
import Nestable from "react-nestable";
import {
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Stack,
  IconButton,
  Typography,
} from "@mui/material";
import {
  CheckboxIcon,
  CheckboxIconChecked,
  DragAndDropIcon,
  OutlinedPlusIcon,
} from "components/Icons";
import CloseIcon from "@mui/icons-material/Close";
import { generateUUID, truncateText } from "helpers/helpers";
import { Input } from "./index";
import { StyledTooltip } from "components";

export const MultiAnswerContentBlock = ({
  isActive,
  options,
  setValue,
  formField,
  orderIndex,
  control,
  errors,
  trigger,
  _count,
}) => {
  const responses = _count?.responses || 0;

  const hasResponses = responses > 0;

  const [hoveredElement, setHoveredElement] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const tempList = useMemo(() => [...formField?.questions], [formField]);
  const indexOfQuestion = useMemo(
    () => formField?.questions.findIndex((q) => q.orderIndex === orderIndex),
    [formField?.questions, orderIndex]
  );
  const questionsList = useMemo(
    () => tempList[indexOfQuestion]?.options,
    [indexOfQuestion, tempList]
  );

  const handleDragStart = () => setIsDragging(true);

  const handleAddOption = useCallback(() => {
    const preparedQuestion = {
      ...formField.questions[indexOfQuestion],
      options: [
        ...formField.questions[indexOfQuestion].options,
        {
          title: `Answer ${
            formField.questions[indexOfQuestion].options.length + 1
          }`,
          uuid: generateUUID(),
          orderIndex: formField.questions[indexOfQuestion].options.length,
        },
      ],
    };

    tempList.splice(indexOfQuestion, 1, preparedQuestion);

    setValue("questions", tempList, { shouldDirty: true });
  }, [formField.questions, indexOfQuestion, setValue, tempList]);

  const handleDeleteOption = useCallback(
    (item) => {
      if (options?.length <= 2) return;

      const questionIndex = questionsList?.findIndex(
        (q) => q.uuid === item.uuid
      );

      questionsList.splice(questionIndex, 1);

      tempList.splice(indexOfQuestion, 1, {
        ...formField.questions[indexOfQuestion],
        options: questionsList.map((q, i) => ({ ...q, orderIndex: i })),
      });

      setValue("questions", tempList, { shouldDirty: true });
      trigger(`questions.${indexOfQuestion}.options`);
    },
    [
      formField.questions,
      indexOfQuestion,
      options?.length,
      questionsList,
      setValue,
      tempList,
      trigger,
    ]
  );

  const handleUpdateOption = useCallback(() => setActiveItem(null), []);

  const handleChangeDnd = useCallback(
    (items) => {
      setIsDragging(false);

      const preparedQuestion = {
        ...formField.questions[indexOfQuestion],
        options: [
          ...items.map((item, index) => {
            return {
              ...item,
              orderIndex: index,
            };
          }),
        ],
      };

      tempList.splice(indexOfQuestion, 1, preparedQuestion);

      setValue("questions", tempList, { shouldDirty: true });

      trigger(`questions.${indexOfQuestion}.options`);
    },
    [formField.questions, indexOfQuestion, setValue, tempList, trigger]
  );

  const disabledAddBtn = questionsList?.length >= 20;

  return (
    <Box
      sx={{
        mt: 1,
        border: "0.5px solid #E3E3E3",
        borderRadius: "4px",
        bgcolor: "#D5D5D516",
        p: "16px 16px 24px 16px",
      }}
    >
      <Box mb={0.5}>
        <Typography fontSize={14} color="#56565680">
          Answer preview
        </Typography>
      </Box>
      <Stack gap="14px" mt={0.5}>
        {!!options?.length && (
          <Nestable
            handler={
              <Stack
                draggable
                alignItems="center"
                width="16px"
                height="16px"
                onDragStart={handleDragStart}
              >
                {<DragAndDropIcon />}
              </Stack>
            }
            maxDepth={0}
            idProp="uuid"
            items={options}
            onChange={({ items }) => handleChangeDnd(items)}
            renderItem={({ item, handler }, index) => (
              <Stack
                key={index}
                direction="row"
                alignItems="center"
                gap="18px"
                ml="-24px"
                height="19px"
                maxWidth="500px"
                width="100%"
                onMouseEnter={() => {
                  if (hasResponses) return;
                  setHoveredElement(item?.uuid);
                }}
                onMouseLeave={() => {
                  if (isDragging) return;
                  setHoveredElement(null);
                }}
              >
                <Box
                  component="span"
                  sx={{
                    opacity: isActive && hoveredElement === item?.uuid ? 1 : 0,
                    transition: "all 0.3s",
                    transform: "rotate(90deg)",
                  }}
                >
                  {handler}
                </Box>
                <FormControlLabel
                  sx={{
                    ...(isActive && {
                      "&:hover": {
                        borderRadius: "6px",
                        bgcolor:
                          !!activeItem?.uuid &&
                          activeItem?.uuid === item?.uuid &&
                          isActive
                            ? null
                            : "#D5D5D532",
                      },
                    }),
                    minWidth:
                      !!activeItem?.uuid &&
                      activeItem?.uuid === item?.uuid &&
                      isActive
                        ? "100%"
                        : "fit-content",
                    color: "red",
                    "& .MuiFormControlLabel-label": {
                      width: "100%",

                      ...(!!errors?.questions?.[orderIndex]?.options?.[
                        item?.orderIndex
                      ] && { maxWidth: "162px" }),
                    },
                    "& .MuiTypography-root": {
                      color: "#5F6267",
                      fontSize: 13,
                      ml: "4px",
                    },
                  }}
                  label={
                    !!errors?.questions?.[orderIndex]?.options?.[
                      item?.orderIndex
                    ] ||
                    (!!activeItem?.uuid &&
                      activeItem?.uuid === item?.uuid &&
                      isActive) ? (
                      <Box ml="-4px">
                        <Input
                          {...{
                            handleUpdateOption,
                            item,
                            control,
                            orderIndex,
                            trigger,
                          }}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          gap: "8px",
                          width: "100%",
                          height: "22px",
                        }}
                      >
                        {item?.title
                          ? truncateText(item?.title, 56)
                          : "Enter the answer option"}

                        <Stack mr={0.5}>
                          <IconButton
                            sx={{
                              ml: "auto",
                              p: 0,
                              opacity:
                                isActive &&
                                hoveredElement === item?.uuid &&
                                options?.length > 2
                                  ? 1
                                  : 0,
                              width: "16px",
                              height: "16px",
                              transition: "all 0.3s",
                              background: "#939393",
                              borderRadius: "50%",

                              "&:hover": {
                                background: "#939393",
                              },
                            }}
                            onClick={(e) => {
                              if (
                                !(
                                  isActive &&
                                  hoveredElement === item?.uuid &&
                                  options?.length > 2
                                )
                              )
                                return;

                              e.stopPropagation();
                              handleDeleteOption(item);
                            }}
                          >
                            <CloseIcon sx={{ fontSize: 14, color: "#FFF" }} />
                          </IconButton>
                        </Stack>
                      </Box>
                    )
                  }
                  control={
                    <Box
                      sx={{
                        width: "25.5px",
                        minWidth: "25.5px",
                        height: "25.5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "4px",
                      }}
                    >
                      <Checkbox
                        sx={{ p: 0 }}
                        icon={<CheckboxIcon size={14} stroke="#00000032" />}
                        checkedIcon={<CheckboxIconChecked size={15.5} />}
                      />
                    </Box>
                  }
                  onClick={() => {
                    if (hasResponses) return;
                    setActiveItem(item);
                  }}
                  value={item?.title}
                />
              </Stack>
            )}
          />
        )}

        {!hasResponses && (
          <StyledTooltip
            arrow
            placement="left"
            isDark
            title="Max answer options reached"
            disableHoverListener={!disabledAddBtn}
            disableFocusListener={!disabledAddBtn}
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: { offset: [0, -5] },
                },
              ],
            }}
          >
            <Box component="span">
              <Button
                disableRipple
                disabled={disabledAddBtn}
                sx={{
                  ml: "4px",
                  color: "#5F6267",
                  height: "18px",
                  fontSize: "13px",
                  fontWeight: 400,
                  padding: 0,
                  whiteSpace: "nowrap",
                  width: "fit-content",
                  minWidth: 0,

                  "& svg": {
                    width: "16px",
                    height: "16px",
                    marginRight: "9px",
                  },
                }}
                onClick={handleAddOption}
              >
                <OutlinedPlusIcon circleColor="#47A06D" plusColor="#47A06D" />
                Add new
              </Button>
            </Box>
          </StyledTooltip>
        )}
      </Stack>
    </Box>
  );
};

MultiAnswerContentBlock.propTypes = {
  isActive: bool,
  options: array,
  setValue: func,
  formField: object,
  orderIndex: number,
  control: object,
  errors: object,
  trigger: func,
  _count: object,
};
