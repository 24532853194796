import { Stack } from "@mui/material";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";
import React, { useContext } from "react";
import { EmptyUserBlock } from "../..";
import { RepsItem } from "./RepsItem";

export const RepsList = () => {
  const { formField, onRepsDelete, onChangeAllReps } =
    useContext(FormViewContext);

  return (
    <Stack>
      {formField._count.assignedRepresentatives ? (
        <RepsItem
          isSingleSelect={formField._count.assignedRepresentatives === 1}
          list={formField.assignedRepresentatives}
          onDelete={onRepsDelete}
          onEdit={() => onChangeAllReps(true)}
          count={formField._count.assignedRepresentatives}
        />
      ) : (
        <EmptyUserBlock label="No reps selected" />
      )}
    </Stack>
  );
};
