export const FORMS_FILTER = "FORMS_FILTER";
export const RESET_FORMS_FILTER = "RESET_FORMS_FILTER";

export const formsFilterAction = (payload) => ({
  type: FORMS_FILTER,
  payload,
});

export const resetFormsFilterAction = () => ({
  type: RESET_FORMS_FILTER,
});
