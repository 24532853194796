import { string, object, func } from "prop-types";
import { Box, Paper, Typography } from "@mui/material";
import { CheckboxComponent } from "../../../components";
import { useAdmin } from "helpers/helpers";

export const TitleBlock = ({ name, control, handleWebAccess }) => {
  const isAdmin = useAdmin();

  return (
    <Paper
      sx={{
        height: "64px",
        border: "0.5px solid #D5D9D9",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: "21px",
      }}
      variant="outlined"
    >
      <Typography fontSize={16} fontFamily={500} color="#000">
        {name}
      </Typography>

      <Box>
        <CheckboxComponent
          type="portalAccess"
          label="Activate access"
          disabled={isAdmin}
          isAdmin={isAdmin}
          control={control}
          handleWebAccess={handleWebAccess}
          // checkedColor={!profile?.id ? "rgba(71, 160, 110, 0.5)" : "#47a06d"}
        />
      </Box>
    </Paper>
  );
};

TitleBlock.propTypes = { name: string, control: object, handleWebAccess: func };
TitleBlock.defaultProps = { name: "", control: {} };
