import { useEffect, useState } from "react";
import { shape, string } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import {
  activitiesSelector,
  activitiesCountSelector,
} from "../../../../../../redux/selectors/orders";
import {
  getActivitiesAction,
  getDraftActivitiesAction,
} from "../../../../../../redux/actions/orders";

import { CircularProgress, Grid, Paper, Typography, Box } from "@mui/material";

import ActivityItem from "./ActivityItem";
import { useCallback } from "react";
import {
  DRAFT_ORDER_TYPES,
  SCROLL_LIMIT_ACTIVITIES,
} from "../../../../../../utils/constants";
import { currentUserSelector } from "../../../../../../redux/selectors/auth";
import { InfiniteScrollWrapper } from "components";

const selector = createSelector(
  activitiesSelector,
  activitiesCountSelector,
  currentUserSelector,
  (activitiesList, activitiesCount, currentUser) => ({
    activitiesList,
    activitiesCount,
    currentUser,
  })
);

const Activity = ({ order }) => {
  const {
    activitiesList,
    activitiesCount,
    currentUser: { timeZone },
  } = useSelector(selector);
  const dispatch = useDispatch();

  const [limitActivities, setLimitActivities] = useState(
    2 * SCROLL_LIMIT_ACTIVITIES
  );

  const isOrderDraft = [
    DRAFT_ORDER_TYPES.open,
    DRAFT_ORDER_TYPES.closed,
  ].includes(order?.status);

  useEffect(() => {
    if (order.id) {
      isOrderDraft
        ? dispatch(
            getDraftActivitiesAction(order.id, {
              limit: SCROLL_LIMIT_ACTIVITIES,
              sort_by_date: "desc",
            })
          )
        : dispatch(
            getActivitiesAction(order.id, {
              limit: SCROLL_LIMIT_ACTIVITIES,
              sort_by_date: "desc",
            })
          );
    }
  }, [dispatch, isOrderDraft, order]);

  const handleFetchActivities = useCallback(() => {
    isOrderDraft
      ? dispatch(
          getDraftActivitiesAction(
            order.id,
            {
              limit: limitActivities,
              sort_by_date: "desc",
            },
            true
          )
        )
      : dispatch(
          getActivitiesAction(
            order.id,
            {
              limit: limitActivities,
              sort_by_date: "desc",
            },
            true
          )
        );
    setLimitActivities((prev) => prev + SCROLL_LIMIT_ACTIVITIES);
  }, [dispatch, isOrderDraft, limitActivities, order.id]);

  return (
    <Grid xs={6.08} item>
      <Paper
        sx={{
          maxHeight: "275px",
          height: "275px",
          alignContent: "flex-start",
          overflowY: "auto",
          width: "100%",
        }}
        elevation={0}
        variant="outlined"
        component={Grid}
      >
        <Box width="100%">
          <InfiniteScrollWrapper
            maxHeight="273px"
            dataLength={activitiesList.length}
            id="activities-scroll-table"
            loader={
              <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />
            }
            nextFunc={handleFetchActivities}
            hasMoreItems={activitiesList.length < activitiesCount}
          >
            <Grid
              sx={{
                height: "45px",
                alignItems: "center",
                width: "100%",
                paddingLeft: "14px",
              }}
              xs={12}
              item
              container
            >
              <Typography
                mt={3.4}
                ml={1}
                fontSize={12}
                fontWeight={500}
                color="#000000"
              >
                ACTIVITY
              </Typography>
            </Grid>
            {activitiesList.map((activity, i) => (
              <ActivityItem
                key={activity.id}
                index={i}
                activity={activity}
                timeZone={timeZone}
                lastItem={
                  !!(activitiesList?.length === i + 1 && activitiesList?.length)
                }
                order={order}
              />
            ))}
          </InfiniteScrollWrapper>
        </Box>
      </Paper>
    </Grid>
  );
};
Activity.apply.propTypes = {
  order: shape({
    id: string,
  }),
};

export default Activity;
