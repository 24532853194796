import * as React from "react";
export const UploadFileIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.063 17.5a3.437 3.437 0 0 0 3.437-3.437v-3.75a3.437 3.437 0 0 0-3.437-3.438h-.626a.937.937 0 1 0 0 1.875h.626c.862 0 1.562.7 1.562 1.563v3.75c0 .862-.7 1.562-1.562 1.562H5.937c-.862 0-1.562-.7-1.562-1.562v-3.75c0-.863.7-1.563 1.563-1.563h.625a.937.937 0 1 0 0-1.875h-.625A3.437 3.437 0 0 0 2.5 10.313v3.75A3.437 3.437 0 0 0 5.938 17.5zm-.588-11.837a.94.94 0 0 0 0-1.326l-2.812-2.812a.937.937 0 0 0-1.326 0L6.525 4.337A.937.937 0 1 0 7.85 5.663L9.063 4.45v8.987a.937.937 0 1 0 1.874 0V4.45l1.213 1.212a.937.937 0 0 0 1.325 0"
      fill="#939393"
    />
  </svg>
);
