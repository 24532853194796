import { useRef, useEffect, useState, useCallback, useMemo } from "react";
import { func, number, object, string } from "prop-types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentOrderTab } from "redux/actions/orders";

import {
  CircularProgress,
  IconButton,
  ListItemText,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  Box,
  Button,
} from "@mui/material";
import {
  ActionRestrictionWrapper,
  StyledButton,
  StyledMenu,
  StyledTooltip,
} from "components";
import { PrinterIcon } from "components/Icons";

import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import { TABS } from "./constants";
import { useAdmin } from "helpers/helpers";
import { useThirdPartyOrdersTabActions } from "Pages/OrdersPage/useThirdPartyOrdersTabActions";
import { SETTINGS_TABS } from "Pages/SettingsPage/SettingsPage.constants";
import { useRepsPermissions } from "helpers/hooks";
import { useExport } from "Pages/SettingsPage/components/SettingsTabs/ImportExportTab/ImportExport.hooks";
import { ExportDialog } from "Pages/SettingsPage/components/SettingsTabs/ImportExportTab/components/ExportDialog";
import { createSelector } from "reselect";
import { openSnackbarSelector } from "redux/selectors/snackbar";
import {
  confirmDialogFormChangedSelector,
  editTypeSelector,
} from "redux/selectors/confirmDialogs";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  ADMIN_ONLY_VIEW_MESSAGE_PAGE,
} from "utils/constants";

const CloneProps = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children, ...other } = props;
  return children(other);
};

const selector = createSelector(
  openSnackbarSelector,
  editTypeSelector,
  confirmDialogFormChangedSelector,
  (openSnackbar, editType, formChanged) => ({
    openSnackbar,
    editType,
    formChanged,
  })
);

const OrdersPageTab = ({
  checkedCount,
  handlePrintOrders,
  handlePrintPackingSlips,
  handlePrintPickList,
  setPrintInvoiceListDialog,
  printLoading,
  setPrintLoading,
  currentTab,
  setCurrentTab,
}) => {
  const isAdmin = useAdmin();
  const { pathname } = useLocation();
  const { deleteThirdPartyOrdersCount, thirdPartyOrdersCount } =
    useThirdPartyOrdersTabActions();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { openSnackbar, editType, formChanged } = useSelector(selector);

  const [menuOpen, setMenuOpen] = useState(false);
  const printRef = useRef(null);

  useEffect(() => {
    dispatch(setCurrentOrderTab(currentTab));
  }, [currentTab, dispatch]);

  useEffect(() => {
    currentTab === "3rd Party" &&
      thirdPartyOrdersCount > 0 &&
      deleteThirdPartyOrdersCount();
  }, [currentTab, deleteThirdPartyOrdersCount, thirdPartyOrdersCount]);

  useEffect(() => {
    if (!printLoading.loading || printLoading.downloading) return;
    const delay = 500; //ms
    const updProgress = Math.round(100 / checkedCount);
    const interval = setInterval(() => {
      setPrintLoading((prev) => ({
        ...prev,
        progress:
          prev.progress + updProgress > 100 ? 100 : prev.progress + updProgress,
      }));
    }, delay);

    return () => {
      clearInterval(interval);
    };
  }, [
    checkedCount,
    printLoading.loading,
    printLoading.downloading,
    setPrintLoading,
  ]);

  const handleConfirmCheckedItemsDialog = useCallback(
    (nav, tab) => {
      dispatch(
        openConfirmDialogAction({
          path: nav,
          title: "Leaving this page?",
          text: "Your selected list will be discarded.",
          buttons: (
            <>
              <StyledButton
                variant="outlined"
                color="cancel"
                type="submit"
                form={`new-${editType}-form`}
                label="Cancel"
                sx={{ height: "28px" }}
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                }}
              />
              <Button
                sx={{
                  width: "auto",
                  height: "28px",
                  color: "#FFFFFF",
                  fontSize: "13px",
                  boxShadow: "none",
                }}
                color="confirmDelete"
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                  dispatch(setFormChangedAction(false));
                  if (nav) return navigate(nav);
                  if (tab) return setCurrentTab(tab);
                }}
                variant="contained"
              >
                Confirm
              </Button>
            </>
          ),
        })
      );
    },
    [dispatch, editType, navigate, setCurrentTab]
  );

  const handleChangeCurrentTab = (newValue) => {
    setCurrentTab(newValue);
    dispatch(setCurrentOrderTab(newValue));
  };

  const repPermissions = useRepsPermissions();

  const disabledTabs = (tab) => {
    if (tab === TABS[0]) {
      if (repPermissions) {
        return !repPermissions?.orders?.view;
      }
    }

    if (tab === TABS[1]) {
      if (repPermissions) {
        return !repPermissions?.orders?.view;
      }
    }

    if (tab === TABS[2]) {
      if (repPermissions) {
        return !repPermissions?.orders?.view;
      }

      return false;
    }
  };

  const disabledCreateBtn = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.orders?.create_edit;
    }
  }, [repPermissions]);

  const disabledImportBtn = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.orders?.import;
    }

    return false;
  }, [repPermissions]);

  const disabledExportBtn = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.orders?.export;
    }

    return false;
  }, [repPermissions]);

  const {
    handleSubmit,
    control,
    exportOpenData,
    updateExportState,
    formField,
    onSubmit,
  } = useExport();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        px: "32px",
        backgroundColor: "#F0F0F0",
        borderBottom: "0.5px solid #D4D4D4",
        height: "44px",
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ position: "absolute" }}
        id="orders-export-form"
      >
        <ExportDialog
          open={exportOpenData.open}
          type={exportOpenData.type}
          onClose={() => updateExportState({ open: false })}
          control={control}
          format={formField.format}
          form="orders-export-form"
        />
      </form>
      <StyledMenu
        anchorEl={printRef.current}
        isOpen={menuOpen}
        handleClose={() => setMenuOpen(false)}
        sx={{ p: 0 }}
      >
        <MenuItem
          onClick={() => {
            setMenuOpen(false);

            if (currentTab === TABS[1]) {
              setPrintInvoiceListDialog(true);
            } else {
              handlePrintOrders();
            }
          }}
        >
          <ListItemText>Print order(s)</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMenuOpen(false);
            handlePrintPackingSlips();
          }}
        >
          <ListItemText>Print packing slip(s)</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMenuOpen(false);
            handlePrintPickList();
          }}
        >
          <ListItemText>Print picklist(s)</ListItemText>
        </MenuItem>
      </StyledMenu>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 3,
          position: "relative",
        }}
      >
        <Box>
          {
            <Typography
              variant="h6"
              fontWeight="400"
              fontSize="20px"
              color="#9C9C94"
            >
              Orders
            </Typography>
          }
        </Box>
        <Tabs
          sx={{
            minHeight: "auto",
            height: "44px",
            "& .Mui-selected": {
              backgroundColor: "transparent!important",
            },
          }}
          value={TABS.findIndex((t) => t === currentTab) || 0}
          onChange={(e, newVal) => {
            if (formChanged && editType === "checked_items") {
              e.preventDefault();
              return handleConfirmCheckedItemsDialog("", newVal);
            }
            handleChangeCurrentTab(newVal);
          }}
        >
          {TABS.map((tab) => (
            <CloneProps key={tab}>
              {(tabProps) => (
                <ActionRestrictionWrapper
                  disableHoverListener={!disabledTabs(tab)}
                  childrenWrapper={{
                    "&:last-child": {
                      position: "relative",
                      "&:last-child::after": {
                        content: '""',
                        position: "absolute",
                        height: "16px",
                        width: "1px",
                        backgroundColor: "#9C9C94",
                        left: 0,
                        top: "50%",
                        transform: "translateY(-50%)",
                      },
                    },
                  }}
                >
                  <Tab
                    {...tabProps}
                    sx={{
                      py: 0,
                      height: "44px",
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#9C9C94",
                    }}
                    disabled={disabledTabs(tab)}
                    label={
                      <Box
                        sx={{
                          textTransform: "none",
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        {tab}
                        {thirdPartyOrdersCount !== 0 && tab === TABS[1] && (
                          <Box
                            sx={{
                              boxShadow: "0 0 0 rgba(64, 154, 101, 0.9)",
                              animation: "shadowPulse 2s infinite",
                              borderRadius: "50%",
                              width: "14px",
                              height: "14px",
                              display: "flex",
                              fontSize: "8px",
                              alignItems: "center",
                              justifyContent: "center",
                              background: "#46A06C",
                              color: "#FFFFFF",
                            }}
                          >
                            {thirdPartyOrdersCount}
                          </Box>
                        )}
                      </Box>
                    }
                    value={tab}
                  />
                </ActionRestrictionWrapper>
              )}
            </CloneProps>
          ))}
        </Tabs>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 1,
        }}
      >
        {printLoading.loading && (
          <>
            <CircularProgress
              variant="determinate"
              size="20px"
              value={printLoading.progress}
            />
            <Typography color="primary">
              {printLoading.progress >= 0 &&
                printLoading.progress <= 100 &&
                `${printLoading.progress}% `}
              {printLoading.downloading
                ? "Downloading pdf.."
                : "Preparing pdf.."}
            </Typography>
          </>
        )}
        <StyledTooltip
          title={checkedCount > 100 ? "100 orders max." : "Print"}
          placement="top"
          arrow
          // disableHoverListener={!(checkedCount > 100)}
          isDark
        >
          <Box>
            <IconButton
              sx={{
                borderRadius: "4px",
                border: "0.5px solid #D4D4D4",
                height: "28px",
                width: "29px",
              }}
              onClick={() => setMenuOpen(true)}
              disabled={
                !checkedCount || checkedCount > 100 || isAdmin || openSnackbar
              }
              ref={printRef}
            >
              <PrinterIcon width="15.38" height="13.47" />
            </IconButton>
          </Box>
        </StyledTooltip>

        {currentTab === "Direct" && (
          <>
            <ActionRestrictionWrapper disableHoverListener={!disabledExportBtn}>
              <StyledButton
                disabled={disabledExportBtn}
                sx={{ height: 28, color: "#707070" }}
                color="groundLighter"
                variant="outlined"
                onClick={() =>
                  updateExportState({ type: "orders", open: true })
                }
                label="Export"
              />
            </ActionRestrictionWrapper>
            <ActionRestrictionWrapper
              disableHoverListener={!disabledImportBtn}
              title={
                repPermissions?.role === "SALES"
                  ? ADMIN_ONLY_VIEW_MESSAGE
                  : ADMIN_ONLY_VIEW_MESSAGE_PAGE
              }
            >
              <StyledButton
                disabled={disabledImportBtn}
                sx={{ height: 28, color: "#707070" }}
                color="groundLighter"
                variant="outlined"
                label="Import"
                onClick={() => {
                  if (formChanged) {
                    if (editType === "checked_items")
                      return handleConfirmCheckedItemsDialog("", "/settings", {
                        state: { tab: SETTINGS_TABS[6], goBack: true },
                      });
                  }
                  navigate("/settings", {
                    state: { tab: SETTINGS_TABS[6], goBack: true },
                  });
                }}
              />
            </ActionRestrictionWrapper>
          </>
        )}

        <ActionRestrictionWrapper
          disableHoverListener={!disabledCreateBtn}
          title={
            repPermissions?.role === "SALES"
              ? ADMIN_ONLY_VIEW_MESSAGE
              : ADMIN_ONLY_VIEW_MESSAGE_PAGE
          }
        >
          <StyledButton
            disabled={disabledCreateBtn || isAdmin}
            sx={{ height: 28 }}
            variant="contained"
            label="Create Order"
            component={Link}
            to={"/cart"}
            from={pathname}
            state={{ tab: currentTab }}
            onClick={(e) => {
              if (formChanged && editType === "checked_items") {
                e.preventDefault();
                return handleConfirmCheckedItemsDialog("/cart");
              }
            }}
          />
        </ActionRestrictionWrapper>
      </Box>
    </Box>
  );
};

OrdersPageTab.propTypes = {
  checkedCount: number,
  handlePrintOrders: func,
  handlePrintPackingSlips: func,
  handlePrintPickList: func,
  setPrintInvoiceListDialog: func,
  printLoading: object,
  setPrintLoading: func,
  currentTab: string,
  setCurrentTab: func,
};
OrdersPageTab.defaultProps = {
  checkedCount: 0,
  printLoading: { loading: false, progress: 0 },
  handlePrintPickList: () => {},
};

export default OrdersPageTab;
