import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { StyledButton } from "components";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";
import { BreadCrumbsBlock } from "./components/BreadCrumbsBlock";
import { cl } from "./PageHeader.styles";
import { createSelector } from "reselect";
import {
  confirmDialogFormChangedSelector,
  editTypeSelector,
} from "redux/selectors/confirmDialogs";
import { useDispatch, useSelector } from "react-redux";
import {
  openDiscardChanges,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";

const selector = createSelector(
  editTypeSelector,
  confirmDialogFormChangedSelector,
  (editType, formChanged) => ({
    editType,
    formChanged,
  })
);

export const PageHeader = () => {
  const navigate = useNavigate();
  const { editType, formChanged } = useSelector(selector);
  const dispatch = useDispatch();

  const {
    trigger,
    errors,
    loading,
    formId,
    formField,
    handleSubmitForm,
    isDirty,
    dirtyFields,
    handleSwitchStatus,
  } = useContext(FormViewContext);

  const preventNavigate = useCallback(
    ({ onClose }) => {
      // eslint-disable-next-line no-console
      if (!onClose) return console.error("onClose is a required parameter");

      if (formChanged && editType === "form-view") {
        return dispatch(
          openDiscardChanges(
            () => onClose(),
            async () => {
              const resTrigger = await trigger();
              if (resTrigger && !(loading && !!Object.keys(errors).length)) {
                handleSubmitForm();
              } else {
                dispatch(setFormChangedAction(isDirty));
              }
            }
          )
        );
      }

      onClose();
    },
    [
      dispatch,
      editType,
      errors,
      formChanged,
      handleSubmitForm,
      isDirty,
      loading,
      trigger,
    ]
  );

  return (
    <Stack direction="row" sx={cl.headerWrapper}>
      <BreadCrumbsBlock
        title={formId ? formField?.title : ""}
        {...{ preventNavigate }}
      />

      <Stack direction="row">
        <StyledButton
          sx={{
            width: "59px",
            height: "28px",
            mr: "12px",
          }}
          fontSize="12px"
          color="cancel"
          label="Discard"
          variant="outlined"
          onClick={() => {
            preventNavigate({
              onClose: () => navigate("/tasks", { state: { tab: "Forms" } }),
            });
          }}
        />

        <Box>
          <StyledButton
            sx={{
              width: "59px",
              height: "28px",
              border: "none !important",
              "&.Mui-disabled": {
                color: "#FFF",
                bgcolor: ({ palette }) =>
                  `${palette.primary.main}50 !important`,
              },
            }}
            disabled={loading || !!Object.keys(errors).length || !isDirty}
            fontSize="12px"
            label="Save"
            color="primary"
            variant="contained"
            type="submit"
            form="new-form-view-form"
            onClick={(e) => {
              if (formId && formField?.active && dirtyFields?.active) {
                e.preventDefault();
                handleSwitchStatus({ status: formField?.active });
              }
            }}
          />
        </Box>
      </Stack>
    </Stack>
  );
};
