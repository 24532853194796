import { useState } from "react";
import { func, bool, object } from "prop-types";

import {
  ActionRestrictionWrapper,
  StyledMenu,
  ThreeDotButton,
} from "components";
import { useAdmin } from "helpers/helpers";

import { Box, Divider, ListItemText, MenuItem } from "@mui/material";

export const MenuColumn = ({ getActionList, adminIsAllowed, actionItem }) => {
  const isAdmin = useAdmin(adminIsAllowed);

  const [anchorEl, setAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleDotClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleCloseDot = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const MENU_ACTIONS = getActionList(actionItem);

  return (
    <>
      <ThreeDotButton
        disabled={isAdmin}
        paddingRight="14px"
        onClick={handleDotClick}
      />

      <StyledMenu
        anchorEl={anchorEl}
        isOpen={isMenuOpen}
        handleClose={handleCloseDot}
        menuProps={{
          MenuListProps: {
            sx: {
              padding: "0px !important",
            },
          },
        }}
        sx={{
          minWidth: "135px",
        }}
      >
        {MENU_ACTIONS.map(
          (
            {
              sx,
              wrapperSx,
              label,
              disabled,
              onClick,
              element,
              show,
              withDividerAfter,
              withDividerBefore,
              icon,
              hasTooltip,
              tooltip,
            },
            index
          ) => (
            <Box
              key={index}
              sx={{
                "& .MuiDivider-root": {
                  height: "0.5px",
                  mt: "6px",
                  mb: "6px",
                },
              }}
            >
              {withDividerBefore && <Divider />}
              {show && hasTooltip && <Box sx={wrapperSx}>{element}</Box>}
              {show && !hasTooltip && (
                <ActionRestrictionWrapper
                  key={index}
                  title={tooltip?.title}
                  disableHoverListener={!tooltip?.show}
                >
                  <MenuItem
                    sx={sx}
                    disabled={disabled}
                    onClick={(e) => {
                      onClick();
                      e.stopPropagation();
                      setAnchorEl(null);
                    }}
                  >
                    {element ? (
                      <>
                        {icon}
                        {element}
                      </>
                    ) : (
                      <>
                        {icon}
                        <ListItemText>{label}</ListItemText>
                      </>
                    )}
                  </MenuItem>
                </ActionRestrictionWrapper>
              )}
              {withDividerAfter && <Divider />}
            </Box>
          )
        )}
      </StyledMenu>
    </>
  );
};

MenuColumn.propTypes = {
  getActionList: func,
  adminIsAllowed: bool,
  actionItem: object,
};
