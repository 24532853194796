import React, { useContext } from "react";

import { RepsAssignedDrawer } from "components";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";
import { CustomersBlock, TabsBlock, RepsBlock } from "./components";
import AllCustomersPopup from "Pages/CreateRoutePage/components/AllCustomersPopup";

import { TABS_VISIBILITY_SETTINGS_PAGE } from "Pages/FormViewPage/FormViewPage.constants";
import { cl } from "./styles";

import { Stack } from "@mui/material";

export const VisibilitySettings = () => {
  const {
    currentVisibilitySettingsTab,
    openAllCustomers,
    onChangeAllCustomers,
    openAllReps,
    onChangeAllReps,
    showCheckedOnly,
    customersCheckedList,
    openEditAllCustomers,
    handleAddCustomers,
    handleAddReps,
    assignedReps,
    onDeleteAllResp,
  } = useContext(FormViewContext);

  const content = {
    [TABS_VISIBILITY_SETTINGS_PAGE[0].value]: <CustomersBlock />,
    [TABS_VISIBILITY_SETTINGS_PAGE[1].value]: <RepsBlock />,
  };

  return (
    <Stack sx={cl.tabWrapper}>
      <TabsBlock />
      {content[currentVisibilitySettingsTab]}

      {openAllCustomers && (
        <AllCustomersPopup
          hideActive
          hideStatus
          allowMissingInfo
          viewCustomerPopup={false}
          handleAddCustomers={handleAddCustomers}
          addedStops={customersCheckedList}
          open={openAllCustomers}
          onClose={() => onChangeAllCustomers(false)}
          showCheckedOnly={showCheckedOnly}
          hideOrders
          isEdit={openEditAllCustomers}
        />
      )}

      <RepsAssignedDrawer
        isOpen={openAllReps}
        handleClose={() => onChangeAllReps(false)}
        assignedRepresentatives={assignedReps}
        handleSetData={handleAddReps}
        handleDeleteAllReps={onDeleteAllResp}
        submitBtnLabel="Save"
        withoutThirdParty
      />
    </Stack>
  );
};
