import { useCallback, useMemo } from "react";
import { bool, func, object } from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { CheckmarkIcon, CrossIcon } from "components/Icons";
import {
  FlagIcon,
  NoteIcon,
  PhotoCommentIcon,
  PicIcon,
} from "components/Icons/Activities";
import { GoogleMap, StyledLabel, StyledAvatar } from "components";
import {
  convertFeetToMeters,
  getFormattedDate,
  photoUrl,
} from "helpers/helpers";
import { useNavigate } from "react-router-dom";
import { useRepsPermissions } from "helpers/hooks";

const MapPopup = ({
  withHeader,
  isOpen,
  handleClose,
  address,
  handleSetAddress,
  customer,
  closeBtnStyle,
  defaultCenter = {
    lat: 40.72316765399142,
    lng: -73.99994350677083,
  },
  skipCustomerRedirect = false,
  hideTooltip = false,
  hideRadius = false,
}) => {
  const navigate = useNavigate();
  const { timeZone, checkInCustomerRadiusEnabled, checkInCustomerRadius } =
    useSelector(({ auth }) => auth.currentUser || {});

  const repPermissions = useRepsPermissions();

  const checkInCustomerRadiusFeet = convertFeetToMeters(
    checkInCustomerRadius || 0
  );

  const checkIn = customer?.checkIn;

  const checkInTime = useMemo(() => {
    return checkIn?.checkedInAt ? checkIn?.checkedInAt : customer?.createdAt;
  }, [checkIn?.checkedInAt, customer?.createdAt]);

  const checkOutTime = useMemo(() => {
    return checkIn?.checkedOutAt ? checkIn?.checkedOutAt : null;
  }, [checkIn?.checkedOutAt]);

  const profilePhoto = useCallback(
    (user) => user?.avatar || photoUrl(user?.profilePhoto?.fileName),
    []
  );

  const disabledCustomersView = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.customers?.view;
    }
  }, [repPermissions]);

  const activityStatus = useMemo(() => {
    if (customer?.type === "CHECKED_IN") {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
          }}
        >
          <FlagIcon />
          <Typography fontSize="12px" color="#42A57F" ml="6px" mr="3px" noWrap>
            Checked in
          </Typography>
        </Box>
      );
    }

    if (customer?.type === "PHOTO_GROUP_ADDED") {
      return (
        <Box sx={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}>
          <Box sx={{ display: "flex", ml: "-3px" }}>
            <PicIcon />
          </Box>
          <Typography fontSize="12px" color="#42A57F" ml="3px" mr="3px" noWrap>
            Photo
          </Typography>
        </Box>
      );
    }

    if (customer?.type === "NOTE_ADDED") {
      return (
        <Box sx={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}>
          <NoteIcon />
          <Typography fontSize="12px" color="#42A57F" ml="5px" mr="3px" noWrap>
            Note
          </Typography>
        </Box>
      );
    }

    if (customer?.type === "COMMENT_ADDED") {
      return (
        <Box sx={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}>
          <PhotoCommentIcon />
          <Typography fontSize="12px" color="#42A57F" ml="7px" mr="3px" noWrap>
            Photo Comment
          </Typography>
        </Box>
      );
    }

    return (
      <Box display="flex" alignItems="center" flexWrap="nowrap">
        <FlagIcon />
        <Typography fontSize="12px" color="#42A57F" ml="6px" mr="3px" noWrap>
          Checked in
        </Typography>
      </Box>
    );
  }, [customer?.type]);

  const convertFeetToMiles = (feet) => {
    const FEET_IN_MILE = 5280;
    const miles = feet / FEET_IN_MILE;
    return miles.toFixed(1);
  };

  const calcAndPrepareDistance = useMemo(() => {
    const storeDistance = checkIn?.storeDistance;

    if (!storeDistance || typeof storeDistance !== "number") return "-";

    if (storeDistance <= 1000) return `${parseInt(storeDistance)} ft`;

    return `${convertFeetToMiles(storeDistance)} miles`;
  }, [checkIn?.storeDistance]);

  const CHECK_IN_TYPES = {
    INSIDE_STORE: {
      bgcolor: "#47A06D10",
      text: "Check in within radius",
      icon: <CheckmarkIcon width="8.79" height="6.33" stroke="#47A06D" />,
      point_color: "#47A06D",
    },

    OUTSIDE_STORE: {
      bgcolor: "#EB423310",
      text: `Check in was ${calcAndPrepareDistance} from allowed radius`,
      icon: (
        <Box
          sx={{
            width: "8px",
            height: "8px",
            bgcolor: "#EB4233",
            borderRadius: "50%",
          }}
        />
      ),
      point_color: "#EB4233",
    },

    NO_LOCATION_PROVIDED: {
      bgcolor: "#6A6A6A10",
      text: "Location not provided",
      icon: (
        <Box
          sx={{
            width: "8px",
            height: "8px",
            bgcolor: "#6A6A6A",
            borderRadius: "50%",
          }}
        />
      ),
      point_color: "#5F6267",
    },
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{
        "& > .MuiDialog-container > .MuiPaper-root": {
          maxWidth: "900px",
        },
      }}
    >
      <IconButton
        sx={{
          zIndex: 1,
          position: "absolute",
          top: 0,
          right: 0,
          ...closeBtnStyle,
        }}
        onClick={handleClose}
      >
        <CrossIcon />
      </IconButton>
      {!!withHeader && (
        <>
          <Box
            sx={{
              width: "100%",
              height: "60px",
              minHeight: "60px",
              display: "flex",
              alignItems: "center",
              p: 3,
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                color: "#707070",
              }}
            >
              Rep Activity
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              height: "64px",
              minHeight: "64px",
              borderWidth: "0.5px 0 0.5px 0",
              borderStyle: "solid",
              borderColor: "#CCCCCC",
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              px: 3,
            }}
          >
            {customer ? (
              <>
                <Box mr={1.5}>
                  <StyledAvatar
                    sx={{ width: 42, height: 42 }}
                    name={customer?.representativeDuplicate?.name}
                    src={profilePhoto(customer?.representativeDuplicate)}
                    stringStyle={{ fontSize: 12 }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: 500,
                        color: "#1C1C19",
                        mr: 1,
                      }}
                      noWrap
                    >
                      {customer?.representativeDuplicate?.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: 300,
                        color: "#C6C6C6",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {checkInTime
                        ? `| ${moment(
                            getFormattedDate(checkInTime, timeZone)
                          ).format("h:mm a")}`
                        : null}{" "}
                      {checkOutTime
                        ? ` - ${moment(
                            getFormattedDate(checkOutTime, timeZone)
                          ).format("h:mm a")}`
                        : null}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    {activityStatus}
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: 300,
                        color: "#1C1C19",
                      }}
                      noWrap
                    >
                      at{" "}
                      <Box
                        component="span"
                        fontWeight={500}
                        sx={{
                          cursor: !skipCustomerRedirect && "pointer",
                          "&:hover": {
                            textDecoration:
                              !skipCustomerRedirect && "underline",
                          },
                        }}
                        onClick={() => {
                          if (disabledCustomersView) return;
                          !skipCustomerRedirect &&
                            navigate(
                              `/customers/${
                                customer?.customer?.id || customer?.id
                              }`
                            );
                        }}
                      >
                        {customer?.customer?.displayName ||
                          customer?.customer?.name ||
                          customer?.displayName ||
                          customer?.name}
                      </Box>
                    </Typography>
                    <Box display="flex" alignItems="center" gap="6px" ml={0.5}>
                      {!(
                        customer?.representativeDuplicate?.representativeId ||
                        customer?.representativeDuplicate?.id
                      ) && <StyledLabel text="deleted" />}
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: 300,
                        color: "#000000",
                      }}
                      noWrap
                    >
                      {customer?.customer?.shippingAddress?.formatted_address ||
                        customer?.shippingAddress?.formatted_address}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  sx={{
                    minWidth: "fit-content",
                    ml: 1,
                  }}
                >
                  {[
                    "INSIDE_STORE",
                    "OUTSIDE_STORE",
                    "NO_LOCATION_PROVIDED",
                  ].includes(
                    // customer?.locationType ??
                    checkIn?.locationType
                  ) && (
                    <Box
                      sx={{
                        height: "20px",
                        px: 1,
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        borderRadius: "4px",
                        bgcolor:
                          CHECK_IN_TYPES[
                            // customer?.locationType ??
                            checkIn?.locationType
                          ]?.bgcolor,
                      }}
                    >
                      {
                        CHECK_IN_TYPES[
                          // customer?.locationType ??
                          checkIn?.locationType
                        ]?.icon
                      }
                      <Typography fontSize={10} color="#707070">
                        {
                          CHECK_IN_TYPES[
                            // customer?.locationType ??
                            checkIn?.locationType
                          ]?.text
                        }
                      </Typography>
                    </Box>
                  )}
                </Box>
              </>
            ) : null}
          </Box>
        </>
      )}

      <DialogContent
        sx={{
          position: "relative",
          width: "900px",
          height: "800px",
          pt: "30px",
          "& .gm-bundled-control-on-bottom": {
            bottom: "90px !important",
          },
        }}
      >
        <GoogleMap
          defaultCenter={defaultCenter}
          customer={customer?.customer || customer}
          checkIn={{ ...customer?.checkIn, createdAt: customer?.createdAt }}
          radius={
            checkInCustomerRadiusEnabled && !hideRadius
              ? checkInCustomerRadiusFeet
              : null
          }
          checkInPoint={{
            lat: checkIn?.location?.lat,
            lng: checkIn?.location?.lng,
            color:
              // customer?.locationType ??
              checkIn?.locationType
                ? CHECK_IN_TYPES[
                    // customer?.locationType ??
                    checkIn.locationType
                  ]?.point_color
                : "",
          }}
          {...{ address, timeZone, handleSetAddress, hideTooltip }}
        />
      </DialogContent>
    </Dialog>
  );
};

MapPopup.propTypes = {
  withHeader: bool,
  isOpen: bool,
  handleClose: func,
  handleSetAddress: func,
  address: object,
  customer: object,
  defaultCenter: object,
  closeBtnStyle: object,
  skipCustomerRedirect: bool,
  hideTooltip: bool,
  hideRadius: bool,
};

MapPopup.defaultProps = {
  withHeader: false,
  isOpen: false,
  hideTooltip: false,
  hideRadius: false,
  address: null,
  handleSetAddress: () => {},
};

export default MapPopup;
