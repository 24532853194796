export const TABS_PAGE = [
  {
    value: 0,
    label: "Products",
    tooltipText: "",
    showTooltip: false,
  },
  {
    value: 1,
    label: "Customers",
    showTooltip: false,
    tooltipText: "Coming soon",
  },
];

export const DISCOUNT_TYPES = ["PERCENTAGE", "ABSOLUTE"];
