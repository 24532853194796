/* eslint-disable react/prop-types */
import * as React from "react";
export const FormSingleTypeIcon = ({
  firstRectFill = "#fff",
  secondRectStroke = "#000",
  lastRectFill = "#C9C9C9",
  strokeOpacity = 0.16,
  ...props
}) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1.333}
      y={1.333}
      width={13.333}
      height={13.333}
      rx={6.667}
      fill={firstRectFill}
    />
    <rect
      x={1.667}
      y={1.667}
      width={12.667}
      height={12.667}
      rx={6.333}
      stroke={secondRectStroke}
      strokeOpacity={strokeOpacity}
      strokeWidth={0.667}
    />
    <rect x={4} y={4} width={8} height={8} rx={4} fill={lastRectFill} />
  </svg>
);
