import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { currentUserSelector } from "redux/selectors/auth";
import { formsFilterAction } from "redux/actions/forms";
import { useRepsPermissions } from "helpers/hooks";
import { TABS_PAGE } from "../TasksPage.constants";
import { handleError, truncateText } from "helpers/helpers";
import { error, success } from "utils/notifications";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import { Typography } from "@mui/material";
import { bulkFormsActivationService, deleteFormsService } from "services/forms";
import pluralize from "pluralize";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

export const useFormsPage = ({ formsState }) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(selector);
  const repPermissions = useRepsPermissions();

  const {
    formsList,
    formsFilter,
    checkedForms,
    setCheckedForms,
    allFormsChecked,
    setAllFormsChecked,
    setFormsState,
  } = formsState || {};

  const [currentTab, setCurrentTab] = useState(TABS_PAGE[0].value);

  const handleChangeCurrentTab = (_, tab) => {
    setCurrentTab(tab);
  };

  const handleCheckForm = (formId) => {
    const formIndex = checkedForms.findIndex(
      (checkedForm) => checkedForm === formId
    );
    if (formIndex > -1) {
      const newForms = [...checkedForms];
      newForms.splice(formIndex, 1);
      return setCheckedForms([...newForms]);
    }

    setCheckedForms([...checkedForms, formId]);
  };

  const handleUncheckAllForms = useCallback(() => {
    setAllFormsChecked(false);
    setCheckedForms([]);
  }, [setAllFormsChecked, setCheckedForms]);

  const checkAllForms = useCallback(() => {
    dispatch(
      formsFilterAction({ ...formsFilter, page: undefined, limit: undefined })
    );
  }, [dispatch, formsFilter]);

  const handleSelectAvailableForms = useCallback(() => {
    if (formsList?.length === checkedForms?.length) {
      handleUncheckAllForms();
      return;
    }

    setCheckedForms(formsList);
  }, [checkedForms?.length, handleUncheckAllForms, setCheckedForms, formsList]);

  const handleSwitchStatusForms = useCallback(
    async (form) => {
      const preparedFormsIds = form?.id
        ? [form.id]
        : checkedForms.map((form) => form?.id);

      const isActive = formsFilter?.active;
      const switchText = isActive ? "inactive" : "active";

      if (!preparedFormsIds.length)
        return error(`Nothing to mark as ${switchText}`);

      try {
        setFormsState((prev) => ({ ...prev, loadingFormsList: true }));

        await bulkFormsActivationService({
          formsIds: preparedFormsIds,
          active: !isActive,
        });

        dispatch(formsFilterAction({ ...formsFilter, page: 1 }));
        setCheckedForms([]);
        success(
          `${pluralize("Form", preparedFormsIds.length)} ${pluralize(
            "was",
            preparedFormsIds.length
          )} changed to ${switchText}`
        );
      } catch (error) {
        handleError(error);
      } finally {
        setFormsState((prev) => ({ ...prev, loadingFormsList: false }));
      }
    },
    [checkedForms, dispatch, formsFilter, setCheckedForms, setFormsState]
  );

  const handleBulkDeleteForms = useCallback(
    async (form) => {
      const preparedFormsIds = form?.id
        ? [form.id]
        : checkedForms.map((form) => form?.id);

      if (!preparedFormsIds.length) return error("Nothing to delete");

      try {
        setFormsState((prev) => ({ ...prev, loadingFormsList: true }));

        await deleteFormsService({ formsIds: preparedFormsIds });

        dispatch(formsFilterAction({ ...formsFilter, page: 1 }));
        setCheckedForms([]);
        success(
          `${pluralize("Form", preparedFormsIds.length)} ${pluralize(
            "was",
            preparedFormsIds.length
          )} deleted`
        );
      } catch (error) {
        handleError(error);
      } finally {
        setFormsState((prev) => ({ ...prev, loadingFormsList: false }));
      }
    },
    [checkedForms, dispatch, formsFilter, setCheckedForms, setFormsState]
  );

  const handleDeleteWarning = (onConfirm, item) => {
    const isSingle = !!item;

    dispatch(
      openConfirmDialogAction({
        isIcon: true,
        title: `Delete${isSingle ? ` ${truncateText(item?.title)}` : ""}?`,
        text: (
          <Typography>
            Are you sure you want to delete form{isSingle ? "" : "s"}
            {isSingle && (
              <span
                style={{ fontWeight: "500", color: "#000" }}
              >{` "${truncateText(item?.title, 30)}"`}</span>
            )}
            ?<br />
            {"All data will be erased and this can't be undone."}
          </Typography>
        ),
        dialogStyles: { maxWidth: "420px" },
        propBtns: {
          left: {
            sx: {
              color: "#6A6A6A",
              borderColor: "#D4D4D4",
              fontSize: "13px",
              height: "28px",
            },
            onClick: () => {
              dispatch(setConfirmIsOpenAction(false));
            },
            variant: "outlined",
            label: "Cancel",
          },

          right: {
            sx: {
              color: "#FFFFFF",
              fontSize: "13px",
              height: "28px",
              boxShadow: "none",
            },
            color: "confirmDelete",
            onClick: () => {
              onConfirm();
              dispatch(setConfirmIsOpenAction(false));
            },
            variant: "contained",
            label: "Delete",
          },
        },
      })
    );
  };

  const handleFetchList = () =>
    dispatch(formsFilterAction({ ...formsFilter, page: 1 }));

  useEffect(() => {
    if (formsList?.length && formsList?.length === checkedForms?.length) {
      setAllFormsChecked(true);
    } else {
      setAllFormsChecked(false);
    }
  }, [checkedForms?.length, setAllFormsChecked, formsList?.length]);

  return {
    TABS_PAGE,
    currentTab,
    handleChangeCurrentTab,
    currentUser,
    checkedForms,
    handleCheckForm,
    allFormsChecked,
    setAllFormsChecked,
    checkAllForms,
    repPermissions,
    handleFetchList,
    handleUncheckAllForms,
    handleSelectAvailableForms,
    handleBulkDeleteForms: (item) =>
      handleDeleteWarning(
        () => handleBulkDeleteForms(item),
        item ? item : checkedForms?.length === 1 ? checkedForms[0] : null
      ),
    handleSwitchStatusForms,
  };
};
