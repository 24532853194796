import { bool, func } from "prop-types";
import { IconButton, Stack, Typography } from "@mui/material";
import { OutlinedPlusIcon } from "components/Icons";
import { cl } from "../../AddFormsComponent.styles";

export const ActionsBlock = ({
  handleOpenAddForms = () => {},
  isNotAllowedToEdit = false,
}) => {
  const { wrapper } = cl.ActionsBlock;

  return (
    <Stack py="16px">
      <IconButton
        disabled={isNotAllowedToEdit}
        onClick={handleOpenAddForms}
        sx={wrapper}
        disableRipple
      >
        <OutlinedPlusIcon
          size={24}
          circleColor="#D9D9D9"
          plusColor={isNotAllowedToEdit ? "#D9D9D9" : "#47A06D"}
        />
        <Typography fontSize={14} fontWeight={400} color="#5F6267">
          Add form
        </Typography>
      </IconButton>
    </Stack>
  );
};

ActionsBlock.propTypes = { handleOpenAddForms: func, isNotAllowedToEdit: bool };
