/* eslint-disable no-useless-escape */
import * as Yup from "yup";

const PHONE_REGEXP =
  /^[\+][\d]{1}[\s]?[\(]?[\d]{3}[\)]?[\s]?[\d]{3}[\s]?[\d]{4}$/im;

export const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().trim().required(" "),
    billingAddress: Yup.object().shape({
      formatted_address: Yup.string()
        .nullable()
        .test("billingAddress", "This field is required", (value, context) => {
          return context?.from?.[1]?.value?.shippingIsSame ? true : !!value;
        }),
      street: Yup.string()
        .nullable()
        .test("billingAddress", "This field is required", (value, context) => {
          return context?.from?.[1]?.value?.shippingIsSame ? true : !!value;
        }),
      city: Yup.string()
        .nullable()
        .test("billingAddress", "This field is required", (value, context) => {
          return context?.from?.[1]?.value?.shippingIsSame ? true : !!value;
        }),
      state: Yup.string()
        .nullable()
        .test("billingAddress", "This field is required", (value, context) => {
          return context?.from?.[1]?.value?.shippingIsSame ? true : !!value;
        }),
      zip: Yup.string()
        .nullable()
        .test("billingAddress", "This field is required", (value, context) => {
          return context?.from?.[1]?.value?.shippingIsSame ? true : !!value;
        }),
      lat: Yup.number()
        .nullable()
        .test("billingAddress", "Coordinates are missing", () => {
          return true;
        }),
      lng: Yup.number()
        .nullable()
        .test("billingAddress", "Coordinates are missing", () => {
          return true;
        }),
    }),
    shippingIsSame: Yup.boolean(),
    shippingAddress: Yup.object().shape({
      formatted_address: Yup.string()
        .nullable()
        .test("shippingAddress", "This field is required", (value) => {
          return !!value;
        }),
      street: Yup.string()
        .nullable()
        .test("shippingAddress", "This field is required", (value) => {
          return !!value;
        }),
      city: Yup.string()
        .nullable()
        .test("shippingAddress", "This field is required", (value) => {
          return !!value;
        }),
      state: Yup.string()
        .nullable()
        .test("shippingAddress", "This field is required", (value) => {
          return !!value;
        }),
      zip: Yup.string()
        .nullable()
        .test("shippingAddress", "This field is required", (value) => {
          return !!value;
        }),
      lat: Yup.number()
        .nullable()
        .test("shippingAddress", "Coordinates are missing", (value) => {
          return !!value;
        }),
      lng: Yup.number()
        .nullable()
        .test("shippingAddress", "Coordinates are missing", (value) => {
          return !!value;
        }),
    }),
    phone: Yup.string()
      .trim()
      .test("phoneValidation", "Not valid phone number!", (value) => {
        const cleaned = value.replace(/[^+\d]/g, "");

        if (!value.replace("+", "")) return true;
        if (cleaned === "+1") return true;

        return PHONE_REGEXP.test(value);
      }),
    businessFax: Yup.string()
      .trim()
      .test("businessFaxValidation", "Not valid business fax!", (value) => {
        if (!value) return true;
        return PHONE_REGEXP.test(value);
      }),
    email: Yup.string()
      .trim()
      .test("emailValidation", "Email not valid!", (value) => {
        if (!value) return true;
        const EMAIL_REGEXP =
          /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d)|(([a-z]|\d)([a-z]|\d|-|\.|_|~)*([a-z]|\d)))\.)+(([a-z])|(([a-z])([a-z]|\d|-|\.|_|~)*([a-z])))$/i; // eslint-disable-line
        return EMAIL_REGEXP.test(value);
      }),
  });
