import { Fragment, useEffect, useMemo, useState } from "react";
import { func, object, bool, array } from "prop-types";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import StyledMenu from "components/StyledMenu/StyledMenu";

import { SendIcon } from "components/Icons";
import {
  ActionRestrictionWrapper,
  StyledTooltip,
  ThreeDotButton,
} from "components";
import { useAdmin } from "helpers/helpers";
import { OrdersActions } from "Pages/useOrdersActions";
import { AddContactDrawer } from "components/AddContactDrawer/AddContactDrawer";
import { useOrdersActions } from "Pages/OrdersPage/Orders.hook";
import { ADMIN_ONLY_VIEW_MESSAGE } from "utils/constants";

export const MenuColumnOrder = ({
  hasCustomerEmail,
  handleOpenEmail,
  handleChoseMenuItem,
  order,
  isQuickBooksConnected,
  repPermissions,
  hideSendEmail,
  additionalItems,
  disabled = false,
}) => {
  const isAdmin = useAdmin();

  const {
    handleSaveContacts,
    customerState,
    handleFetchCustomer,
    setCustomId,
  } = OrdersActions();
  const [openAddContactPopup, setOpenAddContactPopup] = useState();

  const customer = useMemo(
    () => customerState?.customer,
    [customerState.customer]
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const openHeaderList = Boolean(anchorEl);

  useEffect(() => {
    setCustomId(order?.customer?.id);
  }, [order?.customer?.id, setCustomId]);

  useEffect(() => {
    openAddContactPopup && handleFetchCustomer();
  }, [handleFetchCustomer, openAddContactPopup]);

  const handleClickOnDotsBtn = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleCloseDotsBtn = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const { handleGetOrderActions } = useOrdersActions({
    isQuickBooksConnected,
    order,
    handleChoseMenuItem,
  });

  const ORDER_ACTIONS_LIST = handleGetOrderActions();

  const titleTooltip = useMemo(() => {
    if (repPermissions && !repPermissions?.orders?.create_edit)
      return ADMIN_ONLY_VIEW_MESSAGE;

    return hasCustomerEmail ? (
      <Typography>Email order</Typography>
    ) : (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {!hasCustomerEmail && (
          <Typography>
            No email address found <br /> for this customer
          </Typography>
        )}
        <Typography
          variant="subtitle2"
          color="primary"
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            fontWeight: 600,
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            !!setOpenAddContactPopup && setOpenAddContactPopup(true);
          }}
        >
          Add Customer Contact
        </Typography>
      </Box>
    );
  }, [hasCustomerEmail, repPermissions]);

  return (
    <>
      <AddContactDrawer
        open={openAddContactPopup}
        onClose={() => setOpenAddContactPopup(false)}
        handleAddContact={(newContact) =>
          handleSaveContacts(customer?.contacts, newContact)
        }
        contacts={customer?.contacts}
      />
      <Grid item>
        {hideSendEmail ? null : (
          <StyledTooltip
            isDark={!(!!repPermissions && !repPermissions?.orders?.create_edit)}
            title={titleTooltip}
            // disableHoverListener={hasCustomerEmail}
            arrow
          >
            <Box mr="14px">
              <IconButton
                sx={{
                  backgroundColor: "#F8F8F8",
                  borderRadius: "4px",
                  border: "0.5px solid #D4D4D4",
                  height: "28px",
                  width: "29px",
                }}
                disabled={
                  !hasCustomerEmail ||
                  isAdmin ||
                  (!!repPermissions && !repPermissions?.orders?.create_edit)
                }
                onClick={() => handleOpenEmail(order)}
              >
                <SendIcon
                  fill={
                    !hasCustomerEmail ||
                    isAdmin ||
                    (!!repPermissions && !repPermissions?.orders?.create_edit)
                      ? "#B9B9B9"
                      : "#5f6267"
                  }
                />
              </IconButton>
            </Box>
          </StyledTooltip>
        )}
      </Grid>
      <ThreeDotButton
        paddingRight="14px"
        onClick={handleClickOnDotsBtn}
        disabled={disabled || isAdmin}
      />
      <StyledMenu
        sx={{
          paddingY: 0,
          width: "146px",
        }}
        anchorEl={anchorEl}
        isOpen={openHeaderList}
        handleClose={handleCloseDotsBtn}
      >
        {ORDER_ACTIONS_LIST.map(
          (
            {
              sx,
              label,
              disabled,
              disabledPermissions,
              onClick,
              element,
              show,
              withDividerAfter,
              withDividerBefore,
            },
            index
          ) => (
            <ActionRestrictionWrapper
              key={index}
              disableHoverListener={!disabledPermissions}
            >
              <Box>
                {withDividerBefore && show && <Divider />}
                {show && (
                  <MenuItem
                    sx={sx}
                    disabled={disabled || disabledPermissions}
                    onClick={handleCloseDotsBtn}
                  >
                    {element ? (
                      element
                    ) : (
                      <ListItemText onClick={onClick}>{label}</ListItemText>
                    )}
                  </MenuItem>
                )}
                {withDividerAfter && show && <Divider />}
              </Box>
            </ActionRestrictionWrapper>
          )
        )}

        {!!additionalItems?.length &&
          additionalItems.map((item) => {
            return (
              <Box
                key={item?.label}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
                component="span"
              >
                {!!item?.showBeforeDivider && <Divider sx={{ my: "8px" }} />}
                <MenuItem onClick={handleCloseDotsBtn}>
                  <ListItemText
                    classes={{ primary: item?.redText ? "deleteItem" : "" }}
                    sx={{ "& > .MuiTypography-root": { color: "#FF6254" } }}
                    onClick={() => item?.onClick(order)}
                  >
                    {item?.label}
                  </ListItemText>
                </MenuItem>
                {!!item?.showAfterDivider && <Divider sx={{ my: "8px" }} />}
              </Box>
            );
          })}
      </StyledMenu>
    </>
  );
};

MenuColumnOrder.propTypes = {
  hasCustomerEmail: bool,
  isAdmin: bool,
  handleOpenEmail: func,
  handleChoseMenuItem: func,
  handleOpenOrder: func,
  order: object,
  isQuickBooksConnected: bool,
  repPermissions: object,
  hideSendEmail: bool,
  additionalItems: array,
  disabled: bool,
};
