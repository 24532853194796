import React from "react";

export const ErrorIcon = (props) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.75a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5M.25 10C.25 4.615 4.615.25 10 .25s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S.25 15.385.25 10M10 8.25a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75M10 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
        fill="#EB4233"
      />
    </svg>
  );
};
