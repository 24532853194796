export const cl = {
  tabWrapper: {
    width: "100%",
    height: "100%",
    paddingBottom: "24px",
    background: "white",
    boxShadow: "0px 1.5px 3px rgba(0, 0, 0, 0.16)",
    borderRadius: "4px",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "8px",
  },
};
