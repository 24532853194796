import { string, bool, object } from "prop-types";
import { Link as MuiLink } from "@mui/material";
import { Link } from "react-router-dom";

export const StyledLink = ({
  children,
  disabled = false,
  sx = {},
  ...props
}) => {
  return (
    <MuiLink
      component={disabled ? "span" : Link}
      sx={{
        ...(disabled && {
          color: "grey",
          textDecoration: "none",
          cursor: "default",
        }),
        ...sx,
      }}
      {...props}
    >
      {children}
    </MuiLink>
  );
};

StyledLink.propTypes = { children: string, disabled: bool, sx: object };
