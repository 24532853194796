export const PRIORITIES = ["MEDIUM", "LOW", "CRITICAL"];
export const STATUSES = ["PENDING", "COMPLETED"];

export const defaultValues = {
  title: "",
  description: "",
  dueDate: "",
  priority: PRIORITIES[0],
  representative: "",
  assignToDistributor: null,
  repeated: false,
  repeatInterval: "day",
  repeatStep: 1,
  applyForFutureTasks: false,
  addTime: false,
  assignedDistributorId: null,
};

export const defaultRepeatInterval = [
  "day",
  "weekday",
  "week",
  "month",
  "year",
];
