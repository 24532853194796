import { array, bool, func } from "prop-types";
import { Chip, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { cl } from "../../AddFormsComponent.styles";

export const SelectedFormsBlock = ({
  checkedForms = [],
  handleSetCheckedForms = () => {},
  isNotAllowedToEdit = false,
}) => {
  const { wrapper, chip } = cl.SelectedFormsBlock;

  return (
    <Stack sx={wrapper}>
      {checkedForms?.map((form) => (
        <Chip
          key={form?.id}
          sx={chip}
          label={form?.title}
          disabled={isNotAllowedToEdit}
          size="small"
          deleteIcon={
            <CloseIcon
              size="15"
              style={{ fill: "#707070", cursor: "pointer" }}
            />
          }
          onDelete={() => handleSetCheckedForms(form)}
        />
      ))}
    </Stack>
  );
};

SelectedFormsBlock.propTypes = {
  checkedForms: array,
  handleSetCheckedForms: func,
  isNotAllowedToEdit: bool,
};
