import { useContext } from "react";
import {
  CardBlockWrapper,
  CountBlock,
  TabsBlock,
  // ThreeDotBlock,
  TitleBlock,
} from "./components";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";

export const ControlPanel = () => {
  const { responsesState } = useContext(FormViewContext);
  const { data } = responsesState;

  return (
    <CardBlockWrapper
      wrapProps={{ position: "relative", pt: "20px", px: "16px" }}
    >
      {/* <ThreeDotBlock /> */}
      <TitleBlock name={data?.title || ""} />
      <CountBlock name="Form" count={data?._count?.progresses || 0} />
      <TabsBlock />
    </CardBlockWrapper>
  );
};
