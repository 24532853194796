import { useEffect } from "react";
import { oneOf, func, bool } from "prop-types";
import { useNavigate } from "react-router-dom";

import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import useStyles, { cl } from "./styles";
import { ACTIVITIES_FILTER_TABS } from "../constants";
import {
  StyledTooltip,
  StyledButton,
  ActionRestrictionWrapper,
} from "components";
import { useRepsPermissions } from "helpers/hooks";

export const Switcher = ({
  currentTabActivities,
  setCurrentTabActivities,
  isThirdParty,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const {
    Switcher: { paperOptions, btnGalleryOptions, text, wrapper },
  } = cl;

  const repPermissions = useRepsPermissions();

  const handleSetTabActivity = (tab) => {
    setCurrentTabActivities(tab);
  };

  useEffect(function resetTab() {
    setCurrentTabActivities("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper {...paperOptions}>
      <Typography sx={text}>Activities</Typography>
      <Box sx={wrapper}>
        {currentTabActivities === "photo_group_added" ? (
          <ActionRestrictionWrapper
            disableHoverListener={
              !(!!repPermissions && !repPermissions?.customers?.gallery)
            }
          >
            <StyledButton
              disabled={!!repPermissions && !repPermissions?.customers?.gallery}
              {...btnGalleryOptions}
              onClick={() => {
                navigate("/customers", {
                  state: { tab: "Gallery", goBack: true },
                });
              }}
            />
          </ActionRestrictionWrapper>
        ) : null}
      </Box>
      <Tabs
        value={currentTabActivities}
        onChange={(_, newVal) => handleSetTabActivity(newVal)}
        className={classes.tabs}
        TabIndicatorProps={{ style: { background: "none" } }}
      >
        {ACTIVITIES_FILTER_TABS.map((tab, i) => (
          <Tab
            key={tab.value}
            disabled={isThirdParty && i !== 5}
            label={
              <StyledTooltip
                arrow
                title={
                  isThirdParty && i !== 5
                    ? "Not available for 3rd party reps"
                    : ""
                }
                key={tab.value}
                placement="top"
              >
                <Box sx={{ pointerEvents: "all" }}>{tab.name}</Box>
              </StyledTooltip>
            }
            name={tab.field}
            value={tab.value}
            className={classes.tab}
          />
        ))}
      </Tabs>
    </Paper>
  );
};

Switcher.propTypes = {
  currentTabActivities: oneOf(ACTIVITIES_FILTER_TABS.map((tab) => tab.value)),
  setCurrentTabActivities: func,
  isThirdParty: bool,
};

Switcher.defaultProps = {
  currentTabActivities: "",
  setCurrentTabActivities: () => {},
  isThirdParty: false,
};
