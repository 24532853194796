import {
  defaultImportState,
  ImportContext,
} from "Pages/SettingsPage/SettingsPage";
import { useCallback, useContext, useMemo, useState } from "react";
import useImportStatus from "./useImportStatus";
import {
  collection,
  endBefore,
  getCountFromServer,
  getFirestore,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { IMPORT_LIMIT, IMPORT_STATUSES } from "../../ImportTab.constants";
import {
  deleteImportSessionService,
  finishImportService,
  getImportSchemasService,
  getImportSessionsService,
  getImportSessionStateByIdService,
  remapImportDataService,
  uploadImportSheetService,
} from "services/import";
import { error } from "utils/notifications";
import { uploadProgress } from "helpers/helpers";
import { useDispatch } from "react-redux";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import ExcelSample from "import_templates/customers_sample.xlsx";
import OrderExcelSample from "import_templates/orders_sample.xlsx";

const useImport = () => {
  const { importData, setImportData } = useContext(ImportContext);

  const { importStatus, importStatusLoading } = useImportStatus({
    session: importData.uploadSession?.session,
  });

  const importRef = collection(
    getFirestore(),
    `importer/${importData.uploadSession?.session}/data`
  );

  const [importPagination, setImportPagination] = useState({
    page: 0,
    cursorStart: 2,
    cursorEnd: -1,
    cursorPoints: [],
  });

  const [importRows, importRowsLoading] = useCollection(
    query(
      importRef,
      orderBy("error", "desc"),
      startAfter(importPagination.cursorStart || 2),
      endBefore(importPagination.cursorEnd || -1),
      limit(IMPORT_LIMIT)
    )
  );

  const tableRows = useMemo(
    () => importRows?.docs?.map((r) => ({ id: r.id, ...r.data() })) || [],
    [importRows?.docs]
  );

  const updateImportData = useCallback(
    (newValues) => {
      setImportData((prev) => ({ ...prev, ...newValues }));
    },
    [setImportData]
  );

  const lastDoc = useMemo(
    () => importRows?.docs[importRows?.docs?.length - 1],
    [importRows?.docs]
  );

  const handlePaginate = (page) => {
    if (
      page > importPagination.page &&
      lastDoc?.id === importPagination.cursorStart?.id
    )
      return;
    if (page > importPagination.page) {
      if (!lastDoc) return;
      setImportPagination((prev) => ({
        ...prev,
        cursorStart: lastDoc,
        cursorEnd: -1,
        page,
        cursorPoints: [...prev.cursorPoints, lastDoc],
      }));
    } else {
      setImportPagination((prev) => {
        const prevCursorStart = prev.cursorPoints[prev.cursorPoints.length - 2];
        const lastCursorStart = prev.cursorPoints[prev.cursorPoints.length - 1];

        return {
          ...prev,
          cursorEnd: -1,
          cursorStart: page === 0 ? 2 : prevCursorStart,
          page,
          cursorPoints:
            page === 0
              ? []
              : prev.cursorPoints.filter(
                  (point) =>
                    point?.id !== lastCursorStart?.id ||
                    point !== lastCursorStart
                ),
        };
      });
    }
  };

  const importNextDisabled = useMemo(() => {
    switch (importData.step) {
      case 0:
        return !importData.fileUploadSuccess;
      case 1:
        return importStatus === IMPORT_STATUSES.ERROR_REMAP.status;
      case 2:
        return importStatus !== IMPORT_STATUSES.READY_FOR_IMPORT.status;
      default:
        return false;
    }
  }, [importData.fileUploadSuccess, importData.step, importStatus]);

  const handleCheckImportState = useCallback(async () => {
    try {
      const res = await getImportSessionStateByIdService(
        importData.uploadSession?.session
      );
      return res;
    } catch (err) {
      error("Import data not ready. Try again.");
    }
  }, [importData.uploadSession?.session]);

  const handleUploadSheet = useCallback(
    async (e) => {
      if (e.target.files.length === 0) return;

      const file = e.target.files[0];
      try {
        updateImportData({
          uploading: true,
          fileUploadSuccess: false,
          file: null,
        });

        const uploadSession = await uploadImportSheetService({
          data: file,
          type: importData.importType.key,
          onUploadProgress: (progressEvent) =>
            uploadProgress(progressEvent, setImportData, "uploadingProgress"),
        });

        updateImportData({
          file,
          uploading: false,
          fileUploadSuccess: true,
          uploadSession,
          rowsCount: uploadSession?.count ?? 0,
          fileErrorMessage: null,
          countFetched: true,
        });
        e.target.value = null;
      } catch (err) {
        const message = err?.response?.data?.message;
        // eslint-disable-next-line no-console
        console.error(message);
        updateImportData({
          uploading: false,
          fileUploadSuccess: false,
          fileErrorMessage: message,
        });
        e.target.value = null;
      }
    },
    [importData.importType.key, setImportData, updateImportData]
  );

  const dispatch = useDispatch();

  const handleDeleteImportSession = async (option, schemasList) => {
    try {
      updateImportData({ confirmLoading: true });
      const currentSession =
        importData?.uploadSession?.session ||
        importData.records[option?.key][0]?.session;

      await deleteImportSessionService(currentSession);
      dispatch(setConfirmIsOpenAction(false));

      updateImportData({
        ...defaultImportState,
        isImport: true,
        confirmLoading: false,
        schemas: schemasList,
        importType: option,
      });
    } catch (err) {
      updateImportData({ confirmLoading: false });
      error(err?.response?.data?.message);
    }
  };

  const getRowsCount = useCallback(
    async ({ force }) => {
      if (
        (!force && importData.countFetched) ||
        !importData.uploadSession?.session
      )
        return;
      const rowsSnap = await getCountFromServer(importRef);

      updateImportData({
        countFetched: true,
        rowsCount: rowsSnap?.data?.()?.count || 0,
      });
    },
    [
      importData.countFetched,
      importData.uploadSession?.session,
      importRef,
      updateImportData,
    ]
  );

  const handleContinueMapping = async (type) => {
    const record = importData.records[type][0];
    const step = IMPORT_STATUSES[record.state].step || 0;
    updateImportData({
      step,
      isImport: true,
      uploadSession: record,
    });
    dispatch(setConfirmIsOpenAction(false));
  };

  const handleImport = (option, inProgress) => {
    const currentSchema = importData.allSchemas[option.key];

    updateImportData({
      importType: option,
      currentSchema,
    });

    if (inProgress) {
      dispatch(
        openConfirmDialogAction({
          title: "Import Confirmation",
          text: `You have previously uploaded a file for import. Choose one of the options below:
            
                  1. Delete Previously Uploaded File & Upload New
                  2. Continue Mapping of Previously Uploaded File
  
                  Select your preferred option:`,
          propBtns: {
            left: {
              onClick: () => handleDeleteImportSession(option, currentSchema),
              color: "confirmDelete",
              variant: "contained",
              label: "Delete & Upload New",
            },

            right: {
              color: "primary2",
              onClick: () => handleContinueMapping(option.key),
              variant: "contained",
              label: "Continue Mapping",
            },
          },
        })
      );
      return;
    }

    updateImportData({
      isImport: true,
    });
  };

  const getImportData = useCallback(async () => {
    if (importData.isImport) return;
    try {
      updateImportData({ sessionsListLoading: true });
      const [{ records }, { schemas }] = await Promise.all([
        getImportSessionsService(),
        getImportSchemasService(),
      ]);

      updateImportData({
        sessionsListLoading: false,
        allSchemas: schemas,
        records,
      });
    } catch (err) {
      updateImportData({ sessionsListLoading: false });
      error(err?.response?.data?.message);
    }
  }, [importData.isImport, updateImportData]);

  const handleImportNext = useCallback(async () => {
    const { state } = (await handleCheckImportState()) || {};
    switch (importData.step) {
      case 0: {
        if (state === IMPORT_STATUSES.READY_FOR_REMAP.status)
          updateImportData({ step: importData.step + 1 });
        break;
      }
      case 1: {
        const data = {
          key: importData.importType?.key,
          cols: importData.mappedCols,
        };
        const { success } = await remapImportDataService(
          importData.uploadSession?.session,
          data
        );
        if (success) updateImportData({ step: importData.step + 1 });

        break;
      }
      case 2: {
        const { state } = (await handleCheckImportState()) || {};
        if (state === IMPORT_STATUSES.READY_FOR_IMPORT.status) {
          await finishImportService(importData.uploadSession?.session);
        }
        break;
      }
      default:
        return;
    }
  }, [
    handleCheckImportState,
    importData.mappedCols,
    importData.step,
    importData.uploadSession?.session,
    updateImportData,
    importData.importType?.key,
  ]);

  const getErrorsCount = useCallback(async () => {
    const errorQ = query(
      collection(
        getFirestore(),
        `importer/${importData.uploadSession?.session}/data`
      ),
      where("error", "==", 1)
    );

    const errorsSnap = await getCountFromServer(errorQ);

    updateImportData({
      errorsCount: errorsSnap?.data?.()?.count || 0,
      errorsCountFetched: true,
    });
  }, [importData.uploadSession?.session, updateImportData]);

  const getCurrentTemplate = useCallback(() => {
    switch (importData.importType?.title) {
      case "Customers":
        return ExcelSample;
      case "Orders":
        return OrderExcelSample;
      default:
        return ExcelSample;
    }
  }, [importData.importType]);

  return {
    handleUploadSheet,
    importData,
    updateImportData,
    getImportData,
    handleImport,
    handleImportNext,
    importNextDisabled,
    handleCheckImportState,
    handlePaginate,
    importPagination,
    tableRows,
    importStatusLoading,
    importStatus,
    getRowsCount,
    getErrorsCount,
    importRowsLoading,
    getCurrentTemplate,
    handleDeleteImportSession,
  };
};

export default useImport;
