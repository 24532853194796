import { string, object, func, shape, bool } from "prop-types";
import { Grid, IconButton, Typography } from "@mui/material";

import StyledButton from "../../../components/StyledButton";
import { StarIcon } from "components/Icons";
import { useAdmin } from "helpers/helpers";
import { useRepsPermissions } from "helpers/hooks";
import { ActionRestrictionWrapper } from "components";
// import StyledMenu from "../../../components/StyledMenu/StyledMenu";
// import { ThreeDotButton } from "../../../components";

const ReportItem = ({
  title,
  report,
  config,
  onExport,
  handleFavorite,
  isFavorite,
}) => {
  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();
  // const [anchorEl, setAnchorEl] = useState(null);
  // const openHeaderList = Boolean(anchorEl);

  // const handleClickOnDotsBtn = useCallback((e) => {
  //   e.stopPropagation();
  //   setAnchorEl(e.currentTarget);
  // }, []);

  // const handleCloseDotsBtn = useCallback((e) => {
  //   e.stopPropagation();
  //   setAnchorEl(null);
  // }, []);

  return (
    <Grid height="68px" xs={12} item container pr="13px">
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        xs={10}
        item
      >
        <Typography ml={4} fontSize={22} color="#000">
          {title}
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "9px",
        }}
        xs={2}
        item
      >
        <StyledButton
          sx={{
            "&.MuiButtonBase-root:hover": {
              backgroundColor: "#47A06D",
            },
            backgroundColor: "#47A06D",
            borderRadius: "8px",
            height: "32px",
            width: "78px",
          }}
          label={
            <Typography fontSize={19} fontWeight="400" color="#FFFFFF">
              Run
            </Typography>
          }
          color="inactive"
          variant="outlined"
          onClick={() => onExport({ ...report, config }, true)}
          data-testid="run-button"
        />
        <ActionRestrictionWrapper disableHoverListener={!repPermissions}>
          <IconButton
            disabled={isAdmin || !!repPermissions}
            onClick={() => handleFavorite(report, isFavorite)}
          >
            <StarIcon
              fill={isFavorite ? "#47A06D" : "none"}
              stroke={isFavorite ? "none" : "#D5D9D9"}
            />
          </IconButton>
        </ActionRestrictionWrapper>
        {/* <ThreeDotButton onClick={handleClickOnDotsBtn} /> */}

        {/* <StyledMenu
          sx={{
            paddingY: 0,
          }}
          anchorEl={anchorEl}
          isOpen={openHeaderList}
          handleClose={handleCloseDotsBtn}
        >
          <MenuItem onClick={handleCloseDotsBtn}>
            <ListItemText onClick={() => onExport({ ...report, config }, true)}>
              Customize report
            </ListItemText>
          </MenuItem>
        </StyledMenu> */}
      </Grid>
    </Grid>
  );
};
ReportItem.propTypes = {
  title: string,
  report: object,
  config: shape({
    isShow: object,
  }),
  onExport: func,
  handleFavorite: func,
  isFavorite: bool,
};
ReportItem.defaultProps = {
  title: "Default title",
  report: {},
  config: {},
  onExport: () => {},
  isFavorite: false,
};

export default ReportItem;
