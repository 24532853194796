import { bool, func, number, string, array } from "prop-types";
import { Dialog } from "@mui/material";
import { ContentBlock, HeaderBlock } from "./components";
import { useCallback, useEffect } from "react";

export const FreeCasesDialog = ({
  open,
  onClose,
  list,
  discounts,
  handleAddPromotion,
  handleFetchFreeCases,
  loading,
  cartProducts,
  calcProductPriceByPriceList,
}) => {
  const handleFetchProducts = useCallback(() => {
    if (open) {
      handleFetchFreeCases();
    }
  }, [handleFetchFreeCases, open]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleFetchProducts, [open, discounts]);
  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "8px",
          maxWidth: "504px",
          "& .MuiDialogContent-root": { pb: "18px" },
        },
      }}
      open={open}
      // maxWidth="sm"
    >
      <HeaderBlock title={"Choose discounts"} onClose={onClose} />
      <ContentBlock
        list={list}
        discounts={discounts}
        handleAddPromotion={handleAddPromotion}
        loading={loading}
        cartProducts={cartProducts}
        calcProductPriceByPriceList={calcProductPriceByPriceList}
      />
    </Dialog>
  );
};

FreeCasesDialog.propTypes = {
  open: bool,
  onClose: func,
  manufacturerName: string,
  allowedCount: number,
  list: array,
  handleClickSetFree: func,
  discounts: array,
  handleAddPromotion: func,
  handleFetchFreeCases: func,
  calcProductPriceByPriceList: func,
  loading: bool,
  cartProducts: array,
};
FreeCasesDialog.defaultProps = {
  open: false,
  manufacturerName: "",
  allowedCount: 0,
  discounts: [],
  handleAddPromotion: () => {},
  handleFetchFreeCases: () => {},
  loading: false,
  cartProducts: [],
};
