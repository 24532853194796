import { useMemo, useState, useEffect, useCallback, useRef } from "react";
import { array, func, bool, number } from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import TabSortedComponent from "./components/TabsContent/TabSortedComponent";
import TabFlatComponent from "./components/TabsContent/TabFlatComponent";
import TabMapComponent from "./components/TabsContent/TabMapComponent";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
  setEditTypeAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import {
  archiveBulkAction,
  changePaymentTermBulkAction,
  deleteBulkAction,
  deleteCustomerAction,
  getCustomersAction,
  setCustomersStatusFilter,
  setShowInactiveCustomers,
  updateCustomerGroupTerritoryAction,
} from "redux/actions/customers";
import {
  customersShowInactiveSelector,
  customersDefaultFiltersSelector,
  customersDefaultSwitchSelector,
  customersCountDataSelector,
  customersStatusFilterSelector,
  customersGetParamsSelector,
} from "redux/selectors/customers";
import {
  flatCountSelector,
  flatSelector,
  groupsGetParamsSelector,
} from "redux/selectors/parentCustomers";
import {
  totalSummariesCustomersDataSelector,
  totalSummariesCustomersLoadingSelector,
} from "redux/selectors/total-summaries";
import { getTotalSummariesCustomersDataAction } from "redux/actions/total-summaries";
import { currentUserSelector } from "redux/selectors/auth";
import {
  getGroupsAction,
  getParentCustomersAction,
  groupsGetParams as groupsGetParamsAction,
} from "redux/actions/parentCustomers";
import {
  CUSTOMER_STATUS_FILTERS,
  SCROLL_LIMIT,
  SCROLL_LIMIT_CUSTOMERS,
  UNCATEGORIZED_TERRITORY,
} from "utils/constants";
import NewCustomersFilter from "./components/TabsContent/components/NewCustomersFilter";
import StyledButton from "components/StyledButton";
import { CustomersTotalSummaries } from "./components/CustomersTotalSummaries/CustomersTotalSummaries";
import ReportDialog from "../../../ReportsPage/components/ReportDialog/ReportDialog";
import { reportsList } from "../../../ReportsPage/ReportsPage.constants";
import CustomerOrdersPopup from "./components/CustomerOrdersPopup";
import MissingInfoCounts from "./components/MissingInfoCounts/MissingInfoCounts";
import {
  GroupConfirmDialog,
  NoteDrawer,
  RepsAssignedDrawer,
  TagsDrawer,
  TaskDrawer,
} from "components";
import { useGroupDialogActions } from "./useGroupDialogActions";
import { error, success } from "utils/notifications";
import { bulkAssignCustomerTagsService } from "services/tags";
import {
  getParentCustomersService,
  updateCustomerService,
} from "services/customers";
import { useAssignedReps, useCustomersActionList } from "./CustomersTab.hooks";
import { useRepsPermissions } from "helpers/hooks";
import { TAG_TYPES, useTagsActions } from "helpers/useTagsActions";
import { removeAllPersistData } from "helpers/auth";
import { getTagsAction } from "redux/actions/tags";
import { useReportPage } from "Pages/ReportsPage/ReportsPage.hooks";

const selector = createSelector(
  flatSelector,
  customersDefaultFiltersSelector,
  totalSummariesCustomersDataSelector,
  totalSummariesCustomersLoadingSelector,
  customersDefaultSwitchSelector,
  currentUserSelector,
  customersCountDataSelector,
  groupsGetParamsSelector,
  customersShowInactiveSelector,
  customersStatusFilterSelector,
  customersGetParamsSelector,
  flatCountSelector,
  (
    parentCustomersList,
    customersDefaultFilters,
    totalSummariesCustomersData,
    totalSummariesCustomersLoading,
    customerDefaultSwitch,
    currentUser,
    customerCountData,
    groupsGetParams,
    showInactive,
    customersStatus,
    customersGetParams,
    countParents
  ) => ({
    parentCustomersList,
    customersDefaultFilters,
    totalSummariesCustomersData,
    totalSummariesCustomersLoading,
    customerDefaultSwitch,
    currentUser,
    customerCountData,
    groupsGetParams,
    showInactive,
    customersStatus,
    customersGetParams,
    countParents,
  })
);

const CustomersTab = ({
  checkedCustomers,
  setCheckedCustomers,
  handleCheckCustomer,
  handleSetCheckedCustomersHeader,
  handleSetCheckAllCustomers,
  handleSetCheckAllCustomersWithGroup,
  customersCount,
  loadingCustomers,
  resetCheckedCustomers,
}) => {
  const tabs = useMemo(() => ["flat", "sorted", "map"], []);
  const [currentTab, setCurrentTab] = useState(tabs?.[0]);
  const [currentFlatTab, setCurrentFlatTab] = useState(0);
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [checkedGroups, setCheckedGroups] = useState([]);
  const [allGroupsChecked, setAllGroupsChecked] = useState(false);
  const [isGroups, setIsGroups] = useState(false);
  const [expanded, setExpanded] = useState(null);
  const [resetTableHeight, setResetTableHeight] = useState(false);
  const [limitActiveCustomers, setActiveLimitCustomers] = useState([]);
  const [quickSort, setQuickSort] = useState({
    general: {
      sort_visit: "desc",
      sort_orders: "",
      sort_last_order: "",
      sort_display_name: "",
      sort_territory_name: null,
      sort_order_direct: null,
      sort_parent_customer_name: null,
      sort_by_orders_count: null,
    },
  });

  const statusRef = useRef(null);

  const getExpendedPage = useCallback(() => {
    if (!limitActiveCustomers.length || !expanded) return 1;

    return limitActiveCustomers.filter((item) => item.id === expanded)[0]?.page;
  }, [expanded, limitActiveCustomers]);

  const expendedPageParam = useMemo(() => getExpendedPage(), [getExpendedPage]);

  const repPermissions = useRepsPermissions();

  const {
    parentCustomersList,
    customersDefaultFilters,
    totalSummariesCustomersData,
    totalSummariesCustomersLoading,
    customerDefaultSwitch,
    currentUser,
    customerCountData,
    showInactive,
    customersStatus,
    groupsGetParams,
    customersGetParams,
    countParents,
  } = useSelector(selector);

  const haveCustomersMissingInfo = useMemo(
    () => (currentUser?.countCustomersWithMissingInfo ?? 0) > 0,
    [currentUser?.countCustomersWithMissingInfo]
  );
  const currentList = useMemo(
    () => (isGroups ? checkedGroups : checkedCustomers),
    [checkedCustomers, checkedGroups, isGroups]
  );

  const isCheckedAllCustomersItems = useMemo(() => {
    return currentList?.length === customersCount;
  }, [currentList?.length, customersCount]);

  const isCheckedAllParentsItems = useMemo(() => {
    return checkedGroups?.length === countParents - 1;
  }, [checkedGroups?.length, countParents]);

  const currentSetList = useMemo(
    () => (isGroups ? setCheckedGroups : setCheckedCustomers),
    [isGroups, setCheckedCustomers]
  );

  const getCustomersFromParentCustomers = useCallback(
    (list) => {
      return list.flatMap((item) =>
        item.subCustomers && currentTab === "sorted" ? item.subCustomers : []
      );
    },
    [currentTab]
  );

  const findAndReplaceCheckedCustomers = useCallback(
    (updatedList) => {
      if (!currentList.length || !updatedList || !updatedList.length) return;
      const checkedIds = currentList.map(({ id }) => id);

      const filteredList =
        currentTab !== "sorted"
          ? updatedList
          : getCustomersFromParentCustomers(updatedList);

      if (!filteredList.length) return;

      const updatedCheckedList = filteredList.filter(({ id }) =>
        checkedIds.includes(id)
      );

      if (!updatedCheckedList.length && currentTab === "map") return;
      currentSetList(updatedCheckedList);
    },
    [currentList, currentSetList, currentTab, getCustomersFromParentCustomers]
  );

  const filterCustomersWithMissingInfo = (el) => {
    return haveCustomersMissingInfo ? true : el?.value !== "missing_info";
  };

  const [switches, setSwitches] = useState(
    customerDefaultSwitch?.filter(filterCustomersWithMissingInfo)
  );
  const [taskDialogState, setTaskDialogState] = useState({
    open: false,
    data: null,
    customer: null,
  });

  const [page, setPage] = useState(1);

  const actionFetchParams = useMemo(() => {
    return {
      limit: page * SCROLL_LIMIT,
    };
  }, [page]);

  const actionGroupFetchParams = useMemo(() => {
    return {
      page: expendedPageParam,
      ...quickSort.general,
    };
  }, [expendedPageParam, quickSort]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [filterSearch, setFilterSearch] = useState("");
  const [shapes, setShapes] = useState([]);
  const [ordersState, setOrdersState] = useState({
    open: false,
    customer: null,
  });

  const handleFetchParents = useCallback(
    (page) => {
      if (currentFlatTab !== 1 && currentTab !== tabs[0]) return;

      dispatch(groupsGetParamsAction({ ...groupsGetParams, page }));

      dispatch(
        getParentCustomersAction(
          {
            page: page ? page : groupsGetParams.page,
            status: `["${
              showInactive
                ? CUSTOMER_STATUS_FILTERS.inactive
                : CUSTOMER_STATUS_FILTERS.active
            }"]`,
          },
          (updatedList) => findAndReplaceCheckedCustomers(updatedList)
        )
      );
    },
    [
      currentFlatTab,
      currentTab,
      tabs,
      dispatch,
      groupsGetParams,
      showInactive,
      findAndReplaceCheckedCustomers,
    ]
  );

  const checkAllGroups = useCallback(() => {
    const filteredGroups = parentCustomersList.filter(
      (group) => group?.name !== "Uncategorized"
    );
    if (checkedGroups.length === filteredGroups.length) {
      setAllGroupsChecked(false);
      return setCheckedGroups([]);
    }
    setCheckedGroups([...filteredGroups]);
  }, [checkedGroups.length, parentCustomersList]);

  const onSelectAllGroups = useCallback(() => {
    const filteredGroups = parentCustomersList.filter(
      (group) => group?.name !== "Uncategorized"
    );
    if (checkedGroups.length === filteredGroups.length) {
      setAllGroupsChecked(false);
      return setCheckedGroups([]);
    }

    setLoadingGroups(true);
    getParentCustomersService({
      status: `["${
        showInactive
          ? CUSTOMER_STATUS_FILTERS.inactive
          : CUSTOMER_STATUS_FILTERS.active
      }"]`,
    })
      .then(({ rows }) => setCheckedGroups(rows))
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err.message);
        error(err.response?.data?.message || "Something went wrong.");
      })
      .finally(() => setLoadingGroups(false));
    setCheckedGroups([...filteredGroups]);
  }, [checkedGroups?.length, parentCustomersList, showInactive]);

  const fetchGroups = useMemo(() => {
    return isCheckedAllParentsItems
      ? () => onSelectAllGroups()
      : () => handleFetchParents();
  }, [isCheckedAllParentsItems, handleFetchParents, onSelectAllGroups]);

  const groupFetchCallback = useMemo(
    () =>
      isGroups
        ? () => fetchGroups()
        : currentTab === "sorted"
        ? findAndReplaceCheckedCustomers
        : null,
    [currentTab, findAndReplaceCheckedCustomers, fetchGroups, isGroups]
  );

  const [filterFields, setFilterFields] = useState(customersDefaultFilters);

  const handleChangeCurrentTab = (tab) => {
    statusRef.current = null;
    setCurrentTab(tab);
  };

  const handleChangeCurrentFlatTab = (tab) => {
    setCurrentFlatTab(tab);
    dispatch(setShowInactiveCustomers(false));
    if (groupsGetParams?.page !== 1)
      dispatch(groupsGetParamsAction({ ...groupsGetParams, page: 1 }));
  };

  const handleCustomerTaskDrawer = useCallback((open, data, customer) => {
    setTaskDialogState({
      open,
      data,
      customer,
    });
  }, []);

  const queryParams = useMemo(
    () => ({
      status: `["${customersStatus}"]`,
      search: filterSearch,
      representative_id: filterFields?.representative_id?.value ?? "",
      territory_id:
        filterFields.territory_id?.value === UNCATEGORIZED_TERRITORY.id
          ? "null"
          : filterFields.territory_id?.value,
      parent_customer_id: filterFields.group_id?.value,
      tag_id: filterFields.tag_id?.value,
    }),
    [
      customersStatus,
      filterFields.group_id?.value,
      filterFields?.representative_id?.value,
      filterFields.tag_id?.value,
      filterFields.territory_id?.value,
      filterSearch,
    ]
  );

  useEffect(() => {
    const issuesField = customerDefaultSwitch.filter(
      (item) => item.value === "issues"
    )?.[0];

    const purchasedProductsField = customerDefaultSwitch.filter(
      (item) => item.value === "purchasedProducts"
    );

    if (
      typeof issuesField?.values?.[0] !== "string" ||
      !purchasedProductsField?.length
    )
      return removeAllPersistData();
  }, [customerDefaultSwitch, dispatch]);

  useEffect(() => setPage(1), [queryParams, filterFields]);

  useEffect(() => {
    resetCheckedCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterFields, filterSearch]);

  useEffect(() => {
    if (currentFlatTab === 1 && currentTab === tabs[0])
      return setIsGroups(true);
    setIsGroups(false);
  }, [currentFlatTab, currentTab, tabs]);

  const handleOpenConfirmDialog = useCallback(
    (confirmQuery) => {
      dispatch(openConfirmDialogAction(confirmQuery));
    },
    [dispatch]
  );

  const formChanged = useSelector(
    ({ confirmDialogs }) => confirmDialogs.formChanged
  );
  const editType = useSelector(({ confirmDialogs }) => confirmDialogs.editType);
  const { timeZone } = useSelector(({ auth }) => auth?.currentUser || {});

  const handleConfirmCheckedItemsDialog = useCallback(
    ({ id, nav }) => {
      dispatch(
        openConfirmDialogAction({
          title: "Leaving this page?",
          text: "Your selected list will be discarded.",
          buttons: (
            <>
              <StyledButton
                variant="outlined"
                color="cancel"
                type="submit"
                form={`new-${editType}-form`}
                label="Cancel"
                sx={{ height: "28px" }}
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                }}
              />
              <Button
                sx={{
                  width: "auto",
                  height: "28px",
                  color: "#FFFFFF",
                  fontSize: "13px",
                  boxShadow: "none",
                }}
                color="confirmDelete"
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                  dispatch(setFormChangedAction(false));
                  if (nav) {
                    nav();
                  } else {
                    navigate(id);
                  }
                }}
                variant="contained"
              >
                Confirm
              </Button>
            </>
          ),
        })
      );
    },
    [dispatch, editType, navigate]
  );

  const handleOpenCustomerProfile = useCallback(
    (id) => {
      if (formChanged && editType === "checked_items") {
        return handleConfirmCheckedItemsDialog({ id });
      } else {
        navigate(`/customers/${id}`);
      }
    },
    [editType, formChanged, handleConfirmCheckedItemsDialog, navigate]
  );

  const handleRedirectMissingInfo = useCallback(
    (customer) => {
      if (!customer) return;

      const isMissingInfo = customer?.missingFields;
      const duplicatedFields = customer?.duplicatedFields;

      if (!isMissingInfo?.length && !duplicatedFields?.length) return;

      const state = {
        ...(isMissingInfo?.length && { checkMissingInfo: true }),
        ...(duplicatedFields?.length && { checkDuplicateInfo: true }),
        redirectToList: true,
      };

      if (formChanged && editType === "checked_items") {
        return handleConfirmCheckedItemsDialog({
          nav: () => navigate(`/customers/edit/${customer?.id}`, { state }),
        });
      } else {
        navigate(`/customers/edit/${customer?.id}`, { state });
      }
    },
    [editType, formChanged, handleConfirmCheckedItemsDialog, navigate]
  );

  const handleDeleteGroup = useCallback((e) => {
    e.stopPropagation();
    //  dispatch(deleteGroupAction(id));
  }, []);

  const handleEditGroup = useCallback(
    (e, group) => {
      e.stopPropagation();
      navigate(`edit/${group.id}`, {
        state: group,
      });
    },
    [navigate]
  );

  const handleDeleteCustomers = useCallback(
    (id, hasDuplicated) => {
      setResetTableHeight(true);
      if (id) {
        dispatch(
          deleteCustomerAction({
            ids: id,
            withRefetch: hasDuplicated,
            onSuccess: isGroups ? fetchGroups : null,
          })
        );
        resetCheckedCustomers();
        return;
      }

      const ids = isGroups
        ? checkedGroups.map((customer) => customer.id)
        : checkedCustomers.map((customer) => customer.id);

      const filterForDuplicates = checkedCustomers.filter(
        (obj) => obj.duplicatedFields && !!obj.duplicatedFields.length
      );

      dispatch(
        deleteCustomerAction({
          ids,
          withRefetch: !!filterForDuplicates.length,
          onSuccess: isGroups ? fetchGroups : null,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, checkedCustomers]
  );

  const clearShapes = useCallback(() => {
    shapes.forEach((shape) => shape.setMap(null));
  }, [shapes]);

  const handleDeleteBulkCustomers = useCallback(() => {
    let preparedData = {
      customerIds: isGroups
        ? checkedGroups.map((c) => c?.id)
        : checkedCustomers.map((c) => c?.id),
    };

    setResetTableHeight(true);
    dispatch(
      deleteBulkAction({
        data: preparedData,
        query: queryParams,
        //onSuccess: isGroups ? handleFetchParents : null,
      })
    );
    resetCheckedCustomers();
    setCheckedGroups([]);
    clearShapes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isGroups,
    checkedGroups,
    checkedCustomers,
    dispatch,
    queryParams,
    resetCheckedCustomers,
    clearShapes,
  ]);

  const handleArchiveCustomers = useCallback(
    (id) => {
      setResetTableHeight(true);
      const checkedCurrent = isGroups
        ? [...checkedGroups]
        : [...checkedCustomers];
      let preparedData = {
        customerIds: checkedCurrent.map((customer) => customer?.id),
        status: (
          isGroups
            ? showInactive
            : customersStatus === CUSTOMER_STATUS_FILTERS.inactive
        )
          ? CUSTOMER_STATUS_FILTERS.active
          : CUSTOMER_STATUS_FILTERS.inactive,
      };

      resetCheckedCustomers();
      setCheckedGroups([]);

      clearShapes();
      if (id)
        return dispatch(
          archiveBulkAction({
            data: {
              customerIds: id,
              status: (
                isGroups
                  ? showInactive
                  : customersStatus === CUSTOMER_STATUS_FILTERS.inactive
              )
                ? CUSTOMER_STATUS_FILTERS.active
                : CUSTOMER_STATUS_FILTERS.inactive,
            },
            //onSuccess: isGroups ? handleFetchParents : null,
          })
        );
      dispatch(
        archiveBulkAction({
          data: preparedData,
          query: queryParams,
          //onSuccess: isGroups ? handleFetchParents : null,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      checkedGroups,
      checkedCustomers,
      clearShapes,
      dispatch,
      queryParams,
      isGroups,
    ]
  );

  const fetchCustomers = useMemo(() => {
    return isCheckedAllCustomersItems
      ? () => handleSetCheckAllCustomers("fetch all customers")
      : findAndReplaceCheckedCustomers;
  }, [
    findAndReplaceCheckedCustomers,
    handleSetCheckAllCustomers,
    isCheckedAllCustomersItems,
  ]);

  const handleChangePaymentTerm = (customers, paymentTermsId) => {
    if (typeof paymentTermsId !== "number")
      // eslint-disable-next-line no-console
      return console.log("The payment terms id is not a number");

    const preparedData = {
      paymentTermsId,
      customerIds: customers.map((customer) => customer.id),
    };

    statusRef.current = null;
    dispatch(
      changePaymentTermBulkAction(
        preparedData,
        isGroups ? null : fetchCustomers,
        groupFetchCallback,
        actionFetchParams,
        actionGroupFetchParams
      )
    );
    //resetCheckedCustomers();
    //setCheckedGroups([]);

    //clearShapes();
  };

  const groupCustomersFetchCallback = () => {
    const { page, ...rest } = actionGroupFetchParams;
    currentTab === "sorted"
      ? dispatch(
          getGroupsAction(
            {
              limit: SCROLL_LIMIT,
              limit_sub_customers: page
                ? page * SCROLL_LIMIT_CUSTOMERS
                : SCROLL_LIMIT_CUSTOMERS,
              ...rest,
            },
            (res) => {
              findAndReplaceCheckedCustomers(res);
            }
          )
        )
      : dispatch(
          getCustomersAction(
            actionFetchParams,
            null,
            findAndReplaceCheckedCustomers,
            actionGroupFetchParams
          )
        );
  };

  const {
    groupData,
    handleOpenGroupDialog,
    handleCloseGroupDialog,
    handleFetch: handleFetchGroup,
    handleSaveParentGroup,
  } = useGroupDialogActions({
    handleFetchCustomers: groupCustomersFetchCallback,
    isGroups,
  });

  const handleChangeParentGroup = useCallback(
    (customer) => {
      handleOpenGroupDialog({
        customer: customer || null,
        customers:
          !customer && checkedCustomers?.length ? checkedCustomers : null,
      });
      //resetCheckedCustomers();
      //clearShapes();
    },
    [handleOpenGroupDialog, checkedCustomers]
  );

  const handleChangeGroup = useCallback(
    (customerIds, groupId) => {
      let preparedData = {
        groupId: groupId,
        narrowedFilter: {
          includeParentCustomers: false,
        },
        customerIds:
          customerIds?.length === 1 && !customerIds?.[0]?.id
            ? customerIds
            : customerIds.map((el) => el?.id || el),
      };

      dispatch(updateCustomerGroupTerritoryAction(preparedData, queryParams));
      resetCheckedCustomers();
      clearShapes();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, queryParams, clearShapes]
  );

  useEffect(() => {
    resetCheckedCustomers();
    clearShapes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersStatus]);

  const handleChangeTerritory = useCallback(
    (customerIds, territoryId) => {
      let preparedData = {
        territoryId:
          territoryId === UNCATEGORIZED_TERRITORY.id ? null : territoryId,
        customerIds,
      };
      statusRef.current = null;
      dispatch(
        updateCustomerGroupTerritoryAction(
          preparedData,
          queryParams,
          isGroups ? null : fetchCustomers,
          groupFetchCallback,
          actionFetchParams,
          actionGroupFetchParams
        )
      );
      //resetCheckedCustomers();
      //setCheckedGroups([]);
      //clearShapes();
    },
    [
      dispatch,
      queryParams,
      isGroups,
      fetchCustomers,
      groupFetchCallback,
      actionFetchParams,
      actionGroupFetchParams,
    ]
  );

  const handleCheckGroup = (group) => {
    const customerIndex = checkedGroups.findIndex(
      (checkedGroup) => checkedGroup.id === group.id
    );
    if (customerIndex > -1) {
      const newGroups = [...checkedGroups];
      newGroups.splice(customerIndex, 1);
      return setCheckedGroups([...newGroups]);
    }

    setCheckedGroups([...checkedGroups, group]);
  };

  useEffect(() => {
    dispatch(getTotalSummariesCustomersDataAction());
    return () => {
      dispatch(setCustomersStatusFilter(CUSTOMER_STATUS_FILTERS.active));
    };
  }, [dispatch]);

  useEffect(() => {
    if (currentList?.length > 1) {
      dispatch(setEditTypeAction("checked_items"));
      dispatch(setFormChangedAction(true));
    }

    if (!currentList?.length || currentList?.length <= 1) {
      dispatch(setEditTypeAction(""));
      dispatch(setFormChangedAction(false));
    }
  }, [currentList?.length, dispatch]);

  const [openTagsDrawer, setOpenTagsDrawer] = useState(false);
  const [loadingTagsDrawer, setLoadingTagsDrawer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handleAssignTags = (customer) => {
    setOpenTagsDrawer(true);

    if (customer) return setSelectedCustomer(customer);
  };

  const {
    list: tagsList,
    count: tagsCount,
    handleFetchTags,
    page: tagsPage,
    setTagsParams,
    searcheble,
  } = useTagsActions({
    type: "customer",
    params: {
      used: true,
      sort_by_tag: "asc",
    },
  });

  const handleSaveTags = async ({ customerIds, tags, isBulk }) => {
    if (loadingTagsDrawer) return;

    try {
      setLoadingTagsDrawer(true);

      const customerTags = tags
        ?.filter(({ id, newTag }) => id && !newTag)
        ?.map(({ id }) => id);

      const newCustomerTags = tags
        ?.filter(({ newTag }) => !!newTag)
        ?.map(({ tag }) => tag);

      statusRef.current = null;
      if (isBulk) {
        await bulkAssignCustomerTagsService({
          customerIds,
          customerTags,
          newCustomerTags,
        });
      } else {
        const data = {
          customerTags,
          newCustomerTags,
        };
        await updateCustomerService(data, customerIds[0]);
      }

      success("Tags updated successfully");
      handleFetchTags(1);

      dispatch(getTagsAction({ limit: SCROLL_LIMIT }));

      if (isGroups) return fetchGroups();

      dispatch(getCustomersAction(actionFetchParams, null, fetchCustomers));
      const { page, ...rest } = actionGroupFetchParams;
      currentTab === "sorted" &&
        dispatch(
          getGroupsAction(
            {
              limit: SCROLL_LIMIT,
              limit_sub_customers: page
                ? page * SCROLL_LIMIT_CUSTOMERS
                : SCROLL_LIMIT_CUSTOMERS,
              ...rest,
            },
            (res) => {
              findAndReplaceCheckedCustomers(res);
            }
          )
        );
      return;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setLoadingTagsDrawer(false);
      //setCheckedGroups([]);
      //resetCheckedCustomers();
      handleCloseTagsDrawer();
    }
  };

  const handleCloseTagsDrawer = () => {
    setSelectedCustomer(null);
    setOpenTagsDrawer(false);
  };

  const handleCancelSelection = () => {
    resetCheckedCustomers();
    clearShapes();
  };

  const handleCreateRoute = useCallback(() => {
    dispatch(setFormChangedAction(false));
    navigate("/routes/new", { state: { checkedCustomers } });
  }, [checkedCustomers, dispatch, navigate]);

  const [noteDialogState, setNoteDialogState] = useState({
    open: false,
    data: null,
  });

  const handleCloseCustomerNotes = useCallback(() => {
    setNoteDialogState({
      open: false,
      data: null,
    });
  }, []);

  const handleOpenCustomerNotes = useCallback((data) => {
    setNoteDialogState({
      open: true,
      data,
    });
  }, []);

  const handleOrders = (customer) => {
    setOrdersState((prev) => ({ ...prev, customer, open: true }));
  };

  const {
    assignedRepsState,
    setAssignedRepsState,
    handleCloseRepsAssignedDrawer,
    handleAssignedRepresentatives,
    handleDeleteAllReps,
    handleOpenTransfer,
  } = useAssignedReps({
    checkedCurrent: isGroups ? checkedGroups : checkedCustomers,
    resetCheckedCustomers,
    clearShapes,
    setCheckedGroups,
    queryParams,
    // handleFetchParents: isGroups ? handleFetchParents : null,
  });

  const handleAssignReps = useCallback(
    (customer) => {
      setAssignedRepsState({
        open: true,
        customer,
        assignedReps:
          customer?.assignedRepresentatives?.map((r) => r?.representative) ||
          [],
      });
    },
    [setAssignedRepsState]
  );

  const handleReport = useCallback((report, isCustom) => {
    setCustom(!!isCustom);
    setCurrentReport(report);
    setOpenReportDialog(true);
  }, []);

  const reportData = useMemo(() => {
    return {
      ...reportsList[0].reports[0],
      selectAndReorderColumnsBottom:
        reportsList[0].reports[0].selectAndReorderColumnsBottom.map(
          (field) => ({
            ...field,
            chosen: true,
            selected: true,
          })
        ),
      config: { ...reportsList[0].config },
    };
  }, []);

  const { handleGetActions, getAdditionalActions, handleGetOrderActions } =
    useCustomersActionList({
      customer: currentList.length === 1 ? currentList[0] : {},
      repPermissions,
      handleOpenConfirmDialog,
      handleChangeTerritory,
      handleChangeGroup: handleChangeParentGroup,
      handleChangePaymentTerm,
      handleDeleteCustomers,
      handleArchiveCustomers,
      handleAssignReps,
      handleCustomerTaskDrawer,
      handleAssignTags,
      handleOrders,
      onReport: handleReport,
      reportData,
      checkedItemsList: currentList,
      showInactive,
      setCheckedCustomers,
    });

  const moreActionList = handleGetActions(true, {
    isGroups,
    checkedGroups,
    checkedCustomers,
    handleOpenTransfer,
    handleFetchParents,
    handleDeleteBulkCustomers,
    handleChangeParentGroup,
  });

  const additionalActions = getAdditionalActions(
    isGroups,
    handleCreateRoute,
    handleOpenTransfer
  );

  const MAP_FILTER_PARAMS = useMemo(() => {
    return {
      ...customersGetParams,
      sort_visit: null,
      sort_orders: null,
      sort_name: null,
      sort_newest: null,
      sort_last_order: null,
    };
  }, [customersGetParams]);

  const resetTableCallback = () => {
    setPage(1);
    setResetTableHeight(false);
  };

  const tabParams = currentFlatTab === 0 ? customersGetParams : groupsGetParams;

  const tableFilterParams = useMemo(() => {
    return { ...tabParams, reset: resetTableHeight };
  }, [resetTableHeight, tabParams]);

  const showProspectsToggle = useMemo(
    () => customersStatus === CUSTOMER_STATUS_FILTERS.active,
    [customersStatus]
  );

  const showCustomersToggle = useMemo(
    () => customersStatus === CUSTOMER_STATUS_FILTERS.prospect,
    [customersStatus]
  );

  const renderContent = (currentTab, tabs) => {
    switch (currentTab) {
      case tabs[1]:
        return (
          <TabSortedComponent
            onReport={handleReport}
            actionList={moreActionList}
            reportData={{
              ...reportsList[0].reports[0],
              selectAndReorderColumnsBottom:
                reportsList[0].reports[0].selectAndReorderColumnsBottom.map(
                  (field) => ({ ...field, chosen: true, selected: true })
                ),
              config: { ...reportsList[0].config },
            }}
            {...{
              handleEditGroup,
              handleDeleteGroup,
              handleOpenConfirmDialog,
              handleOpenCustomerProfile,
              handleRedirectMissingInfo,
              handleCheckCustomer,
              checkedCustomers,
              handleChangeTerritory,
              handleChangeGroup,
              handleChangePaymentTerm,
              handleDeleteCustomers,
              handleArchiveCustomers,
              resetTableCallback,
              tableFilterParams,
              handleAssignReps,
              groupList: parentCustomersList,
              handleSetCheckedCustomersHeader,
              handleSetCheckAllCustomersWithGroup,
              loadingCustomers,
              handleOpenCustomerNotes,
              handleOrders,
              handleApplyFilter,
              repPermissions,
              handleGetOrderActions,
              additionalActions,
              handleSetCheckAllCustomers,
              setCheckedCustomers,
              onSelectAllGroups,
              expanded,
              setExpanded,
              limitActiveCustomers,
              setActiveLimitCustomers,
              quickSort,
              setQuickSort,
              filterFields,
            }}
          />
        );
      case tabs[0]:
        return (
          <TabFlatComponent
            handleCheckCustomer={handleCheckCustomer}
            onReport={handleReport}
            actionList={moreActionList}
            handleGetOrderActions={handleGetOrderActions}
            setCheckedCustomers={
              isGroups ? setCheckedGroups : setCheckedCustomers
            }
            reportData={reportData}
            {...{
              checkedGroups,
              handleCheckGroup,
              allGroupsChecked,
              checkAllGroups,
              handleEditGroup,
              handleDeleteGroup,
              handleOpenConfirmDialog,
              handleOpenCustomerProfile,
              handleRedirectMissingInfo,
              handleChangeTerritory,
              handleChangeGroup,
              handleChangeParentGroup,
              handleChangePaymentTerm,
              handleDeleteCustomers,
              handleArchiveCustomers,
              currentFlatTab,
              checkedCustomers,
              resetTableCallback,
              tableFilterParams,
              handleSetCheckedCustomersHeader,
              handleAssignReps,
              parentCustomersList,
              customersCount,
              handleOpenCustomerNotes,
              handleOrders,
              page,
              setPage,
              handleFetchParents,
              handleCustomerTaskDrawer,
              handleAssignTags,
              repPermissions,
              loadingCustomers,
              handleSetCheckAllCustomers,
              additionalActions,
              loadingGroups,
              onSelectAllGroups,
            }}
          />
        );
      case tabs[2]:
        return (
          <TabMapComponent
            handleApplyFilter={handleApplyFilter}
            currentMissingInfoCount={customerCountData.missingInfoCount}
            canDraw
            showHeader
            autoZoomDistributor
            params={MAP_FILTER_PARAMS}
            actionList={moreActionList}
            styles={{
              height: "calc(100vh - 283px)",
              borderRadius: "0 0 4px 4px",
            }}
            {...{
              checkedCustomers,
              setCheckedCustomers,
              setShapes,
              clearShapes,
              handleCancelSelection,
              handleCheckCustomer,
              handleCreateRoute,
              additionalActions,
              handleSetCheckAllCustomers,
              findAndReplaceCheckedCustomers,
              currentList,
              loadingCustomers,
              showProspectsToggle,
              showCustomersToggle,
              statusRef,
            }}
          />
        );
    }
  };

  const [isOpenReportDialog, setOpenReportDialog] = useState(false);
  const [currentReport, setCurrentReport] = useState(null);
  const [isCustom, setCustom] = useState(false);

  const handleCloseReportDialog = useCallback(() => {
    setCustom(false);
    setOpenReportDialog(false);
    setCurrentReport(null);
  }, []);

  const { handleSetFavorite, reportState } = useReportPage();

  const handleApplyFilter = (infoFilter) => {
    setFilterFields((prev) => ({
      ...prev,
      issues: [...(prev?.issues || []), infoFilter.name],
    }));
    if (infoFilter.value === "with_missing_info") {
      setCurrentTab("flat");
    }
    const index = switches.findIndex((s) => s.value === "issues");
    if (index > -1) {
      const insert = {
        ...switches[index],
        checked: true,
      };
      const newSwitches = [...switches];
      newSwitches.splice(index, 1, insert);
      setSwitches(newSwitches);
    }
  };

  const handleRefreshTable = (notesList) => {
    if (notesList.length === 1) {
      dispatch(getCustomersAction({ limit: 15, page: page }));
      dispatch(
        getGroupsAction(
          {
            limit: SCROLL_LIMIT,
            limit_sub_customers: page
              ? page * SCROLL_LIMIT_CUSTOMERS
              : SCROLL_LIMIT_CUSTOMERS,
          },
          (res) => {
            findAndReplaceCheckedCustomers(res);
          }
        )
      );
    }
  };

  return (
    <>
      <CustomerOrdersPopup
        open={ordersState.open}
        onClose={() => setOrdersState((prev) => ({ ...prev, open: false }))}
        timeZone={timeZone}
        customer={ordersState.customer}
      />

      {!!noteDialogState.open && (
        <NoteDrawer
          open={noteDialogState.open}
          onClose={handleCloseCustomerNotes}
          data={noteDialogState.data}
          refetchCallback={handleRefreshTable}
          titleLabel="Notes"
        />
      )}

      <TagsDrawer
        type={TAG_TYPES.customer}
        open={openTagsDrawer}
        handleClose={handleCloseTagsDrawer}
        handleSave={handleSaveTags}
        customers={
          selectedCustomer
            ? [selectedCustomer]
            : isGroups
            ? checkedGroups
            : checkedCustomers
        }
        isBulk={!selectedCustomer}
        loading={loadingTagsDrawer}
        setCheckedCustomers={isGroups ? setCheckedGroups : setCheckedCustomers}
      />

      <GroupConfirmDialog
        open={!!groupData.customer || !!groupData.customers?.length}
        onClose={handleCloseGroupDialog}
        data={groupData}
        handleFetch={handleFetchGroup}
        handleSave={(parentCustomerData) => {
          statusRef.current = null;
          handleSaveParentGroup(
            parentCustomerData,
            fetchCustomers,
            actionFetchParams,
            actionGroupFetchParams
          );
        }}
      />

      <Box px="32px">
        <Box pt="12px">
          <CustomersTotalSummaries
            isLoading={totalSummariesCustomersLoading}
            totalSummaries={totalSummariesCustomersData}
          />
        </Box>
        <NewCustomersFilter
          tagsList={tagsList}
          tagsCount={tagsCount}
          tagsPage={tagsPage}
          setTagsParams={setTagsParams}
          //handleArchiveCustomers={() =>
          //  handleArchiveCustomers(null, handleFetchParents)
          //}
          //handleChangeTerritory={(checkedItems, territoryId) => {
          //  handleChangeTerritory(
          //    checkedItems,
          //    territoryId,
          //    handleFetchParents
          //  );
          //}}
          {...{
            handleFetchTags,
            handleChangePaymentTerm,
            setFilterSearch,
            setFilterFields,
            filterFields,
            handleCancelSelection,
            isGroups,
            checkedGroups,
            checkedCustomers,
            handleChangeCurrentFlatTab,
            currentFlatTab,
            handleChangeCurrentTab,
            currentTab,
            handleCreateRoute,
            resetCheckedCustomers,
            switches,
            setSwitches,
            handleAssignTags,
            searcheble,
          }}
        />
        {currentTab === "flat" && (
          <Box
            paddingY="10px"
            display="flex"
            backgroundColor="white"
            border="1px solid #d5d9d9"
            borderTop="none"
          >
            <Tabs
              sx={{
                minHeight: "auto",
                margin: 0,
                padding: 0,
                "& .Mui-selected": {
                  backgroundColor: "transparent!important",
                  color: "#47A06D !important",
                },
                "& .MuiTab-root": {
                  padding: "4px 0",
                  minHeight: "auto",
                  color: "#9C9C9C",
                },
                "& .MuiTabs-indicator": {
                  borderTopRightRadius: "4px",
                  borderTopLeftRadius: "4px",
                },
                paddingLeft: "14px",
              }}
              value={currentFlatTab}
              onChange={(e, newValue) => handleChangeCurrentFlatTab(newValue)}
            >
              <Tab
                label={
                  <Typography
                    variant="caption"
                    sx={{ textTransform: "none", fontSize: "16px" }}
                  >
                    Customers
                  </Typography>
                }
                key={0}
                value={0}
              />
              <Tab
                label={
                  <Typography
                    variant="caption"
                    sx={{ textTransform: "none", fontSize: "16px" }}
                  >
                    Groups
                  </Typography>
                }
                key={1}
                value={1}
              />
            </Tabs>
            {currentFlatTab === 0 && (
              <MissingInfoCounts
                filterFields={filterFields.issues}
                handleApplyFilter={handleApplyFilter}
                countData={customerCountData}
              />
            )}
          </Box>
        )}
        {renderContent(currentTab, tabs)}

        <RepsAssignedDrawer
          isOpen={assignedRepsState?.open}
          handleClose={handleCloseRepsAssignedDrawer}
          assignedRepresentatives={assignedRepsState?.assignedReps}
          handleSetData={(reps) => {
            statusRef.current = null;
            handleAssignedRepresentatives(
              reps,
              isGroups ? null : fetchCustomers,
              groupFetchCallback,
              actionFetchParams,
              actionGroupFetchParams
            );
          }}
          handleDeleteAllReps={() =>
            handleDeleteAllReps(isGroups ? fetchGroups : fetchCustomers)
          }
          submitBtnLabel="Update"
        />

        <ReportDialog
          open={isOpenReportDialog}
          onClose={handleCloseReportDialog}
          report={currentReport}
          isCustom={isCustom}
          handleSetFavorite={handleSetFavorite}
          disabledCustomReportBtn={reportState.loading}
        />

        {taskDialogState.open && (
          <TaskDrawer
            open={taskDialogState.open}
            onClose={() => handleCustomerTaskDrawer(false, null, null)}
            data={taskDialogState.data}
            selectedCustomers={isGroups ? checkedGroups : checkedCustomers}
            customer={taskDialogState?.customer}
            setCheckedCustomers={
              isGroups ? setCheckedGroups : setCheckedCustomers
            }
            isAdvancedDrawer
            resetData={false}
          />
        )}
      </Box>
    </>
  );
};
CustomersTab.propTypes = {
  checkedCustomers: array,
  setCheckedCustomers: func,
  handleCheckCustomer: func,
  handleSetCheckedCustomersHeader: func,
  handleSetCheckAllCustomers: func,
  handleSetCheckAllCustomersWithGroup: func,
  customersCount: number,
  loadingCustomers: bool,
  resetCheckedCustomers: func,
};

export default CustomersTab;
