import { useCallback, useEffect, useMemo, useState } from "react";
import { func, array, bool, object, string } from "prop-types";
import {
  Box,
  Button,
  Grid,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";

import TabHeaderComponent from "./TabHeaderComponent.jsx";
import TabListComponent from "./TabListComponent.jsx";

import { ShevronIcon } from "components/Icons";
import { MarkerIcon, BoxIcon, TrashIcon } from "components/Icons";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import {
  customersExistSelector,
  customersLoadingSelector,
  customersShowInactiveSelector,
} from "redux/selectors/customers.js";
import {
  parentCustomersActiveSelector,
  parentCustomersActiveCountSelector,
  parentCustomersLoadingSelector,
} from "redux/selectors/parentCustomers.js";
import { PenIcon, ReportIcon, RouteIcon } from "components/Icons/MenuIcons";
import StyledMenu from "components/StyledMenu/StyledMenu.jsx";

import {
  getCustomerByGroupIdAction,
  getGroupsActiveAction,
} from "redux/actions/parentCustomers.js";

import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress } from "@mui/material";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  CUSTOMER_STATUS_FILTERS,
  SCROLL_LIMIT,
  SCROLL_LIMIT_CUSTOMERS,
} from "utils/constants.js";
import {
  AccordionComponent,
  DetailsComponent,
  SummaryComponent,
} from "components/AccordionComponent/AccordionComponent.jsx";
import EmptyScreen from "components/EmptyScreen/EmptyScreen.jsx";
import { useNavigate } from "react-router-dom";
import { setConfirmIsOpenAction } from "redux/actions/confirmDialogs";
import {
  ActionRestrictionWrapper,
  InfiniteLoadMoreBtn,
  InfiniteLoaderWrapper,
  ThreeDotButton,
} from "components";
import { getCustomerByGroupIdService } from "services/parentCustomers.js";
import { error } from "utils/notifications.js";
import MissingInfoCounts from "../../MissingInfoCounts/MissingInfoCounts.jsx";
import { TableHeaderControlPanel } from "components/TableHeaderControlPanel/TableHeaderControlPanel.jsx";
import { useAdmin } from "helpers/helpers.js";

const selector = createSelector(
  parentCustomersActiveSelector,
  customersLoadingSelector,
  parentCustomersActiveCountSelector,
  parentCustomersLoadingSelector,
  customersExistSelector,
  customersShowInactiveSelector,
  (
    groupsActive,
    customersLoading,
    groupsActiveCount,
    groupsLoading,
    customersExist,
    showInactive
  ) => ({
    groupsActive,
    customersLoading,
    groupsActiveCount,
    groupsLoading,
    customersExist,
    showInactive,
  })
);

const TabSortedComponent = ({
  handleEditGroup,
  handleOpenConfirmDialog,
  handleOpenCustomerProfile,
  handleRedirectMissingInfo,
  handleCheckCustomer,
  checkedCustomers,
  handleChangeTerritory,
  handleChangeGroup,
  handleChangePaymentTerm,
  handleDeleteCustomers,
  handleArchiveCustomers,
  handleAssignReps,
  groupList,
  handleSetCheckedCustomersHeader,
  handleOpenCustomerNotes,
  onReport,
  reportData,
  handleOrders,
  handleApplyFilter,
  repPermissions,
  handleGetOrderActions,
  additionalActions,
  actionList,
  loadingCustomers,
  setCheckedCustomers,
  expanded,
  setExpanded,
  limitActiveCustomers,
  setActiveLimitCustomers,
  quickSort,
  setQuickSort,
  resetTableCallback,
  tableFilterParams,
  filterFields,
}) => {
  const {
    groupsActive,
    customersLoading,
    groupsActiveCount,
    groupsLoading,
    customersExist,
    showInactive,
  } = useSelector(selector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdmin = useAdmin();

  const [page, setPage] = useState(1);
  // const [sortVisit, setSortVisit] = useState("desc");

  const disabledCreateEdit = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.customers?.create_edit;
    }
  }, [repPermissions]);

  const disabledCreateRoute = useCallback(
    (group) => {
      if (repPermissions) {
        return !repPermissions?.routes?.create_edit;
      }

      return !group.subCustomerCount;
    },
    [repPermissions]
  );

  const disabledCreateReport = useCallback(
    (group) => {
      if (repPermissions) {
        return !repPermissions?.reports?.view;
      }

      return !group.subCustomerCount;
    },
    [repPermissions]
  );

  const [createRouteLoading, setCreateRouteLoading] = useState(false);

  useEffect(() => {
    if (limitActiveCustomers?.length !== groupsActive?.length) {
      setActiveLimitCustomers(
        groupsActive.map((el) => {
          return {
            id: el?.id,
            limit: SCROLL_LIMIT_CUSTOMERS,
            count: el?.subCustomerCount,
            page: 1,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsActive]);

  const handleChange = (panel) => (e, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [openGroupMenu, setOpenGroupMenu] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickLogoClient = useCallback((e, group) => {
    e.stopPropagation();
    setOpenGroupMenu(group);
    setAnchorEl(e.currentTarget);
  }, []);

  const handleCloseLogoClient = (e) => {
    e.stopPropagation();
    setOpenGroupMenu(null);
    setAnchorEl(null);
  };

  const handleFetchGroups = useCallback(
    (refetch) => {
      dispatch(
        getGroupsActiveAction({
          page: refetch ? 1 : page + 1,
          limit: SCROLL_LIMIT,
          limit_sub_customers: SCROLL_LIMIT_CUSTOMERS,
          ...quickSort.general,
        })
      );
      setPage((prev) => (refetch ? 1 : prev + 1));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, quickSort]
  );

  const handleSelectAllCustomersFromTable = useCallback(
    (newList) => {
      if (!newList.length) return;
      const parentId = newList[0].parentCustomer.id;
      const filteredState = checkedCustomers.filter(
        (el) => el.parentCustomer.id !== parentId
      );
      setCheckedCustomers([...filteredState, ...newList]);
    },
    [checkedCustomers, setCheckedCustomers]
  );

  const handleFetchCustomers = useCallback(
    (groupId, fetchAll = false) => {
      const tempArr = [...limitActiveCustomers];
      const indexItem = tempArr.findIndex((el) => el.id === groupId);
      let editItem = tempArr.find((el) => el.id === groupId);

      const { reset, ...tableFilter } = tableFilterParams || {};

      const preparedParams = {
        page: !fetchAll ? editItem.page + 1 : 1,
        ...(!fetchAll && { limit: SCROLL_LIMIT_CUSTOMERS }),
        ...tableFilter,
        ...(quickSort[groupId] || quickSort.general),
      };

      dispatch(
        getCustomerByGroupIdAction(
          groupId,
          preparedParams,
          fetchAll && handleSelectAllCustomersFromTable
        )
      );
      editItem.page += 1;
      tempArr.splice(indexItem, 1, editItem);

      setActiveLimitCustomers(tempArr);
    },
    [
      limitActiveCustomers,
      tableFilterParams,
      quickSort,
      dispatch,
      handleSelectAllCustomersFromTable,
      setActiveLimitCustomers,
    ]
  );

  const handleSetSortVisit = (groupId, sortField) => {
    const tempArr = [...limitActiveCustomers];

    const indexItem = tempArr.findIndex((el) => el.id === groupId);
    let editItem = tempArr.find((el) => el.id === groupId);

    const preparedParams = {
      limit: SCROLL_LIMIT_CUSTOMERS,
      page: 1,
      status: `["${
        showInactive
          ? CUSTOMER_STATUS_FILTERS.inactive
          : CUSTOMER_STATUS_FILTERS.active
      }"]`,
      [sortField]: quickSort[groupId]?.[sortField] !== "desc" ? "desc" : "asc",
    };

    setQuickSort({
      ...quickSort,
      [groupId]: {
        sort_visit: null,
        sort_orders: null,
        sort_last_order: null,
        sort_display_name: null,
        sort_territory_name: null,
        sort_order_direct: null,
        sort_parent_customer_name: null,
        sort_by_orders_count: null,
        [sortField]:
          quickSort[groupId]?.[sortField] !== "desc" ? "desc" : "asc",
      },
    });

    dispatch(getCustomerByGroupIdAction(groupId, preparedParams));
    editItem.limit = preparedParams.limit;
    editItem.page = preparedParams.page;
    tempArr.splice(indexItem, 1, editItem);

    setActiveLimitCustomers(tempArr);
  };

  const handleCreateRouteFromGroup = async (groupId) => {
    if (createRouteLoading) return;
    try {
      setCheckedCustomers([]);
      setCreateRouteLoading(true);
      const { subCustomers } = await getCustomerByGroupIdService(groupId, {});
      navigate("/routes/new", { state: { checkedCustomers: subCustomers } });
      setCreateRouteLoading(false);
    } catch (err) {
      setCreateRouteLoading(false);
      error(err?.response?.data?.message);
    }
  };

  const handleAvailableSelectCount = (id) => {
    return checkedCustomers.filter((el) => el?.parentCustomer?.id === id)
      .length;
  };

  const calcWidthForBox = (list, key1, key2) => {
    if (!list?.length) return "fil-content";

    const theLargestCountOfOrders = list?.reduce((acc, el) => {
      const count = el[key1] || el[key2] || 0;
      return acc > count ? acc : count;
    }, 0);

    if (theLargestCountOfOrders) {
      const lengthOfCount = theLargestCountOfOrders.toString().length;

      if (lengthOfCount > 1 && lengthOfCount < 10) {
        return `${lengthOfCount}0px`;
      }
    }

    return "fil-content";
  };

  const calcWidthForTotalOrderBox = calcWidthForBox(
    groupsActive,
    "orderCount._count",
    "orderCount"
  );

  const calcWidthForTotalCustomerBox = calcWidthForBox(
    groupsActive,
    "subCustomerCount"
  );

  return groupsActiveCount ? (
    <Box
      sx={{
        height: "calc(100vh - 283px)",
        overflowY: "scroll",
        position: "relative",
        mt: "12px",
        "&::-webkit-scrollbar": {
          width: "3px",
          display: "none",
        },
      }}
      id="sorted-scroll-table"
      className="infinite-scroll-custom-scrollbar"
    >
      <InfiniteScroll
        dataLength={groupsActive.length}
        next={handleFetchGroups}
        loader={<CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />}
        hasMore={groupsActive.length < groupsActiveCount}
        scrollableTarget="sorted-scroll-table"
      >
        {groupsActive.map((group) => {
          const currentCheckedCount = handleAvailableSelectCount(group.id);

          const {
            duplicatedInfoCount,
            missingInfoCount,
            expiredDocumentInfoCount,
          } = group;
          return (
            <AccordionComponent
              sx={{
                "&.MuiPaper-root.MuiAccordion-root.MuiPaper-root.MuiAccordion-root":
                  { "&:last-of-type": { mb: 0 } },
              }}
              key={group.id}
              expanded={expanded === group.id}
              onChange={handleChange(group.id)}
            >
              <SummaryComponent sx={{ pr: 0 }}>
                <Grid sx={{ justifyContent: "space-between" }} container>
                  <Grid alignItems="center" xs container item>
                    <Grid xs="auto" item container>
                      <ShevronIcon className="my-ico" />
                    </Grid>

                    <Grid xs="auto" item>
                      <Typography
                        sx={{
                          fontSize: "22px",
                          fontWeight: 600,
                          color: "#5F6267",
                          ml: "32px",
                          mr: "24px",
                        }}
                        noWrap
                      >
                        {group.displayName || group.name}
                      </Typography>
                    </Grid>

                    <Grid xs item>
                      <MissingInfoCounts
                        filterFields={filterFields.issues}
                        countData={{
                          duplicatedInfoCount,
                          missingInfoCount,
                          expiredDocumentInfoCount,
                        }}
                        handleApplyFilter={handleApplyFilter}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={4.2}
                    item
                    container
                  >
                    <Grid xs="auto" item container alignItems="center">
                      <MarkerIcon fill="#D5D9D9" />
                      <Typography
                        sx={{
                          fontSize: "11px",
                          fontWeight: 400,
                          color: "#6A6A6A",
                          ml: "8px",
                          lineHeight: 1.1,
                        }}
                      >
                        TOTAL CUSTOMERS
                      </Typography>

                      <Grid xs item container>
                        <Box
                          sx={{
                            width: calcWidthForTotalCustomerBox,
                            ml: "8px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 500,
                              color: "#1C1C19",
                              lineHeight: 1.1,
                            }}
                          >
                            {group?.subCustomerCount || 0}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid height="100%" xs="auto" item container>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "32px",
                        }}
                      >
                        <Box
                          sx={{
                            height: "30%",
                            borderLeft: "1px solid #B5B5AC",
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid xs="auto" item container alignItems="center">
                      <BoxIcon fill={"#D5D9D9"} width={14} />
                      <Typography
                        sx={{
                          fontSize: "11px",
                          fontWeight: 400,
                          color: "#6A6A6A",
                          ml: "8px",
                          lineHeight: 1.1,
                        }}
                      >
                        TOTAL ORDERS
                      </Typography>

                      <Box
                        sx={{
                          width: calcWidthForTotalOrderBox,
                          ml: "8px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            color: "#1C1C19",
                            lineHeight: 1.1,
                          }}
                        >
                          {group?.orderCount?._count ||
                            group?.orderCount ||
                            "0"}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid justifyContent="center" xs="auto" item container>
                      <Box>
                        <ActionRestrictionWrapper
                          disableHoverListener={!disabledCreateEdit}
                        >
                          <ThreeDotButton
                            paddingRight="14px"
                            disabled={disabledCreateEdit}
                            onClick={(e) => handleClickLogoClient(e, group.id)}
                          />
                        </ActionRestrictionWrapper>
                        <StyledMenu
                          anchorEl={anchorEl}
                          handleClose={handleCloseLogoClient}
                          isOpen={openGroupMenu === group.id}
                        >
                          {group.name !== "Uncategorized" && (
                            <MenuItem
                              onClick={(e) => {
                                handleEditGroup(e, group);
                                setOpenGroupMenu(null);
                                setAnchorEl(null);
                              }}
                            >
                              <PenIcon />
                              <ListItemText>Edit group</ListItemText>
                            </MenuItem>
                          )}
                          <MenuItem
                            onClick={() => handleCreateRouteFromGroup(group.id)}
                            disabled={disabledCreateRoute(group)}
                            sx={{
                              "& svg": {
                                mr: "0!important",
                              },
                            }}
                          >
                            {createRouteLoading ? (
                              <CircularProgress size={15} />
                            ) : (
                              <RouteIcon />
                            )}
                            <ListItemText sx={{ ml: "10px" }}>
                              Create Route
                            </ListItemText>
                          </MenuItem>
                          <MenuItem
                            disabled={disabledCreateReport(group)}
                            onClick={(e) => {
                              handleCloseLogoClient(e);
                              onReport({ ...reportData, group }, true);
                            }}
                          >
                            <ReportIcon />
                            <ListItemText>Report</ListItemText>
                          </MenuItem>
                          {group.name !== "Uncategorized" && (
                            <MenuItem
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenGroupMenu(null);
                                setAnchorEl(null);
                                handleOpenConfirmDialog({
                                  title: "Delete customer group?",
                                  text: (
                                    <>
                                      <Typography
                                        sx={{
                                          display: "flex",
                                          textAlign: "center",
                                          alignItems: "center",
                                          fontSize: "13px",
                                          fontWeight: 400,
                                          color: "#363531",
                                        }}
                                      >
                                        Are you sure you want to delete
                                        <Typography
                                          sx={{
                                            m: "4px 4px",
                                            fontSize: "13px",
                                            fontWeight: 400,
                                            textAlign: "center",
                                            alignItems: "center",
                                          }}
                                          color="primary"
                                        >
                                          {group?.name}
                                        </Typography>
                                        group?
                                      </Typography>
                                      <Typography
                                        sx={{
                                          mt: 1,
                                          mb: 1,
                                          display: "flex",
                                          textAlign: "center",
                                          alignItems: "center",
                                          fontSize: "13px",
                                          fontWeight: 400,
                                          color: "#363531",
                                        }}
                                      >
                                        All customers will be reassigned to
                                        <Typography
                                          sx={{
                                            m: "4px 4px",
                                            fontSize: "13px",
                                            fontWeight: 400,
                                            textAlign: "center",
                                            alignItems: "center",
                                          }}
                                          color="primary"
                                        >
                                          Uncategorized
                                        </Typography>
                                        group.
                                      </Typography>
                                    </>
                                  ),
                                  dialogStyles: {
                                    width: "423px",
                                    height: "87px",
                                  },
                                  dialogButtonsStyles: {
                                    paddingTop: 0,
                                  },
                                  buttons: (
                                    <>
                                      <Button
                                        sx={{
                                          width: "85px",
                                          height: "28px",
                                          fontSize: "13px",
                                          color: "#6A6A6A",
                                          borderColor: "#D4D4D4",
                                        }}
                                        onClick={() => {
                                          dispatch(
                                            setConfirmIsOpenAction(false)
                                          );
                                        }}
                                        variant="outlined"
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        sx={{
                                          width: "85px",
                                          height: "28px",
                                          fontSize: "13px",
                                          color: "#FFFFFF",
                                          boxShadow: "none",
                                        }}
                                        color="confirmDelete"
                                        onClick={() => {
                                          dispatch(
                                            setConfirmIsOpenAction(false)
                                          );
                                          handleDeleteCustomers(
                                            [group.id],
                                            false,
                                            () => handleFetchGroups(true)
                                          );
                                        }}
                                        variant="contained"
                                      >
                                        Confirm
                                      </Button>
                                    </>
                                  ),
                                });
                              }}
                            >
                              <TrashIcon
                                fill="#FF6254"
                                height="10.5"
                                width="8.58"
                              />
                              <ListItemText
                                sx={{
                                  "& > .MuiTypography-root": {
                                    color: "#FF6254",
                                  },
                                }}
                                classes={{ primary: "deleteItem" }}
                              >
                                Delete
                              </ListItemText>
                            </MenuItem>
                          )}
                        </StyledMenu>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </SummaryComponent>
              {group.subCustomerCount ? (
                <>
                  <TableHeaderControlPanel
                    actionWrapperProps={{
                      sx: { padding: "0 19px 0 14.5px !important" },
                    }}
                    checkedCount={currentCheckedCount}
                    counterNumber={checkedCustomers.length}
                    actionsList={additionalActions}
                    loading={loadingCustomers || groupsLoading}
                    dropDownActions={actionList}
                    onSelectAll={() => {
                      const { id } = group;
                      if (expanded === id) handleFetchCustomers(id, true);
                    }}
                    hasCheckedItems={!!checkedCustomers?.length}
                    availableSelectCount={group.subCustomerCount}
                    selectName="customer"
                    onSelectVisible={handleSetCheckedCustomersHeader}
                    cancelSelection={() => setCheckedCustomers([])}
                    headerComponent={
                      <TabHeaderComponent
                        handleSetCheckedCustomersHeader={
                          handleSetCheckedCustomersHeader
                        }
                        checkedCustomers={checkedCustomers}
                        group={group}
                        currentTab="SORTED"
                        quickSort={quickSort[group.id] || quickSort.general}
                        handleSetSortVisit={(sortField) =>
                          handleSetSortVisit(group?.id, sortField)
                        }
                        isAdmin={isAdmin}
                      />
                    }
                  />

                  <InfiniteLoaderWrapper
                    dynamicId={`sorted-content-${group.id}`}
                    itemsList={expanded === group.id ? group?.subCustomers : []}
                    itemsCount={group?.subCustomerCount}
                    listProps={{
                      height:
                        group?.subCustomers?.length < 8
                          ? group?.subCustomers?.length * 51
                          : 408,
                    }}
                    resetCallback={resetTableCallback}
                    contentParams={tableFilterParams}
                    offsetWidth={64}
                    loading={groupsLoading}
                    handleFetch={() => {
                      const { id } = group;
                      if (expanded === id) handleFetchCustomers(id);
                    }}
                    itemHeight={51}
                    tableStyle={{ border: "none" }}
                    overscanRowCount={15}
                    threshold={1}
                    renderChildren={(index) => (
                      <TabListComponent
                        customer={group?.subCustomers[index]}
                        checkedCustomers={checkedCustomers}
                        handleCheckCustomer={handleCheckCustomer}
                        handleOpenConfirmDialog={handleOpenConfirmDialog}
                        handleOpenCustomerProfile={handleOpenCustomerProfile}
                        handleRedirectMissingInfo={handleRedirectMissingInfo}
                        handleChangeTerritory={handleChangeTerritory}
                        handleChangeGroup={handleChangeGroup}
                        handleChangePaymentTerm={handleChangePaymentTerm}
                        handleDeleteCustomers={handleDeleteCustomers}
                        handleArchiveCustomers={handleArchiveCustomers}
                        handleAssignReps={handleAssignReps}
                        groupList={groupList}
                        handleOpenCustomerNotes={handleOpenCustomerNotes}
                        currentTab="SORTED"
                        onReport={onReport}
                        reportData={reportData}
                        lastItem={index === group?.subCustomers?.length - 1}
                        {...{
                          handleOrders,
                          repPermissions,
                          handleGetOrderActions,
                          isAdmin,
                        }}
                      />
                    )}
                  />
                </>
              ) : (
                <DetailsComponent>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="70px"
                    borderTop="1px solid #d5d9d9"
                  >
                    <Typography
                      textAlign="center"
                      color="#6A6A6A"
                      fontWeight="300"
                      fontSize="16px"
                    >
                      No customers found
                    </Typography>
                  </Box>
                </DetailsComponent>
              )}
            </AccordionComponent>
          );
        })}
        {!groupsLoading && groupsActive.length < groupsActiveCount && (
          <InfiniteLoadMoreBtn onClick={handleFetchGroups} />
        )}
      </InfiniteScroll>
    </Box>
  ) : (
    <EmptyScreen
      type="customers"
      height="calc(100vh - 490px)"
      onConfirm={() => navigate("/customers/new")}
      showAction={!customersExist}
      loading={customersLoading}
      disabled={!!repPermissions && !repPermissions?.customers?.create_edit}
      tooltipTitle={ADMIN_ONLY_VIEW_MESSAGE}
    />
  );
};

TabSortedComponent.propTypes = {
  handleEditGroup: func,
  handleOpenConfirmDialog: func,
  handleOpenCustomerProfile: func,
  handleRedirectMissingInfo: func,
  handleCheckCustomer: func,
  checkedCustomers: array,
  handleChangeTerritory: func,
  handleChangeGroup: func,
  handleChangePaymentTerm: func,
  handleDeleteCustomers: func,
  handleArchiveCustomers: func,
  handleAssignReps: func,
  groupList: array,
  handleSetCheckedCustomersHeader: func,
  handleSetCheckAllCustomersWithGroup: func,
  loadingCustomers: bool,
  handleOpenCustomerNotes: func,
  onReport: func,
  reportData: object,
  handleOrders: func,
  handleApplyFilter: func,
  handleGetOrderActions: func,
  handleSetCheckAllCustomers: func,
  setCheckedCustomers: func,
  repPermissions: object,
  additionalActions: array,
  actionList: array,
  expanded: string,
  setExpanded: func,
  limitActiveCustomers: array,
  setActiveLimitCustomers: func,
  quickSort: object,
  setQuickSort: func,
  tableFilterParams: object,
  filterFields: object,
  resetTableCallback: func,
};

TabSortedComponent.defaultProps = {
  handleEditGroup: () => {},
  handleOpenConfirmDialog: () => {},
  handleOpenCustomerProfile: () => {},
  handleRedirectMissingInfo: () => {},
  handleCheckCustomer: () => {},
  checkedCustomers: [],
  additionalActions: [],
  actionList: [],
  handleChangeTerritory: () => {},
  handleChangeGroup: () => {},
  handleChangePaymentTerm: () => {},
  handleDeleteCustomers: () => {},
  handleArchiveCustomers: () => {},
  handleAssignReps: () => {},
  groupList: [],
  handleSetCheckedCustomersHeader: () => {},
  handleSetCheckAllCustomersWithGroup: () => {},
  loadingCustomers: false,
  handleOrders: () => {},
  handleApplyFilter: () => {},
  handleGetOrderActions: () => {},
  handleSetCheckAllCustomers: () => {},
  setCheckedCustomers: () => {},
  filterFields: {},
};

export default TabSortedComponent;
