import { func, object, number } from "prop-types";
import { CloneProps, StyledTextField, StyledTooltip } from "components";
import { Controller } from "react-hook-form";
import { Box } from "@mui/material";

export const Input = ({
  handleUpdateOption,
  item,
  control,
  orderIndex,
  trigger,
}) => {
  return (
    <Controller
      render={({ field, fieldState: { error } }) => {
        return (
          <CloneProps>
            {(clonedProps) => (
              <StyledTooltip
                arrow
                placement="left"
                isError
                title={
                  error?.message ? error?.message : "This field can't be empty"
                }
                open={!!error}
                PopperProps={{
                  disablePortal: true,
                  modifiers: [
                    {
                      name: "offset",
                      options: { offset: [0, -5] },
                    },
                  ],
                }}
              >
                <Box component="span" width="100%">
                  <StyledTextField
                    {...clonedProps}
                    fullWidth={true}
                    placeholder="Enter the answer option"
                    InputProps={{
                      sx: {
                        opacity: item?.title ? 1 : 0.5,
                        fontSize: "13px",
                        "& > fieldset": {
                          borderColor: "#ffff",
                          borderWidth: "0px !important",
                        },
                        "& input": { pl: "4px" },
                        ...(!!error && {
                          height: "26px",
                          border: "0.5px solid #F2545C !important",
                        }),
                        "&.Mui-focused": {
                          borderWidth: "0px !important",
                        },
                      },
                    }}
                    autoFocus={true}
                    error={!!error}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      trigger(
                        `questions[${orderIndex}].options[${item?.orderIndex}].title`
                      );
                    }}
                    onBlur={handleUpdateOption}
                  />
                </Box>
              </StyledTooltip>
            )}
          </CloneProps>
        );
      }}
      name={`questions[${orderIndex}].options[${item?.orderIndex}].title`}
      control={control}
    />
  );
};

Input.propTypes = {
  handleUpdateOption: func.isRequired,
  item: object.isRequired,
  control: object.isRequired,
  orderIndex: number.isRequired,
  trigger: func.isRequired,
};
Input.defaultProps = {
  handleUpdateOption: () => {},
  item: {},
  control: {},
  orderIndex: 0,
  trigger: () => {},
};
