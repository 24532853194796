import { useMemo } from "react";
import { object, func, bool } from "prop-types";
import { Box, Typography } from "@mui/material";
import { PersonIcon, StoreIcon } from "components/Icons";
import { CheckinStatus, StyledAvatar } from "components";
import qbLogo from "assets/logos/quickbookLogo.png";

const cl = {
  circle: {
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    border: "0.2px solid #26874B",
    width: "22.6px",
    maxWidth: "22.6px",
    height: "22.6px",
    maxHeight: "22.6px",
    backgroundColor: "#E7F5EB",
  },
};

export const CheckInStatusBlock = ({
  item,
  onClickCheckin,
  showCheckInStatus,
  styleWrapper = {},
  preparedAvatarData,
}) => {
  const setCheckInStatus = useMemo(() => {
    const { type, createdBy, createdByBackOfficeRepresentative } = item || {};

    if (createdByBackOfficeRepresentative) {
      return (
        <Box
          sx={{
            ...cl.circle,
            backgroundColor: "#D4D4D450",
            border: "0.2px solid #707070",
          }}
          onMouseEnter={
            type === "ORDER_ADDED" ? preparedAvatarData?.onMouseEnter : null
          }
          onMouseLeave={
            type === "ORDER_ADDED" ? preparedAvatarData?.onMouseLeave : null
          }
        >
          <PersonIcon color="#5F6267" />
        </Box>
      );
    }

    if (createdBy === "QUICKBOOKS") {
      return (
        <StyledAvatar
          sx={{ width: 22.59, height: 22.59 }}
          name="qb logo"
          src={qbLogo}
        />
      );
    }

    if (createdBy === "DISTRIBUTOR") {
      return (
        <Box
          sx={{
            ...cl.circle,
            backgroundColor: "#D4D4D450",
            border: "0.2px solid #707070",
          }}
          onMouseEnter={
            type === "ORDER_ADDED" ? preparedAvatarData?.onMouseEnter : null
          }
          onMouseLeave={
            type === "ORDER_ADDED" ? preparedAvatarData?.onMouseLeave : null
          }
        >
          <PersonIcon color="#5F6267" />
        </Box>
      );
    }
    if (type === "COMMENT_ADDED" && createdBy === "DISTRIBUTOR") {
      return (
        <Box sx={cl.circle}>
          <PersonIcon />
          <Typography
            fontSize={8}
            fontWeight={500}
            color="#1C1C19"
            position="absolute"
            bottom="-14px"
          >
            Admin
          </Typography>
        </Box>
      );
    }
    if (item?.appCustomer?.id) {
      return (
        <Box
          sx={cl.circle}
          onMouseEnter={
            type === "ORDER_ADDED" ? preparedAvatarData?.onMouseEnter : null
          }
          onMouseLeave={
            type === "ORDER_ADDED" ? preparedAvatarData?.onMouseLeave : null
          }
        >
          <StoreIcon />
        </Box>
      );
    } else {
      return (
        <CheckinStatus
          isCheckin={item?.checkIn}
          onClick={() => {
            if (item?.checkIn) onClickCheckin(item);
          }}
          showCheckInStatus={showCheckInStatus}
        />
      );
    }
  }, [item, onClickCheckin, showCheckInStatus, preparedAvatarData]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      sx={styleWrapper}
    >
      {setCheckInStatus}
    </Box>
  );
};

CheckInStatusBlock.propTypes = {
  item: object,
  onClickCheckin: func,
  showCheckInStatus: bool,
  styleWrapper: object,
  preparedAvatarData: object,
};
