export const cl = {
  tabsPropsSx: {
    py: 0,
    height: "36px",
    fontSize: "20px !important",
    fontWeight: 400,
    color: "#9C9C94",
    px: "0 !important",
  },
  tabs: {
    "& .MuiTabs-flexContainer": {
      gap: "32px",
    },
    "& .MuiTabs-indicator": {
      height: "3px",
      borderRadius: "6px !important",
    },
    minHeight: "auto",
    height: "38px",
    "& .Mui-selected": {
      backgroundColor: "transparent!important",
    },
  },
};
