import { Stack } from "@mui/material";
import { DashedUploadInput } from "components";
import { bool, string } from "prop-types";
export const PhotosContentBlock = () => {
  return (
    <Stack mt="8px" direction="row" alignItems="center" gap="10px">
      <DashedUploadInput
        disabled={true}
        wrapProps={{ width: "100%" }}
        label="The responder can upload up to 5 photos."
        sx={{
          width: "100% !important",
          minWidth: "100% !important",
          borderStyle: "dashed !important",
          bgcolor: "#F8F8F8",
        }}
        {...{ inputRef: null, onAttach: () => {} }}
      />
      {/*{!!attachments.length && (
        <Stack
          direction="row"
          alignItems="center"
          gap="10px"
        >
          {attachments?.map((attachment) => (
            <FileItem
              key={attachment.name}
              {...{ attachment, handleRemoveFile }}
            />
          ))}
        </Stack>
      )}*/}

      {/* <PhotoFileItem
        {...{
          attachment: {
            url: "https://firebasestorage.googleapis.com/v0/b/simply-depo-staging-media/o/converted%2FmTwW3Tx6hpS0n51NgT2FIdPPOMD2%2F33f18e4f55ee7fe0fb6da9e313e71165be01745b3706ca050583922a938a36ab.png?alt=media&token=141f0020-b21f-4db0-a0a9-0149f57992f1",
            name: "test",
          },
          handleRemoveFile: () => {},
        }}
      /> */}
    </Stack>
  );
};

PhotosContentBlock.propTypes = {
  isActive: bool,
  answer: string,
};
