import { useCallback, useEffect, useState } from "react";
import {
  getPriceListsProductsByCustomerIdService,
  getPriceListsService,
} from "services/priceLists";
import { SCROLL_LIMIT } from "utils/constants";
import { error } from "utils/notifications";
import { getCalcProductPriceByPriceList } from "./helpers";

const DEFAULT_STATE = {
  loading: false,
  list: [],
  count: 0,
};

const DEFAULT_PRICE_LIST_STATE = {
  priceList: {},
  priceListName: "",
  loading: false,
  priceListId: null,
};

const DEFAULT_PARAMS = {
  page: 1,
  limit: SCROLL_LIMIT,
};

export const usePriceLists = ({ open = true, customerId = null }) => {
  const [priceListState, setPriceListState] = useState(DEFAULT_STATE);
  const [priceListByIdState, setPriceListByIdState] = useState(
    DEFAULT_PRICE_LIST_STATE
  );
  const [priceListParams, setPriceListParams] = useState(DEFAULT_PARAMS);

  const setLoading = (loading) =>
    setPriceListState((prev) => ({ ...prev, loading }));

  const onFetchPriceList = useCallback(
    async (page) => {
      if (!page) {
        setLoading(true);
      }
      try {
        const res = await getPriceListsService({
          ...priceListParams,
          page: page ? page : 1,
        });

        const rows = res?.rows || [];
        const count = res?.count || 0;

        setPriceListState((prev) => ({
          ...prev,
          list: page > 1 ? [...prev.list, ...rows] : rows,
          count: count,
        }));

        setPriceListParams((prev) => ({ ...prev, page: page ? page : 1 }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [priceListParams]
  );

  const getPriceInfoById = useCallback(async () => {
    if (!customerId) return;
    setPriceListByIdState((prev) => ({ ...prev, loading: true }));

    try {
      const res = await getPriceListsProductsByCustomerIdService(customerId);

      if (!res.rows.length)
        return setPriceListByIdState((prev) => ({ ...prev, priceList: {} }));

      const result = res?.rows.length
        ? res?.rows?.reduce((acc, item) => {
            acc[item?.product?.id] = { ...item };
            return acc;
          }, {})
        : {};

      setPriceListByIdState((prev) => ({
        ...prev,
        priceList: result,
        priceListId: res.priceList?.id,
        priceListName: res.priceList?.name,
      }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setPriceListByIdState((prev) => ({ ...prev, loading: false }));
    }
  }, [customerId]);

  const calcProductPriceByPriceList = useCallback(
    (product) =>
      getCalcProductPriceByPriceList({
        product,
        priceList: priceListByIdState.priceList,
      }),
    [priceListByIdState.priceList]
  );

  useEffect(() => {
    if (open && !customerId) onFetchPriceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, customerId]);

  useEffect(() => {
    if (open && customerId) getPriceInfoById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, customerId]);

  return {
    priceList: priceListState.list,
    priceCount: priceListState.count,
    listLoading: priceListState.loading,
    onFetchPriceList,
    page: priceListParams.page,
    priceListByIdState,
    calcProductPriceByPriceList,
    priceListLoading: priceListByIdState.loading,
  };
};
