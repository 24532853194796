import { useContext } from "react";

import { FilterSearchTextField } from "components";
import { ProductPriceContext } from "Pages/ProductPricePage";
import useStyles from "./styles";

import Box from "@mui/material/Box";

export const CustomerFilterBlock = () => {
  const classes = useStyles();

  const { setFetchParamsByKey, fetchParams } = useContext(ProductPriceContext);

  return (
    <Box className={classes.filterWrapper}>
      <FilterSearchTextField
        adminIsAllowed
        placeholder={"Search customers by name, address or ID"}
        value={fetchParams.search}
        onChange={(e) => setFetchParamsByKey("search", e.target.value)}
        fullWidth
        handleClearValue={() => setFetchParamsByKey("search", "")}
      />
    </Box>
  );
};
