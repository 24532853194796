import { useContext, useState } from "react";
import { arrayOf, number, object, shape, string } from "prop-types";
import { Box, Stack } from "@mui/material";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";
import { StyledTooltip } from "components";

const COLORS = ["#8CC2A3", "#FF373760"];
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  outerRadius,
  percent,
  name,
  handleClickLabel,
  setIsAnimationActive,
}) => {
  const radius = outerRadius + 5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  setIsAnimationActive(false);

  const value = (percent * 100).toFixed(2);

  return (
    <StyledTooltip
      arrow
      placement="left"
      isDark
      title="No reply submitted"
      disableHoverListener={value > 0}
      disableFocusListener={value > 0}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: { offset: [0, -5] },
          },
        ],
      }}
    >
      <g
        style={{
          cursor: value > 0 ? "pointer" : "default",
        }}
        onClick={(e) => {
          e.preventDefault();
          value > 0 && handleClickLabel(name);
        }}
      >
        <circle cx={x} cy={y} r={30} fill="white" stroke="#DDD" />
        <text
          x={x}
          y={y - 5}
          textAnchor="middle"
          dominantBaseline="central"
          fontSize="14px"
          fontWeight="500"
          fill="#595959"
        >
          {name}
        </text>
        <text
          x={x}
          y={y + 10}
          textAnchor="middle"
          dominantBaseline="central"
          fontSize="12px"
          fontWeight="500"
          fill="#59595980"
        >
          {value}%
        </text>
      </g>
    </StyledTooltip>
  );
};

export const YesNoBlock = ({ question = {}, data = [] }) => {
  const { dialogPreviewContentData } = useContext(FormViewContext);

  const { handleOpenDialogPreviewContent } = dialogPreviewContentData;

  const handleClickLabel = (name) => {
    handleOpenDialogPreviewContent({
      item: {
        ...question,
        filterName: name,
      },
    });
  };

  // it fixed a bug with a label and rerender animation after click on the label
  const [isAnimationActive, setIsAnimationActive] = useState(true);

  return (
    <Box pb="10px">
      <Stack
        sx={{
          flexDirection: "row",
          height: "240px",
          borderRadius: "6px",
          backgroundColor: "#F8F8F8",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ResponsiveContainer>
          <PieChart>
            <Pie
              key={Math.random()} // it fixed a bug with a label
              isAnimationActive={isAnimationActive}
              data={data}
              cx="50%"
              cy="50%"
              outerRadius={80}
              labelLine={false}
              label={(data) =>
                renderCustomizedLabel({
                  ...data,
                  handleClickLabel,
                  setIsAnimationActive,
                })
              }
              startAngle={-120}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Stack>
    </Box>
  );
};

YesNoBlock.propTypes = {
  data: arrayOf(
    shape({
      name: string,
      value: number,
    })
  ),
  question: object,
};
