import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Chip, Stack, Typography } from "@mui/material";
import lodash from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import { FilterSearchTextField } from "../../../../../components/TextFields/TextFields";
import { makeStyles } from "@mui/styles";
import { normalizeSnakeCaseString } from "../../../../../helpers/helpers";
import { defaultFilters, FILTER_SWITCHES } from "./FilterThirdParty.constants";
import StyledButton from "../../../../../components/StyledButton";
import { FilterIcon } from "../../../../../components/Icons";
import FilterMenu from "../../../../../components/FilterMenu";
import { useDispatch, useSelector } from "react-redux";
import {
  CUSTOM_FILTERS_TYPES,
  NAME_VIEW_DIALOGS,
  SCROLL_LIMIT,
  USED_ON_TYPE_ORDER_TYPES,
} from "../../../../../utils/constants";
import { useDebounce } from "../../../../../helpers/hooks";
import { getOrdersParamsThirdPartyAction } from "../../../../../redux/actions/orders";
import { snakeCase } from "lodash";
import { ColumnsSettingComponent } from "Pages/CustomersPage/components/CustomersTab/components/TabsContent/components/ColumnsSettingComponent/ColumnsSettingComponent";
import { string, func } from "prop-types";
import { useTagsActions } from "helpers/useTagsActions";
import { ROLES, TYPES, useRepsActions } from "helpers/useRepsActions";
import { createSelector } from "reselect";
import { existWithNoSalesAssignedSelector } from "redux/selectors/orders";
import { setSelectedFilterIdAction } from "redux/actions/savedFilters";
import {
  checkForDefaultFilter,
  handleSetSwitchesForEdit,
} from "helpers/filters";
import { CustomFilterMenu, FilterViewSaveDialog } from "components";
import { currentUserSelector } from "redux/selectors/auth";
import { useCustomFilterCreate } from "helpers/useCustomFilterCreate";

const useStyles = makeStyles(() => ({
  filterWrapper: {
    border: "1px solid #d5d9d9",
    borderRadius: "4px 4px 0 0",
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    paddingLeft: "19px",
    paddingRight: "14px",
    gap: "12px",
    marginTop: "12px",
    height: "60px",
  },

  tab: {
    textTransform: "none",
    fontSize: "20px",
    color: "#707070",
    "&.Mui-selected": {
      backgroundColor: "rgb(71, 160, 110, 0.2)!important",
      borderRadius: "4px",
    },
    width: "fit-content",
    minWidth: "10px",
    paddingLeft: "15px",
    paddingRight: "15px",
    height: "38px",
    minHeight: "38px",
    padding: 0,
  },

  tabs: { width: "100%", maxWidth: "458px", minWidth: "458px" },

  filterChip: {
    borderRadius: "4px",
    border: "0.7px solid #d5d9d9",
    backgroundColor: "#FAFAFB",
    height: "28px",
  },
}));

const selector = createSelector(
  existWithNoSalesAssignedSelector,
  currentUserSelector,
  (existWithNoSalesAssigned, currentUser) => ({
    existWithNoSalesAssigned,
    currentUser,
  })
);

export const FilterThirdParty = ({ currentTab, setCursor }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { existWithNoSalesAssigned, currentUser } = useSelector(selector);

  const filterAnchor = useRef();
  const [searchInput, setSearchInput] = useState("");
  const searchInputDebounced = useDebounce(searchInput, 500);
  const [switches, setSwitches] = useState(FILTER_SWITCHES);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [filterFields, setFilterFields] = useState(defaultFilters);
  const [isFilterChanged, setIsFilterChanged] = useState(false);

  const {
    list: tagsList,
    count: tagsCount,
    handleFetchTags,
    page: tagsPage,
    setTagsParams,
    searcheble: tagsSearcheble,
  } = useTagsActions({
    type: "order",
    params: {
      used: true,
      used_on_type: JSON.stringify([USED_ON_TYPE_ORDER_TYPES.third_party]),
    },
    open: filterMenuOpen,
  });

  const filterChipKeys = useMemo(
    () =>
      Object.keys(filterFields).filter((key) => {
        if (key === "total") {
          return (
            !!filterFields[key].total_amount_lesser?.value ||
            !!filterFields[key].total_amount_greater?.value
          );
        }
        if (
          lodash.isArray(filterFields[key]) &&
          lodash.isEmpty(filterFields[key])
        )
          return;
        return filterFields[key];
      }),
    [filterFields]
  );

  const handleDeleteFilter = (key) => {
    dispatch(setSelectedFilterIdAction(null));
    const newState = switches;
    const index = switches.findIndex((s) => s.value === key);
    const insert = {
      ...switches[index],
      checked: false,
    };
    newState.splice(index, 1, insert);
    setSwitches([...newState]);
    setFilterFields((prev) => {
      return {
        ...prev,
        [key]: lodash.isArray(filterFields[key]) ? [] : "",
      };
    });
  };

  const handleApplyFilter = (newSwitches, newFields) => {
    dispatch(setSelectedFilterIdAction(null));
    setFilterMenuOpen(false);
    setSwitches([...newSwitches]);
    setFilterFields({ ...newFields });
  };

  const {
    list: salesList,
    countSales: salesCount,
    handleFetchReps,
    page: salesPage,
    setRepsParams,
    searcheble: repsSearcheble,
  } = useRepsActions({
    type: TYPES.duplicates,
    params: {
      role: ROLES.sales,
      with_created_orders: true,
      order_type: JSON.stringify([ROLES.third_party]),
    },
    open: filterMenuOpen,
  });

  const {
    list: thirdPartyRepsList,
    countThirdParty: thirdPartyRepsCount,
    handleFetchReps: handleFetchThirdPartyReps,
    page: thirdPartyRepsPage,
    setRepsParams: setRepsParamsThirdParty,
    searcheble: searchebleThirdParty,
  } = useRepsActions({
    type: TYPES.duplicates,
    params: {
      role: ROLES.third_party,
      with_orders: true,
      order_type: JSON.stringify([ROLES.third_party]),
    },
    open: filterMenuOpen,
  });

  useEffect(() => {
    checkForDefaultFilter({
      type: CUSTOM_FILTERS_TYPES.ORDERS[currentTab],
      list: currentUser.customFilters,
      setFilters: setFilterFields,
      setSwitches,
      switchesList: switches,
      dispatchFunc: (id) => dispatch(setSelectedFilterIdAction(id)),
    });
    setIsFilterChanged(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.customFilters]);

  useEffect(() => {
    if (!isFilterChanged) return;
    const setdate =
      typeof filterFields?.date?.value === "string"
        ? { date: snakeCase(filterFields?.date?.value) }
        : { ...filterFields?.date?.value };

    const totalKeys = Object.keys(filterFields?.total);
    const totalFiltersArray = totalKeys.map((key) => ({
      [key]: filterFields.total[key]?.value,
    }));
    const totalFilters = {};
    totalFiltersArray.forEach((obj) => Object.assign(totalFilters, obj));

    const setSalesDuplicateId = (value) => {
      if (value === "no_assignment") return "null";
      return value ? value : null;
    };

    const { total_amount_greater, total_amount_lesser } = totalFilters;
    const preparedData = {
      sort_by_order_date: "desc",
      type: "THIRD_PARTY",
      search: searchInputDebounced,
      sales_duplicate_id: setSalesDuplicateId(filterFields?.sales_id?.value),
      created_by_id: ["distributor"].includes(filterFields.created_by?.value)
        ? null
        : filterFields.created_by?.value,
      created_by: ["distributor"].includes(filterFields.created_by?.value)
        ? filterFields.created_by?.value
        : null,
      limit: SCROLL_LIMIT,
      archived: filterFields?.archived ? undefined : false,
      tag_ids: filterFields?.tag_id?.length
        ? JSON.stringify(filterFields?.tag_id?.map(({ value }) => value))
        : [],
      ...setdate,
    };
    if (total_amount_greater)
      preparedData.total_amount_greater = total_amount_greater;
    if (total_amount_lesser)
      preparedData.total_amount_lesser = total_amount_lesser;

    dispatch(getOrdersParamsThirdPartyAction(preparedData, setCursor));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterFields, dispatch, searchInputDebounced, isFilterChanged]);

  const getChipLabel = (key) => {
    const fieldKeys = Object.keys(filterFields[key]);
    const isCombined = key === "total";
    const isArray = lodash.isArray(filterFields[key]);

    return (
      <Box display="flex" alignItems="center" gap="6px">
        <Typography
          sx={{ fontSize: "13px", fontWeight: 500 }}
          color="groundLighter.main"
        >
          {isArray ? (
            <>
              {normalizeSnakeCaseString(filterFields[key][0]?.label || key)}:{" "}
              {typeof filterFields[key][0] === "string"
                ? normalizeSnakeCaseString(filterFields[key][0])
                : filterFields[key]
                    .map(({ name }) => normalizeSnakeCaseString(name))
                    .join(", ")}
            </>
          ) : (
            <>
              {normalizeSnakeCaseString(filterFields[key]?.label || key)}:{" "}
              <span style={{ color: "#5F6368" }}>
                {isCombined
                  ? fieldKeys
                      .filter((fieldKey) => filterFields[key][fieldKey]?.value)
                      .map((fieldKey) => {
                        const { prefix, value } = filterFields[key][fieldKey];
                        return `${prefix} ${value}`;
                      })
                      .join(" and ")
                  : normalizeSnakeCaseString(
                      filterFields[key]?.name || filterFields[key]
                    )}
              </span>
            </>
          )}
          {/* {normalizeSnakeCaseString(filterFields[key]?.label || key)}:{" "}
          <span style={{ color: "#5F6368" }}>
            {isCombined
              ? fieldKeys
                  .filter((fieldKey) => filterFields[key][fieldKey]?.value)
                  .map((fieldKey) => {
                    const { prefix, value } = filterFields[key][fieldKey];
                    return `${prefix} ${value}`;
                  })
                  .join(" and ")
              : normalizeSnakeCaseString(
                  filterFields[key]?.name || filterFields[key]
                )}
          </span> */}
        </Typography>
      </Box>
    );
  };

  const preparedMatchData = useMemo(() => {
    const noAssignmentItem = {
      id: "no_assignment",
      name: "No assignment",
    };
    const preparedThirdPartyRepsList = [...thirdPartyRepsList];

    if (existWithNoSalesAssigned) {
      preparedThirdPartyRepsList.unshift(noAssignmentItem);
    }

    return [
      {
        id: "created_by",
        childrenList: [
          { name: "Admin", id: "distributor" },
          //hasQuickBooksConnection && {
          //  name: "Quickbooks",
          //  id: "quickbooks",
          //},
          ...salesList,
        ],
        handleFetch: () => handleFetchReps(salesPage + 1),
        setParams: setRepsParams,
        dataCount: salesCount,
        dataLength: salesList?.length,
        label: "Select Rep",
        searcheble: repsSearcheble,
      },
      {
        id: "date",
        label: "Select Date",
      },
      {
        id: "sales_id",
        // childrenList: thirdPartyRepsList,
        childrenList: preparedThirdPartyRepsList,
        handleFetch: () => handleFetchThirdPartyReps(thirdPartyRepsPage + 1),
        setParams: setRepsParamsThirdParty,
        dataCount: thirdPartyRepsCount,
        dataLength: thirdPartyRepsList.length,
        label: "Assignment",
        searcheble: searchebleThirdParty,
      },
      {
        id: "tag_id",
        childrenList: tagsList.map(({ tag, ...item }) => ({
          ...item,
          name: tag,
        })),
        handleFetch: () => handleFetchTags(tagsPage + 1),
        setParams: setTagsParams,
        dataCount: tagsCount,
        dataLength: tagsList?.length,
        label: "",
        searcheble: tagsSearcheble,
      },
    ];
  }, [
    thirdPartyRepsList,
    existWithNoSalesAssigned,
    salesList,
    salesCount,
    thirdPartyRepsCount,
    tagsList,
    tagsCount,
    handleFetchReps,
    salesPage,
    handleFetchThirdPartyReps,
    thirdPartyRepsPage,
    handleFetchTags,
    tagsPage,
    tagsSearcheble,
    repsSearcheble,
    searchebleThirdParty,
    setRepsParams,
    setRepsParamsThirdParty,
    setTagsParams,
  ]);

  const onCustomFilterApply = useCallback(
    (filters) => {
      setFilterFields(filters);
      setSwitches(handleSetSwitchesForEdit(filters, switches));
    },
    [switches]
  );

  const {
    onCloseFilterDialog,
    onFilterViewSave,
    onOpenFilterSaveDialog,
    isOpenedMenu,
  } = useCustomFilterCreate({
    createFilterCallback: onCustomFilterApply,
    menuCallback: () => setFilterMenuOpen(false),
    viewTypeKey: NAME_VIEW_DIALOGS.ORDERS,
    filterType: CUSTOM_FILTERS_TYPES.ORDERS[currentTab],
  });

  return (
    <Box className={classes.filterWrapper}>
      {isOpenedMenu && (
        <FilterViewSaveDialog
          open={isOpenedMenu}
          onClose={onCloseFilterDialog}
          handleSave={onFilterViewSave}
        />
      )}
      <FilterSearchTextField
        formSx={{ minWidth: "380px" }}
        placeholderWidth="310px"
        placeholder="Search by ID, customer name or address"
        value={searchInput}
        handleClearValue={() => setSearchInput("")}
        onChange={(e) => setSearchInput(e.target.value)}
        fullWidth
        adornmentProps={{
          sx: {
            justifyContent: "flex-end",
          },
        }}
        customAdornment={
          <Box
            display="flex"
            gap="5px"
            overflow="auto"
            sx={{
              py: "4px",
              "&::-webkit-scrollbar": {
                height: "2px",
              },
            }}
          >
            {filterChipKeys.map((key) => (
              <Chip
                key={key}
                className={classes.filterChip}
                size="small"
                label={getChipLabel(key)}
                deleteIcon={<CloseIcon size="15" style={{ fill: "#707070" }} />}
                onMouseDown={(e) => e.stopPropagation()}
                onDelete={() => handleDeleteFilter(key)}
              />
            ))}
          </Box>
        }
      />
      <CustomFilterMenu
        {...{
          selectMatchData: preparedMatchData,
          type: CUSTOM_FILTERS_TYPES.ORDERS[currentTab],
          switches: switches,
          onFilterApply: onCustomFilterApply,
          resetFilterFunc: () => {
            //dispatch(resetOrdersActionsFilter());
            setFilterFields(defaultFilters);
            setSwitches(FILTER_SWITCHES);
          },
        }}
      />

      <Stack direction="row" gap="12px" alignItems="center">
        <StyledButton
          label="Filter"
          startIcon={
            filterChipKeys.length ? (
              <Typography
                fontSize="9px!important"
                color="#ffffff"
                backgroundColor="#47A06D"
                borderRadius="50%"
                width="15px"
                height="15px"
              >
                {filterChipKeys.length}
              </Typography>
            ) : (
              <FilterIcon />
            )
          }
          variant="outlined"
          ref={filterAnchor}
          color="edit"
          sx={{
            mr: "2px",
            height: "39px",
            width: "100%",
            maxWidth: "74px",
            border: "0.5px solid #D5D9D9",
            "& .MuiButton-startIcon": {
              ml: 0,
            },
          }}
          fontSize="15px"
          onClick={() => setFilterMenuOpen(true)}
          data-testid="filter-button"
        />
        <FilterMenu
          anchorEl={filterAnchor.current}
          open={filterMenuOpen}
          onClose={() => setFilterMenuOpen(false)}
          filterFields={filterFields}
          setFilterFields={setFilterFields}
          switches={switches}
          handleApplyFilter={handleApplyFilter}
          selectMatchData={preparedMatchData}
          onViewSave={onOpenFilterSaveDialog}
        />
        <Box>
          <ColumnsSettingComponent currentTab={currentTab} />
        </Box>
      </Stack>
    </Box>
  );
};
FilterThirdParty.propTypes = {
  currentTab: string,
  setCursor: func,
};
