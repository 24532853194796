import { Typography } from "@mui/material";
import { useAdmin } from "helpers/helpers";
import { useRepsPermissions } from "helpers/hooks";
import { GlobalPageContext } from "Pages/MasterPage/MasterPage";
import pluralize from "pluralize";
import { useCallback, useContext, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import { deleteProductsPriceListService } from "services/priceLists";
import { ADMIN_ONLY_VIEW_MESSAGE, SCROLL_LIMIT } from "utils/constants";
import { error, success } from "utils/notifications";

export const useProductActions = ({
  selectedProducts,
  productsList,
  onProductStateUpdate,
  setSelectedProducts,
  setTempCheckedProducts,
  productsCount,
  handleFetchProducts,
}) => {
  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();

  const { setGlobalLoading } = useContext(GlobalPageContext);
  const dispatch = useDispatch();
  const [isPriceDrawerOpen, setIsPriceDrawerOpen] = useState(false);

  const closePriceDialog = () => setIsPriceDrawerOpen(false);

  const onProductsDelete = useCallback(
    async (listForDelete, successCallback) => {
      try {
        setGlobalLoading(true);

        await deleteProductsPriceListService({
          productsIds: listForDelete,
        });

        successCallback();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        setGlobalLoading(false);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setGlobalLoading(false);
      }
    },
    [setGlobalLoading]
  );

  const onProductConfirmDelete = useCallback(
    async (id) => {
      const filteredList = productsList?.filter(
        (customer) => customer?.id !== id
      );

      const filteredCheckedList = selectedProducts?.filter(
        (customer) => customer?.id !== id
      );

      if (productsCount - 1 <= SCROLL_LIMIT)
        return onProductsDelete([id], () => {
          success("Product deleted");
          onProductStateUpdate({
            list: filteredList,
            count: productsCount - 1,
          });
          setTempCheckedProducts(filteredList);
          setSelectedProducts(filteredCheckedList);
        });

      return onProductsDelete([id], () => {
        success("Product deleted");
        setTempCheckedProducts(filteredList);
        setSelectedProducts(filteredCheckedList);
        handleFetchProducts();
      });
    },
    [
      productsList,
      selectedProducts,
      onProductsDelete,
      onProductStateUpdate,
      productsCount,
      setTempCheckedProducts,
      setSelectedProducts,
      handleFetchProducts,
    ]
  );

  const onBulkSelectionDelete = useCallback(() => {
    if (selectedProducts.length === 1)
      return onProductConfirmDelete(selectedProducts[0].id);

    const ids = selectedProducts.map(({ id }) => id);

    const filteredList = productsList?.filter(({ id }) => !ids.includes(id));

    if (productsCount - ids.length <= SCROLL_LIMIT)
      return onProductsDelete(ids, () => {
        success(`${pluralize("Product", selectedProducts.length)} deleted`);
        onProductStateUpdate({
          list: filteredList,
          count: productsCount - ids.length,
        });
        setTempCheckedProducts(filteredList);
        setSelectedProducts([]);
      });

    return onProductsDelete(ids, () => {
      success(`${pluralize("Product", selectedProducts.length)} deleted`);
      setTempCheckedProducts(filteredList);
      setSelectedProducts([]);
      handleFetchProducts();
    });
  }, [
    onProductConfirmDelete,
    onProductStateUpdate,
    onProductsDelete,
    productsCount,
    productsList,
    selectedProducts,
    setSelectedProducts,
    setTempCheckedProducts,
    handleFetchProducts,
  ]);

  const onRemoveProduct = (product) => {
    const title = product
      ? "Remove Product?"
      : `Remove ${pluralize("Product", selectedProducts.length)}?`;

    dispatch(
      openConfirmDialogAction({
        title,
        text: (
          <>
            {product ? (
              <Typography
                fontWeight="400"
                fontSize="15px"
                whiteSpace="pre-line"
              >
                Are you sure you want to remove{" "}
                <span style={{ fontWeight: "500" }}>
                  &#39;
                  {product?.product?.name ||
                    product?.product?.parentProduct?.name}
                  &#39;
                </span>{" "}
                from the list?
              </Typography>
            ) : (
              <Typography
                fontWeight="400"
                fontSize="15px"
                whiteSpace="pre-line"
              >
                Are you sure you want to remove selected{" "}
                {pluralize("product", selectedProducts.length)}?
              </Typography>
            )}
          </>
        ),
        isIcon: true,
        propBtns: {
          left: {
            label: "Cancel",
            color: "cancel",
            variant: "outlined",
            sx: {
              width: "78px",
              color: "#6A6A6A",
              borderColor: "#D4D4D4",
              fontSize: "13px",
              height: "28px",
            },
          },
          right: {
            sx: {
              width: "120px",
              color: "#FFFFFF",
              fontSize: "13px",
              padding: "7px 10px",
              height: "28px",
              boxShadow: "none",
              backgroundColor: "#EB4233",
            },
            label: `Remove ${
              product
                ? "Product"
                : pluralize("Product", selectedProducts.length)
            }`,
            color: "error",
            variant: "contained",
            onClick: () => {
              dispatch(setConfirmIsOpenAction(false));
              if (product) return onProductConfirmDelete(product.id);
              return onBulkSelectionDelete();
            },
          },
        },
      })
    );
  };

  const disabledCreatePriceListEdit = useMemo(() => {
    return repPermissions
      ? !repPermissions?.catalog?.create_edit_price_lists
      : false;
  }, [repPermissions]);

  const handleGetActions = () => {
    if (!selectedProducts.length) return [];

    return [
      {
        label: "Set Discounts",
        element: null,
        disabled: disabledCreatePriceListEdit || isAdmin,
        onClick: () => setIsPriceDrawerOpen(true),
        show: true,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: disabledCreatePriceListEdit,
          props: { isDark: true },
        },
      },
      {
        label: "Delete",
        element: null,
        disabled: disabledCreatePriceListEdit || isAdmin,
        onClick: () => onRemoveProduct(),
        show: true,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: disabledCreatePriceListEdit,
          props: { isDark: true },
        },
      },
    ];
  };

  const PRODUCT_ACTIONS_LIST = handleGetActions();

  return {
    PRODUCT_ACTIONS_LIST,
    closePriceDialog,
    isPriceDrawerOpen,
    onRemoveProduct,
  };
};
