import { useCallback, useState } from "react";

const initState = {
  open: false,
  response: null,
  question: null,
};

export const useDialogPreviewPhotos = () => {
  const [dialogPreviewPhotosState, setDialogPreviewPhotosState] =
    useState(initState);

  const handleOpenDialogPreviewPhotos = useCallback(
    ({ response, position }) => {
      if (!response)
        // eslint-disable-next-line no-console
        return console.error("response, question are required");

      setDialogPreviewPhotosState((prev) => ({
        ...prev,
        open: true,
        response,
        position,
      }));
    },
    []
  );

  const handleCloseDialogPreviewPhotos = useCallback(() => {
    setDialogPreviewPhotosState(initState);
  }, []);

  return {
    dialogPreviewPhotosState,
    handleOpenDialogPreviewPhotos,
    handleCloseDialogPreviewPhotos,
  };
};
