import { useAdmin } from "helpers/helpers";
import {
  useOnBoardingTasks,
  useOrderDirectConfigured,
  useRepsPermissions,
} from "helpers/hooks";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectedChildrensOfProductsAction,
  selectedProductsAction,
} from "redux/actions/products";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  ADMIN_ONLY_VIEW_MESSAGE_PAGE,
} from "utils/constants";

const setNavSettingsState = (trueValues) => {
  let state;
  // orders of the if statements are important
  if (trueValues?.profile) return state;
  if (trueValues?.payments) return (state = { tab: "Payments", profileTab: 1 });
  if (trueValues?.representatives) return (state = { tab: "Representatives" });
  if (trueValues?.orders) return (state = { tab: "Orders" });
  if (trueValues?.inventory) return (state = { tab: "Inventory" });
  if (trueValues?.payment_terms) return (state = { tab: "Payment Terms" });
  if (trueValues?.import_export) return (state = { tab: "Import/Export" });
  if (trueValues?.customers) return (state = { tab: "Customers" });

  return state;
};

const setNavCustomersState = (trueValues) => {
  let state;
  // orders of the if statements are important
  if (trueValues?.view) return state;
  if (trueValues?.prospects) return (state = { tab: "Prospects" });
  if (trueValues?.gallery) return (state = { tab: "Gallery" });
  if (trueValues?.customer_tags) return (state = { tab: "Customer Tags" });
  if (trueValues?.territories) return (state = { tab: "Territories" });

  return state;
};

const setNavOrdersState = (trueValues) => {
  let state;
  // orders of the if statements are important
  if (trueValues?.view) return state;
  if (trueValues?.third_party) return (state = "3rd Party");
  if (trueValues?.drafts) return (state = "Drafts");

  return state;
};

const setNavCatalogState = (trueValues) => {
  let state;
  // orders of the if statements are important
  if (trueValues?.view) return state;
  if (trueValues?.manufacturers) return (state = "Manufacturers");
  if (trueValues?.product_tags) return (state = "Product Tags");

  return state;
};

export const useNavbarComponentActions = ({
  handleCloseLogoClient,
  editType,
  handleConfirmCheckedItemsDialog,
  handleConfirmChangesDialog,
  setActiveTab,
  trialEnded,
  subscriptionIsCanceled,
  notificationTooltipData,
}) => {
  const formChanged = useSelector(
    ({ confirmDialogs }) => confirmDialogs.formChanged
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();

  const { onBoardingTasks } = useOnBoardingTasks();
  const orderDirectConfigured = useOrderDirectConfigured();

  const disabledSettingsMenuItem = useMemo(() => {
    if (repPermissions) {
      const hasTrue =
        !!repPermissions?.settings &&
        Object.values(repPermissions?.settings).some(Boolean);

      return !hasTrue;
    }

    return trialEnded || subscriptionIsCanceled;
  }, [repPermissions, subscriptionIsCanceled, trialEnded]);

  const onClickSettingsMenuItem = (e) => {
    if (e.metaKey || e.ctrlKey) return;
    e.preventDefault();

    const hasPermission =
      !!repPermissions?.settings &&
      Object.values(repPermissions?.settings)?.some(Boolean);

    let state;

    if (hasPermission) {
      const trueValues = Object.fromEntries(
        Object.entries(repPermissions?.settings).filter(
          ([, value]) => value === true
        )
      );

      state = setNavSettingsState(trueValues);
    }

    if (formChanged) {
      handleCloseLogoClient();
      if (editType === "checked_items")
        return handleConfirmCheckedItemsDialog("/settings", { state });
      if (editType !== "checked_items")
        return handleConfirmChangesDialog("/settings", { state });
    } else {
      setActiveTab("settings");
      navigate("/settings", { state });
      handleCloseLogoClient();
    }
  };

  const onClickCustomersTab = (e) => {
    if (e.metaKey || e.ctrlKey) return;

    const hasPermission =
      !!repPermissions?.customers &&
      Object.values(repPermissions?.customers)?.some(Boolean);

    let state;

    if (hasPermission) {
      const trueValues = Object.fromEntries(
        Object.entries(repPermissions.customers).filter(
          ([, value]) => value === true
        )
      );

      state = setNavCustomersState(trueValues);
    }

    e.preventDefault();
    if (formChanged) {
      if (editType === "checked_items")
        return handleConfirmCheckedItemsDialog("/customers", { state });
      if (editType !== "checked_items")
        return handleConfirmChangesDialog("/customers", { state });
    } else {
      navigate("/customers", { state });
    }
  };

  const onClickOrdersTab = (e) => {
    if (e.metaKey || e.ctrlKey) return;
    e.preventDefault();

    const hasPermission =
      !!repPermissions?.orders &&
      Object.values(repPermissions?.orders)?.some(Boolean);

    let state;

    if (hasPermission) {
      const trueValues = Object.fromEntries(
        Object.entries(repPermissions.orders).filter(
          ([, value]) => value === true
        )
      );

      state = setNavOrdersState(trueValues);
    }

    if (formChanged) {
      if (editType === "checked_items")
        return handleConfirmCheckedItemsDialog("/orders", { state });
      if (editType !== "checked_items")
        return handleConfirmChangesDialog("/orders", { state });
    } else {
      navigate("/orders", { state });
    }
  };

  const onClickCatalogTab = (e) => {
    if (e.metaKey || e.ctrlKey) return;
    e.preventDefault();

    const hasPermission =
      !!repPermissions?.catalog &&
      Object.values(repPermissions?.catalog)?.some(Boolean);

    let state;

    if (hasPermission) {
      const trueValues = Object.fromEntries(
        Object.entries(repPermissions.catalog).filter(
          ([, value]) => value === true
        )
      );

      state = setNavCatalogState(trueValues);
    }
    dispatch(selectedProductsAction([]));
    dispatch(selectedChildrensOfProductsAction([]));
    if (formChanged) {
      if (editType === "checked_items")
        return handleConfirmCheckedItemsDialog("/catalog", { state });
      if (editType === "product_sku") {
        return handleConfirmChangesDialog("/catalog", { state });
      }
      if (editType !== "checked_items" && editType !== "product_sku")
        return handleConfirmChangesDialog("/catalog", { state });
    } else {
      navigate("/catalog", { state });
    }
  };

  const onClickRepresentativesTab = (e) => {
    if (e.metaKey || e.ctrlKey) return;
    e.preventDefault();

    if (formChanged) {
      if (editType === "checked_items")
        return handleConfirmCheckedItemsDialog("/representatives");
      if (editType !== "checked_items")
        return handleConfirmChangesDialog("/representatives");
    } else {
      navigate("/representatives");
    }
  };

  const onClickDiscountsTab = (e) => {
    if (e.metaKey || e.ctrlKey) return;
    e.preventDefault();

    if (formChanged) {
      if (editType === "checked_items")
        return handleConfirmCheckedItemsDialog("/discounts");
      if (editType !== "checked_items")
        return handleConfirmChangesDialog("/discounts");
    } else {
      navigate("/discounts");
    }
  };

  const onClickTasksTab = (e) => {
    if (e.metaKey || e.ctrlKey) return;
    e.preventDefault();

    if (formChanged) {
      if (editType === "checked_items")
        return handleConfirmCheckedItemsDialog("/tasks");
      if (editType !== "checked_items")
        return handleConfirmChangesDialog("/tasks");
    } else {
      navigate("/tasks");
    }
  };

  const tabRestrictions = {
    Orders: {
      disableHoverListener: () => {
        if (repPermissions)
          return (
            repPermissions?.orders?.view ||
            repPermissions?.orders?.third_party ||
            repPermissions?.orders?.drafts
          );
        return onBoardingTasks.orders;
      },
      tooltipText: () => {
        if (
          repPermissions &&
          !(
            repPermissions?.orders?.view ||
            repPermissions?.orders?.third_party ||
            repPermissions?.orders?.drafts
          )
        )
          return ADMIN_ONLY_VIEW_MESSAGE;
        return "";
      },
      disabledTab: () => {
        if (repPermissions) {
          return !(
            repPermissions?.orders?.view ||
            repPermissions?.orders?.third_party ||
            repPermissions?.orders?.drafts
          );
        }
        return trialEnded || !onBoardingTasks.orders || subscriptionIsCanceled;
      },
    },
    Catalog: {
      disableHoverListener: () => {
        if (repPermissions)
          return (
            repPermissions?.catalog?.view ||
            repPermissions?.catalog?.manufacturers ||
            repPermissions?.catalog?.product_tags
          );
        return onBoardingTasks.products;
      },
      tooltipText: () => {
        if (
          repPermissions &&
          !(
            repPermissions?.catalog?.view ||
            repPermissions?.catalog?.manufacturers ||
            repPermissions?.catalog?.product_tags
          )
        )
          return ADMIN_ONLY_VIEW_MESSAGE;
        return "";
      },
      disabledTab: () => {
        if (repPermissions) {
          return !(
            repPermissions?.catalog?.view ||
            repPermissions?.catalog?.manufacturers ||
            repPermissions?.catalog?.product_tags
          );
        }
        return (
          trialEnded || !onBoardingTasks.products || subscriptionIsCanceled
        );
      },
    },
    Customers: {
      disableHoverListener: () => {
        if (repPermissions)
          return (
            repPermissions?.customers?.view ||
            repPermissions?.customers?.prospects ||
            repPermissions?.customers?.gallery ||
            repPermissions?.customers?.customer_tags ||
            repPermissions?.customers?.territories
          );
        return onBoardingTasks.customers;
      },
      tooltipText: () => {
        if (
          repPermissions &&
          !(
            repPermissions?.customers?.view ||
            repPermissions?.customers?.prospects ||
            repPermissions?.customers?.gallery ||
            repPermissions?.customers?.customer_tags ||
            repPermissions?.customers?.territories
          )
        )
          return ADMIN_ONLY_VIEW_MESSAGE;
        return "";
      },
      disabledTab: () => {
        if (repPermissions) {
          return !(
            repPermissions?.customers?.view ||
            repPermissions?.customers?.prospects ||
            repPermissions?.customers?.gallery ||
            repPermissions?.customers?.customer_tags ||
            repPermissions?.customers?.territories
          );
        }
        return (
          trialEnded || !onBoardingTasks.customers || subscriptionIsCanceled
        );
      },
    },
    Representatives: {
      disableHoverListener: () => {
        if (repPermissions) return repPermissions?.representatives?.view;
        return onBoardingTasks.representatives;
      },
      tooltipText: () => {
        if (repPermissions && !repPermissions?.representatives?.view)
          return ADMIN_ONLY_VIEW_MESSAGE;
        return "";
      },
      disabledTab: () => {
        if (repPermissions) {
          return !repPermissions?.representatives?.view;
        }
        return (
          trialEnded ||
          !onBoardingTasks.representatives ||
          subscriptionIsCanceled
        );
      },
    },
    Discounts: {
      disableHoverListener: () => {
        if (repPermissions) return repPermissions?.discounts?.view;
        return onBoardingTasks.discounts;
      },
      tooltipText: () => {
        if (repPermissions && !repPermissions?.discounts?.view)
          return ADMIN_ONLY_VIEW_MESSAGE;
        return "";
      },
      disabledTab: () => {
        if (repPermissions) {
          return !repPermissions?.discounts?.view;
        }
        return (
          trialEnded || !onBoardingTasks.discounts || subscriptionIsCanceled
        );
      },
    },
    Tasks: {
      disableHoverListener: () => {
        if (repPermissions) return repPermissions?.tasks?.view;
        return onBoardingTasks.tasks;
      },
      tooltipText: () => {
        if (repPermissions && !repPermissions?.tasks?.view)
          return ADMIN_ONLY_VIEW_MESSAGE;
        return "";
      },
      disabledTab: () => {
        if (repPermissions) {
          return !repPermissions?.tasks?.view;
        }
        return trialEnded || !onBoardingTasks.tasks || subscriptionIsCanceled;
      },
    },
    Routes: {
      disableHoverListener: () => {
        if (repPermissions) return repPermissions?.routes?.view;
        return onBoardingTasks.routes;
      },
      tooltipText: () => {
        if (repPermissions && !repPermissions?.routes?.view)
          return ADMIN_ONLY_VIEW_MESSAGE;
        return "";
      },
      disabledTab: () => {
        if (repPermissions) {
          return !repPermissions?.routes?.view;
        }
        return trialEnded || !onBoardingTasks.routes || subscriptionIsCanceled;
      },
    },
    Reports: {
      disableHoverListener: () => {
        if (repPermissions) return repPermissions?.reports?.view;
        return onBoardingTasks.reports;
      },
      tooltipText: () => {
        if (repPermissions && !repPermissions?.reports?.view)
          return ADMIN_ONLY_VIEW_MESSAGE;
        return "";
      },
      disabledTab: () => {
        if (repPermissions) {
          return !repPermissions?.reports?.view;
        }
        return trialEnded || !onBoardingTasks.reports || subscriptionIsCanceled;
      },
    },
    Cart: {
      disableHoverListener: () => {
        if (repPermissions) return repPermissions?.orders?.create_edit;

        return onBoardingTasks.shopping_cart;
      },

      tooltipText: () => {
        if (repPermissions && !repPermissions?.orders?.create_edit)
          return ADMIN_ONLY_VIEW_MESSAGE_PAGE;
        return "";
      },
      disabledTab: () => {
        if (repPermissions) {
          return !repPermissions?.orders?.create_edit;
        }

        return (
          trialEnded || !onBoardingTasks.shopping_cart || subscriptionIsCanceled
        );
      },
    },
    Notifications: {
      disableHoverListener: () => {
        if (repPermissions) return false;

        return !notificationTooltipData?.listener;
      },
      disabledTab: () => {
        if (repPermissions) return true;

        return (
          isAdmin ||
          trialEnded ||
          subscriptionIsCanceled ||
          !orderDirectConfigured
        );
      },
    },
    Chat: {
      disableHoverListener: () => {
        if (repPermissions) return false;

        return onBoardingTasks.chat;
      },

      disabledTab: () => {
        if (repPermissions) return true;

        return (
          isAdmin ||
          trialEnded ||
          !onBoardingTasks.chat ||
          subscriptionIsCanceled
        );
      },
    },
  };

  return {
    onClickSettingsMenuItem,
    onClickCustomersTab,
    onClickOrdersTab,
    onClickCatalogTab,
    onClickRepresentativesTab,
    onClickDiscountsTab,
    onClickTasksTab,
    disabledSettingsMenuItem,
    tabRestrictions,
  };
};
