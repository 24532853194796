/* eslint-disable no-useless-escape */
import * as Yup from "yup";

export const validationSchema = () =>
  Yup.object().shape({
    title: Yup.string()
      .trim()
      .required("This field is required")
      .max(100, "Max 100 characters"),
    description: Yup.string().trim().max(100, "Max 100 characters"),
    representative: Yup.string().trim().required("This field is required"),
  });
