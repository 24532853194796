import { DetailFields, SectionTitle } from "..";

import { Stack } from "@mui/material";

export const FormDetails = () => {
  return (
    <Stack gap="10px">
      <SectionTitle title="Form Details" />
      <DetailFields />
    </Stack>
  );
};
