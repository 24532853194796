import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "44px",
    borderBottom: "0.5px solid #D4D4D4",
    padding: "0 32px",
    width: "100%",
    backgroundColor: "rgba(181, 181, 172, 0.05)",
  },

  pageTitleWrap: {
    display: "flex",
    alignItems: "center",
    width: "calc(100% - 190px)",
    gap: "15px",
    "& .MuiTypography-root": {
      "&.secondary": {
        whiteSpace: "pre-wrap",
      },
      "&.main": {
        cursor: "pointer",
      },
    },
  },
}));

export const cl = {
  saveBtn: {
    height: "28px",
  },
};

export default useStyles;
