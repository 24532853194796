import { useState } from "react";
import { createPriceListService } from "services/priceLists";

import { error, success } from "utils/notifications";

export const useCreatePriceList = ({ handleSave }) => {
  const [priceName, setPriceName] = useState("");
  const [loading, setLoading] = useState(false);

  const onChange = (e) => setPriceName(e.target.value);

  const onSave = async () => {
    setLoading(true);
    try {
      const res = await createPriceListService({ name: priceName });
      handleSave({
        ...res,
        productCount: 0,
        overallAdjustment: 0,
        customerCount: 0,
      });
      success("Price list created");
      setPriceName("");
    } catch (err) {
      error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { priceName, setPriceName, onSave, onChange, loading };
};
