import { useCallback, useEffect, useMemo, useState } from "react";
import { bool, func, shape, string, array, object } from "prop-types";

import {
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useStyles from "Pages/CustomersPage/pages/CustomerProfilePage/components/TaskDrawer/styles";
import { AlarmClockIcon, CheckmarkIcon, CrossIcon } from "components/Icons";
import {
  ActionRestrictionWrapper,
  AddFormsComponent,
  LargeCheckbox,
  StyledButton,
  StyledDatePicker,
  StyledSelect,
  StyledTextField,
  StyledTimePicker,
  StyledTooltip,
} from "components";
import {
  useCustomerTasks,
  useFormFields,
  useTaskAssigne,
} from "./TaskDrawer.hooks";
import { Controller } from "react-hook-form";
import { ActivityBlock } from "Pages/CustomersPage/pages/CustomerProfilePage/components/TaskDrawer/ActivityBlock/ActivityBlock";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { getPriority } from "Pages/CustomersPage/pages/CustomerProfilePage/components/TaskDrawer/TaskDrawer.hooks";
import { AdvancedDrawerContent } from "./components/AdvancedDrawerContent";
import { IntervalContainer } from "./components/IntervalContainer";
import { ConfirmTaskDialog } from "./components/ConfirmTaskDialog";
import AdapterMoment from "@mui/lab/AdapterMoment";
import AllCustomersPopup from "Pages/CreateRoutePage/components/AllCustomersPopup";
import { useRepsPermissions } from "helpers/hooks";
import { truncateText } from "helpers/helpers";
import { setFormChangedAction } from "redux/actions/confirmDialogs";
import { createSelector } from "reselect";
import { currentUserSelector } from "redux/selectors/auth";
import { confirmDialogFormChangedSelector } from "redux/selectors/confirmDialogs";

const selector = createSelector(
  currentUserSelector,
  confirmDialogFormChangedSelector,
  (currentUser, formChanged) => ({
    currentUser,
    formChanged,
  })
);

export const TaskDrawer = ({
  open,
  onClose,
  data,
  refetchCallback,
  selectedCustomers,
  customer,
  setCheckedCustomers,
  isAdvancedDrawer,
  resetData,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = useState({
    isOpen: false,
    isDeleting: false,
  });

  const { currentUser, formChanged } = useSelector(selector);
  const { timeZone } = currentUser || {};

  const repPermissions = useRepsPermissions();

  const {
    formField,
    control,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    isDirty,
    isRepeatedTask,
    errors,
    PRIORITIES,
    STATUSES,
    trigger,
  } = useFormFields({
    open,
    taskState: data?.task || data,
  });

  const {
    title,
    representative,
    status,
    distributorId,
    representativeId,
    assignedDistributorId,
    assignedRepresentatives,
  } = formField || {};
  const { dueDate: dueDateError } = errors || {};

  const disabledAddBtn = useMemo(() => {
    const disable =
      !!isAdvancedDrawer && !customer && !selectedCustomers?.length;

    return repPermissions
      ? !repPermissions?.tasks?.create_edit
      : !(title && representative) || !!dueDateError || disable;
  }, [
    isAdvancedDrawer,
    customer,
    selectedCustomers?.length,
    repPermissions,
    title,
    representative,
    dueDateError,
  ]);

  const {
    handleSaveTask,
    handleCloseTask,
    handleDeleteTask,
    loading,
    taskState,
    editTask,
    handleUpdateCompletedTask,
    repsState,
    handleFetchReps,
    handleOnDeleteDrawer,
    handleOnSaveDrawer,
    handleOnCloseDrawer,
    handleConfirmDelete,
  } = useCustomerTasks({
    isAdvancedDrawer,
    selectedCustomers,
    customer,
    open,
    taskState: data?.task || data,
    onClose,
    refetchCallback,
    setCheckedCustomers,
    formField,
    setDialogState,
    resetData,
    formChanged,
    handleSubmit,
    trigger,
    disabledAddBtn,
    isDirty,
  });

  const [allCustomersOpen, setAllCustomersOpen] = useState(false);

  const isRepeatedTaskChanged = useMemo(
    () => isRepeatedTask && isDirty,
    [isRepeatedTask, isDirty]
  );

  const activities = taskState?.activities;

  const getTitleLabel = useMemo(() => {
    if (editTask) return "Edit";

    return "Create";
  }, [editTask]);

  const disabledCancelBtn = useMemo(() => {
    return loading;
  }, [loading]);

  const disabledDeleteBtn = useMemo(() => {
    return repPermissions ? !repPermissions?.tasks?.delete : loading;
  }, [loading, repPermissions]);

  const setStatusLabelBtn = useMemo(() => {
    if (status === STATUSES[0]) return "Mark complete";
    if (status === STATUSES[1]) return "Completed";
  }, [STATUSES, status]);

  const isValidDate = (str) => {
    if (!str) return false;
    const parsedDate = moment(str, "MM/DD/YYYY", true);
    return parsedDate.isValid();
  };

  const {
    customersList,
    checkedTaskCustomers,
    customersLoading,
    customersSearchInput,
    setCustomersList,
    handleCheckCustomer,
    setCheckedTaskCustomers,
    setCustomersSearchInput,
  } = useTaskAssigne({
    isAdvancedDrawer,
    selectedCustomers,
    setCheckedCustomers,
  });

  useEffect(() => {
    dispatch(setFormChangedAction(isDirty));

    if (!open) dispatch(setFormChangedAction(false));
  }, [isDirty, dispatch, open]);

  const isMarkBtnDisabled = useMemo(() => {
    if (!repPermissions) return assignedDistributorId !== currentUser?.id;

    const repsIds = assignedRepresentatives?.map(
      (rep) => rep?.representative?.id
    );

    return !repsIds?.includes(repPermissions?.representativeId);
  }, [
    assignedDistributorId,
    assignedRepresentatives,
    currentUser?.id,
    repPermissions,
  ]);

  const editCheckedForms = useMemo(() => {
    return (data?.task || data)?.assignedForms?.map((item) => ({
      id: item?.form?.id,
      title: item?.form?.title,
    }));
  }, [data]);

  const handleSetCheckedFormsIds = useCallback(
    (ids) => setValue("formIds", ids),
    [setValue]
  );

  const isNotAllowedToEdit = useMemo(() => {
    if (!data) return false;

    if (!repPermissions)
      return distributorId !== currentUser?.id || representativeId;

    return repPermissions?.representativeId !== representativeId;
  }, [currentUser?.id, data, distributorId, repPermissions, representativeId]);

  return (
    <>
      <ConfirmTaskDialog
        {...dialogState}
        loading={loading}
        handleSaveTask={handleSaveTask}
        formField={formField}
        isRepeatedTask={isRepeatedTask}
        onClose={handleOnCloseDrawer}
        setValue={setValue}
        setDialogState={setDialogState}
        handleConfirmDelete={handleConfirmDelete}
      />

      {!!allCustomersOpen && (
        <AllCustomersPopup
          hideActive
          handleAddCustomers={(customers) => {
            setAllCustomersOpen(false);
            setCheckedCustomers(customers);
            setCheckedTaskCustomers(customers);
          }}
          open={allCustomersOpen}
          onClose={() => setAllCustomersOpen(false)}
          // addedStops={formField.customers}
          addedStops={selectedCustomers}
          allowMissingInfo={true}
        />
      )}

      <Drawer open={open} onClose={handleCloseTask} anchor="right">
        <Box className={classes.wrapper}>
          <Box className={classes.header}>
            <Typography>{getTitleLabel} task</Typography>
            {loading && <CircularProgress size={23} />}
            <IconButton onClick={handleCloseTask}>
              <CrossIcon />
            </IconButton>
          </Box>

          <Box
            className={classes.bodyWrapper}
            sx={{
              gap: isAdvancedDrawer ? "18px !important" : "24px !important",
            }}
          >
            {isAdvancedDrawer && (
              <AdvancedDrawerContent
                setCheckedCustomers={setCheckedCustomers}
                customers={formField.customers}
                isAdvancedDrawer={isAdvancedDrawer}
                selectedCustomers={
                  // customer?.id ? [customer] : selectedCustomers
                  selectedCustomers
                }
                setAllCustomersOpen={setAllCustomersOpen}
                {...{
                  customersList,
                  checkedTaskCustomers,
                  customersLoading,
                  customersSearchInput,
                  setCustomersList,
                  handleCheckCustomer,
                  setCheckedTaskCustomers,
                  setCustomersSearchInput,
                }}
              />
            )}

            <Typography>Task Details</Typography>
            {/* Name */}

            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTooltip
                  arrow
                  placement="top"
                  title={
                    error?.message
                      ? error?.message
                      : "This field can't be empty"
                  }
                  open={!!error}
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: { offset: [0, -5] },
                      },
                    ],
                  }}
                >
                  <Box component="span">
                    <StyledTextField
                      placeholder="Name"
                      fullWidth
                      error={!!error}
                      disabled={isNotAllowedToEdit}
                      sx={{
                        "& .MuiOutlinedInput-root": { height: "100%" },
                      }}
                      {...field}
                    />
                  </Box>
                </StyledTooltip>
              )}
              name="title"
              control={control}
            />

            {/* Description */}
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTooltip
                  arrow
                  placement="top"
                  title={error?.message ? error?.message : ""}
                  open={!!error}
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: { offset: [0, -5] },
                      },
                    ],
                  }}
                >
                  <Box component="span">
                    <StyledTextField
                      placeholder="Description"
                      multiline
                      rows={5}
                      fullWidth
                      error={!!error}
                      disabled={isNotAllowedToEdit}
                      sx={{
                        "& .MuiOutlinedInput-root": { height: "100%" },
                      }}
                      {...field}
                    />
                  </Box>
                </StyledTooltip>
              )}
              name="description"
              control={control}
            />

            {/* Due date */}
            <Stack flexDirection="row" gap={1.75}>
              <Box width="67%">
                <Controller
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <StyledDatePicker
                        dateLibInstance={moment}
                        dateAdapter={AdapterMoment}
                        value={moment.tz(field.value, timeZone)}
                        wrapperClassName={classes.calendarInput}
                        textFieldProps={{
                          fullWidth: true,
                          noErrorMessage: true,
                        }}
                        disabled={isNotAllowedToEdit}
                        inputProps={{ placeholder: "Due date" }}
                        error={error?.message ? error.message : ""}
                        onChange={(time, keyboardVal) => {
                          let isoTime = "";
                          if (keyboardVal) {
                            if (!isValidDate(keyboardVal)) {
                              setError("dueDate", {
                                type: "dueDateValidate",
                                message: "Incorrect date",
                              });
                            } else {
                              clearErrors("dueDate");
                            }

                            const formattedDate = moment
                              .tz(keyboardVal, "MM/DD/YYYY", timeZone)
                              .utc()
                              .format();

                            const isValidFormattedDate =
                              moment(formattedDate).isValid();

                            isoTime =
                              isValidFormattedDate && formattedDate
                                ? formattedDate
                                : "";
                          } else if (time) {
                            isoTime = moment
                              .tz(time, "MM/DD/YYYY", timeZone)
                              .hours(0)
                              .minutes(0)
                              .seconds(0)
                              .utc()
                              .toISOString();
                            clearErrors("dueDate");
                          } else {
                            if (!keyboardVal) {
                              setValue("dueDate", null);
                              clearErrors("dueDate");
                              return;
                            }
                          }
                          field.onChange(isoTime);
                        }}
                      />
                    );
                  }}
                  name="dueDate"
                  control={control}
                />
              </Box>
              <Box width="33%">
                <Controller
                  render={({ field }) => {
                    return (
                      <StyledTimePicker
                        disabled={!formField.addTime || isNotAllowedToEdit}
                        timezone="UTC"
                        dateLibInstance={moment}
                        dateAdapter={AdapterMoment}
                        textFieldProps={{
                          fullWidth: true,
                          formSx: { backgroundColor: "#ffffff" },
                        }}
                        OpenPickerIcon={AlarmClockIcon}
                        InputProps={{
                          sx: {
                            height: "32px",
                            fontSize: "12px",
                            color: "#5F6267",
                          },
                        }}
                        am={true}
                        value={moment.tz(formField?.dueTime, timeZone).utc()}
                        onChange={(time) => {
                          const isoTime = new Date(time).toISOString();
                          setValue(field?.name, isoTime);
                        }}
                      />
                    );
                  }}
                  name="dueTime"
                  control={control}
                />
              </Box>
            </Stack>

            <Stack direction="row" alignItems="center" gap="9px">
              <Box>
                <Controller
                  render={({ field }) => (
                    <LargeCheckbox
                      checked={!!field.value}
                      disabled={isNotAllowedToEdit}
                      size={16}
                      formSx={{ m: 0 }}
                      sx={{ padding: 0 }}
                      {...field}
                      onChange={(e, newVal) => {
                        setValue("addTime", newVal);

                        if (!newVal) {
                          setValue("repeatInterval", "day");
                          setValue("repeatStep", "1");
                        }
                      }}
                    />
                  )}
                  name="addTime"
                  control={control}
                />
              </Box>
              <Typography variant="header" fontSize="13px">
                Add time
              </Typography>
            </Stack>

            {/* Interval */}
            {formField.dueDate && (
              <IntervalContainer
                control={control}
                formField={formField}
                setValue={setValue}
                isNotAllowedToEdit={isNotAllowedToEdit}
              />
            )}

            {/* Assignee */}
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledSelect
                  disabled={repsState?.loading || isNotAllowedToEdit}
                  label="Assignee"
                  fullWidth
                  error={!!error}
                  sx={{
                    "& .MuiSvgIcon-root": { color: "#9E9E9E" },
                  }}
                  IconComponent={PersonAddIcon}
                  {...field}
                  dataLength={repsState?.list?.length}
                  dataCount={repsState?.count}
                  handleFetch={handleFetchReps}
                >
                  <MenuItem value="distributor">Admin</MenuItem>

                  {repsState?.loading &&
                    !!field.value &&
                    field.value !== "distributor" && (
                      <MenuItem value={field.value}>Loading</MenuItem>
                    )}

                  {repsState?.list?.length && !repsState?.loading
                    ? repsState.list.map((rep) => (
                        <MenuItem key={rep?.id} value={rep?.id}>
                          {truncateText(rep?.name, 70)}
                        </MenuItem>
                      ))
                    : null}
                </StyledSelect>
              )}
              name="representative"
              control={control}
            />

            {/* Priority */}
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledSelect
                  label="Priority"
                  fullWidth
                  disabled={isNotAllowedToEdit}
                  error={!!error}
                  {...field}
                >
                  {PRIORITIES.map((p) => (
                    <MenuItem key={p} value={p}>
                      {getPriority({ type: p })}
                    </MenuItem>
                  ))}
                </StyledSelect>
              )}
              name="priority"
              control={control}
            />

            {/* Add Forms */}
            <AddFormsComponent
              isNotAllowedToEdit={isNotAllowedToEdit}
              editCheckedForms={editCheckedForms}
              handleSetCheckedFormsIds={handleSetCheckedFormsIds}
            />

            {/* activities */}
            {editTask ? <ActivityBlock {...{ activities }} /> : null}
          </Box>

          {/* Action buttons */}
          <Box className={classes.footer}>
            {editTask && (
              <ActionRestrictionWrapper
                disableHoverListener={!disabledDeleteBtn}
              >
                <StyledButton
                  disabled={disabledDeleteBtn || isNotAllowedToEdit}
                  label="Delete"
                  color="error"
                  variant="contained"
                  fontSize="15px"
                  onClick={() => {
                    if (isRepeatedTask) return handleOnDeleteDrawer();
                    return handleDeleteTask();
                  }}
                />
              </ActionRestrictionWrapper>
            )}

            {editTask && (
              <StyledButton
                sx={{ ml: "11px" }}
                disabled={
                  isMarkBtnDisabled ||
                  (repPermissions && !repPermissions?.tasks?.create_edit)
                }
                label={setStatusLabelBtn}
                variant="outlined"
                fontSize="15px"
                color="cancel"
                startIcon={
                  <CheckmarkIcon width="11.18" height="8" stroke="#A0A0A0" />
                }
                onClick={() => {
                  if (status === STATUSES[0]) setValue("status", STATUSES[1]);
                  if (status === STATUSES[1]) setValue("status", STATUSES[0]);
                  if (status === STATUSES[0]) {
                    handleUpdateCompletedTask(STATUSES[1]);
                  } else if (status === STATUSES[1]) {
                    handleUpdateCompletedTask(STATUSES[0]);
                  }
                  0;
                }}
              />
            )}

            <Box display="flex" gap="11px" ml="auto">
              <StyledButton
                disabled={disabledCancelBtn}
                label="Cancel"
                color="cancel"
                fontSize="15px"
                onClick={handleCloseTask}
              />
              {editTask && isRepeatedTaskChanged ? (
                <StyledButton
                  disabled={disabledAddBtn || loading || isNotAllowedToEdit}
                  label={editTask ? "Save" : "Add"}
                  variant="contained"
                  fontSize="15px"
                  onClick={handleOnSaveDrawer}
                />
              ) : (
                <StyledButton
                  disabled={disabledAddBtn || loading || isNotAllowedToEdit}
                  label={editTask ? "Save" : "Add"}
                  variant="contained"
                  fontSize="15px"
                  onClick={handleSubmit(handleSaveTask)}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

TaskDrawer.propTypes = {
  open: bool,
  onClose: func,
  viewOnly: bool,
  refetchCallback: func,
  data: shape({
    title: string,
    status: string,
    representativeId: string,
    priority: string,
    id: string,
    dueDate: string,
    distributorId: string,
    description: string,
    customerId: string,
    createdAt: string,
    assignedRepresentatives: array,
    assignedDistributorId: string,
    activityId: string,
    assignedDistributor: shape({
      name: string,
      id: string,
      profileProho: string,
    }),
    activities: array,
  }),
  selectedCustomers: array,
  customer: object,
  setCheckedCustomers: func,
  isAdvancedDrawer: bool,
  resetData: bool,
};

TaskDrawer.defaultProps = {
  open: false,
  onClose: () => {},
  viewOnly: false,
  refetchCallback: () => {},
  data: {
    title: "",
    status: "",
    representativeId: "",
    priority: "",
    id: "",
    dueDate: "",
    distributorId: "",
    description: "",
    customerId: "",
    createdAt: "",
    assignedRepresentatives: [],
    assignedDistributorId: "",
    activityId: null,
    resetData: true,
    assignedDistributor: shape({
      name: "",
      id: "",
      profileProho: null,
    }),
    activities: [],
  },
  selectedCustomers: [],
  setCheckedCustomers: () => {},
  isAdvancedDrawer: false,
};
