import { useDispatch } from "react-redux";
import {
  endLoadingOrders,
  getOrderByIdAction,
  startLoadingOrders,
} from "../../../redux/actions/orders";
import {
  confirmReturnService,
  updateReturnService,
} from "../../../services/orders";
import { error, success } from "../../../utils/notifications";
import { updateUserFieldAction } from "redux/actions/auth";

export const useOnSubmit = ({
  returnItem,
  onClose,
  orderId,
  handleGetOrder,
  successCallback,
}) => {
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const {
      deliveryMethod,
      reason,
      otherReason,
      sendNotification,
      trackingId,
      shippingCarrier,
      productReturns,
      delivererId,
      restock,
    } = data || {};
    dispatch(startLoadingOrders());

    const returnData = {
      deliveryMethod,
      reason: reason === "Other" ? otherReason : reason,
      sendNotification,
      productReturns: productReturns
        .filter((prod) => prod.returnQuantity > 0)
        .map((el) => {
          return {
            orderProductId: el.orderProductId || el.id,
            quantity: el.returnQuantity,
          };
        }),
    };

    if (deliveryMethod === "SHIPPING_CARRIER") {
      returnData.trackingId = trackingId;
      returnData.shippingCarrierId = shippingCarrier;
    }
    if (deliveryMethod === "LOCAL_DELIVERY") {
      returnData.delivererId = delivererId;
    }
    if (returnItem?.id) {
      updateReturnService(returnItem.id, returnData)
        .then(() => {
          success("Return updated successfully");
          if (successCallback) successCallback();
          dispatch(getOrderByIdAction(orderId));
          if (handleGetOrder) handleGetOrder(orderId);
        })
        .catch((err) => {
          error(err?.message || "Something went wrong");
        })
        .finally(() => {
          dispatch(endLoadingOrders());
          onClose();
        });
    }

    if (!returnItem?.id) {
      returnData.orderId = orderId;
      returnData.restock = restock;
      confirmReturnService(returnData)
        .then(() => {
          dispatch(
            updateUserFieldAction("defaultDeliveryMethod", deliveryMethod)
          );
          success("Return was successfully");
          if (successCallback) successCallback();
          dispatch(getOrderByIdAction(orderId));
          if (handleGetOrder) handleGetOrder(orderId);
        })
        .catch((err) => {
          const { deliveredQuantityError, message } = err?.response?.data || {};
          if (deliveredQuantityError)
            error("Something went wrong. Reload your page and try again");
          else error(message || err?.message);
        })
        .finally(() => {
          dispatch(endLoadingOrders());
          onClose();
        });
    }
  };

  return { onSubmit };
};
