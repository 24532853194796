import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { validationSchema } from "./FormViewPage.validations";
import { defaultValues } from "./FormViewPage.constants";
import { useEffect } from "react";

export const useFormFields = () => {
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors, isDirty, dirtyFields },
    trigger,
  } = useForm({
    mode: "onSubmit",
    defaultValues: { ...defaultValues },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const {
    fields: questions,
    append,
    remove,
    update,
    move,
  } = useFieldArray({
    control,
    name: "questions",
    keyName: "questionKey",
  });

  useEffect(() => {
    // scroll to the first textfield with error

    const titleRef = errors?.title?.ref;

    if (titleRef) return titleRef?.focus();

    const extractRefs = (obj, refs = []) => {
      if (obj && typeof obj === "object") {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty("ref")) {
          refs.push(obj.ref);
        }

        for (const key in obj) {
          // eslint-disable-next-line no-prototype-builtins
          if (obj.hasOwnProperty(key)) {
            extractRefs(obj[key], refs);
          }
        }
      } else if (Array.isArray(obj)) {
        obj.forEach((item) => extractRefs(item, refs));
      }

      return refs;
    };

    const refs = extractRefs(errors?.questions);

    if (refs?.[0]) return refs[0]?.focus();
  }, [errors]);

  return {
    control,
    formField,
    questions,
    appendQuestion: append,
    removeQuestion: remove,
    updateQuestion: update,
    moveQuestion: move,
    reset,
    handleSubmit,
    trigger,
    errors,
    clearErrors,
    setValue,
    isDirty,
    dirtyFields,
  };
};
