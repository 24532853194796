import { object, number } from "prop-types";

import { cl } from "./QuestionItem.styles";

import { Box, Stack, Typography } from "@mui/material";
import { handleGetIcons } from "Pages/FormViewPage/FormViewPage.constants";

export const QuestionItem = ({ question, index }) => {
  const { type, text } = question || {};

  const FORM_ICONS = handleGetIcons({
    singleProps: {
      firstRectFill: "rgba(165, 165, 165, 0.60)",
      secondRectStroke: "#fff",
      lastRectFill: "#fff",
      strokeOpacity: 1,
    },
    multiProps: {
      pathFill: "rgba(165, 165, 165, 0.60)",
      rectFill: "#fff",
    },
  });

  return (
    <Stack sx={cl.itemWrapper} direction="row">
      <Box
        sx={{
          ...cl.iconWrapper,
          ...(type === "MULTIPLE_ANSWER" && { ...cl.multiAnswerIcon }),
        }}
      >
        {FORM_ICONS[type]}
      </Box>
      <Typography sx={cl.text} noWrap>{`${index}. ${text}`}</Typography>
    </Stack>
  );
};

QuestionItem.propTypes = {
  question: object,
  index: number,
};
