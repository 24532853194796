import { Grid, Paper, Typography } from "@mui/material";
import { LargeCheckbox } from "components";
import { bool, func } from "prop-types";

const PopupProductsHeader = ({
  hideAvailable,
  isPriceList,
  allowSelectAll,
  handleSelectAllProducts,
  allChecked,
  isIndeterminate,
}) => {
  const classes = {
    grid: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    typography: {
      fontSize: "12px",
      color: "#6A6A6A",
      textTransform: "uppercase",
    },
  };
  return (
    <Paper
      sx={{
        backgroundColor: "#EEEEEE",
        borderBottom: "none",
        borderTop: "none",
        borderWidth: "0.5px",
        pr: "10px",
      }}
      elevation={0}
      variant="outlined"
      square
      component={Grid}
      container
      columns={9.5}
      height="40px"
    >
      <Grid item xs={0.5} sx={{ ...classes.grid }}>
        {allowSelectAll && (
          <LargeCheckbox
            size={16}
            sx={{
              "&.MuiCheckbox-indeterminate svg": { fontSize: 21, ml: "-2.5px" },
              p: "0",
              ml: "16px",
            }}
            formSx={{ m: 0 }}
            checked={allChecked}
            onChange={handleSelectAllProducts}
            onClick={(e) => e.stopPropagation()}
            indeterminate={isIndeterminate}
          />
        )}
      </Grid>

      <Grid
        item
        xs={hideAvailable ? 5.8 : 5.6}
        sx={{ ...classes.grid, pl: "8px" }}
      >
        <Typography sx={classes.typography}>Product</Typography>
      </Grid>
      {/* <Grid item xs={1.8} sx={classes.grid}>
        <Typography sx={classes.typography}>Variations</Typography>
      </Grid> */}
      <Grid item xs={1.6} sx={classes.grid}>
        {!hideAvailable && (
          <Typography sx={classes.typography}>Available qty</Typography>
        )}
      </Grid>

      <Grid item xs={1.6} sx={classes.grid} pl={isPriceList ? "20px" : 0}>
        <Typography sx={classes.typography}>Price</Typography>
      </Grid>
    </Paper>
  );
};

PopupProductsHeader.propTypes = {
  hideAvailable: bool,
  isPriceList: bool,
  allowSelectAll: bool,
  allChecked: bool,
  isIndeterminate: bool,
  handleSelectAllProducts: func,
};

export default PopupProductsHeader;
