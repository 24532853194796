export const cl = {
  title: {
    color: "#1C1C19",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "normal",
  },

  deleteBtn: {
    height: 24,
    width: "fit-content",
    minWidth: "fit-content",
    border: "none !important",
    p: 0,
    color: "#FF6254",
    backgroundColor: "#ffff",
    borderRadius: "8px",
  },

  editBtn: {
    height: 24,
    width: "fit-content",
    minWidth: "70px",
    border: "none !important",
    color: "#707070",
    backgroundColor: "#ffff",
    borderRadius: "8px",
    ml: "auto",
  },

  saveBtn: {
    height: "24px",
    width: "fit-content",
    minWidth: "70px",
    borderRadius: "4px",
  },

  inputLabel: {
    transform: "translate(14px, 8px) scale(1)",

    "&.Mui-focused": {
      transform: "translate(14px, -7px) scale(0.75)",
    },
    "&.MuiInputLabel-shrink": {
      transform: "translate(14px, -7px) scale(0.75)",
    },
  },
};
