import { app } from "firebase/Chat/config";
import { doc, getFirestore } from "firebase/firestore";
import { useMemo } from "react";
import { useDocument } from "react-firebase-hooks/firestore";

const useImportStatus = ({ session }) => {
  const [importSessionDoc, importSessionDocLoading] = useDocument(
    doc(getFirestore(app), `importer/${session}`)
  );

  const { status: importStatus } = useMemo(
    () => importSessionDoc?.data() || {},
    [importSessionDoc]
  );

  const importStatusMemoized = useMemo(() => importStatus, [importStatus]);

  return {
    importStatus: importStatusMemoized,
    importStatusLoading: importSessionDocLoading,
  };
};

export default useImportStatus;
