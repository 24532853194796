import { useState } from "react";
import { string } from "prop-types";
import { Menu, MenuList } from "@mui/material";
import { ColumnsSettingsList } from "./ColumnsSettingsList";
import {
  ActionRestrictionWrapper,
  ThreeDotButton,
} from "../../../../../../../../components";
import { useAdmin } from "helpers/helpers";
import { useRepsPermissions } from "helpers/hooks";
import { ADMIN_ONLY_VIEW_MESSAGE_PAGE } from "utils/constants";

export const ColumnsSettingComponent = ({ currentTab }) => {
  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  return (
    <>
      <ActionRestrictionWrapper
        disableHoverListener={!repPermissions}
        title={ADMIN_ONLY_VIEW_MESSAGE_PAGE}
        offset={[40, -6]}
      >
        <ThreeDotButton
          disabled={!!repPermissions || isAdmin}
          paddingRight="0px"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        />
      </ActionRestrictionWrapper>
      <Menu
        id="column-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        sx={{
          "& .MuiPaper-root": { borderRadius: "8px" },
        }}
        MenuListProps={{
          "aria-labelledby": "setting-button",
          sx: {
            width: "340px",
            py: 0,
          },
        }}
      >
        <MenuList>
          <ColumnsSettingsList
            onClose={() => setAnchorEl(null)}
            currentTab={currentTab}
          />
        </MenuList>
      </Menu>
    </>
  );
};

ColumnsSettingComponent.propTypes = {
  currentTab: string,
};
