import * as React from "react";
export const DeleteOutlinedIcon = (props) => (
  <svg
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="m9.827 6.123-.23 6m-3.193 0-.23-6m6.644-2.14q.343.053.682.11m-.682-.11-.711 9.255a1.5 1.5 0 0 1-1.496 1.385H5.389a1.5 1.5 0 0 1-1.496-1.385l-.711-9.255m9.636 0A32 32 0 0 0 10.5 3.72m-8 .375q.34-.06.682-.11m0 0A32 32 0 0 1 5.5 3.718m5 0v-.61c0-.787-.607-1.443-1.393-1.468a35 35 0 0 0-2.214 0c-.786.025-1.393.681-1.393 1.467v.611m5 0a32.4 32.4 0 0 0-5 0"
        stroke="#000"
        strokeOpacity={0.4}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .123h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
