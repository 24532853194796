import { useMemo, useRef, useState } from "react";
import { bool, object, func } from "prop-types";
import { Grid, Paper } from "@mui/material";
import {
  ActionRestrictionWrapper,
  ThreeDotButton,
  TotalSummariesToggles,
  TotalSummaryBlock,
  TotalSummaryInfoBlock,
} from "components";
import { useAdmin, separateNumWithComma } from "helpers/helpers";
import { addPercentsOfPayments } from "components/TotalSummariesComponents/helpers";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { currentUserSelector } from "redux/selectors/auth";
import { SETTINGS_NAME } from "components/TotalSummariesComponents/TotalSummariesToggles/TotalSummariesToggles.constants";
import { useRepsPermissions } from "helpers/hooks";
import { ADMIN_ONLY_VIEW_MESSAGE_PAGE } from "utils/constants";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

export const ProductsTotalSummaries = ({ isLoading, totalSummaries }) => {
  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();
  const { currentUser } = useSelector(selector);
  const products = currentUser?.totalSummarySettings?.products;

  const chosenTopThreeName = useMemo(() => {
    if (!products) return "";
    return Object.keys(products)
      .map((key) => ({ name: key, value: products[key] }))
      .find((el) => el.value)?.name;
  }, [products]);

  const toggleBtnAnchor = useRef();
  const [openToggles, setOpenToggles] = useState(false);

  return (
    <Paper
      sx={{
        mt: "12px",
        pl: 1.75,
        minHeight: "64px",
        height: "64px",
      }}
      component={Grid}
      container
      elevation={0}
      variant="outlined"
    >
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
        xs={6}
        item
      >
        <TotalSummaryInfoBlock
          isLoading={isLoading}
          items={[
            {
              name: "Total Inventory",
              value: totalSummaries?.totalInventoryValue
                ? `$ ${separateNumWithComma(
                    totalSummaries?.totalInventoryValue
                  )}`
                : 0,
            },
            {
              name: "Total Products",
              value: totalSummaries?.totalProducts ?? 0,
            },
            {
              name: "Low on Stock",
              value: totalSummaries?.lowOnStock ?? 0,
              valueStyles: { color: "#FF8000" },
            },
            {
              name: "Out of Stock",
              value: totalSummaries?.outOfStock ?? 0,
              valueStyles: { color: "#FF6254" },
            },
          ]}
        />
      </Grid>
      <Grid
        sx={{
          pl: "clamp(6px, 0.65vw, 12.8px)",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 1,
        }}
        xs={6}
        item
      >
        <TotalSummaryBlock
          name={SETTINGS_NAME?.[chosenTopThreeName]?.name}
          isLoading={isLoading}
          topName={chosenTopThreeName}
          topThree={addPercentsOfPayments(totalSummaries?.topThree)}
        />

        <ActionRestrictionWrapper
          disableHoverListener={!repPermissions}
          title={ADMIN_ONLY_VIEW_MESSAGE_PAGE}
        >
          <ThreeDotButton
            paddingRight="14px"
            disabled={!!repPermissions || isAdmin}
            ref={toggleBtnAnchor}
            onClick={() => setOpenToggles(true)}
          />
        </ActionRestrictionWrapper>
      </Grid>

      <TotalSummariesToggles
        anchorEl={toggleBtnAnchor.current}
        open={openToggles}
        onClose={() => setOpenToggles(false)}
        settingsType="products"
      />
    </Paper>
  );
};

ProductsTotalSummaries.propTypes = {
  isLoading: bool,
  totalSummaries: object,
  setFilterMenuOpen: func,
  filterMenuOpen: bool,
};
