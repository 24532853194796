import { useCallback, useContext, useMemo, useState } from "react";
import { object, number } from "prop-types";
import { IconButton, Stack } from "@mui/material";
import { CopyOutlinedIcon, DeleteOutlinedIcon } from "components/Icons";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";
import { generateUUID } from "helpers/helpers";
import { CheckboxBlock } from "./index";
import { cloneDeep } from "lodash";
import { MAX_ALLOWED_QUESTIONS } from "Pages/FormViewPage/FormViewPage.constants";

export const FooterBlock = ({ question, orderIndex, control }) => {
  const {
    formField,
    setValue,
    handleOpenQuestionDeleteDialog,
    formId,
    clearErrors,
    dirtyFields,
  } = useContext(FormViewContext);

  const tempList = useMemo(() => cloneDeep(formField?.questions), [formField]);

  const [isClickBlocked, setIsClickBlocked] = useState(false);

  const handleCopy = useCallback(() => {
    if (tempList?.length >= MAX_ALLOWED_QUESTIONS) return;

    if (isClickBlocked) return;
    setIsClickBlocked(true);
    setTimeout(() => setIsClickBlocked(false), 300);

    tempList.splice(question?.orderIndex + 1, 0, {
      text: question?.text,
      type: question?.type,
      ...(!!question?.options?.length && { options: question?.options }),
      autofill: question?.autofill,
      required: question?.required,
      uuid: generateUUID(),
    });

    setValue(
      "questions",
      tempList.map((q, i) => ({ ...q, orderIndex: i })),
      { shouldDirty: true }
    );
    clearErrors("questions");
  }, [question, setValue, tempList, clearErrors, isClickBlocked]);

  const handleDelete = useCallback(() => {
    const responses = question?._count?.responses || 0;
    if (typeof responses !== "number")
      // eslint-disable-next-line no-console
      return console.error("responses is required");

    if (responses > 0) return handleOpenQuestionDeleteDialog({ question });

    tempList.splice(question?.orderIndex, 1);

    if (formId && question?.id)
      setValue("questionsToDelete", [
        ...formField?.questionsToDelete,
        {
          id: question.id,
          deleteResponses: true,
        },
      ]);

    setValue(
      "questions",
      tempList.map((q, i) => ({ ...q, orderIndex: i })),
      { shouldDirty: true }
    );
    clearErrors("questions");
  }, [
    question,
    handleOpenQuestionDeleteDialog,
    tempList,
    formId,
    setValue,
    formField?.questionsToDelete,
    clearErrors,
  ]);

  return (
    <Stack justifyContent="space-between" direction="row" mt={1.5}>
      <CheckboxBlock
        questionType={question?.type}
        {...{ orderIndex, control, formField, setValue, dirtyFields }}
      />

      <Stack gap="8px" direction="row">
        <IconButton sx={{ p: 0 }} onClick={handleCopy}>
          <CopyOutlinedIcon />
        </IconButton>

        <IconButton sx={{ p: 0 }} onClick={handleDelete}>
          <DeleteOutlinedIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

FooterBlock.propTypes = {
  question: object,
  orderIndex: number,
  control: object,
};
