import { useCallback, useState } from "react";

const initState = {
  open: false,
  item: null,
  seeAllAction: false,
};

export const useDialogPreviewContent = () => {
  const [dialogPreviewContentState, setDialogPreviewContentState] =
    useState(initState);

  const handleOpenDialogPreviewContent = useCallback(
    ({ item, seeAllAction = false }) => {
      // eslint-disable-next-line no-console
      if (!item) return console.error("item is required");

      setDialogPreviewContentState((prev) => ({
        ...prev,
        open: true,
        item,
        seeAllAction,
      }));
    },
    []
  );

  const handleCloseDialogPreviewContent = useCallback(() => {
    setDialogPreviewContentState(initState);
  }, []);

  return {
    dialogPreviewContentState,
    handleOpenDialogPreviewContent,
    handleCloseDialogPreviewContent,
  };
};
