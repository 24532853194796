import { func, object, string } from "prop-types";

import { UploadFileIcon } from "components/Icons";

import { Button, Stack } from "@mui/material";

export const DashedUploadInput = ({
  inputRef,
  onAttach,
  wrapProps = {},
  label = "",
  ...props
}) => {
  return (
    <Stack direction="row" alignItems="center" gap="8px" {...wrapProps}>
      <Button
        variant="outlined"
        startIcon={<UploadFileIcon />}
        onClick={() => {
          inputRef.current.click();
        }}
        sx={{
          borderColor: "rgba(0, 0, 0, 0.10) !important",
          padding: "9px 20px",
          borderRadius: "8px",
          height: "38px",
          color: "rgba(86, 86, 86, 0.64)",
          fontSize: "12px",
          span: {
            mr: "4px",
            ml: 0,
          },
          borderStyle: "dotted !important",
        }}
        {...props}
      >
        {label ? label : "Upload photos"}
        <input
          ref={inputRef}
          type="file"
          hidden
          onChange={onAttach}
          accept={"*"}
          multiple
        />
      </Button>
    </Stack>
  );
};

DashedUploadInput.propTypes = {
  inputRef: object,
  onAttach: func,
  label: string,
  wrapProps: object,
};
