import { useContext, useMemo } from "react";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Box, FormControl, Grid, MenuItem } from "@mui/material";
import {
  ActionRestrictionWrapper,
  StyledSelect,
  StyledTextField,
} from "components";
import { CustomerContext } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage";
import { ExpandMore } from "@mui/icons-material";
import { truncateText } from "helpers/helpers";

export const DiscountsBlock = () => {
  const { control, priceListData, formField, repPermissions } =
    useContext(CustomerContext);

  const {
    priceList,
    listLoading,
    onFetchPriceList,
    priceCount,
    page: pagePriceList,
  } = priceListData;

  const isAllowed = (values) => {
    const { floatValue, formattedValue } = values;
    return (
      (floatValue <= 100 &&
        floatValue >= 0 &&
        !formattedValue.includes("-") &&
        !/^0\d/.test(formattedValue)) ||
      formattedValue === ""
    );
  };

  const disabledCreateEditPriceList = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.catalog?.create_edit_price_lists;
    }
  }, [repPermissions]);

  return (
    <Grid xs={12} columnSpacing={2.75} item container>
      <Grid xs={6} item>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <NumberFormat
                adminIsAllowed
                customInput={StyledTextField}
                fullWidth
                noErrorMessage
                formSx={{ mb: 2 }}
                size="small"
                placeholder="0"
                isAllowed={isAllowed}
                InputProps={{
                  sx: {
                    fontSize: "13px",
                    height: "43px",
                    paddingRight: "6px",
                  },
                  endAdornment: (
                    <Box
                      sx={{
                        width: "32px",
                        height: "32px",
                        minWidth: "32px",
                        minHeight: "32px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                        backgroundColor: "#F8F8F8",
                      }}
                    >
                      %
                    </Box>
                  ),
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: "13px",
                    color: "#5F6267",
                    top: 3,
                  },
                }}
                error={error ? error.message : ""}
                label="Percent discount"
                {...field}
              />
            </FormControl>
          )}
          name="percentDiscount"
          control={control}
        />
      </Grid>

      <Grid xs={6} item>
        <Controller
          render={({ field, fieldState: { error } }) => {
            return (
              <ActionRestrictionWrapper
                disableHoverListener={!disabledCreateEditPriceList}
              >
                <StyledSelect
                  disabled={listLoading || disabledCreateEditPriceList}
                  adminIsAllowed
                  displayEmpty
                  fullWidth
                  formSx={{ mb: "16px" }}
                  size="small"
                  labelId="select-label-price-list"
                  error={error ? error.message : ""}
                  label="Price list"
                  notched={true}
                  labelProps={{ shrink: true }}
                  IconComponent={(props) => (
                    <ExpandMore sx={{ fontSize: 27 }} {...props} />
                  )}
                  labelSx={{
                    color: "#5F6267!important",
                    fontWeight: 400,
                    fontSize: 13,
                    top: 3,
                    "&.MuiInputLabel-shrink": { top: 0 },
                  }}
                  sx={{
                    "&:hover": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: " #3F3F3F !important",
                      },
                    },
                  }}
                  height="43px"
                  fontSize="13px"
                  dataCount={priceCount}
                  dataLength={priceList?.length}
                  handleFetch={() => onFetchPriceList(pagePriceList + 1)}
                  value={(priceList.length && field.value) || ""}
                  {...field}
                >
                  <MenuItem value={field.value} sx={{ display: "none" }}>
                    {formField?.priceList?.name}
                  </MenuItem>

                  <MenuItem value="">Standard</MenuItem>
                  {priceList?.map((p) => (
                    <MenuItem key={p.id} value={p?.id}>
                      {truncateText(p?.name, 30)}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </ActionRestrictionWrapper>
            );
          }}
          name="priceListId"
          control={control}
        />
      </Grid>
    </Grid>
  );
};
