import { shape, string, func } from "prop-types";
import { Link as NavLink } from "react-router-dom";
import { Box, Typography } from "@mui/material";

export const CustomerBlock = ({ customer, handleOpenCustomerProfile }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "flex-start",
        cursor: handleOpenCustomerProfile ? "pointer" : "default",
        overflow: "hidden",
        position: "relative",
        textDecoration: "none",
      }}
      component={handleOpenCustomerProfile ? NavLink : "span"}
      to={`/customers/${customer?.id}`}
      onClick={(e) => {
        if (e.metaKey || e.ctrlKey) return;
        e.stopPropagation();
        e.preventDefault();
        !!handleOpenCustomerProfile && handleOpenCustomerProfile(customer?.id);
      }}
    >
      <Box maxWidth="100%" pr={1}>
        <Typography
          sx={{
            fontSize: 13,
            fontWeight: 400,
            color: "#1C1C19",
            "&:hover": {
              textDecoration: handleOpenCustomerProfile ? "underline" : "none",
            },
          }}
          noWrap
        >
          {customer?.displayedName || customer?.displayName || customer?.name}
        </Typography>
      </Box>

      <Box maxWidth="100%" pr={1}>
        <Typography
          sx={{
            fontSize: 13,
            color: "#1C1C19",
            fontWeight: 300,
          }}
          noWrap
        >
          {customer?.shippingAddress?.formatted_address ||
            customer?.billingAddress?.formatted_address}
        </Typography>
      </Box>
    </Box>
  );
};

CustomerBlock.propTypes = {
  customer: shape({
    id: string,
    name: string,
    displayName: string,
    shippingAddress: shape({ formatted_address: string }),
    billingAddress: shape({ formatted_address: string }),
  }),
  handleOpenCustomerProfile: func,
};
CustomerBlock.defaultProps = {
  customer: {
    id: "",
    name: "",
    displayName: "",
    shippingAddress: {
      formatted_address: "",
    },
    billingAddress: {
      formatted_address: "",
    },
  },
  handleOpenCustomerProfile: () => {},
};
