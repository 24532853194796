import * as React from "react";
export const DragAndDropIcon = (props) => (
  <svg
    width={10}
    height={7}
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#939393"
      d="M0 .123h2.4v2.4H0zm3.6 0H6v2.4H3.6zm3.6 0h2.4v2.4H7.2zM0 3.723h2.4v2.4H0zm3.6 0H6v2.4H3.6zm3.6 0h2.4v2.4H7.2z"
    />
  </svg>
);
