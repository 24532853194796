import { bool, func, array, number, object } from "prop-types";
import { Box, Grid, Paper, Typography } from "@mui/material";
import {
  SelectLabelComponent,
  LargeCheckbox,
} from "../../../../../../../components";
import pluralize from "pluralize";
import { useAdmin } from "helpers/helpers";
import { ArrowDownIcon, ArrowUpIcon } from "components/Icons";
import { SORTABLE_COLUMNS } from "./CustomersHeader.constants";

const CustomersHeader = ({
  allCustomersChecked,
  checkAllCustomers,
  checkedCustomers,
  customersCount,
  customers,
  fetchListAndCheckAll,
  repCustomersLoading,
  setCheckedCustomers,
  viewCustomerPopup,
  hideStatus,
  quickSort,
  handleSetSort,
  hideOrders = false,
  adminIsAllowed,
  loading = false,
}) => {
  const classes = {
    grid: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    typography: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#6A6A6A",
      textTransform: "uppercase",
    },
    arrowBox: {
      ml: "4px",
      display: "flex",
      cursor: "pointer",
    },
  };

  const isAdmin = useAdmin(adminIsAllowed);

  const handleSelectAll = (v) => {
    if (customersCount === customers?.length && v) {
      checkAllCustomers(false);
      return;
    }
    if (customersCount === customers?.length && !v) {
      checkAllCustomers(true);
      return;
    }
    fetchListAndCheckAll();
  };

  const TABLE_COLUMNS = [
    {
      title: "",
      xs: 1,
      element: (
        <LargeCheckbox
          sx={{ p: 0 }}
          checked={allCustomersChecked}
          onChange={() => checkAllCustomers(allCustomersChecked)}
          disabled={isAdmin || loading}
          adminIsAllowed={adminIsAllowed}
        />
      ),
      show: !viewCustomerPopup,
      sx: {
        justifyContent: "center",
      },
    },
    {
      title: "Status",
      xs: 1,
      show: !viewCustomerPopup && !hideStatus,
    },
    {
      title: "Id",
      xs: 1,
      sx: viewCustomerPopup ? { marginLeft: "78px" } : {},
      show: true,
    },
    {
      title: "Customer",
      xs:
        !viewCustomerPopup && hideStatus ? 4.8 : !viewCustomerPopup ? 3.8 : 4.5,
      show: true,
    },
    {
      title: "Group",
      xs: hideOrders ? 2.5 : viewCustomerPopup ? 2.7 : 2,
      show: true,
    },
    {
      id: "territory",
      title: "Territory",
      xs: hideOrders ? 2.5 : viewCustomerPopup ? 1.5 : 2,
      show: true,
    },
    {
      id: "orders",
      title: "Orders",
      xs: 1,
      sx: { marginLeft: "-5px" },
      show: !hideOrders,
    },
  ];

  return (
    <Paper
      sx={{ backgroundColor: "#EEEEEE", borderWidth: "0.5px" }}
      elevation={0}
      variant="outlined"
      square
      component={Grid}
      container
      height="40px"
    >
      {checkedCustomers?.length && !viewCustomerPopup ? (
        <SelectLabelComponent
          itemCheckedCount={checkedCustomers?.length}
          itemAllCount={customersCount}
          name={`${pluralize("ENTRY", checkedCustomers?.length)} SELECTED`}
          onSelectAll={(value) => handleSelectAll(value)}
          onCancel={() => {
            if (setCheckedCustomers) setCheckedCustomers([]);
            checkAllCustomers(true);
          }}
          dividerAfterSelect
          loading={repCustomersLoading}
        />
      ) : (
        <>
          {TABLE_COLUMNS.map(({ title, xs, element, sx, show }) => {
            const activeSortableField =
              SORTABLE_COLUMNS?.[title]?.sortableField;

            return (
              !!show && (
                <Grid
                  key={title}
                  item
                  xs={xs}
                  sx={{
                    ...classes.grid,
                    justifyContent: "flex-start",
                    ...sx,
                  }}
                >
                  {!!title && (
                    <Typography sx={classes.typography}>{title}</Typography>
                  )}
                  {element && element}

                  {SORTABLE_COLUMNS[title] && !!quickSort && (
                    <Box
                      sx={classes.arrowBox}
                      onClick={() =>
                        SORTABLE_COLUMNS[title] &&
                        handleSetSort(activeSortableField)
                      }
                    >
                      {quickSort[activeSortableField] === "asc" ? (
                        <ArrowDownIcon />
                      ) : (
                        <ArrowUpIcon
                          fill={
                            !quickSort[activeSortableField]
                              ? "#a7a8aa"
                              : "#5f6267"
                          }
                        />
                      )}
                    </Box>
                  )}
                </Grid>
              )
            );
          })}
        </>
      )}
    </Paper>
  );
};

CustomersHeader.propTypes = {
  allCustomersChecked: bool,
  hideStatus: bool,
  hideOrders: bool,
  checkAllCustomers: func,
  checkedCustomers: array,
  customersCount: number,
  customers: array,
  fetchListAndCheckAll: func,
  repCustomersLoading: bool,
  setCheckedCustomers: func,
  viewCustomerPopup: bool,
  quickSort: object,
  handleSetSort: func,
  adminIsAllowed: bool,
  loading: bool,
};

CustomersHeader.defaultProps = {
  adminIsAllowed: false,
};

export default CustomersHeader;
