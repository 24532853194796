import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { forwardRef } from "react";
import { bool, string, object } from "prop-types";
import useStyles from "./styles";
import { StyledTooltip } from "components";

export const ValueToggleButtonGroup = forwardRef(
  (
    {
      qtyPicker,
      type,
      selectedColor = "#F2F2F2",
      unSelectedColor = "#FAFAFA",
      amountTooltipText = "Amount off per case",
      percentTooltipText = "Percent off per case",
      toggleBtnSx = {},
      hideTooltip = false,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();

    return (
      <ToggleButtonGroup
        size="small"
        exclusive
        className={classes.toggleGroup}
        ref={ref}
        {...props}
      >
        <StyledTooltip
          title={percentTooltipText}
          placement="top"
          arrow
          isDark
          PopperProps={{
            modifiers: [{ name: "offset", options: { offset: [0, -2] } }],
          }}
          disableHoverListener={hideTooltip}
        >
          <ToggleButton
            sx={{
              border: "none",
              backgroundColor:
                type === "PERCENTAGE" ? selectedColor : unSelectedColor,
              "&.Mui-selected": { backgroundColor: selectedColor },
              width: "30px",
              textTransform: "none",
              ...toggleBtnSx,
            }}
            value="PERCENTAGE"
          >
            %
          </ToggleButton>
        </StyledTooltip>
        <StyledTooltip
          title={amountTooltipText}
          placement="top"
          arrow
          isDark
          disableHoverListener={hideTooltip}
          PopperProps={{
            modifiers: [{ name: "offset", options: { offset: [0, -2] } }],
          }}
        >
          <ToggleButton
            sx={{
              border: "none",
              backgroundColor:
                type === "ABSOLUTE" ? selectedColor : unSelectedColor,

              "&.Mui-selected": { backgroundColor: selectedColor },
              width: "30px",
              textTransform: "none",
              ...toggleBtnSx,
            }}
            value="ABSOLUTE"
          >
            $
          </ToggleButton>
        </StyledTooltip>
        {qtyPicker && (
          <StyledTooltip
            title={"Number of free cases"}
            placement="top"
            arrow
            isDark
            PopperProps={{
              modifiers: [{ name: "offset", options: { offset: [0, -2] } }],
            }}
          >
            <ToggleButton
              sx={{
                border: "none",
                backgroundColor:
                  type === "QUANTITY" ? selectedColor : unSelectedColor,

                "&.Mui-selected": { backgroundColor: selectedColor },
                width: "30px",
                textTransform: "none",
                ...toggleBtnSx,
              }}
              value="QUANTITY"
            >
              Qty
            </ToggleButton>
          </StyledTooltip>
        )}
      </ToggleButtonGroup>
    );
  }
);

ValueToggleButtonGroup.propTypes = {
  qtyPicker: bool,
  type: string,
  amountTooltipText: string,
  percentTooltipText: string,
  toggleBtnSx: object,
  selectedColor: string,
  unSelectedColor: string,
  hideTooltip: bool,
};

ValueToggleButtonGroup.defaultProps = {
  qtyPicker: true,
  toggleBtnSx: {},
  amountTooltipText: "Amount off per case",
  percentTooltipText: "Amount off per case",
  selectedColor: "#F2F2F2",
  unSelectedColor: "#FAFAFA",
};

ValueToggleButtonGroup.displayName = "ValueToggleButtonGroup";

export default ValueToggleButtonGroup;
