import { array } from "prop-types";
import { Box, Stack, Typography } from "@mui/material";

export const MultipleDialogBlock = ({ answers = "" }) => {
  return (
    <Stack gap="4px">
      <Box>
        <Typography fontSize={14} fontWeight={400} color="#5F6267" noWrap>
          Replied:
        </Typography>
      </Box>

      <Stack>
        {answers?.map((answer) => (
          <Typography
            key={answer}
            fontSize={14}
            fontWeight={600}
            color="#5F6267"
            noWrap
          >
            ・{answer}
          </Typography>
        ))}
      </Stack>
    </Stack>
  );
};

MultipleDialogBlock.propTypes = { answers: array };
