export const cl = {
  tabsPropsSx: {
    py: 0,
    height: "36px",
    fontSize: "20px !important",
    fontWeight: 400,
    color: "#9C9C94",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      height: "3px",
    },
    minHeight: "auto",
    height: "38px",
    "& .Mui-selected": {
      backgroundColor: "transparent!important",
    },
  },
};
