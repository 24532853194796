export const FILTER_TABS = [
  {
    name: "Active",
    value: true,
    field: "active",
  },
  {
    name: "Inactive",
    value: false,
    field: "inactive",
  },
];

export const FORM_STATUS = {
  active: "ACTIVE",
  inactive: "INACTIVE",
};
