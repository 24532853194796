import { bool, string, array, object, func } from "prop-types";
import { Box, CircularProgress } from "@mui/material";
import { HeaderBlock, ListBlock } from "./components";

export const FreeCasesBlock = ({
  status,
  title,
  list,
  paymentStatus,
  formField,
  handleUpdateFreeCaseProduct,
  handleRemoveFreeCaseProduct,
  discDict,
  loading,
  calcProductPriceByPriceList,
}) => {
  return (
    <Box bgcolor="#FFF" borderRadius="4px">
      <HeaderBlock
        {...{ status, title }}
        hasProducts={!!list?.length}
        handleSelectProducts={handleUpdateFreeCaseProduct}
      />
      {loading && (
        <Box
          display="flex"
          height="200px"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <CircularProgress />
        </Box>
      )}
      {list?.length ? (
        <ListBlock
          {...{
            list,
            paymentStatus,
            handleUpdateFreeCaseProduct,
            handleRemoveFreeCaseProduct,
            discDict,
            calcProductPriceByPriceList,
          }}
          formDiscountType={formField.discount?.type}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

FreeCasesBlock.propTypes = {
  status: bool,
  title: string,
  list: array,
  paymentStatus: string,
  formField: object,
  handleUpdateFreeCaseProduct: func,
  handleRemoveFreeCaseProduct: func,
  calcProductPriceByPriceList: func,
  discDict: array,
  loading: bool,
};
