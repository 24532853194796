import { string } from "prop-types";

import { FormsIcon } from "components/Icons";

import { cl } from "./TitleBlock.styles";

import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const TitleBlock = ({ title, text, id }) => {
  const navigate = useNavigate();

  return (
    <Stack direction="row" sx={cl.titleBlockWrapper}>
      <Stack width="fit-content">
        <FormsIcon />
      </Stack>

      <Stack sx={cl.titleWrapper}>
        <Typography
          noWrap
          sx={{ ...cl.title, "&:hover": { textDecoration: "underline" } }}
          onClick={() => navigate(`/form/${id}`)}
        >
          {title}
        </Typography>
        <Typography noWrap sx={{ ...cl.title, fontWeight: 300 }}>
          {text}
        </Typography>
      </Stack>
    </Stack>
  );
};

TitleBlock.propTypes = {
  title: string,
  text: string,
  id: string,
};
