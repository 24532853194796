import { createContext } from "react";
import { bool } from "prop-types";
import { useLocation, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { CustomerHeader, CustomerMainBody } from "./components";
import { useCustomerPage } from "./CustomerPage.hooks";
import PaytermComponent from "Pages/SettingsPage/components/SettingsTabs/PaytermsTab/PaytermComponent";
import { NewTerritoryDialog } from "components/NewTerritoryDialog/NewTerritoryDialog";
import MapPopup from "../NewCustomerPage/MapPopup/MapPopup";
import DuplicatePopup from "Pages/CustomersPage/components/DuplicatePopup";
import { useRepsPermissions } from "helpers/hooks";
import { usePriceLists } from "helpers/usePriceLists";

export const CustomerContext = createContext();

export const CustomerPage = ({ isEdit }) => {
  const { state } = useLocation();
  const { customerId } = useParams();
  const repPermissions = useRepsPermissions();

  const priceListData = usePriceLists({ open: true });

  const {
    isAdmin,
    control,
    setValue,
    errors,
    trigger,
    formField,
    handleSetCustomer,
    loadingCustomer,
    handleSetDisplayName,
    setCustomerLoading,
    territoryList,
    duplicateOpen,
    setDuplicateOpen,
    handleProceedWithDuplicate,
    popupDuplicates,
    setPopupDuplicates,
    paytermsList,
    handleFetchPayTerms,
    isOpenPaymentTermModal,
    setOpenPaymentTermModal,
    isOpenNewTerritoryModal,
    setOpenNewTerritoryModal,
    shippingDuplicates,
    setShippingDuplicates,
    resetShippingAddressCoordinates,
    handleOpenDuplicates,
    googleAddress,
    setGoogleAddress,
    toggleDetails,
    setToggleDetails,
    mapOpen,
    setMapOpen,
    customIdModal,
    setCustomIdModal,
    customIdValidation,
    setCustomIdValidation,
    nameTooltipOpen,
    setNameTooltipOpen,
    nameWarnText,
    setNameWarnText,
    nameValidation,
    setNameValidation,
    billingValidation,
    setBillingValidation,
    shippingValidation,
    setShippingValidation,
    handleSubmit,
    clearErrors,
    validationNames,
    resetBillingAddressCoordinates,
    formChanged,
    deleteDublicates,
    checkedCustomers,
    handleCheckCustomers,
    deleteLoading,
    handleCloseDuplicatePopup,
    haveQBConnect,
    setError,
    currentUser,
  } = useCustomerPage({ customerId, state, isEdit });

  return (
    <Box>
      <CustomerContext.Provider
        value={{
          isAdmin,
          isEdit,
          control,
          setValue,
          errors,
          trigger,
          handleSetCustomer,
          loadingCustomer,
          handleSetDisplayName,
          setCustomerLoading,
          territoryList,
          duplicateOpen,
          setDuplicateOpen,
          handleProceedWithDuplicate,
          popupDuplicates,
          setPopupDuplicates,
          paytermsList,
          handleFetchPayTerms,
          isOpenPaymentTermModal,
          setOpenPaymentTermModal,
          isOpenNewTerritoryModal,
          setOpenNewTerritoryModal,
          shippingDuplicates,
          setShippingDuplicates,
          resetShippingAddressCoordinates,
          handleOpenDuplicates,
          googleAddress,
          setGoogleAddress,
          toggleDetails,
          setToggleDetails,
          mapOpen,
          setMapOpen,
          customIdModal,
          setCustomIdModal,
          resetBillingAddressCoordinates,
          validationNames,
          customIdValidation,
          setCustomIdValidation,
          nameTooltipOpen,
          setNameTooltipOpen,
          nameWarnText,
          setNameWarnText,
          nameValidation,
          setNameValidation,
          billingValidation,
          setBillingValidation,
          shippingValidation,
          setShippingValidation,
          handleSubmit,
          formField,
          clearErrors,
          formChanged,
          setError,
          repPermissions,
          customerId,
          priceListData,
        }}
      >
        <PaytermComponent
          open={isOpenPaymentTermModal}
          onClose={() => setOpenPaymentTermModal(false)}
          isQuickAdd
          setValue={setValue}
          handleFetchPayTerms={() => handleFetchPayTerms()}
        />

        <NewTerritoryDialog
          open={isOpenNewTerritoryModal}
          onClose={() => setOpenNewTerritoryModal(false)}
          setValue={setValue}
        />

        <MapPopup
          handleSetAddress={(address) => {
            if (setGoogleAddress)
              setGoogleAddress((prev) => ({
                ...prev,
                [mapOpen]: { ...address },
              }));

            if (clearErrors) clearErrors(mapOpen);
            setValue(mapOpen, address, { shouldDirty: true });
          }}
          defaultCenter={currentUser.shippingAddress}
          address={formField[mapOpen]}
          isOpen={!!mapOpen}
          customer={formField}
          hideTooltip
          hideRadius
          handleClose={() => setMapOpen(null)}
        />

        <DuplicatePopup
          isOpen={duplicateOpen}
          handleClose={handleCloseDuplicatePopup}
          handleProceed={handleProceedWithDuplicate}
          duplicates={popupDuplicates}
          type="shippingAddress"
          showCheckboxes={popupDuplicates.length > 1}
          checkedCustomers={checkedCustomers}
          setCheckedState={handleCheckCustomers}
          handleDelete={deleteDublicates}
          loading={deleteLoading}
          showDeleteBtn={!haveQBConnect}
          disabledActionBtn={
            popupDuplicates.length > 1 && !checkedCustomers.length
          }
        />

        <CustomerHeader />

        <Box mt={4.2} display="flex" justifyContent="center">
          <CustomerMainBody />
        </Box>
      </CustomerContext.Provider>
    </Box>
  );
};

CustomerPage.propTypes = { isEdit: bool };
CustomerPage.defaultProps = { isEdit: false };

export default CustomerPage;
