import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { object, func, bool } from "prop-types";
import { ExportIcon, ImportIcon } from "../../../../../../../components/Icons";
import StyledButton from "../../../../../../../components/StyledButton";
import useStyles from "./styles";
import { useAdmin } from "helpers/helpers";
import { ActionRestrictionWrapper, StyledTooltip } from "components";

const ImportOption = ({
  option,
  handleImport,
  handleExport,
  inProgress,
  disabled,
  disabledImport,
  disabledExport,
}) => {
  const classes = useStyles({ inProgress });
  const isAdmin = useAdmin();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.titleWrapper}>
        <Typography className={classes.title}>{option.title}</Typography>
      </Box>
      <Box className={classes.iconWrapper}>{option.icon}</Box>
      <Box className={classes.buttonsWrapper}>
        {disabled && !isAdmin ? (
          <StyledTooltip title="Coming soon" arrow placement="top">
            <Box>
              <StyledButton
                disabled={disabled || disabledImport}
                label="Import"
                variant="contained"
                fontSize="18px"
                startIcon={<ImportIcon fill={inProgress ? "#fff" : "#000"} />}
                className={`${classes.button} import`}
              />
            </Box>
          </StyledTooltip>
        ) : (
          <ActionRestrictionWrapper disableHoverListener={!disabledImport}>
            <StyledButton
              disabled={disabled || disabledImport}
              label="Import"
              variant={
                inProgress || disabled || disabledImport
                  ? "contained"
                  : "outlined"
              }
              color={
                inProgress || disabled || disabledImport ? "primary" : "greyBtn"
              }
              onClick={() => handleImport(option, inProgress)}
              fontSize="18px"
              startIcon={<ImportIcon fill={inProgress ? "#fff" : "#000"} />}
              className={`${classes.button} import`}
            />
          </ActionRestrictionWrapper>
        )}

        <ActionRestrictionWrapper disableHoverListener={!disabledExport}>
          <StyledButton
            disabled={disabledExport}
            label="Export"
            variant="outlined"
            color="greyBtn"
            onClick={() => handleExport(option.title.toLowerCase())}
            fontSize="18px"
            startIcon={<ExportIcon />}
            className={classes.button}
          />
        </ActionRestrictionWrapper>
      </Box>
    </Box>
  );
};

ImportOption.propTypes = {
  option: object,
  handleImport: func,
  handleExport: func,
  inProgress: bool,
  disabled: bool,
  disabledImport: bool,
  disabledExport: bool,
};

ImportOption.defaultProps = {
  option: {},
  handleImport: () => {},
  handleExport: () => {},
  disabled: false,
  inProgress: false,
  disabledImport: false,
  disabledExport: false,
};

export default ImportOption;
