import { array, string, func, number, object } from "prop-types";
import { Chip, Stack } from "@mui/material";
import { Box } from "@mui/system";

const CustomerTabSwitcherComponent = ({
  tabs,
  currentTab,
  handleChangeCurrentTab,
  tabWrapperSx = {},
  componentWrapperSx = {},
}) => {
  return (
    <Box
      sx={{
        mr: "15px",
        ...componentWrapperSx,
      }}
    >
      <Stack
        sx={{
          py: "16px",
        }}
        direction="column"
      >
        <Box display="flex" gap={1} sx={tabWrapperSx}>
          {tabs &&
            tabs.map((tab) => (
              <Chip
                key={tab}
                sx={{
                  height: "28px",
                  fontSize: "11px",
                  fontWeight: 400,
                  borderRadius: "7px",
                  textTransform: "uppercase",
                }}
                label={tab}
                color={tab === currentTab ? "ground" : "groundLight"}
                onClick={() => handleChangeCurrentTab(tab)}
              />
            ))}
        </Box>
      </Stack>
    </Box>
  );
};

CustomerTabSwitcherComponent.propTypes = {
  tabs: array,
  currentTab: string,
  currentFlatTab: number,
  handleChangeCurrentTab: func,
  handleChangeCurrentFlatTab: func,
  checkedCustomersCount: number,
  checkedGroupsCount: number,
  tabWrapperSx: object,
  componentWrapperSx: object,
};

CustomerTabSwitcherComponent.defaultProps = {
  tabs: null,
};

export default CustomerTabSwitcherComponent;
