import { combineReducers } from "redux";

import auth from "./auth.js";
import reps from "./reps.js";
import customers from "./customers.js";
import parentCustomers from "./parentCustomers.js";
import territory from "./territory.js";
import tags from "./tags.js";
import gallery from "./gallery.js";
import products from "./products.js";
import manufacturers from "./manufacturers.js";
import confirmDialogs from "./confirmDialogs";
// import productCategories from "./product-categories.js";
import orders from "./orders";
import tasks from "./tasks";
import forms from "./forms";
import snackbar from "./snackbar.js";
import payterms from "./payterms.js";
import transactions from "./transactions.js";
import drafts from "./drafts";
import savedFilters from "./savedFilters";
import discounts from "./discounts";
import manufacturersDiscounts from "./manufacturers-discounts.js";
import chat from "./chat";
import orderDirects from "./order-directs";
import productTags from "./product-tags";
import categories from "./categories";
import settings from "./settings";
import totalSummaries from "./total-summaries";
import integrations from "./integrations";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import subscriptionDialogs from "./subscriptionDialogs";

const customersPersistConfig = {
  key: "customers",
  storage,
  whitelist: ["customersFilters", "customersSwitch"],
};

const productsPersistConfig = {
  key: "products",
  storage,
  whitelist: ["catalogFilter", "catalogSwitch"],
};

const ordersPersistConfig = {
  key: "orders",
  storage,
  whitelist: ["ordersFilter", "ordersQuickFilter", "ordersSwitch"],
};

const repsPersistConfig = {
  key: "reps",
  storage,
  whitelist: [
    "routesQuickFilter",
    "routesFilter",
    "repsFilter",
    "repsFilterSwitches",
    "routesSwitch",
  ],
};

const msdPersistConfig = {
  key: "manufacturersDiscounts",
  storage,
  whitelist: ["showInactive"],
};

const rootReducer = combineReducers({
  auth,
  reps: persistReducer(repsPersistConfig, reps),
  customers: persistReducer(customersPersistConfig, customers),
  groups: parentCustomers,
  territory,
  tags,
  gallery,
  products: persistReducer(productsPersistConfig, products),
  manufacturers,
  confirmDialogs,
  // productCategories,
  orders: persistReducer(ordersPersistConfig, orders),
  tasks,
  forms,
  snackbar,
  payterms,
  transactions,
  drafts,
  savedFilters,
  discounts,
  manufacturersDiscounts: persistReducer(
    msdPersistConfig,
    manufacturersDiscounts
  ),
  chat,
  orderDirects,
  productTags,
  categories,
  settings,
  totalSummaries,
  integrations,
  subscriptionDialogs,
});

export default rootReducer;
