import { useContext, useEffect } from "react";

import {
  EmptyScreen,
  InfiniteLoadMoreBtn,
  InfiniteScrollWrapper,
  StyledTooltip,
  TableHeaderControlPanel,
} from "components";
import { FormItem, FormsListHeader } from "./components";

import { FormsPageContext } from "Pages/TasksPage/TasksPage";

import { cl } from "./FormsList.styles";

import { CircularProgress, Divider, ListItemText, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { formsFilterAction } from "redux/actions/forms";
import { useNavigate } from "react-router-dom";
import { ADMIN_ONLY_VIEW_MESSAGE } from "utils/constants";

export const FormsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    repPermissions,
    checkedForms,
    setCheckedForms,
    handleCheckForm,
    checkAllForms,
    handleSelectAvailableForms,
    isAdmin,

    formsFilter,
    countForms,
    existDataForms,
    loadingFormsList,
    formsList,
    fetchFormsList,
    handleBulkDeleteForms,
    handleSwitchStatusForms,
  } = useContext(FormsPageContext);

  const handleSingleFormActions = (item) => {
    const form = item || checkedForms?.[0];

    return [
      {
        label: "Edit form",
        element: null,
        disabled: repPermissions
          ? !repPermissions?.forms?.create_edit
          : isAdmin,
        onClick: () => navigate(`/form/${form?.id}`),
        show: true,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: repPermissions ? !repPermissions?.forms?.create_edit : false,
          props: { isDark: false },
        },
      },
      {
        label: "Report",
        disabled: true,
        onClick: () => {},
        show: true,
        hasTooltip: true,
        wrapperSx: {
          "& .MuiDivider-root": {
            display: "none",
          },
          "& .MuiListItemText-root": {
            "& span": {
              fontSize: "12px !important",
              p: "4.5px 13px",
            },
          },
        },
        element: (
          <Stack direction="row" alignItems="center">
            <StyledTooltip
              title="Coming soon"
              arrow
              placement="top"
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: { offset: [0, -10] },
                  },
                ],
              }}
            >
              <ListItemText
                sx={{
                  opacity: 0.3,
                  "& .MuiTypography-root": {
                    fontSize: "13px",
                  },
                }}
              >
                Report
              </ListItemText>
            </StyledTooltip>
            <Divider
              orientation="vertical"
              sx={{
                height: "14px",
                ml: "11px",
                backgroundColor: "#ffff",
              }}
            />
          </Stack>
        ),
      },
      {
        label: form?.active ? "Inactivate" : "Activate",
        element: null,
        disabled: repPermissions
          ? !repPermissions?.forms?.create_edit
          : isAdmin,
        onClick: () => handleSwitchStatusForms(item),
        show: true,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: repPermissions ? !repPermissions?.forms?.create_edit : false,
          props: { isDark: false },
        },
      },
      {
        label: "Delete",
        sx: {
          "& .MuiListItemText-root": {
            "& .MuiTypography-root": {
              fontSize: "12px",
              fontWeight: 500,
              color: "#FF6254 !important",
            },
          },
        },
        disabled: repPermissions ? !repPermissions?.forms?.delete : isAdmin,
        onClick: () => handleBulkDeleteForms(item),
        show: true,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: repPermissions ? !repPermissions?.forms?.delete : false,
          props: { isDark: false },
        },
      },
    ];
  };

  const handleMultipleRoutesActions = () => {
    return [
      {
        label: "Report",
        disabled: true,
        onClick: () => {},
        show: true,
        hasTooltip: true,
        wrapperSx: {
          "& .MuiDivider-root": {
            display: "none",
          },
          "& .MuiListItemText-root": {
            "& span": {
              fontSize: "12px !important",
              p: "4.5px 13px",
            },
          },
        },
        element: (
          <Stack direction="row" alignItems="center">
            <StyledTooltip
              title="Coming soon"
              arrow
              placement="top"
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: { offset: [0, -10] },
                  },
                ],
              }}
            >
              <ListItemText
                sx={{
                  opacity: 0.3,
                  "& .MuiTypography-root": {
                    fontSize: "13px",
                  },
                }}
              >
                Report
              </ListItemText>
            </StyledTooltip>
            <Divider
              orientation="vertical"
              sx={{
                height: "14px",
                ml: "11px",
                backgroundColor: "#ffff",
              }}
            />
          </Stack>
        ),
      },
      {
        label: formsFilter?.active ? "Inactivate" : "Activate",
        element: null,
        onClick: () => handleSwitchStatusForms(),
        show: true,
        disabled: repPermissions
          ? !repPermissions?.forms?.create_edit
          : isAdmin,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: repPermissions ? !repPermissions?.forms?.create_edit : false,
          props: { isDark: false },
        },
      },
      {
        label: "Delete",
        disabled: repPermissions ? !repPermissions?.forms?.delete : isAdmin,
        onClick: () => handleBulkDeleteForms(),
        show: true,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: repPermissions ? !repPermissions?.forms?.delete : false,
          props: { isDark: false },
        },
      },
    ];
  };

  const handleGetQuickActions = (params) => {
    if (!checkedForms.length) return [];

    if (checkedForms.length === 1) {
      return handleSingleFormActions(params).slice(0, 5);
    }
    return handleMultipleRoutesActions(params).slice(0, 5);
  };

  const handleGetDropDownActions = (params) => {
    if (!checkedForms.length) return [];

    if (checkedForms.length === 1) {
      return handleSingleFormActions(params).slice(5, 6);
    }
    return handleMultipleRoutesActions(params).slice(5, 6);
  };

  const QUICK_ACTIONS = handleGetQuickActions();
  const DROPDOWN_ACTIONS = handleGetDropDownActions();

  const handleNextFunc = () => {
    dispatch(formsFilterAction({ ...formsFilter, page: formsFilter.page + 1 }));
  };

  useEffect(() => {
    return () => {
      dispatch(formsFilterAction({ ...formsFilter, page: 1 }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchFormsList();
  }, [fetchFormsList]);

  return formsList?.length ? (
    <>
      <TableHeaderControlPanel
        actionWrapperProps={{ sx: cl.actionWrapperPropsSx }}
        checkedCount={checkedForms.length}
        actionsList={QUICK_ACTIONS}
        loading={false}
        dropDownActions={DROPDOWN_ACTIONS}
        onSelectAll={(value) => checkAllForms(value)}
        hasCheckedItems={!!checkedForms.length}
        availableSelectCount={countForms}
        selectName="form"
        onSelectVisible={handleSelectAvailableForms}
        cancelSelection={() => setCheckedForms([])}
        headerComponent={<FormsListHeader />}
      />
      <Stack
        sx={{
          height: "100%",
          maxHeight: "calc(100vh - 268px)",
          overflow: "hidden",
          borderWidth: "0 0.5px 0.5px 0.5px",
          borderStyle: "solid",
          borderColor: "#D5D9D9",
          borderRadius: "0 0 4px 4px",
        }}
      >
        <InfiniteScrollWrapper
          dataLength={formsList.length}
          loading={loadingFormsList}
          next={handleNextFunc}
          loader={
            <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />
          }
          hasMore={formsList.length < countForms}
          maxHeight={"calc(100vh - 318px)"}
          id="tasks-list-scroll"
        >
          {formsList.map((task, index) => (
            <FormItem
              key={task?.id}
              task={task}
              isChecked={checkedForms.some(
                (checkedForm) => checkedForm?.id === formsList[index]?.id
              )}
              handleCheckForm={handleCheckForm}
              getActionList={handleSingleFormActions}
            />
          ))}
          {!loadingFormsList && formsList.length < countForms && (
            <InfiniteLoadMoreBtn onClick={handleNextFunc} />
          )}
        </InfiniteScrollWrapper>
      </Stack>
    </>
  ) : (
    <EmptyScreen
      type="form"
      height="calc(100vh - 470px)"
      onConfirm={() => navigate("/form/new")}
      showAction={!existDataForms}
      loading={loadingFormsList}
      disabled={!!repPermissions && !repPermissions?.forms?.create_edit}
      tooltipTitle={ADMIN_ONLY_VIEW_MESSAGE}
    />
  );
};
