import { SCROLL_LIMIT_FORMS } from "utils/constants";

export const SHOW_WARNING_MSG_TIMES = 2;
export const WARNING_MSG = (
  <>
    You&apos;ve reached the maximum <br /> number of forms allowed to add.
  </>
);

export const INIT_FORMS_STATE = {
  loadingFormsList: true,
  countForms: 0,
  formsList: [],
};

export const INIT_FORMS_FILTER = {
  page: 1,
  limit: SCROLL_LIMIT_FORMS,
  active: true,
  sort_by_title: "asc",
  search: "",
};
