import { Stack, Typography } from "@mui/material";
import { string } from "prop-types";

export const EmptyUserBlock = ({ label }) => {
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        p: "11px 16px 12px",
        background: "#F8F8F8",
        borderRadius: "4px",
        border: "0.5px #D5D9D9 solid",
      }}
    >
      <Typography fontSize="10px" color="#707070" lineHeight="12px">
        {label}
      </Typography>
    </Stack>
  );
};

EmptyUserBlock.propTypes = {
  label: string,
};
