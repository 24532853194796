import { useNavigate } from "react-router-dom";
import { array, func, string, object } from "prop-types";
import { Box, Typography } from "@mui/material";
import StyledButton from "../../../../components/StyledButton";
import { useSelector } from "react-redux";
import { useAdmin } from "helpers/helpers";
import { useMemo } from "react";
import { ActionRestrictionWrapper } from "components";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  ADMIN_ONLY_VIEW_MESSAGE_PAGE,
} from "utils/constants";

const DiscountsPageTab = ({
  handleConfirmCheckedItemsDialog,
  repPermissions,
}) => {
  const isAdmin = useAdmin();
  const navigate = useNavigate();
  const formChanged = useSelector(
    ({ confirmDialogs }) => confirmDialogs.formChanged
  );

  const disabledCreateEdit = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.discounts?.create_edit;
    }
  }, [repPermissions]);

  const editType = useSelector(({ confirmDialogs }) => confirmDialogs.editType);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        px: "32px",
        backgroundColor: "#F0F0F0",
        borderBottom: "0.5px solid #D4D4D4",
        height: "44px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box display="flex">
          <Typography
            variant="h6"
            fontWeight="400"
            fontSize="20px"
            color="#9C9C94"
            mr="6px"
          >
            Discounts
          </Typography>
          {/* <Typography
            variant="h6"
            fontWeight="400"
            fontSize="20px"
            color="#63666B"
          >
            Manufacturer specific discounts
          </Typography> */}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 1,
        }}
      >
        <ActionRestrictionWrapper
          disableHoverListener={!disabledCreateEdit}
          title={
            repPermissions?.role === "SALES"
              ? ADMIN_ONLY_VIEW_MESSAGE
              : ADMIN_ONLY_VIEW_MESSAGE_PAGE
          }
          placement="bottom"
        >
          <StyledButton
            disabled={disabledCreateEdit || isAdmin}
            sx={{ height: 28 }}
            variant="contained"
            label="Create Discount"
            onClick={(e) => {
              e.preventDefault();
              if (formChanged && editType === "checked_items") {
                handleConfirmCheckedItemsDialog();
                return;
              }
              navigate("new");
            }}
          />
        </ActionRestrictionWrapper>
      </Box>
    </Box>
  );
};
DiscountsPageTab.propTypes = {
  tabs: array,
  currentTab: string,
  handleSetCurrentTab: func,
  handleConfirmCheckedItemsDialog: func,
  repPermissions: object,
};
DiscountsPageTab.defaultProps = {
  tabs: [],
};

export default DiscountsPageTab;
