import { useCallback, useEffect, useMemo, useRef } from "react";
import { array, bool, func, number, object, string } from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, CircularProgress, Stack } from "@mui/material";

import { OrdersHeader, OrdersItem, OrdersTotalSummaries } from "./components";
import { NewOrdersFilter } from "../OrdersFilter/NewOrdersFilter";
import { EmptyScreen, InfiniteLoaderWrapper } from "components";
import { useOrderStatuses } from "Pages/OrdersPage/Orders.hook";
import { getOrdersAction, getOrdersList } from "redux/actions/orders";
import { initialState } from "redux/reducers/settings";
import { error } from "utils/notifications";
import { updateDistributorSettingsService } from "services/account";
import { setDirectColumnLayoutsAction } from "redux/actions/settings";
import { useAdmin } from "helpers/helpers";
import { TableHeaderControlPanel } from "components/TableHeaderControlPanel/TableHeaderControlPanel";
import { ADMIN_ONLY_VIEW_MESSAGE, SCROLL_LIMIT } from "utils/constants";
import { getTransactionsAction } from "redux/actions/transactions";
import { useWindowSize } from "helpers/hooks";

export const DirectOrdersTab = ({
  isLoadingTotalSummaries,
  totalSummaries,
  setCurrentFilterDate,
  orders,
  ordersLoading,
  loadingAllItems,
  allOrdersChecked,
  checkAllOrders,
  setAllOrdersChecked,
  setCheckedOrders,
  ordersCount,
  checkedOrders,
  currentUser,
  handleCheckOrder,
  handleChoseMenuItem,
  handleDownloadInvoice,
  handleOpenEmail,
  ordersExist,
  handleConfirmCheckedItemsDialog,
  isQuickBooksConnected,
  currentTab,
  repPermissions,
  itemAllCount,
  onSelectAll,
  additionalActions,
  dropDownActions,
}) => {
  const isAdmin = useAdmin();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { paymentStatuses, handleReplaceFirebaseStatuses } = useOrderStatuses();
  const [windowWidth] = useWindowSize();

  const handleFetchOrders = useCallback(() => {
    dispatch(getOrdersAction({ limit: SCROLL_LIMIT }));
  }, [dispatch]);

  const replaceOrders = useCallback(() => {
    const newOrders = handleReplaceFirebaseStatuses(orders);
    if (newOrders) {
      dispatch(getOrdersList({ orders: newOrders, isScrolling: false }));
      if (Object.keys(paymentStatuses).length) {
        dispatch(getTransactionsAction({ limit: SCROLL_LIMIT }));
      }
    }
  }, [dispatch, handleReplaceFirebaseStatuses, orders, paymentStatuses]);

  useEffect(replaceOrders, [replaceOrders]);
  // const [openAddContact, setOpenAddContact] = useState(false);

  const handleResetLayout = useCallback(async () => {
    const { direct } = initialState.column_layouts;

    try {
      const res = await updateDistributorSettingsService({
        orderLayoutSettings: { direct },
      });
      dispatch(setDirectColumnLayoutsAction(res?.orderLayoutSettings?.direct));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    }
  }, [dispatch]);

  // It should reset the columns to the initial state if their counts don't match
  const savedColumnsCount = {
    direct:
      (currentUser?.orderLayoutSettings?.direct?.disable?.length || 0) +
      (currentUser?.orderLayoutSettings?.direct?.main?.length || 0),
  };
  const initColumnsCount = {
    direct:
      initialState.column_layouts.direct.disable?.length +
      initialState.column_layouts.direct.main?.length,
  };

  useEffect(() => {
    if (savedColumnsCount?.direct !== initColumnsCount?.direct) {
      if (repPermissions || isAdmin) return;
      handleResetLayout();
    }
  }, [
    repPermissions,
    currentTab,
    handleResetLayout,
    initColumnsCount?.direct,
    isAdmin,
    savedColumnsCount?.direct,
  ]);

  const stackRef = useRef(null);

  const calcStackWidth = useMemo(() => {
    return stackRef?.current?.offsetWidth || 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, stackRef?.current?.offsetWidth]);

  return (
    <>
      <Box px="32px" mt={1.5}>
        <OrdersTotalSummaries
          isLoading={isLoadingTotalSummaries}
          totalSummaries={totalSummaries}
          handleChoosePeriod={setCurrentFilterDate}
        />
        <NewOrdersFilter
          {...{ currentTab, setCheckedOrders, setAllOrdersChecked }}
        />
        {orders.length && ordersExist ? (
          <Box position="relative">
            <TableHeaderControlPanel
              actionWrapperProps={{
                sx: { padding: "0 19px 0 15px !important" },
              }}
              checkedCount={checkedOrders?.length}
              actionsList={additionalActions}
              loading={ordersLoading}
              loadingAllItems={loadingAllItems}
              dropDownActions={dropDownActions}
              hasCheckedItems={!!checkedOrders?.length}
              availableSelectCount={itemAllCount}
              selectName="order"
              onSelectVisible={checkAllOrders}
              onSelectAll={(value) => onSelectAll(value)}
              cancelSelection={() => setCheckedOrders([])}
              headerComponent={
                <OrdersHeader
                  allOrdersChecked={allOrdersChecked}
                  checkAllOrders={checkAllOrders}
                />
              }
            />
            {ordersLoading && (
              <CircularProgress
                size="24px"
                sx={{ position: "absolute", top: "6px", right: "70px" }}
              />
            )}
            <Stack
              sx={{
                height: "100%",
                maxHeight: "calc(100vh - 317px)",
                overflow: "hidden",
                borderWidth: "0 1px 1px 1px",
                borderStyle: "solid",
                borderColor: "#D5D9D9",
                borderRadius: "0 0 4px 4px",
              }}
              ref={stackRef}
            >
              <InfiniteLoaderWrapper
                itemsList={orders}
                itemsCount={ordersCount}
                offsetHeight={316}
                wrapperWidth={calcStackWidth}
                loading={ordersLoading}
                hideScroll
                tableStyle={{ borderWidth: "0px" }}
                handleFetch={handleFetchOrders}
                itemHeight={49}
                testid="orders-scroll-table"
                renderChildren={(index) => (
                  <OrdersItem
                    showBorder={index !== orders.length - 1}
                    order={orders[index]}
                    isChecked={checkedOrders.some(
                      (checkedOrder) => checkedOrder === orders[index]?.id
                    )}
                    timeZone={currentUser.timeZone}
                    handleCheckOrder={handleCheckOrder}
                    handleChoseMenuItem={handleChoseMenuItem}
                    handleDownloadInvoice={handleDownloadInvoice}
                    handleOpenEmail={handleOpenEmail}
                    handleConfirmCheckedItemsDialog={
                      handleConfirmCheckedItemsDialog
                    }
                    isQuickBooksConnected={isQuickBooksConnected}
                    currentTab={currentTab}
                    repPermissions={repPermissions}
                  />
                )}
              />
            </Stack>
          </Box>
        ) : (
          <EmptyScreen
            type="orders"
            onConfirm={() => navigate("/cart")}
            height="calc(100vh - 453px)"
            showAction={!ordersExist}
            loading={ordersLoading}
            disabled={!!repPermissions && !repPermissions?.orders?.create_edit}
            tooltipTitle={ADMIN_ONLY_VIEW_MESSAGE}
          />
        )}
      </Box>
    </>
  );
};

DirectOrdersTab.propTypes = {
  isLoadingTotalSummaries: bool,
  totalSummaries: object,
  setCurrentFilterDate: func,
  orders: array,
  ordersLoading: bool,
  loadingAllItems: bool,
  allOrdersChecked: bool,
  checkAllOrders: func,
  ordersCount: number,
  checkedOrders: array,
  currentUser: object,
  handleCheckOrder: func,
  handleChoseMenuItem: func,
  handleDownloadInvoice: func,
  handleOpenEmail: func,
  ordersExist: bool,
  setAllOrdersChecked: func,
  setCheckedOrders: func,
  handleConfirmCheckedItemsDialog: func,
  isQuickBooksConnected: bool,
  currentTab: string,
  repPermissions: object,
  onSelectAll: func,
  itemAllCount: number,
  additionalActions: array,
  dropDownActions: array,
};
