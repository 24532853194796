import { Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { AddContentButton } from "..";
import { RepsList } from "./components/RepsList";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";

export const RepsBlock = () => {
  const { onChangeAllReps } = useContext(FormViewContext);

  return (
    <Stack gap="14px" width="100%" px="24px">
      <Typography fontSize="14px" fontWeight={500} color="#595959">
        Connected to customers
      </Typography>

      <RepsList />
      <AddContentButton
        label="Add representatives"
        onClick={() => onChangeAllReps(true)}
      />
    </Stack>
  );
};
