import {
  RESET_TASKS_FILTER,
  RESET_TASKS_SWITCH,
  TASKS_FILTER,
  TASKS_SWITCH,
} from "redux/actions/tasks";
import { SCROLL_LIMIT_TASKS } from "utils/constants";

export const initialTasksState = {
  tasksFilter: {
    page: 1,
    limit: SCROLL_LIMIT_TASKS,
    status: undefined, // PENDING, COMPLETED
    sort_by_due_date: "asc", // asc, desc
    sort_by_date: undefined, // asc, desc
    sort_by_name: undefined, // asc, desc
    sort_by_customer: undefined, // asc, desc
    sort_by_priority: undefined, // asc, desc
    sort_by_assigned: undefined, // asc, desc
    repeated: undefined, // true, false
    customer_id: undefined,
    customer_name: undefined,
    customer_display_name: undefined,
    customer_ids: undefined,
    representative_id: undefined,
    start_due_date: undefined, // date
    end_due_date: undefined, // date
    due_date: undefined, // today, yesterday, tomorrow, this_week, last_week, next_week, this_month, last_month, next_month, this_year, last_year, all_time
    search: undefined,
  },
  tasksSwitch: [
    {
      value: "representative_id",
      label: "Assigned to",
      type: "select",
      checked: false,
      selectLabel: "Assigned to",
    },
    {
      value: "due_date",
      label: "Due date",
      type: "date",
      checked: false,
      selectLabel: "Select",
    },
    {
      value: "repeated",
      label: "Repeated",
      type: "switch",
      values: ["on"],
      checked: false,
    },
  ],
};

const tasksReducer = (state = initialTasksState, { type, payload }) => {
  switch (type) {
    case TASKS_FILTER:
      return { ...state, tasksFilter: payload };

    case TASKS_SWITCH:
      return { ...state, tasksSwitch: payload };

    case RESET_TASKS_FILTER:
      return { ...state, tasksFilter: initialTasksState.tasksFilter };

    case RESET_TASKS_SWITCH:
      return { ...state, tasksSwitch: initialTasksState.tasksSwitch };

    default:
      return state;
  }
};

export default tasksReducer;
