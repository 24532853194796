import { array, object, func, number, string, bool } from "prop-types";
import {
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import {
  ActionRestrictionWrapper,
  StyledSelect,
  StyledTextField,
} from "components";
import useStyles from "../../../styles";
import { CrossIcon, OutlinedPlusIcon, SearchIcon } from "components/Icons";
import { useRepsPermissions } from "helpers/hooks";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  ADMIN_ONLY_VIEW_MESSAGE_PAGE,
} from "utils/constants";

export const ManufacturerBlock = ({
  manufacturers,
  manufacturersCount,
  handleFetchManufacturers,
  setCustomersSearchInput,
  customersSearchInput,
  setIsOpenNewManDialog,
  setManufacturersPage = () => {},
  formField,
  setValue,
  control,
  fontSize = "14px",
  formSx = {},
  labelSx = {},
  disabled,
}) => {
  const classes = useStyles();

  const repPermissions = useRepsPermissions();

  return (
    <Grid xs={12} item>
      <Controller
        render={({ field, fieldState: { error } }) => (
          <StyledSelect
            disabled={disabled}
            adminIsAllowed
            error={error ? error.message : ""}
            noErrorMessage
            fullWidth
            label="Manufacturer *"
            labelSx={{
              color: "#B5B5AC!important",
              fontWeight: 400,
              fontSize: fontSize,
              lineHeight: 1,
              "&.Mui-focused": {
                transform: "translate(14px, -5px) scale(0.75)",
              },
              "&.MuiInputLabel-shrink": {
                transform: "translate(14px, -5px) scale(0.75)",
              },
              ...labelSx,
            }}
            formSx={formSx}
            fontSize={fontSize}
            dataLength={manufacturers.length}
            dataCount={manufacturersCount}
            handleFetch={handleFetchManufacturers}
            PaperPropsSx={{ "& ul": { pt: 0 }, maxWidth: "383px" }}
            onClose={() => {
              setCustomersSearchInput("");
            }}
            inputProps={{
              classes: {
                icon: classes.selectIcon,
              },
            }}
            {...field}
            data-testid="manufacturer"
          >
            <MenuItem
              sx={{
                p: "10px",
                "&:hover": { backgroundColor: "#FFF !important" },
                "&.Mui-focused": { bgcolor: "#FFF !important" },
                "&.Mui-focusVisible": { bgcolor: "#FFF !important" },
                "&.Mui-selected": { bgcolor: "#FFF !important" },
                "&.Mui-selected:hover": { bgcolor: "#FFF" },
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
            >
              <Grid xs={12} item>
                <StyledTextField
                  adminIsAllowed
                  fullWidth
                  size="small"
                  value={customersSearchInput}
                  onChange={(e) => {
                    !!setManufacturersPage && setManufacturersPage(1);
                    setCustomersSearchInput(e.target.value);
                  }}
                  placeholder="Search manufacturers"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  InputProps={{
                    className: classes.textInputSearch,
                    endAdornment: (
                      <>
                        <InputAdornment
                          sx={{ position: "absolute", left: 9 }}
                          position="start"
                        >
                          <SearchIcon />
                        </InputAdornment>
                        {customersSearchInput && (
                          <InputAdornment position="end">
                            <IconButton
                              sx={{ p: "1px" }}
                              onClick={() => {
                                setManufacturersPage(1);
                                setCustomersSearchInput("");
                              }}
                            >
                              <CrossIcon size="15" />
                            </IconButton>
                          </InputAdornment>
                        )}
                      </>
                    ),
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel,
                  }}
                />
              </Grid>
            </MenuItem>
            <MenuItem value={field.value} sx={{ display: "none" }}>
              {formField.manufacturerName}
            </MenuItem>
            {formField.manufacturerIdInactive?.id ? (
              <MenuItem
                sx={{
                  color: "#F2545B",
                }}
                onClick={() =>
                  setValue(
                    "manufacturerName",
                    formField.manufacturerIdInactive?.name
                  )
                }
                value={formField.manufacturerIdInactive?.id}
              >
                {formField.manufacturerIdInactive?.name}
              </MenuItem>
            ) : null}
            <ActionRestrictionWrapper
              disableHoverListener={
                !repPermissions || repPermissions?.catalog?.manufacturers
              }
              title={
                repPermissions?.role === "SALES"
                  ? ADMIN_ONLY_VIEW_MESSAGE
                  : ADMIN_ONLY_VIEW_MESSAGE_PAGE
              }
            >
              <MenuItem
                disabled={
                  !!repPermissions && !repPermissions?.catalog?.manufacturers
                }
                onClick={() => setIsOpenNewManDialog(true)}
              >
                <OutlinedPlusIcon />
                <Typography color="#42A57F" fontSize="12px" ml="5px">
                  Add manufacturer
                </Typography>
              </MenuItem>
            </ActionRestrictionWrapper>
            {manufacturers.map((man) => (
              <MenuItem
                onClick={() => setValue("manufacturerName", man.name)}
                key={man.id}
                value={man.id}
              >
                <Typography
                  fontSize="inherit"
                  fontWeight="inherit"
                  color="inherit"
                  component="span"
                  noWrap
                >
                  {man.name}
                </Typography>
              </MenuItem>
            ))}
          </StyledSelect>
        )}
        name="manufacturerId"
        control={control}
      />
    </Grid>
  );
};

ManufacturerBlock.propTypes = {
  manufacturers: array,
  manufacturersCount: number,
  handleFetchManufacturers: func,
  setCustomersSearchInput: func,
  customersSearchInput: string,
  setIsOpenNewManDialog: func,
  setManufacturersPage: func,
  formField: object,
  setValue: func,
  control: object,
  formSx: object,
  labelSx: object,
  fontSize: string,
  disabled: bool,
};
