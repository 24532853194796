import { bool, string, array, object, oneOfType } from "prop-types";
import { Box } from "@mui/material";
import { StyledTooltip } from "components";

export const OnboardingTooltip = ({
  disableHoverListener,
  ml,
  // eslint-disable-next-line react/prop-types
  children,
  text = "Complete Onboarding to use this feature",
  offset = [0, -6],
}) => {
  return (
    <StyledTooltip
      placement="bottom"
      disableHoverListener={disableHoverListener}
      arrow
      title={text}
      PopperProps={{
        modifiers: [{ name: "offset", options: { offset } }],
      }}
    >
      <Box display="flex" ml={ml}>
        {children}
      </Box>
    </StyledTooltip>
  );
};

OnboardingTooltip.propTypes = {
  disableHoverListener: bool,
  ml: string,
  offset: array,
  text: oneOfType([string, object]),
};
