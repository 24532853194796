import { string, func } from "prop-types";
import { NavLink, useNavigate } from "react-router-dom";
import { IosArrowForward } from "components/Icons";

import { Breadcrumbs, Link, Typography } from "@mui/material";
import { truncateText } from "helpers/helpers";

export const BreadCrumbsBlock = ({ title = "", preventNavigate }) => {
  const navigate = useNavigate();

  const breadcrumbs = [
    <Link
      key="1"
      underline="none"
      color="#409A65"
      fontSize="12px"
      sx={{ cursor: "pointer" }}
      to="/tasks"
      component={NavLink}
      onClick={(e) => {
        e.preventDefault();
        preventNavigate({
          onClose: () => navigate("/tasks"),
        });
      }}
    >
      Tasks
    </Link>,
    <Link
      key="2"
      underline="none"
      color="#409A65"
      fontSize="12px"
      sx={{ cursor: "pointer" }}
      to="/tasks"
      state={{ tab: "Forms" }}
      component={NavLink}
      onClick={(e) => {
        e.preventDefault();
        preventNavigate({
          onClose: () => navigate("/tasks", { state: { tab: "Forms" } }),
        });
      }}
    >
      Forms
    </Link>,
    <Typography
      key="3"
      underline="none"
      color="#A5A5A5"
      fontSize="12px"
      lineHeight="10px"
      mt="3px"
    >
      {title ? truncateText(title, 50) : "New Form"}
    </Typography>,
  ];
  return (
    <Breadcrumbs
      sx={{ "& .MuiBreadcrumbs-separator": { mt: "3px" } }}
      separator={<IosArrowForward width={4.75} height={8} />}
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
};
BreadCrumbsBlock.propTypes = { title: string, preventNavigate: func };
