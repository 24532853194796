import {
  ContentBlock,
  PageHeader,
  QuestionDeleteDialog,
  QuestionsPreview,
} from "./components";

import { cl } from "./FormViewPage.styles";

import { Stack } from "@mui/material";
import { createContext } from "react";
import { useFormViewPage } from "./useFormViewPage";
import { useParams } from "react-router-dom";
import { useFormFields } from "./useFormFields";
import { Loader } from "components";
import { useResponsesTab } from "./useResponsesTab";
import { useVisibilitySettingsTab } from "./useVisibilitySettingsTab";
import { useDialogPreviewContent } from "./components/ContentBlock/components/ResponsesDetails/components/DialogPreviewContent/useDialogPreviewContent";
import { useDialogPreviewPhotos } from "./components/ContentBlock/components/ResponsesDetails/components/DialogPreviewPhotos/useDialogPreviewPhotos";
import { useFormViewResponsesPage } from "./useFormViewResponsesPage";
import { useSelector } from "react-redux";

export const FormViewContext = createContext();

const FormViewPage = () => {
  const currentUser = useSelector(({ auth }) => auth?.currentUser);

  const { id: formId } = useParams();

  const formFieldsData = useFormFields();

  const { reset, formField, handleSubmit, setValue, isDirty } = formFieldsData;

  const formState = useFormViewPage({
    formId,
    reset,
    formField,
    handleSubmit,
    setValue,
    isDirty,
  });

  const responsesTabState = useResponsesTab();
  const visibilitySettingsState = useVisibilitySettingsTab(
    formId,
    setValue,
    formField
  );

  const responsesState = useFormViewResponsesPage({
    open: formState?.currentTab === 1,
    formId,
    params: { is_completed: true },
    // limit_responses: 10,
  });

  const dialogPreviewContentData = useDialogPreviewContent();
  const dialogPreviewPhotosData = useDialogPreviewPhotos();

  return (
    <FormViewContext.Provider
      value={{
        currentUser,
        ...formState,

        ...formFieldsData,

        formId,

        ...responsesTabState,
        ...visibilitySettingsState,

        dialogPreviewContentData,
        dialogPreviewPhotosData,
        responsesState,
      }}
    >
      <QuestionDeleteDialog
        open={formState.questionDeleteState.open}
        handleClose={formState.handleCloseQuestionDeleteDialog}
        handleSubmit={formState.handleDeleteQuestion}
      />
      <PageHeader />
      <Stack
        direction="row"
        sx={{
          ...cl.contentWrapper,
          ...(formState?.currentTab !== 0 && { mr: "-10px" }),
        }}
      >
        {formState?.loading ? <Loader isLoading={formState?.loading} /> : null}
        {formState?.currentTab === 0 && <QuestionsPreview />}
        <ContentBlock />
      </Stack>
    </FormViewContext.Provider>
  );
};

export default FormViewPage;
