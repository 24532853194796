import { useState } from "react";
import { useDispatch } from "react-redux";
import { bulkUpdateOrdersAction } from "redux/actions/orders";
import {
  bulkPackingSlipOrderReportService,
  getOrdersBulkPackingSlipPDF,
  getOrdersBulkReportPDF,
} from "services/orders";
import { error } from "utils/notifications";

export const usePrintOrders = ({
  checkedOrderIds,
  fetchCallback,
  successFetchCallback,
}) => {
  const [printLoading, setPrintLoading] = useState({
    loading: false,
    progress: 0,
    downloading: false,
  });
  
  const dispatch = useDispatch();

  const [printPickListDialog, setPrintPickListDialog] = useState(false);

  const handlePrintOrders = (includePrices = true, checkedOrder, callback) => {
    setPrintLoading((prev) => ({ ...prev, loading: true }));
    getOrdersBulkReportPDF({
      ordersIds: checkedOrder?.length ? checkedOrder : checkedOrderIds,
      includePrices,
      onDownloadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setPrintLoading((prev) => ({ ...prev, progress, downloading: true }));
      },
    })
      .then((res) => {
        setTimeout(() => {
          setPrintLoading((prev) => ({
            ...prev,
            loading: false,
            progress: 0,
            downloading: false,
          }));
          dispatch(
            bulkUpdateOrdersAction(
              checkedOrder?.length ? checkedOrder : checkedOrderIds,
              {
                lastPdfsPrinted: [
                  {
                    type: "INVOICE_PRINTED",
                    createdAt: new Date().toISOString(),
                  },
                ],
              }
            )
          );
          !!successFetchCallback && successFetchCallback(res);
          !!callback && callback();
        }, 1000);
      })
      .catch((err) => {
        error(err?.response?.data?.message || err?.message);
        setPrintLoading((prev) => ({
          ...prev,
          loading: false,
          progress: 0,
          downloading: false,
        }));
      });
  };

  const handlePrintPackingSlips = () => {
    setPrintLoading((prev) => ({ ...prev, loading: true }));
    getOrdersBulkPackingSlipPDF({
      ordersIds: checkedOrderIds,
      onDownloadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setPrintLoading((prev) => ({ ...prev, progress, downloading: true }));
      },
    })
      .then((res) => {
        setTimeout(() => {
          setPrintLoading((prev) => ({
            ...prev,
            loading: false,
            progress: 0,
            downloading: false,
          }));
          dispatch(
            bulkUpdateOrdersAction(checkedOrderIds, {
              lastPdfsPrinted: [
                {
                  type: "PACKING_SLIP_PRINTED",
                  createdAt: new Date().toISOString(),
                },
              ],
            })
          );

          !!successFetchCallback && successFetchCallback(res);
        }, 1000);
      })
      .catch((err) => {
        error(err?.response?.data?.message || err?.message);
        setPrintLoading((prev) => ({
          ...prev,
          loading: false,
          progress: 0,
          downloading: false,
        }));
      });
  };

  const fetchBulkPackingSlipOrderReport = (data) => {
    setPrintLoading((prev) => ({ ...prev, loading: true }));
    bulkPackingSlipOrderReportService({
      data,
      onDownloadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setPrintLoading((prev) => ({ ...prev, progress, downloading: true }));
      },
    })
      .then((res) => {
        setTimeout(() => {
          setPrintLoading((prev) => ({
            ...prev,
            loading: false,
            progress: 0,
            downloading: false,
          }));
          !!successFetchCallback && successFetchCallback(res);
        }, 1000);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
        setPrintLoading((prev) => ({
          ...prev,
          loading: false,
          progress: 0,
          downloading: false,
        }));
      });
  };

  const fetchPrintPickList = ({
    isCheckedGroupOrders = false,
    checkedOrdersIds,
  }) => {
    if (!checkedOrdersIds || checkedOrdersIds?.length < 1) return;

    fetchBulkPackingSlipOrderReport({
      ordersIds: checkedOrdersIds,
      groupOrders: isCheckedGroupOrders,
    });

    !!fetchCallback && fetchCallback();
  };

  const handlePrintPickList = () => {
    const hasMoreThenOne = checkedOrderIds?.length > 1;
    if (hasMoreThenOne) return setPrintPickListDialog(true);

    fetchPrintPickList({ checkedOrdersIds: checkedOrderIds });
    dispatch(
      bulkUpdateOrdersAction(checkedOrderIds, {
        lastPdfsPrinted: [
          {
            type: "PICKLIST_PRINTED",
            createdAt: new Date().toISOString(),
          },
        ],
      })
    );
  };

  return {
    printPickListDialog,
    handlePrintPickList,
    setPrintPickListDialog,
    fetchPrintPickList,
    printLoading,
    setPrintLoading,
    handlePrintOrders,
    handlePrintPackingSlips,
  };
};
