import { func } from "prop-types";
import { DialogTitle, IconButton, Stack } from "@mui/material";
import { CrossIcon } from "components/Icons";

export const CreatePriceListHeader = ({ onClose }) => {
  return (
    <Stack sx={{ borderBottom: "0.5px solid #CCCCCC" }}>
      <DialogTitle
        sx={{
          fontSize: "16px",
          color: "#3F3F3F",
          fontWeight: 400,
          p: "12px 28px 10px 28px",
          position: "relative",
        }}
      >
        Add price list
      </DialogTitle>
      <IconButton
        sx={{ position: "absolute", right: "6px", top: "6px" }}
        onClick={onClose}
      >
        <CrossIcon />
      </IconButton>
    </Stack>
  );
};

CreatePriceListHeader.propTypes = {
  onClose: func,
};
CreatePriceListHeader.defaultProps = {
  onClose: () => {},
};

//import { func } from "prop-types";
//import { DialogTitle, IconButton, Stack } from "@mui/material";
//import { CrossIcon } from "components/Icons";

//export const CreatePriceListHeader = ({ onClose }) => {
//  return (
//    <Stack>
//      <DialogTitle
//        sx={{
//          fontSize: "16px",
//          color: "#3F3F3F",
//          fontWeight: 400,
//          p: "14px 24px 0px 24px",
//          position: "relative",
//        }}
//      >
//        Add price list
//      </DialogTitle>
//      <IconButton
//        sx={{ position: "absolute", right: "11px", top: "6px" }}
//        onClick={onClose}
//      >
//        <CrossIcon />
//      </IconButton>
//    </Stack>
//  );
//};

//CreatePriceListHeader.propTypes = {
//  onClose: func,
//};
//CreatePriceListHeader.defaultProps = {
//  onClose: () => {},
//};
