export const cl = {
  nameWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "84%",
  },

  productName: {
    cursor: "pointer",
    textDecoration: "none",
    fontSize: "12px",
    fontWeight: 600,
    color: "#1C1C19",
    width: "100%",
  },

  productNameVariation: {
    fontSize: 12,
    fontWeight: 400,
    color: "#1C1C19",
  },

  link: {
    fontSize: "10px",
    fontWeight: 400,
    color: "#1C1C19",
    display: "flex",
  },

  productNameBlock: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },

  emptyProduct: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
    borderRadius: "4px",
  },

  productPhoto: {
    maxWidth: "38px",
    minWidth: "38px",
    height: "38px",
    maxHeight: "38px",
    backgroundColor: "white",
    border: "1px solid #D5D9D9",
    borderRadius: "4px",
  },

  moqInput: {
    fontSize: "12px",
    borderRadius: "6px",
    borderWidth: "0.5px !important",

    "& input": {
      padding: "6px 8px",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "& input[type=number]": {
      MozAppearance: "textfield",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "0.5px solid #D5D9D9",
    },
    width: "122px",
    pr: "0.5px",
    position: "relative",
  },

  messageBtn: {
    position: "absolute",
    left: "-36px",
    top: "2px",
    cursor: "auto !important",
    //transition: "0.3s all",
  },

  toggleBtn: {
    width: "24px",
    height: "24px",
    borderRadius: "4px",
    m: "2px 2px 2px 0px",
    color: "black",
  },
};
