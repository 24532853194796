import { useContext, useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import { handleGetIcons } from "Pages/FormViewPage/FormViewPage.constants";
import { CardBlockWrapper } from "../index";
import {
  AddressBlock,
  BottomBlock,
  DateBlock,
  MultipleBlock,
  NameBlock,
  NoResponsesBlock,
  NumericBlock,
  PhotoBlock,
  SingleBlock,
  TextBlock,
  TitleBlock,
  YesNoBlock,
} from "./components";
import { ScrollControlWrapper } from "components";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";

export const ContentBlocks = () => {
  const { responsesState, currentUser } = useContext(FormViewContext);
  const { data } = responsesState;

  const FORM_ICONS = handleGetIcons({
    singleProps: {
      firstRectFill: "#409A65",
      secondRectStroke: "#fff",
      lastRectFill: "#fff",
      strokeOpacity: 1,
    },
    multiProps: {
      rectFill: "#47A06E",
      width: 21,
      height: 21,
    },
  });

  const answersBlock = ({ type, response, questions, question }) => {
    switch (type) {
      case "TEXT":
        return <TextBlock text={response?.answer} />;
      case "NUMERIC":
        return <NumericBlock number={response?.answer} />;
      case "YES_NO": {
        const data = [
          { name: "Yes", value: +question?.answersPercent?.Yes || 0 },
          { name: "No", value: +question?.answersPercent?.No || 0 },
        ];
        return (
          <YesNoBlock questions={questions} question={question} data={data} />
        );
      }
      case "SINGLE_ANSWER":
        return <SingleBlock questions={questions} question={question} />;
      case "MULTIPLE_ANSWER":
        return <MultipleBlock questions={questions} question={question} />;
      case "PHOTO":
        return <PhotoBlock response={response} />;
      case "DATE":
        return (
          <DateBlock
            date={response?.answerDate}
            timeZone={currentUser?.timeZone}
          />
        );
      // case "BARCODE":
      //   return <TextBlock text={response?.answer} />;
      // case "PRODUCT":
      //   return <TextBlock text={response?.answer} />;
      default:
        return <TextBlock text={response?.answer} />;
    }
  };

  const renderQuestion = ({ list, question }) => {
    const questionType = question?.type; // "TEXT" ...

    return ["TEXT", "NUMERIC", "PHOTO", "DATE"].includes(questionType)
      ? list?.map((q) => (
          <Stack key={q?.id} mt="16px">
            <NameBlock
              name={q?.progress?.representative?.name}
              date={q?.updatedAt}
              timeZone={currentUser?.timeZone}
              autofilled={q?.autofilled}
            />

            {answersBlock({ type: questionType, response: q, question })}

            <AddressBlock
              place={q?.progress?.customer?.displayedName}
              address={q?.progress?.customer?.billingAddress?.formatted_address}
              wrapProps={{
                ...(["PHOTO"].includes(questionType) && { mt: "0px" }),
              }}
            />
          </Stack>
        ))
      : answersBlock({
          type: questionType,
          questions: question?.responses,
          question,
        });
  };

  const titleBlockRef = useRef({});
  const bottomBlockRef = useRef({});

  const [titleBlockHeight, setTitleBlockHeight] = useState({});
  const [bottomBlockHeight, setBottomBlockHeight] = useState({});

  useEffect(() => {
    if (titleBlockRef.current) {
      setTitleBlockHeight(titleBlockRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleBlockRef.current]);

  useEffect(() => {
    if (bottomBlockRef.current) {
      setBottomBlockHeight(bottomBlockRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bottomBlockRef.current]);

  return (
    <Stack gap="12px">
      {data?.questions?.map((question) => {
        const responsesList = question?.responses;

        const questionType = question?.type;
        const type = question?.deleted
          ? "Deactivated"
          : question?.required
          ? "Required"
          : "Optional";

        return (
          <CardBlockWrapper
            key={question?.id}
            wrapProps={{
              minHeight: "181px",
              maxHeight: "592px",
            }}
          >
            <Box
              sx={{
                p: `24px 24px ${responsesList?.length > 1 ? 12 : 24}px 24px`,
                height: "100%",
              }}
            >
              <Box ref={(el) => (titleBlockRef.current[question?.id] = el)}>
                <TitleBlock
                  icon={FORM_ICONS[questionType]}
                  title={question?.text}
                  type={type}
                  questionType={questionType}
                  hideBorder={responsesList?.length === 0}
                />
              </Box>

              {responsesList?.length ? (
                <Box
                  sx={{
                    height: `calc(100% - ${
                      (titleBlockHeight?.[question?.id]?.clientHeight || 0) +
                      (bottomBlockHeight?.[question?.id]?.clientHeight || 0) -
                      1
                    }px)`,
                  }}
                >
                  <ScrollControlWrapper
                    dataLength={responsesList?.length}
                    id="content-blocks-list"
                    maxHeight="100%"
                  >
                    {renderQuestion({ list: responsesList, question })}
                  </ScrollControlWrapper>
                </Box>
              ) : (
                <NoResponsesBlock />
              )}

              {question?._count?.responses > 1 && (
                <Box ref={(el) => (bottomBlockRef.current[question?.id] = el)}>
                  <BottomBlock
                    name="Response"
                    count={question?._count?.responses || 0}
                    item={question}
                  />
                </Box>
              )}
            </Box>
          </CardBlockWrapper>
        );
      })}
    </Stack>
  );
};
