import { string } from "prop-types";

import { Typography } from "@mui/material";

export const SectionTitle = ({ title }) => {
  return (
    <Typography color="#3F3F3F" fontSize="18px" fontWeight="700" ml="20px">
      {title}
    </Typography>
  );
};

SectionTitle.propTypes = {
  title: string,
};
