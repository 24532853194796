import { useContext, useMemo } from "react";

import { LargeCheckbox, TableHeader } from "components";

import { cl } from "./PriceListHeader.styles";

import { Grid, Typography } from "@mui/material";
import { PriceContext } from "Pages/CatalogPage";

const PriceListHeader = () => {
  const { allPricesChecked, checkAllPrices, selectedPrices } =
    useContext(PriceContext);

  const isIndeterminate = useMemo(
    () =>
      selectedPrices?.length > 0 &&
      selectedPrices?.length !== selectedPrices?.length,
    [selectedPrices]
  );

  const TABLE_DATA = [
    {
      id: 1,
      title: "",
      element: (
        <LargeCheckbox
          formSx={{ m: 0 }}
          checked={allPricesChecked}
          onChange={checkAllPrices}
          sx={cl.checkboxSx}
          onClick={(e) => e.stopPropagation()}
          size={22}
          rectSize={22}
          indeterminate={isIndeterminate}
          indeterminateIcon={27}
          checkedViewBox="0 0 24 24"
        />
      ),
      sx: { pl: "16px" },
      xs: 0.48,
    },
    {
      id: 3,
      title: "TITLE",
      sx: { ...cl.leftHeaderItem },
      xs: 6.56,
    },
    {
      id: 4,
      title: "PRODUCTS",
      sx: { ...cl.leftHeaderItem },
      xs: 1.5,
    },
    {
      id: 5,
      title: "CUSTOMERS",
      sx: { ...cl.leftHeaderItem },
      xs: 1.5,
    },
    {
      id: 6,
      title: "OVERALL ADJUSTMENT",
      sx: { ...cl.leftHeaderItem },
      xs: 1.5,
    },
  ];

  return (
    <TableHeader sx={cl.tableHeaderSx} columns={12}>
      {TABLE_DATA.map(({ id, sx, title, element, xs }) => (
        <Grid sx={sx} key={id} item xs={xs}>
          {title && (
            <Typography fontSize={12} color="#6A6A6A" mr={1}>
              {title}
            </Typography>
          )}
          {element && element}
        </Grid>
      ))}
    </TableHeader>
  );
};

export default PriceListHeader;
