import {
  ActionRestrictionWrapper,
  StyledButton,
  StyledTextField,
} from "components";
import { func, bool, any, object } from "prop-types";

import { cl } from "./styles";

import { Menu, Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useRepsPermissions } from "helpers/hooks";

const EditPriceName = ({
  anchorEl,
  isOpen,
  price,
  handleClose,
  onDelete = () => {},
  onSave = () => {},
}) => {
  const repPermissions = useRepsPermissions();
  const [priceName, setPriceName] = useState(price?.name);

  useEffect(() => {
    if (!isOpen) return;
    setPriceName(price?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onChangeEditPrice = (e) => setPriceName(e.target.value);

  const onCancelEdit = (e) => {
    setPriceName(price?.name);
    handleClose(e);
  };

  const disabledDeletePriceList = useMemo(() => {
    return repPermissions
      ? !repPermissions?.catalog?.delete_price_lists
      : false;
  }, [repPermissions]);

  return (
    <Menu
      id="basic-menu"
      sx={{
        ".MuiPopover-paper": {
          boxShadow: "0px 3px 6px rgba(0, 3, 6, 0.16)",
          border: "0.5px solid #D5D9D9",
          borderRadius: "8px",
        },
      }}
      MenuListProps={{
        "aria-labelledby": "basic-button",
        sx: {
          width: "240px",
          maxWidth: "320px",
          p: "10px",
        },
      }}
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      onClick={(e) => e.stopPropagation()}
    >
      <Stack gap="12px">
        <Typography sx={cl.title} color="#1C1C19" noWrap>
          Edit &apos;{price?.displayName || price?.name}&apos; price list
        </Typography>

        <StyledTextField
          name="priceName"
          fullWidth
          labelSx={cl.inputLabel}
          height="32px"
          sx={{
            "& .MuiOutlinedInput-input": {
              p: "8px",
            },
          }}
          inputProps={{
            placeholder: "Price list name",
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          value={priceName}
          onChange={onChangeEditPrice}
        />

        <Stack direction="row" gap="8px">
          <ActionRestrictionWrapper
            disableHoverListener={!disabledDeletePriceList}
          >
            <StyledButton
              disableRipple
              disabled={disabledDeletePriceList}
              onClick={(e) => {
                handleClose(e);
                onDelete(price.id, price.name);
              }}
              sx={cl.deleteBtn}
              variant="outlined"
              label="Delete"
              fontWeight="500"
            />
          </ActionRestrictionWrapper>
          <StyledButton
            disableRipple
            sx={cl.editBtn}
            onClick={(e) => {
              onCancelEdit(e);
              handleClose(e);
            }}
            variant="outlined"
            label="Cancel"
            fontWeight="500"
          />
          <StyledButton
            disableRipple
            disabled={!priceName || priceName === price?.name}
            sx={cl.saveBtn}
            variant="contained"
            label="Save"
            fontWeight="500"
            onClick={(e) => {
              handleClose(e);
              onSave(price.id, priceName);
            }}
          />
        </Stack>
      </Stack>
    </Menu>
  );
};

export default EditPriceName;

EditPriceName.propTypes = {
  price: object,
  anchorEl: any,
  isOpen: bool,
  handleClose: func,
  onDelete: func,
  onSave: func,
};
