import { Stack, Typography } from "@mui/material";
import moment from "moment-timezone";
import { string } from "prop-types";

export const DateBlock = ({ date = "", timeZone = "" }) => {
  return (
    <Stack flexDirection="row" gap="4px">
      <Typography fontSize={13} fontWeight={400} color="#5F6267" noWrap>
        Replied: {moment(date).tz(timeZone).format("MM/D/YYYY")}
      </Typography>
    </Stack>
  );
};

DateBlock.propTypes = { date: string, timeZone: string };
