import { useMemo } from "react";
import { bool, func, string, number, object } from "prop-types";
import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import { StyledButton, StyledTooltip } from "components";
import { ButtonTagIcon, CrossIcon } from "components/Icons";
import useStyles from "Pages/CartPage/styles";
import ApplyDiscountPopup from "../ApplyDiscountPopup/ApplyDiscountPopup";
import TotalDiscountPopup from "../TotalDiscountPopup/TotalDiscountPopup";
import { getProductsDictionary } from "Pages/DiscountsPage/components/ApplyDiscountPage/components/MSDPage/MSDPage.helpers";
import { countMSDs } from "./ThirdCustomerStep.helpers";
import { DiscountCount, PriceListLabel } from "./components";

export const ThirdCustomerStep = ({
  formField,
  customerExist,
  isAdmin,
  updateDiscountDisabled,
  handleAddDiscount,
  applyDiscountOpen,
  setApplyDiscountOpen,
  handleApplyDiscount,
  cartState,
  deliveryStatus,
  paymentStatus,
  totalDiscountOpen,
  setTotalDiscountOpen,
  handleApplyTotalDiscount,
  calculatedTotal,
  hasQuickBooks,
  customerDiscount,
  totalCustomerDiscount,
  handleDiscountsBlock,
  isPayed,
  isDuplicate,
  priceListLoading,
  priceListName,
}) => {
  const classes = useStyles();

  const {
    customer,
    products,
    manufacturerDiscounts,
    discount,
    totalOrderDiscountAmount,
    totalOrderDiscountType,
  } = formField || {};

  const prodDict = getProductsDictionary(
    products,
    cartState.availableMSDs?.map((dis) => dis.discounts).flat(),
    isPayed && !isDuplicate
  );
  const availableMSDCount = useMemo(
    () => countMSDs(cartState, customerExist, formField, prodDict),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cartState.availableMSDs, manufacturerDiscounts]
  );

  const tooltipTitle = useMemo(() => {
    if (!products?.length && !customer)
      return "Please add product and customer to proceed";

    if (!products?.length && customer) return "Please add product to proceed";

    if (products?.length && !customer) return "Please add customer to proceed";

    return "";
  }, [products, customer]);

  return (
    <Paper
      className={classes.section}
      sx={{
        mt: "16px",
        pt: "17px",
        position: "relative",
        minHeight: "92px",
        width: "100%",
      }}
    >
      <Box display="inline-flex">
        <Typography
          sx={{
            color: "#47A06D",
            textTransform: "uppercase",
            fontSize: "14px",
            fontWeight: 400,
            position: "relative",
            left: "20px",
          }}
        >
          Step 3{" "}
          <span
            style={{
              fontWeight: 400,
              color: "#9C9C94",
              textTransform: "none",
            }}
          >
            (optional)
          </span>
        </Typography>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: discount ? 19 : 12,
          right: discount ? 19 : 8,
          zIndex: 1,
        }}
      >
        <StyledTooltip
          title={tooltipTitle}
          arrow
          PopperProps={{
            modifiers: [{ name: "offset", options: { offset: [0, -5] } }],
          }}
          disableHoverListener={!!customerExist && products?.length > 0}
          placement="top"
        >
          <Box>
            {discount ? (
              <StyledButton
                label={"Update"}
                variant="outlined"
                disabled={isAdmin || updateDiscountDisabled()}
                color="greyBtn"
                sx={{ color: "#6A6A6A" }}
                onClick={handleAddDiscount}
                startIcon={<ButtonTagIcon />}
              />
            ) : (
              <IconButton
                disabled={
                  !products?.length ||
                  !customer ||
                  isAdmin ||
                  updateDiscountDisabled()
                }
                onClick={handleAddDiscount}
                sx={{ transform: "rotate(135deg)" }}
              >
                <CrossIcon />
              </IconButton>
            )}
          </Box>
        </StyledTooltip>
      </Box>
      <ApplyDiscountPopup
        isOpen={applyDiscountOpen}
        handleClose={() => setApplyDiscountOpen(false)}
        handleApplyDiscount={handleApplyDiscount}
        isDisablemsdBtn={!cartState.availableMSDCount}
        deliveryStatus={deliveryStatus}
        paymentStatus={paymentStatus}
      />
      <TotalDiscountPopup
        isOpen={totalDiscountOpen}
        handleClose={() => setTotalDiscountOpen(false)}
        handleApply={handleApplyTotalDiscount}
        amount={totalOrderDiscountAmount}
        type={totalOrderDiscountType}
        orderAmountWithoutCustomerDiscount={
          calculatedTotal - Number(totalCustomerDiscount)
        }
      />
      <Stack direction="row" gap="10px">
        <Typography
          color="#707070"
          fontWeight={600}
          fontSize="clamp(15px, 1.3vw, 25px)"
          position="relative"
          left="20px"
        >
          Discount
        </Typography>

        {!!availableMSDCount && !!products.length && customerExist && (
          <DiscountCount
            {...{ availableMSDCount, handleApplyDiscount, deliveryStatus }}
          />
        )}
      </Stack>

      {customer?.id && (
        <Box p="12px 20px">
          <PriceListLabel
            priceListName={priceListName}
            loading={priceListLoading}
          />
        </Box>
      )}

      {customer?.id &&
      !hasQuickBooks &&
      (customer?.percentDiscount || customerDiscount) ? (
        <Box className={classes.customerDiscountRow}>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#707070",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              position: "relative",
              left: "20px",
            }}
          >
            Customer discount | {customer?.percentDiscount || customerDiscount}%
          </Typography>
          <Typography className={classes.discountTotal}>
            -${totalCustomerDiscount}
          </Typography>
        </Box>
      ) : (
        ""
      )}
      {discount ? handleDiscountsBlock(discount?.type) : ""}
    </Paper>
  );
};

ThirdCustomerStep.propTypes = {
  customerExist: string,
  isAdmin: bool,
  updateDiscountDisabled: func,
  handleAddDiscount: func,
  applyDiscountOpen: bool,
  setApplyDiscountOpen: func,
  handleApplyDiscount: func,
  cartState: object,
  totalDiscountOpen: bool,
  setTotalDiscountOpen: func,
  priceListLoading: bool,
  handleApplyTotalDiscount: func,
  calculatedTotal: number,
  hasQuickBooks: bool,
  customerDiscount: number,
  totalCustomerDiscount: string,
  handleDiscountsBlock: func,
  formField: object,
  deliveryStatus: string,
  paymentStatus: string,
  priceListName: string,
  isPayed: bool,
  isDuplicate: bool,
};
