import { useMemo } from "react";
import { func, object, array, oneOf, bool } from "prop-types";
import pluralize from "pluralize";
import { Grid, IconButton, Paper, Typography, Box } from "@mui/material";

import { ActionRestrictionWrapper, StyledButton } from "components";
import { CartTrashIcon, TagIcon } from "components/Icons";
import { useAdmin } from "helpers/helpers";

export const TagItem = ({
  tag,
  tagsList,
  handleDelete,
  handleRename,
  handleMerge,
  type,
  repPermissions,
  showGalleryCount,
}) => {
  const isAdmin = useAdmin();

  const disabledCreateEdit = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.catalog?.create_edit;
    }
  }, [repPermissions]);

  const calcCount = useMemo(() => {
    let count = 0;
    if (type === "product") count = tag?._count?.products;
    if (type === "customer") count = tag?._count?.customers;
    if (type === "order") count = tag?._count?.orders;
    return pluralize("time", count, true);
  }, [
    tag?._count?.customers,
    tag?._count?.orders,
    tag?._count?.products,
    type,
  ]);

  return (
    <Paper
      key={tag?.id}
      sx={{
        alignItems: "center",
        borderStyle: "solid",
        borderColor: "#D5D9D9",
        minHeight: "48px",
        borderWidth:
          tagsList.length > 4 && tagsList[tagsList.length - 1].id === tag?.id
            ? "0 0 0 0"
            : "0 0 1px 0",
        "&:last-of-type": {
          borderBottom: "none",
        },
      }}
      elevation={0}
      component={Grid}
      container
      square
    >
      {/* Empty block */}
      <Grid item xs={0.6} />

      {/* Tag name */}
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        item
        xs={3.3}
      >
        <Box
          sx={{
            maxWidth: "250px",
            border: "1px solid #D4D4D4",
            borderRadius: "12px",
            display: "flex",
            alignItems: "center",
            py: "4px",
            px: "10px",
          }}
        >
          <Box width="16px" display="flex">
            <TagIcon />
          </Box>
          <Typography
            sx={{
              marginLeft: "6px",
              fontSize: "13px",
              fontWeight: 400,
              color: "#5F6267",
            }}
            noWrap
          >
            {tag?.tag}
          </Typography>
        </Box>
      </Grid>

      {/* Used in products */}
      <Grid
        sx={{
          fontSize: "16px",
          color: "#6A6A6A",
          display: "flex",
          gap: "30px",
        }}
        xs={1.7}
        item
      >
        {calcCount}
      </Grid>

      {/* Used in gallery */}
      {showGalleryCount ? (
        <Grid
          sx={{
            fontSize: "16px",
            color: "#6A6A6A",
            display: "flex",
            gap: "30px",
          }}
          xs={1.7}
          item
        >
          {!isNaN(tag?._count?.photos) &&
          typeof tag?._count?.photos === "number"
            ? pluralize("time", tag?._count?.photos, true)
            : "-"}
        </Grid>
      ) : null}

      {/* Actions */}
      <Grid
        item
        xs
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          pr: "8px",
          gap: "30px",
        }}
      >
        <ActionRestrictionWrapper disableHoverListener={!disabledCreateEdit}>
          <StyledButton
            disabled={disabledCreateEdit || isAdmin}
            label="Rename"
            fontSize="16px"
            color="cancel"
            onClick={() => handleRename(tag)}
          />
        </ActionRestrictionWrapper>
        <ActionRestrictionWrapper disableHoverListener={!disabledCreateEdit}>
          <StyledButton
            disabled={disabledCreateEdit || isAdmin}
            label="Merge"
            fontSize="16px"
            onClick={() => handleMerge(tag)}
          />
        </ActionRestrictionWrapper>
        <ActionRestrictionWrapper disableHoverListener={!disabledCreateEdit}>
          <IconButton
            disabled={disabledCreateEdit || isAdmin}
            onClick={() => handleDelete(tag)}
          >
            <CartTrashIcon />
          </IconButton>
        </ActionRestrictionWrapper>
      </Grid>
    </Paper>
  );
};

TagItem.propTypes = {
  tag: object,
  tagsList: array,
  handleDelete: func,
  handleRename: func,
  handleMerge: func,
  type: oneOf(["product", "customer", "order"]),
  repPermissions: object,
  showGalleryCount: bool,
};
TagItem.defaultProps = {
  tag: null,
  handleDelete: () => {},
  handleRename: () => {},
  handleMerge: () => {},
  showGalleryCount: false,
};

export default TagItem;
