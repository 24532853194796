import { func, object, bool } from "prop-types";

import { CartTrashIcon } from "components/Icons";

import { ActionRestrictionWrapper, LargeCheckbox } from "components";
import { Grid, IconButton, Typography } from "@mui/material";
import { useMemo, useState } from "react";

export const CustomerItem = ({
  lastItem,
  customer,
  onCustomerDelete,
  handleCheckCustomer,
  checkForCustomerSelection,
  repPermissions,
}) => {
  const { name, id, customId, territory, displayedName } = customer || {};
  const [isItemHovered, setIsItemHovered] = useState(false);

  const isChecked = checkForCustomerSelection(id);

  const address = useMemo(
    () =>
      customer?.shippingAddress?.formatted_address ||
      customer?.billingAddress?.formatted_address,
    [
      customer?.billingAddress?.formatted_address,
      customer?.shippingAddress?.formatted_address,
    ]
  );

  const disabledEditPriceList = useMemo(() => {
    return repPermissions
      ? !repPermissions?.catalog?.create_edit_price_lists
      : false;
  }, [repPermissions]);

  return (
    <>
      <Grid
        onMouseEnter={() => setIsItemHovered(true)}
        onMouseLeave={() => setIsItemHovered(false)}
        sx={{
          backgroundColor: isChecked ? "#ECF5F0" : "#ffff",
          alignItems: "center",
          height: "48px",
          overflow: "hidden",
          borderRadius: "0 0 4px 4px",

          "&:hover": {
            backgroundColor: isChecked ? "#ECF5F0" : "#F7F7F7",
          },

          ...(!lastItem && {
            borderBottom: "0.5px solid #D5D9D9",
            borderRadius: "0px",
          }),
        }}
        container
      >
        <Grid sx={{ pl: "15.5px" }} xs={0.56} item>
          <LargeCheckbox
            checked={isChecked}
            sx={{ padding: 0 }}
            formSx={{ m: 0 }}
            size={22}
            rectSize={22}
            onChange={() => handleCheckCustomer(customer)}
            onClick={(e) => e.stopPropagation()}
            checkedViewBox="0 0 24 24"
          />
        </Grid>

        <Grid pr={1} xs={0.7} item>
          <Typography fontSize={12} fontWeight={400} color="#1C1C19" noWrap>
            {customId?.customId || "-"}
          </Typography>
        </Grid>

        <Grid
          xs={4}
          item
          pr={1}
          container
          flexDirection="column"
          sx={{ overflow: "hidden" }}
        >
          <Typography
            fontSize={12}
            fontWeight={400}
            color="#1C1C19"
            noWrap
            width="100%"
          >
            {displayedName || name}
          </Typography>
          <Typography
            fontSize={10}
            fontWeight={300}
            color="#1C1C19"
            noWrap
            width="100%"
          >
            {address}
          </Typography>
        </Grid>

        <Grid xs={isItemHovered ? 6.44 : 6.69} item pr={1}>
          <Typography fontSize={12} fontWeight={400} color="#1C1C19" noWrap>
            {territory?.name || "Uncategorized"}
          </Typography>
        </Grid>

        <Grid xs={isItemHovered ? 0.25 : 0} item>
          <ActionRestrictionWrapper
            disableHoverListener={!disabledEditPriceList}
            placement="left"
          >
            <IconButton
              disabled={disabledEditPriceList}
              onClick={() => onCustomerDelete(customer)}
              sx={{
                p: 0,
                opacity: isItemHovered ? 1 : 0,
                display: isItemHovered ? "flex" : "none",
                transition: "all 0.2s",
              }}
            >
              <CartTrashIcon width="15" height="17" />
            </IconButton>
          </ActionRestrictionWrapper>
        </Grid>
      </Grid>
    </>
  );
};

CustomerItem.propTypes = {
  lastItem: bool,
  customer: object,
  handleCheckCustomer: func,
  onCustomerDelete: func,
  checkForCustomerSelection: func,
  repPermissions: object,
};
CustomerItem.defaultProps = {
  customer: {},
  handleCheckCustomer: () => {},
  checkForCustomerSelection: () => {},
  onCustomerDelete: () => {},
};
