import { useCallback, useEffect, useRef, useState } from "react";
import { isEqual } from "lodash";
import { handleError } from "helpers/helpers";
import { getFormsService } from "services/forms";
import {
  INIT_FORMS_FILTER,
  INIT_FORMS_STATE,
  SHOW_WARNING_MSG_TIMES,
  WARNING_MSG,
} from "./AddFormsComponent.constants";
import { warning } from "utils/notifications";

export const useAddFormsComponent = ({
  checkedItemsLimit = 5,
  editCheckedForms = [],
  handleSetCheckedFormsIds = () => {},
}) => {
  const [formsState, setFormsState] = useState(INIT_FORMS_STATE);
  const [formsFilter, setFormsFilter] = useState(INIT_FORMS_FILTER);
  const [checkedForms, setCheckedForms] = useState([]);
  const [showWarnMsg, setShowWarnMsg] = useState(SHOW_WARNING_MSG_TIMES);

  const [anchorMenuEl, setAnchorMenuEl] = useState(null);

  const handleOpenAddForms = (event) => setAnchorMenuEl(event.currentTarget);

  const handleCloseAddForms = () => setAnchorMenuEl(null);

  const handleSetCheckedForms = useCallback(
    (form) => {
      const foundIndex = checkedForms.findIndex(
        (checkedForm) => checkedForm?.id === form.id
      );

      if (foundIndex > -1) {
        const newForms = [...checkedForms];
        newForms.splice(foundIndex, 1);
        return setCheckedForms([...newForms]);
      }

      if (showWarnMsg && checkedForms?.length + 1 >= checkedItemsLimit) {
        warning(WARNING_MSG);
        setShowWarnMsg((prev) => prev - 1);
      }

      setCheckedForms([...checkedForms, form]);
    },
    [checkedForms, checkedItemsLimit, showWarnMsg]
  );

  const fetchFormsList = useCallback(async (params, signal) => {
    try {
      setFormsState((prev) => ({ ...prev, loadingFormsList: true }));

      const { count, rows } = await getFormsService(params, signal);

      const formsList = Array.isArray(rows) ? rows : [];

      setFormsState((prev) => ({
        ...prev,
        countForms: count || 0,
        formsList:
          params.page > 1 ? [...prev.formsList, ...formsList] : formsList,
      }));
    } catch (error) {
      handleError(error);
    } finally {
      setFormsState((prev) => ({ ...prev, loadingFormsList: false }));
    }
  }, []);

  useEffect(() => {
    if (editCheckedForms?.length > 0) {
      setCheckedForms([...editCheckedForms]);
    }
  }, [editCheckedForms, setCheckedForms]);

  useEffect(() => {
    handleSetCheckedFormsIds(checkedForms.map((form) => form?.id));
  }, [checkedForms, handleSetCheckedFormsIds]);

  const formsFilterRef = useRef(null);

  useEffect(() => {
    const controller = new AbortController();

    if (!isEqual(formsFilterRef.current, formsFilter)) {
      fetchFormsList(formsFilter, controller.signal);
      formsFilterRef.current = formsFilter;
    }

    return () => controller.abort();
  }, [fetchFormsList, formsFilter]);

  return {
    ...formsState,
    anchorMenuEl,
    handleOpenAddForms,
    handleCloseAddForms,
    formsFilter,
    setFormsFilter,
    checkedForms,
    handleSetCheckedForms,
    fetchFormsList,
    setCheckedForms,
  };
};
