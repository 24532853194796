import { Stack, Typography } from "@mui/material";
import { string } from "prop-types";

export const YesNoDialogBlock = ({ text = "" }) => {
  return (
    <Stack flexDirection="row" gap="4px">
      <Typography fontSize={13} fontWeight={400} color="#5F6267" noWrap>
        Replied: {text}
      </Typography>
    </Stack>
  );
};

YesNoDialogBlock.propTypes = { text: string };
