import { bool, func, string } from "prop-types";
import { Drawer } from "@mui/material";
import { DrawerFooter } from "./components/DrawerFooter";
import { DrawerHeader } from "./components/DrawerHeader";
import { DrawerContent } from "./components/DrawerContent";
import { useContext, useState } from "react";
import { ProductPriceContext } from "Pages/ProductPricePage";
import { bulkUpdateProductsPriceListService } from "services/priceLists";
import { error, success } from "utils/notifications";

export const PriceActionDrawer = ({ open, onClose }) => {
  const {
    selectedProducts,
    isAllowed,
    productsList,
    setSelectedProducts,
    setProductsListState,
  } = useContext(ProductPriceContext);

  const isSingleSelect = selectedProducts.length === 1;

  const selectedProductsFromList =
    isSingleSelect &&
    productsList.filter((product) => product.id === selectedProducts?.[0]?.id);

  const selectedSingleProduct = isSingleSelect && selectedProductsFromList?.[0];

  const [loading, setLoading] = useState(false);
  const [tempDiscountValue, setTempDiscountValue] = useState({
    value:
      isSingleSelect &&
      selectedSingleProduct?.discountValue !== 0 &&
      selectedSingleProduct?.discountValue !== ""
        ? Number(selectedSingleProduct?.discountValue).toFixed(2)
        : "",
    type: isSingleSelect ? selectedSingleProduct?.discountType : "PERCENTAGE",
  });

  const onSave = async () => {
    setLoading(true);
    try {
      const ids = selectedProducts.map((product) => product.id);
      const res = await bulkUpdateProductsPriceListService({
        priceListProductIds: ids,
        discountValue: tempDiscountValue.value
          ? Number(tempDiscountValue.value)
          : 0,
        discountType: tempDiscountValue.type,
      });

      const result = res.reduce((acc, item) => {
        acc[item.id] = item.discountValue;
        return acc;
      }, {});

      const list = [...productsList];
      const selectedList = [...selectedProducts];

      selectedList.forEach((product) => {
        product.discountValue = result[product.id];
        product.discountType = tempDiscountValue.type;
      });

      list.forEach((product) => {
        if (ids.includes(product.id)) {
          product.discountValue = result[product.id];
          product.discountType = tempDiscountValue.type;
        }
      });

      setSelectedProducts(selectedList);
      setProductsListState((prev) => ({ ...prev, list }));

      onClose();
      success(isSingleSelect ? "Discount updated" : "Discounts updated");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: "545px" } }}
    >
      <DrawerHeader titleLabel="Set discount" onClose={onClose} />
      <DrawerContent
        {...{ isAllowed, tempDiscountValue, setTempDiscountValue }}
      />
      <DrawerFooter {...{ onSave, onClose, loading }} />
    </Drawer>
  );
};

PriceActionDrawer.propTypes = {
  open: bool,
  type: string,
  onClose: func,
};
