import { FormsFilter, FormsFooter, FormsList } from "./components";

export const FormsTab = () => {
  return (
    <>
      <FormsFilter />
      <FormsList />
      <FormsFooter />
    </>
  );
};
