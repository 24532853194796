import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
export const YesNoContentBlock = () => {
  const RADIO_VALUES = [{ name: "Yes" }, { name: "No" }];

  return (
    <Box
      sx={{
        mt: 1,
        border: "0.5px solid #E3E3E3",
        borderRadius: "4px",
        bgcolor: "#D5D5D516",
        p: "16px 16px 24px 16px",
      }}
    >
      <Box mb={0.5}>
        <Typography fontSize={14} color="#56565680">
          Answer preview
        </Typography>
      </Box>
      <RadioGroup
        disabled
        sx={{
          ml: "4px",
          "& svg": { color: "#00000016 !important" },
          color: "#5F6267",
          gap: "10px",
          "& .MuiFormControlLabel-root": { ml: 0 },
          "& .MuiTypography-root": { fontSize: "13px", ml: "9px" },
          "& .MuiRadio-root": { p: "0px" },
          "& .MuiSvgIcon-root": { fontSize: 16 },
        }}
      >
        {RADIO_VALUES.map(({ name }) => (
          <FormControlLabel
            key={name}
            label={name}
            control={<Radio />}
            value={name}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};
