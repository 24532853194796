import { any, object } from "prop-types";
import { Box } from "@mui/material";

export const CardBlockWrapper = ({ children = <></>, wrapProps = {} }) => {
  return (
    <Box
      borderRadius="4px"
      backgroundColor="#FFF"
      boxShadow="0px 1.5px 3px 0px rgba(0, 0, 0, 0.16)"
      {...wrapProps}
    >
      {children}
    </Box>
  );
};

CardBlockWrapper.propTypes = {
  children: any,
  wrapProps: object,
};
