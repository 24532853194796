import { useContext, useMemo } from "react";
import { TasksPageContext } from "Pages/TasksPage/TasksPage";
import { Box, Typography } from "@mui/material";
import { ActionRestrictionWrapper, StyledButton, StyledTabs } from "components";
import { useNavigate } from "react-router-dom";

export const TabsPage = () => {
  const navigate = useNavigate();

  const {
    tabs,
    currentTab,
    handleChangeCurrentTab,
    handleCreateTask,
    repPermissions,
  } = useContext(TasksPageContext);

  const disabledActions = {
    task: {
      disabled: false,
      disabledPermissions: repPermissions
        ? !repPermissions?.tasks?.create_edit
        : false,
    },
    form: {
      disabled: false,
      disabledPermissions: repPermissions
        ? !repPermissions?.forms?.create_edit
        : false,
    },
  };

  const tabTitle = useMemo(() => {
    if (currentTab === tabs[0].value) return "Tasks";
    if (currentTab === tabs[1].value) return "Forms";

    return "Tasks";
  }, [currentTab, tabs]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: "32px",
        backgroundColor: "#F0F0F0",
        borderBottom: "0.5px solid #D4D4D4",
        height: "44px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box minWidth="60px">
          <Typography
            variant="h6"
            fontWeight="400"
            fontSize="20px"
            color="#9C9C94"
          >
            {tabTitle}
          </Typography>
        </Box>
        <Box>
          {tabs && (
            <StyledTabs
              onChange={handleChangeCurrentTab}
              value={currentTab}
              tabs={tabs}
              tabProps={{
                sx: {
                  py: 0,
                  height: "44px",
                  fontSize: "12px !important",
                  fontWeight: 400,
                  color: "#9C9C94",
                },
              }}
              sx={{
                minHeight: "auto",
                height: "44px",
                "& .Mui-selected": {
                  backgroundColor: "transparent!important",
                },
              }}
            />
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 1,
        }}
      >
        {currentTab === tabs[0].value && (
          <>
            <ActionRestrictionWrapper
              disableHoverListener={!disabledActions.task.disabledPermissions}
            >
              <StyledButton
                disabled={
                  disabledActions.task.disabled ||
                  disabledActions.task.disabledPermissions
                }
                sx={{ height: 28 }}
                variant="contained"
                label="Add Task"
                onClick={() => handleCreateTask()}
              />
            </ActionRestrictionWrapper>
          </>
        )}
        {currentTab === tabs[1].value && (
          <>
            <ActionRestrictionWrapper
              disableHoverListener={!disabledActions.form.disabledPermissions}
            >
              <StyledButton
                disabled={
                  disabledActions.form.disabled ||
                  disabledActions.form.disabledPermissions
                }
                sx={{ height: 28 }}
                variant="contained"
                label="Add Form"
                onClick={() => navigate("/form/new")}
              />
            </ActionRestrictionWrapper>
          </>
        )}
      </Box>
    </Box>
  );
};
