import React from "react";
import { string, object, func } from "prop-types";
import { PlaceAutocompleteField } from "components";
import { getAddressComponents } from "Pages/CustomersPage/components/AddressField/AddressField.helpers";
import { getObjectDifference, sliceCountry } from "helpers/helpers";

const ImportNameAutocomplete = ({
  api,
  value,
  updateChangesState,
  row,
  id,
  field,
  reverseMappedCols,
}) => {
  const handleSetCustomer = (customerInfo) => {
    const { state, city, zip } = getAddressComponents(customerInfo);

    const preparedNumber = (n) => {
      if (!n) return "";
      if (n.startsWith("(") && n.length === 14)
        return `+1${n.replace(/\s|\(|\)|-/g, "")}`;
      return n;
    };

    const formattedAddress = sliceCountry(customerInfo.formatted_address);

    api?.setEditCellValue?.({
      id,
      field,
      value: customerInfo?.name,
    });
    api?.stopCellEditMode({ id, field });

    const {
      customerBillingAddress,
      customerBillingCity,
      customerBillingState,
      customerBillingZip,
      customerShippingAddress,
      customerShippingCity,
      customerShippingState,
      customerShippingZip,
      phone,
      website,
      name,
    } = reverseMappedCols || {};

    const updatedRowFields = {
      [customerBillingAddress]: formattedAddress,
      [customerBillingCity]: city,
      [customerBillingState]: state,
      [customerBillingZip]: +zip || null,
      [customerShippingAddress]: formattedAddress,
      [customerShippingCity]: city,
      [customerShippingState]: state,
      [customerShippingZip]: +zip || null,
      [phone]: preparedNumber(customerInfo.formatted_phone_number),
      [website]: customerInfo.website,
      [name]: customerInfo.name,
    };

    const oldRowFields = {
      [customerBillingAddress]: row[customerBillingAddress],
      [customerBillingCity]: row[customerBillingCity],
      [customerBillingState]: row[customerBillingState],
      [customerBillingZip]: row[customerBillingZip],
      [customerShippingAddress]: row[customerShippingAddress],
      [customerShippingCity]: row[customerShippingCity],
      [customerShippingState]: row[customerShippingState],
      [customerShippingZip]: row[customerShippingZip],
      [phone]: row[phone],
      [website]: row[website],
      [name]: row[name],
    };

    api?.updateRows([
      {
        id: row.id,
        ...updatedRowFields,
      },
    ]);

    updateChangesState({
      updatedRowFields: getObjectDifference(oldRowFields, updatedRowFields),
      id: row.id,
    });
  };

  return (
    <PlaceAutocompleteField
      size="small"
      autoFocus
      paperStyles={{ left: "-10px", width: "calc(100% + 20px)", top: "25px" }}
      handleSetCustomer={handleSetCustomer}
      ignorePathnameSetFocused
      noErrorMessage
      InputProps={{
        sx: {
          fontSize: "13px",
          height: "43px",
          borderRadius: "4px 0 0 4px",
          "& fieldset": { borderWidth: "1px 0.5px 1px 1px" },
        },
      }}
      value={value}
      onChange={(e) => {
        api?.setEditCellValue?.({
          id,
          field,
          value: e.target.value,
        });
      }}
    />
  );
};

ImportNameAutocomplete.propTypes = {
  value: string,
  api: object,
  id: string,
  updateChangesState: func,
  row: object,
  field: string,
  reverseMappedCols: object,
};

export default ImportNameAutocomplete;
