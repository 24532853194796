import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import PropTypes, {
  object,
  func,
  bool,
  array,
  string,
  number,
} from "prop-types";
import { AtentionIcon, DangerIcon } from "../../../../../../components/Icons";
import CustomersTableComponent from "./CustomersTableComponent/CustomersTableComponent";
import RepsTableComponent from "./RepsTableComponent/RepsTableComponent";
import {
  customersByRepGetParamsAction,
  endLoadingCustomersByRep,
  setShowInactiveCustomersByRep,
  startLoadingCustomersByRep,
} from "../../../../../../redux/actions/customers";
import { useDebounce } from "../../../../../../helpers/hooks";
import { getRepsService } from "../../../../../../services/reps";
import { error } from "../../../../../../utils/notifications";
import {
  CUSTOMER_STATUS_FILTERS,
  UNCATEGORIZED_GROUP,
  UNCATEGORIZED_TERRITORY,
} from "../../../../../../utils/constants";
import { NewTransferFilter } from "./NewTransferFilter/NewTransferFilter";
import { CustomersInfoBarComponent } from "./CustomersInfoBarComponent";
import { CustomersTabFilter } from "./CustomersTabFilter";
import { getCustomersService } from "../../../../../../services/customers";

const TabCustomersComponent = ({
  profile,
  isTransfer,
  handleCheckCustomer,
  checkedCustomers,
  checkAllCustomers,
  allCustomersChecked,
  checkedReps,
  role,
  profileRole,
  selectedMerch,
  selectedSales,
  selectedThirdParty,
  setSelectedMerch,
  setSelectedSales,
  setSelectedThirdParty,
  selectedReps,
  handleSelectRep,
  merchList,
  salesList,
  thirdPartyList,
  setAssignSearchQuery,
  customersCount,
  setCheckedCustomers,
}) => {
  const customersState = useSelector(({ customers }) => customers);
  const dispatch = useDispatch();
  const [repsList, setRepsList] = useState([]);
  const [searchQuery, setSearchQuery] = useState({
    search: "",
    territoryId: "",
  });
  const [searchInput, setSearchInput] = useState("");
  const [currentTab, setCurrentTab] = useState(
    role?.find((i) => i.toLowerCase())?.toLowerCase()
  );

  const [loading, setLoading] = useState(false);
  const searchInputDebounced = useDebounce(searchInput, 500);
  const [customersSearchInput, setCustomersSearchInput] = useState("");
  const customersSearchInputDebounced = useDebounce(customersSearchInput, 500);
  const [page, setPage] = useState(1);
  const checkDisableSales = role?.find((i) => i === "SALES") ? false : true;
  const checkDisableMerch = role?.find((i) => i === "MERCHANDISER")
    ? false
    : true;
  const checkDisableThirdParty = role?.find((i) => i === "THIRD_PARTY")
    ? false
    : true;

  useEffect(() => {
    if (profile?.id) {
      dispatch(
        customersByRepGetParamsAction(profile?.id, {
          search: customersSearchInputDebounced,
          page: 1,
        })
      );
    }
  }, [customersSearchInputDebounced, profile?.id, dispatch]);

  const handleFetchCustomers = () => {
    dispatch(
      customersByRepGetParamsAction(profile?.id, {
        search: customersSearchInputDebounced,
        page: page + 1,
      })
    );
    setPage((prev) => prev + 1);
  };

  const fetchListAndCheckAll = () => {
    dispatch(startLoadingCustomersByRep());
    getCustomersService({
      status: `["${customersState.status}"]`,
      representative_id: profile?.id,
      search: customersSearchInputDebounced || "",
      parent_customer_id:
        customersState?.customersByRepGetParams?.group_id || "",
      territory_id: customersState?.customersByRepGetParams?.territory_id || "",
    })
      .then((res) => {
        dispatch(endLoadingCustomersByRep());
        setCheckedCustomers(res?.rows);
      })
      .catch(() => {
        error("Something went wrong.");
        dispatch(endLoadingCustomersByRep());
      });
  };

  useEffect(() => {
    setLoading(true);
    handleSelectRep([]);
    const q = {
      ...searchQuery,
      search: searchInputDebounced,
      role: currentTab || "",
    };
    setSearchQuery(q);
    if (setAssignSearchQuery) setAssignSearchQuery(q);
    getRepsService(q)
      .then((res) => {
        setLoading(false);
        setRepsList(res.rows.filter((rep) => rep.id !== profile?.id));
      })
      .catch((e) => {
        error(e.response?.data?.message);
        setLoading(false);
      });

    if (profile?.id) {
      return () => {
        dispatch(setShowInactiveCustomersByRep(false));
        dispatch(
          customersByRepGetParamsAction(profile?.id, {
            search: "",
            // active: true,
            page: 1,
            limit: 10,
            territory_id: "",
          })
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputDebounced]);

  const handleTerritoryFilter = (e) => {
    const val = e?.target?.value ?? e;
    setLoading(true);
    const q = {
      ...searchQuery,
      role: profileRole || "",
      territoryId: val === UNCATEGORIZED_TERRITORY.id ? "null" : val,
    };
    setSearchQuery(q);
    if (setAssignSearchQuery) setAssignSearchQuery(q);
    getRepsService(q)
      .then((res) => {
        setLoading(false);
        setRepsList(res.rows.filter((rep) => rep.id !== profile?.id));
      })
      .catch((e) => {
        error(e.response?.data?.message);
        setLoading(false);
      });
  };

  const handleCustomersFilter = (e) => {
    const val = e?.target?.value ?? e;

    const currentStatus = val?.status ?? customersState.statusByRep;

    setPage(1);
    const q = {
      page: 1,
      parent_customer_id:
        val?.group_id?.value === UNCATEGORIZED_GROUP.id
          ? "null"
          : val?.group_id?.value,
      territory_id:
        val?.territory_id?.value === UNCATEGORIZED_TERRITORY.id
          ? "null"
          : val?.territory_id?.value,
      status: JSON.stringify([currentStatus]),
    };

    delete q.active;

    if (setAssignSearchQuery) setAssignSearchQuery(q);
    dispatch(customersByRepGetParamsAction(profile?.id, q));
  };

  useEffect(() => {
    handleCustomersFilter({ status: CUSTOMER_STATUS_FILTERS.active });
    if (profile?.chosenCustomer) {
      setCheckedCustomers((prev) => [...prev, profile?.chosenCustomer]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckRep = (rep, selected, setSelected) => {
    if (selected.some((s) => s.id === rep.id))
      return setSelected(selected.filter((s) => s.id !== rep.id));
    setSelected([...selected, rep]);
  };

  const handleSelectAll = (selected, setSelected, reps) => {
    if (selected.length === reps.length) return setSelected([]);
    setSelected([...reps]);
  };

  const getHandleSelectAll = (currentTab) => {
    switch (currentTab) {
      case "sales":
        return () =>
          handleSelectAll(selectedSales, setSelectedSales, salesList);
      case "merchandiser":
        return () =>
          handleSelectAll(selectedMerch, setSelectedMerch, merchList);
      case "third_party":
        return () =>
          handleSelectAll(
            selectedThirdParty,
            setSelectedThirdParty,
            thirdPartyList
          );
    }
  };

  const getHandleSelectReps = (currentTab) => {
    switch (currentTab) {
      case "sales":
        return (rep) => handleCheckRep(rep, selectedSales, setSelectedSales);
      case "merchandiser":
        return (rep) => handleCheckRep(rep, selectedMerch, setSelectedMerch);
      case "third_party":
        return (rep) =>
          handleCheckRep(rep, selectedThirdParty, setSelectedThirdParty);
    }
  };

  const getSelectedReps = (currentTab) => {
    switch (currentTab) {
      case "sales":
        return selectedSales;
      case "merchandiser":
        return selectedMerch;
      case "third_party":
        return selectedThirdParty;
    }
  };

  if (isTransfer) {
    const getRepsList = () => {
      if (profile) return repsList;
      if (currentTab === "sales") return salesList;
      if (currentTab === "merchandiser") return merchList;
      if (currentTab === "third_party") return thirdPartyList;
    };
    return (
      <Grid container>
        <Grid item xs={12}>
          <NewTransferFilter
            handleTerritoryFilter={handleTerritoryFilter}
            loading={loading}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          px="23px"
          mt="20px"
        >
          <Box display="flex" alignItems="center">
            <DangerIcon />
            <Typography ml="16px" fontSize="15px" color="groundLighter.main">
              Select to which representative you want to transfer customers to.
            </Typography>
          </Box>
        </Grid>
        {!profile && (
          <Tabs
            value={currentTab}
            onChange={(e, newTab) => setCurrentTab(newTab)}
            sx={{ pl: "28px", my: "20px" }}
          >
            <Tab
              value="sales"
              sx={{
                color: "#707070",
                textTransform: "none",
                fontSize: "20px",
                gap: "4px",
                minHeight: "48px",
              }}
              className="profile-tabs"
              label="Sales Reps"
              disabled={checkDisableSales && salesList.length === 0}
              icon={
                <Box
                  sx={{
                    display:
                      !(
                        !checkDisableSales &&
                        !selectedSales.length &&
                        salesList.length !== 0
                      ) && "none",
                  }}
                >
                  <AtentionIcon />
                </Box>
              }
              iconPosition="start"
            />
            <Tab
              value="merchandiser"
              sx={{
                color: "#707070",
                textTransform: "none",
                fontSize: "20px",
                gap: "4px",
                minHeight: "48px",
              }}
              className="profile-tabs"
              label="Merchendiser Reps"
              disabled={checkDisableMerch && merchList.length === 0}
              icon={
                <Box
                  sx={{
                    display:
                      !(
                        !checkDisableMerch &&
                        !selectedMerch.length &&
                        merchList.length !== 0
                      ) && "none",
                  }}
                >
                  <AtentionIcon />
                </Box>
              }
              iconPosition="start"
            />
            <Tab
              value="third_party"
              sx={{
                color: "#707070",
                textTransform: "none",
                fontSize: "20px",
                gap: "4px",
                minHeight: "48px",
              }}
              className="profile-tabs"
              label="3rd Party Reps"
              disabled={checkDisableThirdParty && thirdPartyList.length === 0}
              icon={
                <Box
                  sx={{
                    display:
                      !(
                        !checkDisableThirdParty &&
                        !selectedThirdParty.length &&
                        thirdPartyList.length !== 0
                      ) && "none",
                  }}
                >
                  <AtentionIcon />
                </Box>
              }
              iconPosition="start"
            />
          </Tabs>
        )}
        <Grid item xs={12}>
          <RepsTableComponent
            handleSelectAll={
              profile
                ? () => handleSelectAll(selectedReps, handleSelectRep, repsList)
                : getHandleSelectAll(currentTab)
            }
            handleSelectRep={
              profile
                ? (rep) => handleCheckRep(rep, selectedReps, handleSelectRep)
                : getHandleSelectReps(currentTab)
            }
            selectedReps={profile ? selectedReps : getSelectedReps(currentTab)}
            representatives={getRepsList()}
            loading={loading}
            checkedReps={checkedReps}
          />
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <CustomersInfoBarComponent />
      </Grid>
      <Grid item xs={12}>
        <CustomersTabFilter
          handleCustomersFilter={handleCustomersFilter}
          setCustomersSearchInput={setCustomersSearchInput}
          customersSearchInput={customersSearchInput}
        />
        {profile && (
          <CustomersTableComponent
            handleCheckCustomer={handleCheckCustomer}
            checkedCustomers={checkedCustomers}
            checkAllCustomers={checkAllCustomers}
            fetchListAndCheckAll={fetchListAndCheckAll}
            repCustomersLoading={customersState?.repCustomersLoading}
            setCheckedCustomers={setCheckedCustomers}
            allCustomersChecked={allCustomersChecked}
            customersCount={customersCount}
            {...{ handleFetchCustomers }}
            maxHeight="280px"
            fetched
          />
        )}
      </Grid>
    </Grid>
  );
};

TabCustomersComponent.propTypes = {
  profile: object,
  isTransfer: bool,
  handleSelectRep: func,
  handleCheckCustomer: func,
  checkedCustomers: array,
  checkAllCustomers: func,
  allCustomersChecked: bool,
  checkedReps: array,
  role: array,
  selectedMerch: PropTypes.oneOfType([array, object]),
  selectedSales: PropTypes.oneOfType([array, object]),
  selectedThirdParty: PropTypes.oneOfType([array, object]),
  setSelectedMerch: func,
  setSelectedSales: func,
  setSelectedThirdParty: func,
  selectedReps: array,
  merchList: array,
  salesList: array,
  thirdPartyList: array,
  setAssignSearchQuery: func,
  customersCount: number,
  setCheckedCustomers: func,
  profileRole: string,
};

TabCustomersComponent.defaultProps = {
  profile: null,
  isTransfer: false,
  selectedReps: [],
  checkedCustomers: [],
  allCustomersChecked: false,
  checkedReps: [],
  merchList: [],
  salesList: [],
  thirdPartyList: [],
  customersCount: 0,
};

export default TabCustomersComponent;
