export const cl = {
  cardWrapper: {
    width: "100%",
    height: "100%",
    padding: "20px",
    background: "white",
    boxShadow: "0px 1.5px 3px rgba(0, 0, 0, 0.16) ",
    borderRadius: "4px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "16px",
  },
};
