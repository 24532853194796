export const cl = {
  itemWrapper: {
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "8px",
  },

  iconWrapper: {
    width: "26px",
    height: "26px",
    padding: "5px",
    background: "rgba(165, 165, 165, 0.60)",
    borderRadius: "3px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",

    svg: {
      path: {
        fill: "#ffff",
      },
    },
  },

  multiAnswerIcon: {
    "& path": {
      fill: "rgba(165, 165, 165, 0.60) !important",
    },
  },

  text: {
    color: "#595959",
    fontSize: "14px",
    fontWeight: "400",
  },
};
