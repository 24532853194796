import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledButton } from "../../../../../components";
import { updateUserFieldAction } from "../../../../../redux/actions/auth";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "../../../../../redux/actions/confirmDialogs";
import {
  checkQuickbooksShippingAvailability,
  checkShipStationConnection,
  enableQuickbooksShipping,
} from "../../../../../services/integrations";
import { error, success } from "../../../../../utils/notifications";
import { handleConfirmAction } from "Pages/DiscountsPage/DiscountsPage.helpers";
import { addSyncStatusAction } from "redux/actions/integrations";
import { getEnvironment } from "helpers/helpers";

export const useIntegrations = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { deliveryFee, quickBooksTokens } = useSelector(
    ({ auth }) => auth?.currentUser
  );

  const isPreProdEnv = getEnvironment("pre-production");

  const handleTurnOnDelivery = async ({ onProceed }) => {
    setLoading(true);

    const res = await enableQuickbooksShipping().catch((err) => {
      setLoading(false);
      error(err?.response?.data?.message);
    });
    setLoading(false);

    success(res?.message);
    dispatch(setConfirmIsOpenAction(false));
    onProceed();
  };

  const handleOpenDeliveryFeeWarning = async ({
    buttonLabel,
    onProceed,
    manualDeliveryFee,
    isCreatingOrder,
  }) => {
    const skipChecking = !quickBooksTokens || (!deliveryFee && isCreatingOrder);
    if (skipChecking) return onProceed();

    setLoading(true);
    const { quickbooksShippingEnabled } =
      await checkQuickbooksShippingAvailability({
        manualDeliveryFee,
      }).catch((err) => {
        setLoading(false);
        error(err?.response?.data?.message);
      });
    setLoading(false);
    if (!quickbooksShippingEnabled)
      dispatch(
        openConfirmDialogAction({
          isIcon: true,
          title: "Quickbooks Delivery Fee Alert",
          text: "We have noticed that your Quickbooks delivery fee is currently off. To ensure that your account is up-to-date and accurate, please take a moment to review and adjust your settings as needed.",
          buttons: (
            <StyledButton
              label={buttonLabel || "Turn on"}
              variant="contained"
              onClick={() => handleTurnOnDelivery({ onProceed })}
            />
          ),
        })
      );
    else onProceed();
  };

  const handleRequest = useCallback(
    async ({ request, loadingKey, onSuccess }) => {
      if (!request) return;

      try {
        setLoading(loadingKey);
        const data = await request();
        setLoading(null);
        if (onSuccess) onSuccess();
        return data;
      } catch (err) {
        // temporarily
        if (!isPreProdEnv) error(err?.response?.data?.message);
        // eslint-disable-next-line no-console
        console.log(err, err?.response?.data?.message);
        setLoading(null);
      }
    },
    [isPreProdEnv]
  );

  const checkShipStation = useCallback(async () => {
    handleRequest({
      request: () =>
        checkShipStationConnection().then(({ exists }) => {
          dispatch(
            addSyncStatusAction({
              shipstation: exists ? { status: "COMPLETED" } : null,
            })
          );
        }),
      loadingKey: "ShipStation",
    });
  }, [dispatch, handleRequest]);

  const handleSync = useCallback(
    ({ syncService, loadingKey }) => {
      handleRequest({ request: syncService, loadingKey });
    },
    [handleRequest]
  );

  const handleClearQuickbooksToken = useCallback(() => {
    dispatch(updateUserFieldAction("quickBooksTokens", null));
  }, [dispatch]);

  const closeConfirm = useCallback(() => {
    dispatch(setConfirmIsOpenAction(false));
  }, [dispatch]);

  const handleDisconnect = useCallback(
    ({ disconnectService, loadingKey, confirm }) => {
      if (confirm) {
        dispatch(
          handleConfirmAction({
            title: "Disconnect ShipStation",
            confirmText: "Disconnect",
            confirmColor: "confirmDelete",
            text: "Are you sure you want to disconnect from ShipStation? Once disconnected, your orders will no longer be synchronized with SimplyDepo.",
            onConfirm: () => {
              handleRequest({
                request: disconnectService,
                loadingKey,
                onSuccess: () => {
                  checkShipStation();
                  closeConfirm();
                },
              });
            },
          })
        );
        return;
      }

      handleRequest({
        request: disconnectService,
        loadingKey,
        onSuccess: handleClearQuickbooksToken,
      });
    },
    [
      handleRequest,
      handleClearQuickbooksToken,
      dispatch,
      checkShipStation,
      closeConfirm,
    ]
  );

  return {
    handleSync,
    loading,
    handleDisconnect,
    handleOpenDeliveryFeeWarning,
    checkShipStation,
  };
};

export const useIntegrationCard = () => {
  const [loading, setLoading] = useState({ sync: false, connection: true });

  return { loading, setLoading };
};
