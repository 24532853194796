import { useState, useMemo, useRef } from "react";
import { object, bool, string, array } from "prop-types";
import { NavLink, useNavigate } from "react-router-dom";
import { Typography, Box, Chip } from "@mui/material";
import { PaperIcon, SimpleArrowDownIcon } from "components/Icons";
import { getCreatedAt, getCreatedOrderBy, useAdmin } from "helpers/helpers";
import PaymentStatus from "components/PaymentStatus/PaymentStatus.jsx";
import { cl } from "./styles";
import { MenuColumnOrder } from "Pages/CustomersPage/components/CustomersTab/components/TabsContent/TabSortedComponent/components/columnsData/MenuColumn/MenuColumnOrder";
import { CustomerMenu, TagsMenu } from "./components";
import { createSelector } from "reselect";
import { columnLayoutsSelector } from "redux/selectors/settings";
import { useSelector } from "react-redux";
import { ActionRestrictionWrapper, StyledTooltip } from "components";

const selector = createSelector(columnLayoutsSelector, (columnLayouts) => ({
  columnLayouts,
}));

const columnWidth = {
  Order: 4.5,
  Chip: 6.5,
  Note: 2.5,
  Date: 10.5,
  Customer: 22,
  "Created by": 23.7,
  Status: 11.6,
  Total: 8,
  Tags: 11.2,
};

export const DraftsItem = ({
  order,
  timeZone,
  showBorder,
  isQuickBooksConnected,
  menuItems,
  repPermissions,
}) => {
  const isAdmin = useAdmin();

  const { columnLayouts } = useSelector(selector);

  const [anchorCustomerEl, setAnchorCustomerEl] = useState(null);
  const [anchorTagsEl, setAnchorTagsEl] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedTags, setSelectedTags] = useState(null);
  const [hoverColor, setHoverColor] = useState("#FFF");

  const customerNameRef = useRef(null);

  const isThirdParty = order?.orderType === "THIRD_PARTY";

  const additionalCountTagsRef = useRef(null);

  const handleClickOnArrow = (e, order) => {
    e.stopPropagation();
    setSelectedOrder(order);
    if (customerNameRef.current) {
      setAnchorCustomerEl(customerNameRef.current);
    }
  };
  const handleCloseArrowBtn = (e) => {
    e.stopPropagation();
    setSelectedOrder(null);
    setSelectedTags(null);
    setAnchorCustomerEl(null);
    setAnchorTagsEl(null);
  };

  const handleClickOnRestTags = (e, tags) => {
    e.stopPropagation();
    setSelectedTags(tags);
    if (additionalCountTagsRef.current) {
      setAnchorTagsEl(additionalCountTagsRef.current);
    }
  };

  const navigate = useNavigate();

  const hasCustomerEmail = useMemo(() => {
    return (
      !!order?.customer?.email ||
      order?.customer?.contacts?.some((contact) => contact?.email)
    );
  }, [order?.customer?.contacts, order?.customer?.email]);

  const tags = useMemo(() => {
    return order?.tags?.length ? order.tags.map((t) => t?.tag) : [];
  }, [order.tags]);

  const defaultColumnWidth = useMemo(
    () => Object.values(columnWidth).reduce((acc, cur) => (acc += cur), 0),
    []
  );

  const createdBy = useMemo(() => getCreatedOrderBy(order), [order]);

  const drafts_tab_disable = useMemo(
    () => [
      ...columnLayouts.drafts.disable,
      {
        id: "Chip",
        name: "Chip",
        disabled: true,
        selected: true,
      },
      {
        id: "Note",
        name: "Note",
        disabled: true,
        selected: true,
      },
    ],
    [columnLayouts.drafts.disable]
  );
  const drafts_tab = columnLayouts.drafts.main;

  const columns = useMemo(() => {
    return [...drafts_tab_disable, ...drafts_tab.filter((col) => col.selected)];
  }, [drafts_tab, drafts_tab_disable]);

  const columnData = useMemo(
    () => ({
      Order: (
        <Box>
          <Typography sx={cl.orderText}>{order.customId?.customId}</Typography>
        </Box>
      ),
      Chip: (
        <Box display="flex" justifyContent="center" alignItems="center">
          {isThirdParty && (
            <Chip
              sx={{
                height: "18px",
                fontSize: "10px",
                color: "#5F6267",
                backgroundColor: "#F6F6F6",
                border: "0.5px solid #D5D9D9",
                "& .MuiChip-label": { px: 1 },
              }}
              label="3rd Party"
            />
          )}
        </Box>
      ),
      Note: (
        <Box display="flex" alignItems="center" position="relative">
          {order && order.note && order.note?.text && (
            <StyledTooltip
              title={order.note.text}
              placement="top"
              arrow
              PopperProps={{
                modifiers: [{ name: "offset", options: { offset: [0, -4] } }],
              }}
            >
              <Box
                justifyContent="center"
                sx={{
                  height: "17px",
                  svg: { height: "17px", width: "14px" },
                }}
              >
                <PaperIcon color="#5F6267" />
              </Box>
            </StyledTooltip>
          )}
        </Box>
      ),
      Date: (
        <Box display="flex" alignItems="center">
          <Typography
            sx={{
              ...cl.typography,
              textDecorationLine:
                order?.orderStatus === "CANCELED" && "line-through",
            }}
          >
            {getCreatedAt(order.createdAt, timeZone)}
          </Typography>
        </Box>
      ),
      Customer: (
        <Box>
          <Box display="flex">
            <Box
              sx={cl.orderName}
              display="flex"
              gap="3px"
              alignItems="center"
              flexWrap="nowrap"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleClickOnArrow(e, order);
              }}
            >
              <Typography
                ref={customerNameRef}
                sx={{
                  ...cl.typography,
                  textDecorationLine:
                    order?.orderStatus === "CANCELED" && "line-through",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                noWrap
                color="#1C1C19"
              >
                {order?.customer?.displayName || order?.customer?.name}
              </Typography>
              <Box
                display="flex"
                sx={{
                  mb: "-2px",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <SimpleArrowDownIcon width="7px" heigh="3.6px" />
              </Box>
            </Box>
          </Box>
          <Typography
            sx={{
              ...cl.typography,
              marginTop: "2px",
              fontWeight: 300,
              textDecorationLine:
                order?.orderStatus === "CANCELED" && "line-through",
            }}
            noWrap
          >
            {order.customer?.shippingAddress?.formatted_address}
          </Typography>
        </Box>
      ),
      "Created by": (
        <Box>
          <Typography sx={cl.typography} noWrap>
            {createdBy}
          </Typography>
        </Box>
      ),
      Status: (
        <Box display="flex" alignItems="center" position="relative">
          <PaymentStatus str={order?.status} />
        </Box>
      ),
      Total: (
        <Box>
          <Typography
            sx={{
              ...cl.typography,
              fontWeight: 600,
              textDecorationLine:
                order.orderStatus === "CANCELED" && "line-through",
              textDecorationColor: "#1C1C19",
            }}
          >
            $ {order?.totalAmount?.toFixed(2)}
          </Typography>
        </Box>
      ),
      Tags: (
        <Box
          sx={{
            ...cl.orderName,
            display: "flex",
            alignItems: "center",
            gap: 1,
            width: "100%",
            maxWidth: "100%",
          }}
        >
          <Box
            sx={{ width: "30px", minWidth: "30px" }}
            display="flex"
            gap={0.5}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              const tagsWithoutFirstItem = [...tags].slice(1);
              handleClickOnRestTags(e, tagsWithoutFirstItem);
            }}
          >
            {tags?.length > 1 ? (
              <>
                <Typography
                  ref={additionalCountTagsRef}
                  fontSize={12}
                  color="#5F6368"
                >
                  +{tags?.length - 1}
                </Typography>
                <Box display="flex" alignItems="center" cursor="pointer">
                  <SimpleArrowDownIcon width="7px" heigh="3.6px" />
                </Box>
              </>
            ) : null}
          </Box>
          {tags?.length ? (
            <Chip
              sx={{
                fontSize: "14px",
                height: "24px",
                color: "#5F6368",
                maxWidth: "calc(100% - 38px)",
                backgroundColor: "#F8F8F8",
              }}
              label={tags[0]?.tag}
              variant="outlined"
            />
          ) : (
            <Typography color="#5F6368">-</Typography>
          )}
        </Box>
      ),
    }),
    [createdBy, isThirdParty, order, tags, timeZone]
  );

  const setColumnWidth = (col) => {
    if (col?.name === "Tags")
      return `calc(${columnWidth?.[col?.name]}% - ${10}px)`;

    const columns = columnWidth?.[col?.name]
      ? `${columnWidth?.[col?.name]}%`
      : `${
          (100 - defaultColumnWidth) /
          drafts_tab.filter((col) => col.selected).length
        }%`;
    return columns;
  };

  const disabledMenu = useMemo(() => {
    return !!(
      repPermissions &&
      (repPermissions?.role === "MERCHANDISER" ||
        !repPermissions?.orders?.create_edit)
    );
  }, [repPermissions]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "48px",
        backgroundColor: hoverColor,
        borderBottom: showBorder && "1px solid #D5D9D9",
        position: "relative",
      }}
      onMouseMove={() => setHoverColor("#F7F7F7")}
      onMouseLeave={() => setHoverColor("#FFF")}
      data-testid="draft-row"
    >
      <Box sx={{ width: "22px", minWidth: "22px" }} />
      <Box
        sx={{
          width: `calc(100% - ${22 + 70}px)`,
          maxWidth: `calc(100% - ${22 + 70}px)`,
          display: "flex",
          alignItems: "center",
        }}
      >
        {columns.map((col) => {
          const valueOfWidth = setColumnWidth(col);
          return (
            <Box
              component={NavLink}
              to={`/orders/drafts/${order.id}`}
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) return;
                e.stopPropagation();
                e.preventDefault();
                navigate(`/orders/drafts/${order.id}`);
              }}
              key={col.id}
              sx={{
                width: valueOfWidth,
                maxWidth: valueOfWidth,
                minWidth: valueOfWidth,
                textDecoration: "none",
              }}
            >
              {columnData?.[col?.name]}
            </Box>
          );
        })}
      </Box>

      <Box width="70px" minWidth="70px">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            backgroundColor: hoverColor,
          }}
        >
          <ActionRestrictionWrapper disableHoverListener={!disabledMenu}>
            <MenuColumnOrder
              {...{
                isQuickBooksConnected,
                order,
                hasCustomerEmail,
                isAdmin,
                repPermissions,
              }}
              hideSendEmail
              additionalItems={menuItems}
              disabled={disabledMenu}
            />
          </ActionRestrictionWrapper>
        </Box>
      </Box>

      {!!selectedOrder && (
        <CustomerMenu
          isOpen={!!anchorCustomerEl}
          customer={selectedOrder.customer}
          anchorEl={anchorCustomerEl}
          handleClose={handleCloseArrowBtn}
        />
      )}

      {!!anchorTagsEl && (
        <TagsMenu
          open={!!anchorTagsEl}
          tags={selectedTags}
          anchorEl={anchorTagsEl}
          handleClose={handleCloseArrowBtn}
        />
      )}
    </Box>
  );
};

DraftsItem.propTypes = {
  order: object,
  timeZone: string,
  showBorder: bool,
  isQuickBooksConnected: bool,
  menuItems: array,
  repPermissions: object,
};

DraftsItem.defaultProps = {
  order: null,
  timeZone: "",
  showBorder: false,
  isQuickBooksConnected: false,
};

export default DraftsItem;
