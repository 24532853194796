import { object, string } from "prop-types";
import { Stack, Typography } from "@mui/material";

export const TitleBlock = ({ name = "", wrapProps = {}, nameProps = {} }) => {
  return (
    <Stack alignItems="center" {...wrapProps}>
      <Typography fontSize={18} fontWeight={700} color="#3F3F3F" {...nameProps}>
        {name}
      </Typography>
    </Stack>
  );
};

TitleBlock.propTypes = {
  name: string,
  wrapProps: object,
  nameProps: object,
};
