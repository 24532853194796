import { useContext } from "react";
import { Typography } from "@mui/material";
import pluralize from "pluralize";
import { FormsPageContext } from "Pages/TasksPage/TasksPage";

export const FormsFooter = () => {
  const { formsList } = useContext(FormsPageContext);

  return (
    <>
      {!!formsList?.length && (
        <Typography color="#6E6E6E" fontSize={17} fontWeight={300} mt="24px">
          Showing {formsList.length} {pluralize("form", formsList.length)}
        </Typography>
      )}
    </>
  );
};
