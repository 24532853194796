import { FORMS_FILTER, RESET_FORMS_FILTER } from "redux/actions/forms";
import { SCROLL_LIMIT_FORMS } from "utils/constants";

export const initialFormsState = {
  formsFilter: {
    page: 1,
    limit: SCROLL_LIMIT_FORMS,
    active: true,
    search: undefined,
    sort_by_title: undefined,
    sort_by_customers: undefined,
    sort_by_responses: undefined,
  },
};

const formsReducer = (state = initialFormsState, { type, payload }) => {
  switch (type) {
    case FORMS_FILTER:
      return { ...state, formsFilter: payload };

    case RESET_FORMS_FILTER:
      return { ...state, formsFilter: initialFormsState.formsFilter };

    default:
      return state;
  }
};

export default formsReducer;
