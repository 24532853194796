/* eslint-disable no-useless-escape */
import * as Yup from "yup";
import { ORDER_TYPES } from "./CartPage.constants";
import { isValidFulfillByDate } from "helpers/helpers";

export const validationSchema = (currentUser) =>
  Yup.object().shape({
    products: Yup.array()
      .of(
        Yup.object().shape({
          quantity: Yup.number()
            .typeError("Must be a number.")
            .when("parentProduct", {
              is: null,
              otherwise: Yup.number().when(
                "parentProduct",
                (parentProduct, schema) => {
                  return schema.test({
                    test: (quantity) =>
                      quantity >= (parentProduct?.minOrderQTY || 0),
                    message: "Quantity should be greater than MOQ",
                  });
                }
              ),
            })
            .when("product", {
              is: null,
              otherwise: Yup.number().when("product", (product, schema) => {
                return schema.test({
                  test: (quantity) =>
                    quantity >=
                    (product?.minOrderQTY ||
                      product?.product?.minOrderQTY ||
                      product?.parentProduct?.minOrderQTY ||
                      product?.product?.parentProduct?.minOrderQTY ||
                      0),
                  message: "Quantity should be greater than MOQ",
                });
              }),
            })
            .when("minOrderQTY", (minOrderQTY, schema) => {
              return schema.test({
                test: (quantity) => quantity >= (minOrderQTY || 0),
                message: "Quantity should be greater than MOQ",
              });
            }),
          itemDiscountAmount: Yup.number()
            .required("This field is required")
            .typeError("Must be a number.")
            .when("itemDiscountType", {
              is: "PERCENTAGE",
              then: Yup.number().test(
                "discountValidation",
                "Please type a valid discount.",
                (value) => {
                  return value >= 0 && value <= 100;
                }
              ),
            }),
        })
      )
      .test("productsValidation", "At least 1 product is required", (value) => {
        return value.length > 0;
      }),
    customer: Yup.object({}).nullable().required("Customer is required"),
    contactId: Yup.string().test(
      "contactIdValidation",
      "Contact is required",
      (value, ctx) => {
        const { parent } = ctx || {};
        const { customer } = parent || {};

        if (customer) return !!value;

        return true;
      }
    ),
    salesId: Yup.string()
      .nullable()
      .test("salesIdValidation", "Select Sales Rep", (value, ctx) => {
        const { parent } = ctx || {};
        const { customer, createOrderType } = parent || {};

        if (createOrderType === ORDER_TYPES["3rd Party"]) {
          return true;
        }
        if (customer) return !!value;

        return true;
      }),
    deliveryFee: Yup.number().nullable().typeError("Must be a number."),
    fulfillBy: Yup.string().test("fulfillByTest", "Invalid date", (value) => {
      if (!value) return true;
      return isValidFulfillByDate(value, currentUser?.timeZone);
    }),
  });
