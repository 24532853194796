import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  Box,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import PropTypes, {
  bool,
  func,
  array,
  number,
  string,
  object,
} from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { CrossBigIcon } from "../../../../components/Icons";
import {
  productsCountSelector,
  productsListSelector,
  productsLoadingSelector,
} from "../../../../redux/selectors/products";
import PopupProductsItem from "./PopupProductsItem/PopupProductsItem";
import StyledButton from "../../../../components/StyledButton";
import {
  getProductsAction,
  resetProductsGetParamsAction,
} from "../../../../redux/actions/products";
import {
  PRODUCT_TYPE_INVENTORY,
  SCROLL_LIMIT,
} from "../../../../utils/constants";
import { containsEvery, useAdmin } from "../../../../helpers/helpers";
import PopupProductsHeader from "./PopupProductsHeader/PopupProductsHeader";
import { NewProductsFilter } from "./components";
import StyledPopper from "../../../../components/StyledPopper/StyledPopper";
import { setIndeterminate } from "./helpers";
import { InfiniteScrollWrapper } from "components";

const selector = createSelector(
  productsListSelector,
  productsCountSelector,
  productsLoadingSelector,
  (productsList, productsCount, productsLoading) => ({
    productsList,
    productsCount,
    productsLoading,
  })
);

const AllProductsPopup = ({
  isOpen,
  handleClose,
  handleAddProducts,
  handleCancelProducts,
  handleCheckProduct,
  checkedProducts,
  addedProducts,
  specificManufacturer,
  disableOutOfStock,
  isOrder,
  isPriceList = false,
  hideAvailable,
  environment,
  skipExpanding = false,
  hideGreenNavigationBar = false,
  allowMultipleSelect = false,
  allowSelectAll = false,
  setCheckState,
  priceListId = null,
}) => {
  const isAdmin = useAdmin();
  const { productsList, productsCount, productsLoading } =
    useSelector(selector);

  const [limitProducts, setLimitProducts] = useState(2 * SCROLL_LIMIT);
  const [expandedParentId, setExpandedParentId] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isOpen) {
      // dispatch(resetProductsGetParamsAction());
      setExpandedParentId(null);
    } else dispatch(resetProductsGetParamsAction(specificManufacturer?.id));
  }, [isOpen, dispatch, specificManufacturer]);

  const handleFetchProducts = () => {
    const preparedData = {
      limit: limitProducts,
      include_price_list_id: priceListId ? priceListId : null,
      exclude_negative_in_parents_inventory: true,
    };
    if (specificManufacturer?.id)
      preparedData.manufacturer_id = specificManufacturer?.id;
    dispatch(getProductsAction({ query: preparedData }));
    setLimitProducts(limitProducts + SCROLL_LIMIT);
  };

  const [currentProductRef, setCurrentProductRef] = useState(false);
  const [textProduct, setTextProduct] = useState("");

  const productsRefs = useRef([]);

  const calcAvailable = useCallback((product) => {
    const onHand = product?.inventory?.onHand;
    const allocated = product?.inventory?.allocated;

    return onHand - allocated;
  }, []);

  const productsAvailableLessThanMinimum = useCallback(
    (product) => {
      const isNonInventory =
        product?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

      const available = calcAvailable(product);
      if (
        product?.sellingOutOfStock ||
        product?.childProducts?.length ||
        isNonInventory
      )
        return false;
      return available < product?.minOrderQTY;
    },
    [calcAvailable]
  );

  const supplierWithOnlyOneProductWithVariations = useMemo(() => {
    const productsWithChildren = productsList?.filter(
      (p) => p?.childProducts?.length
    );
    if (productsWithChildren?.length === 1) return true;
    return false;
  }, [productsList]);

  // If all products of the parent product are out of stock
  const calcProductOutOfStockForParent = useCallback(
    (product) => {
      if (product?.childProducts?.length && !product?.sellingOutOfStock) {
        return !product?.childProducts?.some((prod) => calcAvailable(prod) > 0);
      }
      return false;
    },
    [calcAvailable]
  );

  const handleDisableIfChosenProduct = useCallback(
    (product, parent, allowMultiple = false) => {
      if (isOrder || !checkedProducts?.length || isPriceList) return false;
      const manufacturerId =
        product?.manufacturer?.id || parent?.manufacturer?.id;

      const checkedManufacturerId =
        checkedProducts?.[0]?.manufacturer?.id ||
        checkedProducts?.[0]?.parentProduct?.manufacturer?.id;

      if (manufacturerId === checkedManufacturerId && allowMultiple)
        return false;

      const selectedParentId =
        checkedProducts?.[0]?.parentProduct?.id ||
        checkedProducts?.[0]?.parentId ||
        checkedProducts?.[0]?.id;

      const currentProductId = parent?.id || product?.id;

      return !(selectedParentId === currentProductId);
    },
    [checkedProducts, isOrder, isPriceList]
  );

  const checkOnDisabledChild = useCallback(
    (child) => {
      const isNonInventory =
        child?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

      const isOutOfStock =
        !isNonInventory &&
        disableOutOfStock &&
        productsAvailableLessThanMinimum(child);

      return isOutOfStock;
    },
    [disableOutOfStock, productsAvailableLessThanMinimum]
  );

  const areAllChildProductsDisabled = useCallback(
    (product) => {
      if (product?.childProducts?.length) {
        const disabledProducts = product.childProducts.filter((child) => {
          const isOutOfStock = checkOnDisabledChild({
            ...child,
            sellingOutOfStock: product?.sellingOutOfStock,
            minOrderQTY: product?.minOrderQTY,
          });
          if (isOutOfStock) return isOutOfStock;
        });

        return disabledProducts.length === product?.childProducts?.length;
      }
      return false;
    },
    [checkOnDisabledChild]
  );

  const handleProductsDisable = useCallback(
    (product, isChild) => {
      if (isPriceList) return false;
      if (isChild || !product.isMultiple)
        //if (!addedProducts?.length) return false;

        return (
          (disableOutOfStock && productsAvailableLessThanMinimum(product)) ||
          addedProducts?.some(
            (addedProduct) =>
              addedProduct?.id === product?.id ||
              addedProduct?.product?.id === product?.id
          )
        );

      const isAllChildDisabled = areAllChildProductsDisabled(product);

      return (
        (disableOutOfStock && productsAvailableLessThanMinimum(product)) ||
        containsEvery(
          product?.childProducts?.filter(
            // if isMultiple, check if Added products (formfield) contain
            // any of child products from this parent
            (childProduct) =>
              !addedProducts?.some(
                (prod) =>
                  prod?.id === childProduct?.id ||
                  prod?.product?.id === childProduct?.id
              )
          ),
          addedProducts
        ) ||
        isAllChildDisabled
      );
    },
    [
      isPriceList,
      disableOutOfStock,
      productsAvailableLessThanMinimum,
      addedProducts,
      areAllChildProductsDisabled,
    ]
  );

  const getFlatProductsList = useCallback(() => {
    if (!productsList?.length || !setCheckState) return [];
    let products = [];

    productsList.forEach((product) => {
      if (product?.childProducts?.length) {
        products = [...products, ...product.childProducts];
      } else {
        products.push(product);
      }
    });

    return products;
  }, [productsList, setCheckState]);

  const flatList = useMemo(() => getFlatProductsList(), [getFlatProductsList]);

  const handleSelectAllProducts = () => {
    if (flatList?.length === checkedProducts?.length) return setCheckState([]);
    setCheckState(flatList);
  };

  const handleProductsChecked = (product) => {
    const disabled = handleProductsDisable(product);

    if (disabled) return false;

    if (product.isMultiple) {
      const s = product?.childProducts?.filter(
        (childProduct) =>
          !addedProducts?.some(
            (prod) =>
              prod?.id === childProduct?.id ||
              prod?.product?.id === childProduct.id
          )
      );

      //if (!s?.length) return false;

      return containsEvery(s, checkedProducts);
    }
    return checkedProducts.some(
      (checkedProduct) => checkedProduct?.id === product?.id
    );
  };

  return (
    <>
      <StyledPopper
        style={{ zIndex: 1401, whiteSpace: "pre-wrap" }}
        open={!!currentProductRef && isOpen && !hideAvailable}
        anchorEl={productsRefs.current?.[currentProductRef] || null}
        text={textProduct}
        renderRoot
        modifiers={[
          {
            name: "offset",
            options: { offset: [0, -6] },
          },
        ]}
        placement="top"
        transition
        aria-hidden="true"
      />
      <Dialog
        open={isOpen}
        PaperProps={{
          sx: {
            maxWidth: "786px",
            border: "0.5px #D5D9D9 solid",
            borderRadius: "8px",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "784px",
            maxHeight: "580px",
            height: "100%",
            p: "24px 32px",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            mb="24px"
            alignItems="center"
          >
            <Typography fontSize="30px" color="#707070">
              All Products{" "}
              {specificManufacturer?.name ? specificManufacturer?.name : null}
            </Typography>
            <IconButton
              onClick={handleClose}
              disableRipple
              sx={{ width: "24px", height: "24px", p: 0 }}
            >
              <CrossBigIcon size={22} strokeWidth={2} />
            </IconButton>
          </Box>

          <NewProductsFilter
            isOpen={isOpen}
            priceListId={priceListId}
            specificManufacturer={specificManufacturer}
          />

          <PopupProductsHeader
            {...{
              hideAvailable,
              isPriceList,
              allowSelectAll,
              flatList,
              handleSelectAllProducts,
              allChecked:
                !!checkedProducts?.length &&
                flatList?.length === checkedProducts?.length,
              isIndeterminate:
                checkedProducts?.length > 0 &&
                flatList?.length !== checkedProducts?.length,
            }}
          />
          <Stack
            sx={{
              height: "281px",
              overflow: "hidden",
              border: "0.5px solid #D5D9D9",
              borderRadius: "0 0 4px 4px",
            }}
          >
            {productsLoading && !productsList.length ? (
              <CircularProgress sx={{ ml: "48%", mt: "125px" }} size="30px" />
            ) : (
              <InfiniteScrollWrapper
                maxHeight="281px"
                dataLength={productsList.length}
                next={handleFetchProducts}
                loader={
                  <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />
                }
                hasMore={productsList.length < productsCount}
                id="all-products-scroll-table"
              >
                {productsList?.length ? (
                  productsList.map((product) => {
                    const disableIfChosenProduct = handleDisableIfChosenProduct(
                      product,
                      null,
                      allowMultipleSelect
                    );

                    const isNonInventory =
                      product?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

                    const setOutOfStock = (product) => {
                      if (!isOrder || !disableOutOfStock) return false;

                      return (
                        (!isNonInventory &&
                          disableOutOfStock &&
                          productsAvailableLessThanMinimum(product)) ||
                        (!isNonInventory &&
                          calcProductOutOfStockForParent(product))
                      );
                    };

                    const outOfStock = setOutOfStock(product);

                    const isChecked = handleProductsChecked(product);

                    const indeterminateParent = setIndeterminate(
                      product,
                      isChecked,
                      checkedProducts
                    );

                    const isMissingInfo = product?.missingFields;

                    return (
                      <Fragment key={product.id}>
                        <PopupProductsItem
                          isOrder={isOrder}
                          isPriceList={isPriceList}
                          productsRefs={productsRefs}
                          onMouseEnter={() => {
                            if (
                              productsAvailableLessThanMinimum(product) &&
                              !product?.isMultiple &&
                              disableOutOfStock &&
                              outOfStock
                            ) {
                              setCurrentProductRef(product?.id);
                              setTextProduct(
                                `Cannot add this product to the cart.\n Items Available: ${calcAvailable(
                                  product
                                )}, Minimum Order QTY: ${product?.minOrderQTY}`
                              );
                            }
                          }}
                          onMouseLeave={() => {
                            setCurrentProductRef(false);
                            setTextProduct("");
                          }}
                          addedProducts={addedProducts}
                          key={product.id}
                          product={product}
                          hideGreenNavigationBar={hideGreenNavigationBar}
                          skipExpanding={skipExpanding}
                          hideAvailable
                          expandedParentId={expandedParentId}
                          parentInOrder={
                            product.isMultiple &&
                            containsEvery(
                              product.childProducts.filter(
                                // if isMultiple, check if Added products (formfield) contain
                                // any of child products from this parent
                                (childProduct) =>
                                  !addedProducts?.some(
                                    (prod) => prod.id === childProduct.id
                                  )
                              ),
                              addedProducts
                            )
                          }
                          handleCheckProduct={handleCheckProduct}
                          supplierWithOnlyOneProductWithVariations={
                            supplierWithOnlyOneProductWithVariations &&
                            !!product?.childProducts?.length
                          }
                          handleExpandVariations={(id) => {
                            if (id === expandedParentId)
                              return setExpandedParentId(null);
                            setExpandedParentId(id);
                          }}
                          isChecked={isChecked}
                          isMissingInfo={isMissingInfo}
                          disabled={
                            handleProductsDisable(product, false) ||
                            disableIfChosenProduct ||
                            !!isMissingInfo?.length
                          }
                          indeterminateParent={indeterminateParent}
                          setExpandedParentId={setExpandedParentId}
                          disableIfChosenProduct={disableIfChosenProduct}
                          outOfStock={
                            outOfStock || areAllChildProductsDisabled(product)
                          }
                          {...{ hideAvailable }}
                        />
                        <Collapse
                          in={expandedParentId === product.id}
                          timeout={700}
                        >
                          {product.childProducts?.map(
                            (children, indexChild) => {
                              children.sellingOutOfStock =
                                product?.sellingOutOfStock;
                              children.minOrderQTY = product?.minOrderQTY;

                              const isNonInventory =
                                product?.type ===
                                PRODUCT_TYPE_INVENTORY.non_inventory;

                              const setOutOfStock = (children) => {
                                if (!isOrder || !disableOutOfStock)
                                  return false;
                                return (
                                  !isNonInventory &&
                                  disableOutOfStock &&
                                  productsAvailableLessThanMinimum(children)
                                );
                              };

                              const outOfStock = setOutOfStock(children);

                              const disableIfChosenProduct =
                                handleDisableIfChosenProduct(
                                  children,
                                  product,
                                  allowMultipleSelect
                                );
                              const isMissingInfo = children?.missingFields;

                              return (
                                <PopupProductsItem
                                  productsRefs={productsRefs}
                                  isPriceList={isPriceList}
                                  onMouseEnter={() => {
                                    if (outOfStock) {
                                      setCurrentProductRef(children?.id);
                                      setTextProduct(
                                        `Cannot add this product to the cart.\n Items Available: ${calcAvailable(
                                          children
                                        )}, Minimum Order QTY: ${
                                          product?.minOrderQTY
                                        }`
                                      );
                                    }
                                  }}
                                  hideAvailable={hideAvailable}
                                  onMouseLeave={() => {
                                    setCurrentProductRef(false);
                                    setTextProduct("");
                                  }}
                                  key={children.id}
                                  product={children}
                                  parent={product}
                                  hideGreenNavigationBar={
                                    hideGreenNavigationBar
                                  }
                                  skipExpanding={skipExpanding}
                                  supplierWithOnlyOneProductWithVariations={
                                    supplierWithOnlyOneProductWithVariations &&
                                    !!product?.childProducts?.length
                                  }
                                  handleExpandVariations={(id) => {
                                    if (id === expandedParentId)
                                      return setExpandedParentId(null);
                                    setExpandedParentId(id);
                                  }}
                                  isChild
                                  handleCheckProduct={handleCheckProduct}
                                  isChecked={checkedProducts.some(
                                    (checkedProduct) =>
                                      checkedProduct.id === children.id
                                  )}
                                  isMissingInfo={isMissingInfo}
                                  disabled={
                                    handleProductsDisable(children, true) ||
                                    disableIfChosenProduct ||
                                    !!isMissingInfo?.length
                                  }
                                  disableIfChosenProduct={
                                    disableIfChosenProduct
                                  }
                                  outOfStock={outOfStock}
                                  lastChild={
                                    product?.childProducts.length ===
                                    indexChild + 1
                                  }
                                  environment={environment}
                                />
                              );
                            }
                          )}
                        </Collapse>
                      </Fragment>
                    );
                  })
                ) : (
                  <Typography
                    fontWeight="300"
                    fontSize="16px"
                    textAlign="center"
                    width="100%"
                    color="#6A6A6A"
                    paddingTop="20px"
                    paddingBottom="20px"
                  >
                    No products found
                  </Typography>
                )}
              </InfiniteScrollWrapper>
            )}
          </Stack>
          <DialogActions sx={{ p: "24px 0px 0px" }}>
            <StyledButton
              label="Cancel"
              sx={{ height: "28px", mr: "4px" }}
              color="cancel"
              onClick={handleCancelProducts}
              fontSize="12px"
            />
            <StyledButton
              disabled={isAdmin}
              label="Add"
              variant="contained"
              sx={{ height: "28px", minWidth: "58px" }}
              fontSize="12px"
              onClick={handleAddProducts}
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

AllProductsPopup.propTypes = {
  isOpen: bool,
  allowSelectAll: bool,
  handleClose: func,
  handleCancelProducts: func,
  setCheckState: func,
  handleAddProducts: func,
  handleCheckProduct: func,
  checkedProducts: array,
  addedProducts: array,
  specificManufacturer: PropTypes.oneOfType([number, string, object]),
  disableOutOfStock: bool,
  isOrder: bool,
  isPriceList: bool,
  hideAvailable: bool,
  allowMultipleSelect: bool,
  skipExpanding: bool,
  hideGreenNavigationBar: bool,
  environment: string,
  priceListId: string,
};

AllProductsPopup.defaultProps = {
  isOpen: false,
  checkedProducts: [],
  addedProducts: [],
  disableOutOfStock: false,
  isOrder: false,
  isPriceList: false,
  hideAvailable: false,
  skipExpanding: false,
  priceListId: null,
  hideGreenNavigationBar: false,
};

export default AllProductsPopup;
