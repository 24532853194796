import { bool, string } from "prop-types";
import { Stack, Typography } from "@mui/material";
import moment from "moment-timezone";

export const NameBlock = ({
  name = "",
  date = "",
  timeZone = "",
  autofilled = false,
}) => {
  return (
    <Stack flexDirection="row" gap="4px" mb="4px">
      <Typography fontSize={12} fontWeight={700} color="#222" noWrap>
        {name}
      </Typography>

      <Typography
        fontSize={12}
        fontWeight={300}
        color="#707070CC"
        whiteSpace="nowrap"
      >
        {!!date && moment(date).tz(timeZone).format("MMM D YYYY [at] h:mm a z")}
      </Typography>

      {!!autofilled && (
        <Typography
          fontSize={12}
          fontWeight={500}
          color="#707070CC"
          whiteSpace="nowrap"
        >
          via Autofill
        </Typography>
      )}
    </Stack>
  );
};

NameBlock.propTypes = {
  name: string,
  date: string,
  timeZone: string,
  autofilled: bool,
};
