export const cl = {
  title: {
    fontSize: 12,
    fontWeight: 400,
    color: "#1C1C19",
    cursor: "pointer",
    lineHeight: "18px",
  },

  titleWrapper: {
    overflow: "hidden",
    width: "fit-content",
  },

  titleBlockWrapper: {
    gap: "15px",
    pr: 1,
    alignItems: "center",
  },
};
