import { createContext } from "react";
import { FormsTab, TabsPage, TasksTab } from "./components";
import { Box } from "@mui/material";
import {
  useAssignRapDrawer,
  useDueDateDrawer,
  useTasksPage,
  useTasksTotalSummaries,
} from "./hooks";
import { DueDateDrawer, RepsAssignedDrawer, TaskDrawer } from "components";
import { useTasks } from "./hooks/useTasks";
import { useForms } from "./hooks/useForms";
import { useFormsPage } from "./hooks/FormsPage.hooks";

export const TasksPageContext = createContext();
export const FormsPageContext = createContext();

const TasksPage = () => {
  const tasksState = useTasks();
  const formsState = useForms();

  const tasksPageState = useTasksPage({ tasksState });
  const formsPageState = useFormsPage({ formsState });

  const {
    TABS_PAGE,
    currentTab,
    checkedTasks,
    handleBulkAssignRepresentatives,
    handleSaveDueDate,
    taskDrawerState,
    handleCreateTask,
    handleCloseTaskDrawer,
    checkedCustomers,
    setCheckedCustomers,
    handleFetchList,
    repPermissions,
  } = tasksPageState;

  const tasksTotalSummaries = useTasksTotalSummaries();

  const assignRapDrawerState = useAssignRapDrawer();

  const dueDateDrawerState = useDueDateDrawer();

  const mapTabs = {
    0: <TasksTab />,
    1: <FormsTab />,
  };

  return (
    <>
      <TasksPageContext.Provider
        value={{
          handleCreateTask,
          ...tasksPageState,
          ...tasksTotalSummaries,
          tabs: TABS_PAGE,
          ...assignRapDrawerState,
          ...dueDateDrawerState,
          ...tasksState,
        }}
      >
        <FormsPageContext.Provider
          value={{ ...formsPageState, ...formsState, repPermissions }}
        >
          {taskDrawerState.open && (
            <TaskDrawer
              open={taskDrawerState.open}
              onClose={handleCloseTaskDrawer}
              data={taskDrawerState.data}
              selectedCustomers={checkedCustomers}
              setCheckedCustomers={setCheckedCustomers}
              isAdvancedDrawer={!taskDrawerState?.data?.customer}
              resetData={false}
              refetchCallback={handleFetchList}
            />
          )}

          <RepsAssignedDrawer
            isOpen={assignRapDrawerState?.open}
            handleClose={assignRapDrawerState?.handleCloseAssignRapDrawer}
            singleAddRep
            handleSetData={(rep) => {
              if (rep?.id)
                handleBulkAssignRepresentatives({
                  repId: rep.id,
                  isDistributor: rep?.role === "distributor",
                });
            }}
            withoutThirdParty
          />

          <DueDateDrawer
            open={dueDateDrawerState.open}
            handleClose={dueDateDrawerState.handleCloseDueDateDrawer}
            handleSave={handleSaveDueDate}
            checkedTasksIds={checkedTasks?.map((task) => task?.id)}
          />

          <TabsPage />
          <Box px={4}>{mapTabs[currentTab]}</Box>
        </FormsPageContext.Provider>
      </TasksPageContext.Provider>
    </>
  );
};

export default TasksPage;
