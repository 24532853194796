import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { error, success } from "../../../../../utils/notifications";
import { validationSchema } from "./ProfileTab.validations";
import lodash from "lodash";

import { Box, Divider, Tab, Tabs, Typography } from "@mui/material";
import StyledButton from "../../../../../components/StyledButton";
import { updateDistributorService } from "../../../../../services/account";
import { LocationsTab, SummaryTab } from "./components";
import CarriersTab from "./components/CarriersTab";
import { defaultValues } from "./ProfileTab.constants";
import { setCurrentUser } from "../../../../../redux/actions/auth";
import Loader from "../../../../../components/Loader";
import ErrorIcon from "@mui/icons-material/Error";
import { TAB_NAMES } from "./ProfileTab.constants";
import useStyles from "./newStyles";
import {
  setEditTypeAction,
  setFormChangedAction,
} from "../../../../../redux/actions/confirmDialogs";
import ValidationPopper from "../../../../../components/ValidationPopper/ValidationPopper";
import {
  ADMIN_ONLY_VIEW_MESSAGE_PAGE,
  COUNTRY_PHONE_CODES,
} from "../../../../../utils/constants";
import { useAdmin } from "helpers/helpers";
import { useRepsPermissions } from "helpers/hooks";
import { ActionRestrictionWrapper } from "components";

const NewProfile = () => {
  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();

  const classes = useStyles();
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const { state } = useLocation();
  const saveAnchor = useRef();

  useEffect(() => {
    const { profileTab } = state || {};
    if (![0, 1, 2].includes(profileTab)) return;
    setTabValue(profileTab);
  }, [state]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [tabValue, setTabValue] = useState(0);
  const [isEdit, setIsEdit] = useState(
    state?.type === "onboarding" ? true : false
  );
  const [loading, setLoading] = useState(false);
  const [validationPopperOpen, setValidationPopperOpen] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    setError,
    formState: { errors, isDirty },
    trigger,
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    shouldFocusError: false,
    defaultValues: {
      name: currentUser.name || defaultValues.name,
      companyIndustry:
        currentUser.companyIndustry || defaultValues.companyIndustry,
      description: currentUser.description || defaultValues.description,
      federalTaxId: currentUser.federalTaxId || defaultValues.federalTaxId,
      billingAddress:
        currentUser.billingAddress || defaultValues.billingAddress,
      shippingAddress:
        currentUser.shippingAddress || defaultValues.shippingAddress,
      shippingIsSame:
        currentUser.billingAddress?.formatted_address &&
        currentUser.billingAddress?.formatted_address ===
          currentUser.shippingAddress?.formatted_address,
      email: currentUser.baseUser?.email || defaultValues.email,
      countryPrefics: defaultValues?.countryPrefics,
      phone: currentUser?.phone
        ? currentUser?.phone?.replace("+1", "")
        : defaultValues?.phone,
      website: currentUser.website || defaultValues.website,
      timeZone: currentUser.timeZone || defaultValues.timeZone,
      profilePhoto: currentUser.profilePhoto || defaultValues.profilePhoto,
      contacts:
        currentUser.contacts?.map((contact) => ({
          ...contact,
          phone: contact?.phone?.replace("+1", ""),
          countryPrefix: COUNTRY_PHONE_CODES[0],
        })) || [],
      locations: currentUser.locations || [],
      shippingCarriers:
        currentUser.shippingCarriers || defaultValues.shippingCarriers,
    },
    resolver: yupResolver(
      validationSchema({
        onboardingSummary:
          state?.type === "onboarding" && state?.profileTab === 0,
      })
    ),
  });

  const formField = useWatch({ control });

  useEffect(() => {
    dispatch(setFormChangedAction(isDirty));
  }, [isDirty, dispatch]);

  useEffect(() => {
    dispatch(setEditTypeAction("profile", !isEdit));

    return () => dispatch(setEditTypeAction("", false));
  }, [dispatch, isEdit]);

  useEffect(() => {
    if (!isEdit)
      reset({
        name: currentUser.name || defaultValues.name,
        companyIndustry:
          currentUser.companyIndustry || defaultValues.companyIndustry,
        description: currentUser.description || defaultValues.description,
        federalTaxId: currentUser.federalTaxId || defaultValues.federalTaxId,
        billingAddress:
          currentUser.billingAddress || defaultValues.billingAddress,
        shippingAddress:
          currentUser.shippingAddress || defaultValues.shippingAddress,
        shippingIsSame:
          currentUser.billingAddress?.formatted_address &&
          currentUser.billingAddress?.formatted_address ===
            currentUser.shippingAddress?.formatted_address,
        email: currentUser.baseUser?.email || defaultValues.email,
        countryPrefics: defaultValues?.countryPrefics,
        phone: currentUser?.phone
          ? currentUser?.phone.replace("+1", "")
          : defaultValues?.phone,
        website: currentUser.website || defaultValues.website,
        timeZone: currentUser.timeZone || defaultValues.timeZone,
        profilePhoto: currentUser.profilePhoto || defaultValues.profilePhoto,
        contacts:
          currentUser.contacts?.map((contact) => ({
            ...contact,
            phone: contact?.phone?.replace("+1", ""),
            countryPrefix: COUNTRY_PHONE_CODES[0],
          })) || [],
        locations: currentUser.locations || [],
        shippingCarriers:
          currentUser.shippingCarriers || defaultValues.shippingCarriers,
      });
  }, [isEdit, currentUser, reset]);

  const isDisabled = useMemo(() => {
    return !formField?.name || !formField?.federalTaxId || !formField?.phone;
  }, [formField]);

  const initialErrPhoneStatus = useMemo(
    () => ({
      status: false,
      message: "",
      value: "",
    }),
    []
  );

  const handleStreetError = useCallback(async () => {
    const error = {};
    const skipCheck = state?.type === "onboarding" && state?.profileTab === 0;

    if (!formField.shippingAddress.street && !skipCheck) {
      error.shippingAddress = "The field is required";
      setError("shippingAddress.formatted_address", {
        type: "custom",
        message: "field is required",
      });
    }

    if (!formField.billingAddress.street) {
      error.billingAddress = "The field is required";
      setError("billingAddress.formatted_address", {
        type: "custom",
        message: "field is required",
      });
    }

    if (formField.locations.length) {
      formField.locations.map((item, index) => {
        if (!item.street) {
          error.locations = "The field is required";
          setError(`locations[${index}].formatted_address`, {
            type: "custom",
            message: "field is required",
          });
        }
      });
    }

    if (Object.keys(error).length) return true;
    return false;
  }, [
    formField.billingAddress.street,
    formField.locations,
    formField.shippingAddress.street,
    setError,
    state?.profileTab,
    state?.type,
  ]);

  const [errPhoneStatus, setErrPhoneStatus] = useState(initialErrPhoneStatus);

  const handleUpdateProfile = useCallback(
    (data) => {
      handleStreetError().then((hasErrors) => {
        if (!hasErrors) {
          setLoading(true);
          updateDistributorService(data)
            .then((res) => {
              setLoading(false);
              dispatch(setCurrentUser(res));
              success("Distributor updated successfully.");
              setIsEdit(false);
              setErrPhoneStatus(initialErrPhoneStatus);
              if (state?.type === "onboarding") navigate("/");
            })
            .catch((err) => {
              setLoading(false);
              // eslint-disable-next-line no-console
              if (/phone/.test(err?.response?.data?.message)) {
                return setErrPhoneStatus((prev) => ({
                  ...prev,
                  status: true,
                  message: "Not valid phone number!",
                  value: formField?.phone,
                }));
              }

              if (err?.response?.status === 422) {
                if (/address/gim.test(err?.response?.data?.message)) {
                  return error(
                    "'Enter valid address' message should be shown up instead of the current one"
                  );
                }
              }

              error(err?.response?.data?.message || "Something went wrong.");
            });
        } else {
          error("Street address is required");
        }
      });
    },
    [
      dispatch,
      formField?.phone,
      handleStreetError,
      initialErrPhoneStatus,
      navigate,
      state?.type,
    ]
  );

  const onSubmit = (data) => {
    if (!isEdit) return setIsEdit(true);
    const profilePhotoId = data?.profilePhoto?.id || null;
    const contacts = data.contacts.map(
      ({ distributorId, countryPrefix, ...c }) => ({
        ...c,
        phone: `${countryPrefix?.phone_code}${c?.phone}`.replace(
          /\s|\(|\)/g,
          ""
        ),
      })
    );
    const {
      shippingIsSame,
      profilePhoto,
      carriers,
      phone,
      shippingAddress,
      countryPrefics,
      groupContentId,
      ...uploadData
    } = {
      ...data,
      federalTaxId: parseInt(data.federalTaxId?.replace("-", "")) || "",
      contacts,
    };
    const cleanData = lodash.pickBy(uploadData, (value) => value !== "");

    // temporary
    if (shippingAddress?.formatted_address)
      cleanData.shippingAddress = shippingAddress;

    // temporary
    if (!shippingAddress?.formatted_address) {
      cleanData.shippingAddress = null;
    }

    handleUpdateProfile({
      ...cleanData,
      profilePhotoId,
      phone: `${countryPrefics?.phone_code}${phone}`.replace(/\s|\(|\)/g, ""),
      // federalTaxId: parseInt(data.federalTaxId?.replace("-", "")),
    });
  };

  const handleRemovePhoto = useCallback(async () => {
    await handleUpdateProfile({ profilePhotoId: null });
    setValue("groupContentId", "");
    setValue("profilePhoto", null);
  }, [handleUpdateProfile, setValue]);

  const settingsPage = useMemo(() => {
    switch (tabValue) {
      case 0:
        return (
          <SummaryTab
            {...{
              isEdit,
              control,
              setValue,
              clearErrors,
              trigger,
              errPhoneStatus,
              handleRemovePhoto,
            }}
            distributorId={currentUser?.id}
            userCountry={currentUser?.country}
          />
        );

      case 1:
        return (
          <LocationsTab
            {...{
              isEdit,
              control,
              setValue,
              trigger,
              clearErrors,
            }}
            userCountry={currentUser?.country}
          />
        );

      case 2:
        return <CarriersTab {...{ isEdit, control, setValue }} />;

      default:
        return <></>;
    }
  }, [
    tabValue,
    isEdit,
    control,
    setValue,
    clearErrors,
    trigger,
    errPhoneStatus,
    handleRemovePhoto,
    currentUser.id,
    currentUser.country,
  ]);

  const checkError = (tab) => {
    const {
      name,
      federalTaxId,
      billingAddress,
      shippingAddress,
      companyIndustry,
      timeZone,
      profilePhoto,
      email,
      phone,
      website,
      contacts,
      locations,
      shippingCarriers,
    } = errors;

    switch (tab) {
      case "Summary": {
        return (
          !!name ||
          !!federalTaxId ||
          !!companyIndustry ||
          !!timeZone ||
          !!profilePhoto ||
          !!email ||
          !!phone ||
          !!website ||
          !!contacts
        );
      }
      case "Locations":
        return !!billingAddress || !!shippingAddress || !!locations;
      case "Carriers":
        return !!shippingCarriers;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (formField?.name) clearErrors("name");
    if (formField?.federalTaxId) clearErrors("federalTaxId");
    if (formField?.phone) clearErrors("phone");
  }, [clearErrors, formField]);

  const onError = () => {
    setValidationPopperOpen(true);
  };

  return (
    <>
      <Loader isLoading={loading} />
      <ValidationPopper
        isOpen={validationPopperOpen}
        anchorEl={saveAnchor.current}
        errors={errors}
        setIsOpen={setValidationPopperOpen}
        tailProps={{
          top: -6,
          right: 28,
          rotate: -180,
        }}
        offset={[-255, 16]}
      />
      <Box className={classes.profileHeader}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="1px"
        >
          <Typography className={classes.title}>Account Profile</Typography>
          <Box className={classes.buttonsBlock}>
            {isEdit ? (
              <>
                <ActionRestrictionWrapper
                  disableHoverListener={!repPermissions}
                >
                  <StyledButton
                    label="Cancel"
                    variant="outlined"
                    color="edit"
                    disabled={!!repPermissions}
                    onClick={() => setIsEdit(false)}
                    className={classes.editButton}
                    fontSize="14px"
                  />
                </ActionRestrictionWrapper>
                <Box
                  onMouseEnter={() => {
                    onError();
                    if (trigger) trigger();
                  }}
                  component="span"
                >
                  <StyledButton
                    disabled={isDisabled}
                    ref={saveAnchor}
                    label="Save"
                    variant="contained"
                    color="primary"
                    className={classes.editButton}
                    fontSize="14px"
                    type="submit"
                    form="new-profile-form"
                  />
                </Box>
              </>
            ) : (
              <ActionRestrictionWrapper
                disableHoverListener={!repPermissions}
                title={ADMIN_ONLY_VIEW_MESSAGE_PAGE}
              >
                <StyledButton
                  disabled={isAdmin || !!repPermissions}
                  label="Edit"
                  variant="outlined"
                  color="edit"
                  onClick={() => setIsEdit(true)}
                  className={classes.editButton}
                  fontSize="14px"
                />
              </ActionRestrictionWrapper>
            )}
          </Box>
        </Box>
        <Tabs
          value={tabValue}
          onChange={(e, newTab) => setTabValue(newTab)}
          sx={{
            "& .Mui-selected": {
              backgroundColor: "transparent!important",
            },
          }}
        >
          {TAB_NAMES.map((tab, i) => (
            <Tab
              sx={{
                py: 0,
                height: "44px",
                minHeight: "44px",
                fontSize: "23px",
                fontWeight: 400,
                color: "#707070",
                textTransform: "none",
              }}
              key={tab}
              label={tab}
              icon={
                checkError(tab) ? (
                  <ErrorIcon style={{ fill: "#ff6254", fontSize: "20px" }} />
                ) : (
                  ""
                )
              }
              iconPosition="start"
              value={i}
              // disabled={tab === "Delivery" || tab === "Sales"}
            />
          ))}
        </Tabs>
      </Box>
      <Divider />
      <Box
        className={classes.profileInnerPage}
        component="form"
        id="new-profile-form"
        onSubmit={handleSubmit(onSubmit, onError)}
        height="100%"
      >
        {settingsPage}
      </Box>
    </>
  );
};

export default NewProfile;
