export const cl = {
  listWrapper: {
    borderWidth: "0 1px 1px 1px",
    borderStyle: "solid",
    borderColor: "#D5D9D9",
    borderRadius: "0 0 4px 4px",
    height: "100%",
    maxHeight: "calc(100vh - 327px)",
  },

  actionWrapperPropsSx: {
    padding: "0 19px 0 14px !important",
  },
};
