export const cl = {
  itemWrapper: {
    width: "100%",
    height: "100%",
    pt: "16px",
    pb: "24px",
    px: "24px",
    background: "#ffff",
    borderRadius: "4px",
    flexDirection: "column",
    cursor: "pointer",
    transition: "all 0.3s",
  },

  checkboxLabel: {
    fontSize: "13px",
    color: "#707070",
    ml: "5px",
  },

  checkboxFormStyles: {
    "& span": { p: 0 },
    ml: 0,
    mr: "20px",
  },

  titleBlockWrapper: {
    width: "100%",
    gap: "8px",
    justifyContent: "space-between",
    mb: "4px",
  },

  handlerWrapper: {
    width: "100%",
    alignItems: "center",
  },

  iconWrapper: {
    width: "26px",
    height: "26px",
    background: "#47A06D",
    borderRadius: "3px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",

    svg: {
      transform: "rotate(0deg)",
      path: {
        fill: "#ffff",
      },
    },
  },

  selectStyles: {
    "& fieldset": {
      borderRadius: "4px !important",
      borderColor: "#F2F2F2",
    },
    "& .MuiSelect-select": {
      pl: "7px",
      pr: "31px !important",
      paddingTop: "6px !important",
      paddingBottom: "6px !important",
    },
  },

  selectFormStyles: {
    height: "38px",
    width: "fit-content",
  },

  selectPaperStyles: {
    zIndex: 1502,
    padding: "3px 16px",
    background: "white",
    boxShadow: "0px 2px 25px #E5E5E5",
    borderRadius: "4px",
    overflow: "hidden",
    border: "1px rgba(201, 201, 201, 0.20) solid",
    gap: "10px",
  },

  textFieldStyles: {
    "&.MuiOutlinedInput-root": {
      p: "0 !important",
    },

    fontSize: "18px",
    borderRadius: "6px",
    fontWeight: 400,
    backgroundColor: "#ffff",

    "& > fieldset": {
      borderColor: "#ffff",
      borderWidth: "0px !important",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0 8px",
    },
  },

  selectMenuItem: {
    backgroundColor: "#ffff !important",
    fontWeight: 500,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "6px",
    px: 0,
  },

  activeTextFieldStyles: {
    backgroundColor: "rgba(95, 95, 95, 0.05)",

    "& > fieldset": {
      borderColor: "rgba(95, 95, 95, 0.05)!important",
      borderWidth: "0px !important",
      width: "fit-content",
    },
  },
};
