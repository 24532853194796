import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import _, { isEqual } from "lodash";

import { Box } from "@mui/material";
import { NewDashboardFilter } from "./components";
import ListView from "./components/Views/ListView/ListView";
import StyledButton from "../../components/StyledButton";
import TabMapComponent from "../CustomersPage/components/CustomersTab/components/TabsContent/TabMapComponent";
import { ListIcon } from "../../components/Icons/MenuIcons/ListIcon";

import {
  getTopSummariesService,
  getOrdersDashboardService,
  getBottomLeftPartService,
  getChartService,
} from "../../services/dashboard.js";

import { VIEWS } from "./DashboardPage.constants";
import { error } from "../../utils/notifications.js";
import { SCROLL_LIMIT_DASHBOARD_ORDERS } from "../../utils/constants";
import { repsListSelector } from "../../redux/selectors/reps";
import { useActivities } from "./useActivities";
import AssignedRepsPopper from "../CustomersPage/components/CustomersTab/components/AssignedRepsPopper/AssignedRepsPopper";
import { ArrowLeftIcon } from "../../components/Icons";
import { OnboardingTasks } from "../../components";
import { useOnboardingActions } from "./useOnboardingActions";
import { useRepsPermissions } from "helpers/hooks";

const selector = createSelector(repsListSelector, (repsList) => ({ repsList }));

const DashboardPage = () => {
  const repPermissions = useRepsPermissions();
  const { repsList } = useSelector(selector);
  const [isLoadingTopSummaries, setIsLoadingTopSummaries] = useState(false);
  const [
    isLoadingOrdersListAndTotalOrders,
    setIsLoadingOrdersListAndTotalOrders,
  ] = useState(false);
  const [isLoadingBottomLeftPart, setIsLoadingBottomLeftPart] = useState(false);
  const [currentView, setCurrentView] = useState(VIEWS.LIST);
  const [switchToView, setSwitchToView] = useState(VIEWS.MAP);
  const [currentFilterDate, setCurrentFilterDate] = useState("This week");
  const [currentRepresentative, setCurrentRepresentative] = useState("");
  const [isMerchendiser, setIsMerchendiser] = useState(false);
  const [isThirdParty, setIsThirdParty] = useState(false);
  const [topSummaries, setTopSummaries] = useState({});
  const [ordersList, setOrdersList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [totalOrders, setTotalOrders] = useState({});
  const [insights, setInsights] = useState({});
  const [chartList, setChartList] = useState([]);
  const [loadingChartList, setLoadingChartList] = useState(true);
  const [currentTabOrders, setCurrentTabOrders] = useState({
    type: "",
    status: "",
  });

  const [assignedPopperState, setAssignedPopperState] = useState({
    rep: null,
    open: false,
    anchorEl: null,
  });

  const [currentTabActivities, setCurrentTabActivities] = useState("");

  const [params, setParams] = useState({
    representativeId: "",
    date: "this_week",
    start_date: null,
    end_date: null,
  });
  const {
    isLoadingActivitiesList,
    activitiesList,
    handleFetchActivities,
    hasMoreActivities,
    getActivities,
  } = useActivities({
    currentRepresentative,
    currentFilterDate,
    currentTabActivities,
  });

  const paramsRef = useRef(params);

  useEffect(() => {
    const isDateString = typeof currentFilterDate === "string";
    const { start_date, end_date } = prepereQuaryDate(currentFilterDate);

    const newParams = {
      representativeId: currentRepresentative,
      date: isDateString ? _.snakeCase(currentFilterDate) : null,
      start_date: isDateString ? null : start_date,
      end_date: isDateString ? null : end_date,
    };

    if (!isEqual(newParams, paramsRef.current)) {
      setParams(newParams);
      paramsRef.current = newParams;
    }
  }, [currentFilterDate, currentRepresentative]);

  const resetFilters = () => {
    setCurrentFilterDate("This week");
    setCurrentRepresentative("");
    setIsMerchendiser(false);
  };

  const handleSetCurrentFilterDate = (data) => {
    if (data !== undefined) setCurrentFilterDate(data);
  };
  const handleSetCurrentRepresentative = (data) => {
    setCurrentRepresentative(data);
  };

  const prepereQuaryDate = (date) => {
    if (typeof date === "string") return {};
    const start_date = date?.[0]?.format()?.split("+")?.[0];
    const end_date = date?.[1]?.format()?.split("+")?.[0];
    return { start_date, end_date };
  };

  useEffect(() => {
    let didCancel = false;

    if (repPermissions && !repPermissions?.hasAnyCheckedTrue) return;
    async function getTopSummaries() {
      setIsLoadingTopSummaries(true);

      return getTopSummariesService(params)
        .then((res) => {
          if (didCancel) return;
          setTopSummaries(res);
          setIsLoadingTopSummaries(false);
        })
        .catch((err) => {
          setIsLoadingTopSummaries(false);
          if (err?.message === "canceled") return;
          error("Something went wrong.");
        });
    }

    getTopSummaries();

    return () => {
      didCancel = true;
    };
  }, [params, repPermissions]);

  const [ordersPage, setOrdersPage] = useState(1);

  const preparedParams = useMemo(() => {
    const isDateString = typeof currentFilterDate === "string";
    const { start_date, end_date } = prepereQuaryDate(currentFilterDate);
    setOrdersPage(1);

    return {
      representativeId: currentRepresentative,
      date: isDateString ? _.snakeCase(currentFilterDate) : null,
      start_date: isDateString ? null : start_date,
      end_date: isDateString ? null : end_date,
      order_status: currentTabOrders.status,
      type: currentTabOrders.type,
      page: 1,
      limit: SCROLL_LIMIT_DASHBOARD_ORDERS,
    };
  }, [currentFilterDate, currentRepresentative, currentTabOrders]);

  useEffect(
    () => {
      let didCancel = false;

      if (repPermissions && !repPermissions?.hasAnyCheckedTrue) return;
      async function getOrdersListAndTotalOrders() {
        setIsLoadingOrdersListAndTotalOrders(true);

        getOrdersDashboardService(preparedParams)
          .then((res) => {
            if (didCancel) return;
            setOrdersList(() => res?.rows);
            setTotalOrders(res?.totalOrders);
            setHasMore(
              Math.ceil(res?.count / SCROLL_LIMIT_DASHBOARD_ORDERS) > ordersPage
            );
            setIsLoadingOrdersListAndTotalOrders(false);
          })
          .catch((err) => {
            setIsLoadingOrdersListAndTotalOrders(false);
            if (err?.message === "canceled") return;
            error("Something went wrong.");
          });
      }

      getOrdersListAndTotalOrders();

      return () => {
        didCancel = true;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [preparedParams]
  );

  const handleFetchOrders = () => {
    getOrdersDashboardService({ ...preparedParams, page: ordersPage + 1 })
      .then((res) => {
        setOrdersList([...ordersList, ...res?.rows]);
        setTotalOrders(res?.totalOrders);
        setHasMore(
          Math.ceil(res?.count / SCROLL_LIMIT_DASHBOARD_ORDERS) > ordersPage
        );
        setOrdersPage(ordersPage + 1);
      })
      .catch((err) => {
        if (err?.message === "canceled") return;
        error("Something went wrong.");
      });
  };

  useEffect(() => {
    let didCancel = false;

    if (repPermissions && !repPermissions?.hasAnyCheckedTrue) return;
    async function getBottomLeftPart() {
      setIsLoadingBottomLeftPart(true);

      getBottomLeftPartService({ representativeId: params.representativeId })
        .then((res) => {
          if (didCancel) return;
          setInsights({ ...res });
          setIsLoadingBottomLeftPart(false);
        })
        .catch((err) => {
          setIsLoadingBottomLeftPart(false);
          if (err?.message === "canceled") return;
          error("Something went wrong.");
        });
    }
    getBottomLeftPart();

    return () => {
      didCancel = true;
    };
  }, [params.representativeId, repPermissions]);

  useEffect(() => {
    let didCancel = false;

    if (repPermissions && !repPermissions?.hasAnyCheckedTrue) return;
    setLoadingChartList(true);
    async function getChart() {
      getChartService(params)
        .then((res) => {
          if (didCancel) return;
          setChartList(res);
        })
        .catch((err) => {
          if (err?.message === "canceled") return;
          error("Something went wrong.");
        })
        .finally(() => {
          setLoadingChartList(false);
        });
    }

    getChart();

    return () => {
      didCancel = true;
    };
  }, [params, repPermissions]);

  const handleChangeView = () => {
    setCurrentView(switchToView);
    setSwitchToView(currentView);
  };

  useEffect(() => {
    const res = repsList.find((rep) => rep.id === currentRepresentative)?.role;
    setIsMerchendiser(res === "MERCHANDISER");
    setIsThirdParty(res === "THIRD_PARTY");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRepresentative]);

  const parseDate =
    typeof currentFilterDate === "string"
      ? currentFilterDate
      : currentFilterDate[1]
      ? `${currentFilterDate[0]?.format(
          "MM/DD/YYYY"
        )} - ${currentFilterDate[1]?.format("MM/DD/YYYY")}`
      : `${currentFilterDate[0]?.format("MM/DD/YYYY")}`;

  const handleSetAssignedPopover = (anchorEl, rep, open) => {
    setAssignedPopperState({ anchorEl, rep, open });
  };

  const {
    isOpenDialog,
    stateOnboarding,
    handleFetchOnboarding,
    handleCloseOnboarding,
  } = useOnboardingActions();

  return (
    <Box position="relative">
      <OnboardingTasks
        open={isOpenDialog}
        stateOnboarding={stateOnboarding}
        onFetch={handleFetchOnboarding}
        onClose={handleCloseOnboarding}
      />
      <Box px={4} pt={2}>
        <AssignedRepsPopper
          anchorEl={assignedPopperState.anchorEl}
          rep={assignedPopperState.rep}
          open={assignedPopperState.open}
        />
        {currentView === VIEWS.LIST && (
          <NewDashboardFilter
            currentView={currentView}
            switchToView={switchToView}
            handleChangeView={handleChangeView}
            handleSetCurrentFilterDate={handleSetCurrentFilterDate}
            currentRepresentative={currentRepresentative}
            handleSetCurrentRepresentative={handleSetCurrentRepresentative}
            resetFilters={resetFilters}
          />
        )}
        {currentView === VIEWS.MAP && (
          <StyledButton
            fontSize="16px"
            sx={{
              flex: "0 1 8%",
              backgroundColor: "#fff",
              height: "39px",
              border: "0.5px solid #D5D9D9",
              whiteSpace: "nowrap",
              zIndex: "100",
              position: "absolute",
              right: 49,
              top: 30,
              width: "115px",
              color: "#5F6267",
              display: "flex",
              justifyContent: "flex-start",
            }}
            variant="contained"
            color="white"
            label={switchToView}
            height="39px"
            onClick={handleChangeView}
            startIcon={<ArrowLeftIcon color="#5F6267" />}
            endIcon={
              <Box sx={{ ml: "auto", height: "20px" }}>
                <ListIcon color="#5F6267" />
              </Box>
            }
          />
        )}
        {currentView === VIEWS.LIST ? (
          <ListView
            isLoadingTopSummaries={isLoadingTopSummaries}
            isLoadingOrdersListAndTotalOrders={
              isLoadingOrdersListAndTotalOrders
            }
            isLoadingActivitiesList={isLoadingActivitiesList}
            isLoadingBottomLeftPart={isLoadingBottomLeftPart}
            topSummaries={topSummaries}
            ordersList={ordersList}
            totalOrders={totalOrders}
            insights={insights}
            activitiesList={activitiesList}
            chartList={chartList}
            currentFilterDate={parseDate}
            currentRepresentative={currentRepresentative}
            isMerchendiser={isMerchendiser}
            handleFetchOrders={handleFetchOrders}
            handleFetchActivities={handleFetchActivities}
            dataLength={ordersList?.length}
            hasMore={hasMore}
            hasMoreActivities={hasMoreActivities}
            currentTabOrders={currentTabOrders}
            setCurrentTabOrders={setCurrentTabOrders}
            currentTabActivities={currentTabActivities}
            setCurrentTabActivities={setCurrentTabActivities}
            params={params}
            getActivities={getActivities}
            {...{ isThirdParty, loadingChartList }}
          />
        ) : (
          <TabMapComponent
            autoZoomDistributor
            setAssignedAnchorEl={handleSetAssignedPopover}
            customMapOptions={{
              zoomControlOptions: {
                position: 7,
              },
              fullscreenControlOptions: {
                position: 7,
              },
            }}
            styles={{
              height: "calc(100vh - 72px)",
              position: "absolute",
              top: "0px",
              left: 0,
              p: 0,
              "button.gm-control-active.gm-fullscreen-control": {
                right: "40px !important",
                top: "70px!important",
              },
              "div.gmnoprint": {
                right: "80px !important",
                top: "70px!important",

                left: "none !important",
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default DashboardPage;
