import { string } from "prop-types";
import { Stack, Typography } from "@mui/material";

export const TitleBlock = ({ title = "Forms" }) => {
  return (
    <Stack>
      <Typography fontSize={18} fontWeight={400} color="#3F3F3F">
        {title}
      </Typography>
    </Stack>
  );
};

TitleBlock.propTypes = { title: string };
