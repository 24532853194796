import { useState, useMemo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createSelector } from "reselect";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { cl } from "./PaymentsTab.styles";

import { BillingTab, PayoutsTab, TransactionsTab } from "./components";
import CustomerCardPopup from "../../../../CustomersPage/components/CustomerCreditCards/components/CustomerCardPopup/CustomerCardPopup";
import EditPaymentPopup from "../../../../CustomersPage/components/CustomerCreditCards/components/EditPaymentPopup/EditPaymentPopup";

import { validationSchema } from "../../../../CustomersPage/pages/NewCustomerPage/NewCustomerPage.validation";
import {
  currentUserSelector,
  distributorPaymentCardsSelector,
} from "../../../../../redux/selectors/auth";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useRepsPermissions } from "helpers/hooks";
import { getTransactionsAction } from "redux/actions/transactions";
import { ADMIN_ONLY_VIEW_MESSAGE_PAGE, SCROLL_LIMIT } from "utils/constants";
import { CloneProps, StyledTooltip } from "components";

const selector = createSelector(
  currentUserSelector,
  distributorPaymentCardsSelector,
  (currentUser, distributorPaymentCards) => ({
    currentUser,
    distributorPaymentCards,
  })
);

const PaymentsTab = () => {
  const { currentUser, distributorPaymentCards } = useSelector(selector);
  const [cardOpen, setCardOpen] = useState(false);
  const [editPaymentOpen, setEditPaymentOpen] = useState(false);
  const [editCard, setEditCard] = useState(null);
  const repPermissions = useRepsPermissions();
  const dispatch = useDispatch();

  const TABS = useMemo(
    () => [
      {
        name: "Billing",
        value: 0,
        isDisabled: repPermissions,
      },
      {
        name: "Payouts",
        value: 1,
        isDisabled: repPermissions,
      },
      {
        name: "Transactions",
        value: 2,
        isDisabled: false,
      },
    ],
    [repPermissions]
  );

  const [currentTab, setCurrentTab] = useState(0);
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { control, setValue } = useForm({
    mode: "onChange",
    shouldFocusError: false,
    defaultValues: {
      creditCards:
        distributorPaymentCards?.map((card) => {
          return { ...card, name: card?.billingAddress?.name };
        }) || [],
    },
    resolver: yupResolver(validationSchema({ activeOrderCount: 0 })),
  });

  const handleEditCard = (card) => {
    setEditPaymentOpen(true);
    setEditCard(card);
  };

  useEffect(
    () => dispatch(getTransactionsAction({ limit: SCROLL_LIMIT })),
    [dispatch]
  );

  useEffect(() => {
    const subTab = searchParams.get("subTab");
    if (subTab === "Payouts") {
      setCurrentTab(TABS[1].value);
    }
  }, [TABS, navigate, searchParams]);

  const handleAddCard = useCallback(() => setCardOpen(true), []);

  const paymentsBody = useMemo(() => {
    switch (currentTab) {
      case 0:
        return (
          <BillingTab
            distributorId={currentUser?.id}
            control={control}
            setValue={setValue}
            onAddCard={handleAddCard}
            onEditCard={handleEditCard}
          />
        );
      case 1:
        return <PayoutsTab />;
      case 2:
        return <TransactionsTab />;

      default:
        return (
          <BillingTab
            distributorId={currentUser?.id}
            control={control}
            setValue={setValue}
            onAddCard={handleAddCard}
            onEditCard={handleEditCard}
          />
        );
    }
  }, [control, currentTab, currentUser?.id, handleAddCard, setValue]);

  useEffect(() => {
    setValue(
      "creditCards",
      distributorPaymentCards?.map((card) => {
        return { ...card, name: card?.billingAddress?.name };
      })
    );
  }, [distributorPaymentCards, setValue]);

  useEffect(() => {
    const { paymentsTab } = state || {};
    const tab =
      !isNaN(paymentsTab) &&
      typeof paymentsTab === "number" &&
      paymentsTab <= TABS.length;
    let preparedTab;
    tab
      ? (preparedTab = paymentsTab)
      : (preparedTab = TABS.findIndex((t) => t.name === paymentsTab)?.value);

    if (preparedTab) setCurrentTab(preparedTab);
  }, [TABS, state]);

  useEffect(() => {
    if (repPermissions) {
      setCurrentTab(2);
    }
  }, [TABS, repPermissions]);

  return (
    <>
      <CustomerCardPopup
        isOpen={cardOpen}
        handleClose={() => setCardOpen(false)}
        distributorId={currentUser?.id}
        billingAddress={currentUser?.billingAddress}
        handleSave={(data) => setValue("creditCards", data)}
      />
      <EditPaymentPopup
        isOpen={editPaymentOpen}
        handleClose={() => setEditPaymentOpen(false)}
        distributorId={currentUser?.id}
        card={editCard}
        handleSave={(data) => setValue("creditCards", data)}
      />
      <Paper
        sx={{
          padding: "24px 32px 19px 34px",
          borderRadius: "8px",
        }}
        elevation={0}
        component={Grid}
        container
      >
        <Grid alignItems="flex-end" xs={12} item container mb="4px">
          <Typography sx={cl.title}>Payments</Typography>
          <Tabs
            value={currentTab}
            onChange={(e, newValue) => setCurrentTab(newValue)}
            sx={cl.tabs}
          >
            {TABS.map(({ value, name, isDisabled }) => (
              <CloneProps key={value}>
                {(cloneProps) => (
                  <StyledTooltip
                    key={name}
                    disableHoverListener={!isDisabled}
                    title={ADMIN_ONLY_VIEW_MESSAGE_PAGE}
                    arrow
                    placement="top"
                    PopperProps={{
                      modifiers: [
                        { name: "offset", options: { offset: [0, -12] } },
                      ],
                    }}
                  >
                    <Box value={value}>
                      <Tab
                        {...cloneProps}
                        disabled={isDisabled}
                        className="profile-tabs"
                        sx={cl.tab}
                        label={<Box sx={cl.tabLabel}>{name}</Box>}
                        value={value}
                      />
                    </Box>
                  </StyledTooltip>
                )}
              </CloneProps>
            ))}
          </Tabs>
        </Grid>
        {paymentsBody}
      </Paper>
    </>
  );
};

export default PaymentsTab;
