import { Box, Stack, Typography } from "@mui/material";
import { StyledTooltip } from "components";
import { DangerIcon } from "components/Icons";
import { object } from "prop-types";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openDiscardChanges } from "redux/actions/confirmDialogs";

export const BusinessNameTooltip = ({ error, children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleUserError = useCallback(() => {
    if (!error) return { type: "", id: null };

    const errorData = error.type.split("_");
    return { type: errorData[0], id: errorData[1] };
  }, [error]);

  const { type, id } = handleUserError();

  const handleRedirect = (url, state = {}) => {
    dispatch(openDiscardChanges(() => navigate(url, state)));
  };

  const errorMessageText = error?.message?.split("(ID:");

  const title =
    type === "active"
      ? "Another customer is already using this name"
      : "Inactive customers found with same name";

  const subTitle =
    type === "active"
      ? "Please use a different name"
      : "Or use a different name";

  const showOpenTypes =
    type === "active" || type === "inactive" || type === "specialCharacter";

  return (
    <StyledTooltip
      sx={{ zIndex: 1299 }}
      open={!!error && showOpenTypes}
      modifiers={[
        {
          name: "offset",
          options: { offset: [10, 10] },
        },
      ]}
      arrow
      placement="top"
      aria-hidden="true"
      title={
        <Box>
          {type === "active" || type === "inactive" ? (
            <Stack
              alignItems="flex-start"
              sx={{
                "& .MuiTypography-root": {
                  fontSize: "12px",
                  lineHeight: "20px",
                  whiteSpace: "nowrap",
                  color: "#1C1C19",
                },
                svg: {
                  width: "12px",
                  height: "12px",
                },
              }}
            >
              <Stack direction="row" alignItems="center" gap="5px">
                <DangerIcon />
                <Typography>{title}</Typography>
              </Stack>

              {errorMessageText && errorMessageText.length && (
                <Stack
                  width="100%"
                  gap="20px"
                  direction="row"
                  sx={{
                    "& .MuiTypography-root": {
                      cursor: "pointer",
                      flexWrap: "nowrap",

                      "&:hover": {
                        textDecoration: "underline",
                      },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      span: {
                        fontWeight: 600,
                      },
                    }}
                    onClick={() => handleRedirect(`/customers/${id}`)}
                  >
                    <span> {errorMessageText[0] || ""}</span> (ID:
                    {errorMessageText[1] || ""}
                  </Typography>

                  {type === "inactive" && (
                    <Typography
                      sx={{
                        color: "#47A06D !important",
                        ml: "auto",
                        textDecoration: "underline",
                      }}
                      onClick={() =>
                        handleRedirect(`/customers/edit/${id}`, {
                          state: { status: "ACTIVE" },
                        })
                      }
                    >
                      Make Active
                    </Typography>
                  )}
                </Stack>
              )}

              <Typography>{subTitle}</Typography>
            </Stack>
          ) : (
            <>{error?.message}</>
          )}
        </Box>
      }
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            padding: "12px 16px !important",
            marginBottom: "19px !important",
            maxWidth: "100%",
          },
        },
        modifiers: [
          {
            name: "offset",
            options: { offset: [0, -8] },
          },
        ],
      }}
    >
      {children}
    </StyledTooltip>
  );
};

BusinessNameTooltip.propTypes = { children: object, error: object };
