import { object, func, bool } from "prop-types";
import { Box, Grid, MenuItem } from "@mui/material";

import { Controller } from "react-hook-form";
import { StyledButton, StyledTextField } from "components";
import NumberFormat from "react-number-format";
import StyledSelect from "components/StyledSelect";
import { TYPES } from "components/ContactsSection/ContactPopup/contactTypes";
import { OutlinedPlusIcon } from "components/Icons";
import useStyles from "./styles";
import { normalizeUppercaseString } from "helpers/helpers";

export const BodyAddContactDrawer = ({
  onClose,
  control,
  setValue,
  formField,
  isDisabled,
  handleSubmit,
}) => {
  const classes = useStyles();

  const isCustom = (role) => {
    return ![...TYPES, ""].includes(role);
  };

  return (
    <>
      <Box
        sx={{ p: "10px 42px 24px 47px", minWidth: "413px" }}
        id="notes-scroll"
      >
        <Grid display="flex" sx={{ flexDirection: "column" }} container>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                formSx={{ mt: "15px" }}
                fullWidth
                size="small"
                InputProps={{
                  className: classes.textInput,
                }}
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                label="Full name *"
                error={error ? error.message : ""}
                {...field}
              />
            )}
            name="name"
            control={control}
          />

          <Controller
            render={({ field, fieldState: { error } }) => (
              <NumberFormat
                customInput={StyledTextField}
                fullWidth
                formSx={{ mt: "26px" }}
                size="small"
                InputProps={{
                  className: classes.textInput,
                }}
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                error={error ? error.message : ""}
                label="Phone number"
                format="+# (###) ### ####"
                mask="_"
                onClick={() => {
                  if (["", "+"].includes(field?.value)) {
                    setValue("phone", "+1");
                  }
                }}
                {...field}
              />
            )}
            name="phone"
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                formSx={{ mt: "26px" }}
                fullWidth
                size="small"
                InputProps={{
                  className: classes.textInput,
                }}
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                label="Email address"
                error={error ? error.message : ""}
                {...field}
              />
            )}
            name="email"
            control={control}
          />
          <Box sx={{ mt: "26px" }}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledSelect
                  fullWidth
                  label="Contact type"
                  error={!!error}
                  MenuProps={{
                    sx: {
                      zIndex: 1502,
                    },
                  }}
                  {...field}
                  renderValue={(s) => normalizeUppercaseString(s)}
                >
                  {TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                  <MenuItem
                    value="custom"
                    sx={{
                      color: "#409A65",
                      "& svg": {
                        mr: "5px",
                      },
                    }}
                  >
                    <OutlinedPlusIcon /> Add new
                  </MenuItem>
                </StyledSelect>
              )}
              name="role"
              control={control}
            />
          </Box>
          {isCustom(formField?.role) && (
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  formSx={{ mt: "15px" }}
                  fullWidth
                  size="small"
                  InputProps={{
                    className: classes.textInput,
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel,
                  }}
                  label="Custom"
                  error={error ? error.message : ""}
                  {...field}
                />
              )}
              name="custom"
              control={control}
            />
          )}
        </Grid>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "67px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          borderTop: "1px solid #D5D9D9",
          backgroundColor: "#F8F8F8",
          pl: 4,
          pr: 2,
          position: "absolute",
          bottom: 0,
        }}
      >
        <StyledButton
          label={"Cancel"}
          sx={{ height: "31px", mr: 1, minWidth: "78px" }}
          color="cancel"
          onClick={onClose}
          fontWeight="600"
          fontSize="15px"
        />
        <StyledButton
          disabled={isDisabled}
          label={"Add"}
          variant="contained"
          sx={{ height: "31px", minWidth: "78px" }}
          fontWeight="600"
          fontSize="15px"
          onClick={handleSubmit}
        />
      </Box>
    </>
  );
};

BodyAddContactDrawer.propTypes = {
  onClose: func,
  control: object,
  setValue: func,
  formField: object,
  isDisabled: bool,
  handleSubmit: func,
};
BodyAddContactDrawer.defaultProps = {
  onClose: () => {},
  control: {},
  setValue: () => {},
  formField: {},
  isDisabled: false,
  handleSubmit: () => {},
};
