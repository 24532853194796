import { string } from "prop-types";
import { Stack, Typography } from "@mui/material";

export const SingleDialogBlock = ({ text = "" }) => {
  return (
    <Stack flexDirection="row" gap="4px">
      <Typography fontSize={13} fontWeight={400} color="#5F6267" noWrap>
        Replied: {text}
      </Typography>
    </Stack>
  );
};

SingleDialogBlock.propTypes = { text: string };
