import { array, func, bool } from "prop-types";
import {
  Box,
  CircularProgress,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { StyledProductIco } from "components/Icons";
import { photoUrl } from "helpers/helpers";
import QuantityPicker from "components/QuantityPicker";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getProductManufacturer,
  getProductManufacturerName,
} from "Pages/DiscountsPage/components/ApplyDiscountPage/components/MSDPage/MSDPage.helpers";
import { PulsingDiscount, StyledButton } from "components";
import pluralize from "pluralize";
import { PRODUCT_TYPE_INVENTORY } from "utils/constants";

export const ContentBlock = ({
  list,
  discounts,
  handleAddPromotion,
  loading,
  cartProducts,
  calcProductPriceByPriceList,
}) => {
  const [tempList, setTempList] = useState([...list] || []);
  const handleUpdateList = useCallback(() => {
    setTempList([...list]);
  }, [list]);

  useEffect(handleUpdateList, [handleUpdateList]);

  const getCurrentDiscount = (product) => {
    const productManufacturer = getProductManufacturer(product);
    const currentDiscount = discounts.find(
      ({ manufacturer }) => manufacturer.id === productManufacturer
    );
    return currentDiscount;
  };

  const getCurrentManufacturerProductsQtySum = (product) => {
    const currentDiscount = getCurrentDiscount(product);
    if (!currentDiscount) return 0;
    const disManId = currentDiscount.manufacturer?.id;

    const currentManufacturerProducts = tempList.filter((listProd) => {
      const listProdMan = getProductManufacturer(listProd);
      return disManId === listProdMan;
    });

    const currentManufacturerProductsQtySum =
      currentManufacturerProducts.reduce(
        (prevSum, { discountQty }) => prevSum + (discountQty || 0),
        0
      );

    return currentManufacturerProductsQtySum;
  };

  const getIsOutOfStock = (product) => {
    if (!product) return false;
    const { quantity } = cartProducts.find(
      ({ id, productId }) => id === product.id || productId === product.id
    ) || { quantity: 0 };

    const {
      sellingOutOfStock: continueSellingWhenOutOfStock,
      inventory,
      discountQty,
    } = product;
    const runOutOfStock =
      inventory?.onHand -
        inventory?.allocated -
        (discountQty || 0) -
        quantity <=
      0;

    return runOutOfStock && !continueSellingWhenOutOfStock;
  };

  const handleChangeQty = ({ product, isAdd, event }) => {
    const index = tempList.findIndex((prod) => prod.id === product.id);
    let newQty = product.discountQty || 0;
    if (event) {
      newQty = parseInt(event.target.value);
      const isOutOfStock = getIsOutOfStock({
        ...product,
        discountQty: newQty - 1,
      });
      if (isOutOfStock) return;
    } else {
      newQty = isAdd ? newQty + 1 : newQty - 1;
    }

    const currentDiscount = getCurrentDiscount(product);
    const limit = currentDiscount?.quantityDiscount;

    const currentManufacturerProductsQtySum =
      getCurrentManufacturerProductsQtySum(product);

    const newList = [...tempList];
    const oldDiscountQty = product.discountQty || 0;
    if (isAdd) {
      if (currentManufacturerProductsQtySum === limit) return;
    } else {
      if (!event && oldDiscountQty === 0) return;
      if (
        newQty < 0 ||
        newQty > limit ||
        currentManufacturerProductsQtySum - oldDiscountQty + newQty > limit
      )
        return;
    }
    const newProduct = {
      ...product,
      discountQty: newQty,
    };

    newList.splice(index, 1, newProduct);
    setTempList(newList);
  };

  const sectionList = useMemo(
    () =>
      tempList.reduce((prevObj, prod) => {
        const newObj = { ...prevObj };
        const manufacturerId = getProductManufacturer(prod);
        newObj[manufacturerId] = newObj[manufacturerId]
          ? [...newObj[manufacturerId], prod]
          : [prod];
        return newObj;
      }, {}),
    [tempList]
  );

  return (
    <DialogContent
      sx={{
        pt: 0,
        pb: 4.25,
        px: 3.25,
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Box
        maxHeight="50%"
        overflow="auto"
        display="flex"
        flexDirection="column"
        gap="8px"
      >
        {loading ? (
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="300px"
          >
            <CircularProgress />
          </Box>
        ) : (
          Object.keys(sectionList).map((manufacturerId) => {
            const firstProd = sectionList[manufacturerId][0];
            const currentDiscount = getCurrentDiscount(firstProd);

            return (
              <Box
                key={manufacturerId}
                display="flex"
                flexDirection="column"
                gap="8px"
              >
                <Box
                  sx={{
                    background: "rgba(64, 154, 101, 0.1)",
                    p: "8px 15px",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    gap: "13px",
                  }}
                >
                  <PulsingDiscount />
                  <Typography fontSize="12px" color="#5F6267">
                    {currentDiscount?.quantityDiscount} Free{" "}
                    {getProductManufacturerName(firstProd)}{" "}
                    {pluralize("Item", currentDiscount?.quantityDiscount)}
                  </Typography>
                </Box>
                <Stack
                  sx={{ border: "0.5px solid #D5D9D9", borderRadius: "4px" }}
                >
                  {sectionList[manufacturerId].map((product, index) => {
                    const listCountIndexes =
                      sectionList[manufacturerId]?.length - 1;
                    const discountQty = product.discountQty || 0;
                    const isApplied = discountQty > 0;
                    const qtySum =
                      getCurrentManufacturerProductsQtySum(product);
                    const isOutOfStock = getIsOutOfStock(product);

                    const isNonInventory =
                      product?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

                    return (
                      <Grid
                        key={product?.id}
                        width="100%"
                        height="60px"
                        borderBottom={
                          listCountIndexes !== index && "0.5px solid #D4D4D4"
                        }
                        container
                      >
                        {/* empty block */}
                        <Grid xs={0.2} item />

                        {/* Picture */}
                        <Grid
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          xs={1.2}
                          item
                        >
                          <Box
                            sx={{
                              width: "39px",
                              height: "39px",
                              backgroundColor: "white",
                              border: "1px solid #D5D9D9",
                              borderRadius: "4px",
                            }}
                          >
                            <StyledProductIco
                              src={
                                product?.photos?.length
                                  ? photoUrl(product.photos[0].fileName)
                                  : product?.photo
                                  ? photoUrl(product?.photo?.fileName)
                                  : ""
                              }
                              styles={{
                                objectFit: "contain",
                                width: "100%",
                                height: "100%",
                                borderRadius: "4px",
                              }}
                            />
                          </Box>
                        </Grid>

                        {/* Name */}
                        <Grid
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          xs={5}
                          item
                        >
                          <Typography
                            fontSize={12}
                            fontWeight={600}
                            color="#1C1C19"
                            noWrap
                          >
                            {product?.name || product?.parentProduct?.name}
                          </Typography>
                          <Typography fontSize="12px" fontWeight="400" noWrap>
                            {product?.sku}
                            {(product?.size || product?.color) &&
                              ` / ${product?.size || product?.color}`}
                          </Typography>
                        </Grid>

                        {/* button */}
                        <Grid
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="center"
                          xs
                          item
                          pr="18px"
                        >
                          <Box
                            mr="14px"
                            sx={{
                              "& .MuiTypography-root": {
                                fontSize: "12px",
                                color: "#BCBCBC",
                              },
                            }}
                            display="inline-flex"
                            gap="6px"
                          >
                            {isApplied && (
                              <Typography>
                                $
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    color: "#1C1C19",
                                  }}
                                >
                                  {` ${(
                                    discountQty *
                                    (calcProductPriceByPriceList({ product }) ||
                                      0)
                                  ).toFixed(2)}`}
                                </span>
                              </Typography>
                            )}
                            <Typography
                              sx={{
                                color: isApplied && "#47A06D!important",
                                fontWeight: "600",
                              }}
                            >
                              $ 0.00
                            </Typography>
                          </Box>
                          <QuantityPicker
                            onAdd={() =>
                              handleChangeQty({
                                product,
                                isAdd: true,
                                event: null,
                              })
                            }
                            onSubtract={() =>
                              handleChangeQty({
                                product,
                                isAdd: false,
                                event: null,
                              })
                            }
                            value={product.discountQty || 0}
                            minusDisabled={!product.discountQty}
                            plusDisabled={
                              !isNonInventory &&
                              (qtySum >= currentDiscount?.quantityDiscount ||
                                isOutOfStock)
                            }
                            plusTooltipProps={{
                              title: `Cannot add this product to the cart. Items Available: ${
                                product.inventory?.onHand -
                                product.inventory?.allocated
                              }`,
                              disableHoverListener:
                                !isOutOfStock || isNonInventory,
                            }}
                            disabled={
                              !isApplied &&
                              qtySum === currentDiscount?.quantityDiscount
                            }
                            onChange={(e) =>
                              handleChangeQty({
                                product,
                                isAdd: false,
                                event: e,
                              })
                            }
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </Stack>
              </Box>
            );
          })
        )}
      </Box>
      <Box
        pt="12px"
        width="100%"
        sx={{ "& .MuiButton-root": { width: "100%" } }}
      >
        <StyledButton
          label="Add Promotion"
          variant="contained"
          fontSize="15px"
          onClick={() => handleAddPromotion(tempList)}
        />
      </Box>
    </DialogContent>
  );
};

ContentBlock.propTypes = {
  list: array,
  discounts: array,
  handleAddPromotion: func,
  calcProductPriceByPriceList: func,
  loading: bool,
  cartProducts: array,
};
ContentBlock.defaultProps = {
  list: [],
  discounts: [],
  handleAddPromotion: () => {},
  loading: false,
  cartProducts: [],
};
