import { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { bool, func, object } from "prop-types";

import { ArrowNextIcon } from "components/Icons";

import { ActionRestrictionWrapper, LargeCheckbox } from "components";

import EditIcon from "@mui/icons-material/Edit";
import { Grid, IconButton, Typography } from "@mui/material";
import EditPriceName from "../../EditPriceName";

const PriceListItem = ({
  price,
  isChecked,
  handleCheckPrice,
  onDelete,
  onEditSave,
  repPermissions,
}) => {
  const navigate = useNavigate();
  const [isItemHovered, setIsItemHovered] = useState(false);
  const [anchorCustomerEl, setAnchorCustomerEl] = useState(null);

  const customerNameRef = useRef(null);

  const { id, name, productCount, overallAdjustment, customerCount } =
    price || {};

  const handleClickOnEdit = useCallback((e) => {
    e.stopPropagation();
    if (customerNameRef.current) {
      //if (repPermissions && !repPermissions?.customers?.create_edit) return;
      setAnchorCustomerEl(customerNameRef.current);
    }
  }, []);

  const handleCloseEdit = (e) => {
    e.stopPropagation();
    setAnchorCustomerEl(null);
  };

  const disabledEditPriceList = useMemo(() => {
    return repPermissions
      ? !repPermissions?.catalog?.create_edit_price_lists
      : false;
  }, [repPermissions]);

  return (
    <>
      <EditPriceName //should be refactored with UX. make it in the list component .
        isOpen={!!anchorCustomerEl}
        price={price}
        anchorEl={anchorCustomerEl}
        handleClose={handleCloseEdit}
        onDelete={onDelete}
        onSave={onEditSave}
      />

      <Grid
        sx={{
          backgroundColor: isChecked ? "#ECF5F0" : "#ffff",
          alignItems: "center",
          height: "48px",
          borderRadius: "0 0 4px 4px",

          "&:hover": {
            backgroundColor: isChecked ? "#ECF5F0" : "#F7F7F7",
          },

          "&:not(:last-of-type)": {
            borderRadius: "0px",
            borderBottom: "1px solid #D5D9D9",
          },
        }}
        container
      >
        <Grid sx={{ pl: "15.5px" }} xs={0.48} item>
          <LargeCheckbox
            checked={isChecked}
            sx={{ padding: 0 }}
            formSx={{ m: 0 }}
            size={22}
            rectSize={22}
            onChange={() => handleCheckPrice(price)}
            onClick={(e) => e.stopPropagation()}
            checkedViewBox="0 0 24 24"
          />
        </Grid>

        <Grid
          pr={1}
          xs={6.56}
          item
          onMouseEnter={() => setIsItemHovered(true)}
          onMouseLeave={() => setIsItemHovered(false)}
          container
          flexWrap="nowrap"
          alignItems="center"
          gap="8px"
        >
          <Typography
            noWrap
            fontSize="12px"
            color="#1C1C19"
            onClick={() => navigate(`/price-list/${id}`)}
            ref={customerNameRef}
            sx={{
              "&:hover": { textDecoration: "underline", cursor: "pointer" },
            }}
          >
            {name}
          </Typography>
          <ActionRestrictionWrapper
            disableHoverListener={!disabledEditPriceList}
          >
            <IconButton
              disabled={disabledEditPriceList}
              onClick={(e) => {
                e.stopPropagation();
                handleClickOnEdit(e);
              }}
              sx={{
                p: 0,
                opacity: !isItemHovered ? 0 : ".5",
                transition: "all 0.4s",
              }}
              disableRipple
            >
              <EditIcon sx={{ width: "16px", height: "16px" }} />
            </IconButton>
          </ActionRestrictionWrapper>
        </Grid>

        <Grid xs={1.5} item pr={1}>
          <Typography fontSize={13} fontWeight={400} color="#1C1C19" noWrap>
            {productCount}
          </Typography>
        </Grid>

        <Grid xs={1.5} item pr={1}>
          <Typography fontSize={13} fontWeight={400} color="#1C1C19" noWrap>
            {customerCount}
          </Typography>
        </Grid>

        <Grid xs={1.5} item>
          <Typography fontSize={13} fontWeight={400} color="#1C1C19" noWrap>
            {overallAdjustment}
          </Typography>
        </Grid>
        <Grid xs={0.46} item pr="14px" justifyContent="flex-end" container>
          <IconButton
            disableRipple
            onClick={() => navigate(`/price-list/${id}`)}
          >
            <ArrowNextIcon color="#707070" width={5.9} height={10.9} />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};

PriceListItem.propTypes = {
  price: object,
  isChecked: bool,
  handleCheckPrice: func,
  onDelete: func,
  onEditSave: func,
  repPermissions: object,
};
PriceListItem.defaultProps = {
  price: {},
  isChecked: false,
  handleCheckPrice: () => {},
  setPriceName: () => {},
  onDelete: () => {},
  onEditSave: () => {},
};

export default PriceListItem;
