import { string } from "prop-types";
import { Box, InputAdornment, Typography } from "@mui/material";
import { StyledDatePicker } from "components";
import { FormDateTypeIcon } from "components/Icons/FormDateTypeIcon";

export const DateContentBlock = () => {
  return (
    <Box
      sx={{
        mt: 1,
        border: "0.5px solid #E3E3E3",
        borderRadius: "4px",
        bgcolor: "#D5D5D516",
        p: "16px 16px 24px 16px",
      }}
    >
      <Box mb={0.5}>
        <Typography fontSize={14} color="#56565680">
          Answer preview
        </Typography>
      </Box>
      <StyledDatePicker
        disabled
        value=""
        name="date"
        inputProps={{ placeholder: "mm / dd / yy" }}
        inputSx={{
          height: "43px",
          fontSize: "13px",
          bgcolor: "#FFF",
          "& .MuiInputAdornment-positionEnd": { display: "none" },
          "& .Mui-disabled": {
            "&::placeholder": {
              "-webkit-text-fill-color": "#5F6267",
              opacity: 1,
            },
          },
        }}
        onChange={() => {}}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FormDateTypeIcon />
            </InputAdornment>
          ),
          endAdornment: null,
        }}
      />
    </Box>
  );
};

DateContentBlock.propTypes = { answer: string };
