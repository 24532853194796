import { bool, func } from "prop-types";

import { useCreatePriceList } from "./useCreatePriceList";

import { CreatePriceListHeader } from "./components/CreatePriceListHeader";
import { CreatePriceListContent } from "./components/CreatePriceListContent";
import { CreatePriceListActions } from "./components/CreatePriceListActions";

import { cl } from "./styles";

import { Dialog } from "@mui/material";

export const CreatePriceListDialog = ({ open, onClose, handleSave }) => {
  const { priceName, onChange, onSave, loading } = useCreatePriceList({
    handleSave,
  });

  return (
    <Dialog open={open} sx={cl.dialogWrapper}>
      <CreatePriceListHeader {...{ onClose }} />
      <CreatePriceListContent {...{ priceName, onChange }} />
      <CreatePriceListActions {...{ onClose, onSave, priceName, loading }} />
    </Dialog>
  );
};

CreatePriceListDialog.propTypes = {
  open: bool,
  onClose: func,
  handleSave: func,
};
CreatePriceListDialog.defaultProps = {
  open: false,
  onClose: () => {},
  handleSave: () => {},
};
