import { useContext } from "react";

import { ProductItem } from "..";
import { InfiniteLoadMoreBtn, InfiniteScrollWrapper } from "components";

import { ProductPriceContext } from "Pages/ProductPricePage";

import Stack from "@mui/material/Stack";
import { CircularProgress } from "@mui/material";

export const ListBlock = () => {
  const {
    page,
    productsLoading,
    isAllowed,
    productsList,
    productsCount,
    getFinalPrice,
    onProductSelect,
    onRemoveProduct,
    updateFieldByIndex,
    productMessages,
    setProductMessage,
    handleFetchProducts,
    checkForProductSelection,
    repPermissions,
  } = useContext(ProductPriceContext);

  return (
    <Stack
      sx={{
        borderRadius: "0 0 4px 4px",
        maxHeight: "calc(100vh - 312px)",
        borderWidth: "0.5px",
        height: "fit-content",
        borderStyle: "solid",
        borderColor: "#D5D9D9",
      }}
    >
      {productsLoading ? (
        <Stack
          height="calc(100vh - 312px)"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={32} />
        </Stack>
      ) : (
        <InfiniteScrollWrapper
          skipScrollReset
          maxHeight="calc(100vh - 312px)"
          dataLength={productsList.length}
          id="products-prise-list-table-scroll"
          loading={productsLoading}
          nextFunc={() => handleFetchProducts(page + 1)}
          hasMoreItems={productsList.length < productsCount}
          loader={
            <CircularProgress
              sx={{ ml: "calc(50% - 15px)", mt: "10px" }}
              size="30px"
            />
          }
        >
          {productsList?.map((product, index) => (
            <ProductItem
              key={product.id}
              {...{
                index,
                product,
                isAllowed,
                getFinalPrice,
                onRemoveProduct,
                setProductMessage,
                updateFieldByIndex,
                productMessages: productMessages?.[product.id],
                checkForProductSelection,
                handleCheckProduct: onProductSelect,
                repPermissions,
              }}
            />
          ))}
          {!productsLoading && productsList.length < productsCount && (
            <InfiniteLoadMoreBtn
              onClick={() => handleFetchProducts(page + 1)}
            />
          )}
        </InfiniteScrollWrapper>
      )}
    </Stack>
  );
};
