import { Box, Link, Typography } from "@mui/material";
import {
  PRIVACY_POLICY_LINK,
  TERMS_OF_SERVICE_LINK,
} from "../../../utils/constants";

export const LinksRegistration = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        maxWidth: "inherit",
        width: "inherit",
        height: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,

        fontSize: 17,
        color: "#5F6267",
      }}
    >
      <Typography
        fontSize="inherit"
        color="inherit"
        component={Link}
        href={TERMS_OF_SERVICE_LINK}
        underline="hover"
        target="_blank"
      >
        Terms of Service
      </Typography>
      <Typography fontSize="inherit" color="inherit">
        •
      </Typography>
      <Typography
        fontSize="inherit"
        color="inherit"
        component={Link}
        href={PRIVACY_POLICY_LINK}
        underline="hover"
        target="_blank"
      >
        Privacy Policy
      </Typography>
    </Box>
  );
};
