import { Box, Typography } from "@mui/material";
import { StyledTextField } from "components";
import { bool, string } from "prop-types";
export const TextContentBlock = ({ answer }) => {
  return (
    <Box
      sx={{
        mt: 1,
        border: "0.5px solid #E3E3E3",
        borderRadius: "4px",
        bgcolor: "#D5D5D516",
        p: "16px 16px 24px 16px",
      }}
    >
      <Box mb={0.5}>
        <Typography fontSize={14} color="#56565680">
          Answer preview
        </Typography>
      </Box>

      <StyledTextField
        disabled
        placeholder="Type your answer..."
        fullWidth
        onClick={(e) => e.stopPropagation()}
        value={answer}
        InputProps={{
          sx: {
            height: "43px",
            fontSize: "13px",
            borderRadius: "5px",
            bgcolor: "#FFF",
          },
        }}
        InputLabelProps={{
          sx: { fontSize: "13px", color: "#5F6267", top: 3 },
        }}
        name="answer"
      />
    </Box>
  );
};

TextContentBlock.propTypes = {
  isActive: bool,
  answer: string,
};
