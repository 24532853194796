import { Stack } from "@mui/material";

export const NoResponsesBlock = () => {
  return (
    <Stack
      sx={{
        mt: "16px",
        mb: "8px",
        borderRadius: "6px",
        backgroundColor: "#F8F8F8",
        height: "90px",
        justifyContent: "center",
        alignItems: "center",

        fontSize: "14px",
        fontWeight: 400,
        color: "#70707080",
      }}
    >
      No Responses
    </Stack>
  );
};
