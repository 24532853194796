export const cl = {
  arrowBox: {
    display: "flex",
    cursor: "pointer",
  },

  centredHeaderItem: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },

  leftHeaderItem: {
    justifyContent: "flex-start",
    display: "flex",
    alignItems: "center",
  },

  tableHeaderSx: {
    borderRadius: 0,
    borderTop: "none",
    borderWidth: "0px 0px 0.5px 0px",
  },

  checkboxSx: {
    p: 0,
    "&.MuiCheckbox-root.MuiCheckbox-indeterminate": {
      ml: "-3px !important",
    },
  },
};
