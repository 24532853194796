import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
} from "@mui/material";
import { bool, object, array, func, any } from "prop-types";
import { useEffect, useState, Fragment, useCallback, useRef } from "react";
import { useMemo } from "react";
import { Controller, useWatch } from "react-hook-form";
import Nestable from "react-nestable";
import { LargeCheckbox } from "../../../../../../components/Checkboxes";
import {
  CrossIcon,
  ReorderBurgerIcon,
  SearchIcon,
} from "../../../../../../components/Icons";
import { StyledSelect } from "../../../../../../components/Selects";
import { ReportCheckbox } from "../ReportCheckbox";
import useStyles from "./styles";
import { CategoriesComponent } from "./components";
import { StyledTextField } from "components";
import { useDebounce } from "helpers/hooks";
import { setWidthByRef } from "helpers/helpers";

const ReportBody = ({
  open,
  isCustom,
  control,
  report,
  matchFetchData,
  handleChangeSelect,
  setValue,
  hasChosenCol,
  handleAddColumn,
  setAnchorElPopper,
  hasProductSelected,
  hasUnchosenCol,
  showWithoutCents,
  showInactiveReps,
  showNumberOptionsShow,
  showNegativeNumbersInRed,
  showArchiveOrders,
  showInactiveCustomers,
  showInactive,
  checkInactive,
  setParentSearchInput,
  showProspects,
}) => {
  const classes = useStyles();
  const formField = useWatch({ control });

  const orderCheck = report?.config?.isShow?.orderCheck;
  const currentSelect = useMemo(
    () => matchFetchData.find((data) => data.id === formField.groupBy),
    [formField.groupBy, matchFetchData]
  );

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [searchInput, setSearchInput] = useState("");
  const [selectOpen, setSelectOpen] = useState(false);
  const searchInputDebounced = useDebounce(searchInput, 500);
  const [countItems, setCountItems] = useState(formField.itemsCount);

  const selectSalesIdRef = useRef(null);

  useEffect(() => {
    if (!countItems && formField.itemsCount)
      return setCountItems(formField.itemsCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formField.itemsCount]);

  const getPlaceholderText = () => {
    switch (formField.groupBy) {
      case "Customers":
        return "customers";
      case "Customer Groups":
        return "customer groups";
      case "Reps":
        return "reps";
      case "Manufacturers":
        return "manufacturers";
      case "Products":
        return "products";
      case "Groups":
        return "groups";
      default:
        return "";
    }
  };

  const handleFetchMore = useCallback(
    (reset = false) => {
      const newPage = reset ? 1 : page + 1;
      const newLimit = reset ? 20 : limit + 20;
      if (!reset && (newPage === page || newLimit === limit)) return;
      const updatedParams = {
        ...currentSelect.params,
        ...report.fetchParam,
        page: currentSelect.params.page ? newPage : null,
        limit: currentSelect.params.page ? 20 : newLimit,
        search: searchInputDebounced,
      };

      currentSelect
        ?.handleFetch(updatedParams)
        .then((res) => {
          const newList =
            reset || !currentSelect.params.page
              ? res.rows || res
              : [...formField.itemsList, ...(res.rows || res)];
          setValue("itemsList", newList);
          setCountItems(res?.count || newList?.length || 0);
          setPage(newPage);
          setLimit(newLimit);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error("Error fetching data:", error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentSelect,
      formField.active,
      formField.itemsList,
      setValue,
      page,
      limit,
      searchInputDebounced,
    ]
  );

  useEffect(() => {
    if (!open) {
      setValue("itemsList", []);
      setParentSearchInput("");
      setSearchInput("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (selectOpen) {
      handleFetchMore(true);
      setParentSearchInput(searchInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputDebounced]);

  useEffect(() => {
    setLimit(20);
    setPage(1);
    setCountItems(0);
  }, [formField.groupBy, formField.active]);

  return (
    <Box width="100%">
      <Grid className={classes.nativeWrapper} xs={12} item>
        <Box>
          <Typography className={classes.blockTitle} noWrap>
            Group by
          </Typography>
        </Box>
        <Box className={classes.groupByWrapper}>
          {report?.reportsPerGroup?.length > 1 && (
            <Box className={classes.groupBySelectWrapper}>
              <Controller
                render={({ field }) => (
                  <StyledSelect
                    height="38px"
                    fontSize="15px"
                    fontWeight="400"
                    color="#707070"
                    notched={false}
                    fullWidth
                    formSx={{
                      maxWidth: "100%",
                      "&:hover": {
                        "&& fieldset": {
                          border: "0.5px solid #D5D9D9 !important",
                          backgroundColor: "rgba(213, 217, 217, 0.15)",
                        },
                      },
                    }}
                    doNotApplyDisabledForAdmin
                    {...field}
                    data-testid="groupby-option-select"
                  >
                    {report?.reportsPerGroup?.map((groupItem) => (
                      <MenuItem key={groupItem} value={groupItem}>
                        {groupItem}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                )}
                name="groupBy"
                control={control}
              />
            </Box>
          )}
          {/* {formField.groupBy === "Customers" && ( */}
          {formField.groupBy !== "All" &&
            (formField?.groupBy === "Categories" ? (
              <Box className={classes.chooseIdsWrapper}>
                <CategoriesComponent control={control} setValue={setValue} />
              </Box>
            ) : (
              <Box className={classes.chooseIdsWrapper}>
                <Controller
                  render={({ field }) => (
                    <StyledSelect
                      ref={selectSalesIdRef}
                      height="38px"
                      fontSize="15px"
                      fontWeight="400"
                      color="#707070"
                      notched={false}
                      fullWidth
                      multiple
                      doNotApplyDisabledForAdmin
                      PaperPropsSx={{
                        width: setWidthByRef(selectSalesIdRef, "318px"),
                      }}
                      formSx={{
                        maxWidth:
                          report?.reportsPerGroup?.length > 1 ? "324px" : null,
                        "&:hover": {
                          "&& fieldset": {
                            border: "0.5px solid #D5D9D9 !important",
                            backgroundColor: "rgba(213, 217, 217, 0.15)",
                          },
                        },
                      }}
                      {...field}
                      onOpen={() => setSelectOpen(true)}
                      onClose={() => setSelectOpen(false)}
                      onChange={(e) => {
                        const val = e.target.value;
                        if (val.length < field.value.length)
                          return handleChangeSelect(val);
                        const newItem = val[val.length - 1];

                        if (!newItem) return handleChangeSelect("All");

                        if (field.value.some((rep) => rep.id === newItem.id))
                          return handleChangeSelect(
                            field.value.filter((rep) => rep.id !== newItem.id)
                          );

                        handleChangeSelect(val);
                      }}
                      dataLength={formField?.itemsList?.length}
                      dataCount={countItems}
                      handleFetch={handleFetchMore}
                      renderValue={(selected) =>
                        selected.filter((s) => s.id).length
                          ? selected.map((s) => s.name).join(", ")
                          : // : report?.customer?.name
                            // ? report?.customer?.name
                            "All"
                      }
                      data-testid="groupby-value-select"
                    >
                      <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                        <Grid xs={12} item>
                          <StyledTextField
                            fullWidth
                            size="small"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            placeholder={`Search ${getPlaceholderText()}`}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            InputProps={{
                              className: classes.textInputSearch,
                              endAdornment: (
                                <>
                                  <InputAdornment
                                    sx={{ position: "absolute", left: 9 }}
                                    position="start"
                                  >
                                    <SearchIcon />
                                  </InputAdornment>
                                  {searchInput && (
                                    <InputAdornment position="end">
                                      <IconButton
                                        sx={{ pr: "1px" }}
                                        onClick={() => setSearchInput("")}
                                      >
                                        <CrossIcon size="15" />
                                      </IconButton>
                                    </InputAdornment>
                                  )}
                                </>
                              ),
                            }}
                            InputLabelProps={{
                              className: classes.inputLabel,
                            }}
                          />
                        </Grid>
                      </MenuItem>
                      {formField?.itemsList?.length === 0 ? (
                        <MenuItem value="">
                          <Typography
                            fontWeight="300"
                            fontSize="16px"
                            textAlign="center"
                            width="100%"
                            color="#6A6A6A"
                            paddingTop="20px"
                            paddingBottom="20px"
                          >
                            {` No ${getPlaceholderText()} found`}
                          </Typography>
                        </MenuItem>
                      ) : (
                        <MenuItem value="">All</MenuItem>
                      )}

                      {currentSelect?.uncategorizedItem && (
                        <MenuItem value={currentSelect.uncategorizedItem.id}>
                          {currentSelect.uncategorizedItem.name}
                        </MenuItem>
                      )}
                      {formField.itemsList?.map((item) => (
                        <MenuItem
                          sx={{
                            fontSize: 13,
                            color: "rgba(95, 98, 103, 1)",
                          }}
                          key={item.id}
                          value={item}
                        >
                          <LargeCheckbox
                            checked={
                              field.value.findIndex((f) => f.id === item.id) >
                              -1
                            }
                            size={15}
                            sx={{ p: "5px" }}
                            formSx={{ mr: "5px" }}
                          />
                          <Typography
                            noWrap
                            sx={{
                              fontSize: "13px",
                              color: "rgba(95, 98, 103, 1)",
                            }}
                          >
                            {item.displayName || item.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  )}
                  name="groupByIds"
                  control={control}
                />
              </Box>
            ))}
        </Box>
      </Grid>

      {showNumberOptionsShow && (
        <Grid p="10px 36px" xs={12} item>
          <Box>
            {showWithoutCents && (
              <Box>
                <Controller
                  render={({ field }) => (
                    <LargeCheckbox
                      checked={!!field.value}
                      formSx={{ ml: "-9px", mr: 0 }}
                      label={
                        <Typography
                          fontSize={15}
                          fontWeight="400"
                          color="#707070"
                        >
                          Show numbers without cents
                        </Typography>
                      }
                      size={15}
                      {...field}
                    />
                  )}
                  name="withoutCents"
                  control={control}
                />
              </Box>
            )}
            {showInactiveCustomers && (
              <Box>
                <Controller
                  render={({ field }) => (
                    <LargeCheckbox
                      checked={!!field.value}
                      formSx={{ ml: "-9px", mr: 0 }}
                      label={
                        <Typography
                          fontSize={15}
                          fontWeight="400"
                          color="#707070"
                        >
                          Show inactive customers
                        </Typography>
                      }
                      size={15}
                      {...field}
                      onChange={() =>
                        setValue(
                          checkInactive ? "includeInactive" : "active",
                          !field.value
                        )
                      }
                    />
                  )}
                  name={checkInactive ? "includeInactive" : "active"}
                  control={control}
                />
              </Box>
            )}
            {showProspects && (
              <Box>
                <Controller
                  render={({ field }) => (
                    <LargeCheckbox
                      checked={!!field.value}
                      formSx={{ ml: "-9px", mr: 0 }}
                      label={
                        <Typography
                          fontSize={15}
                          fontWeight="400"
                          color="#707070"
                        >
                          Show prospects
                        </Typography>
                      }
                      size={15}
                      {...field}
                      onChange={() => setValue("showProspects", !field.value)}
                    />
                  )}
                  name="showProspects"
                  control={control}
                />
              </Box>
            )}
            {showInactiveReps && (
              <Box>
                <Controller
                  render={({ field }) => (
                    <LargeCheckbox
                      checked={!!field.value}
                      formSx={{ ml: "-9px", mr: 0 }}
                      label={
                        <Typography
                          fontSize={15}
                          fontWeight="400"
                          color="#707070"
                        >
                          Show inactive reps
                        </Typography>
                      }
                      size={15}
                      {...field}
                    />
                  )}
                  name="active"
                  control={control}
                />
              </Box>
            )}
            {showNegativeNumbersInRed && (
              <Box>
                <Controller
                  render={({ field }) => (
                    <LargeCheckbox
                      checked={!!field.value}
                      formSx={{ ml: "-9px", mr: 0 }}
                      label={
                        <Typography
                          fontSize={15}
                          fontWeight="400"
                          color="#707070"
                        >
                          Show negative numbers in red
                        </Typography>
                      }
                      size={15}
                      {...field}
                    />
                  )}
                  name="redNegative"
                  control={control}
                />
              </Box>
            )}
            {showArchiveOrders && (
              <Box>
                <Controller
                  render={({ field }) => (
                    <LargeCheckbox
                      checked={!!field.value}
                      formSx={{ ml: "-9px", mr: 0 }}
                      label={
                        <Typography
                          fontSize={15}
                          fontWeight="400"
                          color="#707070"
                        >
                          Show archived orders
                        </Typography>
                      }
                      size={15}
                      {...field}
                    />
                  )}
                  name="includeArchived"
                  control={control}
                />
              </Box>
            )}
            {showInactive && (
              <Box>
                <Controller
                  render={({ field }) => (
                    <LargeCheckbox
                      checked={!!field.value}
                      formSx={{ ml: "-9px", mr: 0 }}
                      label={
                        <Typography
                          fontSize={15}
                          fontWeight="400"
                          color="#707070"
                        >
                          Show inactive
                        </Typography>
                      }
                      size={15}
                      {...field}
                    />
                  )}
                  // name="active"
                  name={checkInactive ? "includeInactive" : "active"}
                  control={control}
                />
              </Box>
            )}
          </Box>
        </Grid>
      )}

      {isCustom && hasChosenCol && (
        <Grid className={classes.reorderBlock} xs={12} item>
          <Box>
            <Typography className={classes.blockTitle} noWrap>
              Select and reorder columns
            </Typography>
          </Box>
          <Nestable
            items={formField?.selectAndReorderColumns.filter(
              (i) => i.type !== "archived" && i.chosen
            )}
            renderItem={({ index, item }) => (
              <ReportCheckbox
                index={index}
                item={item}
                control={control}
                setValue={setValue}
                handleAddColumn={handleAddColumn}
                orderCheck={orderCheck}
              />
            )}
            maxDepth={0}
            renderCollapseIcon={() => <ReorderBurgerIcon />}
            onChange={({ items }) => setValue("selectAndReorderColumns", items)}
          />
        </Grid>
      )}
      {isCustom && hasUnchosenCol && (
        <>
          <Grid className={classes.blockWrapper} pb={0} xs={12} item container>
            {formField?.selectAndReorderColumnsBottom?.map((col, i) => (
              <Fragment key={col?.type || col?.id}>
                <Grid
                  sx={{
                    display:
                      (!!col?.isHidden && "none") ||
                      (!!col?.chosen && "none") ||
                      (!!col?.bottom && "none"),
                    alignItems: "center",
                  }}
                  pl={2}
                  xs={col.xs || 4}
                  item
                  container
                >
                  <Controller
                    render={({ field }) => (
                      <LargeCheckbox
                        checked={!!field?.value}
                        formSx={{ ml: "-10px", mr: 0 }}
                        label={
                          <Typography
                            fontSize={14}
                            fontWeight={300}
                            color="#707070"
                            noWrap
                          >
                            {col?.name}
                          </Typography>
                        }
                        size={15}
                        {...field}
                        onChange={(e) => {
                          setValue(
                            `selectAndReorderColumnsBottom[${i}].selected`,
                            e.target.checked
                          );
                          setValue(
                            `selectAndReorderColumnsBottom[${i}].chosen`,
                            e.target.checked
                          );
                          handleAddColumn(col);
                        }}
                      />
                    )}
                    name={`selectAndReorderColumnsBottom[${i}].chosen`}
                    control={control}
                  />
                </Grid>
              </Fragment>
            ))}
          </Grid>
          <Grid
            className={classes.blockWrapper}
            pb={0}
            mt={2}
            xs={12}
            item
            container
          >
            {formField?.selectAndReorderColumnsBottom?.map((col, i) => (
              <Fragment key={col?.id || col?.type}>
                <Grid
                  sx={{
                    display:
                      (!!col?.isHidden && "none") ||
                      (!!col?.chosen && "none") ||
                      (!col?.bottom && "none"),
                    alignItems: "center",
                  }}
                  pl={2}
                  xs={col?.type === "productName" ? 12 : 4}
                  item
                  container
                  onMouseEnter={(e) => {
                    if (col?.type !== "productName") {
                      setAnchorElPopper(e.currentTarget);
                    }
                  }}
                  onMouseLeave={() => setAnchorElPopper(null)}
                >
                  <Controller
                    render={({ field }) => {
                      return (
                        <LargeCheckbox
                          checked={!!field?.value}
                          disabled={
                            col?.name === "Product"
                              ? false
                              : !hasProductSelected
                          }
                          formSx={{
                            ml: "-10px",
                            mr: 0,
                          }}
                          stroke={
                            col?.name === "Product"
                              ? "#707070"
                              : hasProductSelected
                              ? "#707070"
                              : "rgba(112, 112, 112, 0.2)"
                          }
                          label={
                            <Typography
                              fontSize={14}
                              fontWeight={300}
                              sx={{
                                color:
                                  col?.name === "Product"
                                    ? "#707070"
                                    : hasProductSelected
                                    ? "#707070"
                                    : "rgba(112, 112, 112, 0.2)",
                              }}
                            >
                              {col?.name}
                            </Typography>
                          }
                          size={15}
                          {...field}
                          onChange={(e) => {
                            setValue(
                              `selectAndReorderColumnsBottom[${i}].selected`,
                              e.target.checked
                            );
                            setValue(
                              `selectAndReorderColumnsBottom[${i}].chosen`,
                              e.target.checked
                            );
                            handleAddColumn(col);
                          }}
                        />
                      );
                    }}
                    name={`selectAndReorderColumnsBottom[${i}].chosen`}
                    control={control}
                  />
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

ReportBody.propTypes = {
  isCustom: bool,
  control: object,
  report: object,
  matchFetchData: array,
  handleChangeSelect: func,
  setValue: func,
  hasChosenCol: bool,
  handleAddColumn: func,
  setAnchorElPopper: func,
  hasProductSelected: bool,
  hasUnchosenCol: bool,
  showWithoutCents: any,
  showInactiveReps: any,
  showNumberOptionsShow: any,
  showNegativeNumbersInRed: any,
  showArchiveOrders: any,
  showInactiveCustomers: any,
  showInactive: any,
  checkInactive: bool,
  currentGroup: object,
  showProspects: bool,
  setParentSearchInput: any,
  open: bool,
};

ReportBody.defaultProps = {
  isCustom: false,
  matchFetchData: [],
  hasChosenCol: false,
  setAnchorElPopper: () => {},
  hasProductSelected: false,
  hasUnchosenCol: false,
};

export default ReportBody;
