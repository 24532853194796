import { number, object, string } from "prop-types";
import { Stack, Typography } from "@mui/material";
import pluralize from "pluralize";

export const CountBlock = ({
  name = "",
  text = "Submitted",
  count = 0,
  wrapProps = {},
  nameProps = {},
}) => {
  return (
    <Stack alignItems="center" mt="4px" {...wrapProps}>
      <Typography fontSize={14} fontWeight={500} color="#707070" {...nameProps}>
        {count} {pluralize(name, count)} {text}
      </Typography>
    </Stack>
  );
};

CountBlock.propTypes = {
  name: string,
  text: string,
  count: number,
  wrapProps: object,
  nameProps: object,
};
