import { useState } from "react";
import { shape, func, array, string, object, bool } from "prop-types";
import {
  Box,
  Divider,
  IconButton,
  ListItemText,
  MenuItem,
} from "@mui/material";
import {
  ActionRestrictionWrapper,
  StyledMenu,
  ThreeDotButton,
} from "components";
import { useAdmin } from "helpers/helpers";
import { PaperIcon } from "components/Icons";

export const MenuColumn = ({
  customer,
  handleOpenCustomerNotes,
  isGroups,
  hideNotes,
  handleGetOrderActions,
  adminIsAllowed,
}) => {
  const isAdmin = useAdmin(adminIsAllowed);

  const [anchorEl, setAnchorEl] = useState(null);
  const openHeaderList = Boolean(anchorEl);

  const handleClickLogoClient = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleCloseLogoClient = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const MENU_ACTIONS = handleGetOrderActions(false, isGroups, customer);

  return (
    <>
      {!hideNotes && (
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls="long-menu"
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={() =>
            handleOpenCustomerNotes({
              customer: {
                id: customer?.id,
                name: customer?.name,
              },
            })
          }
          sx={{ p: "4px" }}
        >
          <PaperIcon color={customer?.notes?.length ? "#42a57f" : "#707070"} />
        </IconButton>
      )}

      <ThreeDotButton
        disabled={isAdmin}
        paddingRight="14px"
        onClick={handleClickLogoClient}
      />

      <StyledMenu
        anchorEl={anchorEl}
        isOpen={openHeaderList}
        handleClose={handleCloseLogoClient}
      >
        {MENU_ACTIONS.map(
          (
            {
              sx,
              label,
              disabled,
              onClick,
              element,
              show,
              withDividerAfter,
              withDividerBefore,
              icon,
              hasTooltip,
            },
            index
          ) => (
            <Box
              key={index}
              sx={{
                "& .MuiDivider-root": {
                  height: "0.5px",
                  mt: "6px",
                  mb: "6px",
                },
              }}
            >
              {withDividerBefore && <Divider />}
              {show && hasTooltip && element}
              {show && !hasTooltip && (
                <ActionRestrictionWrapper disableHoverListener={!disabled}>
                  <MenuItem
                    sx={sx}
                    disabled={disabled}
                    onClick={(e) => {
                      onClick();
                      e.stopPropagation();
                      setAnchorEl(null);
                      handleCloseLogoClient(e);
                    }}
                  >
                    {element ? (
                      <>
                        {icon}
                        {element}
                      </>
                    ) : (
                      <>
                        {icon}
                        <ListItemText>{label}</ListItemText>
                      </>
                    )}
                  </MenuItem>
                </ActionRestrictionWrapper>
              )}
              {withDividerAfter && <Divider />}
            </Box>
          )
        )}
      </StyledMenu>
    </>
  );
};

MenuColumn.propTypes = {
  isGroups: bool,
  customer: shape({
    notes: array,
    id: string,
    name: string,
    group: shape({
      name: string,
    }),
    territory: object,
    status: string,
  }),
  handleOpenCustomerNotes: func,
  handleAssignReps: func,
  handleOpenConfirmDialog: func,
  handleChangeGroup: func,
  handleChangePaymentTerm: func,
  territoryList: array,
  paytermsList: array,
  handleGetOrderActions: func,
  handleChangeTerritory: func,
  handleArchiveCustomers: func,
  handleDeleteCustomers: func,
  onReport: func,
  reportData: shape({
    report: object,
    config: object,
  }),
  handleOrders: func,
  hasQuickBooks: bool,
  hideNotes: bool,
  handleCustomerTaskDrawer: func,
  handleAssignTags: func,
  repPermissions: object,
  adminIsAllowed: bool,
};
