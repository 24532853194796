import { Stack, Typography } from "@mui/material";
import { string } from "prop-types";

export const TextBlock = ({ text = "" }) => {
  return (
    <Stack mt="4px" flexDirection="row" gap="4px">
      <Typography
        fontSize={14}
        fontWeight={400}
        color="#5F6267CC"
        sx={{
          display: "-webkit-box",
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 8,
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
};

TextBlock.propTypes = { text: string };
