import React, { useContext } from "react";
import { string, object } from "prop-types";
import { Box, CircularProgress, Stack } from "@mui/material";
import EmptyScreen from "components/EmptyScreen/EmptyScreen.jsx";
import {
  InfiniteLoadMoreBtn,
  InfiniteScrollWrapper,
  TableHeaderControlPanel,
} from "components";
import PriceListHeader from "./components/PriceListHeader";
import PriceListItem from "./components/PriceListItem";
import { PriceContext } from "Pages/CatalogPage";
import { ADMIN_ONLY_VIEW_MESSAGE } from "utils/constants";

const PriceList = ({ repPermissions }) => {
  const {
    priceList,
    priceCount,
    listLoading,
    selectedPrices,
    onPriceSelect,
    ACTIONS_LIST,
    checkAllPrices,
    onUncheckAllPrices,
    checkAlreadyChecked,
    onEditSave,
    onPriceListDelete,
    setOpenCreatePriceDialog,
    onFetchPriceList,
    page,
    onFetchAndSelectAll,
    globalLoading,
    searchInputDebounced,
  } = useContext(PriceContext);

  return (
    <Box height="100%">
      {priceList.length ? (
        <>
          <TableHeaderControlPanel
            actionWrapperProps={{
              sx: { padding: "0 17px 0 15.5px !important" },
            }}
            checkedCount={selectedPrices.length}
            actionsList={ACTIONS_LIST}
            loading={listLoading || globalLoading}
            onSelectAll={onFetchAndSelectAll}
            hasCheckedItems={!!selectedPrices.length}
            availableSelectCount={priceCount}
            selectName="Price List"
            onSelectVisible={checkAllPrices}
            cancelSelection={onUncheckAllPrices}
            headerComponent={<PriceListHeader />}
          />

          <Stack
            sx={{
              borderRadius: "0 0 4px 4px",
              maxHeight: "calc(100vh - 242px)",
              height: "100%",
              borderWidth: "0 1px 1px 1px",
              borderStyle: "solid",
              borderColor: "#D5D9D9",
              overflow: "hidden",
            }}
          >
            <InfiniteScrollWrapper
              maxHeight="calc(100vh - 242px)"
              dataLength={priceList.length}
              id="prise-list-scroll-table"
              loading={listLoading}
              nextFunc={() => onFetchPriceList(page + 1)}
              loader={
                <CircularProgress
                  sx={{ ml: "calc(50% - 15px)", mt: "10px" }}
                  size="30px"
                />
              }
              hasMoreItems={priceList.length < priceCount}
            >
              {priceList?.map((price) => {
                const isChecked = checkAlreadyChecked(price.id);
                return (
                  <PriceListItem
                    key={price.id}
                    price={price}
                    onEditSave={onEditSave}
                    onDelete={onPriceListDelete}
                    isChecked={isChecked}
                    handleCheckPrice={onPriceSelect}
                    repPermissions={repPermissions}
                  />
                );
              })}
              {!listLoading && priceList.length < priceCount && (
                <InfiniteLoadMoreBtn
                  onClick={() => onFetchPriceList(page + 1)}
                />
              )}
            </InfiniteScrollWrapper>
          </Stack>
        </>
      ) : (
        <EmptyScreen
          type="price lists"
          onConfirm={() => setOpenCreatePriceDialog(true)}
          height="calc(100vh - 242px)"
          showAction={!searchInputDebounced}
          loading={!priceList.length && listLoading}
          disabled={!!repPermissions && !repPermissions?.catalog?.create_edit}
          tooltipTitle={ADMIN_ONLY_VIEW_MESSAGE}
        />
      )}
    </Box>
  );
};

PriceList.propTypes = {
  currentTab: string,
  repPermissions: object,
};
export default PriceList;
