import { string, func, bool } from "prop-types";

import { cl } from "../styles";

import { Button, DialogActions } from "@mui/material";

export const CreatePriceListActions = ({
  onClose,
  onSave,
  priceName,
  loading,
}) => {
  return (
    <DialogActions
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        padding: "0px 28px 24px",
      }}
    >
      <Button sx={cl.cancelActionBtn} onClick={onClose} variant="outlined">
        Cancel
      </Button>
      <Button
        disabled={!priceName || loading}
        sx={cl.saveActionBtn}
        color="primary"
        onClick={onSave}
        variant="contained"
      >
        Save
      </Button>
    </DialogActions>
  );
};

CreatePriceListActions.propTypes = {
  onClose: func,
  priceName: string,
  onSave: func,
  loading: bool,
};
CreatePriceListActions.defaultProps = {
  onClose: () => {},
  onSave: () => {},
  loading: false,
};

//import { string, func, bool } from "prop-types";

//import { cl } from "../styles";

//import { Button, DialogActions } from "@mui/material";

//export const CreatePriceListActions = ({
//  onClose,
//  onSave,
//  priceName,
//  loading,
//}) => {
//  return (
//    <DialogActions
//      sx={{
//        display: "flex",
//        flexDirection: "column",
//        padding: "0px 40px 33px",
//        width: "100%",
//        gap: "8px",
//      }}
//    >
//      <Button
//        disabled={!priceName || loading}
//        sx={cl.saveActionBtn}
//        color="primary"
//        onClick={onSave}
//        variant="contained"
//      >
//        Save
//      </Button>
//      <Button sx={cl.cancelActionBtn} onClick={onClose} variant="outlined">
//        Cancel
//      </Button>
//    </DialogActions>
//  );
//};

//CreatePriceListActions.propTypes = {
//  onClose: func,
//  priceName: string,
//  onSave: func,
//  loading: bool,
//};
//CreatePriceListActions.defaultProps = {
//  onClose: () => {},
//  onSave: () => {},
//  loading: false,
//};
