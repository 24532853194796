import { useCallback, useMemo, useRef, useState } from "react";
import { shape, string, func, array, object } from "prop-types";
import { Box, IconButton } from "@mui/material";
import { CartTrashIcon } from "../../../../../components/Icons";
import { StyledPopper } from "../../../../../components";
import { useDeliveryItemActions } from "./useDeliveryItemActions";
import { BoxItem, StyledButton } from "./components";
import { cl } from "./DeliveryItem.styles";
import { useAdmin } from "helpers/helpers";
import { ADMIN_ONLY_VIEW_MESSAGE } from "utils/constants";

export const DeliveryItem = ({
  order,
  item,
  handleViewDetails,
  handleEditDetails,
  handleDeleteDelivery,
  timeZone,
  list,
  repPermissions,
}) => {
  const isAdmin = useAdmin();
  const refEditBtn = useRef();
  const refDeleteBtn = useRef();

  const {
    setType,
    setVia,
    setFulfilledBy,
    setDate,
    setQuantity,
    hasEditBtnDisabled,
    hasDeleteBtnDisabled,
    hideBtns,
  } = useDeliveryItemActions({ item, order, timeZone, list });

  const initialTooltipState = {
    openTooltip: false,
    anchorElTooltip: null,
    textTooltip: "",
    settingsTooltip: [],
  };

  const [state, setState] = useState(initialTooltipState);

  const { openTooltip, anchorElTooltip, textTooltip, settingsTooltip } = state;

  const setEditTooltip = useCallback(
    (type) => {
      if (repPermissions) return ADMIN_ONLY_VIEW_MESSAGE;

      if (item?.deliveryMethod === "SHIP_ENGINE")
        return (
          <>
            <span>{`Can't ${type}`}</span>
            <br />
            <span>Shipstation shipments</span>
          </>
        );
      if (item?.deliveryMethod === "REFUND") {
        return (
          <>
            <span>Return via refund</span>
            <br />
            <span>could not be edited</span>
          </>
        );
      }
      if (item?.type === "Delivery") {
        return (
          <>
            <span>Fulfilment could</span>
            <br />
            <span>
              not be{" "}
              {(type === "edit" && "edited") ||
                (type === "delete" && "deleted")}
            </span>
            <br />
            <span>due to returned items</span>
          </>
        );
      }
      if (item?.type === "Return") {
        return (
          <>
            <span>Return could</span>
            <br />
            <span>
              not be{" "}
              {(type === "edit" && "edited") ||
                (type === "delete" && "deleted")}
            </span>
            <br />
            <span>due to delivered items</span>
          </>
        );
      }
    },
    [item?.deliveryMethod, item?.type, repPermissions]
  );

  const onEnterEdit = () => {
    setState((prev) => ({
      ...prev,
      openTooltip: true,
      anchorElTooltip: refEditBtn.current,
      textTooltip: setEditTooltip("edit"),
      settingsTooltip: [
        {
          name: "offset",
          options: { offset: [0, 10] },
        },
      ],
    }));
  };

  const onEnterDelete = () => {
    setState((prev) => ({
      ...prev,
      openTooltip: true,
      anchorElTooltip: refDeleteBtn.current,
      textTooltip: setEditTooltip("delete"),
      settingsTooltip: [
        {
          name: "offset",
          options: { offset: [-3, 10] },
        },
      ],
    }));
  };

  const disabledEditBtn = useMemo(() => {
    if (repPermissions) return true;
    return isAdmin || hasEditBtnDisabled;
  }, [hasEditBtnDisabled, isAdmin, repPermissions]);

  const disabledDeleteBtn = useMemo(() => {
    if (repPermissions) return true;

    return isAdmin || hasDeleteBtnDisabled;
  }, [hasDeleteBtnDisabled, isAdmin, repPermissions]);

  return (
    <>
      <StyledPopper
        open={openTooltip}
        anchorEl={anchorElTooltip}
        text={textTooltip}
        modifiers={settingsTooltip}
        placement="top"
        transition
        aria-hidden="true"
      />
      <Box sx={cl.wrapper}>
        <BoxItem text={setType} width="16.7%" maxWidth="16.7%" pl={2} />
        <BoxItem text={setVia} width="16.6%" maxWidth="16.6%" pl={2} />
        <BoxItem
          sx={{ display: "flex", alignItems: "center", gap: "7px" }}
          text={setFulfilledBy.text}
          icon={setFulfilledBy.icon}
          width="20.1%"
          maxWidth="20.1%"
        />
        <BoxItem text={setDate} width="17.5%" maxWidth="17.5%" />
        <BoxItem text={setQuantity} width="12.4%" maxWidth="12.4%" />

        <Box sx={cl.btn_wrapper} pr={hideBtns ? 0 : "63px"}>
          <StyledButton
            color="groundLighter"
            onClick={(e) => {
              e.stopPropagation();
              handleViewDetails(item);
            }}
          >
            View
          </StyledButton>

          {hideBtns && (
            <>
              <Box sx={cl.divider} />
              <Box
                ref={refEditBtn}
                display="flex"
                onMouseEnter={() => {
                  if (hasEditBtnDisabled || repPermissions) {
                    onEnterEdit();
                  }
                }}
                onMouseLeave={() => {
                  if (openTooltip) {
                    setState(initialTooltipState);
                  }
                }}
              >
                <StyledButton
                  color="groundLighter"
                  disabled={disabledEditBtn}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditDetails({ ...item });
                  }}
                >
                  Edit
                </StyledButton>
              </Box>

              <Box
                ref={refDeleteBtn}
                display="flex"
                onMouseEnter={() => {
                  if (hasDeleteBtnDisabled || repPermissions) {
                    onEnterDelete();
                  }
                }}
                onMouseLeave={() => {
                  if (openTooltip) {
                    setState(initialTooltipState);
                  }
                }}
              >
                <IconButton
                  sx={{ mr: 1, p: "4px" }}
                  disabled={disabledDeleteBtn}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteDelivery(item?.id);
                  }}
                >
                  <CartTrashIcon
                    width="10.79"
                    height="11.99"
                    stroke="rgba(0, 0, 0, 0.26)"
                  />
                </IconButton>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

DeliveryItem.propTypes = {
  order: object,
  item: shape({
    id: string,
    deliveryMethod: string,
    type: string,
  }),
  timeZone: string,
  handleViewDetails: func,
  handleEditDetails: func,
  handleDeleteDelivery: func,
  list: array,
  repPermissions: object,
};
