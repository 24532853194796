import { useContext } from "react";

import { LargeCheckbox, TableHeader } from "components";
import { bool, object } from "prop-types";
import { cl } from "./TableHeaderBlock.styles";
import { ArrowDownIcon, ArrowUpIcon } from "components/Icons";

import { Box, Grid, Typography } from "@mui/material";
import { SORTABLE_COLUMNS } from "./TableHeaderBlock.constants";
import { ProductPriceContext } from "Pages/ProductPricePage";

export const TableHeaderBlock = ({ isSorted = false }) => {
  const {
    allCustomersChecked,
    onSelectAllCustomers,
    quickCustomersSort,
    handleCustomersSetSort,
  } = useContext(ProductPriceContext);

  const TABLE_DATA = [
    {
      id: 1,
      title: "",
      element: (
        <LargeCheckbox
          formSx={{ m: 0 }}
          checked={allCustomersChecked}
          onChange={onSelectAllCustomers}
          sx={cl.checkboxSx}
          onClick={(e) => e.stopPropagation()}
          size={22}
          rectSize={22}
          indeterminateIcon={27}
          checkedViewBox="0 0 24 24"
        />
      ),
      sx: { pl: "16px" },
      xs: 0.56,
    },
    {
      id: 2,
      title: "ID",
      sx: { ...cl.leftHeaderItem },
      xs: 0.7,
    },
    {
      id: 3,
      title: "CUSTOMER",
      sx: { ...cl.leftHeaderItem },
      xs: 4,
    },
    {
      id: 4,
      title: "TERRITORY",
      sx: { ...cl.leftHeaderItem },
      xs: 6.7,
    },
  ];

  return (
    <TableHeader
      sx={isSorted ? cl.sortedTableHeaderSx : cl.tableHeaderSx}
      columns={12}
    >
      {TABLE_DATA.map(({ id, sx, title, element, xs }) => {
        const activeSortableField = SORTABLE_COLUMNS?.[title]?.sortableField;
        return (
          <Grid sx={sx} key={id} item xs={xs}>
            {title && (
              <Typography fontSize={12} color="#6A6A6A" mr={1}>
                {title}
              </Typography>
            )}
            {element && element}

            {SORTABLE_COLUMNS?.[title] && (
              <Box
                sx={cl.arrowBox}
                onClick={() => {
                  handleCustomersSetSort(activeSortableField);
                }}
              >
                {quickCustomersSort[activeSortableField] === "asc" ? (
                  <ArrowDownIcon />
                ) : (
                  <ArrowUpIcon
                    fill={
                      !quickCustomersSort[activeSortableField]
                        ? "#a7a8aa"
                        : "#5f6267"
                    }
                  />
                )}
              </Box>
            )}
          </Grid>
        );
      })}
    </TableHeader>
  );
};

TableHeaderBlock.propTypes = {
  isSorted: bool,
  group: object,
};
