import { string, func, object } from "prop-types";
import { Avatar, Box, Typography } from "@mui/material";
import { stringAvatar } from "helpers/helpers";
import { ThirdPartyCircleIcon } from "components/Icons";
import { StyledTooltip } from "components";
import { ADMIN_ONLY_VIEW_MESSAGE_PAGE } from "utils/constants";

export const NameBlock = ({
  handleOpenProfileDialog,
  user,
  profilePhoto,
  repPermissions,
}) => {
  return (
    <Box
      sx={{
        pl: "0",
        width: "100%",
        height: "100%",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
      onClick={() => {
        if (repPermissions) return;
        handleOpenProfileDialog(user);
      }}
    >
      <StyledTooltip
        disableHoverListener={!repPermissions}
        title={ADMIN_ONLY_VIEW_MESSAGE_PAGE}
        arrow
        placement="top"
        PopperProps={{
          modifiers: [{ name: "offset", options: { offset: [0, -4] } }],
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            position: "relative",
          }}
        >
          {user?.role === "THIRD_PARTY" ? (
            <Box width="30px" height="30px">
              <ThirdPartyCircleIcon width={30} height={30} />
            </Box>
          ) : profilePhoto ? (
            <Avatar sx={{ width: "30px", height: "30px" }}>
              <Box
                component="img"
                sx={{ objectFit: "cover", width: "100%", height: "100%" }}
                src={profilePhoto}
              />
            </Avatar>
          ) : (
            <Avatar
              {...stringAvatar(user.name, {
                width: "30px",
                height: "30px",
                fontSize: "12px",
              })}
            />
          )}

          <Box
            sx={{
              ml: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "12px",
                color: "#1C1C19",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              noWrap
            >
              {user.name}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                color: "#1C1C19",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {user?.email || user?.baseUser?.email}
            </Typography>
          </Box>
        </Box>
      </StyledTooltip>
    </Box>
  );
};

NameBlock.propTypes = {
  handleOpenProfileDialog: func,
  user: object,
  profilePhoto: string,
  repPermissions: object,
};
NameBlock.defaultProps = {
  handleOpenProfileDialog: () => {},
  user: {},
  profilePhoto: "",
};
