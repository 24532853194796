import * as React from "react";
export const FormNumericTypeIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.609 13.182 10.279 3h1.193l-1.67 10.182zm-4.61-2.725.195-1.193h7.716l-.194 1.193zm1.03 2.725L6.7 3h1.194l-1.67 10.182zm-.437-6.264.193-1.194h7.716l-.194 1.194z"
      fill="#C9C9C9"
    />
  </svg>
);
