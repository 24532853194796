import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { func, array, bool, number, string, object } from "prop-types";
import CustomersHeader from "./CustomersHeader";
import CustomersItem from "./CustomersItem";
import { createSelector } from "reselect";
import { customersByRepListSelector } from "../../../../../../../redux/selectors/customers";
import { InfiniteScrollWrapper } from "components";

const selector = createSelector(
  customersByRepListSelector,
  (repCustomersList) => ({
    repCustomersList,
  })
);

const CustomersTableComponent = ({
  handleCheckCustomer,
  handleCheckOneCustomer,
  checkedCustomers,
  checkAllCustomers,
  fetchListAndCheckAll,
  repCustomersLoading,
  setCheckedCustomers,
  allCustomersChecked,
  customersList,
  handleFetchCustomers,
  customersCount,
  maxHeight,
  viewCustomerPopup,
  allowMissingInfo,
  hideStatus,
  priceListId = null,
  quickSort,
  handleSetSort,
  hideOrders = false,
  loading,
  existData,
}) => {
  const { repCustomersList } = useSelector(selector);
  const customers = useMemo(
    () => customersList || repCustomersList,
    [repCustomersList, customersList]
  );

  return (
    <Box sx={{ px: "26px" }}>
      <Stack sx={{ alignItems: "center" }}>
        <CustomersHeader
          {...{ quickSort, handleSetSort, hideOrders }}
          hideStatus={hideStatus}
          checkAllCustomers={checkAllCustomers}
          allCustomersChecked={allCustomersChecked}
          checkedCustomers={checkedCustomers}
          customersCount={customersCount}
          customers={customers}
          fetchListAndCheckAll={fetchListAndCheckAll}
          repCustomersLoading={repCustomersLoading}
          setCheckedCustomers={setCheckedCustomers}
          viewCustomerPopup={viewCustomerPopup}
          loading={loading}
        />

        <Stack
          width="100%"
          sx={{
            maxHeight,
            overflow: "hidden",
            borderWidth: "0 0.5px 0.5px 0.5px",
            borderStyle: "solid",
            borderColor: "#D5D9D9",
            borderRadius: "0 0 4px 4px",
          }}
        >
          <InfiniteScrollWrapper
            maxHeight={maxHeight}
            dataLength={customers?.length || 0}
            hasMore={customers?.length < customersCount}
            loader={
              <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="20px" />
            }
            next={handleFetchCustomers}
            id="customers-scroll-popup"
          >
            {loading && !existData ? (
              <Stack height="74px" justifyContent="center" alignItems="center">
                <CircularProgress size="20px" />
              </Stack>
            ) : customers.length ? (
              customers.map((customer) => {
                return (
                  <CustomersItem
                    priceListId={priceListId}
                    key={customer.id}
                    hideOrders={hideOrders}
                    customer={customer}
                    hideStatus={hideStatus}
                    viewCustomerPopup={viewCustomerPopup}
                    handleCheckCustomer={handleCheckCustomer}
                    handleCheckOneCustomer={handleCheckOneCustomer}
                    isChecked={checkedCustomers.some(
                      (item) => item.id === customer.id
                    )}
                    allowMissingInfo={allowMissingInfo}
                  />
                );
              })
            ) : (
              <Typography
                fontWeight="300"
                fontSize="16px"
                textAlign="center"
                width="100%"
                color="#6A6A6A"
                paddingTop="20px"
                paddingBottom="20px"
              >
                No customers found
              </Typography>
            )}
          </InfiniteScrollWrapper>
        </Stack>
      </Stack>
    </Box>
  );
};

CustomersTableComponent.propTypes = {
  handleCheckCustomer: func,
  checkedCustomers: array,
  checkAllCustomers: func,
  fetchListAndCheckAll: func,
  repCustomersLoading: bool,
  setCheckedCustomers: func,
  allCustomersChecked: bool,
  hideStatus: bool,
  showInactive: bool,
  customersList: array,
  handleFetchCustomers: func,
  customersCount: number,
  maxHeight: string,
  priceListId: string,
  viewCustomerPopup: bool,
  handleCheckOneCustomer: func,
  allowMissingInfo: bool,
  existData: bool,
  loading: bool,
  hideOrders: bool,
  quickSort: object,
  handleSetSort: func,
};

CustomersTableComponent.defaultProps = {
  checkedCustomers: [],
  allCustomersChecked: false,
  hideStatus: false,
  showInactive: false,
  customersList: null,
  handleFetchCustomers: () => {},
  customersCount: 0,
  maxHeight: "220px",
  existData: false,
  loading: false,
  priceListId: null,
};

export default CustomersTableComponent;
