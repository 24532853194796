import { useContext } from "react";

import { CustomerItem } from "..";
import { ProductPriceContext } from "Pages/ProductPricePage";

import { CircularProgress, Stack } from "@mui/material";
import { InfiniteLoadMoreBtn, InfiniteScrollWrapper } from "components";

export const ListBlock = () => {
  const {
    customersPage,
    customersList,
    customersCount,
    customersLoading,
    handleCheckCustomer,
    handleFetchCustomers,
    onCustomerFromListRemove,
    checkForCustomerSelection,
    repPermissions,
  } = useContext(ProductPriceContext);

  return (
    <Stack
      sx={{
        borderRadius: "0 0 4px 4px",
        maxHeight: "calc(100vh - 312px)",
        borderWidth: "0 0.5px 0.5px 0.5px",
        borderStyle: "solid",
        borderColor: "#D5D9D9",
      }}
    >
      {customersLoading ? (
        <Stack
          height="calc(100vh - 312px)"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={32} />
        </Stack>
      ) : (
        <InfiniteScrollWrapper
          skipScrollReset
          maxHeight="calc(100vh - 312px)"
          dataLength={customersList.length}
          id="product-prise-list-scroll-table-2"
          loading={customersLoading}
          nextFunc={() => handleFetchCustomers(customersPage + 1)}
          hasMoreItems={customersList.length < customersCount}
          loader={
            <CircularProgress
              sx={{ ml: "calc(50% - 15px)", mt: "10px" }}
              size="30px"
            />
          }
        >
          {customersList?.map((customer, index) => (
            <CustomerItem
              lastItem={index === customersList.length - 1}
              key={customer.id}
              {...{
                customer,
                onCustomerDelete: onCustomerFromListRemove,
                handleCheckCustomer,
                checkForCustomerSelection,
                repPermissions,
              }}
            />
          ))}
          {!customersLoading && customersList.length < customersCount && (
            <InfiniteLoadMoreBtn
              onClick={() => handleFetchCustomers(customersPage + 1)}
            />
          )}
        </InfiniteScrollWrapper>
      )}
    </Stack>
  );
};
