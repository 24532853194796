import { number, object, string } from "prop-types";
import { Box, Typography } from "@mui/material";
import pluralize from "pluralize";
import { useContext } from "react";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";

export const BottomBlock = ({
  name = "",
  count = 0,
  wrapProps = {},
  item = {},
}) => {
  const { dialogPreviewContentData } = useContext(FormViewContext);

  const { handleOpenDialogPreviewContent } = dialogPreviewContentData;

  return (
    <Box {...wrapProps}>
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 400,
          color: "#409A65",
          cursor: "pointer",
          display: "inline-block",
        }}
        onClick={() =>
          handleOpenDialogPreviewContent({ item, seeAllAction: true })
        }
      >
        See all {count} {pluralize(name, count)}
      </Typography>
    </Box>
  );
};

BottomBlock.propTypes = {
  name: string,
  count: number,
  wrapProps: object,
  item: object,
};
