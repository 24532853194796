import { useState } from "react";
import { array, bool, func, number } from "prop-types";

import { CrossBigIcon, NoteIcon } from "components/Icons";

import { Divider, IconButton, Stack, Typography } from "@mui/material";

export const RepsItem = ({ list, isSingleSelect, onDelete, onEdit, count }) => {
  const [isHovered, setIsHovered] = useState(false);

  const { name, baseUser, email } = list[0] || {};

  return (
    <Stack
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      direction="row"
      alignItems="center"
      sx={{
        display: "inline-flex",
        width: "100%",
        p: "4px 12px",
        background: "#F8F8F8",
        borderRadius: "4px",
        border: "0.5px #D5D9D9 solid",
        height: "fit-content",
        minHeight: "36px",
        gap: "10px",
        justifyContent: "space-between",
      }}
    >
      {!isSingleSelect ? (
        <Typography fontSize={11} color="#343434" noWrap>
          Selected reps: <span style={{ color: "#409A65" }}>{count}</span>
        </Typography>
      ) : (
        <Stack
          gap="2px"
          width={isHovered ? "calc(100% - 62px)" : "100%"}
          sx={{ transition: "all 0.3s" }}
        >
          <Typography fontSize={11} lineHeight="13px" color="#343434" noWrap>
            {name}
          </Typography>
          <Typography fontSize={10} lineHeight="12px" color="#707070" noWrap>
            {email || baseUser?.email}
          </Typography>
        </Stack>
      )}

      <Stack
        direction="row"
        sx={{
          height: "100%",
          opacity: isHovered ? 1 : 0,
          transition: "all 0.3s",
        }}
        gap="8px"
      >
        <IconButton disableRipple sx={{ p: 0 }} onClick={onEdit}>
          <NoteIcon color="#707070A3" width={19.5} height={18.5} />
        </IconButton>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ margin: "2px 0", background: "#D9D9D9" }}
        />
        <IconButton disableRipple sx={{ p: 0 }} onClick={onDelete}>
          <CrossBigIcon size={13} strokeWidth={4} color="#707070A3" />
        </IconButton>
      </Stack>
    </Stack>
  );
};

RepsItem.propTypes = {
  list: array,
  onEdit: func,
  count: number,
  onDelete: func,
  isSingleSelect: bool,
};
