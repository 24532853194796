import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useStyles from "./styles";
import { AlarmClockIcon, CheckmarkIcon, CrossIcon } from "components/Icons";
import moment from "moment-timezone";
import AdapterMoment from "@mui/lab/AdapterMoment";
import {
  ActionRestrictionWrapper,
  AddFormsComponent,
  LargeCheckbox,
  StyledButton,
  StyledDatePicker,
  StyledSelect,
  StyledTextField,
  StyledTimePicker,
  StyledTooltip,
} from "components";
import { getPriority, useCustomerTasks } from "./TaskDrawer.hooks";
import { Controller } from "react-hook-form";
import { useTableFilters } from "../../CustomerProfile.hooks";
import { ActivityBlock } from "./ActivityBlock/ActivityBlock";
import { useDispatch, useSelector } from "react-redux";
import { IntervalContainer } from "components/TaskDrawer/components/IntervalContainer";
import { useFormFields } from "components/TaskDrawer/TaskDrawer.hooks";
import { ConfirmTaskDialog } from "components/TaskDrawer/components/ConfirmTaskDialog";
import { useRepsPermissions } from "helpers/hooks";
import { truncateText, useAdmin } from "helpers/helpers";
import { createSelector } from "reselect";
import { confirmDialogFormChangedSelector } from "redux/selectors/confirmDialogs";
import {
  openDiscardChanges,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";

const selector = createSelector(
  confirmDialogFormChangedSelector,
  (formChanged) => ({
    formChanged,
  })
);

const TaskDrawer = () => {
  const classes = useStyles();
  const { formChanged } = useSelector(selector);
  const dispatch = useDispatch();
  const repPermissions = useRepsPermissions();
  const [dialogState, setDialogState] = useState({
    isOpen: false,
    isDeleting: false,
  });

  const isAdmin = useAdmin();

  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const { timeZone } = currentUser || {};

  const {
    handleSaveTask,
    handleCloseTask: onClose,
    open,
    handleDeleteTask,
    loading,
    taskState,
    editTask,
    handleUpdateCompletedTask,
    handleOnDeleteDrawer,
    handleOnSaveDrawer,
    handleOnCloseDrawer,
    handleConfirmDelete,
  } = useCustomerTasks({ setDialogState, formChanged });

  const {
    formField,
    control,
    setValue,
    setError,
    clearErrors,
    isDirty,
    isRepeatedTask,
    handleSubmit,
    errors,
    PRIORITIES,
    STATUSES,
    trigger,
  } = useFormFields({
    open,
    taskState: taskState?.task?.task || taskState?.task,
  });

  const {
    title,
    representative,
    status,
    distributorId,
    representativeId,
    assignedDistributorId,
    assignedRepresentatives,
  } = formField || {};
  const { dueDate: dueDateError } = errors || {};

  const disabledAddBtn = useMemo(() => {
    return repPermissions
      ? !repPermissions?.tasks?.create_edit
      : !(title && representative) || !!dueDateError;
  }, [repPermissions, title, representative, dueDateError]);

  const preventCloseDrawer = useCallback(
    ({ onClose }) => {
      // eslint-disable-next-line no-console
      if (!onClose) return console.error("onClose is a required parameter");

      if (formChanged) {
        return dispatch(
          openDiscardChanges(
            () => onClose(),
            async () => {
              const resTrigger = await trigger();
              if (resTrigger && !disabledAddBtn && !loading) {
                handleSaveTask(formField);
              } else {
                dispatch(setFormChangedAction(isDirty));
              }
            }
          )
        );
      }

      onClose();
    },
    [
      disabledAddBtn,
      dispatch,
      formChanged,
      formField,
      handleSaveTask,
      isDirty,
      loading,
      trigger,
    ]
  );

  const handleCloseTask = useCallback(() => {
    preventCloseDrawer({ onClose });
  }, [onClose, preventCloseDrawer]);

  const isRepeatedTaskChanged = useMemo(
    () => isRepeatedTask && isDirty,
    [isRepeatedTask, isDirty]
  );

  const activities =
    taskState?.task?.task?.activities || taskState?.task?.activities;

  const { repsState, handleFetchReps } = useTableFilters();

  const getTitleLabel = useMemo(() => {
    if (editTask) return "Edit";

    return "Create";
  }, [editTask]);

  const disabledCancelBtn = useMemo(() => {
    return loading;
  }, [loading]);

  const disabledDeleteBtn = useMemo(() => {
    return repPermissions ? !repPermissions?.tasks?.delete : loading;
  }, [loading, repPermissions]);

  const setStatusLabelBtn = useMemo(() => {
    if (status === STATUSES[0]) return "Mark complete";
    if (status === STATUSES[1]) return "Completed";
  }, [STATUSES, status]);

  const isValidDate = (str) => {
    if (!str) return false;
    const parsedDate = moment(str, "MM/DD/YYYY", true);

    return parsedDate.isValid();
  };

  useEffect(() => {
    dispatch(setFormChangedAction(isDirty));

    if (!open) dispatch(setFormChangedAction(false));
  }, [isDirty, dispatch, open]);

  const isMarkBtnDisabled = useMemo(() => {
    if (!repPermissions) return assignedDistributorId !== currentUser?.id;

    const repsIds = assignedRepresentatives?.map(
      (rep) => rep?.representative?.id
    );

    return !repsIds?.includes(repPermissions?.representativeId);
  }, [
    repPermissions,
    assignedDistributorId,
    currentUser?.id,
    assignedRepresentatives,
  ]);

  const isNotAllowedToEdit = useMemo(() => {
    if (!taskState?.task) return false;

    if (!repPermissions)
      return distributorId !== currentUser?.id || representativeId;

    return repPermissions?.representativeId !== representativeId;
  }, [
    currentUser?.id,
    distributorId,
    repPermissions,
    representativeId,
    taskState?.task,
  ]);

  const editCheckedForms = useMemo(() => {
    return editTask?.assignedForms?.map((item) => ({
      id: item?.form?.id,
      title: item?.form?.title,
    }));
  }, [editTask?.assignedForms]);

  const handleSetCheckedFormsIds = useCallback(
    (ids) => setValue("formIds", ids),
    [setValue]
  );

  return (
    <>
      <ConfirmTaskDialog
        {...dialogState}
        loading={loading}
        handleSaveTask={handleSaveTask}
        formField={formField}
        isRepeatedTask={isRepeatedTask}
        onClose={handleOnCloseDrawer}
        setValue={setValue}
        setDialogState={setDialogState}
        handleConfirmDelete={handleConfirmDelete}
      />
      <Drawer
        disableRestoreFocus
        open={open}
        onClose={handleCloseTask}
        anchor="right"
      >
        <Box className={classes.wrapper}>
          <Box className={classes.header}>
            <Typography>{getTitleLabel} task</Typography>
            {loading && <CircularProgress size={23} />}
            <IconButton onClick={handleCloseTask}>
              <CrossIcon />
            </IconButton>
          </Box>

          <Box className={classes.bodyWrapper}>
            {/* Name */}
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTooltip
                  arrow
                  placement="top"
                  title={
                    error?.message
                      ? error?.message
                      : "This field can't be empty"
                  }
                  open={!!error}
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: { offset: [0, -5] },
                      },
                    ],
                  }}
                >
                  <StyledTextField
                    placeholder="Name"
                    fullWidth
                    disabled={isNotAllowedToEdit}
                    error={!!error}
                    sx={{
                      "& .MuiOutlinedInput-root": { height: "100%" },
                    }}
                    {...field}
                  />
                </StyledTooltip>
              )}
              name="title"
              control={control}
            />

            {/* Description */}
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTooltip
                  arrow
                  placement="top"
                  title={error?.message ? error?.message : ""}
                  open={!!error}
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: { offset: [0, -5] },
                      },
                    ],
                  }}
                >
                  <StyledTextField
                    placeholder="Description"
                    multiline
                    rows={5}
                    disabled={isNotAllowedToEdit}
                    fullWidth
                    error={!!error}
                    sx={{
                      "& .MuiOutlinedInput-root": { height: "100%" },
                    }}
                    {...field}
                  />
                </StyledTooltip>
              )}
              name="description"
              control={control}
            />

            {/* Due date */}
            <Stack flexDirection="row" gap={1.75}>
              <Box width="67%">
                <Controller
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <StyledDatePicker
                        value={moment.tz(field.value, timeZone)}
                        dateLibInstance={moment}
                        dateAdapter={AdapterMoment}
                        wrapperClassName={classes.calendarInput}
                        disabled={isNotAllowedToEdit}
                        textFieldProps={{
                          fullWidth: true,
                          noErrorMessage: true,
                        }}
                        inputProps={{ placeholder: "Due date" }}
                        error={error?.message ? error.message : ""}
                        onChange={(time, keyboardVal) => {
                          let isoTime = "";
                          if (keyboardVal) {
                            if (!isValidDate(keyboardVal)) {
                              setError("dueDate", {
                                type: "dueDateValidate",
                                message: "Incorrect date",
                              });
                            } else {
                              clearErrors("dueDate");
                            }

                            const formattedDate = moment
                              .tz(keyboardVal, "MM/DD/YYYY", timeZone)
                              .utc()
                              .format();

                            const isValidFormattedDate =
                              moment(formattedDate).isValid();

                            isoTime =
                              isValidFormattedDate && formattedDate
                                ? formattedDate
                                : "";
                          } else if (time) {
                            isoTime = moment
                              .tz(time, "MM/DD/YYYY", timeZone)
                              .hours(0)
                              .minutes(0)
                              .seconds(0)
                              .utc()
                              .toISOString();
                            clearErrors("dueDate");
                          } else {
                            if (!keyboardVal) {
                              setValue("dueDate", null);
                              clearErrors("dueDate");
                              return;
                            }
                          }
                          field.onChange(isoTime);
                        }}
                      />
                    );
                  }}
                  name="dueDate"
                  control={control}
                />
              </Box>
              <Box width="33%">
                <Controller
                  render={({ field }) => {
                    return (
                      <StyledTimePicker
                        disabled={!formField.addTime || isNotAllowedToEdit}
                        adminIsAllowed
                        timezone="UTC"
                        dateLibInstance={moment}
                        dateAdapter={AdapterMoment}
                        textFieldProps={{
                          fullWidth: true,
                          formSx: { backgroundColor: "#ffffff" },
                        }}
                        OpenPickerIcon={AlarmClockIcon}
                        InputProps={{
                          sx: {
                            height: "32px",
                            fontSize: "12px",
                            color: "#5F6267",
                          },
                        }}
                        am={true}
                        value={moment.tz(formField?.dueTime, timeZone).utc()}
                        onChange={(time) => {
                          const isoTime = new Date(time).toISOString();
                          setValue(field?.name, isoTime);
                        }}
                      />
                    );
                  }}
                  name="dueTime"
                  control={control}
                />
              </Box>
            </Stack>

            <Stack direction="row" alignItems="center" gap="9px">
              <Box>
                <Controller
                  render={({ field }) => (
                    <LargeCheckbox
                      checked={!!field.value}
                      size={16}
                      disabled={isNotAllowedToEdit}
                      formSx={{ m: 0 }}
                      sx={{ padding: 0 }}
                      {...field}
                      onChange={(e, newVal) => {
                        setValue("addTime", newVal);

                        if (!newVal) {
                          setValue("repeatInterval", "day");
                          setValue("repeatStep", "1");
                        }
                      }}
                    />
                  )}
                  name="addTime"
                  control={control}
                />
              </Box>
              <Typography variant="header" fontSize="13px">
                Add time
              </Typography>
            </Stack>

            {/* Interval */}
            {formField.dueDate && (
              <IntervalContainer
                control={control}
                formField={formField}
                setValue={setValue}
                isNotAllowedToEdit={isNotAllowedToEdit}
              />
            )}

            {/* Assignee */}
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledSelect
                  label="Assignee"
                  disabled={repsState?.loading || isAdmin || isNotAllowedToEdit}
                  fullWidth
                  error={!!error}
                  sx={{
                    "& .MuiSvgIcon-root": { color: "#9E9E9E" },
                  }}
                  IconComponent={PersonAddIcon}
                  {...field}
                  dataLength={repsState?.list?.length}
                  dataCount={repsState?.count}
                  handleFetch={handleFetchReps}
                >
                  <MenuItem value="distributor">Admin</MenuItem>
                  {repsState?.list?.length
                    ? repsState.list.map((rep) => (
                        <MenuItem key={rep?.id} value={rep?.id}>
                          {truncateText(rep?.name, 70)}
                        </MenuItem>
                      ))
                    : null}
                </StyledSelect>
              )}
              name="representative"
              control={control}
            />

            {/* Priority */}
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledSelect
                  disabled={isNotAllowedToEdit}
                  label="Priority"
                  fullWidth
                  error={!!error}
                  {...field}
                >
                  {PRIORITIES.map((p) => (
                    <MenuItem key={p} value={p}>
                      {getPriority({ type: p })}
                    </MenuItem>
                  ))}
                </StyledSelect>
              )}
              name="priority"
              control={control}
            />

            {/* Add Forms */}
            <AddFormsComponent
              isNotAllowedToEdit={isNotAllowedToEdit}
              editCheckedForms={editCheckedForms}
              handleSetCheckedFormsIds={handleSetCheckedFormsIds}
            />

            {/* activities */}
            {editTask ? <ActivityBlock {...{ activities }} /> : null}
          </Box>

          {/* Action buttons */}
          <Box className={classes.footer}>
            {editTask && (
              <ActionRestrictionWrapper
                disableHoverListener={!disabledDeleteBtn}
              >
                <StyledButton
                  disabled={disabledDeleteBtn || isAdmin || isNotAllowedToEdit}
                  label="Delete"
                  color="error"
                  variant="contained"
                  fontSize="15px"
                  onClick={() => {
                    if (isRepeatedTask) return handleOnDeleteDrawer();
                    return handleDeleteTask();
                  }}
                />
              </ActionRestrictionWrapper>
            )}

            {editTask && (
              <ActionRestrictionWrapper
                disableHoverListener={
                  !(repPermissions && !repPermissions?.tasks?.create_edit)
                }
              >
                <StyledButton
                  sx={{ ml: "11px" }}
                  disabled={
                    isMarkBtnDisabled ||
                    (repPermissions && !repPermissions?.tasks?.create_edit) ||
                    isAdmin
                  }
                  label={setStatusLabelBtn}
                  variant="outlined"
                  fontSize="15px"
                  color="cancel"
                  startIcon={
                    <CheckmarkIcon width="11.18" height="8" stroke="#A0A0A0" />
                  }
                  onClick={() => {
                    if (status === STATUSES[0]) setValue("status", STATUSES[1]);
                    if (status === STATUSES[1]) setValue("status", STATUSES[0]);
                    if (status === STATUSES[0]) {
                      handleUpdateCompletedTask(STATUSES[1]);
                    } else if (status === STATUSES[1]) {
                      handleUpdateCompletedTask(STATUSES[0]);
                    }
                    0;
                  }}
                />
              </ActionRestrictionWrapper>
            )}

            <Box display="flex" gap="11px" ml="auto">
              <StyledButton
                disabled={disabledCancelBtn}
                label="Cancel"
                color="cancel"
                fontSize="15px"
                onClick={handleCloseTask}
              />
              {editTask && isRepeatedTaskChanged ? (
                <ActionRestrictionWrapper
                  disableHoverListener={
                    !(repPermissions && !repPermissions?.tasks?.create_edit)
                  }
                >
                  <StyledButton
                    disabled={
                      disabledAddBtn || loading || isAdmin || isNotAllowedToEdit
                    }
                    label={editTask ? "Save" : "Add"}
                    variant="contained"
                    fontSize="15px"
                    onClick={handleOnSaveDrawer}
                  />
                </ActionRestrictionWrapper>
              ) : (
                <ActionRestrictionWrapper
                  disableHoverListener={
                    !(repPermissions && !repPermissions?.tasks?.create_edit)
                  }
                >
                  <StyledButton
                    disabled={disabledAddBtn || isAdmin || isNotAllowedToEdit}
                    label={editTask ? "Save" : "Add"}
                    variant="contained"
                    fontSize="15px"
                    onClick={handleSubmit(handleSaveTask)}
                  />
                </ActionRestrictionWrapper>
              )}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default TaskDrawer;
