import { useEffect, useMemo, useState } from "react";
import { func, object, string, number } from "prop-types";
import {
  ActiveIcon,
  CartTrashIcon,
  ErrorIcon,
  StyledProductIco,
} from "components/Icons";
import {
  ActionRestrictionWrapper,
  LargeCheckbox,
  PriceFormat,
  StyledTooltip,
  ValueToggleButtonGroup,
} from "components";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { ProductNameBlock } from "./components/ProductNameBlock";
import { photoUrl } from "helpers/helpers";
import { cl } from "./styles";

export const ProductItem = ({
  handleCheckProduct,
  index,
  updateFieldByIndex,
  product,
  onRemoveProduct,
  checkForProductSelection,
  getFinalPrice,
  productMessages,
  isAllowed,
  setProductMessage,
  repPermissions,
}) => {
  const {
    id,
    discountType,
    discountValue,
    product: { photos, wholesalePrice, parentProduct, manufacturer },
  } = product || {};

  const [isItemHovered, setIsItemHovered] = useState(false);
  const [tempDiscountValue, setTempDiscountValue] = useState("");

  const isChecked = checkForProductSelection(product.id);

  const handleDiscountChange = (val) =>
    setTempDiscountValue(val.value === 0 ? "" : val.value);

  const handleBlur = () => {
    updateFieldByIndex({
      index,
      field: "discountValue",
      value: tempDiscountValue,
    });
    if (Number(tempDiscountValue))
      return setTempDiscountValue(Number(tempDiscountValue).toFixed(2));

    return setTempDiscountValue("");
  };

  const finalPrice = useMemo(
    () => getFinalPrice(wholesalePrice, tempDiscountValue, discountType) || 0,
    [discountType, getFinalPrice, tempDiscountValue, wholesalePrice]
  );

  useEffect(() => {
    const hasPriceError = finalPrice < 0;
    if (hasPriceError)
      setProductMessage(id, "Can't exceed standard price", "error");

    if (!hasPriceError && productMessages?.error)
      setProductMessage(id, "", "error");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalPrice]);

  useEffect(() => {
    if (tempDiscountValue !== discountValue)
      setTempDiscountValue(
        Number(discountValue) === 0 ? "" : Number(discountValue).toFixed(2)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountValue]);

  const showSuccessMessage = useMemo(
    () =>
      productMessages?.success &&
      !productMessages?.error &&
      !productMessages?.loading,
    [productMessages]
  );

  const disabledEditPriceList = useMemo(() => {
    return repPermissions
      ? !repPermissions?.catalog?.create_edit_price_lists
      : false;
  }, [repPermissions]);

  return (
    <Grid
      onMouseEnter={() => setIsItemHovered(true)}
      onMouseLeave={() => setIsItemHovered(false)}
      sx={{
        backgroundColor: isChecked ? "#ECF5F0" : "#ffff",
        alignItems: "center",
        height: "49px",
        overflow: "hidden",
        minHeight: "49px",
        maxHeight: "49px",
        borderRadius: "0 0 4px 4px",
        "&:hover": {
          backgroundColor: isChecked ? "#ECF5F0" : "#F7F7F7",
        },
        "&:not(:last-of-type)": {
          borderBottom: "0.5px solid #D5D9D9",
          borderRadius: "0px",
        },
      }}
      container
    >
      <Grid sx={{ pl: "15.5px" }} xs={0.42} item>
        <LargeCheckbox
          checked={isChecked}
          sx={{ padding: 0 }}
          formSx={{ m: 0 }}
          size={22}
          rectSize={22}
          onChange={() => handleCheckProduct(product, parent)}
          onClick={(e) => e.stopPropagation()}
          checkedViewBox="0 0 24 24"
        />
      </Grid>

      <Grid pr={1} xs={5.1} item sx={cl.productNameBlock}>
        <Box sx={cl.productPhoto}>
          <StyledProductIco
            src={photos?.length ? photoUrl(photos[0].fileName) : ""}
            styles={cl.emptyProduct}
          />
        </Box>
        <ProductNameBlock {...{ product: product?.product }} />
      </Grid>

      <Grid xs={2} item pr={1}>
        <Typography fontSize={12} fontWeight={400} color="#1C1C19" noWrap>
          {manufacturer?.name || parentProduct?.manufacturer?.name}
        </Typography>
      </Grid>

      <Grid xs={1.6} item pr={1}>
        <Typography fontSize={12} fontWeight={400} color="#1C1C19" noWrap>
          ${(wholesalePrice || 0).toFixed(2)}
        </Typography>
      </Grid>

      <Grid xs={1.48} item position="relative">
        <IconButton
          disableRipple
          sx={{
            ...cl.messageBtn,
            visibility: showSuccessMessage ? "visible" : "hidden",
          }}
        >
          <ActiveIcon />
        </IconButton>

        <StyledTooltip
          arrow
          isDark
          title={productMessages?.error || ""}
          placement="top"
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: { offset: [0, -6] },
              },
            ],
          }}
          disableHoverListener={!productMessages?.error}
        >
          <IconButton
            disableRipple
            sx={{
              ...cl.messageBtn,
              p: "3px 6px",
              visibility: productMessages?.error ? "visible" : "hidden",
            }}
          >
            <ErrorIcon />
          </IconButton>
        </StyledTooltip>
        <IconButton
          disableRipple
          sx={{
            ...cl.messageBtn,
            top: "-1px",
            visibility:
              productMessages?.loading && !productMessages?.error
                ? "visible"
                : "hidden",
          }}
        >
          <CircularProgress size={18} />
        </IconButton>
        <ActionRestrictionWrapper
          placement="left"
          disableHoverListener={!disabledEditPriceList}
        >
          <PriceFormat
            disabled={disabledEditPriceList}
            type={discountType}
            errorMsgSx={{
              transform: "translate(-100%, 0)",
              bottom: 0,
              left: -5,
            }}
            isAllowed={(values) => isAllowed(discountType, values)}
            value={tempDiscountValue}
            //value={
            //  Number(tempDiscountValue) === 0
            //    ? ""
            //    : Number(tempDiscountValue).toFixed(2)
            //}
            noErrorMessage
            //decimalScale={2}
            //thousandSeparator
            //fixedDecimalScale={false}
            onValueChange={handleDiscountChange}
            onBlur={handleBlur}
            onFocus={() => {}}
            error={!!productMessages?.error}
            fullWidth
            allowNegative={false}
            InputProps={{
              sx: cl.moqInput,
              endAdornment: (
                <ValueToggleButtonGroup
                  disabled={disabledEditPriceList}
                  qtyPicker={false}
                  hideTooltip
                  type={discountType}
                  toggleBtnSx={cl.toggleBtn}
                  selectedColor="#47A06D26"
                  unSelectedColor="#F8F8F8"
                  onChange={(val) => {
                    setProductMessage(id, "", "success");
                    updateFieldByIndex({
                      index,
                      field: "discountType",
                      value: val.target.value,
                      skipIcons: true,
                    });
                    setTempDiscountValue("");
                  }}
                />
              ),
            }}
          />
        </ActionRestrictionWrapper>
      </Grid>

      <Grid xs={1.15} item>
        <Typography fontSize={12} fontWeight={400} color="#1C1C19" noWrap>
          ${(finalPrice < 0 ? 0 : finalPrice).toFixed(2)}
        </Typography>
      </Grid>
      <Grid xs={0.25} item>
        <ActionRestrictionWrapper disableHoverListener={!disabledEditPriceList}>
          <IconButton
            disabled={disabledEditPriceList}
            onClick={() => onRemoveProduct(product)}
            sx={{
              p: 0,
              opacity: isItemHovered ? 1 : 0,
              transition: "all 0.2s",
            }}
          >
            <CartTrashIcon width="15" height="17" />
          </IconButton>
        </ActionRestrictionWrapper>
      </Grid>
    </Grid>
  );
};

ProductItem.propTypes = {
  handleCheckProduct: func,
  onRemoveProduct: func,
  isAllowed: func,
  checkForProductSelection: func,
  product: object,
  productMessages: object,
  lastItemIdWithoutBottomLine: string,
  index: number,
  updateFieldByIndex: func,
  getFinalPrice: func,
  onPriceCheck: func,
  setProductMessage: func,
  repPermissions: object,
};
ProductItem.defaultProps = {
  lastItemIdWithoutBottomLine: "",
  isChecked: false,
  handleCheckProduct: () => {},
};
