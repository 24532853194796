import { Box, Typography } from "@mui/material";

import { cl } from "../styles";
import { object } from "prop-types";
import { useMemo } from "react";

export const ProductNameBlock = ({ product }) => {
  const { parentProduct, sku, size, color, name } = product || {};

  const setChildrenName = useMemo(() => {
    if (!sku) return "-";
    return `${sku} ${size ? `/ ${size}` : ""} ${color ? `/ ${color}` : ""}`;
  }, [color, size, sku]);

  return (
    <Box sx={cl.nameWrapper}>
      <Box display="flex" alignItems="center" maxWidth="90%">
        <Typography sx={cl.productName} noWrap>
          {name || parentProduct?.name || "-"}
        </Typography>
      </Box>
      <Typography sx={cl.productNameVariation} noWrap>
        {setChildrenName}
      </Typography>
    </Box>
  );
};

ProductNameBlock.propTypes = {
  product: object,
};
