import { Box, Stack } from "@mui/material";
import { CardMediaImage } from "components";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";
import { object, bool } from "prop-types";
import { useContext } from "react";

export const PhotoBlock = ({ response = {}, isDialog = false }) => {
  const { dialogPreviewPhotosData } = useContext(FormViewContext);

  const { handleOpenDialogPreviewPhotos } = dialogPreviewPhotosData;

  const { photos = [] } = response;

  return (
    <Stack
      mt="12px"
      mb="12px"
      flexDirection="row"
      justifyContent={
        photos?.length === 5 && !isDialog ? "space-between" : "flex-start"
      }
      gap={photos?.length === 5 && !isDialog ? null : "12px"}
    >
      {photos.map((product, i) => (
        <Box key={product?.id} sx={{ width: "100px" }}>
          <CardMediaImage
            src={product?.compressedFileName}
            alt={`${i + 1} - photo`}
            height="100px"
            onClick={() =>
              handleOpenDialogPreviewPhotos({
                response,
                position: photos.findIndex((item) => item?.id === product?.id),
              })
            }
          />
        </Box>
      ))}
    </Stack>
  );
};

PhotoBlock.propTypes = { response: object, isDialog: bool };
