import { Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { QuestionItem } from "./components/QuestionItem/QuestionItem";
import { FormViewContext } from "../../FormViewPage";

export const QuestionsPreview = () => {
  const { formField } = useContext(FormViewContext);

  return (
    <Stack width="100%" maxWidth="277px" gap="20px" direction="column">
      <Typography color="#595959" fontSize="16px" fontWeight="500">
        Questions
      </Typography>

      {!!formField?.questions.length && (
        <Stack gap="16px">
          {formField.questions.map((question, index) => {
            return (
              <QuestionItem
                {...{ question, index: index + 1 }}
                key={question?.uuid}
              />
            );
          })}
        </Stack>
      )}
    </Stack>
  );
};
