import { bool, number } from "prop-types";
import { Backdrop, CircularProgress } from "@mui/material";

export const Loader = ({ isLoading, zIndexIncrease = 0 }) => {
  return (
    <Backdrop
      sx={{
        color: (theme) => theme.palette.primary.light,
        zIndex: (theme) => theme.zIndex.tooltip + 1 + zIndexIncrease,
      }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

Loader.propTypes = {
  isLoading: bool,
  zIndexIncrease: number,
};

Loader.defaultTypes = {
  isLoading: false,
  zIndexIncrease: 0,
};

export default Loader;
