import { makeStyles } from "@mui/styles";

export const cl = {
  NewProductsFilter: {
    wrapper: {
      backgroundColor: "#ffffff",
      display: "flex",
      borderWidth: "0.5px 0.5px 0.5px",
      borderStyle: "solid",
      borderColor: "#d5d9d9",
      borderRadius: "4px 4px 0 0",
      alignItems: "center",
      padding: "12px",
      gap: "11px",
    },
    searchAdor: {
      display: "flex",
      overflow: "auto",
      gap: 0.5,
      py: "4px",
      "&::-webkit-scrollbar": {
        height: "2px",
      },
    },
    btnLabel: {
      fontSize: "9px!important",
      color: "#ffffff",
      backgroundColor: "#47A06D",
      borderRadius: "50%",
      width: "15px",
      height: "15px",
    },
    btnFilter: {
      height: "39px",
      width: "100%",
      maxWidth: "74px",
      border: "0.5px solid #D5D9D9",
      "& .MuiButton-startIcon": {
        ml: 0,
      },
    },
  },
};

const useStyles = makeStyles(() => ({
  tab: {
    textTransform: "none",
    fontSize: "20px",
    color: "#707070",
    "&.Mui-selected": {
      backgroundColor: "transparent!important",
    },
    width: "fit-content",
    minWidth: "10px",
    paddingLeft: "8px",
    paddingRight: "8px",
  },

  tabs: {
    width: "100%",
    maxWidth: "168px",
  },
  filterChip: {
    borderRadius: "4px",
    border: "0.7px solid #d5d9d9",
    backgroundColor: "#FAFAFB",
    height: "28px",
  },
}));

export default useStyles;
