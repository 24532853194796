import { useContext } from "react";

import { StyledTabs } from "components";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";

import { cl } from "./TabsBlock.styles";
import { TABS_VISIBILITY_SETTINGS_PAGE } from "Pages/FormViewPage/FormViewPage.constants";
import { Stack } from "@mui/material";

export const TabsBlock = () => {
  const { handleChangeVisibilitySettingsTab, currentVisibilitySettingsTab } =
    useContext(FormViewContext);

  return (
    <Stack sx={cl.tabWrapper}>
      <StyledTabs
        onChange={(e, newVal) => handleChangeVisibilitySettingsTab(newVal)}
        value={currentVisibilitySettingsTab}
        tabs={TABS_VISIBILITY_SETTINGS_PAGE}
        tabProps={{ sx: cl.tabsPropsSx }}
        sx={cl.tabs}
      />
    </Stack>
  );
};
