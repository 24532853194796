import { bool, func } from "prop-types";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { CrossIcon, DangerIcon } from "components/Icons";
import { StyledButton } from "components";

export const QuestionDeleteDialog = ({ open, handleClose, handleSubmit }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 10px 12px 31px",
        }}
      >
        <Box
          sx={{
            display: "inline-flex",
            gap: "11px",
            alignItems: "center",
          }}
        >
          <DangerIcon size={22} />
          <Typography fontSize="17px" color="#3F3F3F">
            Questions deletion
          </Typography>
        </Box>
        <IconButton onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          minWidth: "388px",
          padding: "0px 42px 0px 42px",
          boxSizing: "border-box",
          marginBottom: "12px",
          maxHeight: "178px",
          overflow: "auto",
        }}
      >
        <Typography>
          The selected question has responses associated with it.
        </Typography>
        <Typography>Would you like to delete only the question?</Typography>
        <Typography>
          Would you like to delete the question and all responses?
        </Typography>
        <Typography
          sx={{
            display: "inline-flex",
            alignItems: "center",
            gap: "6px",
            mt: 2,
          }}
        >
          <DangerIcon size={16} /> Note: This action cannot be undone.
        </Typography>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "13px",
          padding: "0 42px 31px",
          "&>:not(:first-of-type)": {
            marginLeft: 0,
          },
        }}
      >
        <StyledButton
          sx={{ width: "100%", height: "34px" }}
          variant="contained"
          label="Proceed with Question Deletion"
          onClick={() => handleSubmit({ deleteResponses: false })}
          fontSize="15px"
        />

        <StyledButton
          sx={{ width: "100%", height: "34px" }}
          variant="contained"
          label="Delete Question and Responses"
          onClick={() => handleSubmit({ deleteResponses: true })}
          fontSize="15px"
          color="confirmDelete"
        />

        <StyledButton
          sx={{ width: "100%", height: "34px" }}
          variant="outlined"
          label="Cancel"
          color="cancel"
          fontSize="15px"
          onClick={handleClose}
        />
      </DialogActions>
    </Dialog>
  );
};

QuestionDeleteDialog.propTypes = {
  open: bool,
  handleClose: func,
  handleSubmit: func,
};
QuestionDeleteDialog.defaultProps = {
  open: false,
  handleClose: () => {},
  handleSubmit: () => {},
};
