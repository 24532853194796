import { func, bool, string } from "prop-types";
import { Box, Typography } from "@mui/material";
import { StyledButton, StyledTooltip } from "components";

export const RepsAssignedDrawerActions = ({
  onClose,
  onSave,
  disabled,
  hasCheckedRep,
  onDeleteAllReps,
  submitBtnLabel,
  onDeleteAllDisabled,
}) => {
  const tooltipOptions = {
    disableHoverListener: !disabled,
    title: "Select at least one representative to proceed",
    arrow: true,
    placement: "top",
    PopperProps: {
      modifiers: [{ name: "offset", options: { offset: [0, -10] } }],
    },
  };

  return (
    <Box
      sx={{
        borderTop: "0.5px solid #D5D9D9",
        background: "#F8F8F8",
        padding: "24px 32px 24px 33px",
        px: "40px",
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Box flexGrow={1}>
        {!!onDeleteAllReps && hasCheckedRep && (
          <StyledButton
            variant="outlined"
            onClick={onDeleteAllReps}
            label="Unassign All Reps"
            disabled={onDeleteAllDisabled}
            color="confirmDelete"
            fontSize="15px"
            sx={{
              height: "32px",
              boxShadow: "none",
            }}
          />
        )}
      </Box>

      <Box>
        <StyledButton
          sx={{ height: 32, borderColor: "#D5D9D9" }}
          onClick={onClose}
          label={
            <Typography fontSize={15} color="#5f6267">
              Cancel
            </Typography>
          }
          variant="text"
        />
        <StyledTooltip {...tooltipOptions}>
          <Box component="span">
            <StyledButton
              disabled={disabled}
              variant="contained"
              onClick={onSave}
              label={submitBtnLabel}
              fontSize="15px"
              sx={{
                height: "32px",
                width: "78px",
                boxShadow: "none",
                ml: "10px",
              }}
            />
          </Box>
        </StyledTooltip>
      </Box>
    </Box>
  );
};

RepsAssignedDrawerActions.propTypes = {
  onClose: func,
  onSave: func,
  disabled: bool,
  hasCheckedRep: bool,
  onDeleteAllDisabled: bool,
  onDeleteAllReps: func,
  submitBtnLabel: string,
};
RepsAssignedDrawerActions.defaultProps = {
  onClose: () => {},
  onSave: () => {},
  disabled: false,
  onDeleteAllDisabled: false,
  submitBtnLabel: "Add",
};
