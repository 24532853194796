import { useEffect, useMemo, useState } from "react";
import { array, bool, func, number, object } from "prop-types";
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import {
  InfiniteScrollWrapper,
  LargeCheckbox,
  StyledMenu,
  StyledTextField,
} from "components";
import { CrossIcon, SearchIcon } from "components/Icons";
import { useDebounce } from "helpers/hooks";
import { cl, useStyles } from "../../AddFormsComponent.styles";

export const SelectFormsBlock = ({
  anchorMenuEl = null,
  handleCloseAddForms = () => {},
  formsList = [],
  handleSetCheckedForms = () => {},
  checkedForms = [],
  formsFilter = {},
  setFormsFilter = () => {},
  loadingFormsList = false,
  countForms = 0,
  checkedItemsLimit = 0,
}) => {
  const classes = useStyles();
  const { mItem, wrapper, checkBox, noFoundText } = cl.SelectFormsBlock;
  const openFormsList = Boolean(anchorMenuEl);

  const [searchInput, setSearchInput] = useState("");

  const debouncedSearch = useDebounce(searchInput, 500);

  useEffect(() => {
    setFormsFilter((prev) => ({
      ...prev,
      page: 1,
      search: debouncedSearch,
    }));
  }, [debouncedSearch, setFormsFilter]);

  const menuPosition = useMemo(() => {
    if (!anchorMenuEl) return { vertical: "bottom", horizontal: "left" };

    const buttonRect = anchorMenuEl.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    if (buttonRect.bottom + 322 <= windowHeight) {
      return { vertical: "top", horizontal: "left" };
    }

    return { vertical: "bottom", horizontal: "left" };
  }, [anchorMenuEl]);

  return (
    <StyledMenu
      sx={{ py: "8px", width: "305px" }}
      anchorEl={anchorMenuEl}
      isOpen={openFormsList}
      handleClose={handleCloseAddForms}
      menuProps={{
        anchorOrigin: {
          vertical: menuPosition.vertical === "bottom" ? "top" : "bottom",
          horizontal: "left",
        },
        transformOrigin: { ...menuPosition },
      }}
    >
      <Box width="100%" px="13px">
        <StyledTextField
          fullWidth
          size="small"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value.trim())}
          placeholder="Search forms"
          onClick={(e) => e.stopPropagation()}
          InputProps={{
            className: classes.textInputSearch,
            endAdornment: (
              <>
                <InputAdornment
                  sx={{ position: "absolute", left: 9 }}
                  position="start"
                >
                  <SearchIcon />
                </InputAdornment>
                {formsFilter?.search && (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ "& svg": { marginRight: "0px !important" } }}
                      onClick={() => setSearchInput("")}
                    >
                      <CrossIcon size="15" />
                    </IconButton>
                  </InputAdornment>
                )}
              </>
            ),
          }}
        />
      </Box>

      {formsList?.length === 0 && (
        <MenuItem disableRipple sx={mItem} value="">
          <Typography sx={noFoundText}>No forms found</Typography>
        </MenuItem>
      )}

      <Stack sx={wrapper}>
        <InfiniteScrollWrapper
          maxHeight="205px"
          dataLength={formsList?.length}
          loading={loadingFormsList}
          nextFunc={() => {
            setFormsFilter((prev) => ({ ...prev, page: prev?.page + 1 }));
          }}
          hasMoreItems={formsList?.length < countForms}
          style={{ overflowX: "hidden" }}
          id="forms-drawer-scroll-table"
          loader={
            <CircularProgress sx={{ ml: "50%", mt: "5px" }} size="30px" />
          }
        >
          {formsList?.map((item) => {
            const checked = checkedForms.some((f) => f?.id === item?.id);

            return (
              <MenuItem
                key={item.id}
                value={item}
                disabled={!checked && checkedForms?.length >= checkedItemsLimit}
                onClick={() => handleSetCheckedForms(item)}
              >
                <LargeCheckbox
                  checked={checked}
                  size={22}
                  sx={checkBox}
                  formSx={{ mr: "5px", ml: 0 }}
                />
                <Typography
                  fontSize={14}
                  fontWeight={400}
                  color="#5F6267"
                  noWrap
                >
                  {item?.title}
                </Typography>
              </MenuItem>
            );
          })}
        </InfiniteScrollWrapper>
      </Stack>
    </StyledMenu>
  );
};

SelectFormsBlock.propTypes = {
  anchorMenuEl: object,
  handleCloseAddForms: func,
  formsList: array,
  handleSetCheckedForms: func,
  checkedForms: array,
  formsFilter: object,
  setFormsFilter: func,
  loadingFormsList: bool,
  countForms: number,
  checkedItemsLimit: number,
};
