import { Grid, IconButton, Paper, Typography, Box } from "@mui/material";
import { object, func, bool } from "prop-types";
import { PenIcon, TrashIcon } from "components/Icons";
import { StyledLabel } from "components";
import { useAdmin, setPhoneNumberMask } from "helpers/helpers";

const CustomerContactItem = ({
  contact,
  handleEditContact,
  handleDeleteContact,
  IsLastItem,
  viewOnly,
}) => {
  const isAdmin = useAdmin();
  const classes = {
    grid: {
      textAlign: "center",
    },
    typography: {
      fontSize: "12px",
      fontWeight: "500",
      color: "#6A6A6A",
      textTransform: "uppercase",
    },
  };

  return (
    <Paper
      sx={{
        minHeight: "51px",
        display: "flex",
        alignItems: "center",
        borderWidth: "0px 1px 1px 1px",
        borderStyle: "solid",
        borderColor: "#D5D9D9",
        borderRadius: IsLastItem ? "0 0 4px 4px" : 0,
      }}
      elevation={0}
      square
      component={Grid}
      container
      columns={13}
    >
      <Grid item xs={0.25} />
      <Grid item xs={3.75} sx={{ textAlign: "left" }}>
        <Grid display="flex" textAlign="center" alignItems="center">
          <Box fontSize="14px" display="flex" component={Typography} noWrap>
            {contact.name}
            {contact.defaultContact && (
              <>
                <Box width="6px" />
                <StyledLabel style={{ position: "relative" }} />
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={2.35} sx={{ ...classes.grid, textAlign: "left" }}>
        <Typography fontSize={12} color="#3F3F3F" noWrap>
          {contact.role}
        </Typography>
      </Grid>
      <Grid item xs={2.45} sx={{ textAlign: "left" }}>
        <Typography
          sx={classes.typography}
          variant="caption"
          color="ground.main"
        >
          {setPhoneNumberMask(contact.phone)}
        </Typography>
      </Grid>
      <Grid item xs={viewOnly ? 4.2 : 3.1} sx={{ textAlign: "left" }}>
        <Typography color="primary" fontSize={12} noWrap>
          {contact.email || "-"}
        </Typography>
      </Grid>

      {!viewOnly ? (
        <Grid
          item
          xs={1.1}
          sx={{
            display: "flex",
            textAlign: "right",
          }}
        >
          <IconButton
            disabled={isAdmin}
            onClick={() => handleEditContact(contact)}
          >
            <PenIcon />
          </IconButton>
          <IconButton
            disabled={isAdmin}
            onClick={() => handleDeleteContact(contact, true)}
          >
            <TrashIcon />
          </IconButton>
        </Grid>
      ) : (
        ""
      )}
    </Paper>
  );
};

CustomerContactItem.propTypes = {
  contact: object,
  handleDeleteContact: func,
  handleEditContact: func,
  IsLastItem: bool,
  viewOnly: bool,
};

CustomerContactItem.defaultProps = {
  contact: {},
  IsLastItem: false,
  viewOnly: false,
};

export default CustomerContactItem;
