import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useState, useEffect, useMemo, useCallback } from "react";
import { bool, func, array, string } from "prop-types";
import { CrossBigIcon } from "../../../../components/Icons";
import StyledButton from "../../../../components/StyledButton";
import { containsEvery, useAdmin } from "../../../../helpers/helpers";
import { useDebounce } from "../../../../helpers/hooks";
import { getCustomersService } from "../../../../services/customers";
import {
  CUSTOMER_STATUS_FILTERS,
  UNCATEGORIZED_GROUP,
  UNCATEGORIZED_TERRITORY,
} from "../../../../utils/constants";
import { CustomersTabFilter } from "../../../RepresentativesPage/components/ProfileComponent/TabsContent/TabCustomersComponent/CustomersTabFilter";
import CustomersTableComponent from "../../../RepresentativesPage/components/ProfileComponent/TabsContent/TabCustomersComponent/CustomersTableComponent/CustomersTableComponent";
import lodash, { snakeCase } from "lodash";

const SORT_FIELDS = [
  "sort_display_name",
  "sort_territory_name",
  "sort_parent_customer_name",
];

const AllCustomersPopup = ({
  open,
  onClose,
  handleAddCustomers,
  addedStops,
  viewCustomerPopup,
  hideActive,
  hideOrders,
  allowMissingInfo,
  hideStatus = false,
  priceListId = null,
  isEdit = false,
}) => {
  const isAdmin = useAdmin();
  const defaultQuery = useMemo(
    () => ({
      territory_id: "",
      representative_id: "",
      search: "",
      sort_display_name: "",
      sort_territory_name: "",
      sort_parent_customer_name: "",
      status: CUSTOMER_STATUS_FILTERS.active,
    }),
    []
  );

  const [query, setQuery] = useState(defaultQuery);

  const searchDebounced = useDebounce(query.search, 500);

  const [page, setPage] = useState(1);

  //   const [customersList, setCustomersList] = useState([]);
  const [customersState, setCustomersState] = useState({
    list: [],
    checked: [],
    count: 0,
    existData: false,
    loading: true,
  });

  //   const [checkedCustomers, setCheckedCustomers] = useState([]);

  useEffect(() => {
    if (open)
      setCustomersState((prev) => ({
        ...prev,
        checked: addedStops,
        ...(isEdit ? { list: addedStops, count: addedStops.length } : {}),
      }));

    return () => {
      setQuery(defaultQuery);
    };
  }, [open, addedStops, defaultQuery, isEdit]);

  useEffect(() => {
    setPage(1);
    setCustomersState((prev) => ({ ...prev, loading: true }));
    getCustomersService({
      ...query,
      search: searchDebounced,
      limit: 10,
      page: 1,
      status: `["${query.status}"${
        viewCustomerPopup ? `, "${CUSTOMER_STATUS_FILTERS.prospect}"` : ""
      }]`,
      ...(isEdit && addedStops.length
        ? { customer_ids: JSON.stringify(addedStops.map((c) => c.id)) }
        : {}),
    })
      .then((res) =>
        setCustomersState((prev) => ({
          ...prev,
          list: [...res.rows],
          existData: res.existData,
          count: res.count,
          loading: false,
        }))
      )
      .finally(() => {
        setCustomersState((prev) => ({ ...prev, loading: false }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    query.territory_id,
    query.group_id,
    query.representative_id,
    query.parent_customer_id,
    query.tag_id,
    query.sort_name,
    query.sort_orders,
    query.sort_newest,
    searchDebounced,
    query.status,
    query.last_visit,
    query.last_visit_than,
    query.last_visit_start_date,
    query.last_visit_end_date,
    query.last_order,
    query.last_order_end_date,
    query.last_order_start_date,
    query.last_order_than,
    query.with_missing_info,
    query.with_duplicated_info,
    query.order_direct,
    query.tasks_due,
    query.tasks_due_start_date,
    query.tasks_due_end_date,
    query.has_assigned_routes,
    query.sort_display_name,
    query.sort_territory_name,
    query.sort_parent_customer_name,
    isEdit,
  ]);

  const setHasAssignedRoutesValue = (value) => {
    if (value === "ASSIGNED") return true;
    if (value === "NOT_ASSIGNED") return false;
    return undefined;
  };

  const handleCustomersFilter = (e) => {
    const val = e?.target?.value ?? e;
    const sort = val.sort_by ? val.sort_by.value.split(" ") : null;

    const { last_visit, last_order, issues, tasks_due, has_assigned_routes } =
      val;

    let removeSort = null;
    if (sort?.[0] === "sort_name") removeSort = "sort_orders";
    else removeSort = "sort_name";

    const q = {
      parent_customer_id:
        val?.group_id?.value === UNCATEGORIZED_GROUP.id
          ? "null"
          : val?.group_id?.value,
      territory_id:
        val?.territory_id?.value === UNCATEGORIZED_TERRITORY.id
          ? "null"
          : val?.territory_id?.value,
      representative_id: val?.representative_id?.value,
      tag_id: val?.tag_id?.value,
      [removeSort]: null,
      [sort?.[0]]: sort?.[1],
      last_visit: last_visit?.value?.start_date
        ? undefined
        : lodash.snakeCase(last_visit?.value),
      last_visit_than: ["more_than", "less_than"].includes(last_visit?.value)
        ? last_visit.days || 1
        : undefined,
      last_visit_start_date: last_visit?.value?.start_date,
      last_visit_end_date: last_visit?.value?.end_date,

      last_order: last_order?.value?.start_date
        ? undefined
        : snakeCase(last_order?.value),
      last_order_than: ["more_than", "less_than"].includes(last_order?.value)
        ? last_order.days || 0
        : undefined,
      last_order_start_date: last_order?.value?.start_date,
      last_order_end_date: last_order?.value?.end_date,
      with_missing_info: issues?.value === "with_missing_info" || undefined,
      with_duplicated_info:
        issues?.value === "with_duplicated_info" || undefined,
      order_direct: !val?.order_direct
        ? null
        : JSON.stringify([val?.order_direct.value]),
      tasks_due: tasks_due?.value?.start_date
        ? undefined
        : snakeCase(tasks_due?.value),
      tasks_due_start_date: tasks_due?.value?.start_date,
      tasks_due_end_date: tasks_due?.value?.end_date,
      has_assigned_routes: setHasAssignedRoutesValue(has_assigned_routes),
    };

    setQuery((prev) => ({ ...prev, ...q }));
  };

  const handleFetchCustomers = () => {
    setCustomersState((prev) => ({ ...prev, loading: true }));
    setPage((prev) => prev + 1);
    getCustomersService({
      ...query,
      search: searchDebounced,
      limit: 10,
      page: page + 1,
      status: `["${query.status}"${
        viewCustomerPopup ? `, "${CUSTOMER_STATUS_FILTERS.prospect}"` : ""
      }]`,
    })
      .then((res) =>
        setCustomersState((prev) => ({
          ...prev,
          list: [...prev.list, ...res.rows],
          existData: res.existData,
          loading: false,
          count: res.count,
        }))
      )
      .finally(() => {
        setCustomersState((prev) => ({ ...prev, loading: false }));
      });
  };

  const handleCheckCustomer = (customer, checked) => {
    if (checked)
      return setCustomersState((prev) => ({
        ...prev,
        checked: prev.checked.filter((item) => item.id !== customer.id),
      }));

    setCustomersState((prev) => ({
      ...prev,
      checked: [...prev.checked, customer],
    }));
  };

  const handleCheckOneCustomer = (customer) => {
    setCustomersState((prev) => ({
      ...prev,
      checked: [customer],
    }));
  };

  const checkAllCustomers = (checked) => {
    if (checked) return setCustomersState((prev) => ({ ...prev, checked: [] }));
    if (priceListId) {
      const filteredList = customersState?.list?.filter(
        (customer) =>
          !customer?.priceList || customer?.priceList?.id === priceListId
      );
      return setCustomersState((prev) => ({ ...prev, checked: filteredList }));
    }
    return setCustomersState((prev) => ({ ...prev, checked: [...prev.list] }));
  };

  const fetchListAndCheckAll = () => {
    if (customersState.checked.length === customersState.count)
      return checkAllCustomers(true);

    setCustomersState((prev) => ({ ...prev, loading: true }));
    getCustomersService({
      ...query,
      status: `["${query.status}"${
        viewCustomerPopup ? `, "${CUSTOMER_STATUS_FILTERS.prospect}"` : ""
      }]`,
      search: searchDebounced,
    })
      .then((res) => {
        if (priceListId) {
          const filteredList = res.rows?.filter(
            (customer) =>
              !customer?.priceList || customer?.priceList?.id === priceListId
          );

          return setCustomersState((prev) => ({
            ...prev,
            checked: filteredList,
          }));
        }
        return setCustomersState((prev) => ({
          ...prev,
          checked: [...res.rows],
        }));
      })
      .finally(() => {
        setCustomersState((prev) => ({ ...prev, loading: false }));
      });
  };

  const handleSetSort = useCallback(
    (sortField) => {
      const sortFieldToDefault = Object.keys(query).filter(
        (item) => SORT_FIELDS.includes(item) && item !== sortField
      );

      setQuery({
        ...query,
        [sortField]: query?.[sortField] !== "desc" ? "desc" : "asc",
        ...sortFieldToDefault.reduce(
          (acc, field) => ({ ...acc, [field]: "" }),
          {}
        ),
      });
    },
    [query]
  );

  const quickSort = useMemo(() => {
    return {
      sort_display_name: query?.sort_display_name,
      sort_territory_name: query?.sort_territory_name,
      sort_parent_customer_name: query?.sort_parent_customer_name,
    };
  }, [
    query?.sort_display_name,
    query?.sort_territory_name,
    query?.sort_parent_customer_name,
  ]);

  return (
    <Dialog
      {...{ open }}
      PaperProps={{
        sx: {
          maxWidth: "981px",
          height: "510px",
          border: "0.5px #D5D9D9 solid",
          borderRadius: "8px",
          overflow: "hidden",
        },
      }}
    >
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: "24px 32px 24px 24px" }}
      >
        <Typography fontSize="30px" color="#707070">
          All customers
        </Typography>
        <IconButton
          onClick={onClose}
          disableRipple
          sx={{ width: "24px", height: "24px", p: 0 }}
        >
          <CrossBigIcon size={22} strokeWidth={2} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: "980px", p: "0", height: "fit-content" }}>
        <CustomersTabFilter
          isEdit={isEdit}
          hideActive={hideActive}
          viewCustomerPopup={viewCustomerPopup}
          isRoute
          {...{ handleCustomersFilter }}
          customersSearchInput={query.search}
          queryStatus={query.status}
          setStatus={(status) => setQuery((prev) => ({ ...prev, status }))}
          setCustomersSearchInput={(search) =>
            setQuery((prev) => ({ ...prev, search }))
          }
          loading={customersState.loading && customersState.existData}
        />
        <CustomersTableComponent
          maxHeight="239px"
          {...{
            handleFetchCustomers,
            handleCheckCustomer,
            checkAllCustomers,
            fetchListAndCheckAll,
            handleCheckOneCustomer,
            allowMissingInfo,
            hideStatus,
          }}
          priceListId={priceListId}
          viewCustomerPopup={viewCustomerPopup}
          customersCount={customersState.count}
          customersList={customersState.list}
          checkedCustomers={customersState.checked}
          allCustomersChecked={containsEvery(
            customersState.list,
            customersState.checked
          )}
          loading={customersState.loading}
          existData={customersState.existData}
          {...{ quickSort, handleSetSort, hideOrders }}
        />
        {!viewCustomerPopup && (
          <Typography fontSize="12px" color="#6A6A69" m="8px 0 0 24px">
            Showing {customersState.list.length} customers
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ p: "0px 24px 24px" }}>
        {/*{viewCustomerPopup && (*/}
        <StyledButton
          sx={{ height: "28px", minWidth: "58px" }}
          label="Cancel"
          fontSize="12px"
          color="cancel"
          onClick={onClose}
        />
        {/*)}*/}
        <StyledButton
          disabled={isAdmin}
          variant="contained"
          label="Add"
          fontSize="12px"
          sx={{ height: "28px", minWidth: "58px" }}
          onClick={() =>
            handleAddCustomers(
              viewCustomerPopup
                ? customersState.checked[0]
                : customersState.checked
            )
          }
        />
      </DialogActions>
    </Dialog>
  );
};

AllCustomersPopup.propTypes = {
  open: bool,
  hideStatus: bool,
  onClose: func,
  handleAddCustomers: func,
  addedStops: array,
  viewCustomerPopup: bool,
  hideActive: bool,
  hideOrders: bool,
  allowMissingInfo: bool,
  isEdit: bool,
  priceListId: string,
};

AllCustomersPopup.defaultProps = {
  open: false,
  hideStatus: false,
  onClose: () => {},
  addedStops: [],
  hideActive: false,
  priceListId: null,
};

export default AllCustomersPopup;
