import { useCallback, useContext } from "react";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";

import {
  TabsBlock,
  FormDetails,
  QuestionsList,
  ResponsesDetails,
  VisibilitySettings,
  AddNewQuestionBlock,
} from "./components";

import { Stack } from "@mui/material";

export const ContentBlock = () => {
  const { handleSubmitForm, currentTab } = useContext(FormViewContext);

  const currentTabView = useCallback((tab) => {
    switch (tab) {
      case 0:
        return (
          <>
            <FormDetails />
            <QuestionsList />
          </>
        );
      case 1:
        return <ResponsesDetails />;
      case 2:
        return <VisibilitySettings />;
      default:
        return <></>;
    }
  }, []);

  return (
    <Stack
      width="100%"
      maxWidth="594px"
      gap="16px"
      mx="auto"
      component="form"
      id="new-form-view-form"
      onSubmit={handleSubmitForm}
    >
      <Stack width="100%" gap="30px">
        <TabsBlock />
        {currentTabView(currentTab)}
      </Stack>

      {currentTab === 0 && <AddNewQuestionBlock />}
    </Stack>
  );
};
