import { string } from "prop-types";

import PriceList from "./components/PriceList";
import PriceListFilter from "./components/PriceListFilter";

import { Box } from "@mui/material";
import { CreatePriceListDialog } from "components/CreatePriceListDialog/CreatePriceListDialog";
import { useContext } from "react";
import { PriceContext } from "..";

export const PriceListTab = ({ currentTab }) => {
  const {
    openCreatePriceDialog,
    setOpenCreatePriceDialog,
    handleSavePriceList,
    repPermissions,
  } = useContext(PriceContext);

  return (
    <Box px="32px" height="100%">
      <CreatePriceListDialog
        open={openCreatePriceDialog}
        onClose={() => setOpenCreatePriceDialog(false)}
        handleSave={handleSavePriceList}
      />

      <PriceListFilter {...{ currentTab }} />
      <PriceList {...{ currentTab, repPermissions }} />
    </Box>
  );
};

PriceListTab.propTypes = {
  currentTab: string,
};
export default PriceListTab;
