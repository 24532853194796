import { useMemo, useState } from "react";
import { bool, func, object, string } from "prop-types";
import { Grid, Stack, Typography } from "@mui/material";
import { CustomerBlock, SelectBlock } from "./components";
import { FormsIcon, StatusIcon } from "components/Icons";
import { StyledAvatar } from "components";
import { photoUrl } from "helpers/helpers";
import { useDaysAgo } from "helpers/hooks";
import { getPriority } from "Pages/CustomersPage/pages/CustomerProfilePage/components/TaskDrawer/TaskDrawer.hooks";
import { cl } from "./TasksItem.styles";
import { useNavigate } from "react-router-dom";
import { ArrowForwardIos } from "@mui/icons-material";

export const TasksItem = ({
  task,
  isChecked,
  showBorder,
  handleCheckTask,
  timeZone,
  handleEditTask,
  repPermissions,
}) => {
  const navigate = useNavigate();

  const {
    status: taskStatus,
    title: taskTitle,
    assignedRepresentatives,
    assignedDistributor,
    customer,
    dueDate,
    priority: priorityType,
    assignedForms,
  } = task || {};

  const assignedRepresentative = assignedRepresentatives?.length
    ? assignedRepresentatives?.[0]?.representative
    : { name: "", profilePhoto: null };

  const [hoverColor, setHoverColor] = useState("#FFF");

  const preparedDueDate = useDaysAgo({
    date: dueDate,
    timeZone,
  });

  const connectedForms = useMemo(() => {
    return assignedForms?.length
      ? assignedForms.map(({ form }) => form?.title).join(", ")
      : null;
  }, [assignedForms]);

  const assignedTo = useMemo(() => {
    const state = {
      name: "",
      fileName: null,
    };

    if (assignedRepresentatives?.length) {
      state.name = assignedRepresentative?.name;
      state.fileName = assignedRepresentative?.profilePhoto?.fileName;
    }
    if (assignedDistributor) {
      state.name = assignedDistributor?.name;
      state.fileName = assignedDistributor?.profilePhoto?.fileName;
    }

    return state;
  }, [
    assignedDistributor,
    assignedRepresentative?.name,
    assignedRepresentative?.profilePhoto?.fileName,
    assignedRepresentatives?.length,
  ]);

  return (
    <Grid
      sx={{
        ...cl.mainGrid,
        backgroundColor: isChecked ? "#ECF5F0" : hoverColor,
        borderBottom: !showBorder && "1px solid #D5D9D9",
      }}
      container
      onMouseMove={() => setHoverColor("#F7F7F7")}
      onMouseLeave={() => setHoverColor("#FFF")}
    >
      <Grid pl={1.6} xs={0.5} data-testid="task-checkbox" item>
        <SelectBlock
          checked={isChecked}
          handleCheckOrder={() => handleCheckTask(task)}
        />
      </Grid>

      <Grid xs={0.5} data-testid="task-status" item>
        <StatusIcon status={taskStatus} />
      </Grid>

      <Grid pr={1} xs={3} data-testid="task-title" item>
        <Typography
          noWrap
          sx={{
            ...cl.typography,
            cursor: handleEditTask ? "pointer" : "default",
            ":hover": {
              textDecoration: handleEditTask ? "underline" : "none",
            },
          }}
          onClick={() => !!handleEditTask && handleEditTask(task)}
        >
          {taskTitle}
        </Typography>
      </Grid>

      <Grid xs={1.15} data-testid="task-assigned-to" item>
        {assignedTo.name ? (
          <StyledAvatar
            name={assignedTo.name}
            sx={{ width: 23.5, height: 23.5 }}
            stringStyle={{ fontSize: 12 }}
            src={photoUrl(assignedTo?.fileName)}
          />
        ) : (
          "-"
        )}
      </Grid>

      <Grid xs={2.2} data-testid="task-customer" item>
        <CustomerBlock
          customer={customer}
          handleOpenCustomerProfile={
            (repPermissions && !repPermissions?.customers?.view) ||
            !customer?.id
              ? null
              : () => navigate(`/customers/${customer.id}`)
          }
        />
      </Grid>

      <Grid xs={1} data-testid="task-due-date" item>
        <Typography sx={cl.typography}>{preparedDueDate}</Typography>
      </Grid>

      <Grid xs={1} data-testid="task-priority" item>
        {getPriority({
          type: priorityType,
          sx: {
            width: "52px",
            height: "18px",
            borderRadius: "4px",
          },
        })}
      </Grid>

      <Grid xs={2.35} data-testid="task-connected-forms" item>
        {connectedForms ? (
          <Stack direction="row" alignItems="center" gap={1} pr={1}>
            <Stack width="fit-content">
              <FormsIcon />
            </Stack>
            <Typography sx={cl.typography} noWrap>
              {connectedForms}
            </Typography>
          </Stack>
        ) : (
          "-"
        )}
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: handleEditTask ? "pointer" : "default",
        }}
        xs={0.3}
        item
        onClick={() => !!handleEditTask && handleEditTask(task)}
        data-testid="task-edit-btn"
      >
        <ArrowForwardIos style={{ fill: "#5F6267", fontSize: "13px" }} />
      </Grid>
    </Grid>
  );
};

TasksItem.propTypes = {
  task: object,
  showBorder: bool,
  isChecked: bool,
  handleCheckTask: func,
  timeZone: string,
  handleEditTask: func,
  repPermissions: object,
};
TasksItem.defaultProps = {
  task: {},
  showBorder: false,
  isChecked: false,
  handleCheckTask: () => {},
  timeZone: "",
  handleEditTask: () => {},
};
