import { shape, bool, func, string, number, array } from "prop-types";

import { MenuColumn, SelectBlock, TitleBlock } from "./components";
import { ActiveIcon, CrossIcon } from "components/Icons";

import { Grid, Typography } from "@mui/material";

export const FormItem = ({
  task,
  isChecked,
  handleCheckForm,
  getActionList,
}) => {
  const {
    id: taskId,
    active,
    title,
    countCustomers,
    countResponses,
    description,
  } = task || {};

  const statusIcons = active ? (
    <ActiveIcon />
  ) : (
    <CrossIcon fill="#FF0000" size={22} />
  );

  return (
    <Grid
      sx={{
        backgroundColor: isChecked ? "#ECF5F0" : "#ffff",
        alignItems: "center",
        height: "48px",
        "&:hover": {
          backgroundColor: isChecked ? "#ECF5F0" : "#F7F7F7",
        },
      }}
      container
    >
      <Grid sx={{ pl: 1.8 }} xs={0.44} item>
        <SelectBlock
          checked={isChecked}
          handleCheckOrder={() => handleCheckForm(task)}
        />
      </Grid>

      <Grid
        xs={0.52}
        item
        container
        sx={{
          alignItems: "center",
          pl: active ? 1.5 : 1.2,
        }}
      >
        {statusIcons}
      </Grid>

      <Grid pr={1} xs={6.3} item>
        <TitleBlock title={title} text={description} id={taskId} />
      </Grid>

      <Grid xs={2.37} item pr={1}>
        <Typography fontSize={13} fontWeight={400} color="#1C1C19" noWrap>
          {countCustomers || "-"}
        </Typography>
      </Grid>

      <Grid xs={2.075} item>
        <Typography fontSize={13} fontWeight={400} color="#1C1C19" noWrap>
          {countResponses || "-"}
        </Typography>
      </Grid>
      <Grid xs={0.28} item pr={1}>
        <span>
          <MenuColumn
            getActionList={getActionList}
            adminIsAllowed
            actionItem={task}
          />
        </span>
      </Grid>
    </Grid>
  );
};

FormItem.propTypes = {
  task: shape({
    id: string,
    active: bool,
    title: string,
    countCustomers: number,
    countResponses: number,
    description: string,
  }),
  isChecked: bool,
  handleCheckForm: func,
  getActionList: func,
  MENU_ACTIONS: array,
};
FormItem.defaultProps = {
  task: {
    id: "",
    active: false,
    title: "",
    countCustomers: 0,
    countResponses: 0,
    description: "",
  },
  isChecked: false,
  MENU_ACTIONS: [],
  handleCheckForm: () => {},
  getActionList: () => {},
};
