import axios from "axios";

export const getFormsService = async (params, signal) => {
  return await axios.get("forms", { params, signal }).then((res) => res.data);
};

export const deleteFormsService = async (data) => {
  return await axios.delete("/forms", { data }).then((res) => res.data);
};

export const bulkFormsActivationService = async (data) => {
  return await axios
    .put("/forms/bulk-activation", data)
    .then((res) => res.data);
};

export const getFormByIdService = async (id) => {
  return await axios.get(`forms/${id}`).then((res) => res.data);
};

export const createFormService = async (data) => {
  return await axios.post("forms", data).then((res) => res.data);
};

export const updateFormByIdService = async (id, data) => {
  return await axios.put(`forms/${id}`, data).then((res) => res.data);
};

export const getFormCustomersService = async (params) => {
  return await axios
    .get("forms/assigned-customers", { params })
    .then((res) => res.data);
};

export const getFormRepsService = async (params) => {
  return await axios
    .get("forms/assigned-representatives", { params })
    .then((res) => res.data);
};

export const getFormsWithResponsesService = async ({ id, params }) => {
  return await axios
    .get(`forms/${id}/with-responses`, { params })
    .then((res) => res.data);
};
