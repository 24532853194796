import React from "react";

export const CheckTrialIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.91"
      height="22.91"
      viewBox="0 0 22.91 22.91"
    >
      <g
        id="Ellipse_1094"
        data-name="Ellipse 1094"
        fill="none"
        stroke="#47a06d"
        strokeWidth="1"
      >
        <ellipse
          cx="11.455"
          cy="11.455"
          rx="11.455"
          ry="11.455"
          stroke="none"
        />
        <ellipse cx="11.455" cy="11.455" rx="10.955" ry="10.955" fill="none" />
      </g>
      <path
        id="Path_5359"
        data-name="Path 5359"
        d="M19211.277-4361.839l2.576,2.578,3.809-3.809,3.832-3.831"
        transform="translate(-19204.523 4374.537)"
        fill="none"
        stroke="#47a06d"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </svg>
  );
};
