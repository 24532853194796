import React, { createContext, useEffect } from "react";
import {
  CustomerProfile,
  CustomerProfileHeader,
  NoteDrawer,
  TaskDrawer,
} from "./components";
import { Box, CircularProgress } from "@mui/material";
import useStyles from "./styles";
import { useCustomerProfile, usePhotoActions } from "./CustomerProfile.hooks";
import { OrdersActions } from "Pages/useOrdersActions";
import {
  ConfirmDeliveryBulkDialog,
  NoteDrawer as GlobalNoteDrawer,
  PrintPickOrdersDialog,
  ReturnDialog,
  TagsDrawer,
} from "components";
import ViewOrderEmailDialog from "Pages/OrdersPage/components/ViewOrderEmailDialog/ViewOrderEmailDialog";
import ConfirmDeliveryPopup from "Pages/OrdersPage/OrderDetailsPage/OrdersTopPaymentBar/components/OrdersDelivery/ConfirmDeliveryPopup/ConfirmDeliveryPopup";
import AcceptPaymentPopup from "Pages/OrdersPage/OrderDetailsPage/OrdersTopPaymentBar/AcceptPaymentPopup";
import CancelDialog from "Pages/OrdersPage/components/CancelDialog/CancelDialog";
import RefundDialog from "Pages/OrdersPage/components/RefundDialog/RefundDialog";
import { TAG_TYPES } from "helpers/useTagsActions";
import { addOnlySharedTags } from "helpers/helpers";

export const CustomerProfileContext = createContext();

const CustomerProfilePage = () => {
  const classes = useStyles();
  const photoGroupPopupState = usePhotoActions();

  const {
    setOpenRefundDialog,
    setNameRefundDialog,
    setOpenReturnDialog,
    setOpenCancelDialog,
    openCancelDialog,
    openRefundDialog,
    nameRefundDialog,
    openReturnDialog,
    handleChoseMenuItem,
    type,
    handleOpenEmail,
    emailOpen,
    setEmailOpen,
    isLoading,
    setType,
    order,
    setOrder,
    handleSendEmail,
    loadingSendEmail,
    isAcceptPaymentPopupOpen,
    setIsAcceptPaymentPopupOpen,
    isConfirmDeliveryPopupOpen,
    setIsConfirmDeliveryPopupOpen,
    confirmDeliveryState,
    setConfirmDeliveryState,
    handleCloseConfirmDeliveryDialog,
    handleBulkConfirmDelivery,
    assignTagsState,
    setAssignTagsState,
    handleCloseAssignTagsDialog,
  } = OrdersActions();

  const {
    handleFetchCustomer,
    customerState,
    currentTable,
    handleChangeAddressTab,
    customerId,
    updateCustomerState,
    handleCheckAllTableItems,
    handleDataSearch,
    filterSearch,
    filters,
    handleChangeTab,
    noteState,
    globalNoteState,
    handleOpenGlobalNote,
    handleCloseCustomerNotes,
    taskState,
    handleCloseTask,
    handleRefetchTasks,
    handleCloseNote,
    handleRefetchNotes,
    handleOpenNote,
    handleOpenTask,
    mapData,
    setMapData,
    handleFetchActivities,
    setContactsOpen,
    contactsOpen,
    isParent,
    resetCustomerState,
    handleOpenOrder,
    emptyCartPopupOpen,
    setEmptyCartPopupOpen,
    navigateToOrderCreate,
    repPermissions,
    printPickListDialog,
    setPrintPickListDialog,
    fetchPrintPickList,
    handleCloseAcceptDeliveryPopup,
    editDelivery,
    actionsSuccessCallback,
    isAdmin,
    handleSaveAssignTags,
    findCheckedItems,
    setTableQuery,
  } = useCustomerProfile({
    handleOpenEmail,
    handleChoseMenuItem,
    isLoading,
    setConfirmDeliveryState,
    handleCloseConfirmDeliveryDialog,
    setIsConfirmDeliveryPopupOpen,
    setType,
    assignTagsState,
    setAssignTagsState,
    handleCloseAssignTagsDialog,
  });

  useEffect(() => {
    handleFetchCustomer();
  }, [handleFetchCustomer]);

  return (
    <CustomerProfileContext.Provider
      value={{
        customerState,
        currentTable,
        handleChangeAddressTab,
        customerId,
        updateCustomerState,
        handleFetchCustomer,
        handleCheckAllTableItems,
        handleDataSearch,
        filterSearch,
        filters,
        noteState,
        globalNoteState,
        handleOpenGlobalNote,
        handleCloseCustomerNotes,
        handleChangeTab,
        handleCloseNote,
        handleRefetchNotes,
        taskState,
        handleCloseTask,
        setContactsOpen,
        contactsOpen,
        handleRefetchTasks,
        handleOpenNote,
        handleOpenTask,
        photoGroupPopupState,
        order,
        handleOpenEmail,
        emailOpen,
        setEmailOpen,
        handleSendEmail,
        loadingSendEmail,
        mapData,
        setMapData,
        handleFetchActivities,
        isParent,
        resetCustomerState,
        handleOpenOrder,
        emptyCartPopupOpen,
        setEmptyCartPopupOpen,
        navigateToOrderCreate,
        repPermissions,
        isAdmin,
        setTableQuery,
      }}
    >
      {customerState.customer ? (
        <Box className={classes.pageWrapper}>
          <CustomerProfileHeader />
          <CustomerProfile />
        </Box>
      ) : (
        <Box
          height="70vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={60} />
        </Box>
      )}

      <ViewOrderEmailDialog
        isOpen={emailOpen}
        handleClose={() => setEmailOpen(false)}
        order={order}
        handleSendEmail={handleSendEmail}
        loading={loadingSendEmail}
      />

      <ConfirmDeliveryPopup
        order={order}
        isOpen={isConfirmDeliveryPopupOpen}
        handleClose={handleCloseAcceptDeliveryPopup}
        editDelivery={editDelivery}
        type={type}
        isOrderPage={true}
        successCallback={actionsSuccessCallback}
      />

      <ConfirmDeliveryBulkDialog
        open={confirmDeliveryState.isOpenDialog}
        handleClose={handleCloseConfirmDeliveryDialog}
        orders={confirmDeliveryState.orders}
        isLoading={confirmDeliveryState.loading}
        handleConfirmDelivery={(preparedData) => {
          handleBulkConfirmDelivery({
            orderIds: confirmDeliveryState?.orders?.map(({ id }) => id),
            onSuccess: () => {
              handleCloseConfirmDeliveryDialog();
              actionsSuccessCallback();
            },
            data: preparedData,
          });
        }}
      />

      <AcceptPaymentPopup
        order={order}
        isOpen={isAcceptPaymentPopupOpen}
        handleClose={() => setIsAcceptPaymentPopupOpen(false)}
        isOrderPage={true}
        successCallback={actionsSuccessCallback}
      />

      <CancelDialog
        order={order}
        title="Cancel order"
        orderId={order?.id}
        customId={order?.customId?.customId}
        paymentStatus={order?.paymentStatus}
        deliveryStatus={order?.deliveryStatus}
        totalPayments={order?.totalPayments}
        open={openCancelDialog}
        onCancelSuccess={actionsSuccessCallback}
        onClose={() => setOpenCancelDialog(false)}
        isOrderPage={true}
      />

      <RefundDialog
        open={openRefundDialog}
        onClose={() => {
          setOpenRefundDialog(false);
          setNameRefundDialog("");
          setOrder({});
        }}
        nameRefundDialog={nameRefundDialog}
        order={order}
        isOrderPage={true}
        successCallback={actionsSuccessCallback}
      />

      <ReturnDialog
        open={openReturnDialog}
        onClose={() => {
          setOpenReturnDialog(false);
          setOrder({});
        }}
        order={order}
        successCallback={actionsSuccessCallback}
      />

      <PrintPickOrdersDialog
        open={printPickListDialog}
        handleClose={() => setPrintPickListDialog(false)}
        handlePrint={(isCheckedGroupOrders) => {
          fetchPrintPickList({
            isCheckedGroupOrders,
            checkedOrdersIds: customerState.tableData.checkedItems,
          });
          setPrintPickListDialog(false);
        }}
      />

      <NoteDrawer />

      <GlobalNoteDrawer
        open={globalNoteState.open}
        onClose={handleCloseCustomerNotes}
        data={globalNoteState.data}
        refetchCallback={() => handleFetchActivities({ page: 1 })}
        titleLabel="Notes"
      />
      <TaskDrawer />

      <TagsDrawer
        type={TAG_TYPES.order}
        open={assignTagsState.open}
        handleClose={handleCloseAssignTagsDialog}
        orderTags={
          assignTagsState.isBulk
            ? addOnlySharedTags([
                ...new Set([
                  ...findCheckedItems({
                    ids: assignTagsState?.orders,
                    customerState,
                  }),
                ]),
              ])
            : assignTagsState?.order?.tags?.map((tag) => tag?.tag)
        }
        handleSave={({ tags }) => {
          handleSaveAssignTags({ tags });
        }}
        isBulk={assignTagsState?.isBulk}
        setCheckedCustomers={() => {}}
      />
    </CustomerProfileContext.Provider>
  );
};

export default CustomerProfilePage;
