import { forwardRef } from "react";
import { object, bool, func, string } from "prop-types";
import { Grid, Box } from "@mui/material";
import useStyles, { cl } from "./styles";

import {
  AvailableBlock,
  CheckboxBlock,
  NameBlock,
  PhotoBlock,
  SkuBlock,
  SumBlock,
} from "./components";
import { PRODUCT_TYPE_INVENTORY } from "utils/constants";

const ProductsSearchItem = forwardRef(
  (
    {
      product,
      isChecked,
      handleCheckProduct,
      name,
      manufacturer,
      disabled,
      outOfStock,
      onMouseEnter,
      onMouseLeave,
      hideAvailable,
      planeView,
    },
    ref
  ) => {
    const classes = useStyles();
    const isMissingInfo = product?.missingFields;

    const isNonInventory =
      product?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

    return (
      <Grid
        container
        key={product.id}
        columns={12}
        height="45px"
        borderBottom="0.5px solid #D5D9D9"
        sx={{
          opacity: disabled || (!isNonInventory && outOfStock) ? 0.5 : 1,
          width: "100%",
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref}
      >
        {planeView ? (
          <Box width="100%" display="flex">
            <Box sx={cl.checkboxWrapper}>
              <CheckboxBlock
                {...{
                  disabled,
                  outOfStock,
                  isMissingInfo,
                  isChecked,
                  handleCheckProduct,
                  product,
                  manufacturer,
                  isNonInventory,
                }}
              />
            </Box>

            <Box sx={cl.photoWrapper}>
              <PhotoBlock {...{ product }} />
            </Box>

            <Grid maxWidth="calc(100% - 84px)" container>
              <Grid
                xs={7}
                item
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <NameBlock {...{ planeView, name, product }} />
              </Grid>

              <Grid display="flex" alignItems="center" xs={2.5} item>
                <AvailableBlock
                  {...{
                    isMissingInfo,
                    hideAvailable,
                    outOfStock,
                    product,
                    isNonInventory,
                  }}
                />
              </Grid>

              <Grid
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                pr={0.4}
                xs={2.5}
                item
              >
                <SumBlock
                  wholesalePrice={
                    product.priceWithPriceListDiscount ?? product.wholesalePrice
                  }
                  planeView
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <>
            <Grid item xs={1} className={classes.grid}>
              <CheckboxBlock
                {...{
                  disabled,
                  outOfStock,
                  isMissingInfo,
                  isChecked,
                  handleCheckProduct,
                  product,
                  manufacturer,
                }}
              />
            </Grid>

            <Grid item xs={1} className={classes.grid}>
              <PhotoBlock />
            </Grid>

            <Grid
              item
              xs={2.5}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              maxWidth="100%"
            >
              <NameBlock {...{ name }} />
            </Grid>

            <Grid
              item
              xs={3.5}
              alignItems="center"
              justifyContent="center"
              container
            >
              <SkuBlock {...{ product }} />
            </Grid>

            <Grid item xs={2.5} className={classes.grid}>
              <AvailableBlock
                {...{
                  isMissingInfo,
                  hideAvailable,
                  outOfStock,
                  product,
                }}
              />
            </Grid>

            <Grid item xs={1.5} className={classes.grid}>
              <SumBlock
                wholesalePrice={
                  product.priceWithPriceListDiscount ?? product.wholesalePrice
                }
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  }
);

ProductsSearchItem.propTypes = {
  product: object,
  handleCheckProduct: func,
  isChecked: bool,
  name: string,
  manufacturer: object,
  disabled: bool,
  outOfStock: bool,
  onMouseEnter: func,
  onMouseLeave: func,
  hideAvailable: bool,
  planeView: bool,
};

ProductsSearchItem.defaultProps = {
  product: null,
  isChecked: false,
  name: "",
  manufacturer: null,
  disabled: false,
  hideAvailable: false,
  planeView: false,
};
ProductsSearchItem.displayName = "ProductsSearchItem";

export default ProductsSearchItem;
