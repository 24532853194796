import { ProductPriceContext } from "Pages/ProductPricePage";
import React, { useContext } from "react";
import { EmptyScreen, TableHeaderControlPanel } from "components";

import { Box, CircularProgress, Stack } from "@mui/material";
import { CustomerFilterBlock, ListBlock, TableHeaderBlock } from "./components";
import { ADMIN_ONLY_VIEW_MESSAGE } from "utils/constants";

export const CustomersTabContent = () => {
  const {
    ACTIONS_LIST,
    customersList,
    onOpenAllCustomers,
    customersCount,
    selectedCustomers,
    customersLoading,
    onCancelSelection,
    onSelectAllCustomers,
    fetchAndCheckAllCustomers,
    customersSearchInputDebounced,
    repPermissions,
  } = useContext(ProductPriceContext);

  return (
    <Box>
      <CustomerFilterBlock />
      {customersLoading && !customersList.length ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          height="calc(100vh - 312px)"
        >
          <CircularProgress />
        </Stack>
      ) : customersList.length ? (
        <>
          <TableHeaderControlPanel
            actionWrapperProps={{
              sx: { padding: "0 17px 0 15.5px !important" },
            }}
            checkedCount={selectedCustomers.length}
            actionsList={ACTIONS_LIST}
            loading={customersLoading}
            onSelectAll={fetchAndCheckAllCustomers}
            hasCheckedItems={!!selectedCustomers.length}
            availableSelectCount={customersCount}
            selectName="Customer"
            onSelectVisible={onSelectAllCustomers}
            cancelSelection={onCancelSelection}
            headerComponent={<TableHeaderBlock />}
          />

          <ListBlock />
        </>
      ) : (
        <EmptyScreen
          type="customers"
          onConfirm={onOpenAllCustomers}
          height="calc(100vh - 312px)"
          showAction={!customersSearchInputDebounced}
          disabled={
            !!repPermissions &&
            !repPermissions?.catalog?.create_edit_price_lists
          }
          tooltipTitle={ADMIN_ONLY_VIEW_MESSAGE}
        />
      )}
    </Box>
  );
};
