import { GoogleAutocompleteField } from "components";
import { getObjectDifference, sliceCountry } from "helpers/helpers";
import { getAddressComponents } from "Pages/CustomersPage/components/AddressField/AddressField.helpers";
import React from "react";
import { string, object, func } from "prop-types";

const ImportAddressAutocomplete = ({
  api,
  value,
  updateChangesState,
  row,
  id,
  field,
  reverseMappedCols,
  type,
}) => {
  const addressKeyMap = {
    billing: {
      state: "customerBillingState",
      zip: "customerBillingZip",
      address: "customerBillingAddress",
      city: "customerBillingCity",
    },
    shipping: {
      state: "customerShippingState",
      zip: "customerShippingZip",
      address: "customerShippingAddress",
      city: "customerShippingCity",
    },
  };

  const onPlaceSelected = (place) => {
    const { state, city, zip } = getAddressComponents(place);
    const formattedAddress = sliceCountry(place.formatted_address);

    api?.setEditCellValue?.({
      id,
      field,
      value: formattedAddress,
    });
    api?.stopCellEditMode({ id, field });

    const stateKey = reverseMappedCols?.[addressKeyMap[type]?.state];
    const zipKey = reverseMappedCols?.[addressKeyMap[type]?.zip];
    const addressKey = reverseMappedCols?.[addressKeyMap[type]?.address];
    const cityKey = reverseMappedCols?.[addressKeyMap[type]?.city];
    const updatedRowFields = {
      [stateKey]: state,
      [zipKey]: +zip || null,
      [addressKey]: formattedAddress,
      [cityKey]: city,
    };

    const oldRowFields = {
      [stateKey]: row[stateKey],
      [zipKey]: row[zipKey],
      [addressKey]: row[addressKey],
      [cityKey]: row[cityKey],
    };

    api?.updateRows([
      {
        id: row.id,
        ...updatedRowFields,
      },
    ]);

    updateChangesState({
      updatedRowFields: getObjectDifference(oldRowFields, updatedRowFields),
      id: row.id,
    });
  };

  const setEditCellValue = (newValue) => {
    api?.setEditCellValue?.({
      id,
      field,
      value: newValue,
    });
  };

  return (
    <GoogleAutocompleteField
      autoFocus
      value={value}
      onPlaceSelected={onPlaceSelected}
      noErrorMessage
      size="small"
      onChange={(e) => {
        setEditCellValue(e.target.value);
      }}
      onPaste={(e) => {
        setEditCellValue(e.clipboardData.getData("Text"));
      }}
      placePredictionsSx={{
        top: "25px",
        left: "-10px",
        width: "calc(100% + 20px)",
      }}
    />
  );
};

ImportAddressAutocomplete.propTypes = {
  value: string,
  api: object,
  id: string,
  updateChangesState: func,
  row: object,
  field: string,
  reverseMappedCols: object,
  type: string,
};

export default ImportAddressAutocomplete;
