import { Typography } from "@mui/material";
import { useAdmin } from "helpers/helpers";
import { useDebounce, useRepsPermissions } from "helpers/hooks";
import { GlobalPageContext } from "Pages/MasterPage/MasterPage";
import pluralize from "pluralize";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import {
  deleteCustomersPriceListService,
  getCustomersPriceListsByIdService,
  updateCustomersPriceListService,
} from "services/priceLists";
import { ADMIN_ONLY_VIEW_MESSAGE, SCROLL_LIMIT } from "utils/constants";
import { error, success } from "utils/notifications";

const SORT_FIELDS = ["sort_by_id", "sort_by_territory", "sort_by_name"];

const DEFAULT_PARAMS = {
  page: 1,
  limit: SCROLL_LIMIT,
  search: "",
  sort_by_id: "",
  sort_by_territory: "",
  sort_by_name: "",
};

export const useCustomers = (currentPriceList) => {
  const { setGlobalLoading } = useContext(GlobalPageContext);
  const dispatch = useDispatch();
  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();
  const [openAllCustomers, setOpenAllCustomers] = useState(false);
  const [allCustomersList, setAllCustomersList] = useState([]);

  const [fetchParams, setFetchParams] = useState(DEFAULT_PARAMS);

  const customersSearchInputDebounced = useDebounce(fetchParams.search, 500);

  const setFetchParamsByKey = (key, value) => {
    setFetchParams((prev) => ({ ...prev, [key]: value }));
  };

  const [customersState, setCustomersState] = useState({
    list: [],
    selectedList: [],
    loading: false,
    count: 0,
  });

  const onOpenAllCustomers = () => setOpenAllCustomers(true);
  const onCloseAllCustomers = () => setOpenAllCustomers(false);

  const setLoading = (loading) =>
    setCustomersState((prev) => ({ ...prev, loading }));

  const onCustomerStateChange = (key, value) =>
    setCustomersState((prev) => ({ ...prev, [key]: value }));

  const onCancelSelection = () => onCustomerStateChange("selectedList", []);

  const checkForCustomerSelection = (id) => {
    return customersState.selectedList.some((customer) => customer.id === id);
  };

  const handleFetchCustomers = useCallback(
    async (page) => {
      if (!currentPriceList?.id) return;
      if (!page) {
        setLoading(true);
      }
      try {
        const isFetchingLastElement =
          page > 1 && customersState.count <= SCROLL_LIMIT;

        const res = await getCustomersPriceListsByIdService(
          currentPriceList.id,
          {
            ...fetchParams,
            page: isFetchingLastElement ? 1 : page ? page : 1,
          }
        );

        const rows = res?.rows || [];
        const count = res?.count || 0;

        setCustomersState((prev) => ({
          ...prev,
          list:
            page > 1 && !isFetchingLastElement ? [...prev.list, ...rows] : rows,
          count,
        }));

        setFetchParams((prev) => ({
          ...prev,
          page: isFetchingLastElement ? 1 : page ? page : 1,
        }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setLoading(false);
        setGlobalLoading(false);
      }
    },
    [currentPriceList.id, customersState.count, fetchParams, setGlobalLoading]
  );

  const onCustomersDelete = useCallback(
    async (listForDelete, successCallback) => {
      try {
        setGlobalLoading(true);

        await deleteCustomersPriceListService({
          customerIds: listForDelete,
        });

        successCallback();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        setGlobalLoading(false);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setGlobalLoading(false);
      }
    },
    [setGlobalLoading]
  );

  const onCustomerDelete = useCallback(
    (id) => {
      const filteredList = customersState.list?.filter(
        (customer) => customer?.id !== id
      );

      const filteredCheckedList = customersState.selectedList?.filter(
        (customer) => customer?.id !== id
      );

      if (customersState.count - 1 <= SCROLL_LIMIT)
        return onCustomersDelete([id], () => {
          success("Customer deleted");
          setCustomersState((prev) => ({
            ...prev,
            list: filteredList,
            selectedList: filteredCheckedList,
            count: prev.count - 1,
          }));
          setAllCustomersList(filteredList);
        });

      return onCustomersDelete([id], () => {
        success("Customer deleted");
        setCustomersState((prev) => ({
          ...prev,
          selectedList: filteredCheckedList,
        }));
        handleFetchCustomers();
        setAllCustomersList(filteredList);
      });
    },
    [
      customersState.count,
      customersState.list,
      customersState.selectedList,
      handleFetchCustomers,
      onCustomersDelete,
    ]
  );

  const getAllCustomersForPopup = useCallback(async () => {
    if (allCustomersList.length === customersState.list.length) {
      setAllCustomersList(customersState.list);
      setOpenAllCustomers(true);
      return;
    }

    setGlobalLoading(true);
    try {
      const res = await getCustomersPriceListsByIdService(currentPriceList.id);
      const rows = res?.rows || [];

      setAllCustomersList(rows);
      setOpenAllCustomers(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setGlobalLoading(false);
    }
  }, [
    allCustomersList.length,
    currentPriceList.id,
    customersState.list,
    setGlobalLoading,
  ]);

  useEffect(() => {
    handleFetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPriceList?.id,
    customersSearchInputDebounced,
    fetchParams?.sort_by_id,
    fetchParams?.sort_by_territory,
    fetchParams?.sort_by_name,
  ]);

  const onSaveCustomersFromPopup = async (list) => {
    if (!list.length) return;

    try {
      setLoading(true);
      await updateCustomersPriceListService(currentPriceList.id, {
        customersIds: list.map((customer) => customer.id),
      });

      if (list.length)
        success(list.length === 1 ? "Customer added" : "Customers added");

      handleFetchCustomers();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      setLoading(false);
      error(err?.response?.data?.message || "Something went wrong.");
    }
  };

  const handleCustomersSetSort = useCallback(
    (sortField) => {
      const sortFieldToDefault = Object.keys(fetchParams).filter(
        (item) => SORT_FIELDS.includes(item) && item !== sortField
      );

      setFetchParams({
        ...fetchParams,
        [sortField]: fetchParams?.[sortField] !== "desc" ? "desc" : "asc",
        ...sortFieldToDefault.reduce(
          (acc, field) => ({ ...acc, [field]: "" }),
          {}
        ),
      });
    },
    [fetchParams]
  );

  const quickCustomersSort = useMemo(() => {
    return {
      sort_by_id: fetchParams?.sort_by_id,
      sort_by_territory: fetchParams?.sort_by_territory,
      sort_by_name: fetchParams?.sort_by_name,
    };
  }, [
    fetchParams?.sort_by_id,
    fetchParams?.sort_by_territory,
    fetchParams?.sort_by_name,
  ]);

  const handleCheckCustomer = (customer) => {
    if (checkForCustomerSelection(customer.id))
      return setCustomersState((prev) => ({
        ...prev,
        selectedList: prev.selectedList.filter(
          (selectedCustomer) => selectedCustomer.id !== customer.id
        ),
      }));

    return setCustomersState((prev) => ({
      ...prev,
      selectedList: [...prev.selectedList, customer],
    }));
  };

  const onSelectAllCustomers = () => {
    if (customersState.selectedList.length === customersState.list.length)
      return onCancelSelection();
    return onCustomerStateChange("selectedList", customersState.list);
  };

  const allCustomersChecked = useMemo(
    () => customersState.list.length === customersState.selectedList.length,
    [customersState.list.length, customersState.selectedList.length]
  );

  const onBulkSelectionDelete = useCallback(() => {
    if (customersState.selectedList.length === 1)
      return onCustomerDelete(customersState.selectedList[0].id);

    const ids = customersState.selectedList.map(({ id }) => id);

    const filteredList = customersState.list?.filter(
      ({ id }) => !ids.includes(id)
    );

    if (customersState.count - ids.length <= SCROLL_LIMIT)
      return onCustomersDelete(ids, () => {
        success("Customers deleted");
        setAllCustomersList(filteredList);
        setCustomersState((prev) => ({
          ...prev,
          list: filteredList,
          count: prev.count - ids.length,
          selectedList: [],
        }));
      });

    return onCustomersDelete(ids, () => {
      success("Customers deleted");
      setAllCustomersList(filteredList);
      setCustomersState((prev) => ({
        ...prev,
        selectedList: [],
      }));
      handleFetchCustomers();
    });
  }, [
    customersState.selectedList,
    customersState.list,
    customersState.count,
    onCustomerDelete,
    onCustomersDelete,
    handleFetchCustomers,
  ]);

  const fetchAndCheckAllCustomers = async () => {
    if (customersState.list.length < customersState.count) {
      setLoading(true);
      try {
        const res = await getCustomersPriceListsByIdService(
          currentPriceList.id
        );

        const rows = res?.rows || [];
        const count = res?.count || 0;

        setCustomersState((prev) => ({
          ...prev,
          list: rows,
          selectedList: rows,
          count,
        }));

        return;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setLoading(false);
      }
    }
    setCustomersState((prev) => ({
      ...prev,
      selectedList: customersState.list,
    }));
  };

  const onCustomerFromListRemove = useCallback(
    (customer) => {
      const title = customer
        ? "Delete customer?"
        : `Delete ${pluralize(
            "customer",
            customersState.selectedList.length
          )}?`;

      dispatch(
        openConfirmDialogAction({
          title,
          text: (
            <>
              {customer ? (
                <Typography
                  fontWeight="400"
                  fontSize="15px"
                  whiteSpace="pre-line"
                >
                  Are you sure you want to delete{" "}
                  <span style={{ fontWeight: "500" }}>
                    &#39;{customer?.displayedName || customer?.name}&#39;
                  </span>{" "}
                  from the list?
                </Typography>
              ) : (
                <Typography
                  fontWeight="400"
                  fontSize="15px"
                  whiteSpace="pre-line"
                >
                  Are you sure you want to delete selected{" "}
                  {pluralize("customer", customersState.selectedList.length)}?
                </Typography>
              )}
            </>
          ),
          isIcon: true,
          propBtns: {
            left: {
              label: "Cancel",
              color: "cancel",
              variant: "outlined",
              sx: {
                width: "78px",
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                fontSize: "13px",
                height: "28px",
              },
            },
            right: {
              sx: {
                width: "78px",
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
                backgroundColor: "#EB4233",
              },
              color: "error",
              label: "Delete",
              variant: "contained",
              onClick: () => {
                dispatch(setConfirmIsOpenAction(false));
                if (customer) return onCustomerDelete(customer.id);
                return onBulkSelectionDelete();
              },
            },
          },
        })
      );
    },
    [
      customersState.selectedList.length,
      dispatch,
      onBulkSelectionDelete,
      onCustomerDelete,
    ]
  );

  const disabledCreatePriceListEdit = useMemo(() => {
    return repPermissions
      ? !repPermissions?.catalog?.create_edit_price_lists
      : false;
  }, [repPermissions]);

  const handleGetActionsList = useCallback(() => {
    if (!customersState.selectedList.length) return [];

    return [
      {
        label: "Delete",
        element: null,
        disabled: isAdmin || disabledCreatePriceListEdit,
        onClick: onCustomerFromListRemove,
        show: true,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: disabledCreatePriceListEdit,
          props: { isDark: true },
        },
      },
    ];
  }, [
    customersState.selectedList.length,
    disabledCreatePriceListEdit,
    isAdmin,
    onCustomerFromListRemove,
  ]);

  const ACTIONS_LIST = useMemo(
    () => handleGetActionsList(),
    [handleGetActionsList]
  );

  return {
    fetchParams,
    setFetchParamsByKey,
    openAllCustomers,
    onOpenAllCustomers,
    onCloseAllCustomers,
    onCustomerStateChange,
    onCancelSelection,
    customersList: customersState.list,
    selectedCustomers: customersState.selectedList,
    customersCount: customersState.count,
    customersLoading: customersState.loading,
    checkForCustomerSelection,
    handleCheckCustomer,
    onCustomerDelete,
    onSelectAllCustomers,
    allCustomersChecked,
    ACTIONS_LIST,
    onCustomerFromListRemove,
    quickCustomersSort,
    handleCustomersSetSort,
    searchCustomersInput: fetchParams.search,
    customersPage: fetchParams.page,
    onSaveCustomersFromPopup,
    handleFetchCustomers,
    fetchAndCheckAllCustomers,
    getAllCustomersForPopup,
    allCustomersList,
    customersSearchInputDebounced,
  };
};
