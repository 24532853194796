import { CircularProgress, Stack } from "@mui/material";
import { TasksHeader, TasksItem } from "./components";
import { useContext, useEffect } from "react";
import { TasksPageContext } from "Pages/TasksPage/TasksPage";
import {
  EmptyScreen,
  InfiniteLoadMoreBtn,
  InfiniteScrollWrapper,
  TableHeaderControlPanel,
} from "components";
import { tasksFilterAction } from "redux/actions/tasks";
import { useDispatch } from "react-redux";
import { STATUSES_TASKS } from "Pages/TasksPage/TasksPage.constants";
import { ADMIN_ONLY_VIEW_MESSAGE } from "utils/constants";

export const TasksList = () => {
  const dispatch = useDispatch();

  const {
    currentUser,
    repPermissions,
    checkedTasks,
    setCheckedTasks,
    handleCheckTask,
    checkAllTasks,
    handleSelectAvailableTasks,
    handleOpenAssignRapDrawer,
    handleOpenDueDateDrawer,

    handleCreateTask,

    tasksFilter,
    countTasks,
    existData,
    loadingTasksList,
    tasksList,
    fetchTasksList,
    handleBulkMarkAsCompleted,
    handleBulkMarkAsIncomplete,
    handleCreateRoute,
  } = useContext(TasksPageContext);

  const { timeZone } = currentUser || {};

  const disabledActions = {
    delete: repPermissions ? !repPermissions?.tasks?.delete : false,
    create_edit: repPermissions ? !repPermissions?.tasks?.create_edit : false,
    create_route: repPermissions ? !repPermissions?.routes?.create_edit : false,
  };

  const handleSingleRoutesActions = () => {
    const isMarkBtnDisabled =
      checkedTasks?.[0]?.assignedDistributorId !== currentUser?.id &&
      checkedTasks?.[0]?.status === STATUSES_TASKS.PENDING;

    return [
      {
        label: "Edit task",
        element: null,
        disabled: disabledActions.create_edit,
        onClick: () => handleCreateTask(checkedTasks?.[0]),
        show: true,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: disabledActions.create_edit,
          props: { isDark: true },
        },
      },
      {
        label: "Mark as Completed",
        element: null,
        disabled:
          isMarkBtnDisabled ||
          disabledActions.create_edit ||
          checkedTasks?.[0]?.status !== STATUSES_TASKS.PENDING,
        onClick: () => handleBulkMarkAsCompleted(),
        show: tasksFilter.status !== STATUSES_TASKS.COMPLETED,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: disabledActions.create_edit,
          props: { isDark: true },
        },
      },
      {
        label: "Mark as Incomplete",
        element: null,
        disabled: isMarkBtnDisabled || disabledActions.create_edit,
        onClick: () => handleBulkMarkAsIncomplete(),
        show: tasksFilter.status === STATUSES_TASKS.COMPLETED,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: disabledActions.create_edit,
          props: { isDark: true },
        },
      },
      {
        label: "Create route",
        element: null,
        disabled: disabledActions.create_route,
        onClick: () => handleCreateRoute(),
        show: true,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: disabledActions.create_route,
          props: { isDark: true },
        },
      },
      {
        label: "Assign rep",
        element: null,
        disabled: disabledActions.create_edit,
        onClick: () => handleOpenAssignRapDrawer(),
        show: true,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: disabledActions.create_edit,
          props: { isDark: true },
        },
      },
      {
        label: "Set due Date",
        element: null,
        disabled: disabledActions.create_edit,
        disabledPermissions: disabledActions.create_edit,
        onClick: () => handleOpenDueDateDrawer(),
        show: true,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: disabledActions.create_edit,
          props: { isDark: true },
        },
      },
    ];
  };

  const handleMultipleRoutesActions = () => {
    const isMarkBtnDisabled = checkedTasks.some(
      (task) =>
        task.assignedDistributorId !== currentUser?.id &&
        task.status === STATUSES_TASKS.PENDING
    );

    return [
      {
        label: "Mark as Completed",
        element: null,
        disabled:
          isMarkBtnDisabled ||
          checkedTasks.some((task) => task.status !== STATUSES_TASKS.PENDING),
        onClick: () => handleBulkMarkAsCompleted(),
        show: tasksFilter.status !== STATUSES_TASKS.COMPLETED,
      },
      {
        label: "Mark as Incomplete",
        element: null,
        disabled: isMarkBtnDisabled,
        onClick: () => handleBulkMarkAsIncomplete(),
        show: tasksFilter.status === STATUSES_TASKS.COMPLETED,
      },
      {
        label: "Create route",
        element: null,
        disabled: false,
        onClick: () => handleCreateRoute(),
        show: true,
      },
      {
        label: "Assign rep",
        element: null,
        // disabled: !!repPermissions && !repPermissions?.routes?.assign,
        disabled: false,
        onClick: () => handleOpenAssignRapDrawer(),
        show: true,
      },
      {
        label: "Set due Date",
        element: null,
        onClick: () => handleOpenDueDateDrawer(),
        show: true,
      },
    ];
  };

  const handleGetQuickActions = (params) => {
    if (!checkedTasks.length) return [];

    if (checkedTasks.length === 1) {
      return handleSingleRoutesActions(params).slice(0, 5);
    }
    return handleMultipleRoutesActions(params).slice(0, 5);
  };

  const handleGetDropDownActions = (params) => {
    if (!checkedTasks.length) return [];

    if (checkedTasks.length === 1) {
      return handleSingleRoutesActions(params).slice(5, 6);
    }
    return handleMultipleRoutesActions(params).slice(5, 6);
  };

  const QUICK_ACTIONS = handleGetQuickActions();

  const DROPDOWN_ACTIONS = handleGetDropDownActions();

  const handleNextFunc = () => {
    dispatch(tasksFilterAction({ ...tasksFilter, page: tasksFilter.page + 1 }));
  };

  useEffect(() => {
    return () => {
      dispatch(tasksFilterAction({ ...tasksFilter, page: 1 }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchTasksList();
  }, [fetchTasksList]);

  return tasksList?.length ? (
    <>
      <TableHeaderControlPanel
        actionWrapperProps={{ sx: { padding: "0 19px 0 14px !important" } }}
        checkedCount={checkedTasks.length}
        actionsList={QUICK_ACTIONS}
        loading={false}
        dropDownActions={DROPDOWN_ACTIONS}
        onSelectAll={(value) => checkAllTasks(value)}
        hasCheckedItems={!!checkedTasks.length}
        availableSelectCount={countTasks}
        selectName="task"
        onSelectVisible={handleSelectAvailableTasks}
        cancelSelection={() => setCheckedTasks([])}
        headerComponent={<TasksHeader />}
      />
      <Stack
        sx={{
          height: "100%",
          maxHeight: "calc(100vh - 318px)",
          overflow: "hidden",
          borderWidth: "0 1px 1px 1px",
          borderStyle: "solid",
          borderColor: "#D5D9D9",
          borderRadius: "0 0 4px 4px",
        }}
      >
        <InfiniteScrollWrapper
          dataLength={tasksList.length}
          loading={loadingTasksList}
          next={handleNextFunc}
          loader={
            <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />
          }
          hasMore={tasksList.length < countTasks}
          maxHeight={"calc(100vh - 318px)"}
          id="tasks-list-scroll"
        >
          {tasksList.map((task, index) => (
            <TasksItem
              key={task.id}
              task={task}
              showBorder={index === tasksList.length - 1}
              isChecked={checkedTasks.some(
                (checkedTask) => checkedTask?.id === tasksList[index]?.id
              )}
              timeZone={timeZone}
              handleCheckTask={handleCheckTask}
              handleEditTask={
                disabledActions.create_edit
                  ? null
                  : (task) => handleCreateTask(task)
              }
              repPermissions={repPermissions}
            />
          ))}
          {!loadingTasksList && tasksList.length < countTasks && (
            <InfiniteLoadMoreBtn onClick={handleNextFunc} />
          )}
        </InfiniteScrollWrapper>
      </Stack>
    </>
  ) : (
    <EmptyScreen
      type="task"
      height="calc(100vh - 470px)"
      onConfirm={() => handleCreateTask()}
      showAction={!existData}
      loading={loadingTasksList}
      disabled={!!repPermissions && !repPermissions?.tasks?.create_edit}
      tooltipTitle={ADMIN_ONLY_VIEW_MESSAGE}
    />
  );
};
