import { useState } from "react";
import moment from "moment-timezone";
import { object, func, bool } from "prop-types";
import { ActionRestrictionWrapper, StyledButton } from "components";

import { Box, Grid, Typography } from "@mui/material";

const CustomerReceivedHoursCard = ({
  items,
  onDelete,
  handleEdit,
  showWorkingDays,
  disabled,
}) => {
  const [hoveredElement, setHoveredElement] = useState(null);

  return (
    <Box pb={showWorkingDays ? "17px" : "2px"} px="16px">
      {showWorkingDays && (
        <>
          <Grid
            flexWrap="nowrap"
            sx={{
              border: "0.5px solid #D5D9D9",
              borderRadius: "4px",
              bgcolor: "#F8F8F8",
              position: "relative",
              width: "100%",
              overflow: "hidden",
              boxSizing: "border-box",
            }}
            p="10px 16px 8px 16px"
            container
            onMouseEnter={() => setHoveredElement(true)}
            onMouseLeave={() => setHoveredElement(false)}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: hoveredElement ? "calc(100% - 54px)" : "100%",
              }}
            >
              {Object.values(items).map((day) => (
                <Grid key={day?.name} container flexWrap="nowrap">
                  <Box width="50px">
                    <Typography
                      fontSize={13}
                      fontWeight={500}
                      color="#5F6267"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {day?.name.slice(0, 3)}
                    </Typography>
                  </Box>
                  {day?.value?.clockInAt && day?.value?.clockOutAt ? (
                    <Box
                      sx={{
                        maxWidth: hoveredElement ? "calc(100% - 54px)" : "100%",
                      }}
                    >
                      <Typography
                        fontSize={13}
                        fontWeight={500}
                        color="#B8B8B8"
                        noWrap
                      >
                        {moment(day?.value?.clockInAt).utc().format("h:mm a")}
                        {" - "}
                        {moment(day?.value?.clockOutAt).utc().format("h:mm a")}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography
                      fontSize={13}
                      fontWeight={500}
                      color="#FF6969"
                      noWrap
                    >
                      Closed
                    </Typography>
                  )}
                </Grid>
              ))}
            </Box>

            <Box
              sx={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-end",
                width: hoveredElement ? "54px" : "0px",
                display: hoveredElement ? "flex" : "none",
              }}
            >
              <ActionRestrictionWrapper disableHoverListener={!disabled}>
                <StyledButton
                  sx={{
                    borderColor: "#D4D4D4",
                    color: "#000",
                    maxWidth: "55px",
                    minWidth: "55px",
                    height: "20px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                  disabled={disabled}
                  label="Edit"
                  variant="outlined"
                  color="ground"
                  fontSize="10px"
                  fontWeight="500"
                  onClick={handleEdit}
                />
              </ActionRestrictionWrapper>
              <ActionRestrictionWrapper disableHoverListener={!disabled}>
                <StyledButton
                  sx={{
                    mt: 1,
                    borderColor: "#D4D4D4",
                    color: "#000",
                    maxWidth: "55px",
                    minWidth: "55px",
                    height: "20px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                  disabled={disabled}
                  label="Remove"
                  variant="outlined"
                  color="ground"
                  fontSize="10px"
                  fontWeight="500"
                  onClick={onDelete}
                />
              </ActionRestrictionWrapper>
            </Box>
          </Grid>
        </>
      )}
    </Box>
  );
};
CustomerReceivedHoursCard.propTypes = {
  items: object,
  handleAdd: func,
  onDelete: func,
  handleEdit: func,
  showWorkingDays: bool,
  disabled: bool,
};

export default CustomerReceivedHoursCard;
