import { PriceFormat, ValueToggleButtonGroup } from "components";

import { func, object } from "prop-types";
import { cl } from "../styles";

import { Box } from "@mui/material";

export const DrawerContent = ({
  isAllowed,
  tempDiscountValue,
  setTempDiscountValue,
}) => {
  const handleDiscountChange = (val) =>
    setTempDiscountValue((prev) => ({
      ...prev,
      value: val.value === 0 ? "" : val.value,
    }));

  const handleDiscountTypeChange = (val) =>
    setTempDiscountValue((prev) => ({
      ...prev,
      type: val.target.value,
      value: "",
    }));

  return (
    <Box p="32px" width="100%">
      <PriceFormat
        type={tempDiscountValue.type}
        errorMsgSx={{
          transform: "translate(-100%, 0)",
          bottom: 0,
          left: -5,
        }}
        isAllowed={(values) => isAllowed(tempDiscountValue.type, values)}
        value={tempDiscountValue.value}
        noErrorMessage={false}
        //decimalScale={1}
        //thousandSeparator
        //fixedDecimalScale={false}
        onValueChange={handleDiscountChange}
        fullWidth
        allowNegative={false}
        InputProps={{
          sx: { ...cl.moqInput, pr: "0.5px" },
          endAdornment: (
            <ValueToggleButtonGroup
              qtyPicker={false}
              hideTooltip
              sx={{ height: "36px !important" }}
              type={tempDiscountValue.type}
              toggleBtnSx={cl.toggleBtn}
              selectedColor="#47A06D26"
              unSelectedColor="#F8F8F8"
              onChange={handleDiscountTypeChange}
            />
          ),
        }}
      />
    </Box>
  );
};

DrawerContent.propTypes = {
  isAllowed: func,
  tempDiscountValue: object,
  setTempDiscountValue: func,
};
