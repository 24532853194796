import { object, func } from "prop-types";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { TitleBlock } from "./components";
import {
  AccordionComponent,
  DetailsComponent,
  LargeCheckbox,
  SummaryComponent,
} from "components";
import { ShevronIcon } from "components/Icons";
import { sx } from "./TabPermissionsComponent.styles";
import { normalizeSnakeCaseString } from "helpers/helpers";
import { Controller } from "react-hook-form";
import { useCallback } from "react";

const TabPermissionsComponent = ({ formField, control, setValue }) => {
  const { role, permissions, portalAccess } = formField || {};

  const permissionData = () => {
    const name = (role) => {
      if (role === "SALES") {
        // return "Sales Rep Access";
        return "Web Portal";
      }
      if (role === "MERCHANDISER") {
        // return "Merchandiser Rep Access";
        return "Web Portal";
      }
      return "";
    };

    const temp = { ...permissions };

    delete temp.id;
    delete temp.role;
    delete temp.representativeId;

    const preparedData = Object.keys(temp).map((key) => {
      const values = Object.values(temp[key]);
      const keys = Object.keys(temp[key]);

      const setName = (childKey) => {
        if (childKey === "create_edit") return "Create and edit";

        return (childKey.charAt(0).toUpperCase() + childKey.slice(1)).replace(
          /_/g,
          " "
        );
      };

      return {
        id: key,
        name: normalizeSnakeCaseString(key),
        checked: values.every(Boolean),
        checkedCount: values.filter(Boolean).length,
        totalCount: values.length,
        children: keys.map((childKey, index) => ({
          id: childKey,
          name: setName(childKey),
          value: values[index],
        })),
      };
    });

    return {
      name: name(role),
      data: preparedData,
    };
  };

  const valueCheckbox = (arr) => {
    const allTrue = arr?.every((item) => !!item?.value);
    return {
      checked: allTrue,
    };
  };

  const { name, data } = permissionData();

  const handleOnChange = ({ permissionBlock, permissionItem }) => {
    const hasKeyViewInObject =
      "view" in formField?.permissions?.[`${permissionBlock?.id}`];

    if (
      permissionItem?.name !== "View" &&
      hasKeyViewInObject &&
      !formField?.permissions?.[`${permissionBlock?.id}`]?.view &&
      !permissionItem?.value
    ) {
      setValue(`permissions.${permissionBlock?.id}.view`, true);
    }

    if (
      permissionItem?.name === "View" &&
      formField?.permissions?.[`${permissionBlock?.id}`]?.view
    ) {
      setValue(`permissions.${permissionBlock?.id}`, {
        ...permissionBlock?.children?.reduce((acc, child) => {
          acc[child?.id] = false;
          return acc;
        }, {}),
      });
    }

    setValue(
      `permissions.${permissionBlock?.id}.${permissionItem.id}`,
      !permissionItem?.value
    );

    // logic only for the Catalog | View price lists ・ Create edit price lists ・ Delete price lists
    if (
      ["Create edit price lists", "Delete price lists"].includes(
        permissionItem.name
      )
    ) {
      setValue(`permissions.${permissionBlock?.id}.view_price_lists`, true);
    }

    if ("View price lists" === permissionItem.name && permissionItem?.value) {
      setValue(
        `permissions.${permissionBlock?.id}.create_edit_price_lists`,
        false
      );
      setValue(`permissions.${permissionBlock?.id}.delete_price_lists`, false);
    }
  };

  const handleWebAccess = useCallback(
    (accessValue) => {
      setValue("portalAccess", accessValue);

      Object.keys(permissions).forEach((blockKey) => {
        const permissionBlock = permissions[blockKey];
        if (typeof permissionBlock === "object") {
          Object.keys(permissionBlock).forEach((childKey) => {
            setValue(`permissions.${blockKey}.${childKey}`, accessValue);
          });
        }
      });
    },
    [permissions, setValue]
  );

  return (
    <Box
      sx={{
        width: "100%",
        px: "26px",
        pt: 4,
        pb: 0.5,
        maxHeight: "464px",
        overflow: "auto",
      }}
    >
      <TitleBlock {...{ name, control, handleWebAccess }} />

      <Stack mt={2} gap={2}>
        {data.map((item) => {
          const { totalCount, checkedCount, name } = item;
          return (
            <AccordionComponent
              key={name}
              item={item}
              expandedDflt={false}
              sx={sx.accordionComponent}
            >
              <SummaryComponent item={item} sx={sx.summaryComponent}>
                <Grid container alignItems="center" height="100%">
                  <Grid item xs sx={sx.summaryComponentGrid}>
                    <Controller
                      render={({ field }) => {
                        const { checked } = valueCheckbox(item.children);
                        return (
                          <LargeCheckbox
                            disabled={!portalAccess}
                            checked={checked}
                            indeterminate={
                              checkedCount > 0 && checkedCount !== totalCount
                            }
                            indeterminateIcon={21}
                            checkedColor={!portalAccess ? "#C5C5C5" : "#47a06d"}
                            size={18}
                            formSx={{ m: 0 }}
                            sx={{
                              p:
                                checkedCount > 0 && checkedCount !== totalCount
                                  ? "8px"
                                  : "9px",
                              "& svg": {
                                ml:
                                  checkedCount > 0 &&
                                  checkedCount !== totalCount &&
                                  "-1px",
                              },
                            }}
                            onClick={(e) => e.stopPropagation()}
                            {...field}
                            onChange={(e) => {
                              const val = e.target.checked;
                              setValue(`permissions.${item?.id}`, {
                                ...item?.children?.reduce((acc, child) => {
                                  acc[child?.id] = val;
                                  return acc;
                                }, {}),
                              });
                            }}
                          />
                        );
                      }}
                      control={control}
                      name={`permissions.${item?.id}`}
                    />
                    <Typography ml="6px">{item?.name}</Typography>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {`${checkedCount}/${totalCount}`}
                  </Grid>

                  <Grid item xs={0.8} display="flex" justifyContent="center">
                    <ShevronIcon
                      className="my-ico"
                      width={12}
                      height={6}
                      color="#707070"
                    />
                  </Grid>
                </Grid>
              </SummaryComponent>

              <DetailsComponent>
                <Grid container>
                  <Stack py={1}>
                    {item?.children?.map((c) => (
                      <Box key={c?.name} pl="45px">
                        <Controller
                          render={({ field }) => {
                            return (
                              <LargeCheckbox
                                disabled={!portalAccess}
                                checkedColor={
                                  !portalAccess ? "#C5C5C5" : "#47a06d"
                                }
                                size={18}
                                formSx={{ m: 0 }}
                                label={
                                  <Typography
                                    fontSize={15}
                                    fontWeight={400}
                                    color="#707070"
                                  >
                                    {c?.name}
                                  </Typography>
                                }
                                checked={c?.value}
                                {...field}
                                onChange={() =>
                                  handleOnChange({
                                    permissionBlock: item,
                                    permissionItem: c,
                                  })
                                }
                              />
                            );
                          }}
                          control={control}
                          name={`permissions.${item?.id}.${c.id}`}
                        />
                      </Box>
                    ))}
                  </Stack>
                </Grid>
              </DetailsComponent>
            </AccordionComponent>
          );
        })}
      </Stack>
    </Box>
  );
};

TabPermissionsComponent.propTypes = {
  control: object,
  formField: object,
  setValue: func,
};
TabPermissionsComponent.defaultProps = {
  control: {},
  formField: {},
  setValue: () => {},
};

export default TabPermissionsComponent;
