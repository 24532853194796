import { useCallback, useContext, useMemo } from "react";

import { LargeCheckbox, TableHeader } from "components";
import { FormsPageContext } from "Pages/TasksPage/TasksPage";
import { ArrowDownIcon, ArrowUpIcon } from "components/Icons";

import { cl } from "./FormsListHeader.styles";

import { Box, Grid, Typography } from "@mui/material";

export const FormsListHeader = () => {
  const {
    allFormsChecked,
    handleSelectAvailableForms,
    checkedForms,
    formsFilter,
    handleSetSortBy,
  } = useContext(FormsPageContext);

  const isIndeterminate = useMemo(
    () =>
      checkedForms?.length > 0 && checkedForms?.length !== checkedForms?.length,
    [checkedForms]
  );

  const TABLE_DATA = [
    {
      id: 1,
      title: "",
      element: (
        <LargeCheckbox
          formSx={{ m: 0 }}
          checked={allFormsChecked}
          onChange={handleSelectAvailableForms}
          sx={cl.checkboxSx}
          onClick={(e) => e.stopPropagation()}
          size={22}
          rectSize={22}
          indeterminate={isIndeterminate}
          indeterminateIcon={27}
          checkedViewBox="0 0 24 24"
        />
      ),
      sx: { pl: "14.5px" },
      xs: 0.44,
    },
    {
      id: 2,
      title: "STATUS",
      sx: { ...cl.leftHeaderItem },
      xs: 0.52,
    },
    {
      id: 3,
      title: "TITLE",
      column: "sort_by_title",
      sx: { ...cl.leftHeaderItem },
      xs: 6.3,
    },
    {
      id: 4,
      title: "TOTAL CUSTOMERS",
      column: "sort_by_customers",
      sx: { ...cl.leftHeaderItem },
      xs: 2.37,
    },
    {
      id: 5,
      title: "TOTAL RESPONSES",
      column: "sort_by_responses",
      sx: { ...cl.leftHeaderItem },
      xs: 2.37,
    },
  ];

  const SORTABLE_COLUMNS = {
    TITLE: { sortableField: formsFilter.sort_by_title },
    "TOTAL CUSTOMERS": { sortableField: formsFilter.sort_by_customers },
    "TOTAL RESPONSES": { sortableField: formsFilter.sort_by_responses },
  };
  // tasksFilter
  const quickSortArrow = useCallback((value) => {
    if (value === "asc") return <ArrowDownIcon />;
    if (value === "desc") return <ArrowUpIcon />;

    return (
      <Box sx={{ opacity: 0.5 }}>
        <ArrowDownIcon />
      </Box>
    );
  }, []);

  return (
    <TableHeader sx={cl.tableHeaderSx} columns={12}>
      {TABLE_DATA.map(({ id, sx, title, element, column, xs }) => {
        const sortableField = SORTABLE_COLUMNS[title]?.sortableField;

        return (
          <Grid sx={sx} key={id} item xs={xs}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: SORTABLE_COLUMNS[title] ? "pointer" : "auto",
              }}
              onClick={() => {
                if (SORTABLE_COLUMNS[title]) {
                  const preparedData = {
                    sort_by_title: undefined,
                    sort_by_customers: undefined,
                    sort_by_responses: undefined,
                  };

                  preparedData[column] =
                    sortableField === "asc" ? "desc" : "asc";

                  handleSetSortBy(preparedData);
                }
              }}
            >
              {title && (
                <Typography fontSize={12} color="#6A6A6A" mr={1}>
                  {title}
                </Typography>
              )}
              {element && element}

              {SORTABLE_COLUMNS[title] && (
                <Box sx={cl.arrowBox}>{quickSortArrow(sortableField)}</Box>
              )}
            </Box>
          </Grid>
        );
      })}
    </TableHeader>
  );
};
