import { GlobalPageContext } from "Pages/MasterPage/MasterPage";
import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import {
  deletePriceListService,
  getPriceListsByIdService,
  updatePriceListService,
} from "services/priceLists";
import { error, success } from "utils/notifications";
import { TABS_PAGE } from "./constants";

const DEFAULT_STATE = {
  id: null,
  name: "",
  _count: { products: 0, customers: 0 },
};

export const useProductListById = (priceItemId) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(TABS_PAGE[0].value);
  const { setGlobalLoading } = useContext(GlobalPageContext);
  const [currentPriceList, setCurrentPriceList] = useState(DEFAULT_STATE);

  const getPriceInfoById = useCallback(async () => {
    setGlobalLoading(true);
    try {
      const res = await getPriceListsByIdService(priceItemId);

      setCurrentPriceList(res);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setGlobalLoading(false);
    }
  }, [priceItemId, setGlobalLoading]);

  const onDeleteConfirm = useCallback(async () => {
    setGlobalLoading(true);
    dispatch(setConfirmIsOpenAction(false));

    try {
      await deletePriceListService({ priceListsIds: [priceItemId] });

      success("Price list deleted");
      return navigate("/catalog", {
        state: { currentTab: "Price Lists" },
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setGlobalLoading(false);
    }
  }, [dispatch, navigate, priceItemId, setGlobalLoading]);

  const onPriceListDelete = () => {
    dispatch(
      openConfirmDialogAction({
        title: "Delete price list?",
        isIcon: true,
        text: `Are you sure you want to delete the '${currentPriceList.name}' price list?`,
        propBtns: {
          left: {
            label: "Cancel",
            color: "cancel",
            variant: "outlined",
            sx: {
              width: "78px",
              color: "#6A6A6A",
              borderColor: "#D4D4D4",
              fontSize: "13px",
              height: "28px",
            },
          },
          right: {
            sx: {
              width: "78px",
              color: "#FFFFFF",
              fontSize: "13px",
              height: "28px",
              boxShadow: "none",
              backgroundColor: "#EB4233",
            },
            color: "error",
            label: "Delete",
            variant: "contained",
            onClick: () => onDeleteConfirm(),
          },
        },
      })
    );
  };

  const onEditSave = async (id, name) => {
    setGlobalLoading(true);
    try {
      const res = await updatePriceListService(id, { name });

      setCurrentPriceList((prev) => ({ ...prev, name: res?.name }));
      success("Price list name updated");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setGlobalLoading(false);
    }
  };

  useEffect(() => {
    getPriceInfoById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onPriceListDelete,
    currentPriceList,
    onEditSave,
    setCurrentTab,
    currentTab,
    tabs: TABS_PAGE,
  };
};
