export const cl = {
  headerWrapper: {
    justifyContent: "space-between",
    alignItems: "center",
    px: "40px",
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #E5E5E5",
    height: "38px",
  },
};
