import { string, func } from "prop-types";

import { StyledTextField } from "components";

import { cl } from "../styles";

import { DialogContent } from "@mui/material";

export const CreatePriceListContent = ({ priceName, onChange }) => {
  return (
    <DialogContent
      sx={{
        p: "20px 28px 32px",
        maxWidth: "484px",
        minWidth: "420px",
      }}
    >
      <StyledTextField
        label="Price list name"
        name="priceName"
        fullWidth
        labelSx={cl.inputLabel}
        value={priceName}
        onChange={onChange}
      />
    </DialogContent>
  );
};

CreatePriceListContent.propTypes = {
  priceName: string,
  onChange: func,
};
CreatePriceListContent.defaultProps = {
  priceName: "",
  onChange: () => {},
};
