import { object, func, number } from "prop-types";

import { FooterBlock, TitleBlock } from "./components";

import { cl } from "./QuestionItem.styles";

import { Box, ClickAwayListener, Stack } from "@mui/material";
import { useMemo, useRef, useState } from "react";
import { getCurrentContentBlock } from "Pages/FormViewPage/FormViewPage.constants";

export const QuestionItem = ({
  question,
  activeQuestion,
  setActiveQuestion,
  handler,
  orderIndex,
  control,
  setValue,
  formField,
  errors,
  clearErrors,
  handleOpenChangeDialog,
  trigger,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [selectedType, setSelectedType] = useState(question?.type);

  const selectRef = useRef(null);

  const handleClickAway = (event) => {
    if (event.target.localName === "body") return;
    if (selectRef.current && selectRef.current.contains(event.target)) return;

    setActiveQuestion(null);
  };

  const isActive = activeQuestion?.uuid === question?.uuid;

  const handleItemClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setActiveQuestion(question);
  };

  const contentElement = useMemo(
    () =>
      getCurrentContentBlock(selectedType, {
        isActive,
        setValue,
        formField,
        control,
        errors,
        clearErrors,
        trigger,
        ...question,
      }),
    [
      selectedType,
      isActive,
      setValue,
      formField,
      control,
      errors,
      clearErrors,
      trigger,
      question,
    ]
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box
          component="span"
          sx={{
            opacity: isHovered ? 1 : 0,
            transition: "all 0.3s",
            position: "absolute",
            width: "100%",
          }}
        >
          {handler}
        </Box>
        <Stack
          sx={{
            ...cl.itemWrapper,
            border: `0.5px solid ${isActive ? "#47A06D" : "#FFF"} !important`,
            ...(isActive
              ? {
                  boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.16);",
                }
              : { boxShadow: "0px 1.5px 4px rgba(0, 0, 0, 0.16)" }),
          }}
          onClick={handleItemClick}
        >
          <Stack sx={cl.titleBlockWrapper} direction="row">
            <TitleBlock
              {...{
                isActive,
                question,
                selectedType,
                handleItemClick,
                setSelectedType,
                setActiveQuestion,
                orderIndex,
                control,
                setValue,
                formField,
                handleOpenChangeDialog,
                trigger,
              }}
              ref={selectRef}
            />
          </Stack>

          {contentElement}

          <FooterBlock {...{ question, orderIndex, control }} />
        </Stack>
      </Box>
    </ClickAwayListener>
  );
};

QuestionItem.propTypes = {
  question: object,
  handler: object,
  setActiveQuestion: func,
  activeQuestion: object,
  orderIndex: number,
  control: object,
  setValue: func,
  formField: object,
  errors: object,
  clearErrors: func,
  handleOpenChangeDialog: func,
  trigger: func,
};
