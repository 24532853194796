import Box from "@mui/material/Box";

import useStyles from "./styles";

import { FilterSearchTextField } from "components";
import { ProductPriceContext } from "Pages/ProductPricePage";
import { useContext } from "react";

export const FilterBlock = () => {
  const classes = useStyles();
  const { setListSearch, searchInput } = useContext(ProductPriceContext);

  return (
    <>
      <Box className={classes.filtersWrapper}>
        <FilterSearchTextField
          adminIsAllowed
          placeholder="Search products by name, manufacturer or SKU"
          value={searchInput}
          onChange={(e) => setListSearch(e.target.value)}
          fullWidth
          adornmentProps={{
            sx: {
              justifyContent: "flex-end",
            },
          }}
          handleClearValue={() => setListSearch("")}
        />
      </Box>
    </>
  );
};
