import { object, func, shape, bool } from "prop-types";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  Link as MuiLink,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { ActiveIcon } from "../../../../components/Icons";
import { StyledField } from "./StyledField";
import PasswordCriteria from "../../../../components/PasswordCriteria";
import { PASSWORD_CRITERIA } from "../../../../components/PasswordCriteria/PasswordCriteria.constants";
import { StyledTooltip } from "components";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDebounce } from "helpers/hooks";
import { checkEmailService } from "services/account";
import { error } from "utils/notifications";

export const StepOne = ({ formData, loading }) => {
  const { control, errors, setValue, formField, trigger } = formData;

  const [userAlreadyRegistered, setUserAlreadyRegistered] = useState(false);

  const debouncedEmail = useDebounce(formField.email, 500);

  const hasEmailAlreadyRegistered = async (email) => {
    try {
      const { exists } = await checkEmailService(email, {
        isDistributor: true,
      });
      if (exists) {
        setUserAlreadyRegistered(true);
      } else {
        setUserAlreadyRegistered(false);
      }
    } catch (err) {
      if (err.response?.data?.message) error(err.response.data.message);
      // eslint-disable-next-line no-console
      console.error(
        err.response?.data?.message || err.message || "Something went wrong!"
      );
    }
  };

  useEffect(() => {
    if (debouncedEmail && !errors?.businessEmail) {
      hasEmailAlreadyRegistered(debouncedEmail);
    }
    if (!debouncedEmail) setUserAlreadyRegistered(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEmail]);

  return (
    <Box mb="62px">
      <Box
        sx={{
          pt: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "22px",
        }}
      >
        <Controller
          render={({ field }) => (
            <TextField
              disabled
              fullWidth
              InputProps={{
                style: { height: "48px", borderRadius: "8px", fontSize: 18 },
                endAdornment: (
                  <InputAdornment position="end">
                    <ActiveIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Email"
              {...field}
            />
          )}
          name="email"
          control={control}
        />

        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledField
              {...{ control, field, error }}
              onChange={(e) => {
                field.onChange(e);
                if (formField?.confirmPassword) trigger(["confirmPassword"]);
              }}
              label="Password"
              type="password"
            />
          )}
          name="password"
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledField
              {...{ control, field, error }}
              onChange={(e) => {
                field.onChange(e);
                if (formField?.password) trigger(["password"]);
              }}
              label="Confirm password"
              type="password"
            />
          )}
          name="confirmPassword"
          control={control}
        />
      </Box>
      <PasswordCriteria
        password={formField.password}
        confirmPassword={formField.confirmPassword}
      />
      <StyledTooltip
        title={
          <Box>
            <Typography fontSize={12}>
              This email is already registered.
            </Typography>
            <Typography fontSize={12}>
              Click{" "}
              <MuiLink component={Link} color="primary" to="/login">
                here
              </MuiLink>{" "}
              to login.
            </Typography>
          </Box>
        }
        placement="top"
        arrow
        open={userAlreadyRegistered}
      >
        <Box sx={{ mt: "20px" }}>
          <Button
            sx={{
              height: "48px",
              fontSize: 20,
              fontWeight: 500,
            }}
            fullWidth
            variant="contained"
            disabled={
              loading ||
              userAlreadyRegistered ||
              PASSWORD_CRITERIA.some((criteria) => {
                const { password, confirmPassword } = formField;
                const completed = criteria.condition({
                  password,
                  confirmPassword,
                });
                return !completed;
              })
            }
            onClick={() => {
              if (
                formField?.password &&
                !errors?.password &&
                !errors?.confirmPassword
              ) {
                return setValue("step", 2);
              }
            }}
          >
            {loading ? "Loading..." : "Next Step"}
          </Button>
        </Box>
      </StyledTooltip>
    </Box>
  );
};

StepOne.propTypes = {
  formData: shape({
    control: object,
    errors: object,
    setValue: func,
    formField: object,
  }),
  loading: bool,
};
