import { bool, string, array, any, object, oneOfType } from "prop-types";
import { Box } from "@mui/material";
import { StyledTooltip } from "components";
import { ADMIN_ONLY_VIEW_MESSAGE } from "utils/constants";

export const ActionRestrictionWrapper = ({
  disableHoverListener,
  children,
  offset = [0, -6],
  placement = "top",
  tooltipProps,
  childrenWrapper = {},
  title = "",
}) => {
  return (
    <StyledTooltip
      placement={placement}
      disableHoverListener={disableHoverListener}
      disableFocusListener={disableHoverListener}
      arrow
      title={title || ADMIN_ONLY_VIEW_MESSAGE}
      PopperProps={{
        modifiers: [{ name: "offset", options: { offset } }],
      }}
      {...tooltipProps}
    >
      <Box component="span" sx={{ ...childrenWrapper }}>
        {children}
      </Box>
    </StyledTooltip>
  );
};

ActionRestrictionWrapper.propTypes = {
  disableHoverListener: bool,
  ml: string,
  offset: array,
  text: string,
  children: any,
  placement: string,
  tooltipProps: object,
  childrenWrapper: object,
  title: oneOfType([string, object]),
};
