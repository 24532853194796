import { useCallback, useEffect, useMemo, useState } from "react";
import { error, success } from "utils/notifications";
import { useDispatch, useSelector } from "react-redux";
import {
  openConfirmDialogAction,
  openDiscardChanges,
  setConfirmIsOpenAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./TaskDrawer.validations";
import {
  defaultValues,
  PRIORITIES,
  STATUSES,
} from "Pages/CustomersPage/pages/CustomerProfilePage/components/TaskDrawer/TaskDrawer.constants";
import {
  createTaskBulkService,
  createTaskService,
  deleteTaskService,
  updateCompletedTaskService,
  updateTaskService,
} from "services/tasks";
import { CUSTOMER_STATUS_FILTERS, SCROLL_LIMIT } from "utils/constants";
import { getRepsService } from "services/reps";
import { getCustomersService } from "services/customers";
import { useDebounce } from "helpers/hooks";
import moment from "moment-timezone";
import { handleError } from "helpers/helpers";

export const useCustomerTasks = ({
  open,
  taskState,
  onClose,
  refetchCallback,
  isAdvancedDrawer,
  selectedCustomers,
  customer,
  setCheckedCustomers,
  setDialogState,
  resetData = true,
  formChanged,
  trigger,
  formField,
  disabledAddBtn,
  isDirty,
}) => {
  const dispatch = useDispatch();

  const editTask = useMemo(() => taskState, [taskState]);
  const taskId = useMemo(() => taskState?.id, [taskState?.id]);
  const customerId = taskState?.customerId;

  const [loading, setLoading] = useState(false);
  const [repsState, setRepsState] = useState({
    list: [],
    count: 0,
    loading: false,
  });

  const handleFetchReps = useCallback(async () => {
    setRepsState((prev) => ({ ...prev, loading: true }));
    const { list } = repsState;
    const cursor = list[list.length - 1]?.id;
    const fetchQuery = {
      limit: SCROLL_LIMIT,
      roles: JSON.stringify(["sales", "merchandiser"]),
      cursor,
    };
    const { countSales, countMerchandiser, rows } = await getRepsService({
      ...fetchQuery,
    });
    setRepsState((prev) => ({
      ...prev,
      list: cursor ? [...prev.list, ...rows] : rows,
      count: countSales + countMerchandiser,
      loading: false,
    }));
  }, [repsState]);

  useEffect(() => {
    if (open) handleFetchReps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getCustomerIds = useCallback(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!selectedCustomers.length)
      return selectedCustomers.map((customer) => customer.id);
    if (customer?.id) return [customer.id];
    return [];
  }, [selectedCustomers, customer]);

  const handleSaveTask = useCallback(
    async (data, isRepeatedTask = false) => {
      setLoading(true);

      setDialogState((prev) => ({ ...prev, isOpen: false }));
      const {
        title,
        description,
        dueDate,
        priority,
        representative,
        status,
        repeated,
        repeatInterval,
        repeatStep,
        applyForFutureTasks,
        addTime,
        dueTime,
        formIds,
      } = data || {};

      const preparedData = {
        title,
        description,
        priority,
        repeated,
        ...(repeated && {
          repeatInterval: repeatInterval.toUpperCase(),
        }),
        ...(repeated &&
          repeatInterval !== "weekday" && {
            repeatStep: +repeatStep,
          }),
        ...(editTask && isRepeatedTask && { applyForFutureTasks }),
        ...(isAdvancedDrawer
          ? { customerIds: getCustomerIds() }
          : { customerId }),
        formIds,
      };

      if (dueDate) preparedData.dueDate = dueDate;

      if (addTime && dueTime)
        preparedData.dueTime = moment(dueTime).utc().format("HH:mm");

      preparedData.assignToDistributor = representative === "distributor";
      preparedData.representativesIds =
        representative === "distributor" ? undefined : [representative];

      try {
        if (editTask && taskId) {
          preparedData.status = status;
          await updateTaskService({ taskId, data: preparedData });
        } else {
          if (isAdvancedDrawer) {
            await createTaskBulkService({ data: preparedData });
            if (resetData) {
              setCheckedCustomers([]);
            }
            success(
              `${selectedCustomers?.length > 1 ? "Tasks" : "Task"} ${
                editTask ? "updated" : "created"
              } successfully!`
            );
          } else {
            await createTaskService({ data: preparedData });
            success(`Task ${editTask ? "updated" : "created"} successfully!`);
          }
        }

        dispatch(setFormChangedAction(false));
        onClose();
        refetchCallback();
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      customerId,
      dispatch,
      editTask,
      getCustomerIds,
      onClose,
      isAdvancedDrawer,
      refetchCallback,
      selectedCustomers?.length,
      setCheckedCustomers,
      taskId,
    ]
  );

  const preventCloseDrawer = useCallback(
    ({ onClose }) => {
      // eslint-disable-next-line no-console
      if (!onClose) return console.error("onClose is a required parameter");

      if (formChanged) {
        return dispatch(
          openDiscardChanges(
            () => onClose(),
            async () => {
              const resTrigger = await trigger();
              if (resTrigger && !disabledAddBtn && !loading) {
                handleSaveTask(formField);
              } else {
                dispatch(setFormChangedAction(isDirty));
              }
            }
          )
        );
      }

      onClose();
    },
    [
      disabledAddBtn,
      dispatch,
      formChanged,
      formField,
      handleSaveTask,
      isDirty,
      loading,
      trigger,
    ]
  );

  const handleCloseTask = useCallback(() => {
    preventCloseDrawer({ onClose });
  }, [onClose, preventCloseDrawer]);

  const handleUpdateCompletedTask = async (data) => {
    const preparedData = {};
    try {
      if (editTask && taskId) {
        preparedData.status = data;
        await updateCompletedTaskService({ taskId, data: preparedData });
        success(data === STATUSES[1] ? "Task completed" : "Marked incomplete");
      }
      refetchCallback();
    } catch (err) {
      error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmDelete = useCallback(
    async (data, isRepeatedTask = false) => {
      try {
        setLoading(true);
        await deleteTaskService({
          tasksIds: [taskId],
          ...(editTask &&
            isRepeatedTask && {
              applyForFutureTasks: data?.applyForFutureTasks || false,
            }),
        });
        success("Task deleted successfully!");
        dispatch(setConfirmIsOpenAction(false));
        setDialogState((prev) => ({ ...prev, isOpen: false }));
        handleCloseTask();
        refetchCallback();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        error(err?.response?.data?.message);
      }
    },
    [
      dispatch,
      editTask,
      handleCloseTask,
      refetchCallback,
      setDialogState,
      taskId,
    ]
  );

  const handleDeleteTask = async () => {
    dispatch(
      openConfirmDialogAction({
        title: "Delete task?",
        text: "Are you sure you want to delete this task?",
        propBtns: {
          left: { label: "Cancel", color: "cancel", variant: "outlined" },
          right: {
            label: "Confirm",
            color: "error",
            variant: "contained",
            onClick: handleConfirmDelete,
          },
        },
      })
    );
  };

  const handleOnDeleteDrawer = () => {
    setDialogState({ isOpen: true, isDeleting: true });
  };

  const handleOnSaveDrawer = async () => {
    const resTrigger = await trigger();

    if (resTrigger) setDialogState({ isOpen: true, isDeleting: false });
  };

  const handleOnCloseDrawer = () => {
    setDialogState({ isOpen: false, isDeleting: false });
  };

  return {
    handleSaveTask,
    handleConfirmDelete,
    handleCloseTask,
    open,
    repData: taskState?.representativeDuplicate,
    taskData: taskState,
    handleDeleteTask,
    loading,
    taskState,
    editTask,
    repsState,
    handleFetchReps,
    handleUpdateCompletedTask,
    handleOnDeleteDrawer,
    handleOnSaveDrawer,
    handleOnCloseDrawer,
  };
};

export const useTaskAssigne = ({
  isAdvancedDrawer,
  selectedCustomers,
  setCheckedCustomers,
}) => {
  const [customersList, setCustomersList] = useState([]);
  const [checkedTaskCustomers, setCheckedTaskCustomers] =
    useState(selectedCustomers);
  const [customersLoading, setCustomersLoading] = useState(false);
  const [customersSearchInput, setCustomersSearchInput] = useState("");

  const customersSearchInputDebounced = useDebounce(customersSearchInput, 300);

  useEffect(() => {
    if (!isAdvancedDrawer) return;
    if (!customersSearchInputDebounced) return setCustomersList([]);
    setCustomersLoading(true);
    getCustomersService({
      search: customersSearchInputDebounced,
      status: `["${CUSTOMER_STATUS_FILTERS.active}"]`,
    })
      .then((res) => {
        setCustomersLoading(false);
        setCustomersList(res.rows);
      })
      .catch(() => {
        setCustomersLoading(false);
        error("Something went wrong.");
      });
  }, [customersSearchInputDebounced, isAdvancedDrawer]);

  const handleCheckCustomer = (customer, withUpdate = false) => {
    const customerIndex = checkedTaskCustomers.findIndex(
      (checkedCustomer) => checkedCustomer.id === customer.id
    );
    if (customerIndex > -1) {
      const newCustomers = [...checkedTaskCustomers];
      newCustomers.splice(customerIndex, 1);

      if (withUpdate) setCheckedCustomers([...newCustomers]);
      return setCheckedTaskCustomers([...newCustomers]);
    }

    if (withUpdate) setCheckedCustomers([...checkedTaskCustomers, customer]);
    setCheckedTaskCustomers([...checkedTaskCustomers, customer]);
  };

  return {
    customersList,
    customersLoading,
    checkedTaskCustomers,
    customersSearchInput,
    setCustomersList,
    handleCheckCustomer,
    setCustomersSearchInput,
    setCheckedTaskCustomers,
  };
};

export const useFormFields = ({ open, taskState }) => {
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const [isRepeatedTask, setIsRepeatedTask] = useState(false);

  const { timeZone } = currentUser || {};

  const defaultDueTime = useMemo(() => {
    const now = moment().utc();
    return now.hour(9).minute(0).second(0).millisecond(0).utc().toISOString();
  }, []);

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors, dirtyFields, isDirty },
    trigger,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      ...defaultValues,
      dueTime: defaultDueTime,
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  useEffect(() => {
    if (open) {
      const {
        title,
        description,
        dueDate,
        dueTime,
        priority,
        assignedRepresentatives,
        status,
        repeated,
        repeatInterval,
        repeatStep,
        applyForFutureTasks,
        distributorId,
        representativeId,
        assignedDistributorId,
      } = taskState || {};

      const assignedRepresentativeId =
        assignedRepresentatives?.[0]?.representative?.id || "distributor";

      if (repeated) {
        setIsRepeatedTask(true);
      } else {
        setIsRepeatedTask(false);
      }

      const now = moment().utc();

      reset({
        title: title || defaultValues.title,
        description: description || defaultValues.description,
        dueDate: dueDate || defaultValues.dueDate,
        dueTime: dueTime
          ? now
              .hour(dueTime.split(":")?.[0] || "09")
              .minute(dueTime.split(":")?.[1] || "00")
              .utc()
              .toISOString()
          : defaultDueTime,
        addTime: !!dueTime,
        priority: priority || defaultValues.priority,
        representative: assignedRepresentativeId,
        assignToDistributor: assignedRepresentativeId === "distributor",
        status: status || null,
        repeated: repeated || defaultValues.repeated,
        applyForFutureTasks:
          applyForFutureTasks || defaultValues.applyForFutureTasks,
        repeatInterval:
          (repeatInterval && repeatInterval.toLowerCase()) ||
          defaultValues.repeatInterval,
        repeatStep: repeatStep || defaultValues.repeatStep,
        distributorId,
        representativeId,
        assignedDistributorId:
          assignedDistributorId || defaultValues.assignedDistributorId,
        assignedRepresentatives,
      });
    }
  }, [reset, open, taskState, timeZone, defaultDueTime]);

  return {
    formField,
    control,
    handleSubmit,
    PRIORITIES,
    STATUSES,
    isDirty,
    isRepeatedTask,
    setValue,
    setError,
    dirtyFields,
    clearErrors,
    errors,
    trigger,
  };
};
