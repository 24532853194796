import React, { useContext } from "react";

import { EmptyUserBlock } from "../..";
import { CustomersItem } from "./CustomersItem";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";

import { Stack } from "@mui/material";

export const CustomersList = () => {
  const { formField, onCustomersDelete, onEditAllCustomers } =
    useContext(FormViewContext);

  return (
    <Stack>
      {formField._count.assignedCustomers ? (
        <CustomersItem
          isSingleSelect={formField._count.assignedCustomers === 1}
          list={formField.assignedCustomers}
          onEdit={onEditAllCustomers}
          onDelete={onCustomersDelete}
          count={formField._count.assignedCustomers}
        />
      ) : (
        <EmptyUserBlock label="No customers selected" />
      )}
    </Stack>
  );
};
