import { Fragment, useMemo } from "react";
import pluralize from "pluralize";
import { object, bool, func, number, string, array } from "prop-types";

import { CheckboxIconChecked, CrossIcon } from "components/Icons";
import { IndeterminateCheckboxIcon } from "components/Icons/IndeterminateCheckboxIcon";

import { useStyles } from "../styles";

import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { GoBackIcon } from "components/Icons/GoBackIcon";
import { DropDownActionsSelect } from "./DropDownActionsSelect";
import { StyledTooltip } from "components";

export const ActionControlBlock = ({
  loading,
  selectName,
  actionsList,
  onSelectAll,
  checkedCount,
  counterNumber,
  dropDownActions,
  onSelectVisible,
  cancelSelection,
  actionWrapperProps,
  availableSelectCount,
  loadingAllItems,
}) => {
  const classes = useStyles();

  const isChecked = useMemo(
    () => checkedCount === availableSelectCount,
    [availableSelectCount, checkedCount]
  );

  const PANEL_DATA = [
    {
      element: (
        <>
          {!!onSelectVisible && (
            <Box sx={{ width: "21px" }}>
              <FormControlLabel
                sx={{ m: 0 }}
                control={
                  <Checkbox
                    disabled={loading || loadingAllItems}
                    checked={isChecked}
                    indeterminate={!isChecked}
                    onChange={() => {
                      if (loadingAllItems) return;
                      onSelectVisible();
                    }}
                    sx={{ padding: 0, "& svg": { width: 21, height: 21 } }}
                    checkedIcon={
                      <CheckboxIconChecked size={22} color="#FFFFFF30" />
                    }
                    indeterminateIcon={<IndeterminateCheckboxIcon size={21} />}
                  />
                }
                label=""
              />
            </Box>
          )}
        </>
      ),
    },
    {
      element: (
        <Stack direction="row" gap="11px" alignItems="center" ml="13px">
          <Box className={classes.selectedCountWrapper}>
            <Typography>{counterNumber || checkedCount}</Typography>
          </Box>
          {!isChecked && (
            <>
              <Typography fontSize={13} fontWeight={600} color="#ffff">
                SELECTED
              </Typography>
              <Divider orientation="vertical" className={classes.divider} />
            </>
          )}
        </Stack>
      ),
    },
    {
      element: (
        <>
          {!!availableSelectCount && !isChecked ? (
            <Stack
              direction="row"
              gap="11px"
              alignItems="center"
              disabled={loading || loadingAllItems}
            >
              <Typography
                className={classes.selectAllText}
                sx={{
                  cursor: loadingAllItems ? "default !important" : null,
                  textDecoration: loadingAllItems ? "none !important" : null,
                }}
                onClick={(e) => {
                  if (loadingAllItems) return;
                  onSelectAll(e);
                }}
              >
                Select all{" "}
                <span className={classes.textSpan}>{availableSelectCount}</span>{" "}
                {pluralize(selectName, availableSelectCount)}
              </Typography>

              {!!actionsList.length && (
                <Divider orientation="vertical" className={classes.divider} />
              )}
            </Stack>
          ) : (
            <Stack direction="row" gap="3px" alignItems="center">
              <Typography className={classes.actionItemText} mr="6px">
                ALL SELECTED
              </Typography>

              <IconButton
                disableRipple
                onClick={cancelSelection}
                sx={{ width: "30px", "& svg": { width: "16px" } }}
              >
                <GoBackIcon />
              </IconButton>
              {!!actionsList.length && (
                <Divider orientation="vertical" className={classes.divider} />
              )}
            </Stack>
          )}
        </>
      ),
    },
    ...actionsList.filter((el) => el.show),
    {
      element: (
        <Stack>
          {!!dropDownActions.length && (
            <DropDownActionsSelect
              disabled={loadingAllItems}
              dropDownActions={dropDownActions}
            />
          )}
        </Stack>
      ),
    },
    {
      element: (
        <>
          {loading && <CircularProgress size="20px" sx={{ color: "#fff" }} />}
        </>
      ),
    },
    {
      element: (
        <IconButton
          disableRipple
          onClick={cancelSelection}
          className={classes.crossIcon}
        >
          <CrossIcon size="27" fill="#ffff" opacity={1} />
        </IconButton>
      ),
    },
  ];

  return (
    <Stack
      direction="row"
      className={classes.actionWrapper}
      {...actionWrapperProps}
    >
      {PANEL_DATA.map((el, i) => (
        <Fragment key={i}>
          {el.element ? (
            el.element
          ) : (
            <Stack direction="row" gap="11px" alignItems="center">
              <StyledTooltip
                title={el?.tooltip?.title || ""}
                placement="top"
                arrow
                disableHoverListener={
                  !el?.tooltip?.show && !!el?.tooltip?.title
                }
                sx={{ ...el?.tooltip?.sx }}
                {...{ ...(el?.tooltip?.props || {}) }}
              >
                <Box>
                  <Typography
                    onClick={() => {
                      if (loadingAllItems) return;

                      return (
                        !(el.disabled || loading) &&
                        !!el.onClick &&
                        el.onClick()
                      );
                    }}
                    className={classes.actionItemText}
                    sx={{
                      cursor:
                        el.disabled || loadingAllItems ? "auto" : "pointer",
                      opacity: el.disabled || loadingAllItems ? 0.5 : 1,

                      "&:hover": {
                        textDecoration:
                          (el.disabled || loadingAllItems) && "none !important",
                      },
                      ...el?.sx,
                    }}
                  >
                    {el.label}
                  </Typography>
                </Box>
              </StyledTooltip>
              {i !== PANEL_DATA.length - (!dropDownActions.length ? 4 : 3) && (
                <Divider orientation="vertical" className={classes.divider} />
              )}
            </Stack>
          )}
        </Fragment>
      ))}
    </Stack>
  );
};

ActionControlBlock.propTypes = {
  loading: bool,
  onSelectAll: func,
  onSelectVisible: func,
  selectName: string,
  actionsList: array,
  checkedCount: number,
  dropDownActions: array,
  hasCheckedItems: bool,
  counterNumber: number,
  cancelSelection: func,
  headerComponent: object,
  actionWrapperProps: object,
  availableSelectCount: number,
  loadingAllItems: bool,
};

ActionControlBlock.defaultProps = {
  loading: false,
  selectName: "",
  actionsList: [],
  checkedCount: 0,
  counterNumber: 0,
  dropDownActions: [],
  onSelectAll: () => {},
  onSelectVisible: () => {},
  hasCheckedItems: false,
  actionWrapperProps: {},
  availableSelectCount: 0,
  cancelSelection: () => {},
  loadingAllItems: false,
};
