import { yupResolver } from "@hookform/resolvers/yup";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  createOrderAction,
  updateOrderAction,
} from "../../redux/actions/orders";
import {
  getDiscountsDictionary,
  getMSDDiscountSum,
} from "../DiscountsPage/components/ApplyDiscountPage/components/MSDPage/MSDPage.helpers";
import {
  CART_TYPES,
  CREATED_ORDER_TYPES,
  defaultValues,
  DEFAULT_ORDER_DATA,
  DISCOUNT_NAMES,
  DISCOUNT_TYPES,
} from "./CartPage.constants";
import { validationSchema } from "./CartPage.validations";
import { getManufacturersWithDiscountsService } from "services/manufacturers-discounts";
import {
  createDraftAction,
  deleteDraftAction,
  getDrafts,
  updateDraftAction,
} from "redux/actions/drafts";
import { getPaymentIntentLinkService } from "services/orders";
import { error, success } from "utils/notifications";
import {
  getProductsLastOrdersInfoService,
  getProductsService,
} from "services/products";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import {
  createDraftOrderService,
  createOrderFromDraftService,
  updateDraftOrderService,
} from "services/draft_orders";
import { DATA_KEYS } from "utils/constants";
import { getPriceListsProductsByCustomerIdService } from "services/priceLists";
import { getCalcProductPriceByPriceList } from "helpers/helpers";

const DEFAULT_PRICE_LIST_STATE = {
  priceList: {},
  priceListName: "",
  loading: false,
  priceListId: null,
};

export const useCart = ({
  isDuplicate,
  isDraftOrder,
  isDuplicateDraft,
  paymentStatus,
  setEmailLinkDialogData,
  setIsButtonDisabled,
  currentUser,
}) => {
  const ordersCurrentTab = useSelector(({ orders }) => orders.currentTab);
  const draft = useSelector(({ drafts }) => drafts.cart);

  const [manualDeliveryFee, setManualDeliveryFee] = useState(false);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [initCustomerId, setInitCustomerId] = useState("");

  const [avoidUpd, setAvoidUpd] = useState(false);

  const [cartState, setCartState] = useState({
    availableMSDs: [],
    availableMSDCount: 0,
    loading: true,
  });

  const { orderId } = useParams();

  const getCartType = () => {
    if (isDraftOrder) return CART_TYPES.draft;
    if (isDuplicateDraft) return CART_TYPES.duplicate_draft;
    if (isDuplicate) return CART_TYPES.duplicate;
    if (orderId) return CART_TYPES.edit;
    return CART_TYPES.order;
  };
  const cartType = getCartType();

  const setCreateOrderType = () => {
    const isCartClear = isEqual(draft?.data, DEFAULT_ORDER_DATA);

    if (isCartClear && ordersCurrentTab && ordersCurrentTab === "3rd Party")
      return "THIRD_PARTY";

    if (draft?.data?.createOrderType) return draft?.data?.createOrderType;

    return defaultValues.createOrderType;
  };

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { errors, isDirty },
    clearErrors,
    trigger,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      ...defaultValues,
      createOrderType: setCreateOrderType(),
    },
    resolver: yupResolver(validationSchema(currentUser)),
  });

  const formField = useWatch({ control });

  const [freeCasesState, setFreeCasesState] = useState({
    loading: false,
    open: false,
    selectedProductsIds: [],
    isDelStatus: false,
  });

  const [priceListState, setPriceListState] = useState(
    DEFAULT_PRICE_LIST_STATE
  );

  const setPriceListLoading = (loading) =>
    setPriceListState((prev) => ({ ...prev, loading }));

  const getPriceInfoById = useCallback(async () => {
    if (!formField.customer.id) return;

    setPriceListLoading(true);
    try {
      const res = await getPriceListsProductsByCustomerIdService(
        formField.customer.id
      );

      if (!res.rows.length)
        return setPriceListState((prev) => ({ ...prev, priceList: {} }));

      const result = res?.rows.length
        ? res?.rows?.reduce((acc, item) => {
            acc[item?.product?.id] = { ...item };
            return acc;
          }, {})
        : {};

      setPriceListState((prev) => ({
        ...prev,
        priceList: result,
        priceListId: res.priceList?.id,
        priceListName: res.priceList?.name,
      }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setPriceListLoading(false);
    }
  }, [formField?.customer?.id]);

  useEffect(() => {
    if (!formField?.customer?.id && priceListState.priceListId) {
      setPriceListState(DEFAULT_PRICE_LIST_STATE);
    }

    getPriceInfoById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formField?.customer?.id]);

  const calcProductPriceByPriceList = useCallback(
    ({ product, calcTotal = false }) =>
      getCalcProductPriceByPriceList({
        product,
        calcTotal,
        priceList: priceListState.priceList,
      }),
    [priceListState.priceList]
  );

  const handleOpenFreeCasesDialog = () => {
    setFreeCasesState((prev) => ({ ...prev, open: true }));
  };

  useEffect(() => {
    if (
      formField.createOrderType === "THIRD_PARTY" &&
      draft?.lastAssignedThirdPartyRepresentativeId &&
      formField.salesId !== draft?.lastAssignedThirdPartyRepresentativeId
    ) {
      setValue("salesId", draft?.lastAssignedThirdPartyRepresentativeId, {
        shouldDirty: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft?.lastAssignedThirdPartyRepresentativeId, setValue]);

  const discountsWithQuantity = useMemo(
    () =>
      formField?.manufacturerDiscounts?.filter((d) => d.type === "QUANTITY"),
    [formField?.manufacturerDiscounts]
  );

  const {
    handleGetFreeCaseProducts,
    freeProducts,
    loading: freeCasesLoading,
    setFreeProducts,
  } = useFreeCases({
    msdList: discountsWithQuantity || [],
    freeCaseIds: formField.freeCasesProductsIds,
    handleOpenFreeCasesDialog,
  });

  const cartProductsWithFreeCaseProducts = useMemo(() => {
    const chosenFreeProducts = freeProducts.filter(
      ({ freeCaseChosen, id: freeId, productId }) => {
        const isFromCart = formField.products.some(
          ({ id }) => id === freeId || id === productId
        );

        return !!freeCaseChosen && !isFromCart;
      }
    );
    return [...formField.products, ...chosenFreeProducts];
  }, [formField.products, freeProducts]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const calculatedTotal = [...cartProductsWithFreeCaseProducts]?.reduce(
    (prevSum, currProd) =>
      prevSum +
      calcProductPriceByPriceList({ product: currProd }) *
        ((currProd.quantity || 0) + (currProd.discountQty || 0)),
    0
  );

  const discountMSD = getMSDDiscountSum(
    cartProductsWithFreeCaseProducts,
    formField.manufacturerDiscounts,
    null,
    freeProducts
  );

  const totalOrderDiscount = useMemo(() => {
    const totalDisc = formField.totalOrderDiscountAmount;
    const totalType = formField.totalOrderDiscountType;
    if (totalDisc)
      return totalType === "PERCENTAGE"
        ? (calculatedTotal * totalDisc) / 100
        : totalDisc;
    return 0;
  }, [
    calculatedTotal,
    formField.totalOrderDiscountAmount,
    formField.totalOrderDiscountType,
  ]);

  const calculatedItemsDiscount = formField.products?.reduce(
    (prevSum, currProd) => {
      const discountAmount = parseFloat(currProd.itemDiscountAmount) || 0;
      const price =
        calcProductPriceByPriceList({ product: currProd }) * currProd.quantity;
      const disc =
        currProd.itemDiscountType === "PERCENTAGE"
          ? (discountAmount / 100) * price
          : discountAmount;

      return prevSum + disc;
    },
    0
  );

  const appliedOrderDiscount = useMemo(
    () => totalOrderDiscount || calculatedItemsDiscount,
    [calculatedItemsDiscount, totalOrderDiscount]
  );

  const handleResetCart = useCallback(
    ({ isEdit = false, orderData = {} }) => {
      setAvoidUpd(true);
      if (isDuplicate) navigate("/cart");
      reset({ ...defaultValues });
      dispatch(getDrafts(null));

      if (isEdit && draft?.id && !isDuplicate) {
        return dispatch(
          updateDraftAction({
            id: draft?.id,
            data: { data: { ...DEFAULT_ORDER_DATA, ...orderData } },
          })
        );
      }

      if (!isDuplicate) {
        dispatch(
          createDraftAction(
            {
              data: { ...DEFAULT_ORDER_DATA, ...orderData },
            },
            () => setAvoidUpd(false)
          )
        );
      }
    },
    [dispatch, draft?.id, isDuplicate, navigate, reset]
  );

  const getPaymentIntentLink = useCallback(
    async (orderId, orderType, customId) => {
      try {
        if (orderId) {
          const res = await getPaymentIntentLinkService(orderId);
          const { paymentIntentLink } = res || {};
          if (!paymentIntentLink)
            return error("A payment link is not available.");
          setEmailLinkDialogData({ paymentIntentLink, orderType, customId });
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      }
    },
    [setEmailLinkDialogData]
  );

  const handleCreateDraftOrder = useCallback(
    async (data) => {
      const uploadData = { ...data };

      try {
        uploadData.orderType = data.type;
        delete uploadData.type;
        delete uploadData.paymentTermsDuplicate;
        const res = await createDraftOrderService(uploadData);

        dispatch(setFormChangedAction(false));

        const {
          customId: { customId = "" },
          id: orderId,
        } = res;

        if (customId) {
          cartType === CART_TYPES.duplicate_draft
            ? success(`Draft order duplicated`)
            : success(`Draft ${customId} created`);
        }

        setAvoidUpd(true);

        cartType === CART_TYPES.duplicate_draft
          ? navigate(`/orders/drafts/${orderId}`)
          : navigate("/orders", { state: "Drafts" });

        reset({ ...defaultValues });
        dispatch(getDrafts(null));

        dispatch(
          createDraftAction(
            {
              data: {
                createOrderType: "DIRECT",
                customer: "",
                deliveryFee: 0,
                discount: null,
                freeCasesProductsIds: null,
                fulfillBy: "",
                manualDeliveryFee: false,
                manufacturerDiscounts: [],
                note: "",
                orderTags: [],
                paymentTerms: {},
                productsData: [],
                tagIds: [],
                totalOrderDiscountAmount: 0,
                totalOrderDiscountType: "PERCENTAGE",
              },
            },
            () => setAvoidUpd(false)
          )
        );
        dispatch(deleteDraftAction());
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      }

      setIsButtonDisabled(false);
    },
    [cartType, dispatch, navigate, reset, setIsButtonDisabled]
  );

  const handleUpdateDraftOrder = useCallback(
    async ({ id, data, onSuccess }) => {
      try {
        const res = await updateDraftOrderService({ id, data });
        dispatch(setFormChangedAction(false));
        onSuccess && onSuccess();
        return res;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      }
    },
    [dispatch]
  );

  const handleCreateOrderViaDraft = useCallback(async ({ data, onSuccess }) => {
    try {
      const res = await createOrderFromDraftService(data);

      if (onSuccess) onSuccess(res);

      return res;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    }
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      const defaultContact = data.customer?.contacts?.find(
        (contact) => contact.defaultContact
      )?.id;
      const contactId = Number(data.contactId || defaultContact);

      const chosenFreeProducts = freeProducts.filter(
        ({ freeCaseChosen, id: freeId, productId }) =>
          !!freeCaseChosen &&
          !data.products.some(({ id }) => id === freeId || id === productId)
      );
      const dict = getDiscountsDictionary({
        productsList: [...data.products, ...chosenFreeProducts],
        checkedDiscounts: data.manufacturerDiscounts,
        isEdit: !!orderId,
      });

      if (orderId && !isDuplicate) {
        const freeCaseProducts = [...data.products, ...chosenFreeProducts].map(
          (freeProduct) => {
            const manufacturerDiscountData =
              dict[freeProduct.id]
                ?.filter((discount) =>
                  discount.type === "QUANTITY"
                    ? !!freeProduct.discountQty
                    : discount
                )
                ?.map((discount) => ({
                  id: discount.id,
                  quantity:
                    discount.type === "QUANTITY"
                      ? freeProduct.discountQty
                      : undefined,
                })) || [];

            return {
              id: freeProduct.isNewAdded
                ? freeProduct.id
                : freeProduct.productId || freeProduct.id,
              quantity:
                (freeProduct.quantity || 0) + (freeProduct.discountQty || 0),
              manufacturerDiscountData,
              itemDiscountAmount: freeProduct.itemDiscountAmount || 0,
              itemDiscountType: freeProduct.itemDiscountType || "PERCENTAGE",
              productId: freeProduct.product?.id,
              isNewAdded: freeProduct.isNewAdded,
            };
          }
        );

        const oldProducts = freeCaseProducts
          .filter((d) => !d.isNewAdded)
          .map(({ productId, isNewAdded, ...p }) => p);
        const newProducts = freeCaseProducts
          .filter((d) => d.isNewAdded)
          .map(({ productId, isNewAdded, ...p }) => p);

        const manufacturerDiscountIds = data.manufacturerDiscounts
          .filter((m) => !m.manufacturerDiscountId)
          .map((m) => m.id);
        const manufacturerDiscountDuplicateIds = data.manufacturerDiscounts
          .filter((m) => m.manufacturerDiscountId)
          .map((m) => m.id);

        const isoFulfillBy =
          data.fulfillBy && new Date(data.fulfillBy).toISOString();

        const preparedData = {
          salesId: data?.salesId === "no_rep" ? null : data?.salesId || null,
          contactId,
          products: oldProducts,
          newProducts,
          manufacturerDiscountDuplicateIds:
            manufacturerDiscountDuplicateIds.filter((id) =>
              freeCaseProducts.some(({ manufacturerDiscountData }) =>
                manufacturerDiscountData.some((item) => item.id === id)
              )
            ),
          totalOrderDiscountAmount:
            data.discount?.type === "total"
              ? data.totalOrderDiscountAmount || 0
              : 0,
          totalOrderDiscountType: data.totalOrderDiscountType,
          appliedDiscountsType:
            data.discount && (appliedOrderDiscount || discountMSD) !== 0
              ? DISCOUNT_TYPES[data.discount.type]
              : "NONE",
          manufacturerDiscountIds: [...manufacturerDiscountIds].filter((id) =>
            freeCaseProducts.some(({ manufacturerDiscountData }) =>
              manufacturerDiscountData.some((item) => item.id === id)
            )
          ),
          note: { text: data.note ? data.note : "" },
          manualDeliveryFee,
          deliveryFee: manualDeliveryFee ? Number(deliveryFee) : undefined,
          email: data.email,
          paymentTermsId: data?.paymentTerms?.paymentTermsId,
        };

        if (data.createOrderType === "DIRECT") {
          preparedData.fulfillBy = isoFulfillBy || undefined;
        }

        if (initCustomerId !== data?.customer?.id)
          preparedData.customerId = data?.customer?.id;

        if (!preparedData.paymentTermsId) delete preparedData.paymentTermsId;

        const tagIds = [];
        const newTags = [];

        data?.orderTags.forEach((tag) => {
          return tag?.newTag
            ? newTags.push(tag?.tag)
            : tagIds.push(tag?.id || tag?.tag?.id);
        });

        preparedData.tagIds = tagIds;
        if (newTags?.length) preparedData.newTags = newTags;

        if (data?.createType === CREATED_ORDER_TYPES[3]) {
          handleUpdateDraftOrder({
            id: orderId,
            data: preparedData,
            onSuccess: () => {
              success("Draft updated successfully.");
              navigate(`/orders/drafts/${orderId}`);
              setIsButtonDisabled(false);
            },
          });
          return;
        }

        if (cartType === CART_TYPES.draft) {
          const email = preparedData.email;
          delete preparedData.email;

          const res = await handleUpdateDraftOrder({
            id: orderId,
            data: preparedData,
            onSuccess: () => {
              success("Draft updated successfully.");
            },
          });

          if (!res?.id) return;

          await handleCreateOrderViaDraft({
            data: { draftOrderId: res.id, email },
            onSuccess: () => {
              if (data?.createType === CREATED_ORDER_TYPES[0]) {
                navigate("/cart");
              } else {
                navigate("/orders");
              }

              success("Order created successfully.");
              setIsButtonDisabled(false);
            },
          });
          return;
        }

        dispatch(
          updateOrderAction({
            id: orderId,
            data: preparedData,
            navigate: CREATED_ORDER_TYPES.includes(data?.createType)
              ? null
              : navigate,
            onSuccess: (order) => {
              if (data?.createType === CREATED_ORDER_TYPES[0]) {
                handleResetCart({ isEdit: true });
                navigate("/cart");
              }
              if (data?.createType === CREATED_ORDER_TYPES[1]) {
                getPaymentIntentLink(
                  order?.id,
                  order?.type,
                  order?.customId?.customId
                );
              }
              setIsButtonDisabled(false);
            },
          })
        );
        return;
      }

      const freeCaseProducts = [...data.products, ...chosenFreeProducts].map(
        (freeProduct) => {
          const manufacturerDiscountData =
            dict[freeProduct.id]
              ?.filter((discount) =>
                discount.type === "QUANTITY"
                  ? !!freeProduct.discountQty
                  : discount
              )
              .map((discount) => ({
                id: discount.id,
                quantity:
                  discount.type === "QUANTITY"
                    ? freeProduct.discountQty
                    : undefined,
              })) || [];

          return {
            id: freeProduct.id,
            quantity:
              (freeProduct.quantity || 0) + (freeProduct.discountQty || 0),
            manufacturerDiscountData,
            itemDiscountAmount: freeProduct?.itemDiscountAmount || 0,
            itemDiscountType: freeProduct?.itemDiscountType || "PERCENTAGE",
            deleted: freeProduct?.deleted || false,
          };
        }
      );
      const {
        customer,
        manufacturerDiscounts,
        manufacturerDiscountDuplicates,
        discount,
        productsData,
        createOrderType,
        paymentTerms,
        customId,
        productsWithFreeCases,
        freeCasesProductsIds,
        tagIds,
        orderTags,
        createType,
        hasProceeded,
        fulfillBy,
        lastAssignedThirdPartyRepresentativeId,
        ...uploadData
      } = {
        ...data,
        salesId: data.salesId === "no_rep" ? null : data.salesId || null,
        customerId: data.customer?.id,
        products: freeCaseProducts
          .filter((p) => !p.deleted)
          .map(({ deleted, isNewAdded, ...p }) => p),
        manufacturerDiscountIds: data.manufacturerDiscounts
          .map((discount) => discount.id)
          .filter((id) =>
            freeCaseProducts.some(({ manufacturerDiscountData }) =>
              manufacturerDiscountData.some((item) => item.id === id)
            )
          ),
        appliedDiscountsType:
          data.discount && (appliedOrderDiscount || discountMSD) !== 0
            ? DISCOUNT_TYPES[data.discount.type]
            : "NONE",
        note: { text: data.note },
        orderProducts: freeCaseProducts
          .filter((p) => p.deleted)
          .map(({ deleted, ...p }) => p),
        manualDeliveryFee,
        deliveryFee: manualDeliveryFee ? Number(deliveryFee) : undefined,
        contactId,
        type: data?.createOrderType,
        draftData: {
          id: draft?.id,
          type: isDuplicate ? "duplicate" : "create",
        },
      };

      if (createOrderType === "DIRECT") {
        uploadData.fulfillBy = fulfillBy || undefined;
      }

      if (uploadData.createdAt) delete uploadData.createdAt;
      if (uploadData.totalRawAmount) delete uploadData.totalRawAmount;

      if (
        data?.paymentTerms?.name !== data?.customer?.paymentTermsDuplicate?.name
      )
        uploadData.paymentTermsId = data?.paymentTerms?.id;

      if (uploadData.paymentTermsDuplicate)
        delete uploadData.paymentTermsDuplicate;

      if (data?.tagIds?.length) {
        const tagIds = [];
        const newTags = [];

        data?.orderTags.forEach((tag) => {
          return tag?.newTag
            ? newTags.push(tag?.tag)
            : tagIds.push(tag?.id || tag?.tag?.id);
        });

        if (tagIds?.length) uploadData.tagIds = tagIds;
        if (newTags?.length) uploadData.newTags = newTags;
      }

      if (cartType === CART_TYPES.duplicate_draft) {
        uploadData.draftData = {
          id: uploadData?.draftData?.id,
          type: "create",
        };
      }

      if (
        data?.createType === CREATED_ORDER_TYPES[2] ||
        cartType === CART_TYPES.duplicate_draft
      ) {
        setAvoidUpd(true);
        handleCreateDraftOrder(uploadData);
        return;
      }

      delete uploadData.paymentTermsDuplicate;

      if ([CART_TYPES.duplicate_draft, CART_TYPES.draft].includes(cartType))
        delete uploadData.draftData;

      localStorage.setItem(
        DATA_KEYS.DRAFT_ACTIONS,
        JSON.stringify({ draftId: null, data: {} })
      );

      dispatch(
        createOrderAction({
          data: uploadData,
          navigate: CREATED_ORDER_TYPES.includes(data?.createType)
            ? null
            : navigate,
          deleteDraft: !isDuplicate,
          onSuccess: (order) => {
            if (data?.createType === CREATED_ORDER_TYPES[0]) {
              handleResetCart({});
            }
            if (data?.createType === CREATED_ORDER_TYPES[1]) {
              handleResetCart({});
              getPaymentIntentLink(
                order?.id,
                order?.type,
                order?.customId?.customId
              );
            }
            setIsButtonDisabled(false);
          },
        })
      );
    },
    [
      freeProducts,
      orderId,
      isDuplicate,
      appliedOrderDiscount,
      discountMSD,
      manualDeliveryFee,
      deliveryFee,
      draft?.id,
      cartType,
      dispatch,
      navigate,
      initCustomerId,
      handleUpdateDraftOrder,
      setIsButtonDisabled,
      handleCreateOrderViaDraft,
      handleResetCart,
      getPaymentIntentLink,
      handleCreateDraftOrder,
    ]
  );

  const handleCloseFreeCasesDialog = () => {
    setFreeCasesState((prev) => ({ ...prev, open: false }));
  };

  const setDelManufacturerDiscounts = () => {
    setFreeCasesState((prev) => ({ ...prev, isDelStatus: true }));
  };

  useEffect(() => {
    if (
      [
        ...formField?.manufacturerDiscountIds,
        ...formField?.manufacturerDiscounts,
      ]?.some((d) => d?.type === "QUANTITY" && !orderId) &&
      !freeCasesState.isDelStatus
    ) {
      // handleOpenFreeCasesDialog();
      if (freeCasesState.isDelStatus)
        setFreeCasesState((prev) => ({ ...prev, isDelStatus: false }));
    }
  }, [
    formField?.manufacturerDiscountIds,
    formField?.manufacturerDiscounts,
    freeCasesState.isDelStatus,
    orderId,
  ]);

  const checkAvailableMSDs = useCallback(async () => {
    const productsWithManufacturers = formField.products
      // filter out deleted products. Don't check manufacturers for them
      .filter((p) =>
        !isDraftOrder && orderId && !isDuplicate
          ? !!p.product || p.isNewAdded
          : p
      )
      .filter((p) =>
        !!orderId &&
        !isDuplicate &&
        !isDraftOrder &&
        paymentStatus !== "PENDING"
          ? p.isNewAdded
          : p
      )
      .map((prod) => {
        if (prod.manufacturer || prod.parentProduct?.manufacturer) return prod;
        return {
          ...prod,
          manufacturer: {
            id: prod.manufacturerId,
            name: prod.manufacturerName,
          },
        };
      });
    const manufacturers = [
      ...new Map(
        productsWithManufacturers.map((item) => [
          item.manufacturer?.id ||
            item.parentProduct?.manufacturer?.id ||
            item.manufacturerId,
          item.manufacturer ||
            item.parentProduct?.manufacturer ||
            item.manufacturerId,
        ])
      ).values(),
    ];
    const manufacturerIds = manufacturers
      .filter((man) => man?.id)
      .map((m) => m.id);
    const product_ids = formField.products?.map((p) =>
      orderId && !isDuplicate ? p?.product?.id : p?.id
    );

    if (!manufacturerIds.length || !product_ids.length) return;

    const params = {
      manufacturer_ids: `[${manufacturerIds}]`,
      customer_id: formField.customer?.id,
      //discount_limit: SCROLL_LIMIT_DISCOUNT,
      product_ids: JSON.stringify(product_ids.filter((id) => id)),
      status: `["active", "inactive_for_customers"]`,
      available_by_date: true,
    };

    const { rows, count } = await getManufacturersWithDiscountsService({
      ...params,
    });
    setCartState((prev) => ({
      ...prev,
      availableMSDs: rows,
      availableMSDCount: count,
    }));
  }, [
    isDraftOrder,
    formField.customer?.id,
    formField.products,
    isDuplicate,
    orderId,
    paymentStatus,
  ]);

  useEffect(() => {
    checkAvailableMSDs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formField.customer?.id,
    formField.products?.length,
    isDuplicate,
    orderId,
    paymentStatus,
  ]);

  const getAvailableDiscounts = (prod) => {
    if (!prod) return [];
    const dict = getDiscountsDictionary({
      productsList: [prod],
      checkedDiscounts: cartState.availableMSDs
        .map(({ discounts }) => discounts)
        .flat(),
    });

    return dict?.[prod.id] || [];
  };

  const freeCasesCount = freeProducts.reduce(
    (count, currProd) => count + (currProd.discountQty || 0),
    0
  );

  const handleAddMSD = useCallback(
    (discount) => {
      const type = "manufacturer";
      const name = DISCOUNT_NAMES[type];

      setValue("manufacturerDiscounts", [
        ...formField.manufacturerDiscounts,
        discount,
      ]);
      setValue("discount", { type, name });
    },
    [formField.manufacturerDiscounts, setValue]
  );

  const setLoading = useCallback((loading) => {
    setCartState((prev) => ({ ...prev, loading }));
  }, []);

  const handleProspectWarning = useCallback(
    ({ onProceed, onCancel }) => {
      dispatch(
        openConfirmDialogAction({
          title: "Turn prospect into active customer?",
          text: "Do you want to continue creating order for selected prospect? This will turn the prospect into active customer.",
          propBtns: {
            left: {
              label: "Cancel",
              color: "cancel",
              variant: "outlined",
              sx: { height: "28px", minWidth: "78px" },
              onClick: () => {
                dispatch(setConfirmIsOpenAction(false));
                onCancel();
              },
            },
            right: {
              label: "Proceed",
              color: "primary",
              variant: "contained",
              onClick: () => {
                dispatch(setConfirmIsOpenAction(false));
                onProceed();
              },
              sx: { height: "28px", minWidth: "78px" },
            },
          },
        })
      );
    },
    [dispatch]
  );

  return {
    cartType,
    onSubmit,
    orderId,
    formField,
    reset,
    isDirty,
    control,
    clearErrors,
    setValue,
    handleSubmit,
    setError,
    trigger,
    errors,
    manualDeliveryFee,
    setManualDeliveryFee,
    deliveryFee,
    setDeliveryFee,
    appliedOrderDiscount,
    calculatedTotal,
    totalOrderDiscount,
    setInitCustomerId,
    getAvailableDiscounts,
    cartState,
    freeCasesCount,
    freeCasesState: {
      ...freeCasesState,
      handleOpenFreeCasesDialog,
      handleCloseFreeCasesDialog,
      setDelManufacturerDiscounts,
    },
    handleAddMSD,
    avoidUpd,
    handleGetFreeCaseProducts,
    freeProducts,
    setFreeProducts,
    freeCasesLoading,
    cartProductsWithFreeCaseProducts,
    discountsWithQuantity,
    setLoading,
    handleProspectWarning,
    handleResetCart,
    priceListId: priceListState.priceListId,
    calcProductPriceByPriceList,
    priceList: priceListState.priceList,
    priceListLoading: priceListState.loading,
    priceListName: priceListState.priceListName,
  };
};

export const useFreeCases = ({ msdList, freeCaseIds }) => {
  const initialState = {
    loading: false,
    freeProducts: [],
  };

  const msdRef = useRef(null);

  const [productsState, setProductsState] = useState(initialState);

  const getFlatProducts = useCallback((products) => {
    if (products.length) {
      const productVariations = products
        .map((item) =>
          item.childProducts?.map((i) => ({
            ...i,
            name: item.name,
            manufacturer: item.manufacturer,
          }))
        )
        .flat();

      const singleProducts = products.filter(
        (item) => item.childProducts?.length === 0
      );

      return [...productVariations, ...singleProducts];
    }
    return [];
  }, []);

  const handleGetFreeCaseProducts = useCallback(async () => {
    const ids = msdList.map(
      ({ manufacturerDiscountId, id }) => manufacturerDiscountId || id
    );
    if (isEqual(ids, msdRef.current) || !ids?.length) return;
    msdRef.current = ids;
    setProductsState((prev) => ({ ...prev, loading: true }));

    try {
      const { rows } = await getProductsService({
        manufacturer_discount_available: true,
        manufacturer_discount_ids: JSON.stringify(ids),
        status: JSON.stringify(["ACTIVE", "INACTIVE_FOR_CUSTOMERS"]),
      });

      const filterData = getFlatProducts(rows);

      const withFreeCases = filterData.map((p) => {
        const foundInDraft = freeCaseIds?.find((item) => item?.id === p.id);
        if (foundInDraft) {
          return {
            ...p,
            freeCaseChosen: true,
            discountQty: foundInDraft?.discountQty || 0,
            productId: foundInDraft?.productId,
          };
        }
        return p;
      });
      setProductsState((prev) => ({ ...prev, freeProducts: withFreeCases }));
      return filterData;
    } catch (err) {
      error(err?.response?.data?.message);
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setProductsState((prev) => ({ ...prev, loading: false }));
    }
  }, [freeCaseIds, getFlatProducts, msdList]);

  const setFreeProducts = useCallback((newList) => {
    setProductsState((prev) => ({ ...prev, freeProducts: newList }));
  }, []);

  useEffect(() => {
    if (freeCaseIds?.length > 0) handleGetFreeCaseProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeCaseIds]);

  return {
    handleGetFreeCaseProducts,
    freeProducts: productsState.freeProducts,
    loading: productsState.loading,
    setFreeProducts,
  };
};

export const useProductsLastOrders = ({ productIds }) => {
  const initState = useMemo(
    () => ({
      loading: false,
      data: {},
    }),
    []
  );

  const [lastProductsOrders, setLastProductsOrders] = useState(initState);

  const fetchLastProductsOrders = useCallback(async (ids) => {
    try {
      setLastProductsOrders((prev) => ({ ...prev, loading: true }));

      const res = await getProductsLastOrdersInfoService({
        ids: JSON.stringify(ids),
      });

      const preparedData = res?.reduce((acc, item) => {
        return { ...acc, [item.id]: item };
      }, {});

      setLastProductsOrders((prev) => ({
        ...prev,
        data: { ...prev.data, ...preparedData },
      }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message);
    } finally {
      setLastProductsOrders((prev) => ({ ...prev, loading: false }));
    }
  }, []);

  const resetLastProductsOrders = useCallback(() => {
    setLastProductsOrders(initState);
  }, [initState]);

  useEffect(() => {
    const newIds = [];
    const currentIds = Object.keys(lastProductsOrders.data);

    if (productIds.length > 0) {
      productIds.forEach((id) => {
        if (lastProductsOrders.data[id] === undefined) newIds.push(id);
      });
    }

    const diffIdsArr = [];

    if (newIds.length > 0) {
      newIds.forEach((id) => {
        if (!currentIds.some((item) => item === id)) {
          diffIdsArr.push(id);
        }
      });
    }

    if (newIds.length > 0 && diffIdsArr.length > 0) {
      fetchLastProductsOrders(newIds);
    }
  }, [fetchLastProductsOrders, lastProductsOrders.data, productIds]);

  return { lastProductsOrders, resetLastProductsOrders };
};
