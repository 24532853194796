import { useContext } from "react";

import { StyledTabs } from "components";

import { cl } from "./TabsBlock.styles";
import { ProductPriceContext } from "Pages/ProductPricePage";

export const TabsBlock = () => {
  const { tabs, currentTab, setCurrentTab } = useContext(ProductPriceContext);

  return (
    <>
      {tabs && (
        <StyledTabs
          value={currentTab}
          tabs={tabs}
          tabProps={{ sx: cl.tabsPropsSx }}
          sx={cl.tabs}
          onChange={(e, newVal) => {
            setCurrentTab(newVal);
          }}
        />
      )}
    </>
  );
};
