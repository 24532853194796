import { useContext } from "react";

import { AddContentButton } from "..";
import { CustomersList } from "./components/CustomersList";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";

import { Stack, Typography } from "@mui/material";

export const CustomersBlock = () => {
  const { onChangeAllCustomers } = useContext(FormViewContext);

  return (
    <Stack gap="14px" width="100%" px="24px">
      <Typography fontSize="14px" fontWeight={500} color="#595959">
        Connected to customers
      </Typography>

      <CustomersList />
      <AddContentButton
        label="Add customers"
        onClick={() => onChangeAllCustomers(true)}
      />
    </Stack>
  );
};
