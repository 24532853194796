export const TABS_PAGE = [
  {
    value: 0,
    label: "Tasks",
    disabled: false,
    showTooltip: false,
    tooltipText: "",
  },
  {
    value: 1,
    label: "Forms",
    disabled: false,
    showTooltip: false,
    tooltipText: "",
  },
];

export const PRIORITIES = {
  low: "LOW",
  medium: "MEDIUM",
  high: "HIGH",
};

export const STATUSES_TASKS = {
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
};
