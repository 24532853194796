import { useEffect, useState } from "react";
import { string, object, func, bool } from "prop-types";
import { Box, CircularProgress } from "@mui/material";

const ImageBox = (
  {
    src,
    placeholderSrc,
    alt,
    sx,
    setLoadingPhoto,
    loading = false,
    showLoadingState = false,
  },
  props
) => {
  const [imageSrc, setImageSrc] = useState(placeholderSrc);

  const cl = `progressive-${
    imageSrc === placeholderSrc ? "loading" : "loaded"
  }`;

  useEffect(() => {
    if (setLoadingPhoto && cl === "progressive-loading") setLoadingPhoto(true);
  }, [cl, setLoadingPhoto]);

  useEffect(() => {
    if (!loading && showLoadingState) setLoadingPhoto && setLoadingPhoto(true);
    const img = new Image();
    img.src = src;
    img.onload = () => {
      if (setLoadingPhoto && showLoadingState)
        setLoadingPhoto && setLoadingPhoto(false);
      setImageSrc(src);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoadingPhoto, src]);

  return (
    <>
      {loading && showLoadingState ? (
        <Box
          sx={{
            ...sx,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={28} />
        </Box>
      ) : (
        <Box
          sx={sx}
          className={cl}
          component="img"
          src={imageSrc}
          alt={alt}
          {...props}
        />
      )}
    </>
  );
};
ImageBox.propTypes = {
  src: string,
  placeholderSrc: string,
  alt: string,
  sx: object,
  loading: bool,
  showLoadingState: bool,
  setLoadingPhoto: func,
};

export default ImageBox;
