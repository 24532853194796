import { useEffect, useState } from "react";
import PropTypes, { array, object, number, string, bool } from "prop-types";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Text } from "recharts";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import DateChip from "./components/DateChip";
import { useBreakpoint } from "helpers/useBreakpoint";
import pluralize from "pluralize";
import {
  formatLargeNumber,
  separateNumWithComma,
  truncateText,
} from "helpers/helpers";
import { StyledTooltip } from "components";

const SalesChart = ({ chartList, currentFilterDate, loadingChartList }) => {
  const breakpoint = useBreakpoint();
  const styles = {
    paper: {
      alignItems: "center",
      border: "1px solid #D5D9D9",
      maxHeight: "296px",
    },
    text: {
      fontSize: "16px",
      fontWeight: 500,
      color: "#1C1C19",
    },
  };

  const [list, setList] = useState([]);

  const setWeek = (date) => {
    const numberDay = new Date(date).getDay();
    switch (numberDay) {
      case 1:
        return "Mon";
      case 2:
        return "Tue";
      case 3:
        return "Wed";
      case 4:
        return "Thu";
      case 5:
        return "Fri";
      case 6:
        return "Sat";
      case 0:
        return "Sun";

      default:
        return "";
    }
  };

  const setMonth = (month) => {
    switch (month) {
      case "01":
        return "JAN";
      case "02":
        return "FEB";
      case "03":
        return "MAR";
      case "04":
        return "APR";
      case "05":
        return "MAY";
      case "06":
        return "JUN";
      case "07":
        return "JUL";
      case "08":
        return "AUG";
      case "09":
        return "SEP";
      case "10":
        return "OCT";
      case "11":
        return "NOV";
      case "12":
        return "DEC";

      default:
        return "";
    }
  };

  useEffect(() => {
    setList([]);
    if (chartList?.length) {
      let store = [];
      let tempList = [...chartList];
      tempList.map((el) => {
        if (el?.sales == null) el.sales = 0;
        if (el?.units == null) el.units = 0;
        if (
          el?.date &&
          (currentFilterDate === "This week" ||
            currentFilterDate === "Last week")
        ) {
          el.week = setWeek(el?.date);
          el.nameTip = el?.date;
          return store.push(el);
        }
        if (
          el?.date &&
          (currentFilterDate === "This month" ||
            currentFilterDate === "Last month")
        ) {
          el.nameTip = el?.date;
          return store.push(el);
        }
        if (
          el?.date &&
          (currentFilterDate === "Last year" ||
            currentFilterDate === "This year")
        ) {
          el.year = setMonth(el.date.split("-")[1]);
          el.nameTip = el?.date;
          return store.push(el);
        }
        if (
          el?.date &&
          currentFilterDate === "All time" &&
          chartList.length === 12
        ) {
          el.year = setMonth(el.date.split("-")[1]);
          el.nameTip = el?.date;
          return store.push(el);
        }
        if (el?.hour) {
          el.nameTip = el?.hour;
          return store.push(el);
        }
        if (el?.date && currentFilterDate) {
          el.nameTip = el?.date;
          return store.push(el);
        }
      });
      setList(store);
    }
  }, [chartList, currentFilterDate]);

  const CustomizedLabel = ({
    dy = 26,
    textAnchor = "middle",
    xLeft = 0,
    x,
    y,
    payload,
  }) => {
    const setLabel = (currentLabel) => {
      if (typeof currentLabel === "string") return currentLabel;

      return formatLargeNumber(currentLabel);
    };

    return (
      <g transform={`translate(${x},${y})`}>
        <Text
          x={xLeft}
          dy={dy}
          textAnchor={textAnchor}
          fill="#000000"
          fontSize={12}
        >
          {truncateText(setLabel(payload.value), 8)}
        </Text>
      </g>
    );
  };
  CustomizedLabel.propTypes = {
    dy: string,
    textAnchor: string,
    payload: object,
    xLeft: number,
    x: number,
    y: number,
    stroke: string,
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "#FFFFFF",
            border: "1px solid #D5D9D9",
            borderRadius: "4px",
            position: "relative",
            textAlign: "center",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              paddingLeft: "10px",
              paddingRight: "10px",
              margin: 0,
            }}
          >{`${payload[0]?.payload?.nameTip}`}</p>
          {payload[0]?.name === "units" && (
            <p
              style={{
                fontSize: "12px",
                paddingLeft: "10px",
                paddingRight: "10px",
                margin: 0,
              }}
            >
              {payload[0]?.payload?.units}{" "}
              {pluralize("case", payload[0]?.payload?.units)}
            </p>
          )}
          {payload[0]?.name === "sales" && (
            <p
              style={{
                fontSize: "12px",
                paddingLeft: "10px",
                paddingRight: "10px",
                margin: 0,
              }}
            >
              {`${separateNumWithComma(payload[0]?.payload?.sales)} USD`}
            </p>
          )}
          <div
            style={{
              backgroundColor: "white",
              borderWidth: "0px 1px 1px 0px",
              borderStyle: "solid",
              borderColor: "#D5D9D9",
              width: "6px",
              height: "6px",
              position: "absolute",
              bottom: "-10",
              left: "50%",
              transform: "rotate(45deg) translate(-50%, 0%)",
            }}
          />
        </div>
      );
    }

    return null;
  };
  CustomTooltip.propTypes = {
    active: bool,
    payload: array,
    label: PropTypes.oneOfType([string, number]),
  };

  const [radioValue, setRadioValue] = useState("sales");

  const handleChangeRadioValue = (e) => {
    setRadioValue(e.target.value);
  };

  const checkDate = (str) => {
    const regDate = /^\d{2}\/\d{2}\/\d{4}$/; // 04/26/2022
    return regDate.test(str);
  };

  const showXLabel = (currentLabel) => {
    if (checkDate(currentLabel)) return "hour";

    switch (currentLabel) {
      case "":
      case "Today":
      case "Yesterday":
        return "hour";

      case "This week":
      case "Last week":
        return "week";

      case "This year":
      case "Last year":
        return "year";

      case "This month":
      case "Last month":
        return "date";

      case "All time":
        return chartList.length === 12 ? "year" : "date";

      default:
        return "date";
    }
  };

  return (
    <Paper
      className="paperChart"
      sx={styles.paper}
      elevation={0}
      component={Grid}
      container
      data-testid="sales-chart"
    >
      <Grid item xs={12} container>
        <Grid sx={{ paddingLeft: "20px", paddingTop: "12px" }} item xs={5.5}>
          <Typography sx={{ ...styles.text, display: "inline" }} mr="8px">
            Sales{" "}
          </Typography>
          <DateChip date={currentFilterDate} />
        </Grid>
        <Grid
          sx={{
            paddingRight: "20px",
            paddingTop: "8px",
            paddingBottom: "8px",
            textAlign: "right",
          }}
          item
          xs={6.5}
        >
          <FormControl>
            <RadioGroup
              sx={{
                display: "flex",
                justifyContent: "space-around",
              }}
              aria-labelledby="radio-btns-group"
              name="radio-btns-group"
              row
              value={radioValue}
              onChange={handleChangeRadioValue}
            >
              <FormControlLabel
                value={"sales"}
                sx={{ marginRight: 0 }}
                labelPlacement="start"
                label={
                  <>
                    <Box
                      sx={{
                        display: "inline-block",
                        width: breakpoint === "xl" ? "36px" : "18px",
                        height: "6px",
                        borderTop: "2px solid #409A65",
                        marginRight: "6px",
                      }}
                    />
                    <Typography sx={{ fontSize: "12px" }} variant="caption">
                      Sales (USD)
                    </Typography>
                  </>
                }
                control={
                  <Radio
                    sx={{
                      "& svg": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                  />
                }
              />
              <FormControlLabel
                sx={{ m: 0 }}
                value={"units"}
                labelPlacement="start"
                label={
                  <>
                    <Box
                      sx={{
                        display: "inline-block",
                        width: breakpoint === "xl" ? "36px" : "18px",
                        height: "6px",
                        borderTop: "2px solid #F0C401",
                        marginLeft: breakpoint === "xl" ? "20px" : "10px",
                        marginRight: "6px",
                      }}
                    />
                    <Typography fontSize={12} variant="caption">
                      Cases Ordered
                    </Typography>
                  </>
                }
                control={
                  <Radio
                    sx={{
                      "& svg": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {loadingChartList ? (
          <Box
            height="247px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="#9C9C94"
          >
            <CircularProgress />
          </Box>
        ) : (
          <LineChart
            // width={916}
            width={document.querySelector(".paperChart")?.offsetWidth - 2}
            height={247}
            data={list}
            margin={{ top: 0, right: 0, bottom: 20, left: 0 }}
          >
            <Line
              dot={false}
              dataKey={radioValue}
              stroke={radioValue === "sales" ? "#409A65" : "#F0C401"}
              strokeWidth={1.5}
            />
            <CartesianGrid stroke="rgba(79, 79, 73, 0.1)" vertical={false} />
            <XAxis
              dataKey={showXLabel(currentFilterDate)}
              tickSize={0}
              tick={<CustomizedLabel />}
              padding={{ left: 0, right: 0 }}
              interval={
                ["Today", "Yesterday"].includes(currentFilterDate)
                  ? 1
                  : "preserveStartEnd"
              }
            />
            <YAxis
              tickSize={0}
              tick={<CustomizedLabel dy="4" textAnchor="end" xLeft={-10} />}
              padding={{ top: 20 }}
            />
            <StyledTooltip
              allowEscapeViewBox={{ y: true }}
              offset={-40}
              content={<CustomTooltip />}
            />
          </LineChart>
        )}
      </Grid>
    </Paper>
  );
};
SalesChart.propTypes = {
  chartList: array,
  currentFilterDate: string,
  loadingChartList: bool,
};

export default SalesChart;
