import { useMemo, useState } from "react";
import { func, array, bool, object } from "prop-types";
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import {
  LargeCheckbox,
  RepsAssignedDrawer,
  StyledAvatar,
  StyledSelect,
  StyledTextField,
  StyledTooltip,
} from "components";
import { PlusIcon, SimpleArrowDownIcon } from "components/Icons";
import StopItem from "../StopItem";
import useStyles from "./styles";
import { Controller } from "react-hook-form";
import { normalizeUppercaseString, photoUrl } from "helpers/helpers";
import { PRIORITIES } from "Pages/CreateRoutePage/CreateRoutePage.constants";
import Slider from "react-slick";
import {
  NextArrow,
  PrevArrow,
} from "Pages/CatalogPage/CatalogNewProductPage/ContentTabs/Arrows";
import { useRepsPermissions } from "helpers/hooks";

const StopsTable = ({
  stops,
  handleDeleteStop,
  hasStartLoc,
  setEditCustomerData,
  customersState,
  control,
  handleAssignReps,
  reps,
}) => {
  const classes = useStyles();

  const repPermissions = useRepsPermissions();

  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const sliderSettings = {
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <NextArrow width="4.5" height="7.9" color="#707070" top="30%" />,
    prevArrow: <PrevArrow width="4.5" height="7.9" color="#707070" top="30%" />,
  };

  const handleOpenAssign = (e) => {
    e.preventDefault();
    if (repPermissions && !repPermissions?.routes?.assign) return;
    setAssignDialogOpen(true);
  };

  const thirdPartyLabel = (rep) => {
    return rep?.representative?.role === "THIRD_PARTY" ? (
      <Box className={classes.thirdPartyLabel}>3rd Party</Box>
    ) : (
      ""
    );
  };

  const toggleSelectOpen = () => {
    setIsSelectOpen(!isSelectOpen);
  };

  const handleAvatarContent = (rep) => (
    <Box sx={{ textAlign: "center" }}>
      <Typography fontSize="10px" color="primary">
        {!!rep?.role &&
          (rep?.role === "THIRD_PARTY"
            ? "3rd Party Rep"
            : `${normalizeUppercaseString(rep?.role)} Rep`)}
      </Typography>
      <Typography fontSize="10px" color="#363531">
        {rep?.name}
      </Typography>
    </Box>
  );

  const setMarginRight = useMemo(() => {
    if (reps?.length === 1) return "-26px";
    if (reps?.length === 2) return "-5px";
    return "16px";
  }, [reps?.length]);

  return (
    <>
      <RepsAssignedDrawer
        isOpen={assignDialogOpen}
        handleClose={() => setAssignDialogOpen(false)}
        assignedRepresentatives={reps}
        handleSetData={handleAssignReps}
        submitBtnLabel="Update"
        withoutThirdParty
        skipDisabledBtn
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "14px",
          height: "100%",
          width: "458px",
        }}
      >
        <Box>
          <Box
            className={classes.headerWrapper}
            sx={{ gap: reps?.length > 3 ? "16px" : 0 }}
          >
            <Box display="flex" gap={1} flexGrow={1}>
              <Typography color="#6A6A6A">Route</Typography>
              <Typography color="#9C9C94">
                {stops.length} stops {hasStartLoc && "I 33 miles I 3 hrs 9 m"}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: reps.length > 1 ? "pointer" : "auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mr: setMarginRight,
                }}
                onClick={() => {
                  if (!reps.length) return;
                  toggleSelectOpen();
                }}
              >
                {reps.length > 1 ? (
                  <Typography
                    sx={{ fontSize: "12px", color: "#47A06D", mr: "5px" }}
                  >
                    +{reps.length}
                  </Typography>
                ) : null}
                {reps?.length > 1 ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "10px",
                    }}
                  >
                    <SimpleArrowDownIcon width="20px" />
                  </Box>
                ) : (
                  <Box width="34px" />
                )}
              </Box>

              <Select
                open={isSelectOpen}
                onClose={() => setIsSelectOpen(false)}
                value={""}
                sx={{ opacity: 0, position: "absolute", zIndex: -1 }}
              >
                {reps.map((rep, index) => {
                  const photo = rep?.profilePhoto?.fileName;
                  const name = (rep?.name || "").trim();
                  return (
                    <MenuItem
                      key={rep?.representative?.id || rep?.id}
                      px="10px"
                      value={{ ...rep, index }}
                      sx={{
                        maxWidth: "700px",
                        "&:hover": { backgroundColor: "#FFF" },
                      }}
                      onClick={handleOpenAssign}
                    >
                      <Grid
                        sx={{
                          height: "50px",
                          flexWrap: "nowrap",
                          alignItems: "center",
                          "&:hover": {
                            background: "#E9E9E950",
                            borderRadius: "4px",
                            cursor: "pointer",
                          },
                        }}
                        container
                      >
                        <Grid pl={1} item xs="auto">
                          {thirdPartyLabel(rep)}
                          <StyledAvatar
                            sx={{ width: 30, height: 30 }}
                            name={name}
                            src={photo}
                            stringStyle={{ fontSize: 12 }}
                          />
                        </Grid>

                        <Grid item xs>
                          <Typography
                            sx={{ maxWidth: "435px", px: 0.5 }}
                            noWrap
                          >
                            {name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  );
                })}
              </Select>

              {reps?.length ? (
                <Box className={classes.sliderWrapper}>
                  <Slider {...sliderSettings}>
                    {reps.map((rep) => {
                      return (
                        <StyledTooltip
                          placement="top"
                          arrow
                          title={handleAvatarContent(rep)}
                          key={rep?.id}
                          PopperProps={{
                            modifiers: [
                              {
                                name: "offset",
                                options: { offset: [0, -5] },
                              },
                            ],
                          }}
                        >
                          <StyledAvatar
                            className={classes.assignedAvatar}
                            name={rep?.name}
                            src={photoUrl(rep?.profilePhoto?.fileName)}
                            stringStyle={{ fontSize: 10 }}
                            onClick={handleOpenAssign}
                          />
                        </StyledTooltip>
                      );
                    })}
                  </Slider>
                </Box>
              ) : (
                <Typography
                  sx={{
                    fontSize: 13,
                    fontWeight: 300,
                    color: "#6A6A6A",
                    cursor: "pointer",
                  }}
                  onClick={handleOpenAssign}
                >
                  Assign Rep
                </Typography>
              )}
            </Box>

            <IconButton onClick={handleOpenAssign}>
              <PlusIcon width="15" height="15" stroke="#EEE" />
            </IconButton>
          </Box>
          <Box
            sx={{
              border: "0.5px solid #CCCCCC",
              borderRadius: "0 0 4px 4px",
              backgroundColor: "#FFF",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                px: "15px",
                py: "18px",
              }}
            >
              <Box width="312px">
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <StyledTextField
                      label="Route Name *"
                      inputSx={{ color: "#1C1C19" }}
                      labelSx={{ color: "#5F6267" }}
                      error={error?.message || ""}
                      noErrorMessage
                      {...field}
                    />
                  )}
                  name="name"
                  {...{ control }}
                />
              </Box>

              <Box width="102px">
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <StyledSelect
                      label="Priority *"
                      fullWidth
                      labelSx={{ color: "#5F6267" }}
                      noErrorMessage
                      error={error?.message || ""}
                      {...field}
                    >
                      {PRIORITIES.map((priority) => (
                        <MenuItem key={priority} value={priority}>
                          {normalizeUppercaseString(priority)}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  )}
                  name="priority"
                  {...{ control }}
                />
              </Box>
            </Box>

            <Box sx={{ width: "100%", pb: "4px", px: "15px" }}>
              <Box height="32px">
                <Controller
                  render={({ field }) => (
                    <StyledTextField
                      fullWidth
                      label="Notes"
                      placeholder="Add route note or instructions"
                      {...field}
                    />
                  )}
                  name="note"
                  {...{ control }}
                />
              </Box>
            </Box>

            <Box sx={{ width: "100%", pb: "9px", px: "17px" }}>
              <Box height="32px">
                <Controller
                  render={({ field }) => (
                    <LargeCheckbox
                      label="Allow assigned reps to edit route"
                      size={16}
                      labelSx={{ fontSize: "11px", color: "#363531" }}
                      checked={!!field.value}
                      {...field}
                    />
                  )}
                  name="allowAssignedRepsToUpdate"
                  {...{ control }}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Stack className={classes.stopsWrapper}>
          {stops.length ? (
            stops.map((stop, index) => {
              let shouldRemove = false;
              if (
                !customersState?.available &&
                customersState?.inaccessibleCustomers?.length
              ) {
                shouldRemove = customersState?.inaccessibleCustomers.some(
                  (id) => id === stop.id
                );
              }
              return (
                <StopItem
                  key={stop.id}
                  {...{ stop, index, handleDeleteStop, setEditCustomerData }}
                  shouldRemove={shouldRemove}
                />
              );
            })
          ) : (
            <Typography textAlign="center" mt="24px" fontSize="12px">
              No stops added
            </Typography>
          )}
        </Stack>
      </Box>
    </>
  );
};

StopsTable.propTypes = {
  stops: array,
  handleDeleteStop: func,
  hasStartLoc: bool,
  setEditCustomerData: func,
  customersState: object,
  control: object,
  handleAssignReps: func,
  reps: array,
};

StopsTable.defaultProps = {
  stops: [],
  handleDeleteStop: () => {},
  hasStartLoc: false,
  setEditCustomerData: () => {},
};

export default StopsTable;
