import { StyledTabs } from "components";
import { TABS_RESPONSES_PAGE } from "Pages/FormViewPage/FormViewPage.constants";
import { cl } from "./TabsBlock.styles";
import { Stack } from "@mui/material";
import { useContext } from "react";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";

export const TabsBlock = () => {
  const { currentResponseTab, handleChangeResponsesTab } =
    useContext(FormViewContext);

  return (
    <Stack alignItems="center" mt="20px">
      <StyledTabs
        onChange={(e, newVal) => handleChangeResponsesTab(newVal)}
        value={currentResponseTab}
        tabs={TABS_RESPONSES_PAGE}
        tabProps={{ sx: cl.tabsPropsSx }}
        sx={cl.tabs}
      />
    </Stack>
  );
};
