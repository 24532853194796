import { bool, func, shape, string } from "prop-types";
import { Box, Drawer } from "@mui/material";

import {
  BodyNoteDrawer,
  FooterNoteDrawer,
  HeaderNoteDrawer,
  NoteBlock,
} from "./components";
import { useNoteDrawer } from "./NoteDrawer.hooks";
import { StyledTextField } from "components";
import {
  BODY_WRAPPER_PADDINGS,
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
  INLINE_OF_ONE_ROW,
  PADDINGS_TEXTFIELD,
} from "Pages/CustomersPage/pages/CustomerProfilePage/components/NoteDrawer/NoteDrawer.constants";
import { useWindowSize } from "helpers/hooks";
import { useMemo } from "react";
import { useAdmin } from "helpers/helpers";

export const NoteDrawer = ({
  open,
  onClose,
  data,
  viewOnly,
  refetchCallback,
  titleLabel,
}) => {
  const {
    editNote,
    customerName,
    notesList,
    notesCount,
    handleEdit,
    handleDelete,
    profilePhoto,
    fetchNotesList,
    note,
    setNote,
    loadingNotes,
    page,
    isAdminEdit,
    onCancel,
    isNoteView,
    onConfirm,
    currentUser,
    handleCloseNote,
  } = useNoteDrawer({ data, viewOnly, onClose, refetchCallback, open });
  const isAdmin = useAdmin();

  const calcHeight = useMemo(() => {
    return (
      PADDINGS_TEXTFIELD + BODY_WRAPPER_PADDINGS + HEADER_HEIGHT + FOOTER_HEIGHT
    );
  }, []);

  const [, windowHeight] = useWindowSize();

  const dynamicRows = ((windowHeight - calcHeight) / INLINE_OF_ONE_ROW).toFixed(
    0
  );

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleCloseNote}
      disableRestoreFocus
    >
      <Box
        width="545px"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box width="100%" height="100%" display="flex" flexDirection="column">
          <HeaderNoteDrawer
            viewOnly
            isAdminEdit={isAdminEdit}
            editNote={editNote}
            customerName={customerName}
            onClose={handleCloseNote}
            titleLabel={titleLabel}
          />
          {viewOnly && isAdminEdit ? (
            <Box sx={{ padding: `${BODY_WRAPPER_PADDINGS}px` }}>
              <StyledTextField
                placeholder="Note"
                multiline
                minRows={3}
                maxRows={dynamicRows}
                fullWidth
                value={note}
                onChange={(e) => {
                  const { value } = e.target;
                  setNote(value);
                }}
                error={note.error}
                sx={{
                  "& .MuiOutlinedInput-root": { height: "100%" },
                }}
              />
            </Box>
          ) : (
            <BodyNoteDrawer
              {...{
                viewOnly,
                notesList,
                notesCount,
                handleEdit,
                handleDelete,
                currentUser,
                isAdmin,
              }}
              distrProfilePhoto={profilePhoto?.fileName}
              fetchNotesList={() => fetchNotesList(page)}
            />
          )}
          {!viewOnly && (
            <NoteBlock
              {...{ note, setNote, editNote, isAdmin }}
              loading={loadingNotes}
            />
          )}
        </Box>
        <FooterNoteDrawer
          isNoteView={isNoteView}
          isAdminEdit={isAdminEdit}
          onClose={handleCloseNote}
          viewOnly={viewOnly}
          onCancel={onCancel}
          onConfirm={onConfirm}
          disabledBtns={!note}
          loading={loadingNotes}
          editNote={editNote}
          handleDelete={() => handleDelete(notesList[0])}
        />
      </Box>
    </Drawer>
  );
};

NoteDrawer.propTypes = {
  open: bool,
  onClose: func,
  viewOnly: bool,
  refetchCallback: func,
  data: shape({
    id: string,
    customer: shape({
      name: string,
    }),
  }),
  titleLabel: string,
};
NoteDrawer.defaultProps = {
  open: false,
  onClose: () => {},
  viewOnly: false,
  refetchCallback: () => {},
  data: {
    id: "",
    customer: { name: "", id: "" },
  },
};
