import { Box, IconButton, Stack, Typography } from "@mui/material";
import useStyles, { cl } from "./styles";
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useLocation } from "react-router-dom";
import { IosArrowForward } from "components/Icons";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import { ActionRestrictionWrapper, StyledButton } from "components";
import { ProductPriceContext } from "Pages/ProductPricePage";
import EditPriceName from "Pages/CatalogPage/PriceListTab/components/EditPriceName";
import EditIcon from "@mui/icons-material/Edit";

export const PageHeader = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const {
    getAllProductsForPopup,
    currentTab,
    getAllCustomersForPopup,
    onPriceListDelete,
    currentPriceList,
    onEditSave,
    repPermissions,
    isAdmin,
  } = useContext(ProductPriceContext);

  const [anchorCustomerEl, setAnchorCustomerEl] = useState(null);
  const [isEditHovered, setIsEditHovered] = useState(false);

  const customerNameRef = useRef(null);

  const handleClickOnEdit = useCallback((e) => {
    e.stopPropagation();
    if (customerNameRef.current) {
      setAnchorCustomerEl(customerNameRef.current);
    }
  }, []);

  const handleCloseEdit = (e) => {
    e.stopPropagation();
    setAnchorCustomerEl(null);
  };

  const isProductTab = currentTab === 0;

  const actionBtnLabel = isProductTab ? "Add Products" : "Add Customers";
  const actionBtnCallback = isProductTab
    ? getAllProductsForPopup
    : getAllCustomersForPopup;

  const showEditBtn = isEditHovered || !!anchorCustomerEl;

  const disabledEditPriceList = useMemo(() => {
    return repPermissions
      ? !repPermissions?.catalog?.create_edit_price_lists
      : false;
  }, [repPermissions]);

  const disabledDeletePriceList = useMemo(() => {
    return repPermissions
      ? !repPermissions?.catalog?.delete_price_lists
      : false;
  }, [repPermissions]);

  const breadcrumbs = [
    <Typography
      key={1}
      variant="h6"
      fontWeight="400"
      fontSize="16px"
      color="primary"
      className="main"
      sx={{ textDecoration: "none" }}
      component={Link}
      to={"/catalog"}
    >
      Catalog
    </Typography>,
    <Typography
      key={2}
      variant="h6"
      fontWeight="400"
      fontSize="16px"
      sx={{ textDecoration: "none", color: "#0009" }}
      className="main"
      component={Link}
      to="/catalog"
      state={{ currentTab: "Price Lists" }}
      from={pathname}
    >
      Price Lists
    </Typography>,
    <Stack
      key={2}
      direction="row"
      alignItems="center"
      justifyContent="center"
      onMouseEnter={() => setIsEditHovered(true)}
      onMouseLeave={() => setIsEditHovered(false)}
      sx={{
        p: "2.5px 4px 2.5px 8px",
        background: "rgba(213, 217, 217, 0.30)",
        borderRadius: "6px",
        gap: "4px",
        overflow: "hidden",
      }}
    >
      <Typography
        variant="h6"
        fontWeight="400"
        fontSize="16px"
        className="main"
        noWrap
        pr={!showEditBtn ? "4px" : 0}
      >
        {currentPriceList.name}
      </Typography>

      {showEditBtn && (
        <ActionRestrictionWrapper disableHoverListener={!disabledEditPriceList}>
          <IconButton
            ref={customerNameRef}
            disabled={disabledEditPriceList}
            onClick={(e) => {
              e.stopPropagation();
              handleClickOnEdit(e);
            }}
            sx={{
              p: 0,
              transition: "all 0.4s",
              mb: "2px",
            }}
            disableRipple
          >
            <EditIcon sx={{ width: "16px", height: "16px" }} />
          </IconButton>
        </ActionRestrictionWrapper>
      )}
    </Stack>,
  ];

  return (
    <Box className={classes.headerWrapper}>
      <Box className={classes.pageTitleWrap}>
        <Breadcrumbs
          separator={<IosArrowForward />}
          sx={{
            width: "100%",
            "& svg": {
              mb: "-2px",
            },
            "& .MuiBreadcrumbs-ol": {
              flexWrap: "nowrap",
              width: "100%",
              "& a": {
                whiteSpace: "nowrap",
              },
            },
            "& .MuiBreadcrumbs-li": {
              overflow: "hidden",
              maxWidth: "calc(100% - 180px)",
            },
          }}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>

      <EditPriceName
        isOpen={!!anchorCustomerEl}
        price={currentPriceList}
        onDelete={onPriceListDelete}
        anchorEl={anchorCustomerEl}
        handleClose={handleCloseEdit}
        onSave={onEditSave}
      />

      <Stack direction="row" gap="8px">
        <ActionRestrictionWrapper
          disableHoverListener={!disabledDeletePriceList}
        >
          <StyledButton
            disabled={disabledDeletePriceList || isAdmin}
            disableRipple
            sx={{ ...cl.saveBtn, backgroundColor: "#ffff", p: "5px 9px" }}
            variant="outlined"
            label="Delete"
            color="delete"
            onClick={onPriceListDelete}
            fontWeight="500"
          />
        </ActionRestrictionWrapper>

        <ActionRestrictionWrapper disableHoverListener={!disabledEditPriceList}>
          <StyledButton
            disableRipple
            sx={{ ...cl.saveBtn, backgroundColor: "#ffff", p: "5px 9px" }}
            variant="outlined"
            disabled={disabledEditPriceList}
            label={actionBtnLabel}
            onClick={actionBtnCallback}
            fontWeight="500"
          />
        </ActionRestrictionWrapper>
      </Stack>
    </Box>
  );
};
