import { func, bool } from "prop-types";

import { StyledButton } from "components";

import { Stack } from "@mui/material";

export const DrawerFooter = ({ onClose, onSave, loading }) => {
  return (
    <Stack
      direction="row"
      sx={{
        mt: "auto",
        width: "100%",
        height: "80px",
        justifyContent: "flex-end",
        alignItems: "center",
        borderTop: "1px solid #D5D9D9",
        backgroundColor: "#F8F8F8",
        p: "28px 32px 20px",
        gap: "8px",
      }}
    >
      <StyledButton
        disabled={loading}
        label="Cancel"
        sx={{ height: "32px", minWidth: "80px" }}
        color="cancel"
        onClick={onClose}
        fontWeight="600"
        fontSize="15px"
      />
      <StyledButton
        disabled={loading}
        label="Set"
        variant="contained"
        sx={{ height: "32px", minWidth: "80px" }}
        fontWeight="600"
        fontSize="15px"
        onClick={onSave}
      />
    </Stack>
  );
};

DrawerFooter.propTypes = {
  onClose: func,
  onSave: func,
  loading: bool,
};
