import { useContext, useMemo } from "react";

import { LargeCheckbox, TableHeader } from "components";

import { cl } from "./TableHeaderBlock.styles";
import { ArrowDownIcon, ArrowUpIcon } from "components/Icons";

import { Box, Grid, Typography } from "@mui/material";
import { SORTABLE_COLUMNS } from "./TableHeaderBlock.constants";
import { ProductPriceContext } from "Pages/ProductPricePage";

export const TableHeaderBlock = () => {
  const {
    allProductsChecked,
    checkAllProducts,
    selectedProducts,
    handleSetSort,
    quickSort,
  } = useContext(ProductPriceContext);

  const isIndeterminate = useMemo(
    () =>
      selectedProducts?.length > 0 &&
      selectedProducts?.length !== selectedProducts?.length,
    [selectedProducts]
  );

  const TABLE_DATA = [
    {
      id: 1,
      title: "",
      element: (
        <LargeCheckbox
          formSx={{ m: 0 }}
          checked={allProductsChecked}
          onChange={checkAllProducts}
          sx={cl.checkboxSx}
          onClick={(e) => e.stopPropagation()}
          size={22}
          rectSize={22}
          indeterminate={isIndeterminate}
          indeterminateIcon={27}
          checkedViewBox="0 0 24 24"
        />
      ),
      sx: { pl: "16px" },
      xs: 0.42,
    },
    {
      id: 3,
      title: "PRODUCT",
      sx: { ...cl.leftHeaderItem },
      xs: 5.1,
    },
    {
      id: 4,
      title: "MANUFACTURER",
      sx: { ...cl.leftHeaderItem },
      xs: 2,
    },
    {
      id: 5,
      title: "STANDARD PRICE",
      sx: { ...cl.leftHeaderItem },
      xs: 1.6,
    },
    {
      id: 6,
      title: "DISCOUNT",
      sx: { ...cl.leftHeaderItem },
      xs: 1.48,
    },
    {
      id: 8,
      title: "FINAL PRICE",
      sx: { ...cl.leftHeaderItem },
      xs: 1.15,
    },
  ];

  return (
    <TableHeader sx={cl.tableHeaderSx} columns={12}>
      {TABLE_DATA.map(({ id, sx, title, element, xs }) => {
        const activeSortableField = SORTABLE_COLUMNS?.[title]?.sortableField;

        return (
          <Grid sx={sx} key={id} item xs={xs}>
            {title && (
              <Typography fontSize={12} color="#6A6A6A" mr={1}>
                {title}
              </Typography>
            )}
            {element && element}

            {SORTABLE_COLUMNS[title] && (
              <Box
                sx={cl.arrowBox}
                onClick={() =>
                  SORTABLE_COLUMNS[title] && handleSetSort(activeSortableField)
                }
              >
                {quickSort[activeSortableField] === "asc" ? (
                  <ArrowDownIcon />
                ) : (
                  <ArrowUpIcon
                    fill={
                      !quickSort[activeSortableField] ? "#a7a8aa" : "#5f6267"
                    }
                  />
                )}
              </Box>
            )}
          </Grid>
        );
      })}
    </TableHeader>
  );
};
