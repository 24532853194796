import {
  FormBarcodeTypeIcon,
  FormMultipleTypeIcon,
  FormNumericTypeIcon,
  FormPhotoTypeIcon,
  FormProductTypeIcon,
  FormSingleTypeIcon,
  FormTextTypeIcon,
} from "components/Icons";
import { FormDateTypeIcon } from "components/Icons/FormDateTypeIcon";
import {
  TextContentBlock,
  YesNoContentBlock,
  MultiAnswerContentBlock,
  SingleAnswerContentBlock,
  PhotosContentBlock,
  DateContentBlock,
} from "./components/ContentBlock/components/AnswerContentBlock";
import { generateUUID } from "helpers/helpers";

export const FORM_TYPES = {
  TEXT: "TEXT",
  NUMERIC: "NUMERIC",
  YES_NO: "YES_NO",
  SINGLE_ANSWER: "SINGLE_ANSWER",
  MULTIPLE_ANSWER: "MULTIPLE_ANSWER",
  PHOTO: "PHOTO",
  DATE: "DATE",
  BARCODE: "BARCODE",
  PRODUCT: "PRODUCT",
};

export const FORM_TYPES_SELECT = [
  {
    label: "Text",
    value: "TEXT",
  },
  {
    label: "Numeric",
    value: "NUMERIC",
  },
  {
    label: "Yes/No",
    value: "YES_NO",
  },
  {
    label: "Single Answer",
    value: "SINGLE_ANSWER",
  },
  {
    label: "Multi Answer",
    value: "MULTIPLE_ANSWER",
  },
  {
    label: "Photos",
    value: "PHOTO",
  },
  {
    label: "Date",
    value: "DATE",
  },
  // {
  //   label: "Barcode",
  //   value: "BARCODE",
  //   disabled: true,
  // },
  // {
  //   label: "Product",
  //   value: "PRODUCT",
  //   disabled: true,
  // },
];

export const ICON_STYLES = {
  MULTIPLE_ANSWER: {
    "& path": {
      fill: "#FFF !important",
    },
  },
};

export const handleGetIcons = (props) => {
  const { singleProps = {}, multiProps = {} } = props || {};

  return {
    TEXT: <FormTextTypeIcon />,
    NUMERIC: <FormNumericTypeIcon />,
    YES_NO: <FormTextTypeIcon />,
    SINGLE_ANSWER: <FormSingleTypeIcon {...singleProps} />,
    MULTIPLE_ANSWER: (
      <FormMultipleTypeIcon style={{ margin: "0px 2.7px" }} {...multiProps} />
    ),
    PHOTO: <FormPhotoTypeIcon />,
    DATE: <FormDateTypeIcon />,
    BARCODE: <FormBarcodeTypeIcon />,
    PRODUCT: <FormProductTypeIcon />,
  };
};

export const TABS_PAGE = [
  {
    value: 0,
    label: "Questions",
    showTooltip: false,
    tooltipText: "",
  },
  {
    value: 1,
    label: "Responses",
    showTooltip: false,
    tooltipText: "",
  },
  {
    value: 2,
    label: "Visibility Settings",
    showTooltip: false,
    tooltipText: "",
  },
];

export const TABS_RESPONSES_PAGE = [
  {
    value: 0,
    label: "Summary",
    showTooltip: false,
    tooltipText: "",
  },
  {
    value: 1,
    label: "Questions",
    showTooltip: true,
    tooltipText: "Coming soon",
    disabled: true,
  },
  {
    value: 2,
    label: "Representative",
    showTooltip: true,
    tooltipText: "Coming soon",
    disabled: true,
  },
];

export const TABS_VISIBILITY_SETTINGS_PAGE = [
  {
    value: 0,
    label: "Customers",
    showTooltip: false,
    tooltipText: "",
  },
  {
    value: 1,
    label: "Reps",
    showTooltip: false,
    tooltipText: "",
  },
];

export const STATUS_SELECT_DATA = [
  {
    label: "Active",
    value: true,
  },
  {
    label: "Inactive",
    value: false,
  },
];

export const getCurrentContentBlock = (type, props) => {
  switch (type) {
    case "TEXT":
      return <TextContentBlock {...props} />;
    case "NUMERIC":
      return <TextContentBlock {...props} />;
    case "YES_NO":
      return <YesNoContentBlock {...props} />;
    case "SINGLE_ANSWER":
      return <SingleAnswerContentBlock {...props} />;
    case "MULTIPLE_ANSWER":
      return <MultiAnswerContentBlock {...props} />;
    case "PHOTO":
      return <PhotosContentBlock {...props} />;
    case "DATE":
      return <DateContentBlock {...props} />;
    case "BARCODE":
      return <TextContentBlock {...props} />;
    case "PRODUCT":
      return <TextContentBlock {...props} />;
  }
};

export const defaultValues = {
  title: "",
  active: true,
  description: "",
  autofill: false,
  allRequired: true,
  allowGallery: false,
  questions: [],
  questionsToDelete: [],
  _count: {
    assignedCustomers: 0,
    assignedRepresentatives: 0,
  },
  assignedCustomers: [],
  assignedRepresentatives: [],
};

const item = ({ type, orderIndex, autofill, required, allowGallery }) => ({
  uuid: generateUUID(),
  text: "",
  type,
  orderIndex,
  autofill,
  required,
  allowGallery,
  ...([FORM_TYPES.SINGLE_ANSWER].includes(type) && {
    options: [
      { title: "Option 1", uuid: generateUUID(), orderIndex: 0 },
      { title: "Option 2", uuid: generateUUID(), orderIndex: 1 },
    ],
  }),
  ...([FORM_TYPES.MULTIPLE_ANSWER].includes(type) && {
    options: [
      { title: "Answer 1", uuid: generateUUID(), orderIndex: 0 },
      { title: "Answer 2", uuid: generateUUID(), orderIndex: 1 },
    ],
  }),
});

export const getCurrentItem = ({
  type,
  orderIndex,
  autofill,
  required,
  allowGallery,
}) => {
  switch (type) {
    case FORM_TYPES.TEXT:
      return item({ type, orderIndex, autofill, required, allowGallery });
    case FORM_TYPES.NUMERIC:
      return item({ type, orderIndex, autofill, required, allowGallery });
    case FORM_TYPES.YES_NO:
      return item({ type, orderIndex, autofill, required, allowGallery });
    case FORM_TYPES.SINGLE_ANSWER:
      return item({ type, orderIndex, autofill, required, allowGallery });
    case FORM_TYPES.MULTIPLE_ANSWER:
      return item({ type, orderIndex, autofill, required, allowGallery });
    case FORM_TYPES.PHOTO:
      return item({ type, orderIndex, autofill, required, allowGallery });
    case FORM_TYPES.DATE:
      return item({ type, orderIndex, autofill, required, allowGallery });

    default:
      return item({ type, orderIndex, autofill, required, allowGallery });
  }
};

export const LINEAR_PROGRESS_COLORS = [
  "#FFF387",
  "#FFD66D",
  "#FFB46D",
  "#DD7738",
  "#D54343",
  "#E18D8D",
  "#FF7474",
  "#BBA364",
  "#63987A",
  "#2099C5",
  "#8C9EC2",
  "#96B6F7",
  "#6EDCD1",
  "#9B76FF",
  "#B56EDC",
  "#BFEA68",
  "#38DD7E",
  "#30B35E",
  "#8CC2A3",
  "#FFFED4",
];

export const MAX_ALLOWED_QUESTIONS = 50;
