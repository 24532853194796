import * as Yup from "yup";

export const validationSchema = ({ incoming = false } = {}) =>
  Yup.object().shape({
    title: Yup.string()
      .trim()
      .required("Please enter form title to proceed")
      .max(100, "Max 100 characters"),
    active: Yup.boolean().required("This field is required"),
    description: Yup.string().trim().nullable().max(300, "Max 300 characters"),
    autofill: Yup.boolean().required("This field is required"),
    allRequired: Yup.boolean().required("This field is required"),
    questions: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string(),
          text: Yup.string()
            .required("Please enter the question title to proceed")
            .max(100, "Max 100 characters"),
          type: Yup.mixed()
            .oneOf([
              "TEXT",
              "YES_NO",
              "NUMERIC",
              "SINGLE_ANSWER",
              "MULTIPLE_ANSWER",
              "PHOTO",
              "DATE",
            ])
            .required("This field is required"),
          options: Yup.array()
            .nullable()
            .of(
              incoming
                ? Yup.string()
                    .trim()
                    .required("This field can't be empty")
                    .max(60, "Max 60 characters")
                : Yup.object().shape({
                    title: Yup.string()
                      .trim()
                      .required("This field can't be empty")
                      .max(60, "Max 60 characters"),
                  })
            ),
          orderIndex: Yup.number().required("This field is required"),
          autofill: Yup.boolean().required("This field is required"),
          required: Yup.boolean().required("This field is required"),
          allowGallery: Yup.boolean().nullable(),
        })
      )
      .required("This field is required")
      .min(1, "Please add questions to save the form"),
  });

export const validateResponseData = async ({ data }) => {
  try {
    await validationSchema({ incoming: true }).validate(data, {
      abortEarly: false,
    });
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      // eslint-disable-next-line no-console
      console.error("Response validation error", err.inner);
    }
  }
};
