import { bool, func } from "prop-types";
import { Box, Drawer } from "@mui/material";

import { BodyAddContactDrawer, HeaderAddContactDrawer } from "./components";
import { createSelector } from "reselect";
import { confirmDialogFormChangedSelector } from "redux/selectors/confirmDialogs";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "Pages/CartPage/CartPage.validations";
import {
  openDiscardChanges,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";

const selector = createSelector(
  confirmDialogFormChangedSelector,
  (formChanged) => ({ formChanged })
);

export const AddContactDrawer = ({ open, onClose, handleAddContact }) => {
  const { formChanged } = useSelector(selector);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    setValue,
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      fid: Date.now(),
      id: "",
      email: "",
      name: "",
      phone: "",
      role: "Owner",
      defaultContact: false,
      custom: "",
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const isDisabled = useMemo(() => {
    return (
      !formField?.name ||
      !formField?.role ||
      (formField?.role === "custom" && !formField?.custom) ||
      errors?.name ||
      errors?.phone ||
      errors?.email ||
      errors?.role ||
      errors?.custom
    );
  }, [
    errors?.custom,
    errors?.email,
    errors?.name,
    errors?.phone,
    errors?.role,
    formField?.custom,
    formField?.name,
    formField?.role,
  ]);

  const onSubmit = useCallback(() => {
    const { name, phone, email, defaultContact, custom, role, fid, id } =
      formField;

    handleAddContact({
      name,
      phone: phone ? phone.replace(/\s|\(|\)/g, "") : "",
      email,
      defaultContact,
      role: formField.role === "custom" ? custom : role,
      fid,
      id: id || undefined,
    });
    onClose();
  }, [handleAddContact, onClose, formField]);

  useEffect(() => {
    if (open) {
      reset({
        fid: Date.now(),
        id: "",
        email: "",
        name: "",
        phone: "",
        role: "Owner",
        defaultContact: false,
        custom: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, open]);

  const preventCloseDrawer = useCallback(
    ({ onClose }) => {
      // eslint-disable-next-line no-console
      if (!onClose) return console.error("onClose is a required parameter");

      if (formChanged) {
        return dispatch(
          openDiscardChanges(
            () => onClose(),
            async () => {
              const resTrigger = await trigger();
              if (resTrigger && !isDisabled) {
                onSubmit(formField);
              } else {
                dispatch(setFormChangedAction(isDirty));
              }
            }
          )
        );
      }

      onClose();
    },
    [dispatch, formChanged, formField, isDirty, isDisabled, onSubmit, trigger]
  );

  const handleCloseNote = useCallback(() => {
    preventCloseDrawer({ onClose });
  }, [onClose, preventCloseDrawer]);

  useEffect(() => {
    dispatch(setFormChangedAction(isDirty));

    if (!open) dispatch(setFormChangedAction(false));
  }, [isDirty, dispatch, open]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleCloseNote}
      sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1 }}
    >
      <Box width="545px" height="100%">
        <Box width="100%" height="100%" display="flex" flexDirection="column">
          <HeaderAddContactDrawer onClose={handleCloseNote} />

          <BodyAddContactDrawer
            {...{
              control,
              setValue,
              formField,
              isDisabled,
            }}
            onClose={handleCloseNote}
            handleSubmit={handleSubmit(onSubmit)}
          />
        </Box>
      </Box>
    </Drawer>
  );
};

AddContactDrawer.propTypes = {
  open: bool,
  onClose: func,
  handleAddContact: func,
};
AddContactDrawer.defaultProps = {
  open: false,
  onClose: () => {},
};
