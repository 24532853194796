import { func, string, bool, object, array, oneOfType } from "prop-types";
import { useMemo } from "react";
import {
  EmptyCustomersIcon,
  EmptyDiscountsIcon,
  EmptyMapsIcon,
  EmptyMessagesIcon,
  EmptyOrdersIcon,
  EmptyPriceListIcon,
  EmptyProductsIcon,
  EmptyRepsIcon,
  EmptyTasksIcon,
} from "../Icons";
import { CircularProgress, Typography, Box } from "@mui/material";
import useStyles from "./styles";
import StyledButton from "../StyledButton";
import { useAdmin } from "helpers/helpers";
import { StyledTooltip } from "components";

export const EmptyScreen = ({
  type,
  onConfirm,
  height,
  showAction,
  loading,
  disabled,
  tooltipTitle = "",
  tooltipPlacement = "top",
  tooltipProps = {},
  offset = [0, -6],
}) => {
  const isAdmin = useAdmin();
  const classes = useStyles({ height });

  const icon = useMemo(() => {
    switch (type) {
      case "products": {
        return <EmptyProductsIcon />;
      }
      case "prices": {
        return <EmptyProductsIcon style={{ marginBottom: "8px" }} />;
      }
      case "price lists": {
        return <EmptyPriceListIcon style={{ marginBottom: "8px" }} />;
      }
      case "orders": {
        return <EmptyOrdersIcon />;
      }
      case "drafts": {
        return <EmptyOrdersIcon />;
      }
      case "customers": {
        return <EmptyCustomersIcon style={{ marginBottom: "24px" }} />;
      }
      case "discounts": {
        return <EmptyDiscountsIcon />;
      }
      case "messages": {
        return <EmptyMessagesIcon />;
      }
      case "reps": {
        return <EmptyRepsIcon />;
      }
      case "routes": {
        return <EmptyMapsIcon />;
      }
      case "task": {
        return <EmptyTasksIcon />;
      }
      case "form": {
        return <EmptyTasksIcon />;
      }
    }
  }, [type]);

  const getStartedBy = useMemo(() => {
    switch (type) {
      case "products": {
        return " creating your first product";
      }
      case "prices": {
        return " adding your first product";
      }
      case "price lists": {
        return " adding your first price list";
      }
      case "orders": {
        return " creating your first order";
      }
      case "drafts": {
        return " creating your first draft";
      }
      case "customers": {
        return " adding your first customer";
      }
      case "discounts": {
        return " adding your first discount";
      }
      case "messages": {
        return " messaging your \nteam or customers";
      }
      case "reps": {
        return " adding your first rep";
      }
      case "routes": {
        return "";
      }
      case "task": {
        return " adding your first task";
      }
      case "form": {
        return " adding your first form";
      }
    }
  }, [type]);

  const buttonLabel = useMemo(() => {
    switch (type) {
      case "products": {
        return "Add Product";
      }
      case "prices": {
        return "Add Product";
      }
      case "price lists": {
        return "Add price list";
      }
      case "orders": {
        return "Create order";
      }
      case "drafts": {
        return "Create draft";
      }
      case "customers": {
        return "Add Customer";
      }
      case "discounts": {
        return "Add Discount";
      }
      case "messages": {
        return "Send message";
      }
      case "reps": {
        return "Add Representative";
      }
      case "routes": {
        return "Create route";
      }
      case "task": {
        return "Create task";
      }
      case "form": {
        return "Create form";
      }
    }
  }, [type]);

  const disabledBtn = useMemo(() => {
    return disabled || isAdmin;
  }, [disabled, isAdmin]);

  return (
    <Box className={classes.emptyScreenWrapper}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {icon}
          <Typography className={classes.notFoundText}>
            No {type === "prices" ? "products" : type} found
          </Typography>
          {showAction && (
            <>
              {type === "routes" ? (
                <Box width="200px">
                  <Typography
                    fontSize={14}
                    fontWeight={400}
                    lineHeight="17px"
                    color="#1C1C19"
                    textAlign="center"
                  >
                    Help sales reps visit all the right locations. Efficiently.
                  </Typography>
                </Box>
              ) : (
                <Typography className={classes.getStartedText}>
                  Get started by{getStartedBy}
                </Typography>
              )}
              <StyledTooltip
                arrow
                placement={tooltipPlacement}
                title={tooltipTitle}
                disableHoverListener={!disabledBtn}
                disableFocusListener={!disabledBtn}
                PopperProps={{
                  modifiers: [{ name: "offset", options: { offset } }],
                }}
                {...tooltipProps}
              >
                <Box mt="24px" component="span">
                  <StyledButton
                    disabled={disabledBtn}
                    label={buttonLabel}
                    variant="contained"
                    className={classes.confirmButton}
                    fontSize="15px"
                    onClick={onConfirm}
                  />
                </Box>
              </StyledTooltip>
            </>
          )}
        </>
      )}
    </Box>
  );
};

EmptyScreen.propTypes = {
  type: string,
  onConfirm: func,
  height: string,
  showAction: bool,
  loading: bool,
  disabled: bool,
  tooltipTitle: oneOfType([string, object]),
  tooltipPlacement: string,
  tooltipProps: object,
  offset: array,
};

export default EmptyScreen;
