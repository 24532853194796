import React from "react";
import { func, string } from "prop-types";
import { Box, IconButton, Typography } from "@mui/material";
import { OutlinedPlusIcon } from "components/Icons";

export const AddContentButton = ({ label, onClick = () => {} }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        gap: "8px",
        width: "fit-content",
      }}
    >
      <IconButton
        disableRipple
        sx={{
          p: 0,
        }}
      >
        <OutlinedPlusIcon size={21} circleColor="#707070" plusColor="#47A06D" />
      </IconButton>

      <Typography color="#5F6267" fontSize={13}>
        {label}
      </Typography>
    </Box>
  );
};

AddContentButton.propTypes = {
  label: string,
  onClick: func,
};
