export const cl = {
  moqInput: {
    fontSize: "14px",
    borderRadius: "6px",
    fontWeight: 300,
    width: "100%",
    height: "44px",
    borderWidth: "0.5px !important",

    "& input": {
      padding: "6px 8px",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "& input[type=number]": {
      MozAppearance: "textfield",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "0.5px solid #D5D9D9",
    },
  },

  toggleBtn: {
    width: "36px",
    height: "36px",
    borderRadius: "4px",
    m: "0px 4px 0px 0px",
    color: "black",
  },
};
