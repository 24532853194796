import axios from "axios";

const ENDPOINTS = "/price-lists";

export const createPriceListService = async (data) => {
  return await axios.post(ENDPOINTS, data).then((res) => res.data);
};

export const updatePriceListService = async (id, data) => {
  return await axios.put(`${ENDPOINTS}/${id}`, data).then((res) => res.data);
};

export const updatePriceListProductsService = async (id, data) => {
  return await axios
    .put(`${ENDPOINTS}/${id}/assign-products`, data)
    .then((res) => res.data);
};

export const updateProductsPriceListService = async (id, data) => {
  return await axios
    .put(`${ENDPOINTS}/products/${id}`, data)
    .then((res) => res.data);
};

export const updateCustomersPriceListService = async (id, data) => {
  return await axios
    .put(`${ENDPOINTS}/${id}/assign-customers`, data)
    .then((res) => res.data);
};

export const bulkUpdateProductsPriceListService = async (data) => {
  return await axios.put(`${ENDPOINTS}/products`, data).then((res) => res.data);
};

export const getPriceListsService = async (params) => {
  return await axios.get(ENDPOINTS, { params }).then((res) => res.data);
};

export const getPriceListsByIdService = async (id) => {
  return await axios.get(`${ENDPOINTS}/${id}`).then((res) => res.data);
};

export const getPriceListsProductsByCustomerIdService = async (id) => {
  return await axios.get(`${ENDPOINTS}/products/${id}`).then((res) => res.data);
};

export const getProductsPriceListsByIdService = async (id, params) => {
  return await axios
    .get(`${ENDPOINTS}/${id}/products`, { params })
    .then((res) => res.data);
};

export const deleteProductsPriceListService = async (data) => {
  return await axios
    .delete(`${ENDPOINTS}/products`, { data })
    .then((res) => res.data);
};

export const deleteCustomersPriceListService = async (data) => {
  return await axios
    .delete(`${ENDPOINTS}/customers`, { data })
    .then((res) => res.data);
};

export const deletePriceListService = async (data) => {
  return await axios.delete(ENDPOINTS, { data }).then((res) => res.data);
};

export const getCustomersPriceListsByIdService = async (id, params) => {
  return await axios
    .get(`${ENDPOINTS}/${id}/customers-flat`, { params })
    .then((res) => res.data);
};
export const getSortedCustomersPriceListsByIdService = async (id, params) => {
  return await axios
    .get(`${ENDPOINTS}/${id}/customers-sorted`, { params })
    .then((res) => res.data);
};

export const getSortedCustomersByParentIdService = async (
  id,
  customerId,
  params
) => {
  return await axios
    .get(`${ENDPOINTS}/${id}/customers-sorted/${customerId}`, { params })
    .then((res) => res.data);
};
