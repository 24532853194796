import { handleError } from "helpers/helpers";
import { useCallback, useEffect, useState } from "react";
import { getFormsWithResponsesService } from "services/forms";

const initState = {
  loading: true,
  data: null,
};

export const useFormViewResponsesPage = ({ open = false, formId, params }) => {
  const [responsesPageState, setResponsesPageState] = useState(initState);

  const fetchResponses = useCallback(async () => {
    try {
      const res = await getFormsWithResponsesService({ id: formId, params });

      setResponsesPageState((prev) => ({ ...prev, data: res }));
    } catch (error) {
      handleError(error);
    } finally {
      setResponsesPageState((prev) => ({ ...prev, loading: false }));
    }
  }, [formId, params]);

  useEffect(() => {
    if (!formId) return;

    open && fetchResponses();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, open]);

  return {
    ...responsesPageState,
  };
};
