import { useMemo } from "react";
import { usePersonalDetails } from "./components/SettingsTabs/PaymentsTab/components/PayoutsTab/PayoutsTab.hooks";
import { useRepsPermissions } from "helpers/hooks";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  ADMIN_ONLY_VIEW_MESSAGE_PAGE,
} from "utils/constants";

export const useSettings = () => {
  const { allowedToAddCard, getPayoutWarningText } = usePersonalDetails({
    isSetup: false,
  });

  const TAB_NAMES = useMemo(
    () => [
      "Profile",
      "Payments",
      "Representatives",
      "Orders",
      "Inventory",
      "Payment Terms",
      "Import/Export",
      "Integrations",
      "Order Direct",
      "Customers",
    ],
    []
  );

  const repPermissions = useRepsPermissions();

  const SETTINGS_TABS = useMemo(
    () => [
      {
        value: TAB_NAMES[0],
        label: TAB_NAMES[0],
        disabled: repPermissions ? !repPermissions?.settings?.profile : false,
        showTooltip: repPermissions
          ? !repPermissions?.settings?.profile
          : false,
        tooltipText:
          !!repPermissions && !repPermissions?.settings?.profile
            ? repPermissions?.role === "SALES"
              ? ADMIN_ONLY_VIEW_MESSAGE
              : ADMIN_ONLY_VIEW_MESSAGE_PAGE
            : "",
      },
      {
        value: TAB_NAMES[1],
        label: TAB_NAMES[1],
        disabled: repPermissions ? !repPermissions?.settings?.payments : false,
        showTooltip: repPermissions
          ? !repPermissions?.settings?.payments
          : false,
        tooltipText:
          !!repPermissions && !repPermissions?.settings?.payments
            ? repPermissions?.role === "SALES"
              ? ADMIN_ONLY_VIEW_MESSAGE
              : ADMIN_ONLY_VIEW_MESSAGE_PAGE
            : "",
      },
      {
        value: TAB_NAMES[2],
        label: TAB_NAMES[2],
        disabled: repPermissions
          ? !repPermissions?.settings?.representatives
          : false,
        showTooltip: repPermissions
          ? !repPermissions?.settings?.representatives
          : false,
        tooltipText:
          !!repPermissions && !repPermissions?.settings?.representatives
            ? ADMIN_ONLY_VIEW_MESSAGE
            : "",
      },
      {
        value: TAB_NAMES[3],
        label: TAB_NAMES[3],
        disabled: repPermissions ? !repPermissions?.settings?.orders : false,
        showTooltip: repPermissions ? !repPermissions?.settings?.orders : false,
        tooltipText:
          !!repPermissions && !repPermissions?.settings?.orders
            ? repPermissions?.role === "SALES"
              ? ADMIN_ONLY_VIEW_MESSAGE
              : ADMIN_ONLY_VIEW_MESSAGE_PAGE
            : "",
      },
      {
        value: TAB_NAMES[4],
        label: TAB_NAMES[4],
        disabled: repPermissions ? !repPermissions?.settings?.inventory : false,
        showTooltip: repPermissions
          ? !repPermissions?.settings?.inventory
          : false,
        tooltipText:
          !!repPermissions && !repPermissions?.settings?.inventory
            ? repPermissions?.role === "SALES"
              ? ADMIN_ONLY_VIEW_MESSAGE
              : ADMIN_ONLY_VIEW_MESSAGE_PAGE
            : "",
      },
      {
        value: TAB_NAMES[5],
        label: TAB_NAMES[5],
        disabled: repPermissions
          ? !repPermissions?.settings?.payment_terms
          : false,
        showTooltip: repPermissions
          ? !repPermissions?.settings?.payment_terms
          : false,
        tooltipText:
          !!repPermissions && !repPermissions?.settings?.payment_terms
            ? repPermissions?.role === "SALES"
              ? ADMIN_ONLY_VIEW_MESSAGE
              : ADMIN_ONLY_VIEW_MESSAGE_PAGE
            : "",
      },
      {
        value: TAB_NAMES[6],
        label: TAB_NAMES[6],
        disabled: repPermissions
          ? !(
              repPermissions?.customers?.import ||
              repPermissions?.customers?.export ||
              repPermissions?.catalog?.import ||
              repPermissions?.catalog?.export ||
              repPermissions?.orders?.import ||
              repPermissions?.orders?.export
            )
          : false,
        showTooltip: repPermissions
          ? !(
              repPermissions?.customers?.import ||
              repPermissions?.customers?.export ||
              repPermissions?.catalog?.import ||
              repPermissions?.catalog?.export ||
              repPermissions?.orders?.import ||
              repPermissions?.orders?.export
            )
          : false,
        tooltipText:
          repPermissions &&
          !(
            repPermissions?.customers?.import ||
            repPermissions?.customers?.export ||
            repPermissions?.catalog?.import ||
            repPermissions?.catalog?.export ||
            repPermissions?.orders?.import ||
            repPermissions?.orders?.export
          )
            ? repPermissions?.role === "SALES"
              ? ADMIN_ONLY_VIEW_MESSAGE
              : ADMIN_ONLY_VIEW_MESSAGE_PAGE
            : "",
      },
      {
        value: TAB_NAMES[7],
        label: TAB_NAMES[7],
        disabled: repPermissions
          ? !repPermissions?.settings?.integrations
          : false,
        showTooltip: repPermissions && !repPermissions?.settings?.integrations,
        tooltipText:
          repPermissions?.role === "SALES"
            ? ADMIN_ONLY_VIEW_MESSAGE
            : ADMIN_ONLY_VIEW_MESSAGE_PAGE,
      },
      {
        value: TAB_NAMES[8],
        label: TAB_NAMES[8],
        disabled: repPermissions
          ? !repPermissions?.settings?.order_direct
          : !allowedToAddCard,
        showTooltip: repPermissions
          ? !repPermissions?.settings?.order_direct
          : !allowedToAddCard,
        tooltipText:
          repPermissions && !repPermissions?.settings?.order_direct
            ? repPermissions?.role === "SALES"
              ? ADMIN_ONLY_VIEW_MESSAGE
              : ADMIN_ONLY_VIEW_MESSAGE_PAGE
            : getPayoutWarningText(),
      },
      {
        value: TAB_NAMES[9],
        label: TAB_NAMES[9],
        disabled: repPermissions ? !repPermissions?.settings?.customers : false,
        showTooltip: repPermissions
          ? !repPermissions?.settings?.customers
          : false,
        tooltipText:
          !!repPermissions && !repPermissions?.settings?.customers
            ? ADMIN_ONLY_VIEW_MESSAGE
            : "",
      },
    ],
    [TAB_NAMES, allowedToAddCard, getPayoutWarningText, repPermissions]
  );

  const getLocationError = (field, formField) => {
    const parentField = field.split(".");

    if (!field || (parentField && !formField[parentField[[0]]].street))
      return "field is required";
    return "";
  };

  return { SETTINGS_TABS, getLocationError };
};
