import { handleError } from "helpers/helpers";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formsFilterAction } from "redux/actions/forms";
import { formsFilterSelector } from "redux/selectors/forms";
import { createSelector } from "reselect";
import { getFormsService } from "services/forms";

const selector = createSelector(formsFilterSelector, (formsFilter) => ({
  formsFilter,
}));

export const initFormsState = {
  loadingFormsList: true,
  countForms: 0,
  existDataForms: null,
  formsList: [],
};

export const useForms = () => {
  const dispatch = useDispatch();

  const { formsFilter } = useSelector(selector);

  const [formsState, setFormsState] = useState(initFormsState);

  const [checkedForms, setCheckedForms] = useState([]);
  const [allFormsChecked, setAllFormsChecked] = useState(false);

  const fetchFormsList = useCallback(async () => {
    try {
      setFormsState((prev) => ({ ...prev, loadingFormsList: true }));

      const { count, existData, rows } = await getFormsService(formsFilter);

      const formsList = Array.isArray(rows) ? rows : [];

      setFormsState((prev) => ({
        ...prev,
        countForms: count || 0,
        existDataForms: !!existData,
        formsList:
          formsFilter.page > 1 ? [...prev.formsList, ...formsList] : formsList,
      }));
      if (!formsFilter.page && !formsFilter.limit) {
        setCheckedForms(formsList);
        setAllFormsChecked(true);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setFormsState((prev) => ({ ...prev, loadingFormsList: false }));
    }
  }, [formsFilter]);

  const handleSetSortBy = useCallback(
    (data) => {
      dispatch(formsFilterAction({ ...formsFilter, ...data, page: 1 }));
    },
    [dispatch, formsFilter]
  );

  return {
    ...formsState,
    setFormsState,
    formsFilter,
    fetchFormsList,
    handleSetSortBy,
    checkedForms,
    setCheckedForms,
    allFormsChecked,
    setAllFormsChecked,
  };
};
