import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  emptyScreenWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // minHeight: "100%",
    justifyContent: "center",
    height: ({ height }) => height || "50vh",
    // height: "calc(100vh - 453px)",
  },

  notFoundText: {
    fontSize: "16px",
    fontWeight: "700",
    color: "#1C1C19",
    lineHeight: "19px",
  },

  getStartedText: {
    fontSize: "14px",
    color: "#1C1C19",
    marginTop: "8px",
    lineHeight: "17px",
    whiteSpace: "pre-wrap",
    textAlign: "center",
  },

  confirmButton: {
    backgroundColor: "#3D9A6B",
    fontSize: "10px",
    width: "184px",
    height: "44px",
  },
}));

export default useStyles;
