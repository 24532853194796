import React from "react";
import { string, func, bool, object } from "prop-types";
import { Box, Typography } from "@mui/material";
import useStyles from "./styles";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { StyledDatePicker } from "components";
import AdapterMoment from "@mui/lab/AdapterMoment";

const FulfillBy = ({
  value,
  onChange,
  hideTitle,
  placeholder,
  inputProps,
  titleProps,
  disabled,
  error = "",
  inputSx = {},
  textFieldProps,
}) => {
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const { timeZone } = currentUser || {};

  const classes = useStyles();
  return (
    <Box className={classes.fulfillWrapper} {...titleProps}>
      {hideTitle ? null : (
        <Typography
          fontSize="clamp(15px, 1.3vw, 25px)"
          fontWeight={600}
          color="#707070"
        >
          Fulfill by
        </Typography>
      )}
      <StyledDatePicker
        dateLibInstance={moment}
        dateAdapter={AdapterMoment}
        value={moment.tz(value, timeZone)}
        disablePast
        wrapperClassName={classes.calendarInput}
        textFieldProps={{
          fullWidth: true,
          error,
          ...textFieldProps,
        }}
        inputProps={{ placeholder: placeholder || "Due date", ...inputProps }}
        error={error}
        disabled={disabled}
        onChange={onChange}
        inputSx={inputSx}
      />
    </Box>
  );
};

FulfillBy.propTypes = {
  value: string,
  onChange: func,
  hideTitle: bool,
  placeholder: string,
  inputProps: object,
  error: string,
  titleProps: object,
  textFieldProps: object,
  inputSx: object,
  disabled: bool,
};

FulfillBy.defaultProps = {
  value: "-",
  onChange: () => {},
  hideTitle: false,
  placeholder: "",
  inputProps: {},
  error: {},
  titleProps: {},
  textFieldProps: {},
  inputSx: {},
  disabled: false,
};

export default FulfillBy;
