import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  openConfirmDialogAction,
  openDiscardChanges,
  setConfirmIsOpenAction,
  setEditTypeAction,
  setFormChangedAction,
} from "../../redux/actions/confirmDialogs";
import { makeStyles } from "@mui/styles";
import {
  Button,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { ReactComponent as SearchIcon } from "./search.svg";
import PropTypes, {
  array,
  bool,
  func,
  string,
  object,
  oneOf,
} from "prop-types";
import StyledButton from "../StyledButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "../Icons";
import ValidationPopper from "../ValidationPopper/ValidationPopper";
import { setCurrentTab } from "../../redux/actions/reps";
import { createSelector } from "reselect";
import { currentUserSelector } from "../../redux/selectors/auth";
import { updateUserFieldAction } from "../../redux/actions/auth";
import { useAdmin } from "helpers/helpers";
import { repsLoadingSelector } from "redux/selectors/reps";
import { ActionRestrictionWrapper } from "components";
import { ADMIN_ONLY_VIEW_MESSAGE_PAGE } from "utils/constants";

const useStyles = makeStyles(() => ({
  label: {
    "& .MuiFormLabel-root": {
      color: "#B5B5AC",
      fontSize: "12px",
      top: "2px",
    },
    "& .Mui-focused": {
      color: "#42A57F",
      fontSize: "1rem",
      top: "0px",
    },
    "& .MuiOutlinedInput-root": {
      height: "100%",
    },
  },
  tabs: {
    minHeight: "auto",
    height: "44px",
    "& .Mui-selected": {
      backgroundColor: "transparent!important",
    },
  },
  smallerButton: {
    height: "24px",
    "& > span": {
      fontSize: "11px",
    },
  },

  biggerButton: {
    "& > span": {
      fontSize: "14px",
    },
  },
  tabSeparator: {
    "&:last-child::after": {
      content: '""',
      position: "absolute",
      height: "16px",
      width: "1px",
      backgroundColor: "#9C9C94",
      left: 0,
    },
  },
}));

const selector = createSelector(
  currentUserSelector,
  repsLoadingSelector,
  (currentUser, repsLoadingSelector) => ({
    currentUser,
    repsLoadingSelector,
  })
);

export const PageTabComponent = ({
  title,
  tabs,
  searchInputName,
  isReportBtn,
  addBtnName,
  addGroupBtn,
  handleSetCurrentTab,
  saveButtons,
  currentTab,
  isEdit,
  handleDelete,
  form,
  navigatePath,
  errors,
  validationNames,
  duplicateNamesError,
  trigger,
  nameValidation,
  customIdValidation,
  formField,
  titleName,
  setCheckedUsers,
  loading,
  repPermissions,
}) => {
  const classes = useStyles();
  const isAdmin = useAdmin();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();

  const disabledRepPermissions = useMemo(() => {
    return !!repPermissions && addBtnName === "Add Representative";
  }, [repPermissions, addBtnName]);

  const formChanged = useSelector(
    ({ confirmDialogs }) => confirmDialogs.formChanged
  );
  const editType = useSelector(({ confirmDialogs }) => confirmDialogs.editType);
  const { currentUser, repsLoadingSelector } = useSelector(selector);
  const dispatch = useDispatch();
  const refSaveBtn = useRef();
  const [showTooltip, setShowTooltip] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const hasRepsLimitBeenReached = useMemo(() => {
    const allowedUsers =
      currentUser?.distributorSubscription?.allowedUsers || 5;
    const extraAllowedUsers =
      currentUser?.distributorSubscription?.extraUser?.extraAllowedUsers || 0;

    const usedSubscriptions = currentUser?.countRepresentatives || 0;
    return !(usedSubscriptions < allowedUsers + extraAllowedUsers);
  }, [
    currentUser?.countRepresentatives,
    currentUser?.distributorSubscription?.allowedUsers,
    currentUser?.distributorSubscription?.extraUser?.extraAllowedUsers,
  ]);

  const isDisabled = useMemo(() => {
    if (titleName === "discount") {
      return (
        !formField?.name ||
        !formField?.discount ||
        !formField?.manufacturer?.id ||
        !!errors?.discount
      );
    }
    if (titleName === "manufacturer") {
      return (
        !formField?.name || !!duplicateNamesError?.length || !!errors?.name
      );
    }
    if (titleName === "customer") {
      if (!formField?.shippingIsSame) {
        if (
          !formField?.shippingAddress?.city ||
          !formField?.shippingAddress?.state ||
          !formField?.shippingAddress?.street ||
          !formField?.shippingAddress?.zip
        )
          return true;
      }

      if (Object.keys(errors)?.length) {
        return (
          !!errors?.billingAddress ||
          !!errors?.shippingAddress ||
          !!errors?.groupId ||
          !!errors?.name
        );
      }

      return (
        !formField?.name ||
        !formField?.groupId ||
        !formField?.billingAddress?.formatted_address ||
        !formField?.billingAddress?.city ||
        !formField?.billingAddress?.state ||
        !formField?.billingAddress?.street ||
        !formField?.billingAddress?.zip ||
        nameValidation ||
        customIdValidation
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    titleName,
    formField?.name,
    formField?.discount,
    formField?.manufacturer?.id,
    formField?.shippingIsSame,
    formField?.groupId,
    formField?.billingAddress?.formatted_address,
    formField?.billingAddress?.city,
    formField?.billingAddress?.state,
    formField?.billingAddress?.street,
    formField?.billingAddress?.zip,
    formField?.shippingAddress?.city,
    formField?.shippingAddress?.state,
    formField?.shippingAddress?.street,
    formField?.shippingAddress?.zip,
    errors?.discount,
    errors?.billingAddress,
    errors?.shippingAddress,
    errors?.groupId,
    errors?.name,
    duplicateNamesError,
    nameValidation,
    customIdValidation,
  ]);

  useEffect(() => {
    dispatch(setCurrentTab(currentTab));
  }, [currentTab, dispatch]);

  const handleChangeCurrentTab = (e, newValue) => {
    if (newValue === "Delivery") return;
    handleSetCurrentTab(newValue);
    dispatch(setCurrentTab(newValue));
  };

  const handleShowTooltip = useCallback((tab) => {
    if (tab === "Delivery") setShowTooltip(true);
  }, []);

  const handleHideTooltip = useCallback((tab) => {
    if (tab === "Delivery") setShowTooltip(false);
  }, []);

  const handleConfirmChangesDialog = useCallback(() => {
    dispatch(openDiscardChanges(() => navigate(-1, { state: { currentTab } })));
  }, [currentTab, dispatch, navigate]);

  const handleConfirmCheckedItemsDialog = useCallback(
    (nav, callback) => {
      dispatch(
        openConfirmDialogAction({
          path: nav,
          title: "Leaving this page?",
          text: "Your selected list will be discarded.",
          buttons: (
            <>
              <StyledButton
                variant="outlined"
                color="cancel"
                type="submit"
                form={`new-${editType}-form`}
                label="Cancel"
                sx={{ height: "28px" }}
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                }}
              />
              <Button
                sx={{
                  width: "auto",
                  height: "28px",
                  color: "#FFFFFF",
                  fontSize: "13px",
                  boxShadow: "none",
                }}
                color="confirmDelete"
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                  dispatch(setFormChangedAction(false));
                  setCheckedUsers([]);
                  dispatch(setEditTypeAction(""));
                  if (callback) return callback();
                  navigate(nav);
                }}
                variant="contained"
              >
                Confirm
              </Button>
            </>
          ),
        })
      );
    },
    [dispatch, editType, setCheckedUsers, navigate]
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        px: "32px",
        backgroundColor: "#F0F0F0",
        borderBottom: "0.5px solid #D4D4D4",
        height: "44px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box>
          {title && (
            <Typography
              variant="h6"
              fontWeight="400"
              fontSize="20px"
              color="#9C9C94"
            >
              {title}
            </Typography>
          )}
        </Box>
        <Box>
          {tabs && (
            <Tabs
              className={classes.tabs}
              value={currentTab}
              onChange={handleChangeCurrentTab}
              // sx={{ zIndex: 10 }}
            >
              {tabs.map((tab) => (
                <Tab
                  className={classes.tabSeparator}
                  sx={{
                    py: 0,
                    height: "44px",
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "#9C9C94",
                  }}
                  key={tab}
                  label={<Box sx={{ textTransform: "none" }}>{tab}</Box>}
                  value={tab}
                  // disabled={tab === "Delivery" || tab === "Sales"}
                  onMouseEnter={() => handleShowTooltip(tab)}
                  onMouseLeave={() => handleHideTooltip(tab)}
                />
              ))}
            </Tabs>
          )}
          {showTooltip && (
            <Box
              sx={{
                position: "absolute",
                background: "#707070",
                p: "10px",
                borderRadius: "40px",
                zIndex: 2,
              }}
            >
              <Typography variant="body2" fontSize="10px" color="white">
                This page will be available in the new version of this
                application
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 1,
        }}
      >
        {searchInputName && (
          <TextField
            sx={{ backgroundColor: "#F4F6F9" }}
            label={searchInputName}
            className={classes.label}
            size="small"
            InputLabelProps={{
              style: { top: -2 },
            }}
            InputProps={{
              style: { height: "28px" },
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
        {isReportBtn && (
          <StyledButton
            sx={{
              height: 28,
            }}
            color="groundLighter"
            variant="outlined"
            label="Report"
          />
        )}
        {addGroupBtn && (
          <StyledButton
            disabled={isAdmin}
            sx={{
              height: 28,
            }}
            color="primary"
            variant="outlined"
            label="Add group"
            component={Link}
            to={`${pathname}/new/group`}
          />
        )}
        {addBtnName && (
          <ActionRestrictionWrapper
            disableHoverListener={!disabledRepPermissions}
            title={ADMIN_ONLY_VIEW_MESSAGE_PAGE}
            placement="bottom"
          >
            <StyledButton
              disabled={
                disabledRepPermissions || isAdmin || repsLoadingSelector
              }
              sx={{
                height: 28,
                boxShadow: "none",
              }}
              variant="contained"
              label={addBtnName}
              onClick={(e) => {
                if (formChanged && editType === "checked_items") {
                  e.preventDefault();
                  handleConfirmCheckedItemsDialog(
                    `${pathname}/new`,
                    hasRepsLimitBeenReached && currentTab !== "3rd Party"
                      ? () =>
                          dispatch(
                            updateUserFieldAction(
                              "showReachedUsersLimitDialog",
                              {
                                isOpen: true,
                              }
                            )
                          )
                      : null
                  );
                  return;
                }
                if (hasRepsLimitBeenReached && currentTab !== "3rd Party") {
                  e.preventDefault();
                  dispatch(
                    updateUserFieldAction("showReachedUsersLimitDialog", {
                      isOpen: true,
                    })
                  );
                }
              }}
              component={Link}
              to={`${pathname}/new`}
              state={state?.type === "onboarding" && { type: "onboarding" }}
              from={pathname}
            />
          </ActionRestrictionWrapper>
        )}
        {saveButtons && (
          <>
            <StyledButton
              color="greySecondary"
              label="Back"
              sx={{ p: 0 }}
              className={classes.biggerButton}
              component={Link}
              to={{
                pathname: formChanged
                  ? location.pathname
                  : navigatePath || "/catalog",
                state: { state },
              }}
              state={state}
              from={pathname}
              onClick={() => {
                if (formChanged) {
                  handleConfirmChangesDialog();
                }
              }}
              startIcon={<ArrowLeftIcon />}
            />
            {/* hide delete if user is connected to quickbooks */}
            {!currentUser?.quickBooksTokens && (
              <StyledButton
                sx={{ fontSize: "11px" }}
                className={classes.smallerButton}
                color="error"
                disabled={isAdmin || !isEdit}
                onClick={handleDelete}
                variant="outlined"
                label="Delete"
              />
            )}
            <Box
              onMouseEnter={(e) => {
                setIsOpen(true);
                setAnchorEl(e.target);
                if (trigger) {
                  const billingDuplicate =
                    errors?.billingAddress?.formatted_address?.type ===
                    "duplicate";
                  const shippingDuplicate =
                    errors?.shippingAddress?.formatted_address?.type ===
                    "duplicate";

                  if (billingDuplicate || shippingDuplicate) {
                    const arr = [];
                    if (billingDuplicate) arr.push("billingAddress");
                    if (shippingDuplicate) arr.push("shippingAddress");

                    return trigger(
                      validationNames.filter((item) => !arr.includes(item))
                    );
                  }

                  if (duplicateNamesError?.length) {
                    return trigger(
                      validationNames?.filter(
                        (item) => !duplicateNamesError.includes(item)
                      )
                    );
                  }

                  trigger();
                }
              }}
              component="span"
            >
              <StyledButton
                disabled={isAdmin || isDisabled || loading}
                ref={refSaveBtn}
                className={classes.smallerButton}
                variant="contained"
                type="submit"
                form={form}
                label="Save"
                onClick={(e) => {
                  setIsOpen(true);
                  setAnchorEl(e.target);
                }}
              />
            </Box>
          </>
        )}
        <ValidationPopper
          isOpen={isOpen}
          anchorEl={anchorEl}
          errors={errors}
          setIsOpen={setIsOpen}
          tailProps={{
            top: -6,
            right: 48,
            rotate: -180,
          }}
          offset={[20, 16]}
        />
      </Box>
    </Box>
  );
};

PageTabComponent.propTypes = {
  title: PropTypes.oneOfType([string, object]),
  tabs: array,
  searchInputName: string,
  isReportBtn: bool,
  addBtnName: string,
  addGroupBtn: bool,
  handleSetCurrentTab: func,
  handleOpenConfirmDialog: func,
  onSubmit: func,
  saveButtons: bool,
  currentTab: string,
  isEdit: bool,
  handleDelete: func,
  form: string,
  navigateState: string,
  navigatePath: string,
  errors: object,
  validationNames: array,
  duplicateNamesError: array,
  trigger: func,
  nameValidation: bool,
  customIdValidation: bool,
  formField: object,
  titleName: oneOf(["customer", "manufacturer", "discount"]),
  setCheckedUsers: func,
  loading: bool,
  repPermissions: object,
};
PageTabComponent.defaultProps = {
  title: "",
  tabs: [],
  searchInputName: "",
  isReportBtn: false,
  addBtnName: "",
  addGroupBtn: false,
  handleSetCurrentTab: () => null,
  saveButtons: false,
  isEdit: false,
  isChanged: false,
  form: "",
  navigatePath: "",
  duplicateNamesError: [],
  loading: false,
};

export default PageTabComponent;
