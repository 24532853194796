export const cl = {
  filterWrapper: {
    backgroundColor: "#fff",
    height: "64px",
    border: "0.5px solid #D5D9D9",
    borderRadius: "4px 4px 0 0",
    marginTop: "12px",
    padding: "12px 22px 12px 16px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  filterChip: {
    borderRadius: "4px",
    border: "0.7px solid #d5d9d9",
    backgroundColor: "#FAFAFB",
    height: "28px",
  },

  filterBtn: {
    height: "39px",
    width: "100%",
    maxWidth: "74px",
    border: "0.5px solid #D5D9D9",
    "& .MuiButton-startIcon": {
      ml: 0,
    },
  },

  filterSearchBlock: {
    flexGrow: 1,
  },

  chipWrapper: {
    py: "4px",
    overflow: "auto",
    gap: "5px",
    "&::-webkit-scrollbar": { height: "2px" },
  },

  adornmentPropsSx: { justifyContent: "flex-end" },

  searchFromSx: {
    minWidth: "380px",
  },
};
