import { styled } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { StyledSelect } from "components";

const useStyles = makeStyles(() => ({
  filterWrapper: {
    backgroundColor: "#ffffff",
    borderRadius: "4px 4px 0 0",
    display: "flex",
    border: "0.5px solid #d5d9d9",
    // borderBottom: "none",
    alignItems: "center",
    padding: "12px 18px 12px 12px",
    height: "62px",
    gap: "12px",
  },

  filterChip: {
    borderRadius: "4px",
    border: "0.7px solid #d5d9d9",
    backgroundColor: "#FAFAFB",
    height: "28px",
  },

  searchButton: {
    margin: "8px",
    minWidth: "fit-content",
    borderRadius: "4px",
  },

  divider: {
    height: "14px",
    borderColor: "#5F6267",
    // marginTop: "50%",
    // transform: "translateY(50%)",
  },
}));

export const FilterSelect = styled(StyledSelect)(({ theme: { palette } }) => ({
  background: palette.primary.transparent,
  color: palette.primary.main,
  height: "39px",
  width: "100%",
  "& fieldset": {
    border: "none",
  },

  "& .MuiSelect-select": {
    padding: "0!important",
    paddingLeft: "15px !important",
    paddingRight: "36px !important",

    height: "39px",
    lineHeight: "39px",
    fontSize: "17px",
    width: "100%",
  },

  "& .MuiSvgIcon-root": {
    right: "4px",
    fill: palette.primary.main,
    fontSize: "32px",
    transition: "0.4s all ease",
    "&.MuiSelect-iconOpen": {
      transform: "scale(0.75) rotate(180deg)",
    },
  },
}));

export default useStyles;
