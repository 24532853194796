import * as React from "react";
export const CopyOutlinedIcon = (props) => (
  <svg
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.273 5.396V3.759c0-.903-.733-1.636-1.637-1.636h-6C2.733 2.123 2 2.856 2 3.76v6c0 .904.733 1.637 1.636 1.637h1.637m6-6h1.09c.904 0 1.637.732 1.637 1.636v5.455c0 .903-.733 1.636-1.636 1.636H6.909a1.636 1.636 0 0 1-1.636-1.636v-1.091m6-6H6.909c-.904 0-1.636.732-1.636 1.636v4.364"
      stroke="#000"
      strokeOpacity={0.4}
      strokeWidth={1.091}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
