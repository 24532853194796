import { useState } from "react";
import { TABS_RESPONSES_PAGE } from "./FormViewPage.constants";

export const useResponsesTab = () => {
  const [currentResponseTab, setCurrentResponseTab] = useState(
    TABS_RESPONSES_PAGE[0]?.value
  );

  const handleChangeResponsesTab = (tab) => {
    setCurrentResponseTab(tab);
  };

  return {
    currentResponseTab,
    handleChangeResponsesTab,
  };
};
