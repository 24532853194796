import { useState, useRef, useMemo, useEffect, useCallback } from "react";
import { func, object, bool, any, oneOf } from "prop-types";
import { useNavigate } from "react-router-dom";
import { Controller } from "react-hook-form";
import { ListItemText, MenuItem, Typography, Box } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  StyledButton,
  StyledToggleButton,
  StyledMenu,
  ValidationPopper,
  StyledSelect,
  StyledTooltip,
} from "components";
import { ArrowLeftIcon, IosArrowForward } from "components/Icons";
import { useAdmin } from "helpers/helpers";
import { cl } from "./OrderCartSwitcherTab.styles";
import { useDispatch, useSelector } from "react-redux";
import { setFetchedAction } from "redux/actions/drafts";
import { CART_TYPES, ORDER_TYPES } from "Pages/CartPage/CartPage.constants";
import { PRODUCT_TYPE_INVENTORY } from "utils/constants";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";

const OrderCartSwitcherTab = ({
  handleCreateWithEmail,
  handleCreateWithNew,
  handleCreateWithEmailLink,
  handleSaveAsDraft,
  handleUpdateDraft,
  errors,
  isEdit,
  formField,
  trigger,
  //hasChosenCustomerEmail,
  control,
  checkSellingOutStock,
  isDuplicate,
  cartType,
  isButtonDisabled,
  handleSetDefaultSalesId,
  clearErrors,
  freeCasesLoading,
  hasDraft,
  handleResetCart,
  createdTypeState,
  setValue,
  fieldReset,
}) => {
  const isAdmin = useAdmin();
  const dispatch = useDispatch();
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const createButtonRef = useRef();
  const refSaveBtn = useRef();
  const navigate = useNavigate();
  const [createToggled, setCreateToggled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const productsMinOrderQTY = useMemo(
    () =>
      formField?.products?.map((product) => {
        const deleted = isEdit && !product.product;
        return (
          deleted ||
          product?.quantity >=
            (product?.minOrderQTY ||
              product?.product?.minOrderQTY ||
              product?.parentProduct?.minOrderQTY ||
              product?.product?.parentProduct?.minOrderQTY)
        );
      }),
    [formField, isEdit]
  );

  const filteredProducts = useMemo(
    () => productsMinOrderQTY?.filter((value) => !!value),
    [productsMinOrderQTY]
  );

  const ArrowBor = (props) => {
    return (
      <Box
        sx={{
          top: "0px !important",
          right: "25px !important",
        }}
        {...props}
      >
        <ArrowDropDownIcon sx={cl.arrowDropDownIcon} />
      </Box>
    );
  };

  const checkDeleteProducts =
    isDuplicate && formField.products.some((el) => el.deleted === true);

  const someProductIsOutOfStock = formField.products.some((product, index) => {
    const isNonInventory =
      product?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

    return !isNonInventory && checkSellingOutStock(product, index);
  });

  const isDisableBtn = useMemo(() => {
    if (isAdmin) return;

    return (
      (!!formField.customer &&
        (formField?.createOrderType === ORDER_TYPES["3rd Party"]
          ? true
          : !!formField?.salesId) &&
        !!formField.products.length &&
        !!formField.contactId &&
        productsMinOrderQTY?.length === filteredProducts?.length) ||
      checkDeleteProducts
    );
  }, [
    isAdmin,
    formField?.customer,
    formField?.createOrderType,
    formField?.salesId,
    formField?.products?.length,
    formField?.contactId,
    productsMinOrderQTY?.length,
    filteredProducts?.length,
    checkDeleteProducts,
  ]);

  const hideBtnList = useMemo(() => {
    return ![CART_TYPES.duplicate_draft].includes(cartType);
  }, [cartType]);

  const blockCreateOrUpdate = useMemo(
    () =>
      !isDisableBtn ||
      someProductIsOutOfStock ||
      isButtonDisabled ||
      freeCasesLoading ||
      !!errors?.fulfillBy,
    [
      isDisableBtn,
      someProductIsOutOfStock,
      isButtonDisabled,
      freeCasesLoading,
      errors?.fulfillBy,
    ]
  );

  const title = useMemo(() => {
    const id = formField?.customId?.customId || formField?.customId || "-";
    let firstPart = "";
    let lastPart = "";

    if ([CART_TYPES.order, CART_TYPES.duplicate].includes(cartType)) {
      firstPart = "Cart";
      // lastPart = `Order #${id}`;
    }
    if (cartType === CART_TYPES.edit) {
      firstPart = "Edit order";
      lastPart = `#${id}`;
    }
    if ([CART_TYPES.draft].includes(cartType)) {
      firstPart = "Draft";
      lastPart = `Order #${id}`;
    }

    if ([CART_TYPES.duplicate_draft].includes(cartType)) {
      firstPart = "Draft";
    }
    return (
      <>
        {firstPart}
        {lastPart && <IosArrowForward />}
        {lastPart && <span style={{ color: "#5F6267" }}>{lastPart}</span>}
      </>
    );
  }, [cartType, formField?.customId]);

  const btnLabel = useMemo(() => {
    if (cartType === CART_TYPES.duplicate_draft) return "Save as Draft";
    if (cartType === CART_TYPES.edit) return "Update Order";

    return "Create Order";
  }, [cartType]);

  const handleOnClearClick = useCallback(
    ({ orderData }) => {
      dispatch(
        openConfirmDialogAction({
          title: "Empty your cart?",
          text: (
            <Typography fontWeight="400" fontSize="15px" whiteSpace="pre-line">
              Another draft order is in process. Empty your cart to start a new
              order.
            </Typography>
          ),
          propBtns: {
            left: {
              label: "Cancel",
              color: "cancel",
              variant: "outlined",
              sx: {
                width: "98px",
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                fontSize: "13px",
                height: "28px",
              },
            },
            right: {
              sx: {
                width: "98px",
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
              },
              label: "Proceed",
              variant: "contained",
              onClick: () => {
                dispatch(setConfirmIsOpenAction(false));
                handleResetCart({ orderData });
              },
            },
          },
        })
      );
    },
    [dispatch, handleResetCart]
  );

  // handle check and show clear cart if different types of createOrderType
  const [isEmptyCartAutomaticallyShowed, setIsEmptyCartAutomaticallyShowed] =
    useState(false);

  useEffect(() => {
    if (!fieldReset) return;

    if (
      cartType === CART_TYPES.order &&
      !isEmptyCartAutomaticallyShowed &&
      formField?.createOrderType &&
      [ORDER_TYPES.Direct, ORDER_TYPES["3rd Party"]].includes(
        formField?.createOrderType
      ) &&
      createdTypeState &&
      createdTypeState !== formField?.createOrderType
      // (formField?.products?.length || formField?.customer)
    ) {
      if (formField?.products?.length || formField?.customer) {
        handleOnClearClick({
          orderData: {
            createOrderType: createdTypeState,
          },
        });
      } else {
        handleResetCart({
          orderData: {
            createOrderType: createdTypeState,
          },
        });
      }
    }

    setIsEmptyCartAutomaticallyShowed(true);
  }, [
    hasDraft,
    cartType,
    createdTypeState,
    formField?.createOrderType,
    handleOnClearClick,
    isEmptyCartAutomaticallyShowed,
    setValue,
    formField?.products?.length,
    formField?.customer,
    fieldReset,
    handleResetCart,
  ]);

  return (
    <Box sx={cl.mainBox}>
      <ValidationPopper
        isOpen={isOpen}
        anchorEl={refSaveBtn.current}
        errors={errors}
        setIsOpen={setIsOpen}
        placement="left-start"
        modifiers={[{ name: "offset", options: { offset: [-5, 16] } }]}
      />
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        gap={3}
      >
        <Box display="inline-flex" alignItems="center">
          <Typography
            display="inline-flex"
            alignItems="center"
            gap="15px"
            fontSize="20px"
            fontWeight="400"
            color="#9C9C94"
          >
            {title}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        {!isEdit && !isDuplicate && (
          <StyledTooltip
            placement="top"
            arrow
            title="Cart is empty"
            disableHoverListener={hasDraft}
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: { offset: [0, -5] },
                },
              ],
            }}
          >
            <Box component="span">
              <StyledButton
                disabled={!hasDraft}
                color="error"
                label="Clear Cart"
                variant="outlined"
                sx={{ mr: "10px", height: "27.25px", borderColor: "#EB4233" }}
                onClick={handleOnClearClick}
              />
            </Box>
          </StyledTooltip>
        )}
        {isEdit && (
          <StyledButton
            color="greySecondary"
            label="Back"
            onClick={() => navigate(-1)}
            startIcon={<ArrowLeftIcon />}
          />
        )}
        <Box mr="10px">
          <Controller
            render={({ field }) => (
              <StyledSelect
                disabled={isAdmin || isEdit}
                height="28px"
                IconComponent={ArrowBor}
                fullWidth
                notched={false}
                data-testid="order-type-select"
                fontSize="12px"
                sx={cl.orderTypeSelect}
                color="#47A06D"
                displayEmpty
                {...field}
              >
                <MenuItem
                  value={ORDER_TYPES.Direct}
                  onClick={() => {
                    dispatch(setFetchedAction(false));
                    handleSetDefaultSalesId(
                      formField?.customer,
                      ORDER_TYPES.Direct
                    );
                  }}
                >
                  Direct
                </MenuItem>
                <MenuItem
                  value={ORDER_TYPES["3rd Party"]}
                  onClick={() => {
                    dispatch(setFetchedAction(false));
                    handleSetDefaultSalesId(
                      formField?.customer,
                      ORDER_TYPES["3rd Party"]
                    );
                    if (errors?.salesId) {
                      clearErrors("salesId");
                    }
                  }}
                >
                  3rd Party
                </MenuItem>
              </StyledSelect>
            )}
            name="createOrderType"
            control={control}
          />
        </Box>
        <Box
          ref={refSaveBtn}
          onMouseEnter={() => {
            if (blockCreateOrUpdate) {
              trigger();
              setIsOpen(true);
            }
            if (formField.fulfillBy) trigger("fulfillBy");
          }}
          onMouseLeave={() => {
            if (blockCreateOrUpdate) {
              trigger();
              setIsOpen(false);
            }
            if (formField.fulfillBy) trigger("fulfillBy");
          }}
        >
          <StyledButton
            sx={{
              height: 28,
              borderRadius: !hideBtnList ? "4px" : "4px 0 0 4px",
              pr: !hideBtnList ? null : "9px",
            }}
            variant="contained"
            label={btnLabel}
            type="submit"
            disabled={blockCreateOrUpdate}
            form="new-cart-form"
          />
          {!hideBtnList ? null : (
            <StyledToggleButton
              value="create"
              selected={createToggled}
              onChange={() => setCreateToggled(!createToggled)}
              ref={createButtonRef}
              disabled={blockCreateOrUpdate}
              sx={{
                ...cl.styledToggleButton,
                backgroundColor: !blockCreateOrUpdate
                  ? "#47A06D"
                  : "rgba(0, 0, 0, 0.12)",
              }}
              color="primary"
            >
              <ArrowDropDownIcon
                style={{
                  fill: !blockCreateOrUpdate ? "#ffffff" : "#B5B5AC",
                  fontSize: "16px",
                }}
              />
            </StyledToggleButton>
          )}
        </Box>

        <StyledMenu
          isOpen={createToggled}
          anchorEl={createButtonRef.current}
          handleClose={() => setCreateToggled(false)}
          sx={{ py: "0" }}
        >
          <Box
            sx={{
              "& .MuiTypography-root.MuiTypography-body1": {
                fontSize: "12px",
                pl: 1,
              },
            }}
          >
            {/*<StyledTooltip
              isDark
              title="No email address found for this customer"
              disableHoverListener={!!hasChosenCustomerEmail}
              arrow
            >*/}
            <Box components="span">
              <MenuItem
                onClick={handleCreateWithEmail}
                //disabled={!hasChosenCustomerEmail}
              >
                {/* <CartSendIcon /> */}
                <ListItemText>
                  {cartType === CART_TYPES.edit ? "Update" : "Create"} Order &
                  Email
                </ListItemText>
              </MenuItem>
            </Box>
            {/*</StyledTooltip>*/}

            <Box components="span">
              <MenuItem
                onClick={() => {
                  handleCreateWithNew();
                  setCreateToggled(false);
                }}
                disable={isButtonDisabled}
              >
                <ListItemText>
                  {cartType === CART_TYPES.edit ? "Update" : "Create"} Order &
                  New
                </ListItemText>
              </MenuItem>
            </Box>

            {currentUser?.stripeAccountVerificationStatus === "VERIFIED" &&
              formField?.createOrderType !== "THIRD_PARTY" && (
                <Box components="span">
                  <MenuItem
                    onClick={() => {
                      handleCreateWithEmailLink();
                      setCreateToggled(false);
                    }}
                  >
                    <ListItemText>
                      {cartType === CART_TYPES.edit ? "Update" : "Create"} Order
                      & Email Link
                    </ListItemText>
                  </MenuItem>
                </Box>
              )}

            {(cartType === CART_TYPES.order ||
              cartType === CART_TYPES.duplicate) && (
              <Box components="span">
                <MenuItem
                  onClick={() => {
                    handleSaveAsDraft();
                    setCreateToggled(false);
                  }}
                  disable={isButtonDisabled}
                >
                  <ListItemText>Save as Draft</ListItemText>
                </MenuItem>
              </Box>
            )}

            {cartType === CART_TYPES.draft && (
              <Box components="span">
                <MenuItem
                  onClick={() => {
                    handleUpdateDraft();
                    setCreateToggled(false);
                  }}
                  disable={isButtonDisabled}
                >
                  <ListItemText>Update Draft</ListItemText>
                </MenuItem>
              </Box>
            )}
          </Box>
        </StyledMenu>
      </Box>
    </Box>
  );
};

OrderCartSwitcherTab.propTypes = {
  handleCreateWithEmail: func,
  handleCreateWithNew: func,
  handleCreateWithEmailLink: func,
  handleSaveAsDraft: func,
  handleUpdateDraft: func,
  errors: object,
  isEdit: bool,
  formField: object,
  trigger: func,
  hasChosenCustomerEmail: any,
  control: object,
  setValue: func,
  isDuplicate: bool,
  cartType: oneOf(Object.values(CART_TYPES)),
  checkSellingOutStock: func,
  isButtonDisabled: bool,
  handleSetDefaultSalesId: func,
  clearErrors: func,
  handleResetCart: func,
  freeCasesLoading: bool,
  hasDraft: bool,
  createdTypeState: oneOf([ORDER_TYPES.Direct, ORDER_TYPES["3rd Party"]]),
  fieldReset: bool,
};

export default OrderCartSwitcherTab;
