import { useCallback, useContext, useEffect, useState } from "react";
import { TABS_VISIBILITY_SETTINGS_PAGE } from "./FormViewPage.constants";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import { GlobalPageContext } from "Pages/MasterPage/MasterPage";
import { error } from "utils/notifications";
import { getFormCustomersService, getFormRepsService } from "services/forms";

export const useVisibilitySettingsTab = (formId, setValue, formField) => {
  const { setGlobalLoading } = useContext(GlobalPageContext);
  const dispatch = useDispatch();
  const [currentVisibilitySettingsTab, setCurrentVisibilitySettingsTab] =
    useState(TABS_VISIBILITY_SETTINGS_PAGE[0]?.value);

  const [popupsState, setPopupsState] = useState({
    openAllCustomers: false,
    openAllReps: false,
    openEditAllCustomers: false,
  });

  const [listState, setListState] = useState({
    customersList: [],
    repsList: [],
  });

  const onEditAllCustomers = async () => {
    await getAllCustomersForPopup();
    setPopupsState((prev) => ({
      ...prev,
      openEditAllCustomers: true,
      openAllCustomers: true,
    }));
  };

  useEffect(() => {
    setListState((prev) => ({
      ...prev,
      customersList: formField?.assignedCustomers,
      repsList: formField?.assignedRepresentatives,
    }));
  }, [formField.assignedCustomers, formField.assignedRepresentatives]);

  const getAllCustomersForPopup = useCallback(async () => {
    if (formField._count.assignedCustomers <= 1 || !formId) return;
    setGlobalLoading(true);
    try {
      const res = await getFormCustomersService({
        form_id: formId,
      });

      if (!res?.rows.length && formField.assignedCustomers.length)
        return setListState((prev) => ({
          ...prev,
          customersList: formField.assignedCustomers,
        }));

      const assignedIds = formField?.assignedCustomers?.map((c) => c?.id) || [];
      const newCustomerIds = res?.rows?.map((c) => c?.id) || [];

      const filteredList = assignedIds.length
        ? res?.rows?.filter((c) => assignedIds.includes(c?.id))
        : res?.rows;

      const filteredOldCustomers = newCustomerIds.length
        ? formField?.assignedCustomers?.filter(
            (c) => !newCustomerIds.includes(c?.id)
          )
        : [];

      setListState((prev) => ({
        ...prev,
        customersList: [...filteredList, ...filteredOldCustomers] || [],
      }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setGlobalLoading(false);
    }
  }, [
    formField?._count?.assignedCustomers,
    formId,
    setGlobalLoading,
    formField?.assignedCustomers,
  ]);

  const getAllRepsForPopup = useCallback(async () => {
    if (formField._count.assignedRepresentatives <= 1 || !formId) return;
    setGlobalLoading(true);
    try {
      const res = await getFormRepsService({
        form_id: formId,
      });

      if (!res?.rows.length && formField.assignedRepresentatives.length)
        return setListState((prev) => ({
          ...prev,
          repsList: formField.assignedRepresentatives,
        }));

      const assignedIds =
        formField?.assignedRepresentatives?.map((c) => c?.id) || [];
      const newCustomerIds = res?.rows?.map((c) => c?.id) || [];

      const filteredList = assignedIds.length
        ? res?.rows?.filter((c) => assignedIds.includes(c?.id))
        : res?.rows;

      const filteredOldReps = newCustomerIds.length
        ? formField?.assignedRepresentatives?.filter(
            (c) => !newCustomerIds.includes(c?.id)
          )
        : [];

      setListState((prev) => ({
        ...prev,
        repsList: [...filteredList, ...filteredOldReps] || [],
      }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setGlobalLoading(false);
    }
  }, [
    formField._count.assignedRepresentatives,
    formField.assignedRepresentatives,
    setGlobalLoading,
    formId,
  ]);

  const onChangeAllCustomers = useCallback(
    async (open) => {
      if (open) await getAllCustomersForPopup();
      setPopupsState((prev) => ({
        ...prev,
        openAllCustomers: open,
        openEditAllCustomers: false,
      }));
    },
    [getAllCustomersForPopup]
  );

  const onChangeAllReps = useCallback(
    async (open) => {
      if (open) await getAllRepsForPopup();
      setPopupsState((prev) => ({
        ...prev,
        openAllReps: open,
      }));
    },
    [getAllRepsForPopup]
  );

  const handleChangeVisibilitySettingsTab = (tab) =>
    setCurrentVisibilitySettingsTab(tab);

  //const onCustomersDelete = useCallback(() => {
  //  const isSingleDelete = selectedState.selectedCustomers.length === 1;
  //  const title = isSingleDelete
  //    ? "Delete customer?"
  //    : `Delete ${pluralize(
  //        "customer",
  //        selectedState.selectedCustomers.length
  //      )}?`;

  //  dispatch(
  //    openConfirmDialogAction({
  //      title,
  //      text: (
  //        <>
  //          {isSingleDelete ? (
  //            <Typography
  //              fontWeight="400"
  //              fontSize="15px"
  //              whiteSpace="pre-line"
  //            >
  //              Are you sure you want to delete{" "}
  //              <span style={{ fontWeight: "500" }}>
  //                &#39;
  //                {selectedState.selectedCustomers[0]?.displayedName ||
  //                  selectedState.selectedCustomers[0]?.name}
  //                &#39;
  //              </span>{" "}
  //              from the list?
  //            </Typography>
  //          ) : (
  //            <Typography
  //              fontWeight="400"
  //              fontSize="15px"
  //              whiteSpace="pre-line"
  //            >
  //              Are you sure you want to delete selected{" "}
  //              {pluralize("customer", selectedState.selectedCustomers.length)}?
  //            </Typography>
  //          )}
  //        </>
  //      ),
  //      isIcon: true,
  //      propBtns: {
  //        left: {
  //          label: "Cancel",
  //          color: "cancel",
  //          variant: "outlined",
  //          sx: {
  //            width: "78px",
  //            color: "#6A6A6A",
  //            borderColor: "#D4D4D4",
  //            fontSize: "13px",
  //            height: "28px",
  //          },
  //        },
  //        right: {
  //          sx: {
  //            width: "78px",
  //            color: "#FFFFFF",
  //            fontSize: "13px",
  //            height: "28px",
  //            boxShadow: "none",
  //            backgroundColor: "#EB4233",
  //          },
  //          color: "error",
  //          label: "Delete",
  //          variant: "contained",
  //          onClick: () => {
  //            dispatch(setConfirmIsOpenAction(false));
  //            onSelectedSettingsStateChange({
  //              selectedCustomers: [],
  //            });
  //          },
  //        },
  //      },
  //    })
  //  );
  //}, [dispatch, selectedState.selectedCustomers]);

  //const onRepsDelete = useCallback(() => {
  //  const isSingleDelete = selectedState.selectedReps.length === 1;
  //  const title = isSingleDelete
  //    ? "Delete customer?"
  //    : `Delete ${pluralize("customer", selectedState.selectedReps.length)}?`;

  //  dispatch(
  //    openConfirmDialogAction({
  //      title,
  //      text: (
  //        <>
  //          {isSingleDelete ? (
  //            <Typography
  //              fontWeight="400"
  //              fontSize="15px"
  //              whiteSpace="pre-line"
  //            >
  //              Are you sure you want to delete{" "}
  //              <span style={{ fontWeight: "500" }}>
  //                &#39;
  //                {selectedState.selectedReps[0]?.name}
  //                &#39;
  //              </span>{" "}
  //              from the list?
  //            </Typography>
  //          ) : (
  //            <Typography
  //              fontWeight="400"
  //              fontSize="15px"
  //              whiteSpace="pre-line"
  //            >
  //              Are you sure you want to delete selected{" "}
  //              {pluralize("customer", selectedState.selectedReps.length)}?
  //            </Typography>
  //          )}
  //        </>
  //      ),
  //      isIcon: true,
  //      propBtns: {
  //        left: {
  //          label: "Cancel",
  //          color: "cancel",
  //          variant: "outlined",
  //          sx: {
  //            width: "78px",
  //            color: "#6A6A6A",
  //            borderColor: "#D4D4D4",
  //            fontSize: "13px",
  //            height: "28px",
  //          },
  //        },
  //        right: {
  //          sx: {
  //            width: "78px",
  //            color: "#FFFFFF",
  //            fontSize: "13px",
  //            height: "28px",
  //            boxShadow: "none",
  //            backgroundColor: "#EB4233",
  //          },
  //          color: "error",
  //          label: "Delete",
  //          variant: "contained",
  //          onClick: () => {
  //            dispatch(setConfirmIsOpenAction(false));
  //            onSelectedSettingsStateChange({
  //              selectedReps: [],
  //            });
  //          },
  //        },
  //      },
  //    })
  //  );
  //}, [dispatch, selectedState.selectedReps]);

  const handleAddCustomers = useCallback(
    (customers) => {
      const ids = customers.map(({ id }) => id);
      const assignedIds = listState.customersList.map(({ id }) => id);
      const newAssignedIds = ids.filter((id) => !assignedIds.includes(id));

      if (newAssignedIds.length || ids.length < assignedIds.length) {
        setValue("assignedCustomers", customers);
        setValue("_count.assignedCustomers", customers?.length, {
          shouldDirty: true,
        });

        setListState((prev) => ({
          ...prev,
          customersList: customers,
        }));
      }
      onChangeAllCustomers(false);
    },
    [listState.customersList, onChangeAllCustomers, setValue]
  );

  const handleAddReps = useCallback(
    (selectedReps) => {
      const ids = selectedReps?.map(({ id }) => id);
      const assignedIds = listState.repsList?.map(({ id }) => id);
      const newAssignedIds = ids.filter((id) => !assignedIds.includes(id));

      if (newAssignedIds.length || ids.length < assignedIds.length) {
        setValue("assignedRepresentatives", selectedReps, {
          shouldDirty: true,
        });
        setValue("_count.assignedRepresentatives", selectedReps?.length, {
          shouldDirty: true,
        });
      }
    },
    [listState?.repsList, setValue]
  );

  const onDeleteAllResp = () => {
    setPopupsState((prev) => ({
      ...prev,
      openAllReps: false,
    }));
    setValue("assignedRepresentatives", [], {
      shouldDirty: true,
    });
    setValue("_count.assignedRepresentatives", 0, {
      shouldDirty: true,
    });
    setListState((prev) => ({
      ...prev,
      repsList: [],
    }));
  };

  const onCustomersDelete = useCallback(() => {
    const title = "Remove all customers";

    dispatch(
      openConfirmDialogAction({
        title,
        text: (
          <>
            <Typography fontWeight="400" fontSize="15px" whiteSpace="pre-line">
              Are you sure you want to remove all linked customers from this
              Form?
            </Typography>
          </>
        ),
        isIcon: true,
        propBtns: {
          left: {
            label: "Cancel",
            color: "cancel",
            variant: "outlined",
            sx: {
              width: "78px",
              color: "#6A6A6A",
              borderColor: "#D4D4D4",
              fontSize: "13px",
              height: "28px",
            },
          },
          right: {
            sx: {
              width: "78px",
              color: "#FFFFFF",
              fontSize: "13px",
              height: "28px",
              boxShadow: "none",
              backgroundColor: "#EB4233",
            },
            color: "error",
            label: "Confirm",
            variant: "contained",
            onClick: () => {
              dispatch(setConfirmIsOpenAction(false));
              setValue("assignedCustomers", [], { shouldDirty: true });
              setValue("_count.assignedCustomers", 0, { shouldDirty: true });
              setListState((prev) => ({
                ...prev,
                customersList: [],
              }));
            },
          },
        },
      })
    );
  }, [dispatch, setValue]);

  const onRepsDelete = useCallback(() => {
    const title = "Remove all reps";

    dispatch(
      openConfirmDialogAction({
        title,
        text: (
          <>
            <Typography fontWeight="400" fontSize="15px" whiteSpace="pre-line">
              Are you sure you want to remove all linked reps from this Form?
            </Typography>
          </>
        ),
        isIcon: true,
        propBtns: {
          left: {
            label: "Cancel",
            color: "cancel",
            variant: "outlined",
            sx: {
              width: "78px",
              color: "#6A6A6A",
              borderColor: "#D4D4D4",
              fontSize: "13px",
              height: "28px",
            },
          },
          right: {
            sx: {
              width: "78px",
              color: "#FFFFFF",
              fontSize: "13px",
              height: "28px",
              boxShadow: "none",
              backgroundColor: "#EB4233",
            },
            color: "error",
            label: "Confirm",
            variant: "contained",
            onClick: () => {
              dispatch(setConfirmIsOpenAction(false));
              setValue("assignedRepresentatives", [], { shouldDirty: true });
              setValue("_count.assignedRepresentatives", 0, {
                shouldDirty: true,
              });
              setListState((prev) => ({
                ...prev,
                repsList: [],
              }));
            },
          },
        },
      })
    );
  }, [dispatch, setValue]);

  return {
    currentVisibilitySettingsTab,
    handleChangeVisibilitySettingsTab,
    onChangeAllCustomers,
    onChangeAllReps,
    openAllCustomers: popupsState.openAllCustomers,
    openAllReps: popupsState.openAllReps,
    showCheckedOnly: popupsState.showCheckedOnly,
    onCustomersDelete,
    onRepsDelete,
    onEditAllCustomers,
    setListState,
    handleAddCustomers,
    handleAddReps,
    customersCheckedList: listState.customersList,
    assignedReps: listState.repsList,
    openEditAllCustomers: popupsState.openEditAllCustomers,
    onDeleteAllResp,
  };
};
