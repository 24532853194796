export const cl = {
  tabsPropsSx: {
    p: "0px !important",
    height: "36px",
    fontSize: "14px !important",
    fontWeight: "500!important",
    color: "#9C9C94",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      height: "2px",
    },
    minHeight: "auto",
    height: "36px",
    "& .Mui-selected": {
      backgroundColor: "transparent!important",
    },
  },
  tabWrapper: {
    width: "100%",
    px: "24px",
    height: "fit-content",
    position: "relative",
    borderBottom: "0.50px #D9D9D9 solid",

    "& .MuiTabs-flexContainer": {
      gap: "24px",
    },
  },
};
