import { createContext } from "react";
import { useParams } from "react-router-dom";

import {
  PageHeader,
  ProductTabContent,
  CustomersTabContent,
} from "./components";
import { useProducts } from "./useProducts";
import { useCustomers } from "./useCustomers";
import { useProductActions } from "./useProductActions";
import { useProductListById } from "./useProductListById";
import { PriceActionDrawer } from "./components/PriceActionDrawer";
import { TabsBlock } from "./components/ProductTabContent/components";
import AllCustomersPopup from "Pages/CreateRoutePage/components/AllCustomersPopup";
import AllProductsPopup from "Pages/CartPage/components/AllProductsPopup/AllProductsPopup";

import Stack from "@mui/material/Stack";
import { useAdmin } from "helpers/helpers";
import { useRepsPermissions } from "helpers/hooks";

export const ProductPriceContext = createContext();

export const ProductPricePage = () => {
  const { priceItemId } = useParams();
  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();

  const priceListByIdHookData = useProductListById(priceItemId);

  const { currentTab } = priceListByIdHookData;

  const productsHookData = useProducts(priceListByIdHookData.currentPriceList);
  const customersHookData = useCustomers(
    priceListByIdHookData.currentPriceList,
    currentTab
  );

  const {
    onProductStateUpdate,
    selectedProducts,
    productsList,
    setTempCheckedProducts,
    onTempProductSelect,
    onCloseAllProducts,
    openAllProducts,
    tempCheckedProducts,
    setSelectedProducts,
    onSaveProductsFromPopup,
    productsCount,
    handleFetchProducts,
  } = productsHookData;

  const {
    openAllCustomers,
    onCloseAllCustomers,
    onSaveCustomersFromPopup,
    allCustomersList,
  } = customersHookData;

  const productActionsHookData = useProductActions({
    selectedProducts,
    productsList,
    onProductStateUpdate,
    setSelectedProducts,
    setTempCheckedProducts,
    priceItemId,
    productsCount,
    handleFetchProducts,
  });

  const { isPriceDrawerOpen, closePriceDialog } = productActionsHookData || {};

  const pageContent = {
    0: <ProductTabContent />,
    1: <CustomersTabContent />,
  };

  return (
    <ProductPriceContext.Provider
      value={{
        ...priceListByIdHookData,
        ...productsHookData,
        ...customersHookData,
        ...productActionsHookData,
        repPermissions,
        isAdmin,
      }}
    >
      <PageHeader />

      <Stack
        p="16px 32px"
        gap="13px"
        sx={{
          height: "calc(100vh - 116px)",
        }}
      >
        <TabsBlock />
        {!!priceListByIdHookData?.currentPriceList?.id &&
          pageContent[currentTab]}
      </Stack>

      {!!isPriceDrawerOpen && (
        <PriceActionDrawer
          open={isPriceDrawerOpen}
          onClose={closePriceDialog}
        />
      )}

      {openAllCustomers && (
        <AllCustomersPopup
          hideStatus
          allowMissingInfo
          viewCustomerPopup={false}
          handleAddCustomers={(customer) => {
            onSaveCustomersFromPopup(customer);
            onCloseAllCustomers();
          }}
          addedStops={allCustomersList}
          open={openAllCustomers}
          onClose={onCloseAllCustomers}
          priceListId={priceListByIdHookData?.currentPriceList?.id}
        />
      )}

      {openAllProducts && (
        <AllProductsPopup
          isPriceList
          isOpen={openAllProducts}
          handleClose={() => {
            onCloseAllProducts();
          }}
          handleCheckProduct={onTempProductSelect}
          handleCancelProducts={() => {
            onCloseAllProducts();
            setTempCheckedProducts(selectedProducts);
          }}
          setCheckState={setTempCheckedProducts}
          handleAddProducts={() => {
            onCloseAllProducts();
            onSaveProductsFromPopup();
          }}
          checkedProducts={tempCheckedProducts}
          addedProducts={[]}
          allowMultipleSelect
          hideGreenNavigationBar
          isOrder
          hideAvailable
          skipExpanding
          disableOutOfStock={false}
          allowSelectAll
        />
      )}
    </ProductPriceContext.Provider>
  );
};

export default ProductPricePage;
