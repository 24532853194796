import { useContext } from "react";
import { array, object } from "prop-types";
import { Box, Stack, Typography } from "@mui/material";
import { LinearProgressComponent } from "../index";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";
import { ScrollControlWrapper } from "components";

export const SingleBlock = ({ questions = [], question }) => {
  const list = question?.sortedAnswers?.map((key) => {
    return {
      id: key,
      answer: key,
      count: Math.round(question?.answersPercent?.[key]),
    };
  });

  const { dialogPreviewContentData } = useContext(FormViewContext);

  const { handleOpenDialogPreviewContent } = dialogPreviewContentData;

  return (
    <Box pb="12px">
      <Box
        sx={{
          maxHeight: "467px",
          borderRadius: "6px",
          backgroundColor: "#F8F8F8",
        }}
      >
        <Box pt="16px" pl="16px">
          <Typography fontSize={13} fontWeight={700} color="#000">
            Answers options
          </Typography>
        </Box>
        <Stack sx={{ px: "8px", pb: "8px" }}>
          <ScrollControlWrapper
            dataLength={questions?.length}
            id="single-block-list"
            maxHeight="432px"
          >
            {list?.map((q, i) => (
              <LinearProgressComponent
                key={q?.id}
                index={i}
                title={q?.answer}
                value={q?.count}
                onClick={() => {
                  handleOpenDialogPreviewContent({
                    item: {
                      ...question,
                      filterName: q?.answer,
                    },
                  });
                }}
              />
            ))}
          </ScrollControlWrapper>
        </Stack>
      </Box>
    </Box>
  );
};

SingleBlock.propTypes = {
  questions: array,
  question: object,
};
