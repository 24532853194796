import { object, func, bool } from "prop-types";
import { ActionRestrictionWrapper, TagField, TagsDrawer } from "components";
import { Grid } from "@mui/material";
import { TAG_TYPES } from "helpers/useTagsActions";
import { useRepsPermissions } from "helpers/hooks";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  ADMIN_ONLY_VIEW_MESSAGE_PAGE,
} from "utils/constants";

export const TagsBlock = ({
  setValue,
  formField,
  errors,
  setError,
  viewAllTags,
  setViewAllTags,
  adminIsAllowed,
}) => {
  const repPermissions = useRepsPermissions();

  return (
    <Grid mt="-14px" xs={12} item>
      <TagsDrawer
        type={TAG_TYPES.product}
        open={viewAllTags}
        handleClose={() => setViewAllTags(false)}
        productTags={formField.tags}
        handleSave={({ tags }) => {
          setValue("tags", tags, { shouldDirty: true });
          setViewAllTags(false);
        }}
        setCheckedCustomers={() => {}}
        adminIsAllow
        ed={adminIsAllowed}
      />
      <ActionRestrictionWrapper
        disableHoverListener={
          !repPermissions || repPermissions?.catalog?.manufacturers
        }
        title={
          repPermissions?.role === "SALES"
            ? ADMIN_ONLY_VIEW_MESSAGE
            : ADMIN_ONLY_VIEW_MESSAGE_PAGE
        }
      >
        <TagField
          disabled={!!repPermissions && !repPermissions?.catalog?.product_tags}
          fieldName="tags"
          setValue={setValue}
          errors={errors}
          setError={setError}
          handleViewAll={() => setViewAllTags(true)}
          chosenTags={formField.tags}
          type="product"
          adminIsAllowed={adminIsAllowed}
        />
      </ActionRestrictionWrapper>
    </Grid>
  );
};

TagsBlock.propTypes = {
  setValue: func,
  formField: object,
  errors: object,
  setError: func,
  viewAllTags: bool,
  setViewAllTags: func,
  adminIsAllowed: bool,
};
