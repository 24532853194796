import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { ScrollControlWrapper } from "components";
import { CrossIcon, GalleryNextIcon, MapPinRedIcon } from "components/Icons";
import ImageBox from "components/ImageComponent/ImageBox";
import { photoUrl } from "helpers/helpers";
import { bool, func, number, object } from "prop-types";
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

const DIALOG_GLOBAL_Y_MARGINS = 64;
const TITLE_BLOCK_HEIGHT = 64;

export const DialogPreviewPhotos = ({
  open = false,
  onClose = () => {},
  response = {},
  position = 0,
}) => {
  const sliderRef = useRef(null);
  const [loadingPhoto, setLoadingPhoto] = useState(false);
  const [positionPhoto, setPositionPhoto] = useState(false);

  useEffect(() => {
    if (positionPhoto) {
      sliderRef.current.slickGoTo(`${position}`);
    }
  }, [position, positionPhoto]);

  const customerName = response?.progress?.customer?.displayedName || "";
  const address =
    response?.progress?.customer?.billingAddress?.formatted_address || "";

  return (
    <Dialog
      open={open}
      sx={{
        ".MuiPaper-root": {
          boxShadow: "none",
          width: "100%",
          maxWidth: "640px",
          overflow: "hidden",
        },
      }}
      scroll="paper"
    >
      <Box
        sx={{
          position: "absolute",
          top: `calc(${TITLE_BLOCK_HEIGHT}px + 24px)`,
          left: "24px",
          p: "4px 8px",
          bgcolor: "#FFF",
          borderRadius: "8px",
          zIndex: 1,
          maxWidth: "200px",
        }}
      >
        <Typography fontSize={10} fontWeight={400} color="#000" noWrap>
          {customerName}
        </Typography>
      </Box>

      <IconButton
        sx={{ position: "absolute", right: 10, top: 12 }}
        onClick={onClose}
      >
        <CrossIcon />
      </IconButton>
      <Box>
        <Grid height={`${TITLE_BLOCK_HEIGHT}px`} container>
          <Grid
            xs={1}
            item
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MapPinRedIcon fill="#409A65" />
          </Grid>
          <Grid
            xs={10}
            item
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography fontSize={14} fontWeight={500} color="#707070" noWrap>
              {address}
            </Typography>
          </Grid>
        </Grid>

        <DialogContent
          sx={{
            p: 0,
            height: "100%",
            maxHeight: `calc(100vh - ${
              DIALOG_GLOBAL_Y_MARGINS + TITLE_BLOCK_HEIGHT
            }px)`,
          }}
        >
          <ScrollControlWrapper
            maxHeight={`calc(100vh - ${
              DIALOG_GLOBAL_Y_MARGINS + TITLE_BLOCK_HEIGHT
            }px)`}
            id="dialog-preview-photos-list"
          >
            <Box
              sx={{
                p: "0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                position: "relative",
                height: "705px",
              }}
            >
              <Box
                width="100%"
                height="100%"
                sx={{
                  "& .slick-dots": {
                    position: "absolute",
                    bottom: "0px",
                    "& li": {
                      m: 0,
                    },
                    "& .slick-active .MuiBox-root": {
                      backgroundColor: "#5F6267",
                    },
                  },
                  "& .slick-slider": {
                    height: "100%",
                    "& .slick-list": {
                      height: "100%",
                      "& div": {
                        height: "100%",
                      },
                    },
                  },
                  "& .slick-slide img": {
                    display: "unset",
                  },
                  "& .slick-arrow::before": {
                    content: '""',
                  },
                }}
              >
                <Slider
                  ref={(ref) => {
                    sliderRef.current = ref;
                    if (position > 0) {
                      setPositionPhoto(true);
                    }
                  }}
                  infinite={false}
                  speed={400}
                  slidesToShow={1}
                  slidesToScroll={1}
                  dots
                  customPaging={() => (
                    <Box
                      sx={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        border: "1px solid #5F6267",
                      }}
                    />
                  )}
                  nextArrow={
                    <Box top="47%" right="26px" zIndex="10">
                      <GalleryNextIcon />
                    </Box>
                  }
                  prevArrow={
                    <Box
                      top="47%"
                      left="26px"
                      zIndex="10"
                      sx={{ transform: "scale(-1, -1) translateY(0%)" }}
                    >
                      <GalleryNextIcon />
                    </Box>
                  }
                >
                  {response?.photos?.map((photo) => (
                    <Box
                      key={photo?.id}
                      sx={{
                        display: "flex!important",
                        position: "relative",
                        justifyContent: "center",
                        height: "100%",
                        alignItems: "center",
                      }}
                    >
                      <ImageBox
                        sx={{
                          maxWidth: "500px",
                          maxHeight: "640px",
                          zIndex: 10,
                        }}
                        showLoadingState
                        placeholderSrc={photo?.compressedFileName}
                        src={photo?.fileName}
                        setLoadingPhoto={setLoadingPhoto}
                        loading={loadingPhoto}
                      />
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          maxWidth: "100%",
                          maxHeight: "100%",
                          position: "absolute",
                          filter: "blur(70px) brightness(112%)",
                        }}
                        component="img"
                        src={photoUrl(photo?.compressedFileName)}
                      />
                    </Box>
                  ))}
                </Slider>
              </Box>
            </Box>
          </ScrollControlWrapper>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

DialogPreviewPhotos.propTypes = {
  open: bool,
  onClose: func,
  response: object,
  position: number,
};
