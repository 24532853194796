import React, { useContext } from "react";
import { ProspectsMap, ProspectsTable } from "./components";
import { MapWrap, PageWrap, TableWrap } from "./styles";

import { array, func, string } from "prop-types";
import { MAP_PROSPECT_TYPES } from "../ProspectsFilter/ProspectsFilter.constants";
import { ProspectsPageContext } from "../../ProspectsTab";

const ProspectsPage = ({ categories, handleOpenFilters, setFilter }) => {
  const {
    currentMarkersListBySearch,
    checkedProspects,
    setCheckedProspects,
    handleCheckProspect,
  } = useContext(ProspectsPageContext);

  return (
    <PageWrap sx={{ height: "calc(100vh - 207px)" }}>
      <TableWrap>
        <ProspectsTable
          prospects={currentMarkersListBySearch}
          checkedProspects={checkedProspects}
          handleCheckProspect={handleCheckProspect}
        />
      </TableWrap>
      <MapWrap>
        <ProspectsMap
          mapMarkers={currentMarkersListBySearch}
          {...{
            categories,
            handleOpenFilters,
            handleCheckProspect,
            checkedProspects,
            setCheckedProspects,
            setFilter,
          }}
        />
      </MapWrap>
    </PageWrap>
  );
};

ProspectsPage.defaultProps = {
  categories: [],
  handleOpenFilters: () => {},
  filter: MAP_PROSPECT_TYPES.history,
  setFilter: () => {},
};

ProspectsPage.propTypes = {
  categories: array,
  handleOpenFilters: func,
  filter: string,
  setFilter: func,
};

export default ProspectsPage;
