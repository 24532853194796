import {
  Box,
  CircularProgress,
  MenuItem,
  Paper,
  Tab,
  Tabs,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { bool } from "prop-types";
import { StyledSelect } from "../../../../components/Selects";
import { PerformanceItem, PromotionItem } from "./components";
import { WIDGET_TABS, WIDGET_TYPES } from "./PromotionsWidget.constants";
import useStyles from "./styles";
import NotAvailable from "../../components/NotAvailable";
import {
  getActivePromotionsService,
  getProductPerformanceService,
} from "../../../../services/dashboard";
import { normalizeUppercaseString } from "../../../../helpers/helpers";
import { useBreakpoint } from "helpers/useBreakpoint";
import { ActionRestrictionWrapper, StyledLink } from "components";
import { useRepsPermissions } from "helpers/hooks";

const PromotionsWidget = ({ notAvailable }) => {
  const breakpoint = useBreakpoint();
  const repsPermissions = useRepsPermissions();

  const classes = useStyles({ breakpoint });

  const [type, setType] = useState(WIDGET_TYPES[0].value);
  const [currentTab, setCurrentTab] = useState(WIDGET_TABS[0]);
  const [listState, setListState] = useState({
    promotions: [],
    performances: { top: [], low: [] },
    loading: false,
  });

  const itemsList = useMemo(() => {
    if (type === WIDGET_TYPES[0].value)
      return listState.promotions
        .slice(0, 4)
        .map((item) => <PromotionItem key={item.id} promotion={item} />);

    return listState.performances[currentTab]
      .slice(0, 4)
      .map((item) => <PerformanceItem key={item.id} product={item} />);
  }, [currentTab, listState.performances, listState.promotions, type]);

  const getAvailableText = () => {
    if (notAvailable) return "Not available for 3rd party reps";
    if (!itemsList.length) return "No data available yet";
    return "Not available";
  };

  useEffect(() => {
    const controllerPromotions = new AbortController();
    const controllerPerformance = new AbortController();
    setListState((prev) => ({ ...prev, loading: true }));
    Promise.all([
      getActivePromotionsService({}, controllerPromotions.signal),
      getProductPerformanceService({}, controllerPerformance.signal),
    ])
      .then((res) => {
        const promotions = res[0].topPromotions;
        const { topPerformance: top, lowPerformance: low } = res[1];

        setListState((prev) => ({
          ...prev,
          promotions,
          performances: { top, low },
          loading: false,
        }));
      })
      .catch(() => setListState((prev) => ({ ...prev, loading: false })));

    return () => {
      controllerPromotions.abort();
      controllerPerformance.abort();
    };
  }, []);

  const disabledPermissionsLink = useMemo(() => {
    if (
      type === WIDGET_TYPES[0].value &&
      !!repsPermissions &&
      !repsPermissions?.discounts?.view
    ) {
      return true;
    }
    if (
      type === WIDGET_TYPES[1].value &&
      !!repsPermissions &&
      !repsPermissions?.catalog?.view
    ) {
      return true;
    }

    return false;
  }, [repsPermissions, type]);

  const widgetContent = useMemo(() => {
    if (listState.loading)
      return (
        <Box
          width="100%"
          height="90%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      );
    return (
      <Box>
        <Box display="flex" flexDirection="column">
          {itemsList}
        </Box>

        <Box style={{ position: "absolute", bottom: 13 }}>
          <ActionRestrictionWrapper
            disableHoverListener={!disabledPermissionsLink}
          >
            <StyledLink
              disabled={disabledPermissionsLink}
              to={type === WIDGET_TYPES[0].value ? "discounts" : "catalog"}
              color="#1C1C19"
              fontSize="12px"
            >
              {type === WIDGET_TYPES[0].value
                ? "View all active promotions"
                : "View all products"}
            </StyledLink>
          </ActionRestrictionWrapper>
        </Box>
      </Box>
    );
  }, [disabledPermissionsLink, itemsList, listState.loading, type]);

  return (
    <Paper elevation={0} className={classes.widgetWrapper}>
      <Box className={classes.selectWrapper}>
        <StyledSelect
          value={type}
          onChange={(e) => setType(e.target.value)}
          label=""
          variant="standard"
          disableUnderline
          fontSize="16px"
          sx={{
            "&.MuiInput-root": { m: 0 },
            "& .MuiSelect-select": { background: "transparent!important" },
          }}
        >
          {WIDGET_TYPES.map((t) => (
            <MenuItem key={t.value} value={t.value}>
              {t.name}
            </MenuItem>
          ))}
        </StyledSelect>
        {type === WIDGET_TYPES[1].value && (
          <Tabs
            value={currentTab}
            onChange={(e, newVal) => setCurrentTab(newVal)}
            className={classes.tabs}
            TabIndicatorProps={{ style: { background: "none" } }}
          >
            {WIDGET_TABS.map((tab) => (
              <Tab
                key={tab}
                label={normalizeUppercaseString(tab)}
                value={tab}
                className={classes.tab}
              />
            ))}
          </Tabs>
        )}
      </Box>
      {notAvailable || (!itemsList.length && !listState.loading) ? (
        <NotAvailable text={getAvailableText()} />
      ) : (
        widgetContent
      )}
    </Paper>
  );
};

PromotionsWidget.propTypes = { notAvailable: bool };

export default PromotionsWidget;
