import { CircularProgress, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import useStyles from "../styles";
import { StyledSelect } from "../../../../../../components/Selects";
import { StyledDataGrid } from "./StyledDataGrid";
import { useContext, useEffect } from "react";
import { ImportContext } from "Pages/SettingsPage/SettingsPage";
import { useImport, useMapData } from "./hooks";

const ImportStepTwo = () => {
  const { session, onRemapChange, currentColumns, headers } = useMapData();

  const classes = useStyles({ sessionLoading: session.loading });

  const { importData } = useContext(ImportContext);

  const { handlePaginate, tableRows, importPagination, getRowsCount } =
    useImport();

  useEffect(() => {
    getRowsCount({ force: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box position="relative">
      <Typography className={classes.bodyText}>
        Select type of each column form from the dropdown menu.
      </Typography>
      {session.loading && (
        <Box className={classes.loaderWrapper}>
          <CircularProgress />
        </Box>
      )}
      <Box
        height="486px"
        mt="15px"
        sx={{
          overflowX: "auto",
          overflowY: "hidden",
          border: "1px solid #d5d9d9",
          borderRadius: "4px",
          display: "flex",
          flexDirection: "column",
          pr: 0,
        }}
      >
        <Box className={classes.selectsRow}>
          {Object.keys(importData.mappedCols)?.map((headerKey, index) => (
            <Box key={headerKey} className={classes.selectItem}>
              <StyledSelect
                className={classes.gridColumnSelect}
                fullWidth
                value={importData.mappedCols[headerKey]}
                displayEmpty
                notched={false}
                onChange={(e) =>
                  onRemapChange({
                    value: e.target.value,
                    column: headerKey,
                    index,
                  })
                }
              >
                <MenuItem value="">- - -</MenuItem>
                {Object.keys(currentColumns)
                  .filter((key) => key !== "discount")
                  .map((columnKey) => (
                    <MenuItem key={columnKey} value={columnKey}>
                      {currentColumns[columnKey]?.title}
                    </MenuItem>
                  ))}
              </StyledSelect>
            </Box>
          ))}
        </Box>
        <StyledDataGrid
          rowCount={importData.rowsCount}
          rows={tableRows}
          columns={Object.keys(headers)?.map((key) => ({
            ...headers[key],
            editable: false,
            flex: 1,
            minWidth: 160,
            headerName: headers[key].title,
            field: key,
          }))}
          onPageChange={handlePaginate}
          page={importPagination.page}
        />
      </Box>
    </Box>
  );
};

export default ImportStepTwo;
