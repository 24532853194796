import { object, string } from "prop-types";
import { Box, Stack, Typography } from "@mui/material";

export const AddressBlock = ({ place = "", address = "", wrapProps = {} }) => {
  return (
    <Stack
      mt="4px"
      pb="6px"
      flexDirection="row"
      gap="4px"
      borderBottom="1px solid #F3F3F3"
      {...wrapProps}
    >
      <Box>
        <Typography fontSize={12} fontWeight={300} color="#222" noWrap>
          at {place}
        </Typography>
      </Box>

      <Box>
        <Typography fontSize={12} fontWeight={300} color="#707070CC" noWrap>
          {address}
        </Typography>
      </Box>
    </Stack>
  );
};

AddressBlock.propTypes = {
  place: string,
  address: string,
  wrapProps: object,
};
