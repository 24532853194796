import { styled, Tooltip, tooltipClasses } from "@mui/material";

const ERROR_BGCOLOR = "#FBE4E7";

export const StyledTooltip = styled(
  ({
    className,
    isDark,
    PopperProps = {
      modifiers: [{ name: "offset", options: { offset: [0, -5] } }],
    },
    isError,
    ...props
  }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      PopperProps={PopperProps}
    />
  )
)(({ isDark, isError }) => {
  const setBackgroundColor = () => {
    if (isError) return ERROR_BGCOLOR;
    if (isDark) return "#707070";
    return "#ffffff";
  };
  const setColor = () => {
    if (isError) return "#000000";
    if (isDark) return "#ffffff";
    return "#1C1C19";
  };
  const setBorder = () => {
    if (isError) return "1px solid #EE6F81";
    if (isDark) return "none";
    return "0.4px solid #d5d9d9";
  };

  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: setBackgroundColor(),
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "17px",
      color: setColor(),
      border: setBorder(),
      borderRadius: "8px",
      boxShadow: "0px 3px 6px rgba(0, 3, 6, 0.16)",
      padding: "12.5px 13.5px",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: setColor(),
      height: "12px",
      width: "17px",

      "&:before": {
        border: setBorder(),
        borderRadius: "2px 0 2px 0",
        backgroundColor: setBackgroundColor(),
      },
    },
    [`& .MuiTooltip-tooltipPlacementBottom .${tooltipClasses.arrow}`]: {
      marginTop: "-0.98em !important",
      marginBottom: "-0.98em !important",
    },
    [`& .MuiTooltip-tooltipPlacementTop .${tooltipClasses.arrow}`]: {
      marginBottom: "-0.98em !important",
      marginTop: "-0.98em !important",
    },
    [`& .MuiTooltip-tooltipPlacementRight .${tooltipClasses.arrow}`]: {
      "&:before": {
        borderRadius: "0 0 0 2px",
      },
    },
    [`& .MuiTooltip-tooltipPlacementLeft .${tooltipClasses.arrow}`]: {
      "&:before": {
        borderRadius: "0 2px 0 0",
      },
    },
  };
});

export default StyledTooltip;
