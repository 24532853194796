export const EmptyCustomersIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="124.286"
      height="123.433"
      viewBox="0 0 124.286 123.433"
      {...props}
    >
      <g
        id="Group_7509"
        data-name="Group 7509"
        transform="translate(-6364.637 -18308.357)"
      >
        <g
          id="Group_7507"
          data-name="Group 7507"
          transform="translate(-65.844 -2.203)"
        >
          <g
            id="Ellipse_1009"
            data-name="Ellipse 1009"
            transform="translate(6505.59 18374.762) rotate(-90)"
            fill="#fff"
            stroke="#f5f5f5"
            strokeWidth="1"
          >
            <ellipse
              cx="24.737"
              cy="24.295"
              rx="24.737"
              ry="24.295"
              stroke="none"
            />
            <ellipse
              cx="24.737"
              cy="24.295"
              rx="24.237"
              ry="23.795"
              fill="none"
            />
          </g>
          <g
            id="Group_7506"
            data-name="Group 7506"
            transform="translate(-2116.745 2337.52)"
          >
            <path
              id="Path_4966"
              data-name="Path 4966"
              d="M365,17.923a4.389,4.389,0,0,1,5.295-4.295l2.852.6a9.646,9.646,0,0,0,3.983,0l2.852-.6a4.389,4.389,0,1,1,.905,8.683h-11.5A4.389,4.389,0,0,1,365,17.923Z"
              transform="translate(8271.492 16000.846)"
              fill="none"
              stroke="#abd3bb"
              strokeWidth="2"
            />
            <ellipse
              id="Ellipse_984"
              data-name="Ellipse 984"
              cx="4.482"
              cy="4.482"
              rx="4.482"
              ry="4.482"
              transform="translate(8642.148 16001.85)"
              fill="none"
              stroke="#abd3bb"
              strokeWidth="2"
            />
          </g>
        </g>
        <g
          id="Group_7508"
          data-name="Group 7508"
          transform="translate(-123.581 56.326)"
        >
          <g
            id="Ellipse_1009-2"
            data-name="Ellipse 1009"
            transform="translate(6505.59 18374.762) rotate(-90)"
            fill="#fff"
            stroke="#f5f5f5"
            strokeWidth="2"
          >
            <ellipse
              cx="24.737"
              cy="24.295"
              rx="24.737"
              ry="24.295"
              stroke="none"
            />
            <ellipse
              cx="24.737"
              cy="24.295"
              rx="24.237"
              ry="23.795"
              fill="none"
            />
          </g>
          <g
            id="Group_7506-2"
            data-name="Group 7506"
            transform="translate(-2116.745 2337.52)"
          >
            <path
              id="Path_4966-2"
              data-name="Path 4966"
              d="M365,17.923a4.389,4.389,0,0,1,5.295-4.295l2.852.6a9.646,9.646,0,0,0,3.983,0l2.852-.6a4.389,4.389,0,1,1,.905,8.683h-11.5A4.389,4.389,0,0,1,365,17.923Z"
              transform="translate(8271.492 16000.846)"
              fill="none"
              stroke="#e2f0e8"
              strokeWidth="2"
            />
            <ellipse
              id="Ellipse_984-2"
              data-name="Ellipse 984"
              cx="4.482"
              cy="4.482"
              rx="4.482"
              ry="4.482"
              transform="translate(8642.148 16001.85)"
              fill="none"
              stroke="#e2f0e8"
              strokeWidth="2"
            />
          </g>
        </g>
        <g
          id="Rectangle_11758"
          data-name="Rectangle 11758"
          transform="translate(6428.839 18377.66) rotate(-45)"
          fill="#fff"
          stroke="#f5f5f5"
          strokeWidth="1"
        >
          <rect width="7.216" height="30.066" stroke="none" />
          <rect x="0.5" y="0.5" width="6.216" height="29.066" fill="none" />
        </g>
        <g
          id="Ellipse_1007"
          data-name="Ellipse 1007"
          transform="translate(6364.637 18392.543) rotate(-90)"
          fill="#e2f0e8"
          stroke="#f5f5f5"
          strokeWidth="1"
        >
          <circle cx="42.093" cy="42.093" r="42.093" stroke="none" />
          <circle cx="42.093" cy="42.093" r="41.593" fill="none" />
        </g>
        <g
          id="Rectangle_11757"
          data-name="Rectangle 11757"
          transform="translate(6440.746 18396.369) rotate(-45)"
          fill="#fff"
          stroke="#f5f5f5"
          strokeWidth="1"
        >
          <path
            d="M3.608,0H14.432A3.608,3.608,0,0,1,18.04,3.608V41.073a9.02,9.02,0,0,1-9.02,9.02h0A9.02,9.02,0,0,1,0,41.073V3.608A3.608,3.608,0,0,1,3.608,0Z"
            stroke="none"
          />
          <path
            d="M3.639.5H14.4A3.139,3.139,0,0,1,17.54,3.639V41.073a8.52,8.52,0,0,1-8.52,8.52h0A8.52,8.52,0,0,1,.5,41.073V3.639A3.139,3.139,0,0,1,3.639.5Z"
            fill="none"
          />
        </g>
        <ellipse
          id="Ellipse_1008"
          data-name="Ellipse 1008"
          cx="35.834"
          cy="35.235"
          rx="35.834"
          ry="35.235"
          transform="translate(6371.551 18386.34) rotate(-90)"
          fill="#fff"
        />
        <g
          id="Group_7505"
          data-name="Group 7505"
          transform="translate(-2244.453 2332.736)"
        >
          <path
            id="Path_4966-3"
            data-name="Path 4966"
            d="M365,19.9a6.36,6.36,0,0,1,7.672-6.223l4.132.872a13.978,13.978,0,0,0,5.771,0l4.132-.872a6.36,6.36,0,1,1,1.312,12.583H371.361A6.36,6.36,0,0,1,365,19.9Z"
            transform="translate(8271.492 16006.472)"
            fill="none"
            stroke="#409a65"
            strokeWidth="2"
          />
          <ellipse
            id="Ellipse_984-3"
            data-name="Ellipse 984"
            cx="6.495"
            cy="6.495"
            rx="6.495"
            ry="6.495"
            transform="translate(8644.688 16001.85)"
            fill="none"
            stroke="#409a65"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};
